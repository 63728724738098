<div *ngIf="initialized" class="container-fluid mt-4 py-2 rounded-ninety">
    <div class="row">
        <div class="col" style="font-size: 1.6em;">
            Run Sheet
        </div>
    </div>

    <div class="row pt-2" *ngIf="!locked">
        <div class="col" align="right">
            <app-button class="mx-1" label="Add" [clickFunction]="add.bind(this)" [icon]="faPlus" [small]="'true'">
            </app-button>

            <app-input-enum label="" [fc]="addFC" [enumValuesNameAndId]="findingTypes" [style]="'inside-list'"
                [naturalWidth]="'true'">
            </app-input-enum>
        </div>
    </div>

    <div *ngIf="addError" class="row">
        <div class="col" align="right">
            {{addError}}
        </div>
    </div>

    <div class="row pt-2">
        <div class="col bold" style="font-size: 1.6em; background-color: lightgrey;">
            Order Information
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <app-input-text label="Order #" [fc]="orderNumberFC" [disabled]="'true'">
            </app-input-text>
            <app-input-text label="Order Type" [fc]="orderTypeFC" [disabled]="'true'">
            </app-input-text>
            <app-input-text label="SBL" [fc]="sblFC" [disabled]="'true'">
            </app-input-text>

        </div>
        <div class="col-6">
            <app-input-text label="Client Ref #" [fc]="clientRefFC" [disabled]="'true'">
            </app-input-text>
            <app-input-date label="Searched From" [fc]="searchedFromFC" [disabled]="locked">
            </app-input-date>
            <app-input-date label="Effective Date" [fc]="effectiveDateFC" [disabled]="locked">
            </app-input-date>
        </div>
    </div>


    <div class="row pt-2">
        <div class="col bold" style="font-size: 1.6em; background-color: lightgrey;">
            Owner Information
        </div>
    </div>

    <app-input-text label="Name" [fc]="ownerNameFC" [disabled]="locked">
    </app-input-text>
    <span *ngIf="headerErrors['ownerName']" class="validation-error mt-1">{{headerErrors['ownerName']}}</span>

    <app-input-text label="Property" [fc]="propertyFC" [disabled]="locked">
    </app-input-text>
    <span *ngIf="headerErrors['property']" class="validation-error mt-1">{{headerErrors['property']}}</span>

    <app-input-text label="County" [fc]="countyFC" [disabled]="locked">
    </app-input-text>
    <span *ngIf="headerErrors['county']" class="validation-error mt-1">{{headerErrors['county']}}</span>

    <span *ngFor="let type of findingTypes">
        <span *ngIf="findingsByType[type.id]">


            <div *ngIf="type.id != 'CR' && type.id != 'EASE'" class="row">
                <div class="col bold" style="font-size: 1.6em; background-color: lightgrey;">
                    {{type.name}}
                </div>
            </div>

            <div *ngIf="(type.id == 'CR' && !findingsByType['DEED']) || (type.id == 'EASE' && !findingsByType['DEED'] && !findingsByType['CR'])"
                class="row">
                <div class="col bold" style="font-size: 1.6em; background-color: lightgrey;">
                    Deeds
                </div>
            </div>

            <span *ngFor="let finding of findingsByType[type.id]; let i = index;">
                <span *ngIf="type.id == 'DEED' || type.id == 'CR' || type.id == 'EASE'">
                    <hr
                        *ngIf="i != 0 || (type.id == 'CR' && findingsByType['DEED']) || (type.id == 'EASE' && (findingsByType['DEED'] || findingsByType['CR']))" />
                </span>

                <div class="row">
                    <div class="col-6" style="font-style: italic; color: grey;">
                        {{type.singular_name}} {{i + 1}}
                    </div>
                    <div class="col-6" align="right">
                        <app-button *ngIf="!finding.editable && !locked" class="mx-1" label="Edit"
                            [clickFunction]="edit.bind(this, finding)" [icon]="faPlus" [small]="'true'">
                        </app-button>
                        <app-button class="mx-1" *ngIf="!locked" label="Delete"
                            [clickFunction]="delete.bind(this, type.id, i)" [buttonType]="ButtonType.delete"
                            [small]="'true'">
                        </app-button>
                    </div>
                </div>

                <span *ngIf="type.id == 'NAME'">
                    <div class="row">
                        <div class="col-4">
                            <app-input-text label="Name" [fc]="finding.name1FC"
                                [disabled]="!finding.editable || locked">
                            </app-input-text>
                            <span *ngIf="finding.formErrors && finding.formErrors['name1']"
                                class="validation-error mt-1">{{finding.formErrors['name1']}}</span>

                        </div>
                        <div class="col-4">
                            <app-input-date label="Date Searched Start" [fc]="finding.date1FC"
                                [disabled]="!finding.editable || locked">
                            </app-input-date>
                            <span *ngIf="finding.formErrors && finding.formErrors['date1']"
                                class="validation-error mt-1">{{finding.formErrors['date1']}}</span>
                        </div>
                        <div class="col-4">
                            <app-input-date label="End" [fc]="finding.date2FC" [disabled]="!finding.editable || locked">
                            </app-input-date>
                        </div>
                    </div>
                </span>


                <span *ngIf="type.id == 'DEED' || type.id == 'CR' || type.id == 'EASE'">
                    <app-input-text [label]="type.name_1_label" [fc]="finding.name1FC"
                        [disabled]="!finding.editable || locked">
                    </app-input-text>
                    <span *ngIf="finding.formErrors && finding.formErrors['name1']"
                        class="validation-error mt-1">{{finding.formErrors['name1']}}</span>

                    <app-input-text [label]="type.name_2_label" [fc]="finding.name2FC"
                        [disabled]="!finding.editable || locked">
                    </app-input-text>
                    <span *ngIf="finding.formErrors && finding.formErrors['name2']"
                        class="validation-error mt-1">{{finding.formErrors['name2']}}</span>

                    <div class="row">
                        <div class="col-6">
                            <app-input-text label="Deed Type" [fc]="finding.deedTypeFC"
                                [disabled]="!finding.editable || locked">
                            </app-input-text>
                            <span *ngIf="finding.formErrors && finding.formErrors['deedType']"
                                class="validation-error mt-1">{{finding.formErrors['deedType']}}</span>

                            <app-input-enum-and-text [enumValues]="locationTypes" [fc]="finding.locationTypeFC"
                                [textFC]="finding.locationFC" [disabled]="!finding.editable || locked">
                            </app-input-enum-and-text>
                            <span *ngIf="finding.formErrors && finding.formErrors['location']"
                                class="validation-error mt-1">{{finding.formErrors['location']}}</span>
                        </div>
                        <div class="col-6">
                            <app-input-currency [label]="type.amount_label" [fc]="finding.amountFC"
                                [disabled]="!finding.editable || locked">
                            </app-input-currency>
                            <span *ngIf="finding.formErrors && finding.formErrors['amount']"
                                class="validation-error mt-1">{{finding.formErrors['amount']}}</span>

                            <app-input-text label="Life Estate" [fc]="finding.lifeEstateFC"
                                [disabled]="!finding.editable || locked">
                            </app-input-text>
                            <span *ngIf="finding.formErrors && finding.formErrors['lifeEstate']"
                                class="validation-error mt-1">{{finding.formErrors['lifeEstate']}}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <app-input-date label="Dated" [fc]="finding.date1FC"
                                [disabled]="!finding.editable || locked">
                            </app-input-date>
                            <span *ngIf="finding.formErrors && finding.formErrors['date1']"
                                class="validation-error mt-1">{{finding.formErrors['date1']}}</span>

                            <app-input-date label="Recorded Date" [fc]="finding.date2FC"
                                [disabled]="!finding.editable || locked">
                            </app-input-date>
                            <span *ngIf="finding.formErrors && finding.formErrors['date2']"
                                class="validation-error mt-1">{{finding.formErrors['date2']}}</span>

                        </div>
                        <div class="col-6">
                            <app-input-text label="Assumed Mortgage" [fc]="finding.assumedMortgageFC"
                                [disabled]="!finding.editable || locked">
                            </app-input-text>
                            <span *ngIf="finding.formErrors && finding.formErrors['assumedMortgage']"
                                class="validation-error mt-1">{{finding.formErrors['assumedMortgage']}}</span>

                        </div>
                    </div>

                    <div *ngIf="finding.findingNotesFC.value == null && !locked" class="row">
                        <div class="col">
                            <app-button class="mx-1" label="Add Notes" [clickFunction]="addNotes.bind(this, finding)"
                                [icon]="faPlus" [small]="'true'">
                            </app-button>
                        </div>
                    </div>

                    <div *ngIf="finding.findingNotesFC.value != null" class="row">
                        <div class="col">
                            <app-input-text-area label="Notes" [fc]="finding.findingNotesFC"
                                [disabled]="!finding.editable || locked">
                            </app-input-text-area>
                            <span *ngIf="finding.formErrors && finding.formErrors['findingNotes']"
                                class="validation-error mt-1">{{finding.formErrors['findingNotes']}}</span>
                        </div>
                    </div>
                </span>

                <span *ngIf="type.id == 'MORT' || type.id == 'JL'">
                    <app-input-text [label]="type.name_1_label" [fc]="finding.name1FC"
                        [disabled]="!finding.editable || locked">
                    </app-input-text>
                    <span *ngIf="finding.formErrors && finding.formErrors['name1']"
                        class="validation-error mt-1">{{finding.formErrors['name1']}}</span>

                    <app-input-text *ngIf="type.name_2_label" [label]="type.name_2_label" [fc]="finding.name2FC"
                        [disabled]="!finding.editable || locked">
                    </app-input-text>
                    <span *ngIf="finding.formErrors && finding.formErrors['name2']"
                        class="validation-error mt-1">{{finding.formErrors['name2']}}</span>

                    <div class="row">
                        <div class="col-6">
                            <app-input-enum-and-text [enumValues]="locationTypes" [fc]="finding.locationTypeFC"
                                [textFC]="finding.locationFC" [disabled]="!finding.editable || locked">
                            </app-input-enum-and-text>
                            <span *ngIf="finding.formErrors && finding.formErrors['location']"
                                class="validation-error mt-1">{{finding.formErrors['location']}}</span>

                        </div>
                        <div class="col-6">
                            <app-input-date [label]="type.date_1_label" [fc]="finding.date1FC"
                                [disabled]="!finding.editable || locked">
                            </app-input-date>
                            <span *ngIf="finding.formErrors && finding.formErrors['amount']"
                                class="validation-error mt-1">{{finding.formErrors['date1']}}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <app-input-currency [label]="type.amount_label" [fc]="finding.amountFC"
                                [disabled]="!finding.editable || locked">
                            </app-input-currency>
                            <span *ngIf="finding.formErrors && finding.formErrors['amount']"
                                class="validation-error mt-1">{{finding.formErrors['amount']}}</span>
                        </div>
                        <div class="col-6">
                            <app-input-date [label]="type.date_2_label" [fc]="finding.date2FC"
                                [disabled]="!finding.editable || locked"></app-input-date>
                            <span *ngIf="finding.formErrors && finding.formErrors['date2']"
                                class="validation-error mt-1">{{finding.formErrors['date2']}}</span>
                        </div>
                    </div>
                </span>


                <span *ngIf="type.id == 'SURR'">
                    <app-input-text [label]="type.name_1_label" [fc]="finding.name1FC"
                        [disabled]="!finding.editable || locked">
                    </app-input-text>
                    <span *ngIf="finding.formErrors && finding.formErrors['name1']"
                        class="validation-error mt-1">{{finding.formErrors['name1']}}</span>

                    <app-input-text *ngIf="type.name_2_label" [label]="type.name_2_label" [fc]="finding.name2FC"
                        [disabled]="!finding.editable || locked">
                    </app-input-text>
                    <span *ngIf="finding.formErrors && finding.formErrors['name2']"
                        class="validation-error mt-1">{{finding.formErrors['name2']}}</span>

                    <div class="row">
                        <div class="col-6">
                            <app-input-enum-and-text [enumValues]="locationTypes" [fc]="finding.locationTypeFC"
                                [textFC]="finding.locationFC" [disabled]="!finding.editable || locked">
                            </app-input-enum-and-text>
                            <span *ngIf="finding.formErrors && finding.formErrors['location']"
                                class="validation-error mt-1">{{finding.formErrors['location']}}</span>

                        </div>
                        <div class="col-6">
                        </div>
                    </div>
                    <app-input-date [label]="type.date_1_label" [fc]="finding.date1FC"
                        [disabled]="!finding.editable || locked">
                    </app-input-date>
                    <span *ngIf="finding.formErrors && finding.formErrors['date1']"
                        class="validation-error mt-1">{{finding.formErrors['date1']}}</span>

                </span>
            </span>
        </span>
    </span>

    <div *ngIf="error" class="row pb-1 pt-3">
        <div class="col" align="center">
            {{error}}
        </div>
    </div>

    <div *ngIf="anyError" class="row mt-3 validation-error-banner py-2">
        <div class="col-12" align="center">
            <fa-icon [icon]="faExclamationCircle"></fa-icon>
            Please address errors above and re-submit.
        </div>
    </div>

    <div class="row mt-3">
        <div class="col" align="center">

            <app-button *ngIf="locked" label="Back" [clickFunction]="back.bind(this)" [icon]="faChevronLeft">
            </app-button>
            <app-button *ngIf="!locked" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
            </app-button>

            <app-button *ngIf="!locked" label="Save For Later" [clickFunction]="saveForLater.bind(this)"
                [icon]="faSave">
            </app-button>

            <app-button label="Preview PDF" [clickFunction]="previewPDF.bind(this)" [icon]="faFilePdf">
            </app-button>

            <app-button *ngIf="!locked" [buttonType]="ButtonType.save" label="Finish"
                [clickFunction]="finish.bind(this)" [icon]="faFlagCheckered" [buttonType]="ButtonType.save">
            </app-button>
            <app-button *ngIf="locked" label="Unlock" [clickFunction]="unlock.bind(this)" [icon]="faUnlock">
            </app-button>
        </div>
    </div>

</div>