import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { switchMap, tap } from 'rxjs/operators';
import { ButtonType } from '../button/button.component';
import { TaxSearchService } from '../tax-search.service';

@Component({
    selector: 'app-tax-profile-edit',
    templateUrl: './tax-profile-edit.component.html',
})
export class TaxProfileEditComponent implements OnInit {
    ButtonType = ButtonType
    faExclamationCircle = faExclamationCircle
    formErrors = {}

    constructor(
        private activatedRoute: ActivatedRoute,
        private taxSearchService: TaxSearchService,
        private router: Router,
    ) { }

    ngOnInit() {
    }
    orderUuid
    order_workflow_id
    data$ = this.activatedRoute.queryParams.pipe(
        switchMap((params) => {
            //console.log("params", params)
            this.orderUuid = params.orderUuid
            this.order_workflow_id = params.workflowUuid
            return this.taxSearchService.getTaxSearchMuniDataForEdit(params.workflowUuid)
        }),
        tap((response) => {
            //console.log("data", response)
            response.order_workflow_id = this.order_workflow_id
            for (var i = 0; i < response.result.length; ++i) {
                this.formErrors[response.result[i].profile_type] = {}
                response.result[i].address_fc = new UntypedFormControl(response.result[i].address)
                response.result[i].tax_year_fc = new UntypedFormControl(response.result[i].tax_year)
                response.result[i].tax_period_fc = new UntypedFormControl(response.result[i].tax_period)
                response.result[i].lien_date_fc = new UntypedFormControl(response.result[i].lien_date)
                response.result[i].fiscal_start_date_fc = new UntypedFormControl(response.result[i].fiscal_start_date ? formatDate(response.result[i].fiscal_start_date, 'yyyy-MM-dd', 'en') : null)
                response.result[i].fiscal_end_date_fc = new UntypedFormControl(response.result[i].fiscal_end_date ? formatDate(response.result[i].fiscal_end_date, 'yyyy-MM-dd', 'en') : null)
                response.result[i].due_date_1_fc = new UntypedFormControl(response.result[i].due_date_1 ? formatDate(response.result[i].due_date_1, 'yyyy-MM-dd', 'en') : null)
                response.result[i].due_date_2_fc = new UntypedFormControl(response.result[i].due_date_2 ? formatDate(response.result[i].due_date_2, 'yyyy-MM-dd', 'en') : null)
                response.result[i].due_date_3_fc = new UntypedFormControl(response.result[i].due_date_3 ? formatDate(response.result[i].due_date_3, 'yyyy-MM-dd', 'en') : null)
                response.result[i].due_date_4_fc = new UntypedFormControl(response.result[i].due_date_4 ? formatDate(response.result[i].due_date_4, 'yyyy-MM-dd', 'en') : null)
                response.result[i].refresh_date_fc = new UntypedFormControl(response.result[i].refresh_date ? formatDate(response.result[i].refresh_date, 'yyyy-MM-dd', 'en') : null)
            }
        })
    )

    saveError = null
    anyError = false
    isValid(response) {
        this.anyError = false

        for (var i = 0; i < response.result.length; ++i) {
            if (response.result[i].address_fc.value && response.result[i].address_fc.value.length > 255) {
                this.anyError = true
                this.formErrors[response.result[i].profile_type]["tax_year"] = "Tax year must be 255 characters or fewer."
            } else {
                delete this.formErrors[response.result[i].profile_type]["tax_year"]
            }

            if (response.result[i].tax_year_fc.value && response.result[i].tax_year_fc.value.length > 10) {
                this.anyError = true
                this.formErrors[response.result[i].profile_type]["tax_year"] = "Tax year must be 10 characters or fewer."
            } else {
                delete this.formErrors[response.result[i].profile_type]["tax_year"]
            }

            if (response.result[i].tax_period_fc.value && response.result[i].tax_period_fc.value.length > 10) {
                this.anyError = true
                this.formErrors[response.result[i].profile_type]["tax_period"] = "Tax period must be 10 characters or fewer."
            } else {
                delete this.formErrors[response.result[i].profile_type]["tax_period"]
            }

            if (response.result[i].lien_date_fc.value && response.result[i].lien_date_fc.value.length > 10) {
                this.anyError = true
                this.formErrors[response.result[i].profile_type]["lien_date"] = "Lien Date must be 10 characters or fewer."
            } else {
                delete this.formErrors[response.result[i].profile_type]["lien_date"]
            }

            [
                "fiscal_start_date_fc",
                "fiscal_end_date_fc",
                "due_date_1_fc",
                "due_date_2_fc",
                "due_date_3_fc",
                "due_date_4_fc",
                "refresh_date_fc",
            ].forEach((item) => {
                if (response.result[i][item].value == "") {
                    response.result[i][item].value = null
                }
            })

        }

        return !this.anyError
    }

    save(response) {
        if (!this.isValid(response)) {
            return
        }

        for (var i = 0; i < response.result.length; ++i) {
            response.result[i].address = response.result[i].address_fc.value
            response.result[i].tax_year = response.result[i].tax_year_fc.value
            response.result[i].tax_period = response.result[i].tax_period_fc.value
            response.result[i].lien_date = response.result[i].lien_date_fc.value
            response.result[i].fiscal_start_date = response.result[i].fiscal_start_date_fc.value
            response.result[i].fiscal_end_date = response.result[i].fiscal_end_date_fc.value
            response.result[i].due_date_1 = response.result[i].due_date_1_fc.value
            response.result[i].due_date_2 = response.result[i].due_date_2_fc.value
            response.result[i].due_date_3 = response.result[i].due_date_3_fc.value
            response.result[i].due_date_4 = response.result[i].due_date_4_fc.value
            response.result[i].refresh_date = response.result[i].refresh_date_fc.value
        }
        this.taxSearchService.saveTaxSearchMuniData(response).subscribe((response2) => {
            //console.log("save response", response2)
            if (response2.success) {
                this.cancel()
                return
            }
            console.log(response2)
            this.saveError = response2.message
            this.anyError = true
        })
    }

    cancel() {
        this.router.navigate(["/tax-search"], { queryParams: { orderUuid: this.orderUuid, orderWorkflowUuid: this.order_workflow_id } })
    }

}
