import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { OrderService } from '../order.service';
import { ThemeService } from '../theme.service';
import { faUpload, faBan, faBullseye } from '@fortawesome/free-solid-svg-icons';
import { DocumentsService } from '../documents.service';
import { UntypedFormControl } from '@angular/forms';
import { HttpEventType, HttpRequest, HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrderCreateButtonsComponent } from '../order-create-buttons/order-create-buttons.component';
import { ChargebeeService } from '../chargebee.service';

@Component({
    selector: 'app-order-documents',
    templateUrl: './order-documents.component.html',
})
export class OrderDocumentsComponent implements OnInit {
    faUpload = faUpload;
    faBan = faBan;

    initialized = false;
    cacheOrder;
    documentTypes;
    fileFormControl = new UntypedFormControl();
    descriptionFormControl = new UntypedFormControl();
    fileTypeFormControl = new UntypedFormControl();
    currentSingleUploadFile = null;
    error;
    documentTypeToDisplay = {};

    @ViewChild(OrderCreateButtonsComponent) private childButtons: OrderCreateButtonsComponent;

    constructor(
        private _themeService: ThemeService,
        private _orderService: OrderService,
        private documentsService: DocumentsService,
        private http: HttpClient,
        private chargebeeService: ChargebeeService
    ) { }

    is_agent = false
    ngOnInit() {
        this.chargebeeService.getPlan().subscribe((response) => {
            if(response.plan.includes('CLIENT')) {
                this.is_agent = true
            } else {
                this.is_agent = false
            }
        })

        this.documentsService.getDocumentTypes().subscribe((response) => {
            //console.log("document types: ", response);
            this.documentTypes = [];
            for (var i = 0; i < response.result.length; ++i) {
                this.documentTypeToDisplay[response.result[i].id] = response.result[i].name;
                if (response.result[i].display_to_agent == "Y") {
                    this.documentTypes.push(response.result[i]);
                }
            }
            this.loadOrderCache();
            this.initialized = true;
        });
    }

    validator() {
        if (this.formHasValue()) {
            return "Please upload the document or cancel before proceeding.";
        }
    }

    loadOrderCache() {
        var dataItem = localStorage.getItem("cached_order_draft");
        if (!dataItem) {
            this.cacheOrder = {};
            return;
        }
        this.cacheOrder = JSON.parse(dataItem);
        //console.log("loading cache order", this.cacheOrder);
        if (!this.cacheOrder) {
            this.cacheOrder = {};
            return;
        }
    }

    saveOrderCache() {
        //console.log("saving cache order", this.cacheOrder);
        localStorage.setItem("cached_order_draft", JSON.stringify(this.cacheOrder));
    }

    get themeService() {
        return this._themeService;
    }

    get orderService() {
        return this._orderService;
    }

    handleFileInput(files) {
        this.currentSingleUploadFile = files[0];
        if (!(<HTMLInputElement>document.getElementById("newfile_description")).value) {
            (<HTMLInputElement>document.getElementById("newfile_description")).value = this.currentSingleUploadFile.name;
        }
    }
    uploadClicked = false;
    percentDone = null;
    uploadStatus = "Uploading";

    formHasValue() {
        return this.currentSingleUploadFile
            || this.descriptionFormControl.value
            || (this.fileTypeFormControl.value && this.fileTypeFormControl.value != -1);
    }

    upload() {
        if (this.uploadClicked) {
            this.error = "Upload has already been clicked.";
            return;
        }

        if (!this.fileTypeFormControl.value) {
            this.error = "Please select a document name.";
            return;
        }

        if (this.currentSingleUploadFile == null) {
            this.error = "Please select a file to attach."
            return;
        }

        this.uploadStatus = "Uploading";

        this.uploadClicked = true;
        const status: { [key: string]: { progress: Observable<number> } } = {};

        // create a new multipart-form for every file
        const formData: FormData = new FormData();
        formData.append('fileKey', this.currentSingleUploadFile, this.currentSingleUploadFile.name);
        if (this.cacheOrder.uuid) {
            formData.append("order_uuid", this.cacheOrder.uuid);
        }
        formData.append("document_type", (<HTMLInputElement>document.getElementById("newfile_type")).value);
        formData.append("description", (<HTMLInputElement>document.getElementById("newfile_description")).value);
        formData.append("file_name", this.currentSingleUploadFile.name);
        formData.append("status", 'A');

        var url = "api/document";
        const req = new HttpRequest('PUT', url, formData, {
            reportProgress: true
        });

        const progress = new BehaviorSubject<number>(0);
        this.percentDone = 0;
        this.http.request(req).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {

                this.percentDone = Math.round(100 * event.loaded / event.total);
                if (this.percentDone == 100) {
                    this.uploadStatus = "Processing";
                }
                //console.log("progress report", this.percentDone);
                progress.next(this.percentDone);
            } else if (event instanceof HttpResponse) {
                this.error = null;
                progress.complete();
                this.percentDone = null;
                //console.log("request complete");
                //console.log(event);

                if ((<any>event.body).success) {
                    if (!this.cacheOrder.attached_documents) {
                        this.cacheOrder.attached_documents = [];
                    }
                    this.cacheOrder.attached_documents.push({
                        uuid: (<any>event.body).uuid,
                        "description": (<HTMLInputElement>document.getElementById("newfile_description")).value,
                        status: 'A',
                        "filename": this.currentSingleUploadFile.name,
                        "document_type": (<HTMLInputElement>document.getElementById("newfile_type")).value,
                        "created": new Date().getTime(),
                        "mime_type": (<any>event.body).mime_type,
                        "file_size": (<any>event.body).file_size,
                    });
                    this.saveOrderCache();
                    this.clearForm();
                } else {
                    this.error = "File could not be uploaded. " + (<any>event.body).message;
                    this.uploadClicked = false;
                }
            }
        });

        // Save every progress-observable in a map of all observables
        status[this.currentSingleUploadFile.name] = {
            progress: progress.asObservable()
        };

        // return the map of progress.observables
        progress.asObservable().subscribe((result) => {
            console.log("progress:", result);
        })
        return status;
    }

    clearForm() {
        this.fileTypeFormControl.setValue(null);
        this.descriptionFormControl.setValue(null);
        this.fileFormControl.setValue(null);
        this.currentSingleUploadFile = null;
        this.uploadClicked = false;
        this.error = null;

        this.childButtons.error = null;
    }

    download(doc) {
        this.documentsService.download(doc.uuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body]);
            const url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            link.download = doc.filename;
            link.click();
        });
    }

    delete(doc) {
        this.documentsService.delete(this.cacheOrder.uuid, doc.uuid).subscribe((response) => {
            for (var i = 0; i < this.cacheOrder.attached_documents.length; ++i) {
                if (this.cacheOrder.attached_documents[i].uuid == doc.uuid) {
                    this.cacheOrder.attached_documents.splice(i, 1);
                    break;
                }
            }
            this.saveOrderCache();
            this.ngOnInit();
        });
    }
}
