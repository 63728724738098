import { Component, OnInit, ViewChild } from '@angular/core';
import { ThemeService } from '../theme.service';
import { JurisdictionService } from '../jurisdiction.service';
import { AddressService } from '../address.service';
import { TitleCasePipe } from '@angular/common';
import { NumberFormatPipe } from '../number-format.pipe';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { faSearch, faBan, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ResearcherToolsResultsComponent } from '../researcher-tools-results/researcher-tools-results.component';

@Component({
    selector: 'app-researcher-tools-advanced',
    templateUrl: './researcher-tools-advanced.component.html',
})
export class ResearcherToolsAdvancedComponent implements OnInit {
    @ViewChild('countySearchInput') countySearchInput;
    @ViewChild('municipalitySearchInput') municipalitySearchInput;
    @ViewChild(ResearcherToolsResultsComponent) results;

    initialized = false;
    stateFC = new UntypedFormControl("New York");
    countyFC = new UntypedFormControl();
    municipalityFC = new UntypedFormControl();
    sblFC = new UntypedFormControl();
    sblMatchTypeFC = new UntypedFormControl("Is");
    ownerFC = new UntypedFormControl();
    ownerMatchTypeFC = new UntypedFormControl("Is");
    streetNumberFC = new UntypedFormControl();
    streetNumberMatchTypeFC = new UntypedFormControl("Is");
    streetNameFC = new UntypedFormControl();
    streetNameMatchTypeFC = new UntypedFormControl("Is");
    faSearch = faSearch;
    faBan = faBan;
    faSpinner = faSpinner
    processing = false;

    error;

    searchResults;

    matchTypeValues = [
        "Is",
        "Contains"
    ]

    constructor(
        private _themeService: ThemeService,
        private _jurisdictionService: JurisdictionService,
        private _addressService: AddressService,
        private titlecase: TitleCasePipe,
        private numberFormat: NumberFormatPipe,
        private router: Router,
    ) { }

    ngOnInit() {
        //set background color f7f8f9
        document.body.style.backgroundColor = "#f7f8f9"
        document.body.style.backgroundImage = null

        this._addressService.selectedAddress = null;
        this._jurisdictionService.getJurisdictionData().subscribe((response) => {
            //console.log(response);
            this._jurisdictionService.setJurisdictionData(response);
            this.initialized = true;
        });
    }

    get themeService() {
        return this._themeService;
    }

    get jurisdictionService() {
        return this._jurisdictionService;
    }

    selectedCounty;
    selectedMunicipality;

    countySelected(item) {
        this.selectedCounty = item;
        this.municipalitySearchInput.nextSearchTerm(this.municipalityFC.value);
    }

    municipalitySelected(item) {
        this.selectedMunicipality = item;
    }

    searchMunicipality(term) {
        return this._jurisdictionService.searchMunicipality(this.countyFC.value, term);
    }

    doSearch() {
        this.processing = true;

        var missingFieldCount = 0;
        if (!this.sblFC.value) {
            ++missingFieldCount;
        }
        if (!this.ownerFC.value) {
            ++missingFieldCount;
        }
        if (!this.streetNumberFC.value) {
            ++missingFieldCount;
        }
        if (!this.streetNameFC.value) {
            ++missingFieldCount;
        }
        //console.log("mfc", missingFieldCount);
        if (missingFieldCount == 4) {
            this.error = "At least one must be specified: tax map id, owner name, street # or street name.";
            this.processing = false;
            return;
        }

        //console.log("owner match type", this.ownerMatchTypeFC.value);

        this.error = null;
        var searchObject: any = {
            mapId: this.sblFC.value,
            mapIdSearchType: this.sblMatchTypeFC.value,
            owner: this.ownerFC.value,
            ownerSearchType: this.ownerMatchTypeFC.value,
            streetNumber: this.streetNumberFC.value,
            streetNumberSearchType: this.streetNumberMatchTypeFC.value,
            streetName: this.streetNameFC.value,
            streetNameSearchType: this.streetNameMatchTypeFC.value,
        };

        if (this.selectedMunicipality) {
            searchObject.city = this.selectedMunicipality.swis;
        } else {
            searchObject.county = this.selectedCounty.swis;
        }

        this._addressService.advancedSearch(searchObject).subscribe(response => {
            this.processing = false;
            if (response.success != undefined && !response.success) {
                this.error = response.message;
            } else {
                this.searchResults = response;
                this.error = null;
                setTimeout(function () {
                    //window.scrollTo(0, document.getElementById("searchResultsSpan").offsetTop);
                }, 0);
            }
        });
    }

    getOwnerString(searchResult) {
        var str = searchResult.owner_name_1;
        if (searchResult.owner_name_2 && searchResult.owner_name_2.trim() != "") {
            str += ", " + searchResult.owner_name_2;
        }
        return str;
    }

    addressClick(item) {
        var currentCity = this._jurisdictionService.getJurisdictionByRPCode(item.county_number_code);
        this._jurisdictionService.getTaxInformation(currentCity.state_rp_code).subscribe(response3 => {
            this._addressService.taxInformation = response3;
            this._jurisdictionService.getParcelInformation(currentCity.state_rp_code).subscribe(response4 => {
                this._addressService.parcelInformation = response4;
                this._addressService.setSelectedAddress(item);
                this.results.setPropInfo();
                setTimeout(function () {
                    window.scrollTo(0, document.getElementById("propDesc").offsetTop);
                }, 0);
            });
        });
    }

    clear() {
        this.error = null;
        this.countySearchInput.reset();
        this.municipalitySearchInput.reset();
        this._addressService.selectedAddress = null;
        this.searchResults = null;
        this.sblFC.setValue(null);
        this.sblMatchTypeFC = new UntypedFormControl("Is");
        this.ownerFC.setValue(null);
        this.ownerMatchTypeFC = new UntypedFormControl("Is");
        this.streetNumberFC.setValue(null);
        this.streetNumberMatchTypeFC = new UntypedFormControl("Is");
        this.streetNameFC.setValue(null);
        this.streetNameMatchTypeFC = new UntypedFormControl("Is");
    }
}
