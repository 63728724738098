import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { faBan, faKey } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../auth.service';
import { ButtonComponent, ButtonType } from '../button/button.component';
import { SnackRouteService } from '../snack-route.service';
import { UserService } from '../user.service';

@Component({
    selector: 'app-user-reset-password',
    templateUrl: './user-reset-password.component.html',
    styleUrls: ['./user-reset-password.component.css']
})
export class UserResetPasswordComponent implements OnInit {
    ButtonType = ButtonType
    changePasswordFC = new UntypedFormControl();
    confirmPasswordFC = new UntypedFormControl();
    passwordError = null;
    faKey = faKey
    faBan = faBan
    @ViewChildren(ButtonComponent) buttonComponents: QueryList<ButtonComponent>;

    constructor(
        private router: Router,
        private userService: UserService,
        private authService: AuthService,
        private snackRoute: SnackRouteService,
    ) { }

    ngOnInit() {
    }

    changePasswordCancel() {
        this.router.navigate(['/user'], { queryParams: { id: this.authService.userId } });
    }

    setPassword() {
        var changePasswordButton;
        this.buttonComponents.forEach(button => {
            if (button.label == "Change Password") {
                changePasswordButton = button;
            }
        });

        if (changePasswordButton.processing) {
            console.log("change password button already clicked");
            return;
        }
        this.passwordError = null;

        var passwordCheck: any = this.userService.passwordCheckAndSum(this.changePasswordFC.value, this.confirmPasswordFC.value, false);
        if (passwordCheck.error) {
            this.passwordError = passwordCheck.error;
            return;
        }

        changePasswordButton.processing = true;
        this.userService.changePassword(this.authService.userId, passwordCheck.hash).subscribe((response) => {
            changePasswordButton.processing = false;
            if (response.success) {
                this.changePasswordFC.setValue(null);
                this.confirmPasswordFC.setValue(null);
                this.snackRoute.snack("Password Saved")
            } else {
                this.passwordError = response.message;
            }
        });
    }

}
