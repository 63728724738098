import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from './services/error.service';

/**
 * Default error handler for application. This error handler deals with both 'inside' and 'outside' errors. Inside 
 * errors are generated within the application, while outside errors are errors returned from HTTP calls to external
 * services.
 *
 * There is very little standardization around how external errors are configured, but they generally contain the 
 * following properties:
 * 
 *     status (or code): The HTTP status code.
 *     name:             Class name of the error object.
 *     message:          Explanation message.  
 *
 * Internal errors are usually contained in a standard Error object that will have the following properties:
 *     name:            Class name of the error object.
 *     message:         Explanation message.
 *     fileName:        The file containing the error.
 *     lineNumber:      The line number in code.
 *     columnNumber:    The column in code where the error occurred.
 *     stack:           The stack trace. 
 *     
 * Error page life-cycle will not be initiated if it is not run directly from the zone. This behavior is not ordinary,
 * but the router must be called from the zone for the error page life-cycle to initiate. Otherwise the page component
 * will be displayed, but no life-cycle hooks will be executed.
 */
@Injectable()
export class DefaultErrorHandler implements ErrorHandler 
{
    constructor(
        private injector: Injector, 
        private errorService: ErrorService,
        private zone: NgZone
    ){
        //Nothing to do
    }
    
    handleError(error: any): void
    {
        console.error(`DefaultErrorHandler handling error: ${error.name} - ${error.message}, ${error}`);
        this.errorService.setUnhandledError(error);

        // Navigate to Error Page, avoiding infinite loop
        const router = this.injector.get(Router);
        if(!router.url.startsWith('/error'))
        {
            this.zone.run( () => router.navigate(['/error']))
        }
    }
}