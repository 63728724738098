import { Component, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export enum InputDateFormat {
    verticalLabel,
    none,
}

@Component({
    selector: 'app-input-date',
    templateUrl: './input-date.component.html',
    providers: [DatePipe],
    styleUrls: ['./input-date.component.scss']
})
export class InputDateComponent {
    InputDateFormat = InputDateFormat
    @Input() label;
    @Input() fc;
    @Input() change;
    @Input() disabled;
    @Input() format: InputDateFormat = InputDateFormat.verticalLabel;
    @Input() tooltip
    faInfoCircle = faInfoCircle

    constructor(
    ) {
    }

    doChange() {
        if (this.change) {
            this.change()
        }
    }
}
