<div *ngIf="userData$ | async as userData" class="container-fluid pt-2">
    <div class="row">
        <div class="col-2 d-none d-md-block">
        </div>
        <div class="col-12 col-md-8">

            <div class="row">
                <div class="col bold" style="font-size: 1.6em; color: #117ac9">
                    <span *ngIf="isNew">New</span>
                    <span *ngIf="!isNew">Edit</span>
                    User
                </div>
            </div>

            <div class="row">
                <div class="col-4">
                    <app-input-image label="Photo" [fc]="formControls['photo']" [unsetIcon]="faUser">
                    </app-input-image>
                    <span *ngIf="formErrors['photo']" class="validation-error mt-1">{{formErrors['photo']}}</span>
                </div>
                <div class="col-8">
                    <app-input-text label="Email Address" [fc]="formControls['email_address']" [requiredMarker]="true">
                    </app-input-text>
                    <span *ngIf="formErrors['email_address']"
                        class="validation-error mt-1">{{formErrors['email_address']}}</span>

                    <app-input-password *ngIf="isNew" label="Password" [fc]="formControls['password']"
                        [exampleText]="passwordExampleText" [exampleIcon]="faInfoCircle">
                    </app-input-password>
                    <span *ngIf="formErrors['password']" class="validation-error mt-1">{{formErrors['password']}}</span>

                    <app-input-password *ngIf="isNew" label="Confirm Password" [fc]="formControls['password2']"
                        [hideRules]="true">
                    </app-input-password>
                    <span *ngIf="formErrors['password2']"
                        class="validation-error mt-1">{{formErrors['password2']}}</span>

                    <app-input-text label="Full Name" [fc]="formControls['full_name']" [requiredMarker]="true">
                    </app-input-text>
                    <span *ngIf="formErrors['full_name']"
                        class="validation-error mt-1">{{formErrors['full_name']}}</span>

                    <app-input-text label="Location" [fc]="formControls['location']"></app-input-text>
                    <span *ngIf="formErrors['location']" class="validation-error mt-1">{{formErrors['location']}}</span>

                    <app-input-user-role label="Role" [fc]="formControls['role']" [enumValues]="availableRoles$ | async"
                        [requiredMarker]="true">
                    </app-input-user-role>
                    <span *ngIf="formErrors['role']" class="validation-error mt-1">{{formErrors['role']}}</span>

                    <app-input-text label="Job Title" [fc]="formControls['job_title']">
                    </app-input-text>
                    <span *ngIf="formErrors['job_title']"
                        class="validation-error mt-1">{{formErrors['job_title']}}</span>

                    <app-input-text label="Department" [fc]="formControls['department']">
                    </app-input-text>
                    <span *ngIf="formErrors['department']"
                        class="validation-error mt-1">{{formErrors['department']}}</span>

                    <app-input-text label="Phone" [fc]="formControls['phone']" [change]="phoneUpdate.bind(this)"
                        [exampleText]="phoneExampleText"></app-input-text>
                    <span *ngIf="formErrors['phone']" class="validation-error mt-1">{{formErrors['phone']}}</span>

                    <app-input-enum label="Time Zone" [fc]="formControls['time_zone']"
                        [enumValues]="timezones$ | async">
                    </app-input-enum>
                    <span *ngIf="formErrors['time_zone']"
                        class="validation-error mt-1">{{formErrors['time_zone']}}</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="anyError" class="row mt-3 validation-error-banner py-2">
        <div class="col-12" align="center">
            <fa-icon [icon]="faExclamationCircle"></fa-icon>
            Please address errors above and re-submit.
        </div>
    </div>

    <div class="row pt-3 pb-2">
        <div class="col pb-2" align="center">
            <app-button [buttonType]="ButtonType.cancel" [clickFunction]="back.bind(this)">
            </app-button>
            <a *ngIf="userUuid && userUuid == currentUserId" routerLink="/user-reset-password" queryParams="{userUuid}">
                <app-button label="Change Password" [icon]="faKey">
                </app-button>
            </a>
            <app-button [buttonType]="ButtonType.save" [clickFunction]="save.bind(this)">
            </app-button>
            <app-button *ngIf="!isNew" [buttonType]="ButtonType.delete" [clickFunction]="delete.bind(this, userData)"
                class="float-right">
            </app-button>
        </div>
    </div>
</div>