<div class="container-fluid">
    <app-input-enum label="Report" [fc]="reportFC" [enumValuesNameAndId]="reports$ | async"
        includeUnsetDisplay="Select a report">
    </app-input-enum>

    <span *ngIf="entries$ | async as entries">
        <div class="row mt-3">
            <div class="col px-0">
                <table class="table table-striped table-hover" style="min-width: 100%">
                    <thead>
                        <tr style="background-color: lightgrey">
                            <th *ngFor="let column of entries.definition; let index = index">
                                <span class="sort-column-header"
                                    (click)="sortClick(entries.sortField, column.Field, entries.sortIsDescending)">
                                    {{column.Field | dbtitlecase | titlecase}}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="entries.result && entries.result.length == 0" class="table-row">
                            <td [attr.colspan]="entries.definition.length" align="center" style="font-style: italic;">No
                                results</td>
                        </tr>
                        <tr *ngIf="entries.error" class="table-row">
                            <td align="center" style="font-style: italic;">
                                {{entries.error.errno}}
                                {{entries.error.code}}
                                {{entries.error.sqlMessage}}
                            </td>
                        </tr>
                        <tr class="table-row" *ngFor="let entry of entries.result">
                            <td class="px-1" *ngFor="let column of entries.definition; let index = index" [ngStyle]="{'text-align': 
                            column.Type == 'varchar(36)' 
                            || column.Type.startsWith('decimal')
                            || column.Type.startsWith('int')
                            || column.Type.startsWith('tinyint')
                            ? 'right' : '' }">
                                <span [ngStyle]="{
                                'padding-right': 
                                column.Type == 'varchar(36)' 
                                || column.Type.startsWith('decimal')
                                || column.Type.startsWith('int')
                                || column.Type.startsWith('tinyint')
                                ? '.75rem' : '',
                                'padding-left': 
                                column.Type != 'varchar(36)' 
                                && !column.Type.startsWith('decimal')
                                && !column.Type.startsWith('int')
                                && !column.Type.startsWith('tinyint')
                                ? '.75rem' : '' 
                                    }">
                                    <span *ngIf="column.Type == 'datetime'">
                                        {{entry[column.Field] | date : 'M/d/y'}}
                                    </span>
                                    <span *ngIf="column.Type != 'datetime'">
                                        {{entry[column.Field]}}
                                    </span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr *ngIf="reportFC.value === 'DAILY_ORDERS_TODAY' || reportFC.value === 'DAILY_ORDERS_YESTERDAY'">
                            <td [attr.colspan]="entries.definition.length - 2"></td>
                            <td></td>
                            <td style="font-weight: bold;">Total: {{ footerTotal }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </span>
</div>