<span *ngIf="!initialized">
    <div class="container-fluid bordered-container-middle pt-4" [style.color]="themeService.primaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.secondaryColor">
        Initializing...
    </div>
</span>

<span *ngIf="initialized && cacheOrder.order_type != 'Quick'">
    <app-order-create-step-circles [stepProvider]="orderService"></app-order-create-step-circles>
</span>

<div *ngIf="initialized" class="container-fluid bordered-container-middle light-blue-bordered"
    [style.color]="themeService.primaryColor" [style.background-color]="themeService.tertiaryColor">
    <div class="row pt-4">
        <div class="col bold attribute-h1" align="center">
            Documents
        </div>
    </div>
    <div class="row pt-4">
        <div class="col bold attribute-h2">
            Documents Attached to Order
        </div>
    </div>
    <div *ngIf="!cacheOrder.attached_documents || cacheOrder.attached_documents.length == 0" class="row pt-4">
        <div class="col">
            No documents attached
        </div>
    </div>
    <span *ngIf="cacheOrder.attached_documents && cacheOrder.attached_documents.length > 0">
        <div class="row">
            <div class="col-1">
            </div>
            <div class="col-10 mt-4" [style.border-color]="themeService.backgroundColor"
                style="border-width: 1px; border-style: solid; border-radius: .9em;">
                <div class="row" [style.color]="themeService.textOnBackgroundColor"
                    [style.background-color]="themeService.backgroundColor" style="border-radius: .9em .9em 0 0;">
                    <div class="col-md-3 col-5">
                        Description
                    </div>
                    <div class="col-md-2 col-5">
                        Type
                    </div>
                    <div class="col-3 d-none d-md-block">
                        Filename
                    </div>
                    <div class="col-2 d-none d-md-block">
                        Created
                    </div>
                    <div class="col-2">
                        Action
                    </div>
                </div>
                <div *ngFor="let doc of cacheOrder.attached_documents" class="row">
                    <div class="col-md-3 col-5 link" (click)="download(doc)" [style.color]="themeService.linkColor">
                        {{doc.description}}
                    </div>
                    <div class="col-md-2 col-5">
                        {{documentTypeToDisplay[doc.document_type]}}
                    </div>
                    <div class="col-3 d-none d-md-block">
                        {{doc.filename}}
                    </div>
                    <div class="col-2 d-none d-md-block">
                        {{doc.created | date: 'shortDate'}}
                    </div>
                    <div class="col-2 link" [style.color]="themeService.linkColor" (click)="delete(doc)">
                        Delete
                    </div>
                </div>
            </div>
        </div>
    </span>
</div>

<div *ngIf="initialized && percentDone != null" class="container-fluid bordered-container-middle light-blue-bordered"
    [style.color]="themeService.primaryColor" [style.border-color]="themeService.secondaryColor"
    style="background-color: white;">
    <div class="row pt-4">
        <div class="col attribute-h2 bold">
            {{uploadStatus}}
        </div>
    </div>
    <div class="row py-2 ">
        <div class="col">
            <div class="relative" style="height: 1.6em;">
                <div [ngStyle]="{ 'background-color': 'lightblue', 'width': percentDone + '%'}"
                    style="height: 1.6em; position: absolute; left: 0; top: 0;">
                </div>
                <div [ngStyle]="{ 'width':  '100%'}"
                    style="height: 1.6em; position: absolute; left: 0; top: 0; font-weight: bold;text-align: center;">
                    {{percentDone}}%
                </div>
                <div
                    style="width: 100%; border-style: solid; border-width: 1px; border-color: black; height: 1.6em; position: absolute; left: 0; top: 0;">
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="initialized" class="container-fluid bordered-container-middle light-blue-bordered"
    [style.color]="themeService.primaryColor" [style.background-color]="themeService.tertiaryColor">
    <div class="row pt-4">
        <div class="col bold attribute-h2">
            Add Document
        </div>
    </div>

    <div class="row">
        <div class="col-2">
        </div>
        <div class="col-8">

            <div class="row pt-2" id="addTitle">
                <div class="col" style="font-weight: bold;">
                    Document Name
                </div>
            </div>
            <div class="row">
                <div class="col" style="font-weight: bold;">
                    <select class="input-style" id="newfile_type" [formControl]="fileTypeFormControl">
                        <option *ngFor="let documentType of documentTypes; let i = index" value="{{documentType.id}}">
                            {{documentType.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col" style="font-weight: bold;">
                    Description
                </div>
            </div>
            <div class="row">
                <div class="col" style="font-weight: bold;">
                    <input class="input-style" type="text" id="newfile_description"
                        [formControl]="descriptionFormControl" />
                </div>
            </div>
            <div class="row mt-3">
                <div class="col" style="font-weight: bold;">
                    File
                </div>
            </div>
            <div class="row">
                <div class="col" style="font-weight: bold;">
                    <div class="p-2"
                        style="border-style: solid; border-width: 1px; border-color: lightgrey; border-radius: .9em;">
                        <input type="file" [formControl]="fileFormControl" id="file"
                            (change)="handleFileInput($event.target.files)">
                    </div>
                </div>
            </div>
            <div *ngIf="error" class="row mt-3">
                <div class="col" style="font-weight: bold;">
                    {{error}}
                </div>
            </div>
            <div class="row">
                <div class="col mt-2 mb-2" style="font-weight: bold;">
                    <app-button [icon]="faUpload" label="Upload Attachment" [clickFunction]="upload.bind(this)">
                    </app-button>
                    <app-button *ngIf="formHasValue()" class="ml-2" [icon]="faBan" label="Cancel Upload"
                        [clickFunction]="clearForm.bind(this)">
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-order-create-buttons [validator]="validator.bind(this)" [isAgent]="is_agent" #theButtons></app-order-create-buttons>