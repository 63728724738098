import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard-order-all-open',
    templateUrl: './dashboard-order-all-open.component.html',
})
export class DashboardOrderAllOpenComponent implements OnInit {
    filter = {
        gate: "any",
        comparisons: [
            {
                field: "order_status",
                operator: "is",
                matchValue: "I"
            },
            {
                field: "order_status",
                operator: "is",
                matchValue: "M"
            },
            {
                field: "order_status",
                operator: "is",
                matchValue: "Q"
            },
            {
                field: "order_status",
                operator: "is",
                matchValue: "S"
            },
            {
                field: "order_status",
                operator: "is",
                matchValue: "N"
            }
        ],
        filters: [
            {
                gate: "all",
                comparisons: [
                    {
                        field: "order_status",
                        operator: "is",
                        matchValue: "R"
                    },
                    {
                        field: "provider_created",
                        operator: "is",
                        matchValue: "1"
                    },
                ]
            },

        ]
    }

    constructor() { }

    ngOnInit() {
    }

}
