import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-internal-notes',
  templateUrl: './internal-notes.component.html',
  styleUrl: './internal-notes.component.css'
})
export class InternalNotesComponent 
{
  @Input() orderUuid: string;
  @Input() internalNotes: string;

  internalNotesDialogVisable: boolean = false;
  internalNotesForm: FormGroup
  
  constructor
  (
    private fb: FormBuilder,
    private orderService: OrderService
  ) {}

  showInternalNotesDialog()
  {
    this.internalNotesDialogVisable = true;
    this.initInternalNotesForm();
  }

  hideInternalNotesDialog()
  {
    this.internalNotesDialogVisable = false;
  }

  initInternalNotesForm()
  {
    this.internalNotesForm = this.fb.group({
      internalNotes: [this.internalNotes]
    })
  }

  saveNotes()
  {
    this.internalNotes = this.internalNotesForm.get('internalNotes').value
    this.orderService.updateLongNotes(this.orderUuid, this.internalNotes).subscribe({
      next: (response) => {
        // console.log('internal notes saved with data: ' + this.internalNotes);
        this.hideInternalNotesDialog();
      },
      error: (error) => {
        console.error('An error occured while saving internal notes')
      }
    })
  }
}
