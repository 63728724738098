import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of} from "rxjs"; 
import { SysMunicipality } from "../model/sys-municipality.model";
import { SYSMUNICIPALITY } from "../mock/sys-municipality-mock";
import { SysTaxType } from "../model/sys-tax-type.model";
import { SYSTAXTYPE } from "../mock/sys-tax-type-mock";

@Injectable({
    providedIn: 'root'
})

export class MunicipalitySeviceMock {
    
    constructor(private http: HttpClient) 
    { 
        // Nothing to do
    }

    //Update municipality
    updateMunicipality(municipality: SysMunicipality): Observable<SysMunicipality> {
        let result = SYSMUNICIPALITY.find(x => x.realPropertyCode === municipality.realPropertyCode);
        result.state = municipality.state;
        result.countyId = municipality.countyId;
        result.municipalityName = municipality.municipalityName;
        result.type = municipality.type;
        result.parentId = municipality.parentId;
        result.childId = municipality.childId;
        result.realPropertyCode = municipality.realPropertyCode;
        result.taxTypeId = municipality.taxTypeId;
        result.address = municipality.address;
        result.taxYear = municipality.taxYear;
        result.lienDate = municipality.lienDate;
        result.fiscalStartDate = municipality.fiscalStartDate;
        result.fiscalEndDate = municipality.fiscalEndDate;
        result.dueDate1 = municipality.dueDate1;
        result.dueDate2 = municipality.dueDate2;
        result.dueDate3 = municipality.dueDate3;
        result.dueDate4 = municipality.dueDate4;
        result.refreshDate = municipality.refreshDate;
        return of(municipality);
    }

    getMunicipalities(): Observable<SysMunicipality[]> {
        return of(SYSMUNICIPALITY);
    }

    getTaxTypes(): Observable<SysTaxType[]> {
        return of(SYSTAXTYPE);
    
    }
}
