import { Component, OnInit } from '@angular/core';
import { InvoiceService } from '../invoice.service';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-invoice-history',
    templateUrl: './invoice-history.component.html',
})
export class InvoiceHistoryComponent implements OnInit {
    initialized = false
    invoice
    history
    faChevronLeft = faChevronLeft

    constructor(
        private _themeService: ThemeRebrandService,
        private invoiceService: InvoiceService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) { }

    invoiceId

    getInvoiceIdParamPromise() {
        return new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe(params => {
                this.invoiceId = params.invoiceId
                resolve(this.invoiceId)
            })
        })
    }

    ngOnInit() {
        this.getInvoiceIdParamPromise().then(() => {
            var promises = []
            promises.push(new Promise((resolve, reject) => {
                this.invoiceService.getInvoice(this.invoiceId).subscribe((response) => {
                    //console.log(response);
                    if (response.success) {
                        this.invoice = response.result[0]
                    } else {
                        console.log(response)
                    }
                    resolve(null);
                })
            }))

            promises.push(new Promise((resolve, reject) => {
                this.invoiceService.getInvoiceHistory(this.invoiceId).subscribe((response) => {
                    if (response.success) {
                        this.history = response.result;
                    } else {
                        console.log(response)
                    }
                    resolve(null)
                })
            }))
            return Promise.all(promises)
        }).then(() => {
            console.log(this.history)
            this.initialized = true;
        }).catch((err) => {
            console.log(err)
        })
    }

    get themeService() {
        return this._themeService;
    }

    back() {
        window.history.back()
    }
}
