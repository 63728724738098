import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DocumentsService } from 'src/app/documents.service';
import { iOrderDocument } from 'src/app/model/order-document.model';

@Component({
  selector: 'app-order-download-mat',
  templateUrl: './order-download-mat.component.html',
  styleUrls: ['./order-download-mat.component.css']
})
export class OrderDownloadMatComponent implements OnInit 
{
  documents: MatTableDataSource<iOrderDocument>;
  orderUuid: string;
  orderName: string;
  displayedColumns: string[] = ['description', 'documentType', 'fileName', 'created'];
  @ViewChild(MatSort, {static: true}) sort: MatSort;
    
  constructor(
    private documentsService: DocumentsService,
    private activatedRoute: ActivatedRoute
  ) 
  { 
    this.documents = new MatTableDataSource([])
  }

  downloadFile(doc: iOrderDocument)
  {
    const documentUuid = doc.guid
    if (!documentUuid)
    {
      console.warn("no document uuid was passed");
      return
    }
    this.documentsService.downloadDocument(documentUuid).subscribe(
      (response: Blob) => {
        const blob = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = doc.fileName || 'document';
        link.click();
        window.URL.revokeObjectURL(url);
      })
  }

  downloadAll()
  {
    const documents: iOrderDocument[] = this.documents.data

    documents.forEach(doc => {
      let documentUuid = doc.guid
      this.documentsService.downloadDocument(documentUuid).subscribe(
        (response: Blob) => {
          const blob = new Blob([response]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = doc.fileName || 'document';
          link.click();
        })
      
    });
  }

  ngOnInit() 
  {
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.orderUuid = params.orderUuid
    },
    (error) => {
      console.log('error getting orderUuid from route params', error)
    })
    
    this.documentsService.getFinalDocumentsByOrderUuid(this.orderUuid).subscribe(
      (response) => {
        this.documents.data = response;
        this.orderName = response[0].orderId;
        this.documents.sort = this.sort;
        // console.log(this.documents)
    },
      (error) => {
        console.warn('error while getting documents by orderUuid', error)
      })
  }

}
