<div *ngIf="initialized" class="container-fluid pt-2">

    <div class="row">
        <div class="col-2 d-none d-md-block">
        </div>
        <div class="col-12 col-md-8">

            <div class="row">
                <div class="col bold" style="font-size: 1.6em; color: #117ac9">
                    <span *ngIf="router.url == '/company-settings'">Company</span>
                    <span *ngIf="router.url == '/order-information'">Orders</span>
                </div>
            </div>
            <span *ngIf="router.url == '/company-settings'">
                <div class="row">
                    <div class="col-md">
                        <app-input-image label="Company Logo" [fc]="companyLogoFormControl"
                            [supportedTypes]="['image/jpeg']" [disabled]="isReadOnly">
                        </app-input-image>
                    </div>
                    <div class="col-md">
                        <app-input-text label="Web Address" [fc]="webAddressFormControl" [disabled]="isReadOnly"
                            exampleText="e.g. https://www.example.com">
                        </app-input-text>
                        <span *ngIf="formErrors['web_address']"
                            class="validation-error mt-1">{{formErrors['web_address']}}</span>
                        <app-input-text-area label="Address" [fc]="addressFormControl" [disabled]="isReadOnly" rows="3">
                        </app-input-text-area>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md">
                        <app-input-text label="Company Name" [fc]="companyNameFormControl" [disabled]="isReadOnly">
                        </app-input-text>
                    </div>
                    <div class="col-md">
                        <app-input-enum label="Account Owner" [fc]="accountOwnerFormControl"
                            [enumValuesNameAndId]="companyUsers" [disabled]="isReadOnly">
                        </app-input-enum>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md">
                        <app-input-text label="Phone Number" [fc]="phoneNumberFormControl"
                            [change]="phoneUpdate.bind(this)" [exampleText]="phoneExampleText" [disabled]="isReadOnly">
                        </app-input-text>
                    </div>
                    <div class="col-md">
                        <app-input-text label="Fax Number" [fc]="faxNumberFormControl" [change]="faxUpdate.bind(this)"
                            [exampleText]="phoneExampleText" [disabled]="isReadOnly">
                        </app-input-text>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <app-input-enum label="Default Time Zone" [fc]="defaultTimeZoneFormControl"
                            [enumValues]="timezones" [disabled]="isReadOnly">
                        </app-input-enum>
                    </div>
                    <div class="col">
                    </div>
                </div>

            </span>
            <span *ngIf="router.url == '/order-information'">

                <div class="row">
                    <div class="col-md">
                        <app-input-text label="Invoice Remit Address" [fc]="invoiceRemitFormControl">
                        </app-input-text>
                    </div>
                    <div class="col-md">
                        <app-input-enum label="Fiscal Year Start" [fc]="fiscalYearStartFormControl"
                            [enumValuesNameAndId]="months"></app-input-enum>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md">
                        <app-input-text label="Order ID Prefix" [fc]="orderIdPrefixFormControl">
                        </app-input-text>
                    </div>
                    <div class="col-md">
                        <app-input-text label="Next Order ID" [fc]="nextOrderIdFormControl" [prefix]="nextOrderIdPrefix"
                            [change]="setNextOrderIdPrefix()">
                        </app-input-text>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        <app-input-text label="Notify Email Address" [fc]="notifyEmailAddressFormControl">
                        </app-input-text>
                    </div>
                    <div class="col-md">
                        <app-input-enum label="Default Provider" [fc]="defaultProviderFormControl"
                            [enumValuesNameAndId]="providers" [includeUnset]="true">
                        </app-input-enum>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md">
                        <app-input-text label="Invoice Email Address" [fc]="invoiceEmailAddressFormControl">
                        </app-input-text>
                    </div>
                    <div class="col-md">
                        <app-input-text-area label="Document Delivery Email Signature"
                            [fc]="documentDeliverySignatureFC">
                        </app-input-text-area>
                    </div>
                </div>
            </span>
        </div>
    </div>

    <div *ngIf="anyError" class="row mt-3 validation-error-banner py-2">
        <div class="col-12" align="center">
            <fa-icon [icon]="faExclamationCircle"></fa-icon>
            Please address errors above and re-submit.
        </div>
    </div>

    <div class="row pt-3">
    </div>
    <app-button-group-save [save]="save.bind(this)" [cancel]="back.bind(this)"></app-button-group-save>
</div>