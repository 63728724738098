import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {
    private getCurrenciesURL = 'api/getCurrencies'
    private getDueDatesURL = 'api/getPaymentTerms'
    private getItemTypesURL = 'api/getItemTypes'
    private getInvoiceStatusesURL = 'api/getInvoiceStatuses'
    private getTotalOpenURL = 'api/invoiceTotalOpen'
    private getTotalPaidURL = 'api/invoiceTotalPaid'
    private getOpenInvoicesURL = 'api/getOpenInvoices'
    private getAllInvoicesURL = 'api/getAllInvoices'
    private getInvoiceURL = 'api/getInvoice'
    private searchInvoiceByIDURL = 'api/searchInvoiceByID'
    private addInvoiceURL = '/api/invoice'
    private configurationURL = '/api/invoiceConfiguration'
    private invoiceBalanceURL = '/api/invoiceBalance'
    private sendInvoiceURL = '/api/sendInvoice'
    private getInvoicePDFURL = "api/invoicePDF"
    private recordPaymentURL = "api/invoiceRecordPayment"
    private markAsSentURL = "api/markInvoiceAsSent"
    private markAsUnsentURL = "api/markInvoiceAsUnsent"
    private writeOffURL = "api/markInvoiceAsWrittenOff"
    private deleteInvoiceURL = "api/invoice"
    private searchInvoicesURL = "api/searchInvoices"
    private searchInvoicePaymentsURL = "api/searchInvoicePayments"
    private getInvoiceHistoryURL = "api/invoiceHistory"
    private getInvoiceLatestActivityURL = "api/invoiceLatestActivity"
    private invoiceItemTypeURL = "api/invoiceItemType"
    private invoiceItemTypeReorderURL = "api/invoiceItemTypeReorder"
    private invoiceUpdateBillToCompanyInfoURL = "api/invoiceUpdateBillToCompanyInfo"
    private invoiceGraphDataURL = "api/invoiceGraphData"

    constructor(
        private http: HttpClient,
    ) {
    }

    getCurrencies() {
        return this.http.get<any>(this.getCurrenciesURL)
    }

    getDueDates() {
        return this.http.get<any>(this.getDueDatesURL)
    }

    getItemTypes() {
        return this.http.get<any>(this.getItemTypesURL)
    }

    getInvoiceStatuses() {
        return this.http.get<any>(this.getInvoiceStatusesURL)
    }

    getTotalOpen() {
        return this.http.get<any>(this.getTotalOpenURL)
    }

    getTotalPaid() {
        return this.http.get<any>(this.getTotalPaidURL)
    }

    getOpenInvoices(sortField, direction, pageNum) {
        return this.http.get<any>(this.getOpenInvoicesURL + "/" + sortField + "/" + direction + "/" + pageNum)
    }

    getAllInvoices(sortField, direction, pageNum) {
        return this.http.get<any>(this.getAllInvoicesURL + "/" + sortField + "/" + direction + "/" + pageNum)
    }

    getInvoice(invoiceId) {
        return this.http.get<any>(this.getInvoiceURL + "/" + invoiceId)
    }

    searchInvoiceByID(searchString) {
        return this.http.get<any>(this.searchInvoiceByIDURL + "/" + searchString)
    }

    createInvoice(invoice) {
        return this.http.put<any>(this.addInvoiceURL, invoice, httpOptions)
    }

    updateInvoice(invoice) {
        return this.http.post<any>(this.addInvoiceURL, invoice, httpOptions)
    }

    getInvoiceConfiguration() {
        return this.http.get<any>(this.configurationURL)
    }

    updateInvoiceConfiguration(configuration) {
        return this.http.post<any>(this.configurationURL, configuration, httpOptions)
    }

    getInvoiceBalance(invoiceId?) {
        if (invoiceId) {
            return this.http.get<any>(this.invoiceBalanceURL + "/" + invoiceId)
        } else {
            return this.http.get<any>(this.invoiceBalanceURL)
        }
    }

    sendInvoice(invoice_id, emailParams, includePDF) {
        return this.http.post<any>(this.sendInvoiceURL + "/" + invoice_id + "/" + includePDF, emailParams, httpOptions)
    }

    getInvoicePDF(invoice_id) {
        var options: any = { observe: 'response', responseType: 'blob' };
        return this.http.get<any>(this.getInvoicePDFURL + "/" + invoice_id, options);
    }

    recordPayment(invoice_id, payment_date, amount, notes) {
        return this.http.put<any>(this.recordPaymentURL + "/" + invoice_id, {
            payment_date,
            amount,
            notes
        }, httpOptions)
    }

    markAsSent(invoice_id, sent_date) {
        return this.http.post<any>(this.markAsSentURL, { invoice_id: invoice_id, sent_date: sent_date }, httpOptions);
    }

    markAsUnsent(invoice_id) {
        return this.http.post<any>(this.markAsUnsentURL, { invoice_id: invoice_id }, httpOptions);
    }

    writeOff(invoice_id) {
        return this.http.post<any>(this.writeOffURL, { invoice_id: invoice_id }, httpOptions);
    }

    deleteInvoice(invoice_id) {
        return this.http.delete<any>(this.deleteInvoiceURL + "/" + invoice_id);
    }

    searchInvoices(searchCriteria) {
        return this.http.post<any>(this.searchInvoicesURL, searchCriteria, httpOptions)
    }

    searchInvoicePayments(searchCriteria) {
        return this.http.post<any>(this.searchInvoicePaymentsURL, searchCriteria, httpOptions)
    }

    getInvoiceHistory(invoice_id) {
        return this.http.get<any>(this.getInvoiceHistoryURL + "/" + invoice_id);
    }

    getLatestActivity(invoice_id) {
        return this.http.get<any>(this.getInvoiceLatestActivityURL + "/" + invoice_id);
    }

    addItemType(name) {
        return this.http.put<any>(this.invoiceItemTypeURL, { name: name }, httpOptions);
    }

    updateItemType(id, name) {
        return this.http.post<any>(this.invoiceItemTypeURL, { id: id, name: name }, httpOptions);
    }

    invoiceItemTypeReorder(id1, order1, id2, order2) {
        return this.http.post<any>(this.invoiceItemTypeReorderURL, { id1, order1, id2, order2 }, httpOptions);
    }

    deleteItemType(item_type_id) {
        return this.http.delete<any>(this.invoiceItemTypeURL + "/" + item_type_id);
    }

    updateBillToCompanyInfo(invoice_id, name, address, phone) {
        return this.http.post<any>(this.invoiceUpdateBillToCompanyInfoURL, { id: invoice_id, name, address, phone }, httpOptions);
    }

    getInvoiceGraphData(offset) {
        return this.http.get<any>(this.invoiceGraphDataURL + "/" + offset);
    }
}
