import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
declare var $: any;

@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
})
export class InputTextComponent implements OnInit, AfterViewInit {
    @Input() label;
    @Input() fc;
    @Input() disabled;
    @Input() change;
    @Input() keyupEnter;
    @Input() requiredMarker;
    @Input() exampleText;
    @Input() halfLabelSize;
    @Input() units;
    @Input() rowClass;
    @Input() colClass;
    @Input() prefix;
    @Input() tooltip
    @Input() placeholder

    faInfoCircle = faInfoCircle
    calcRowClass
    calcColClass

    letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    attributeIdentifier = this.randomString();

    randomString() {
        var str = "";
        for (var i = 0; i < 20; ++i) {
            str += this.letters.charAt(Math.random() * this.letters.length);
        }
        return str;
    }

    getElementById(id) {
        return document.getElementById(id);
    }

    constructor() {
        if (!this.placeholder) {
            this.placeholder = ""
        }
    }

    ngOnInit() {
        this.calcRowClass = {};
        this.calcRowClass[this.rowClass] = true;
        this.calcColClass = {};
        this.calcColClass[this.colClass] = true;
    }

    ngAfterViewInit() {
        $(function () {
            (<any>$('[data-toggle="tooltip"]')).tooltip()
        })
    }

    doChange(e) {
        if (this.change) {
            this.change(e);
        }
    }

    doEnter() {
        if (this.keyupEnter) {
            this.keyupEnter();
        }
    }
}
