import { Component, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BannerNotificationService } from '../banner-notification.service';
import { BannerNotificationType } from '../banner-notification-type';
import { ChargebeeService } from '../chargebee.service';

@Component({
    selector: 'app-banner-notification',
    templateUrl: './banner-notification.component.html',
    styleUrls: ['./banner-notification.component.css']
})
export class BannerNotificationComponent {
    types = BannerNotificationType
    faTimes = faTimes
    get notifications() {
        return this.bannerNotificationService.notifications
    }

    constructor(
        private bannerNotificationService: BannerNotificationService,
        private chargebeeService: ChargebeeService
    ) { }

    dismiss(i) {
        this.bannerNotificationService.notifications.splice(i, 1)
    }

    plan: string
    is_agent: boolean
    ngOnInit() {
        this.is_agent = false
        this.chargebeeService.getPlan().subscribe((response) => {
            this.plan = response.plan
            if(this.plan.includes('CLIENT')) {
                this.is_agent = true
            } else {
                this.is_agent = false
            }
        })
    }
}
