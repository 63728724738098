import { Component, OnInit, ViewChild } from '@angular/core';
import { AddressService } from '../address.service';
import { JurisdictionService } from '../jurisdiction.service';
import { ResearcherToolsResultsComponent } from '../researcher-tools-results/researcher-tools-results.component';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../order.service';

@Component({
    selector: 'app-order-assessment-info',
    templateUrl: './order-assessment-info.component.html',
})
export class OrderAssessmentInfoComponent implements OnInit {
    @ViewChild(ResearcherToolsResultsComponent) results;

    constructor(
        private addressService: AddressService,
        private _jurisdictionService: JurisdictionService,
        private activatedRoute: ActivatedRoute,
        private _orderService: OrderService,
    ) { }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            if (params.oid) {
                this._orderService.getOrder(params.oid).subscribe((response) => {
                    var cacheOrder = response.result;
                    this.addressService.orderCounty = cacheOrder.real_property_code;
                    this.addressService.orderSBL = cacheOrder.sbl;

                    this.addressService.searchSBL(this.addressService.orderCounty, this.addressService.orderSBL).subscribe((response) => {
                        var item = response[0];
                        this._jurisdictionService.getTaxInformation(item.county_number_code).subscribe(response3 => {
                            this.addressService.taxInformation = response3;
                            this._jurisdictionService.getParcelInformation(item.county_number_code).subscribe(response4 => {
                                this.addressService.parcelInformation = response4;
                                this.addressService.setSelectedAddress(item);
                                this.results.setPropInfo();
                            });
                        });
                    });
                });
            }
        });
    }
}
