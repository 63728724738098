import { Component } from '@angular/core';
import { AboutService } from '../services/about.service';
import { ErrorService } from '../services/error.service'
import { StringBuilder } from '../util/string-builder';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrl: './error-page.component.css'
})
export class ErrorPageComponent 
{
  // aboutData: About | null;
  errorMessage: string;
  
  constructor
  (
    private aboutService: AboutService,
    private errorService: ErrorService
  )
  {
    // this.aboutData = null;
    this.errorMessage = "";
  }

  ngOnInit(): void
  {
    // this.aboutService.getAboutData().subscribe(result => this.aboutData = result);
    this.errorMessage = this.generateErrorMessage();
  }
  
  generateErrorMessage(): string
  {
    let builder = new StringBuilder();
    let errorObject = this.errorService.getUnhandledError();
    if(errorObject.stack)
    {
      builder.append('\n\n');
      builder.append(errorObject.stack);
    }
    else
    {
      this.addNameAndMessageOnly(builder, errorObject);
    }
    return builder.toTrimString();
  }

  addNameAndMessageOnly(builder: StringBuilder, errorObject: any)
  {
    if(errorObject.name)
    {
      builder.append(errorObject.name);
    }
    if(errorObject.message)
    {
      builder.append(' - ');
      builder.append(errorObject.message);
    }
  }
}
