import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from "rxjs";
import { Md5 } from 'ts-md5';
import { ChargebeeService } from './chargebee.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { SystemService } from './system.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private http: HttpClient,
        private chargebeeService: ChargebeeService,
        private router: Router,
        private authService: AuthService,
        private systemService: SystemService,
    ) {
    }

    isAdmin() {
        return this.authService.userrole == "admin";
    }

    getAllUserRoles() {
        return this.http.get<any>("api/allUserRoles");
    }

    getAvailableRoles() {
        return this.http.get<any>("api/availableUserRoles");
    }

    getCompanies(): Observable<any> {
        return this.http.get<any>("api/companies/");
    }

    getUsersByCompany(companyId): Observable<any> {
        return this.http.get<any>("api/usersByCompany/" + companyId);
    }

    getUser(userId): Observable<any> {
        return this.http.get<any>("api/user/" + userId);
    }

    getAllUsers(): Observable<any> {
        return this.http.get<any>("api/getAllUsers");
    }

    getUserByName(username): Observable<any> {
        return this.http.get<any>("api/userByName/" + username);
    }

    updateUser(userId, fullName, photo, location, role, jobTitle, department, status, timeZone, phone): Observable<any> {
        return this.http.post<any>("api/user/", {
            id: userId,
            full_name: fullName,
            photo: photo,
            location: location,
            role: role,
            job_title: jobTitle,
            department: department,
            status: status,
            time_zone: timeZone,
            phone: phone,
        }, httpOptions);
    }

    passwordCheckAndSum(p1, p2, isOptionalPassword) {
        if (p1 == null) {
            p1 = "";
        }
        if (p2 == null) {
            p2 = "";
        }
        var passwordSpecified;
        if (isOptionalPassword) {
            passwordSpecified = !!p1;
        } else {
            passwordSpecified = true;
            if (!p1) {
                return { error: "A password must be specified." };
            }
        }
        if (passwordSpecified && !p2) {
            return { error: "The password must be confirmed." };
        }
        if (p1 != p2) {
            return { error: "Passwords do not match." };
        }
        if (passwordSpecified && p1.length < 8) {
            return { error: "Password must be at least 8 characters in length." };
        }

        if (passwordSpecified && !/.*[a-z].*/.test(p1)) {
            return { error: "Password must contain at least one lowercase letter." };
        }

        if (passwordSpecified && !/.*[A-Z].*/.test(p1)) {
            return { error: "Password must contain at least one uppercase letter." };
        }

        if (passwordSpecified && !/.*[0-9].*/.test(p1)) {
            return { error: "Password must contain at least one number." };
        }

        if (passwordSpecified && !/.*[!@#\$%\^&\*].*/.test(p1)) {
            return { error: "Password must contain at least one special character (!, @, #, $, %, ^, &, or *)." };
        }
        if (passwordSpecified) {
            return { hash: new Md5().appendStr(p1).end() };
        }
        return {};
    }

    setPassword(userId, newPassword, token, tokenType): Observable<any> {
        return this.http.post<any>("api/userForgotPasswordReset/", {
            id: userId,
            password: newPassword,
            token: token,
            tokenType: tokenType,
        }, httpOptions);
    }

    changePassword(userId, newPassword): Observable<any> {
        return this.http.post<any>("api/userChangePassword/", {
            id: userId,
            password: newPassword,
        }, httpOptions);
    }

    createUser(company, emailAddress, password, fullName, photo, location, role, jobTitle, department, timeZone, phone, isClientUser): Observable<any> {
        return this.http.put<any>("api/user/", {
            company_id: company,
            email_address: emailAddress,
            password: password,
            full_name: fullName,
            photo: photo,
            location: location,
            role: role,
            job_title: jobTitle,
            department: department,
            time_zone: timeZone,
            phone: phone,
            is_client_user: isClientUser,
        }, httpOptions);
    }

    deleteUser(userId) {
        return this.http.delete<any>("api/user/" + userId);
    }

    setOwner(userId) {
        return this.http.put<any>("api/userSetOwner", {
            user_id: userId,
        }, httpOptions);
    }

    isOwner() {
        return this.http.get<any>("api/userIsOwner");
    }

    sendPasswordRecoveryEmail(username) {
        var urlPrefix = this.getURLPrefix()
        return this.http.post<any>("api/userSendForgotPasswordEmail", {
            username,
            urlPrefix
        });
    }

    getURLPrefix() {
        var subLength = window.location.pathname.length
        if (window.location.search) {
            subLength += window.location.search.length
        }
        return window.location.href.substring(0, window.location.href.length - subLength);
    }

    sendInviteEmail(username) {
        var urlPrefix = this.getURLPrefix()
        return this.http.post<any>("api/userSendEmailVerificationEmail", {
            username,
            urlPrefix
        });
    }

    emailVerified(username, token) {
        return this.http.post<any>("api/userVerifyEmail", {
            username,
            token
        });
    }

    logLogout() {
        return this.http.post<any>("api/logLogout", null, httpOptions);
    }

    //in order of selection precedence
    featureDefaultRoutes = [
        {
            id: "Researcher Tools",
            route: "/welcome"
        },
        {
            id: "Client Portal",
            route: "/agent-dashboard"
        },
        {
            id: "Provider Portal",
            route: "/dashboard-order-all-open",
            userRoute: "/dashboard-search-all-open",
        },
        {
            id: "Searcher Portal",
            route: "/dashboard-search-all-open"
        },
    ];

    getDefaultRouteByFeature(features) {
        for (var i = 0; i < this.featureDefaultRoutes.length; ++i) {
            for (var j = 0; j < features.length; ++j) {
                if (this.featureDefaultRoutes[i].id == features[j].feature_name) {
                    if (this.featureDefaultRoutes[i].userRoute
                        && this.authService.userrole == "user") {
                        return this.featureDefaultRoutes[i].userRoute;
                    }
                    return this.featureDefaultRoutes[i].route;
                }
            }
        }
        return "/welcome";
    }

    isClient: boolean
    navigateToInitialPage() {
        var envType;
        this.systemService.getEnvironmentType().subscribe(
            (response) => {
                envType = response.result;
                this.chargebeeService.getPlan().subscribe(
                    (response) => {
                        var checkBypassPromise: Promise<unknown> = Promise.resolve();
                        if (!response.success) {
                            checkBypassPromise = this.authService.bypassAuthPromise();
                        }
                        checkBypassPromise.then(
                            (doBypass) => {
                                if (response.success || doBypass) {
                                    var planId = response.plan;
    
                                    this.chargebeeService.getFeatures(planId).subscribe(
                                        (response) => {
                                            this.router.navigate([this.getDefaultRouteByFeature(response.result)]);
                                        },
                                        (error) => {
                                            console.error("Error occurred while retrieving features:", error);
                                            this.router.navigate(['login'], { queryParams: { loginMessage: "Error occurred while retrieving subscription features" } });
                                        }
                                    );
                                } else {
                                    var errorMessage = response.error ? ": " + response.error.message : "";
                                    this.router.navigate(['login'], { queryParams: { loginMessage: "Error occurred while retrieving subscription information" + errorMessage } });
                                }
                            },
                            (error) => {
                                console.error("Error occurred while processing bypass authentication:", error);
                                this.router.navigate(['login'], { queryParams: { loginMessage: "Error occurred while processing bypass authentication" } });
                            }
                        );
                    },
                    (error) => {
                        console.error("Error occurred while retrieving plan:", error);
                        this.router.navigate(['login'], { queryParams: { loginMessage: "Error occurred while retrieving subscription plan" } });
                    }
                );
            },
            (error) => {
                console.error("Error occurred while retrieving environment type:", error);
                this.router.navigate(['login'], { queryParams: { loginMessage: "Error occurred while retrieving environment type" } });
            }
        );
    }
    

    phoneUpdate(fc) {
        if (/^\d$/.test(fc.value)) {
            fc.setValue("(" + fc.value);
        }
        if (/^\(\d\d\d$/.test(fc.value)) {
            fc.setValue(fc.value + ") ");
        }
        if (/^\(\d\d\d\) \)$/.test(fc.value)) {
            fc.setValue(fc.value.substring(0, 6));
        }
        if (/^\(\d\d\d\) \d\d\d$/.test(fc.value)) {
            fc.setValue(fc.value + "-");
        }
        if (/^\(\d\d\d\) \d\d\d--$/.test(fc.value)) {
            fc.setValue(fc.value.substring(0, 10));
        }
    }

    sendUserFeedback(feedbackType, feedback) {
        return this.http.put<any>("api/userFeedback", {
            feedbackType,
            feedback
        }, httpOptions);
    }
}
