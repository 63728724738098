<span *ngIf="!initialized">
    <div class="container-fluid mt-4 rounded-ninety" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor">
        Initializing...
    </div>
</span>

<div *ngIf="initialized" class="container-fluid rounded-ninety" [style.color]="themeService.textOnTertiaryColor"
    [style.background-color]="themeService.tertiaryColor">
    <div class="row pt-3 pb-2">
        <div class="col-9">
            <div class="row">
                <div class="col">
                    <span class="attribute-h1 bold">
                        <a *ngIf="invoice.order_uuid" routerLink="/searcher-order-edit"
                            [queryParams]="{ orderUuid: invoice.order_uuid }">
                            Invoice {{invoice.invoice_id}}
                        </a>
                        <span *ngIf="!invoice.order_uuid">Invoice {{invoice.invoice_id}}</span>
                    </span>
                    <span class="badge" style="font-size: .8em;"
                        [ngStyle]="{'color': badgeColor, 'border-color': badgeColor}">{{invoice.status_name}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <span style="color: grey; font-size: .8em;">
                        <span style="font-weight: bold;">Latest Activity:</span>
                        {{this.latestActivity.action}} by
                        <span style="font-weight: bold;">{{this.latestActivity.user}}</span>
                        on {{this.latestActivity.event_time | date : 'medium'}}
                        <a routerLink="/invoice-history" [queryParams]="{ invoiceId: invoiceId }">View History</a>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-3" align="right" style="font-size: 1.4em;">
            <div class="pdf-button" align="center">
                &nbsp;<fa-icon [icon]="faFilePdf" class="mr-2 pointer" (click)="viewInvoicePDF()">
                </fa-icon>
            </div>
            <div class="pdf-button" align="center">
                &nbsp;<fa-icon [icon]="faPrint" class="mr-2 pointer" (click)="printInvoicePDF()">
                </fa-icon>
            </div>
        </div>

    </div>
    <div class="row darker-row py-2">
        <div class="col">
            <app-button label="Send Invoice" [clickFunction]="sendInvoice.bind(this)" [icon]="faShareSquare">
            </app-button>
            <app-button class="mx-2" label="Edit Invoice" [clickFunction]="editInvoice.bind(this)" [icon]="faEdit">
            </app-button>
            <div style="position: relative; display: inline-block;">
                <app-button label="Actions" [clickFunction]="toggleActionsDropdown.bind(this)" [icon]="faChevronDown">
                </app-button>
                <div *ngIf="showActions" class="userDropdownMenu" align="left">
                    <span class="userDropdownMenu" align="left" id="userDropdown">
                        <div *ngIf="showMarkAsSent()" class="row userDropdownItem user-dropdown-border-top py-2"
                            (click)="markAsSent()">
                            <div class="col-1 px-0">
                                <fa-icon [icon]="faCheck"></fa-icon>
                            </div>
                            <div class="col-11">
                                Mark as Sent
                            </div>
                        </div>
                        <div *ngIf="showMarkAsUnsent()" class="row userDropdownItem user-dropdown-border-top py-2"
                            (click)="markAsUnsent()">
                            <div class="col-1 px-0">
                                <fa-icon [icon]="faBoxOpen"></fa-icon>
                            </div>
                            <div class="col-11">
                                Mark as Unsent
                            </div>
                        </div>

                        <div *ngIf="showWriteOff()" class="row userDropdownItem user-dropdown-border-top py-2"
                            (click)="writeOff()">
                            <div class="col-1 px-0">
                                <fa-icon [icon]="faPenAlt"></fa-icon>
                            </div>
                            <div class="col-11">
                                Write Off
                            </div>
                        </div>

                        <div class="row userDropdownItem user-dropdown-border-top user-dropdown-border-bottom py-2"
                            (click)="delete()">
                            <div class="col-1 px-0">
                                <fa-icon [icon]="faBan"></fa-icon>
                            </div>
                            <div class="col-11">
                                Delete Invoice
                            </div>
                        </div>
                    </span>
                </div>
            </div>

            <span style="float: right;">
                Balance: {{invoice.balance | currency : 'USD'}}
                <app-button class="ml-2" label="Record Payment" [clickFunction]="recordPayment.bind(this)"
                    [icon]="faMoneyBillWaveAlt">
                </app-button>

            </span>
        </div>
    </div>

    <span *ngIf="subComponentView == SubComponentView.edit_invoice">
        <app-invoice-edit [cancelFunction]="cancelEdit.bind(this)" [onSaveNavigate]="onSaveEditNavigate.bind(this)"></app-invoice-edit>
    </span>

    <span *ngIf="subComponentView == SubComponentView.record_payment">

        <app-input-date label="Payment Date" [fc]="paymentDateFC" rowClass="dark-row"></app-input-date>
        <app-input-text label="Amount" [fc]="paymentAmountFC" rowClass="dark-row"></app-input-text>
        <app-input-text-area label="Notes" [fc]="paymentNotesFC" rows="4" rowClass="dark-row">
        </app-input-text-area>

        <div *ngIf="savePaymentError" class="row dark-row pt-3">
            <div class="col-4">
            </div>
            <div class="col-8" style="color: red; font-weight: bold;">
                {{savePaymentError}}
            </div>
        </div>


        <div class="row dark-row pt-3">
            <div class="col-4">
            </div>
            <div class="col-8">
                <app-button label="Cancel" class="ml-2" [clickFunction]="cancelPayment.bind(this)" [icon]="faBan">
                </app-button>
                <app-button label="Save Payment" class="ml-2" [clickFunction]="savePayment.bind(this)" [icon]="faSave"
                    [buttonType]="ButtonType.save">
                </app-button>
            </div>
        </div>
        <div class="row pt-3 dark-row">
        </div>
    </span>

    <span *ngIf="subComponentView == SubComponentView.delete">
        <div class="row pt-3 dark-row">
            <div class="col bold">
                Are you sure you want to delete Invoice {{invoice.invoice_id}}?
            </div>
        </div>
        <div class="row pt-3 dark-row">
            <div class="col">
                This action will permanently delete this invoice. There is no undo. If you don't plan to accept payment,
                you can write off this invoice instead.
            </div>
        </div>
        <div class="row dark-row pt-3">
            <div class="col-4">
            </div>
            <div class="col-8">
                <app-button [buttonType]="ButtonType.delete" [clickFunction]="doDelete.bind(this)">
                </app-button>
                <app-button [buttonType]="ButtonType.cancel" [clickFunction]="cancelMarkAsSent.bind(this)">
                </app-button>
            </div>
        </div>
        <div class="row pt-3 dark-row">
        </div>
    </span>

    <span *ngIf="subComponentView == SubComponentView.mark_as_sent">
        <app-input-date label="Sent Date" [fc]="markAsSentDateFC" rowClass="dark-row"></app-input-date>
        <div class="row dark-row pt-3">
            <div class="col-4">
            </div>
            <div class="col-8">
                <app-button label="Mark As Sent" [clickFunction]="doMarkAsSent.bind(this)" [icon]="faSave"
                    [buttonType]="ButtonType.save">
                </app-button>
                <app-button label="Cancel" class="ml-2" [clickFunction]="cancelMarkAsSent.bind(this)" [icon]="faBan">
                </app-button>
            </div>
        </div>
        <div class="row pt-3 dark-row">
        </div>

    </span>


    <span *ngIf="subComponentView == SubComponentView.send_invoice">
        <span *ngIf="invoice.status != 'D'">
            <div class="row py-2 dark-row">
                <div class="col">
                    Invoice has already been sent.
                </div>
            </div>
            <div class="row py-2 dark-row" align="center">
                <div class="col">
                    <app-button label="Cancel" class="ml-2" [clickFunction]="doSendCancel.bind(this)" [icon]="faBan">
                    </app-button>
                </div>
            </div>

        </span>
        <span *ngIf="invoice.status == 'D'">
            <div class="row pt-2 dark-row">
            </div>

            <div *ngFor="let recipient of allRecipientsList; let first = first" class="row dark-row">
                <div *ngIf="first" class="col-4 bold">
                    Recipients
                </div>
                <div *ngIf="!first" class="col-4">
                </div>

                <div class="col-8">
                    <app-input-checkbox [label]="recipient.email" [fc]="recipient.fc"
                        [format]="InputCheckboxFormat.none">
                    </app-input-checkbox>
                </div>
            </div>

            <div *ngFor="let recipient of additionalRecipientsList; let first = first; let index = index"
                class="row dark-row">
                <div *ngIf="first" class="col-4 bold">
                    Additional Recipients
                </div>
                <div *ngIf="!first" class="col-4">
                </div>

                <div class="col-8">
                    <div style="display: inline-block;">
                        <app-input-checkbox [fc]="recipient.fc" [format]="InputCheckboxFormat.none">
                        </app-input-checkbox>
                    </div>
                    <input type="text" class="input-style" style="width: 50%" [formControl]="recipient.nameFC" />
                    <fa-icon [icon]="faTimes" class="ml-2 pointer" style="z-index: 2"
                        (click)="removeAdditionalContact(index)"></fa-icon>
                </div>
            </div>

            <div class="row dark-row pb-3">
                <div class="col-4">
                </div>
                <div class="col-8">
                    Sending to someone else?
                    <app-button label="Add Recipient" [clickFunction]="addAdditionalRecipient.bind(this)"
                        [icon]="faEdit" [small]="true">
                    </app-button>
                </div>
            </div>

            <app-input-text label="Message Subject" [fc]="messageSubjectFC" rowClass="dark-row">
            </app-input-text>

            <div class="row pt-3 dark-row">
            </div>
            <app-input-text-area label="Message Body" [fc]="messageBodyFC" rows="9" rowClass="dark-row">
            </app-input-text-area>

            <div class="row dark-row">
                <div class="col-4">
                </div>
                <div class="col-8">
                    <app-input-checkbox label="Include a PDF version of the invoice" [fc]="includePDFFC"
                        [format]="InputCheckboxFormat.none">
                    </app-input-checkbox>
                </div>
            </div>

            <div class="row dark-row pt-3">
                <div class="col-4">
                </div>
                <div class="col-8">
                    <app-button label="Cancel" class="ml-2" [clickFunction]="doSendCancel.bind(this)" [icon]="faBan">
                    </app-button>
                    <app-button label="Send Invoice" class="ml-2" [clickFunction]="doSendInvoice.bind(this)"
                        [icon]="faShareSquare" [buttonType]="ButtonType.save">
                    </app-button>
                </div>
            </div>
            <div class="row pt-3 dark-row">
            </div>
        </span>
    </span>

    <span id="invoice_html">
        <div class="row pt-3">
            <div class="col-8" #imageCol>

            </div>
            <div class="col-1 right-border" align="right">
                From
            </div>
            <div class="col-2">
                {{providerCompany.name}}<br />
                {{providerCompany.address}}<br />
                {{providerCompany.phone}}
            </div>
        </div>


        <div class="row pt-3">
            <div class="col-2 right-border">
                Invoice ID<br />
                Invoice Date<br />
                Due Date<br />
                File Number<br />
                Subject
            </div>
            <div class="col-5">
                <span style="font-weight: bold;">{{invoice.invoice_id}}</span><br />
                {{invoice.invoice_date | date: 'M/d/yy'}}<br />
                {{invoice.due_date | date: 'M/d/yy'}}<br />
                {{invoice.file_number}}<br />
                {{invoice.subject}}
            </div>
            <div class="col-2 right-border" align="right">
                Invoice For
            </div>
            <div class="col-2" *ngIf="!showEditBillToCompanyInfo">
                {{invoice.bill_to_company_name}}<br />
                {{invoice.bill_to_company_address}}<br />
                {{invoice.bill_to_company_phone}}<br />
                <app-button label="Edit Info" [clickFunction]="editBillToCompanyInfo.bind(this)" [icon]="faEdit"
                    [small]="true">
                </app-button>
            </div>
            <div class="col-2" *ngIf="showEditBillToCompanyInfo">
                <app-input-text [fc]="editBillToCompanyNameFC" [style]="'edit-sbl'"></app-input-text>
                <app-input-text-area [fc]="editBillToCompanyAddressFC" [format]="InputTextAreaFormat.none"
                    [style]="'edit-sbl'"></app-input-text-area>
                <app-input-text [fc]="editBillToCompanyPhoneFC" [style]="'edit-sbl'"></app-input-text>
                <app-button label="Cancel" class="ml-2" [clickFunction]="cancelBillToCompanyInfo.bind(this)"
                    [icon]="faSave" [small]="true">
                </app-button>
                <app-button label="Save Info" class="ml-2" [clickFunction]="saveBillToCompanyInfo.bind(this)"
                    [icon]="faSave" [small]="true" [buttonType]="ButtonType.save">
                </app-button>
            </div>
        </div>

        <div class="row pt-5 bold">
            <div class="col-2 invoice-col">
                Item Type
            </div>
            <div class="col-4 invoice-col">
                Description
            </div>
            <div class="col-2 invoice-col" align="right">
                Quantity
            </div>
            <div class="col-2 invoice-col" align="right">
                Unit Price
            </div>
            <div class="col-2 invoice-col-last" align="right">
                Amount
            </div>
        </div>

        <div *ngFor="let item of invoice.items; let last = last; let odd = odd" class="row"
            [ngClass]="{'dark-row': odd }">
            <div class="col-2" [ngClass]="{ 'invoice-col': !last, ' invoice-col-bottom': last }">
                {{itemTypesNameById[item.type]}}
            </div>
            <div class="col-4" [ngClass]="{ 'invoice-col': !last, ' invoice-col-bottom': last }">
                {{item.description}}
            </div>
            <div class="col-2" [ngClass]="{ 'invoice-col': !last, ' invoice-col-bottom': last }" align="right">
                {{getQuantityValue(item)}}
            </div>
            <div class="col-2" [ngClass]="{ 'invoice-col': !last, ' invoice-col-bottom': last }" align="right">
                {{item.unit_price | currency : 'USD'}}
            </div>
            <div class="col-2" [ngClass]="{ 'invoice-col-last': !last, ' invoice-col-last-bottom': last }"
                align="right">
                {{item.amount | currency : 'USD'}}
            </div>
        </div>

        <div class="row pt-3">
        </div>


        <div class="row">
            <div class="col-8">
                <span *ngFor="let part of notesObject">
                    <span *ngIf="part.bold && part.italics" style="font-weight: bold; font-style: italic">
                        {{part.part}}
                    </span>
                    <span *ngIf="part.bold && !part.italics" style="font-weight: bold;">
                        {{part.part}}
                    </span>
                    <span *ngIf="!part.bold && part.italics" style="font-style: italic">
                        {{part.part}}
                    </span>
                    <span *ngIf="!part.bold && !part.italics">
                        {{part.part}}
                    </span>
                </span>
            </div>
            <div class="col-4">
                <span *ngIf="invoice.untaxable_subtotal">
                    <div class="row">
                        <div class="col-10" align="right">
                            Untaxable Subtotal
                        </div>
                        <div class="col-2" align="right">
                            {{invoice.untaxable_subtotal | currency : 'USD'}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-10" align="right">
                            Taxable Subtotal
                        </div>
                        <div class="col-2" align="right">
                            {{invoice.taxable_subtotal | currency : 'USD'}}
                        </div>
                    </div>
                </span>

                <div *ngIf="!invoice.untaxable_subtotal" class="row">
                    <div class="col-6" align="right">
                        Subtotal
                    </div>
                    <div class="col-6" align="right">
                        {{invoice.taxable_subtotal | currency : 'USD'}}
                    </div>
                </div>

                <div *ngIf="invoice.discount_amount" class="row">
                    <div class="col-6" align="right">
                        Discount
                    </div>
                    <div class="col-6" align="right">
                        {{invoice.discount_amount | currency : 'USD'}}
                    </div>
                </div>

                <div class="row">
                    <div class="col-6" align="right">
                        Tax
                    </div>
                    <div class="col-6" align="right">
                        {{invoice.tax_amount | currency : 'USD'}}
                    </div>
                </div>

                <div class="row pt-3 bold" style="font-size: 1.2em;">
                    <div class="col-6" align="right">
                        Amount Due
                    </div>
                    <div class="col-6" align="right">
                        {{invoice.amount_due | currency : 'USD'}}
                    </div>
                </div>

            </div>
        </div>

        <div class="row pt-3">
        </div>
    </span>
</div>