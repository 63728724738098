<span *ngIf="!initialized">
    <div class="container-fluid mt-4 rounded-ninety" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor">
        Initializing...
    </div>
</span>

<div *ngIf="initialized" class="container-fluid pt-2 rounded-ninety" [style.color]="themeService.textOnTertiaryColor"
    [style.background-color]="themeService.tertiaryColor">
    <div class="row pb-1">
        <div class="col-8 pb-3 attribute-h1 bold">
            <a *ngIf="editOrderId" routerLink="/searcher-order-edit" [queryParams]="{ orderUuid: editOrderId }">
                Invoice {{invoiceIdFC.value}}
            </a>
            <span *ngIf="!editOrderId">Invoice {{invoiceIdFC.value}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <app-input-text label="Invoice ID" [fc]="invoiceIdFC">
            </app-input-text>
            <app-input-text label="PO Number" [fc]="poNumberFC">
            </app-input-text>
            <app-input-date label="Invoice Date" [fc]="invoiceDateFC">
            </app-input-date>
            <app-input-enum label="Payment Terms" [fc]="paymentTermFC" [enumValuesNameAndId]="dueDates"
                [change]="paymentTermChange.bind(this)">
            </app-input-enum>
            <app-input-date label="Due Date" [fc]="dueDateFC">
            </app-input-date>
        </div>
        <div class="col-6">
            <app-input-enum label="Bill To Company" [fc]="billToCompanyFC" [enumValuesNameAndId]="uniqueClientCompanies"
                [change]="billToCompanyChange.bind(this)">
            </app-input-enum>
            <div style="width: 100%; text-align: right;">
                <a routerLink="/client" [queryParams]="{ id: billToCompanyFC.value, invoice: true, createOrderUuid: createOrderUuid }">
                    <app-button *ngIf="billToCompanyFC.value" label="Edit Client" [icon]="faEdit" [small]="true">
                    </app-button>
                </a>
            </div>
            <app-input-search label="Tax Location" [fc]="taxLocationFC" [searchFn]="searchJurisdictions.bind(this)"
                [getItemDisplayValue]="getItemJurisdictionName.bind(this)" [itemSelect]="selectJurisdiction.bind(this)">
            </app-input-search>
            <app-input-text label="Tax Rate" [fc]="taxRateFC" units="%" [change]="updateInvoiceTotal.bind(this)">
            </app-input-text>
            <app-input-text label="Tax Code" [fc]="taxCodeFC">
            </app-input-text>
            <app-input-text label="Discount" [fc]="discountFC" units="%">
            </app-input-text>
            <app-input-enum label="Currency" [fc]="currencyFC" [enumValuesNameAndId]="currencies">
            </app-input-enum>
        </div>
    </div>
    
    <div class="row">
        <div class="col">
            <app-input-text label="Subject" [fc]="subjectFC" [halfLabelSize]="'true'">
            </app-input-text>
        </div>
    </div>

    <div *ngIf="internalNotes" class="row">
        <div class="col" align="center">
            <div class="mt-2 py-2"
                style="background-color: lightyellow; white-space: pre; width: 50%; border-color: red; border-style: solid; border-width: 1px;">
                {{internalNotes}}
            </div>
        </div>
    </div>

    <div class="row grey-list-title-row mt-3">
        <div class="col-1 px-1">
        </div>
        <div class="col-2 px-1">
            Item Type
        </div>
        <div class="col-4 px-1">
            Description
        </div>
        <div class="col-1 px-1">
            Quantity
        </div>
        <div class="col-2 px-1">
            Unit Price
        </div>
        <div class="col-1 px-1" align="center">
            Taxable
        </div>
        <div class="col-1 px-1" align="right">
            Amount
        </div>
    </div>
    <div *ngFor="let itemTypeFCSet of itemTypeFCSets; let index = index" class="row">
        <div class="col-1 unedit-padding" align="center">
            <fa-icon [icon]="faTimes" (click)="removeItem(index)" class="pointer"></fa-icon>
        </div>
        <div class="col-2">
            <app-input-enum [fc]="itemTypeFCSet.typeFC" [format]="InputEnumFormat.none"
                [enumValuesNameAndId]="itemTypes">
            </app-input-enum>
        </div>
        <div class="col-4">
            <app-input-text-area [fc]="itemTypeFCSet.descriptionFC" [format]="InputTextAreaFormat.none" rows="2">
            </app-input-text-area>
        </div>
        <div class="col-1">
            <app-input-number [fc]="itemTypeFCSet.quantityFC" [format]="InputNumberFormat.none"
                [change]="changeQuantityOrUnitPrice.bind(this, itemTypeFCSet)">
            </app-input-number>
        </div>
        <div class="col-2">
            <app-input-currency [fc]="itemTypeFCSet.unitPriceFC" [format]="InputCurrencyFormat.none"
                [change]="changeQuantityOrUnitPrice.bind(this, itemTypeFCSet)">
            </app-input-currency>
        </div>
        <div class="col-1" align="center">
            <input type="checkbox" [formControl]="itemTypeFCSet.taxableFC" (click)="taxableClick(itemTypeFCSet, $event)"
                class="pointer">
        </div>
        <div class="col-1 unedit-padding" align="right">
            {{itemTypeFCSet.amount | currency : 'USD'}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col">
            <app-button label="Add Item" [clickFunction]="addItem.bind(this)" [icon]="faPlus">
            </app-button>
        </div>
    </div>

    <span *ngIf="untaxable_subtotal">
        <div class="row">
            <div class="col-10" align="right">
                Untaxable Subtotal
            </div>
            <div class="col-2" align="right">
                {{untaxable_subtotal | currency : 'USD'}}
            </div>
        </div>
        <div class="row">
            <div class="col-10" align="right">
                Taxable Subtotal
            </div>
            <div class="col-2" align="right">
                {{taxable_subtotal | currency : 'USD'}}
            </div>
        </div>
    </span>

    <div *ngIf="!untaxable_subtotal" class="row">
        <div class="col-10" align="right">
            Subtotal
        </div>
        <div class="col-2" align="right">
            {{taxable_subtotal | currency : 'USD'}}
        </div>
    </div>

    <div *ngIf="discount_amount" class="row">
        <div class="col-10" align="right">
            Discount
        </div>
        <div class="col-2" align="right">
            {{discount_amount | currency : 'USD'}}
        </div>
    </div>

    <div class="row">
        <div class="col-10" align="right">
            Tax
        </div>
        <div class="col-2" align="right">
            {{tax_amount | currency : 'USD'}}
        </div>
    </div>

    <div class="row pt-3 bold" style="font-size: 1.2em;">
        <div class="col-10" align="right">
            Amount Due
        </div>
        <div class="col-2" align="right">
            {{amount_due | currency : 'USD'}}
        </div>
    </div>

    <div class="row">
        <div class="col">
            <app-input-text-area [label]="'Notes (optional, displayed on invoice)'" [fc]="notesFC">
            </app-input-text-area>
            Formatting tips: <span style="font-weight: bold;">*bold*</span> <span
                style="font-style: italic;">_italic_</span>
        </div>

    </div>
    <div *ngIf="error" class="row">
        <div class="col" style="color: red;" align="center">
            {{error}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col" align="center">
            <app-button label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
            </app-button>
            <app-button class="ml-2" *ngIf="invoiceId" label="Update Invoice" [clickFunction]="update.bind(this)"
                [icon]="faWrench" [buttonType]="ButtonType.save">
            </app-button>
            <app-button class="ml-2" *ngIf="!invoiceId" label="Create Invoice" [clickFunction]="create.bind(this)"
                [icon]="faPlusSquare" [buttonType]="ButtonType.save">
            </app-button>
        </div>
    </div>

    <div class="row pt-3">
    </div>

</div>