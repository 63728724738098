<div *ngIf="initialized" class="container-fluid pt-2">

    <div class="row">
        <div class="col-2 d-none d-md-block">
        </div>
        <div class="col-12 col-md-8">

            <div class="row">
                <div class="col bold" style="font-size: 1.6em; color: #117ac9">
                    Invoices
                </div>
            </div>

            <div class="row mt-2">
            </div>

            <app-input-enum label="Default Payments Due" [fc]="defaultPaymentTermFC" [enumValuesNameAndId]="dueDates"
                [naturalWidth]="true">
            </app-input-enum>

            <div class="row mt-2">
            </div>

            <app-input-text label="Invoice Subject Template" [fc]="invoiceSubjectTemplateFC">
            </app-input-text>

            <div class="row">
                <div class="col-4">
                </div>
                <div class="col-8" style="font-size: .8em;">
                    The Invoice Subject Template is a one-liner to quickly summarize what the invoice is for. Subjects
                    can be
                    different per invoice, but you might have a common template, which you can specify here as a
                    default. Or just leave it blank.
                </div>
            </div>

            <div class="row mt-2">
            </div>

            <app-input-text-area label="Default Invoice Notes" [fc]="defaultInvoiceNotesFC">
            </app-input-text-area>

            <div class="row">
                <div class="col-4">
                </div>
                <div class="col-8" style="font-size: .8em;">
                    Invoice notes show up below the line items section on invoices. You can add additional
                    information
                    such as payment method, payment terms, or additional tax information.
                </div>
            </div>

            <div class="row mt-2">
            </div>

            <app-input-text label="Next Invoice ID" [fc]="nextInvoiceIdFC">
            </app-input-text>

            <div class="row">
                <div class="col-4">
                </div>
                <div class="col-8" style="font-size: .8em;">
                    The Next Invoice ID will be used if the "Use order id as invoice id" setting is set to false, or if
                    an invoice is not associated to an order.
                </div>
            </div>

            <div class="row mt-2">
            </div>

            <app-input-enum label="Use order id as invoice id" [fc]="useOrderIdAsInvoiceIdFC"
                [enumValuesNameAndId]="trueFalseEnumValues" [naturalWidth]="true">
            </app-input-enum>

            <div class="row mt-2">
            </div>

            <app-input-enum label="Fiscal Year Start" [fc]="fiscalYearStartFC" [enumValuesNameAndId]="monthEnumValues"
                [naturalWidth]="true">
            </app-input-enum>

            <div class="row mt-2">
                <div class="col" style="text-align: center;">
                    <app-button [buttonType]="ButtonType.cancel" [clickFunction]="cancelConfiguration.bind(this)">
                    </app-button>
                    <app-button class="ml-2" [buttonType]="ButtonType.save" label="Save Configuration"
                        [clickFunction]="saveConfiguration.bind(this)">
                    </app-button>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-6" style="font-size: 1.4em; font-weight: bold;">
                    Item Types
                </div>
                <div class="col-6" align="right">
                    <app-button label="New Item Type" [clickFunction]="newItemType.bind(this)" [icon]="faPlus">
                    </app-button>
                </div>
            </div>


            <span *ngIf="showNewItemForm">
                <app-input-text label="New Item Type Name" [fc]="newItemTypeName"
                    [keyupEnter]="createNewItemType.bind(this)">
                </app-input-text>
                <div *ngIf="newItemError" class="row mt-2">
                    <div class="col" align="center" style="color: red">
                        {{newItemError}}
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col" style="text-align: center;">
                        <app-button label="Cancel" [clickFunction]="cancelNewItemType.bind(this)" [icon]="faBan">
                        </app-button>
                        <app-button class="ml-2" label="Create Item Type"
                            [clickFunction]="createNewItemType.bind(this)" [buttonType]="ButtonType.save">
                        </app-button>
                    </div>
                </div>
            </span>
            <div *ngIf="deleteItemError" class="row mt-2">
                <div class="col" align="center" style="color: red">
                    {{deleteItemError}}
                </div>
            </div>
            <div *ngIf="updateItemError" class="row mt-2">
                <div class="col" align="center" style="color: red">
                    {{updateItemError}}
                </div>
            </div>
            <div *ngFor="let itemType of itemTypes" class="row mt-1">
                <div *ngIf="itemType.id == defaultItemTypeId" class="col-2 item-type-row py-2 px-0">
                </div>
                <div *ngIf="itemType.id != defaultItemTypeId" class="col-2 item-type-row py-2 px-0">
                    <app-button label="Edit" [clickFunction]="edit.bind(this, itemType)" [icon]="faEdit"
                        [small]="true">
                    </app-button>
                </div>

                <div *ngIf="editItemId == itemType.id" class="col-4 item-type-row py-2 px-0">
                    <input type="text" [formControl]="editItemTypeName" />
                </div>
                <div *ngIf="editItemId == itemType.id" class="col-4 item-type-row py-2 mx-0 px-0">
                    <app-button [clickFunction]="save.bind(this, itemType)" [small]="true"
                        [buttonType]="ButtonType.save">
                    </app-button>
                    <app-button [buttonType]="ButtonType.cancel" [clickFunction]="cancel.bind(this, itemType)"
                        [small]="true">
                    </app-button>
                </div>
                <div *ngIf="editItemId != itemType.id" class="col-8 item-type-row py-2 px-0">
                    {{itemType.name}}
                </div>
                <div class="col-2 item-type-row py-2 px-0" align="right">
                    <app-button [buttonType]="ButtonType.delete" *ngIf="itemType.id != defaultItemTypeId"
                        [clickFunction]="delete.bind(this, itemType)" [small]="true">
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</div>