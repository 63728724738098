import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit 
{
  @Input() selectedDate: Date;
  @Input() isRange: boolean = false;
  @Output() dateSelected = new EventEmitter<Date | { start: Date, end: Date }>();

  selectedDateRange: {start: Date, end: Date} = {start: null, end: null}

  onDateSelected(date: Date): void
  {
    if (this.isRange) 
    {
      this.handleRangeDateSelected(date);
    } 
    else 
    {
      this.handleSingleDateSelected(date);
    }
  }
  
  private handleSingleDateSelected(date: Date): void 
  {
    this.dateSelected.emit(date);
  }
  
  private handleRangeDateSelected(date: Date): void 
  {
    if (!this.selectedDateRange.start) 
    {
      this.selectedDateRange.start = date;
    }
    else if (!this.selectedDateRange.end)
    {
      this.selectedDateRange.end = date;
      this.emitSelectedRange();
      this.resetSelectedRange();
    } 
    else 
    {
      this.selectedDateRange.start = date;
      this.selectedDateRange.end = null;
    }
  }
  
  private emitSelectedRange(): void 
  {
    this.dateSelected.emit(this.selectedDateRange);
  }
  
  private resetSelectedRange(): void 
  {
    this.selectedDateRange = { start: null, end: null };
  }
  

  constructor() { }

  ngOnInit() {
  }

}
