import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { OrdersService } from '../services/order.service';
import { iOrder } from '../model/order.model';
import { Menu } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-search-primeng',
  templateUrl: './search-primeng.component.html',
  styleUrls: ['./search-primeng.component.css']
})
export class SearchPrimengComponent implements OnInit {
  tableData: iOrder[] = [];
  searchForm: FormGroup;
  @ViewChildren('filterMenu') filterMenus!: QueryList<Menu>;
  @ViewChild('dt') dt!: Table;

  columns = [
    { label: 'Order ID', field: 'orderId', formControl: 'orderId' },
    { label: 'Reference Number', field: 'referenceNumber', formControl: 'referenceNumber' },
    { label: 'Buyer', field: 'propertyBuyer', formControl: 'propertyBuyer' },
    { label: 'SBL', field: 'sbl', formControl: 'sbl' },
    { label: 'County', field: 'county', formControl: 'county' },
    { label: 'State', field: 'state', formControl: 'state' },
    { label: 'Searcher', field: 'searcher', formControl: 'searcher' },
    { label: 'Address', field: 'address', formControl: 'address' },
    { label: 'Order Date', field: 'submissionDate', formControl: 'submissionDate' },
    { label: 'Service', field: 'primaryServiceName', formControl: 'primaryServiceName' },
    { label: 'Status', field: 'orderStatusName', formControl: 'orderStatusName' },
    { label: 'Client Company', field: 'clientCompanyName', formControl: 'clientCompanyName' },
  ];

  filterOptions: { [key: string]: MenuItem[] } = {};
  searchParams: { [key: string]: { value: string; type: string } } = {};
  filterTypes: { [key: string]: string } = {};

  constructor(private orderService: OrdersService, private fb: FormBuilder)
  {
    this.searchForm = this.fb.group(
      this.columns.reduce((group: { [key: string]: FormControl }, column) => {
        group[column.formControl] = new FormControl('');
        return group;
      }, {})
    );
  }

  ngOnInit()
  {
    this.initializeFilterOptions();
  }

  private initializeFilterOptions()
  {
    this.columns.forEach(column => {
      const columnKey = column.formControl;
      this.filterOptions[columnKey] = columnKey === 'submissionDate'
        ? [
            { label: 'Exact', command: () => this.setFilterType('exact', columnKey) },
            { label: 'Before', command: () => this.setFilterType('before', columnKey) },
            { label: 'After', command: () => this.setFilterType('after', columnKey) }
          ]
        : [
            { label: 'Contains', command: () => this.setFilterType('contains', columnKey) },
            { label: 'Equals', command: () => this.setFilterType('equals', columnKey) },
            { label: 'Starts With', command: () => this.setFilterType('startsWith', columnKey) }
          ];
    });
  }

  private setFilterType(type: string, key: string)
  {
    this.filterTypes[key] = type;
    this.updateSearchParams(this.searchForm.value);
  }
  
  clearAllFilters() {
    this.searchForm.reset(); 
    this.filterTypes = {};
    if (this.dt)
    {
      this.dt.reset();
    }
  }

  clearFilter(column: string)
  {
    this.searchForm.get(column)?.setValue('');
    delete this.searchParams[column];
    delete this.filterTypes[column];
  }

  toggleMenu(index: number, event: Event)
  {
    this.filterMenus.toArray()[index].toggle(event);
  }

  applyAllFilters()
  {
    this.updateSearchParams(this.searchForm.value);
    this.fetchOrders();
  }

  autoApplyFilter(column: string, value: any)
  {
    if (column === 'submissionDate')
    {
      if (value && !Array.isArray(value))
      {
        this.filterTypes[column] = 'exact';
        this.updateSearchParams(this.searchForm.value);
      }
    } else
    {
      const inputValue = (value.target as HTMLInputElement).value.trim();
      if (inputValue && !this.filterTypes[column])
      {
        this.filterTypes[column] = 'contains';
      }
      this.updateSearchParams(this.searchForm.value);
    }
  }

  fetchOrders()
  {
    this.orderService.getOrderSearch(this.searchParams).subscribe({
      next: (res) => {
        this.tableData = res;
      },
      error: (err) => {
        console.error('Error fetching orders:', err);
      }
    });
  }

  private updateSearchParams(values: any)
  {
    for (const column of this.columns)
    {
      const key = column.formControl;
      let value = values[key];
  
      if (value instanceof Date)
      {
        value = formatDate(value, 'MM/dd/yyyy', 'en-US');
      } else if (typeof value === 'string')
      {
        value = value.trim();
      }
  
      if (value && this.filterTypes[key])
      {
        this.searchParams[key] = { value, type: this.filterTypes[key] };
      } else
      {
        delete this.searchParams[key];
      }
    }
  }

  removeQuotes(value: string): string
  {
    if (!value) return '';
    // Remove all double quotes from the string
    return value.replace(/"/g, '');
  }

  getFilterLabel(column: string): string
  {
    const filterType = this.filterTypes[column];
    return filterType === 'exact' ? 'Exact' :
           filterType === 'before' ? 'Before' :
           filterType === 'after' ? 'After' :
           filterType === 'contains' ? 'Contains' :
           filterType === 'equals' ? 'Equals' :
           filterType === 'startsWith' ? 'Starts With' : '';
  }

  exportAllToCSV()
  {
    if (this.dt && this.tableData?.length)
    {
      const originalPaginator = this.dt.paginator;
      this.dt.paginator = false;
      this.dt.exportCSV();
      this.dt.paginator = originalPaginator;
    } else
    {
      console.error('Table or data not ready');
    }
  }
}