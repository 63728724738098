import { Component, OnInit, Input, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { ThemeService } from '../theme.service';
import { Router } from '@angular/router';
import { faBan, faSave, faChevronRight, faChevronLeft, faTimes, faSpinner, faCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { TitleCasePipe, DatePipe } from '@angular/common';
import { AttributeNameDisplayPipe } from '../attribute-name-display.pipe';
import { OrderService } from '../order.service';
import { AddressService } from '../address.service';
import { DocumentsService } from '../documents.service';
import { UserService } from '../user.service';
import { ThemeProviderOrderCreateService } from '../theme-provider-order-create.service';
import { ButtonComponent, ButtonType } from '../button/button.component';
import { SnackRouteService } from '../snack-route.service';
import { BannerNotificationService } from '../banner-notification.service';
import { BannerNotificationType } from '../banner-notification-type';
import { ChargebeeService } from '../chargebee.service';

@Component({
    selector: 'app-order-create-buttons',
    templateUrl: './order-create-buttons.component.html',
    providers: [AttributeNameDisplayPipe, DatePipe],
})
export class OrderCreateButtonsComponent implements OnInit, AfterViewInit {
    ButtonType = ButtonType
    @Input() nextDisabler;
    @Input() processNext;
    @Input() validator;
    @Input() orderType;
    @Input() isAgent

    faBan = faBan;
    faSave = faSave;
    faChevronRight = faChevronRight;
    faChevronLeft = faChevronLeft;
    faTimes = faTimes;
    faSpinner = faSpinner;
    faCheck = faCheck;
    faExclamationCircle = faExclamationCircle
    isSubmitting = false;
    isSavingDraft = false;
    error;
    // @ViewChildren(ButtonComponent) buttonComponents: QueryList<ButtonComponent>;
    // submitButtonComponent;
    // saveButtonComponent;
    orderSubmissionError;

    curOrder: string;

    services;
    constructor(
        private _themeService: ThemeService,
        private router: Router,
        private titlecase: TitleCasePipe,
        private orderService: OrderService,
        private addressService: AddressService,
        private documentsService: DocumentsService,
        private userService: UserService,
        private _themeRebrandService: ThemeProviderOrderCreateService,
        private snackRouteService: SnackRouteService,
        private bannerNotificationService: BannerNotificationService,
        private chargebeeService: ChargebeeService
    ) { }

    is_agent = false
    ngOnInit() {
        if(this.isAgent) {
            this.is_agent = this.isAgent
        } else {
            this.chargebeeService.getPlan().subscribe((response) => {
                if(response.plan.includes('CLIENT')) {
                    this.is_agent = true
                } else {
                    this.is_agent = false
                }
            })
        }
    }

    ngAfterViewInit(): void {
        // this.buttonComponents.forEach(writer => {
        //     if (writer.label == "Save for Later") {
        //         this.saveButtonComponent = writer;
        //     }
        //     if (writer.label == "Submit Order") {
        //         this.submitButtonComponent = writer;
        //     }
        // });
    }

    get themeService() {
        return this._themeRebrandService;
    }

    isFirstView() {
        var steps = this.orderService.getSteps();
        for (var i = 0; i < steps.length; ++i) {
            if (steps[i].hidden) {
                continue;
            }
            return this.router.url.startsWith(steps[i].route);
        }
    }

    isLastView() {
        var steps = this.orderService.getSteps();
        for (var i = steps.length - 1; i >= 0; --i) {
            if (steps[i].hidden) {
                continue;
            }

            return this.router.url == steps[i].route;
        }
    }

    nextDisabled() {
        if (this.nextDisabler != null) {
            return this.nextDisabler();
        }
        return false;
    }

    advance() {
        if (this.validator) {
            this.error = this.validator();
            if (this.error) {
                return;
            }
        }

        var steps = this.orderService.getSteps();
        var matchedStep = false;


        for (var i = 0; i < steps.length; ++i) {
            if (matchedStep && !steps[i].hidden) {
                if(this.orderType == 'Quick') {
                    this.curOrder = this.orderType
                    this.router.navigate([steps[6].route]);
                } else {
                    this.router.navigate([steps[i].route]);
                    break;
                }
            }
            if (this.router.url.startsWith(steps[i].route)) {
                matchedStep = true;
            }
        }
    }

    otype: string
    back() {
        var steps = this.orderService.getSteps();
        var matchedStep = false;

        let dataItem = localStorage.getItem("cached_order_draft")
        if(dataItem) {
            let cacheOrder = JSON.parse(dataItem)
            this.otype = cacheOrder.order_type
        }

        for (var i = steps.length - 1; i >= 0; --i) {
            if (matchedStep && !steps[i].hidden) {
                if(this.otype == 'Quick') {
                    console.log(steps)
                    if(steps[i].route == '/order-documents') {
                        console.log('test')
                        this.router.navigate([steps[6].route])
                        break
                    } else if(steps[i].route == '/order-notifications') {
                        this.router.navigate([steps[0].route])
                        break
                    }
                } else {
                    this.router.navigate([steps[i].route])
                    break
                }
            }
            if (steps[i].route == this.router.url) {
                matchedStep = true;
            }
        }
    }

    cancel() {
        var dataItem = localStorage.getItem("cached_order_draft");
        if (dataItem) {
            var cacheOrder = JSON.parse(dataItem);
            this.documentsService.cancel(cacheOrder).subscribe((response) => {
                if (response.success) {
                    this.snackRouteService.snack("Action cancelled.")
                    this.navigateAway();
                } else {
                    console.log("error while cancelling draft", response);
                }
            })
        } else {
            this.snackRouteService.snack("Action cancelled.")
            this.navigateAway();
        }
    }

    navigateAway() {
        localStorage.removeItem("cached_order_draft");

        this.chargebeeService.getPlan().subscribe((response) => {
            if(response.plan.includes('CLIENT')) {
                this.router.navigate(['/agent-dashboard'], { queryParams: { order_status: 'IP' } })
            } else {
                this.router.navigate(['/dashboard-order-all-open'])
            }
        })

        //this.router.navigate(['/dashboard-order-all-open']);
    }

    showDeleteButton() {
        var dataItem = localStorage.getItem("cached_order_draft");
        if (dataItem) {
            var cacheOrder = JSON.parse(dataItem);
            return cacheOrder.uuid;
        }
        return false;
    }

    delete() {
        document.getElementById("delete-confirm").style.display = "block";
    }

    deleteConfirm() {
        document.getElementById("delete-confirm").style.display = "none";
        var dataItem = localStorage.getItem("cached_order_draft");
        if (dataItem) {
            var cacheOrder = JSON.parse(dataItem);
            this.orderService.deleteDraftOrder(cacheOrder.uuid).subscribe((response) => {
                if (response.success) {
                    this.snackRouteService.snack("Order " + response.order_id + " deleted.")
                    this.navigateAway();
                } else {
                    this.setSubmissionError(response);
                }
            });
        }
    }

    deleteCancel() {
        document.getElementById("delete-confirm").style.display = "none";
    }

    saveForLater() {
        // if (this.saveButtonComponent.processing) {
        //     console.log("saving for later button has already been clicked");
        //     return;
        // };
        // if (this.submitButtonComponent && this.submitButtonComponent.processing) {
        //     console.log("submit order button has already been clicked");
        //     return;
        // };

        var dataItem = localStorage.getItem("cached_order_draft");
        if (!dataItem) {
            console.log("no draft order to save was found");
            return;
        }

        //this.saveButtonComponent.processing = true;
        this.orderService.saveOrder(JSON.parse(dataItem), "R").subscribe((response) => {
            //this.saveButtonComponent.processing = false;
            if (response.success) {
                this.bannerNotificationService.addNotification(response.uuid, response.order_id, BannerNotificationType.draftOrderSaved)
                this.navigateAway();
            } else {
                this.setSubmissionError(response);
            }
        });
    }

    checkManualEdits(addressItem) {
        var dataItem = JSON.parse(localStorage.getItem("cached_order_draft"));
        if (!dataItem) {
            dataItem = { property_seller: [] };
        }

        var diff = null;

        var originalAddress = this.titlecase.transform(addressItem.property_full_address + ", " + addressItem.property_town_municipality);

        if (dataItem.address != originalAddress) {
            if (diff == null) {
                diff = {};
            }
            diff.address = originalAddress;
        }
        if (dataItem.sbl != addressItem.print_key) {
            if (diff == null) {
                diff = {};
            }
            diff.sbl = addressItem.print_key;
        }

        if (addressItem.owner_name_1 && (!dataItem.property_seller || dataItem.property_seller.length < 1 || dataItem.property_seller[0] != addressItem.owner_name_1)) {
            if (diff == null) {
                diff = {};
            }
            diff.seller = addressItem.owner_name_1;
            if (addressItem.owner_name_2 && (!dataItem.property_seller || dataItem.property_seller.length < 2 || dataItem.property_seller[1] != addressItem.owner_name_2)) {
                diff.seller += ", " + addressItem.owner_name_2;
            }
        }
        return diff;
    }

    getAddressForManualEditCheckPromise(dataItemParsed) {
        if (!dataItemParsed.tax_map_data_id) {
            return Promise.resolve({
                success: true,
                result: [
                    {
                        property_full_address: "unspecified",
                        property_town_municipality: "",
                        print_key: "unspecified",
                        owner_name_1: "unspecified",
                    },
                ]
            });
        }
        return new Promise((resolve, reject) => {
            this.addressService.getAddressById(dataItemParsed.tax_map_data_id).subscribe((response) => {
                resolve(response);
            })
        });
    }

    hasAdditionalServices(cacheOrder) {
        if (cacheOrder.propertyServices) {
            for (const prop in cacheOrder.propertyServices) {
                if (cacheOrder.propertyServices.hasOwnProperty(prop)) {
                    if (cacheOrder.propertyServices[prop]) {
                        return true
                    }
                }
            }
        }
        if (cacheOrder.entityServices) {
            for (const prop in cacheOrder.entityServices) {
                if (cacheOrder.entityServices.hasOwnProperty(prop)) {
                    if (cacheOrder.entityServices[prop]) {
                        for (const prop2 in cacheOrder.entityServices[prop]) {
                            if (cacheOrder.entityServices[prop].hasOwnProperty(prop2)) {
                                if (cacheOrder.entityServices[prop][prop2]) {
                                    return true
                                }
                            }
                        }
                    }
                }
            }
        }
        if (cacheOrder.individualServices) {
            for (const prop in cacheOrder.individualServices) {
                if (cacheOrder.individualServices.hasOwnProperty(prop)) {
                    if (cacheOrder.individualServices[prop]) {
                        for (const prop2 in cacheOrder.individualServices[prop]) {
                            if (cacheOrder.individualServices[prop].hasOwnProperty(prop2)) {
                                if (cacheOrder.individualServices[prop][prop2]) {
                                    return true
                                }
                            }
                        }

                    }
                }
            }
        }
        return false
    }

    validateOrder(cacheOrder) {
        if (!cacheOrder.service_group_id) {
            return "Please select a search type on Step 1."
        }

        if (cacheOrder.service_group_id == "ADDITIONAL" && !this.hasAdditionalServices(cacheOrder)) {
            return "Please select one or more additional services on steps 2, 3 or 4."
        }
        if (cacheOrder.service_group_id != "ADDITIONAL" && !cacheOrder.primary_service_id) {
            return "Please select a search type on Step 1."
        }

        //further validation checks only apply to provider portal
        if (!cacheOrder.agent_id) {
            return "Please select a client company on Step 1."
        }
        if (!cacheOrder.user_id) {
            return "Please select a client user on Step 1."
        }
    }

    submit() {
        // if (this.saveButtonComponent.processing) {
        //     console.log("saving for later button has already been clicked");
        //     return;
        // };
        // if (this.submitButtonComponent.processing) {
        //     console.log("submit order button has already been clicked");
        //     return;
        // };
        var dataItem = localStorage.getItem("cached_order_draft");
        var dataItemParsed;
        if (dataItem) {
            dataItemParsed = JSON.parse(dataItem);
        } else {
            dataItemParsed = {};
        }

        //DEBUG THIS IS BAD MOJO
        //dataItemParsed.service_group_id = "ADDITIONAL"
        //dataItemParsed.primary_service_id = "UNMAPPED"

        this.error = null
        this.error = this.validateOrder(dataItemParsed)
        if (this.error) {
            return;
        }

        //this.submitButtonComponent.processing = true;

        this.getAddressForManualEditCheckPromise(dataItemParsed).then(function (dataItemParsed, response) {
            //console.log("got addr for check", response);
            if (response.success) {
                var diff = this.checkManualEdits(response.result[0]);
                //console.log("diff obj", diff);
                var statusCode = "N";
                // if (diff && !this.userService.isCurrentPortal('Provider Portal')) {
                //     statusCode = "Q";
                // }
                //console.log("saving order");
                this.orderService.saveOrder(dataItemParsed, statusCode).subscribe((response) => {
                    //console.log("save response", response)
                    if (response.success) {
                        //set the id and save it, so if there is an email issue, any re-submit is an update
                        dataItemParsed.uuid = response.uuid;
                        dataItemParsed.order_id = response.order_id;
                        localStorage.setItem("cached_order_draft", JSON.stringify(dataItemParsed));
                        this.bannerNotificationService.addNotification(response.uuid, response.order_id, BannerNotificationType.orderCreated)
                        this.navigateAway();

                        //removing creation email logic as per SIER-1587
                        // this.orderService.sendOrderSubmissionEmail(response.uuid, diff).subscribe((emailResponse) => {
                        //     this.submitButtonComponent.processing = false;
                        //     if (emailResponse.success) {
                        //         this.snackRouteService.snack("Order " + response.order_id + " submitted.")
                        //         this.navigateAway();
                        //     } else {
                        //         console.log("email send error", emailResponse);
                        //     }
                        // });
                    } else {
                        this.setSubmissionError(response);
                    }
                });
            } else {
                this.setSubmissionError(response);
            }
        }.bind(this, dataItemParsed));
    }

    setSubmissionError(response) {
        var errorText = response.message;
        // if (this.submitButtonComponent) {
        //     this.submitButtonComponent.processing = false;
        // }
        console.log("order submission error", response);
        this.orderSubmissionError = errorText;
        document.getElementById("submission-error").style.display = "block";
    }

    orderSubmissionOk() {
        document.getElementById("submission-error").style.display = "none";
    }
}
