import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { OrderService } from '../order.service';
import { SystemService } from '../system.service';
import { CompanyService } from '../company.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-order-sheet-html',
    templateUrl: './order-sheet-html.component.html',
    styleUrls: ['./order-sheet-html.component.css']
})
export class OrderSheetHtmlComponent implements OnInit {
    @ViewChild("imageCol", { read: ElementRef }) imageCol: ElementRef;
    initialized = false;
    order;
    provider;
    instructions =
        `PLEASE PROVIDE COPIES OF THE FOLLOWING:
    
    1. Full copies of all Deeds
    2. Full copies of Judgments
    3. Pertinent pages of the open mortgages
    4. Copy of pertinent portions of the Subdivision map
    5. Copy of tax map containing our parcel
    6. Full copies of all other documents
    7. Twenty year Judgement/Lien Search on all other parties`

    getInstructionsSplit() {
        return this.instructions.split("\n")
    }

    constructor(
        private _orderService: OrderService,
        private systemService: SystemService,
        private companyService: CompanyService,
        private activatedRoute: ActivatedRoute,
    ) { }
    serviceMapping;
    searchTypeDisplay;
    serviceRows;
    agent;
    orderUuid

    get orderService() {
        return this._orderService
    }

    getOrderIdParamPromise() {
        return new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe(params => {
                this.orderUuid = params.orderUuid
                resolve(this.orderUuid)
            })
        })
    }

    ngOnInit() {
        this.getOrderIdParamPromise().then(() => {
            this._orderService.getOrder(this.orderUuid).subscribe((response) => {
                this.order = response.result;
                this._orderService.getServiceMapping().subscribe((response) => {
                    this.serviceMapping = response.result;
                    this._orderService.getSearchTypeDisplay(this.orderUuid).subscribe((response) => {
                        this.searchTypeDisplay = response.result;
                        this._orderService.getServiceRows(this.orderUuid).subscribe((response) => {
                            this.serviceRows = response.result;
                            this.companyService.getCompanyById(this.order.agent_id).subscribe((response) => {
                                this.agent = response.result[0]
                                this.companyService.getCompanyById(this.order.provider_id).subscribe((response) => {
                                    this.provider = response.result[0];
                                    this.initialized = true;
                                    this.loadLogo();
                                })
                            })
                        })
                    })
                })
            });
        })
    }

    getImageWidth(width, height, newHeight) {
        var ratio = width / height;
        return ratio * newHeight;
    }

    loadLogo() {
        if (this.order.provider_id && this.provider && this.provider.company_logo) {
            this.systemService.getProfileFile(this.provider.company_logo).subscribe((response) => {
                var reader = new FileReader();
                reader.readAsDataURL(<any>response);
                reader.onloadend = () => {
                    var img = document.createElement('img');
                    (<any>img.src) = reader.result;
                    img.onload = function () {
                        var newHeight = 64;
                        var newWidth = this.getImageWidth(img.width, img.height, newHeight);
                        img.width = newWidth;
                        img.height = newHeight;
                        while (this.imageCol.nativeElement.firstChild) {
                            this.imageCol.nativeElement.removeChild(this.imageCol.nativeElement.firstChild);
                        }
                        this.imageCol.nativeElement.appendChild(img);
                    }.bind(this);
                }
            });
        }
    }

    arrayDisplay(array) {
        var display = "";
        if (array) {
            for (var i = 0; i < array.length; ++i) {
                if (i != 0) {
                    display += ", ";
                }
                display += array[i];
            }
        }
        return display;
    }
}
