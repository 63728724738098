export class SysMunicipality 
{
    state?: string;
    countyId?: string;
    countyName?: string;
    municipalityName?: string;
    type?: string;
    parentId?: string;
    childId?: string;
    realPropertyCode?: string;
    taxTypeName?: string;
    taxTypeId?: string;
    address?: string;
    taxYear?: string;
    taxPeriod?: string;
    lienDate?: string;
    fiscalStartDate?: Date;
    fiscalEndDate?: Date;
    dueDate1?: Date;
    dueDate2?: Date;
    dueDate3?: Date;
    dueDate4?: Date;
    refreshDate?: Date; 
}