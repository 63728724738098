import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard-order-on-hold',
    templateUrl: './dashboard-order-on-hold.component.html',
})
export class DashboardOrderOnHoldComponent implements OnInit {

    filter = {
        gate: "all",
        comparisons: [
            {
                field: "order_status",
                operator: "is",
                matchValue: "O"
            }
        ],

    }
    constructor() { }

    ngOnInit() {
    }

}
