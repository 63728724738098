import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SalesTaxService {

    constructor(
        private http: HttpClient,
    ) { }

    getCountySalesTax(county) {
        return this.http.get<any>("api/salesTax/" + county);
    }

    getSalesTaxes() {
        return this.http.get<any>("api/salesTax/");
    }

    getSalesTaxById(id) {
        return this.http.get<any>("api/salesTaxById/" + id);
    }
}
