import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    constructor() {}

    clear() : void
    {
        localStorage.clear()
        sessionStorage.clear()
    }
    
    setClaims(claims: any)
    {
        const claimsStr: string = JSON.stringify(claims)

        sessionStorage.setItem("claims", claimsStr)
        localStorage.setItem("claims", claimsStr)
    }

    getClaims(): any {
        // const claimsStr = sessionStorage.getItem("claims") // when using session storage getters when opening into a new tab the claims are lost
        const claimsStr = localStorage.getItem("claims")
        if(claimsStr)
        {
            let claimsObject: any = JSON.parse(claimsStr)
            return claimsObject
        }
        else
        {
            return {}
        }
    }

    getRoles(): string[]
    {
        const claims = this.getClaims()
        return claims.roles
    }

    getFeatures(): string[]
    {
        const claims = this.getClaims()
        return claims.features 
    }

    getFullName(): string
    {
        const claims = this.getClaims()
        return claims.fullName
    }

    getEmailAddress(): string
    {
        const claims = this.getClaims()
        return claims.emailAddress 
    }

    getToken(): string
    {
        return sessionStorage.getItem("access_token")
    }
    getAuthToken(): string
    {
        return sessionStorage.getItem("authToken")
    }

    admin: boolean
    // this is pulling from the sys_admin table, if a user_uuid is not in that table then this will be false
    isAdmin(): boolean {
        const claims = this.getClaims()
        if(claims.is_admin == 1) {
            this.admin = true
        } else {
            this.admin = false
        }
        return this.admin
    }
}
