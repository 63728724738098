<span *ngIf="!initialized">
    <div class="container-fluid bordered-container mt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.tertiaryColor"
        [style.border-color]="themeService.tertiaryColor">
        Initializing
    </div>
</span>
<div *ngIf="initialized">
    <div class="container-fluid mt-4 py-2">
        <app-dashboard-controls [title]="dashTitle" [pageNumber]="currentPage" [pageSize]="limit" [entryCount]="count"
            [setPage]="this.setPage.bind(this)">
        </app-dashboard-controls>
    </div>
    <div class="container-fluid mt-4 dashboard-header" [style.background-color]="themeService.tertiaryColor">
        <div class="row">
            <div class="col px-0">

                <table class="table table-striped">
                    <thead>
                        <tr class="table-header">
                            <th *ngFor="let columnDefinition of columnDefinitions; let isFirst = first; let k = index"
                                class="py-4"
                                [ngClass]="{'pointer': columnDefinition.field, 'text-center' : columnDefinition.center}"
                                (click)="doSort(columnDefinition.field)">
                                {{columnDefinition.title}}<span *ngIf="sortField == columnDefinition.field">
                                    <span *ngIf="sortDirection == 1">
                                        <fa-icon [icon]="faCaretDown" size="1x" title="sorted ascending"></fa-icon>
                                    </span>
                                    <span *ngIf="sortDirection == 0">
                                        <fa-icon [icon]="faCaretUp" size="1x" title="sorted descending"></fa-icon>
                                    </span>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of qualiaOrders">
                            <td *ngFor="let columnDefinition of columnDefinitions; let isFirst = first; let k = index"
                                class="py-4">
                                <span *ngIf="!columnDefinition.hideValue">
                                    {{item[columnDefinition.field]}}
                                </span>
                                <span *ngIf="columnDefinition.title == 'Apexiem Order #'">
                                    <span
                                        *ngFor="let apexiemOrder of item.apexiemOrders; let isFirstApexiemOrder = first">
                                        <span *ngIf="!isFirstApexiemOrder">, </span>
                                        <span class="link-rebrand"
                                            (click)="entryClick(apexiemOrder.uuid)">{{apexiemOrder.order_id}}</span>
                                    </span>
                                </span>
                                <app-status-indicator *ngIf="columnDefinition.title == 'Status'"
                                    [text]="qualiaOrderStatusesById[item.qualia_status_id].display"
                                    [bgColor]="qualiaOrderStatusesById[item.qualia_status_id].color">
                                </app-status-indicator>
                                <span *ngIf="columnDefinition.title == 'Action' && item.order_status == 'C'"
                                    style="white-space: nowrap;">
                                    <app-button label="Upload" [clickFunction]="doUpload.bind(this, item.api_order_id)"
                                        [icon]="faUpload" [small]="true">
                                    </app-button>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="container-fluid mt-4 py-2">
        <app-dashboard-controls title="Qualia Orders" [pageNumber]="currentPage" [pageSize]="limit" [entryCount]="count"
            [setPage]="this.setPage.bind(this)" [isFooter]="true">
        </app-dashboard-controls>
    </div>
</div>