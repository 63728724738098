import { SysCounty } from "../model/sys-county.model";

export const SYSCOUNTY: SysCounty[] = 

[
    {
      "countyId": 36001,
      "id": "36001_010000",
      "municipalityCode": 10000,
      "state" : "NY",
      "countyName": "Albany",
      "type": "county",
      "deprecated": "",
      "address": "Albany County Division of Finance\n112 State St, Room 1340\nAlbany, NY 12207\np: 518-477-7070 f: 518-447-5516",
      "taxYear": "2022",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": new Date("01-Jan-21"),
      "fiscalEndDate": new Date("31-Dec-21"),
      "dueDate1": new Date("01-Jan-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36003,
      "id": "36003_020000",
      "municipalityCode": 20000,
      "state" : "NY",
      "countyName": "Allegany",
      "type": "county",
      "deprecated": "",
      "address": "COURTHOUSE 7 COURT ST\nBELMONT, NY 14813\np: 585-268-9289 | f: 585-268-7506\n",
      "taxYear": "2020/2021",
      "taxPeriod": "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date("01-Jun-20"),
      "fiscalEndDate": new Date("31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36007,
      "id": "36007_030000",
      "municipalityCode": 30000,
      "state" : "NY",
      "countyName": "Broome",
      "type": "county",
      "deprecated": "",
      "address": "BC DIRECTOR OF OMD\n60 HAWLEY ST 2ND FLOOR PO BOX 1766\nBINGHAMTON, NY 13902\np: 607-778-2169 | f: 607-778-2359\n",
      "taxYear": "2020/2021",
      "taxPeriod": "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date("01-Jun-20"),
      "fiscalEndDate": new Date("31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36009,
      "id": "36009_040000",
      "municipalityCode": 40000,
      "state" : "NY",
      "countyName": "Cattaraugus",
      "type": "county",
      "deprecated": "",
      "address": "",
      "taxYear": "2020/2021",
      "taxPeriod": "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date("01-Jun-20"),
      "fiscalEndDate": new Date("31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36011,
      "id": "36011_050000",
      "municipalityCode": 50000,
      "state" : "NY",
      "countyName": "Cayuga",
      "type": "county",
      "deprecated": "",
      "address": "COUNTY OFFICE BLDG 5TH FLOOR 160 GENESEE ST\nAUBURN, NY 13021\np: 315-253-1211",
      "taxYear": "2020/2021",
      "taxPeriod": "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date("01-Jun-20"),
      "fiscalEndDate": new Date("31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36013,
      "id": "36013_060000",
      "municipalityCode": 60000,
      "state" : "NY",
      "countyName": "Chautauqua",
      "type": "county",
      "deprecated": "",
      "address": "",
      "taxYear": "2020/2021",
      "taxPeriod": "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date("01-Jun-20"),
      "fiscalEndDate": new Date("31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36015,
      "id": "36015_070000",
      "municipalityCode": 70000,
      "state" : "NY",
      "countyName": "Chemung",
      "type": "county",
      "deprecated": "",
      "address": "320 EAST MARKET ST PO BOX 588\nELMIRA, NY 14902\np: 607-737-2927",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36017,
      "id": "36017_080000",
      "municipalityCode": 80000,
      "state" : "NY",
      "countyName": "Chenango",
      "type": "county",
      "deprecated": "",
      "address": "5 COURT ST \nNORWICH, NY 13815\np: 607-337-1414 | f: 607-337-1425\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date("01-Jun-20"),
      "fiscalEndDate": new Date("31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36019,
      "id": "36019_090000",
      "municipalityCode": 90000,
      "state" : "NY",
      "countyName": "Clinton",
      "type": "county",
      "deprecated": "",
      "address": "137 MARGARET ST SUITE 205\nPLATTSBURGH, NY 12901\np: 581-565-4730",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36021,
      "id": "36021_100000",
      "municipalityCode": 100000,
      "state" : "NY",
      "countyName": "Columbia",
      "type": "county",
      "deprecated": "",
      "address": "15 N. 6TH ST\nHUDSON, NY 12534\np: 518-828-0513 | f: 518-822-1110\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-24")
    },
    {
      "countyId": 36023,
      "id": "36023_110000",
      "municipalityCode": 110000,
      "state" : "NY",
      "countyName": "Cortland",
      "type": "county",
      "deprecated": "",
      "address": "60 CENTRAL AVE ROOM 132\nCORTLAND, NY 13045\np: 607-753-5070 | f: 607-758-5512\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36025,
      "id": "36025_120000",
      "municipalityCode": 120000,
      "state" : "NY",
      "countyName": "Delaware",
      "type": "county",
      "deprecated": "",
      "address": "111MAIN ST SUITE 8\nDELI, NY 13753\np: 607-832-5130",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36027,
      "id": "36027_130000",
      "municipalityCode": 130000,
      "state" : "NY",
      "countyName": "Dutchess",
      "type": "county",
      "deprecated": "",
      "address": "22 MARKET ST \nPOUGHKEEPSIE, NY 12601\np: 845-486-2025",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36029,
      "id": "36029_140000",
      "municipalityCode": 140000,
      "state" : "NY",
      "countyName": "Erie",
      "type": "county",
      "deprecated": "",
      "address": "95 FRANKLIN ROOM 1100\nBUFFALO, NY 14202\nP: 716-858-8400\nF: 716-858-8507",
      "taxYear": "2021",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": new Date( "01-Jan-21"),
      "fiscalEndDate": new Date( "31-Dec-21"),
      "dueDate1": new Date("01-Jan-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36031,
      "id": "36031_150000",
      "municipalityCode": 150000,
      "state" : "NY",
      "countyName": "Essex",
      "type": "county",
      "deprecated": "",
      "address": "ESSEX COUNTY TREASURER\n7551 COURT ST PO BOX 217 \nELIZABETHTOWN, NY 12932\np: 518-873-3310 | f: 518-873-3315\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36033,
      "id": "36033_160000",
      "municipalityCode": 160000,
      "state" : "NY",
      "countyName": "Franklin",
      "type": "county",
      "deprecated": "",
      "address": "355 MAIN ST SUITE 140\nMALONE, NY 12953\np: 518-481-1512",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36035,
      "id": "36035_170000",
      "municipalityCode": 170000,
      "state" : "NY",
      "countyName": "Fulton",
      "type": "county",
      "deprecated": "",
      "address": "223 WEST MAIN ST\nJOHNSTOWN, NY 12095\np: 518-736-5580 | f: 518-736-1794\n",
      "taxYear": "2021",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": new Date( "01-Jan-21"),
      "fiscalEndDate": new Date( "31-Dec-21"),
      "dueDate1": new Date("01-Jan-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36037,
      "id": "36037_180000",
      "municipalityCode": 180000,
      "state" : "NY",
      "countyName": "Genesee",
      "type": "county",
      "deprecated": "",
      "address": "15 Main St\nBatavia, NY 14020\np: 585-815-7803",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36039,
      "id": "36039_190000",
      "municipalityCode": 190000,
      "state" : "NY",
      "countyName": "Greene",
      "type": "county",
      "deprecated": "",
      "address": "411 MAIN ST\n4TH FLOOR, PO BOX 191\nCatskill, NY 12414\np: 518-719-3530\nf: 518-719-3794",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36041,
      "id": "36041_200000",
      "municipalityCode": 200000,
      "state" : "NY",
      "countyName": "Hamilton",
      "type": "county",
      "deprecated": "",
      "address": "102 COUNTY VIEW DR PO BOX 206\nLAKE PLEASANT, NY 12108\np: 518-548-7911 | f: 518-548-4519\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36043,
      "id": "36043_210000",
      "municipalityCode": 210000,
      "state" : "NY",
      "countyName": "Herkimer",
      "type": "county",
      "deprecated": "",
      "address": "HERKIMER COUNTY TREASURER\n109 MARY ST SUITE 2419 \nHERKIMER, NY 13350\np: 315-867-1145",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36045,
      "id": "36045_220000",
      "municipalityCode": 220000,
      "state" : "NY",
      "countyName": "Jefferson",
      "type": "county",
      "deprecated": "",
      "address": "JEFFERSON COUNTY TREASURER\n175 ARSENAL ST\nWATERTOWN, NY 13601\np: 315-785-3055",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36047,
      "id": "36047_000000",
      "municipalityCode": 0,
      "state" : "NY",
      "countyName": "Kings",
      "type": "county",
      "deprecated": "",
      "address": "",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36049,
      "id": "36049_230000",
      "municipalityCode": 230000,
      "state" : "NY",
      "countyName": "Lewis",
      "type": "county",
      "deprecated": "",
      "address": "LEWIS COUNTY COURTHOUSE\nLOWVILLE, NY 13367\np: 315-376-5325 | f: 315-376-8552\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36051,
      "id": "36051_240000",
      "municipalityCode": 240000,
      "state" : "NY",
      "countyName": "Livingston",
      "type": "county",
      "deprecated": "",
      "address": "LIVINGSTON COUNTY GOVERNMENT CENTER\n 6 COURT ST ROO\nGENESEO, NY 14454\np: 585-243-7050/58 | f: 585-243-75947\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36053,
      "id": "36053_250000",
      "municipalityCode": 250000,
      "state" : "NY",
      "countyName": "Madison",
      "type": "county",
      "deprecated": "",
      "address": "MADISON COUNTY TREASURER\nPO BOX 665\nWAMPSVILLRE, NY 13163\np: 315-366-2371 | f: 315-366-2705\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36055,
      "id": "36055_260000",
      "municipalityCode": 260000,
      "state" : "NY",
      "countyName": "Monroe",
      "type": "county",
      "deprecated": "",
      "address": "MONROE COUNTY TREASURER\nMONROE COUNTY TREASURER PO BOX 14420 \nROCHESTER, NY 14614\np: 585-753-1200 | f: 585-753-1166\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36057,
      "id": "36057_270000",
      "municipalityCode": 270000,
      "state" : "NY",
      "countyName": "Montgomery",
      "type": "county",
      "deprecated": "",
      "address": "Montgomery County",
      "taxYear": "2021",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": new Date( ""),
      "fiscalEndDate": new Date( ""),
      "dueDate1": new Date("01-Jan-20"),
      "dueDate2": new Date("01-Apr-21"),
      "dueDate3": new Date("01-Jul-21"),
      "dueDate4": new Date("01-Oct-21"),
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36059,
      "id": "36059_280000",
      "municipalityCode": 280000,
      "state" : "NY",
      "countyName": "Nassau",
      "type": "county",
      "deprecated": "",
      "address": "",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36063,
      "id": "36063_290000",
      "municipalityCode": 290000,
      "state" : "NY",
      "countyName": "Niagara",
      "type": "county",
      "deprecated": "",
      "address": "PHILO J. BROOKS CO. OFFICE BLDG 1ST FLOOR\n59 PARK AVE\nLOCKPORT, NY 14094\np: 716-439-7007 | f: 716-439-7021\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36065,
      "id": "36065_300000",
      "municipalityCode": 300000,
      "state" : "NY",
      "countyName": "Oneida",
      "type": "county",
      "deprecated": "",
      "address": "COMMISSIONER OF FINANCE ONEIDA COUNTY FINANCE DEPA\n800 PARK AVE                                      \nUTICA, NY 13501\np: 315-798-571",
      "taxYear": "2021",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": new Date( ""),
      "fiscalEndDate": new Date( ""),
      "dueDate1": new Date("29-Jan-21"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36067,
      "id": "36067_310000",
      "municipalityCode": 310000,
      "state" : "NY",
      "countyName": "Onondaga",
      "type": "county",
      "deprecated": "",
      "address": "JOHN H, MULROY CIVIC CENTER\n421 MONTGOMERY STREET 15TH FLOOR\nSYRACUSE, NY 13202\np: 315-435-2426 | f: 315-435-2421\n",
      "taxYear": "2021",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": new Date( ""),
      "fiscalEndDate": new Date( ""),
      "dueDate1": new Date("05-Jan-21"),
      "dueDate2": new Date("05-Apr-21"),
      "dueDate3": new Date("05-Jul-21"),
      "dueDate4": new Date("05-Oct-21"),
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36069,
      "id": "36069_320000",
      "municipalityCode": 320000,
      "state" : "NY",
      "countyName": "Ontario",
      "type": "county",
      "deprecated": "",
      "address": "COUNTY TREASURER\n20 ONTARIO ST 2ND FLOOR\nCANANDAIGUA, NY 14424\np: 585-396-4432",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36071,
      "id": "36071_330000",
      "municipalityCode": 330000,
      "state" : "NY",
      "countyName": "Orange",
      "type": "county",
      "deprecated": "",
      "address": "COUNTY TREASURER\n265 MAIN ST\nGOSHEN, NY 10924\np: 845-291-2485 | f: 845-291-2516\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36073,
      "id": "36073_340000",
      "municipalityCode": 340000,
      "state" : "NY",
      "countyName": "Orleans",
      "type": "county",
      "deprecated": "",
      "address": "COUNTY TREASURER\n34 EAST PARK ST\nALBION, NY 14411\np: 585-589-5353 | f: 585-589-9220\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36075,
      "id": "36075_350000",
      "municipalityCode": 350000,
      "state" : "NY",
      "countyName": "Oswego",
      "type": "county",
      "deprecated": "",
      "address": "OSWEGO COUNTY TREASURER'S OFFICE\n46 EAST BRIDGE ST\nOSWEGO, NY 13126\np: 315-349-8393 | f: 315-349-8255\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36077,
      "id": "36077_360000",
      "municipalityCode": 360000,
      "state" : "NY",
      "countyName": "Otsego",
      "type": "county",
      "deprecated": "",
      "address": "OTSEGO COUNTY TREASURER\n197 MAIN ST \nCOOPERSTOWN, NY 13326\np: 607-547-4235 | f: 607-547-7579\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36079,
      "id": "36079_370000",
      "municipalityCode": 370000,
      "state" : "NY",
      "countyName": "Putnam",
      "type": "county",
      "deprecated": "",
      "address": "PUTNAM COUNTY TREASURER\n40 GLENEIDA AVE ROOM 202\nCARMEL, NY 10512\np: 845-808-1075 | f: 845-808-1910\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36083,
      "id": "36083_380000",
      "municipalityCode": 380000,
      "state" : "NY",
      "countyName": "Rensselaer",
      "type": "county",
      "deprecated": "",
      "address": "NED PATTISON CENTER\n1600 7TH AVE\nTROY, NY 12180\np: 518-270-2750",
      "taxYear": "2021",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": new Date( "01-Jan-21"),
      "fiscalEndDate": new Date( "31-Dec-21"),
      "dueDate1": new Date("01-Jan-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36087,
      "id": "36087_390000",
      "municipalityCode": 390000,
      "state" : "NY",
      "countyName": "Rockland",
      "type": "county",
      "deprecated": "",
      "address": "1 SOUTH MAIN STREET SUITE 100\nNEW CITYU, NY 10956\np: 845-638-5278",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36089,
      "id": "36089_400000",
      "municipalityCode": 400000,
      "state" : "NY",
      "countyName": "St. Lawrence",
      "type": "county",
      "deprecated": "",
      "address": "COUNTY TREASURER\n48 COURT ST\nCANTON, NY 13617\np: 315-379-2234",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36091,
      "id": "36091_410000",
      "municipalityCode": 410000,
      "state" : "NY",
      "countyName": "Saratoga",
      "type": "county",
      "deprecated": "",
      "address": "COUNTY TREASURER SARATOGA COUNTY MUNICIPAL COMPLEX\n40 MCMASTER ST BLDG1\nBALLSTON SPA, NY 12020\np: 518-884-4724",
      "taxYear": "2021-2022",
      "taxPeriod": "9/1-8/31",
      "lienDate": "9/1",
      "fiscalStartDate": new Date( "01-Jan-21"),
      "fiscalEndDate": new Date( "31-Dec-21"),
      "dueDate1": new Date("01-Sep-21"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36093,
      "id": "36093_420000",
      "municipalityCode": 420000,
      "state" : "NY",
      "countyName": "Schenectady",
      "type": "county",
      "deprecated": "",
      "address": "SCHENECTADY COUNTY FINANCE\n620 STATE STREET 3RD FLOOR\nSCHENECTADY, NY 12305\np: 518-388-4246",
      "taxYear": "2021",
      "taxPeriod": "1/1-12/31",
      "lienDate":  "1/1",
      "fiscalStartDate": new Date( "01-Jan-21"),
      "fiscalEndDate": new Date( "31-Dec-21"),
      "dueDate1": new Date("01-Jan-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36095,
      "id": "36095_430000",
      "municipalityCode": 430000,
      "state" : "NY",
      "countyName": "Schoharie",
      "type": "county",
      "deprecated": "",
      "address": "SCHOHARIE COUNTY FINANCE\nPO BOX 9\nSCHOHARIE, NY 12157\np: 518-295-8386 | f: 518-295-8361\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36097,
      "id": "36097_440000",
      "municipalityCode": 440000,
      "state" : "NY",
      "countyName": "Schuyler",
      "type": "county",
      "deprecated": "",
      "address": "SCHUYLER COUNTY TREASURER\n105 NINTH ST UNIT 17\nWATKINS GLEN , NY 14891\np: 607-535-8181",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36099,
      "id": "36099_450000",
      "municipalityCode": 450000,
      "state" : "NY",
      "countyName": "Seneca",
      "type": "county",
      "deprecated": "",
      "address": "SENECA COUNTY TREASURER SENECA COUNTY OFFICE BUILD\n1 DIPRONIODR\nWATERLOO, NY 13165\np: 315-539-1735 | f: 315-539-1731\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36101,
      "id": "36101_460000",
      "municipalityCode": 460000,
      "state" : "NY",
      "countyName": "Steuben",
      "type": "county",
      "deprecated": "",
      "address": "COUNTY TREASURER\n3 EAST PULTENEY SQUARE\nBATH, NY 14810\np: 607-664-2488 | f: 607-664-2188\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36103,
      "id": "36103_470000",
      "municipalityCode": 470000,
      "state" : "NY",
      "countyName": "Suffolk",
      "type": "county",
      "deprecated": "",
      "address": "H. LEE DENNISION BUILDING\n100 VETERANS MEMORIAL HIGHWAY\nHAUPPAUGE, NY 11788\np: 631-853-5040",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36105,
      "id": "36105_480000",
      "municipalityCode": 480000,
      "state" : "NY",
      "countyName": "Sullivan",
      "type": "county",
      "deprecated": "",
      "address": "SULLIVAN COUNTY GOVERNMENT CENTER\n100 NORTH ST PO BOX 5012\nMONTICELLO, NY 12701\np: 845-807-0200 | f: 845-807-0220\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36107,
      "id": "36107_490000",
      "municipalityCode": 490000,
      "state" : "NY",
      "countyName": "Tioga",
      "type": "county",
      "deprecated": "",
      "address": "TIOGA COUNTY TREASURER\n56 MAIN ST ROOM 210\nOWEGO, NY 13827\np: 607-687-8670",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36109,
      "id": "36109_500000",
      "municipalityCode": 500000,
      "state" : "NY",
      "countyName": "Tompkins",
      "type": "county",
      "deprecated": "",
      "address": "TOMPKINS COUNTY TREASURER\n125 EAST COURT ST 2ND FLOOR OLD JAIN\nITHACA, NY 14850\np: 607-274-5545",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36111,
      "id": "36111_510000",
      "municipalityCode": 510000,
      "state" : "NY",
      "countyName": "Ulster",
      "type": "county",
      "deprecated": "",
      "address": "ULSTER COUNTY TREASURER\nPO BOX 1800 \nKINGSTON, NY 12402\np: 845-340-3000 | f: 845-340-3430\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36113,
      "id": "36113_520000",
      "municipalityCode": 520000,
      "state" : "NY",
      "countyName": "Warren",
      "type": "county",
      "deprecated": "",
      "address": "WARREN COUNTY MUNICIPAL CENTER\n1340 STATE ROUTE 8\nLAKE GEORGE, NY 12845\np: 518-761-6375 | f: 518-761-6470\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36115,
      "id": "36115_530000",
      "municipalityCode": 530000,
      "state" : "NY",
      "countyName": "Washington",
      "type": "county",
      "deprecated": "",
      "address": "WASHINGTON COUNTY MUNICIPAL CENTER\n383 BROADWAY BUILDING B 2ND FLOOR\nFORT EDWARD, NY 12828\np: 518-746-2220 | f: 518-746-2234\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36117,
      "id": "36117_540000",
      "municipalityCode": 540000,
      "state" : "NY",
      "countyName": "Wayne",
      "type": "county",
      "deprecated": "",
      "address": "WAYNE COUNTY TREASURER\n16 WILLIAM ST PO BOX 8\nLYONS, NY 14489\np: 315-946-7441 | f: 315-946-5949\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36119,
      "id": "36119_550000",
      "municipalityCode": 550000,
      "state" : "NY",
      "countyName": "Westchester",
      "type": "county",
      "deprecated": "",
      "address": "",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36121,
      "id": "36121_560000",
      "municipalityCode": 560000,
      "state" : "NY",
      "countyName": "Wyoming",
      "type": "county",
      "deprecated": "",
      "address": "WYOMING COUNTY TREASURER\n143 NORTH MAIN ST SUITE 303\nWARSAW, NY 14569\np: 585-786-8828 | f: 585-786-8827\n",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date( "01-Jun-20"),
      "fiscalEndDate": new Date( "31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "countyId": 36123,
      "id": "36123_570000",
      "municipalityCode": 570000,
      "state" : "NY",
      "countyName": "Yates",
      "type": "county",
      "deprecated": "",
      "address": "YATES COUNTY TREASURER\n417 LIBERTY ST \nPENN YANN, NY 14527\np: 315-536-5150",
      "taxYear": "2020/2021",
      "taxPeriod":  "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": new Date("01-Jun-20"),
      "fiscalEndDate": new Date("31-May-21"),
      "dueDate1": new Date("01-Jun-20"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    }
   ]