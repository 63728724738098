<div [ngClass]="{
    'action-button': true,
    'action-button_default': buttonType == ButtonType.default || buttonType == ButtonType.cancel,
    'action-button_save': buttonType == ButtonType.save,
    'action-button_delete': buttonType == ButtonType.delete,
    'action-button_next': buttonType == ButtonType.next,
    'mx-2': true,
    'action-button_small': small
    }" (click)="doClick()" [title]="title" #theButton>
    <fa-icon *ngIf="!iconRight && processing" [icon]="faSpinner" [spin]="true" size="1x"></fa-icon>
    <fa-icon *ngIf="!iconRight && icon && !processing" class="mr-1" [icon]="icon" size="1x"></fa-icon>
    {{label}}
    <fa-icon *ngIf="iconRight && processing" [icon]="faSpinner" [spin]="true" size="1x"></fa-icon>
    <fa-icon *ngIf="iconRight && icon && !processing" class="mr-1" [icon]="icon" size="1x"></fa-icon>
</div>