import { Component, OnInit, ViewChild } from '@angular/core';
import { SystemService } from '../system.service';
import { ThemeService } from '../theme.service';
import { faSave, faBan } from '@fortawesome/free-solid-svg-icons';
import { Md5 } from 'ts-md5/dist/md5';
import { Router } from '@angular/router';
import { ButtonComponent } from '../button/button.component';

@Component({
    selector: 'app-security-questions-set',
    templateUrl: './security-questions-set.component.html',
})
export class SecurityQuestionsSetComponent implements OnInit {
    @ViewChild(ButtonComponent) private submitButton: ButtonComponent;

    initialized = false;
    questions;
    faSave = faSave;
    faBan = faBan
    error;
    existingQuestionAnswers;

    constructor(
        private _themeService: ThemeService,
        private systemService: SystemService,
        private router: Router,
    ) { }

    get themeService() {
        return this._themeService;
    }

    ngOnInit() {
        this.systemService.getAllSecurityQuestions().subscribe(response => {
            //console.log("questions response", response);
            if (response.success) {
                this.questions = response.result;
            }
            this.systemService.getUserSecurityQuestions().subscribe(response => {
                if (response.success) {
                    this.existingQuestionAnswers = response.result;
                    console.log("existing answers", this.existingQuestionAnswers)
                }
                this.initialized = true;
            });
        });
    }

    answerExists(id) {
        if (this.existingQuestionAnswers) {
            for (var i = 0; i < this.existingQuestionAnswers.length; ++i) {
                //console.log("debug", id, this.existingQuestionAnswers[i].question_id)
                if (this.existingQuestionAnswers[i].question_id == id) {
                    //console.log("checked");
                    return true;
                }
            }
        }
        return false;
    }

    toggleDisabled(id) {
        var elem = document.getElementById(id);
        var isDisabled = elem.hasAttribute("disabled");
        console.log("isDisabled", isDisabled)
        if (isDisabled) {
            elem.removeAttribute("disabled");
            document.getElementById(id).focus();
        } else {
            document.getElementById(id).setAttribute('disabled', 'true');
        }
    }

    toggleCheckbox(id) {
        var elem = document.getElementById(id + "_checkbox");
        (<HTMLInputElement>elem).checked = !(<HTMLInputElement>elem).checked;
        this.toggleDisabled(id);
    }

    submit() {
        if (this.submitButton.processing) {
            console.log("submit button has already been clicked");
            return;
        }
        var selectedQuestions = [];
        for (var i = 0; i < this.questions.length; ++i) {
            var elem = document.getElementById(this.questions[i].id + "_checkbox");
            if ((<HTMLInputElement>elem).checked) {
                var answer = (<HTMLInputElement>document.getElementById(this.questions[i].id)).value;
                if (!this.answerExists(this.questions[i].id) && (!answer || !answer.trim())) {
                    this.error = "Selected question has no answer.";
                    console.log(this.error);
                    return;
                }
                for (var j = 0; j < selectedQuestions.length; ++j) {
                    if (selectedQuestions[j].answer == answer) {
                        this.error = "More than one question has the same answer.";
                        console.log(this.error);
                        return;
                    }
                }
                if (this.answerExists(this.questions[i].id)) {
                    selectedQuestions.push({ id: this.questions[i].id });
                } else {
                    selectedQuestions.push({ id: this.questions[i].id, answer: answer });
                }
            }
        }
        for (var i = 0; i < selectedQuestions.length; ++i) {
            if (selectedQuestions[i].answer) {
                selectedQuestions[i].answer = new Md5().appendStr(selectedQuestions[i].answer).end()
            }
        }

        if (selectedQuestions.length < 3) {
            this.error = "At least 3 questions must be answered.";
            console.log(this.error);
            return;
        }
        this.submitButton.processing = true;
        //console.log(selectedQuestions);
        this.error = null;
        this.systemService.saveUserSecurityQuestions({ questions: selectedQuestions }).subscribe((response) => {
            if (response.success) {
                this.router.navigate(['user-edit']);
            } else {
                console.log("error while submitting question answers", response);
            }
            this.submitButton.processing = false;
        })
    }

    questionAnswerChanged(id) {
        var elem = document.getElementById(id);
        if (!elem) {
            return false;
        }
        return (<HTMLInputElement>elem).value;
    }

    showCancelChange(id) {
        console.log("show cancel for", id, this.answerExists(id), this.questionAnswerChanged(id));
        return this.answerExists(id) && this.questionAnswerChanged(id);
    }

    checkShowCancel(id) {
        var elem = document.getElementById(id + "_showcancel");
        if (this.answerExists(id)) {
            elem.style.display = "block";
        }
    }

    getPlaceholder(id) {
        if (this.answerExists(id)) {
            return "Change Security Question Answer";
        }
        return "Security Question Answer"
    }

    cancelChange(id) {
        var elem = document.getElementById(id + "_showcancel");
        if (this.answerExists(id)) {
            elem.style.display = "none";
        }
        (<HTMLInputElement>document.getElementById(id)).value = null;
    }

    cancel() {
        this.router.navigate(['user-edit']);
    }
}
