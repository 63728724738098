import { Component, OnInit, Input } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuid } from 'uuid';

export enum InputCheckboxFormat {
    verticalLabel,
    none,
}

@Component({
    selector: 'app-input-checkbox',
    templateUrl: './input-checkbox.component.html',
})
export class InputCheckboxComponent implements OnInit {
    InputCheckboxFormat = InputCheckboxFormat
    @Input() label;
    @Input() fc;
    @Input() change;
    @Input() keyupEnter;
    @Input() click
    @Input() disabled
    @Input() format = InputCheckboxFormat.verticalLabel;
    @Input() tooltip
    faInfoCircle = faInfoCircle
    id = uuid()

    constructor() { }

    ngOnInit() {
    }

    doChange(e) {
        if (this.change) {
            this.change(e);
        }
    }

    doClick(e) {
        if (this.click) {
            this.click(e);
        }
    }

    doEnter() {
        if (this.keyupEnter) {
            this.keyupEnter();
        }
    }

}
