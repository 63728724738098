import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class ChargebeeService {
    // private newCheckoutURL = 'api/create_account';
    private getHostedPageURL = 'api/get_hosted_page';
    private listPlansURL = 'api/list_plans';
    private getPlanURL = "api/get_plan";
    private getFeaturesURL = "api/get_features";
    private listAddonsURL = "api/list_addons";

    constructor(
        private http: HttpClient,
    ) { }

    getHostedPage(pageId) {
        return this.http.post<any>(this.getHostedPageURL,
            { page: pageId }, httpOptions);
    }

    listPlans() {
        return this.http.get<any>(this.listPlansURL);
    }

    getPlan() {
        return this.http.get<any>(this.getPlanURL, httpOptions);
    }

    getChargebeeParams() {
        return this.http.get<any>("api/getChargebeeParams");
    }

    getFeatures(planId) {
        return this.http.post<any>(this.getFeaturesURL,
            { planId: planId }, httpOptions);
    }

    listAddons() {
        return this.http.get<any>(this.listAddonsURL);
    }
}
