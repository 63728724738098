<div *ngIf="companyData$ | async as companyData" class="container-fluid pt-2">
    <div class="row">
        <div class="col-2 d-none d-md-block">
        </div>
        <div class="col-12 col-md-8">

            <div class="row">
                <div class="col bold" style="font-size: 1.6em; color: #117ac9">
                    <span *ngIf="isNew">New</span>
                    <span *ngIf="!isNew">Edit</span>
                    Vendor
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <app-input-image label="Company Logo" [fc]="formControls['company_logo']"
                        [supportedTypes]="['image/jpeg']" [disabled]="isReadOnly">
                    </app-input-image>
                    <span *ngIf="formErrors['logo']" class="validation-error mt-1">{{formErrors['logo']}}</span>
                </div>
                <div class="col-md">
                    <app-input-text label="Web Address" [fc]="formControls['web_address']" [disabled]="isReadOnly"
                        exampleText="e.g. https://www.example.com">
                    </app-input-text>
                    <span *ngIf="formErrors['web_address']"
                        class="validation-error mt-1">{{formErrors['web_address']}}</span>
                    <app-input-text-area label="Address" [fc]="formControls['address']" [disabled]="isReadOnly"
                        [rows]="3" placeholder="Please enter the company's physical address.">
                    </app-input-text-area>
                    <span *ngIf="formErrors['address']" class="validation-error mt-1">{{formErrors['address']}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <app-input-text label="Company Name" [fc]="formControls['name']" [disabled]="isReadOnly">
                    </app-input-text>
                    <span *ngIf="formErrors['name']" class="validation-error mt-1">{{formErrors['name']}}</span>
                </div>
                <div class="col-md">
                    <app-input-enum label="Default Time Zone" [fc]="formControls['default_time_zone']"
                        [enumValues]="timezones$ | async" [disabled]="isReadOnly">
                    </app-input-enum>
                    <span *ngIf="formErrors['default_time_zone']"
                        class="validation-error mt-1">{{formErrors['default_time_zone']}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <app-input-text label="Phone Number" [fc]="formControls['phone']" [change]="phoneUpdate.bind(this)"
                        [exampleText]="phoneExampleText" [disabled]="isReadOnly">
                    </app-input-text>
                    <span *ngIf="formErrors['phone']" class="validation-error mt-1">{{formErrors['phone']}}</span>
                </div>
                <div class="col-md">
                    <app-input-text label="Fax Number" [fc]="formControls['fax']" [change]="faxUpdate.bind(this)"
                        [exampleText]="phoneExampleText" [disabled]="isReadOnly">
                    </app-input-text>
                    <span *ngIf="formErrors['fax']" class="validation-error mt-1">{{formErrors['fax']}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <app-input-text label="Default Email" [fc]="formControls['notify_email_address']"
                        [disabled]="isReadOnly">
                    </app-input-text>
                    <span *ngIf="formErrors['notify_email_address']"
                        class="validation-error mt-1">{{formErrors['notify_email_address']}}</span>
                </div>
                <div class="col-md">
                    <app-input-text label="Billing Email" [fc]="formControls['invoice_email_address']"
                        [disabled]="isReadOnly">
                    </app-input-text>
                    <span *ngIf="formErrors['invoice_email_address']"
                        class="validation-error mt-1">{{formErrors['invoice_email_address']}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <app-input-enum label="Terms" [fc]="formControls['default_payment_term_id']"
                        [enumValuesNameAndId]="dueDates$ | async" [disabled]="isReadOnly">
                    </app-input-enum>
                    <span *ngIf="formErrors['default_payment_term_id']"
                        class="validation-error mt-1">{{formErrors['default_payment_term_id']}}</span>
                </div>
                <div class="col-md">
                    <div class="row mt-3">
                        <div class="col bold">
                            Track Payments for 1099?
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col">
                            <input type="checkbox" class="ml-2" style="height: 1.8em; width: 1.8em;"
                                [formControl]="formControls['track_1099']">
                        </div>
                    </div>
                    <span *ngIf="formErrors['track_1099']"
                        class="validation-error mt-1">{{formErrors['track_1099']}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <app-input-text label="EIN" [fc]="formControls['ein']" [disabled]="isReadOnly">
                    </app-input-text>
                    <span *ngIf="formErrors['ein']" class="validation-error mt-1">{{formErrors['ein']}}</span>
                </div>
                <div class="col-md">
                    <app-input-text-area label="Default Invoice Notes" [fc]="formControls['default_invoice_notes']"
                        [disabled]="isReadOnly" [rows]="3">
                    </app-input-text-area>
                    <span *ngIf="formErrors['default_invoice_notes']"
                        class="validation-error mt-1">{{formErrors['default_invoice_notes']}}</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="anyError" class="row mt-3 validation-error-banner py-2">
        <div class="col-12" align="center">
            <fa-icon [icon]="faExclamationCircle"></fa-icon>
            Please address errors above and re-submit.
        </div>
    </div>

    <div class="row pt-3">
    </div>
    <app-button-group-create *ngIf="isNew" [create]="save.bind(this)" [cancel]="back.bind(this)">
    </app-button-group-create>
    <app-button-group-save *ngIf="!isNew" [save]="save.bind(this)" [cancel]="back.bind(this)"
        [delete]="delete.bind(this, companyData)"></app-button-group-save>

</div>