<div class="container-fluid mt-5">
    <div class="row">
        <div class="col" align="center">
            <img src="assets/logo.png" />
        </div>
    </div>
</div>
<div *ngIf="initialized" class="container-fluid mt-5 shadow" style="width: 800px; border-radius: .5em;"
    [style.background-color]="themeService.tertiaryColor"
    [style.border-color]="themeService.secondaryColor">
    <app-account-step-circles [stepProvider]="accountService" disableStepLinks="true">
    </app-account-step-circles>

    <div *ngIf="promotion" class="row pb-1 pt-3">
        <div class="col-1"></div>
        <div class="col-5 mt-3 bold" align="center">
            <div>Company Type:</div>
            <div class="choose-company-tile" style="max-width: 150px;"
                (mouseenter)="tileMouseover(this.accountService.accountCreateCompanyType)"
                (mouseleave)="tileMouseout(this.accountService.accountCreateCompanyType)">
                <div align="right" style="position: absolute; right: 0;">
                    <fa-icon [icon]="faCheck" class="mr-2" style="color: #003263;"
                        id="{{this.accountService.accountCreateCompanyType}}_check"></fa-icon>
                </div>
                <img [src]="getImageForCompanyType(this.accountService.accountCreateCompanyType)" class="vcentered"
                    id="{{this.accountService.accountCreateCompanyType}}_img" />
            </div>
            <div>{{companyTypesById[accountService.accountCreateCompanyType].name}}</div>

            <div class="mt-4 py-3 no-credit-card">*no credit card required</div>
        </div>
        <div class="col-5 mt-3 bold" align="center">
            <div>Plan:</div>
            <div class="text-center mx-2 my-3 choose-plan-tile" id="{{plan.plan.id}}_tile"
                (mouseenter)="tileMouseoverPlan(plan.plan)" (mouseleave)="tileMouseoutPlan(plan.plan)">

                <p class="my-2 bold tile-title" style="font-size: 1.6em;" id="{{plan.plan.id}}_title">{{plan.plan.name}}
                </p>
                <div>
                    <p class="my-2 mx-2 user-count" style="line-height: 1.8em; font-size: 1.4em">
                        14 Day Free Trial!
                    </p>
                    <p class="my-2 mx-2 plan-text" style="line-height: 1.8em;">
                        New York State property search tool
                    </p>
                </div>
                <p class="my-1 user-count">&nbsp;{{getUserCount(plan)}}</p>
                <p class="mt-1 mb-0">
                    <span style="font-weight: bold;">${{plan.plan.totalPrice / 100}}</span>
                    <span> / per {{plan.plan.period_unit}}</span>
                </p>
                <p style="font-size: .8em;">(after trial)</p>
            </div>
        </div>
    </div>
    <div class="row pb-1 pt-3">
        <div class="col-2">
        </div>
        <div class="col-8">
            <form>
                <app-input-text label="Email Address" [fc]="emailFC" [keyupEnter]="next.bind(this)">
                </app-input-text>
                <app-input-password label="Password" [fc]="passwordFC" [keyupEnter]="next.bind(this)">
                </app-input-password>
                <app-input-password label="Confirm Password" [fc]="password2FC" [keyupEnter]="next.bind(this)"
                    [hideRules]="true">
                </app-input-password>
                <div *ngIf="error" class="row">
                    <div class="col" style="color: red;">
                        {{error}}
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row mt-4 pb-5">
        <div class="col mt-3 bold" align="center">
            <div class="action-button action-button_secondary" (click)="back()">
                <fa-icon [icon]="faLongArrowAltLeft" class="mr-2"></fa-icon>BACK
            </div>
        </div>
        <div class="col mt-3 bold" align="center">
            <div class="action-button action-button_primary" (click)="next()">NEXT<fa-icon [icon]="faLongArrowAltRight"
                                                                     class="ml-2">
                </fa-icon>
            </div>
        </div>
    </div>
</div>
