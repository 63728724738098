import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { iPrimengColumn } from '../model/primeng-column.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OrderService } from '../order.service';
import { OrdersService } from '../services/order.service';


@Component({
  selector: 'app-additional-costs',
  templateUrl: './additional-costs.component.html',
  styleUrl: './additional-costs.component.css'
})
export class AdditionalCostsComponent implements OnInit
{
  @Input() additionalCosts: [];
  @Input() orderUuid: string;
  @Input() providerId: string;

  @Output() costChange = new EventEmitter<void>();

  additionalCostDialogVisable: boolean = false;
  editCostDialogVisable: boolean = false;

  costForm: FormGroup
  additionalCostDropDownOptions

  additionalCostColumns: iPrimengColumn[] = 
  [
    { header: 'Type', field: 'name' },
    { header: 'Description', field: 'custom_description' },
    { header: 'Cost Per Unit', field: 'price' },
    { header: 'Units', field: 'quantity' },
  ]

  constructor
  (
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private orderService: OrderService,
    private ordersService: OrdersService
  ) {}

  showAdditionalCostDialog()
  {
    this.clearCostForm();
    this.additionalCostDialogVisable = true;
  }

  hideAdditionalCostDialog()
  {
    this.additionalCostDialogVisable = false;
  }

  showEditCostDialog(cost) 
  {
    this.updateCostFormWithData(cost);
    this.editCostDialogVisable = true;
  }

  hideEditCostDialog()
  {
    this.editCostDialogVisable = false;
  }

  initCostForm()
  {
    this.costForm = this.fb.group({
      uuid: [''],
      type: ['', [Validators.required]],
      description: ['',],
      price: [0, [Validators.required]],
      quantity: [0, [Validators.required]]
    })
    this.getAdditionalCostOptions();
  }

  updateCostFormWithData(cost) 
  {
    this.costForm.patchValue({
      uuid: cost.uuid,
      type: cost.service_id,
      description: cost.custom_description,
      price: cost.price,
      quantity: cost.quantity
    });
  }
  

  clearCostForm() 
  {
    this.costForm.reset({
      type: '',
      description: '',
      price: 0,
      quantity: 0
    });
  }
  

  addCost()
  {
    if (this.costForm.invalid) 
    {
      this.costForm.markAllAsTouched();
      return;
    }
    const formValues = this.costForm.value

    const selectedOption = this.additionalCostDropDownOptions.find(option => option.service_id === formValues.type);
  
    const { service_id, name } = selectedOption;

    this.orderService.addAdditionalCost
    (
      this.orderUuid,
      formValues.price,
      formValues.quantity,
      formValues.description,
      service_id,
      name
    ).subscribe({
      next: () => {
        this.costChange.emit();
        this.messageService.add({ severity: 'success', summary: 'New Cost Added', life: 3000 });
        this.hideAdditionalCostDialog();
      },
      error: (error) => {
        console.error('Error while adding new cost: ', error);
      }
    })
  }
  
  saveCost()
  {
    if (this.costForm.invalid) 
    {
      this.costForm.markAllAsTouched();
      return;
    }

    const formValues = this.costForm.value
    const selectedOption = this.additionalCostDropDownOptions.find(option => option.service_id === formValues.type);
  
    const { service_id, name } = selectedOption;

    this.orderService.updateAdditionalCost
    (
      this.orderUuid,
      formValues.uuid,
      formValues.price,
      formValues.quantity,
      formValues.description,
      service_id,
      name
    ).subscribe({
      next: () => {
        this.costChange.emit();
        this.messageService.add({ severity: 'success', summary: 'Cost Updated', life: 3000 });
        this.hideEditCostDialog();
      },
      error: (error) => {
        console.error('Error while updating Cost: ', error);
      }
    })
  }

  deleteCost(cost)
  {
    this.orderService.deleteAdditionalCost(cost.uuid).subscribe({
      next: (response) => {
        this.costChange.emit();
        this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Cost Deleted', life: 3000 })
      },
      error: (error) => {
        console.error('An error occured while deleting cost: ', error)
      }
    })
  }

  confirmDeleteCost(event: Event, cost)
  {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure you want to delete this Cost',
      header: 'Confirm Delete',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      acceptIcon:"none",
      rejectIcon:"none",
      acceptButtonStyleClass:"p-button-danger",
      rejectButtonStyleClass:"p-button-text",
      accept: () => {
        this.deleteCost(cost)
      },
      reject: () => {
        this.messageService.add({severity: 'error', summary: 'Canceled', detail: 'Cost Deletion Canceled', life: 3000 })
      }
    })
  }

  getAdditionalCostOptions()
  {
    this.ordersService.getAdditionalCostOptions(this.providerId).subscribe({
      next: (result) => {
        this.additionalCostDropDownOptions = result
      },
      error: (error) => {
        console.error('Error while getting additional cost drop down options: ', error)
      }
    })
  }

  ngOnInit()
  {
    this.initCostForm();
    this.costForm.get('type')?.valueChanges.subscribe(selectedType => {
      const selectedOption = this.additionalCostDropDownOptions.find(option => option.service_id === selectedType);

      if (selectedOption) 
      {
        this.costForm.patchValue({
          price: selectedOption.price_1
        });
      }
    });
  }
}
