<span>
    <div class="container-fluid bordered-container mt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.tertiaryColor"
        [style.border-color]="themeService.tertiaryColor">

        <div class="row mt-3">
            <div class="col bold">
                The system has encountered an unexpected error.
            </div>
        </div>

        <div class="row" *ngFor="let error of systemService.clientError">
            <div class="col">
                {{error}}
            </div>
        </div>
        <div class="row mt-4 mb-4">
            <div class="col">
                Please contact us if the problem persists.
            </div>
        </div>
    </div>
</span>