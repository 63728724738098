<mat-paginator style="float: right;" [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons role="table"></mat-paginator>

  <mat-form-field class="container">
    <mat-label>Filter</mat-label>
    <input matInput (input)="applyFilter($event.target.value)" placeholder="Ex. Albany" #input>
  </mat-form-field>
  <table mat-table [dataSource]="municipalityDataSource" class="mat-elevation-z8">
  
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef> State </th>
      <td mat-cell *matCellDef="let municipality"> {{municipality.state}} </td>
    </ng-container>

    <ng-container matColumnDef="county">
      <th mat-header-cell *matHeaderCellDef> County </th>
      <td mat-cell *matCellDef="let municipality"> {{municipality.countyName}} </td>
    </ng-container>

    <ng-container matColumnDef="municipalityName">
        <th mat-header-cell *matHeaderCellDef> Municipality </th>
        <td mat-cell *matCellDef="let municipality">
          <span>
            <a class="municipality-link" style="color: #337ab7;" 
            (click)="openDialog(municipality)">
               {{municipality.municipalityName}} 
            </a>
          </span>
        </td>
      </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let municipality"> {{municipality.type}} </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef> Code </th>
      <td mat-cell *matCellDef="let municipality"> {{municipality.realPropertyCode}} </td>
    </ng-container>

    <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef class="tax-period-column"> Address </th>
        <td mat-cell *matCellDef="let municipality"> {{municipality.address}} </td>
      </ng-container>

    <ng-container matColumnDef="taxType">
        <th mat-header-cell *matHeaderCellDef> Tax Type </th>
        <td mat-cell *matCellDef="let municipality" class="tax-type-column"> {{municipality.taxTypeName}} </td>
    </ng-container>

    <ng-container matColumnDef="taxYear">
        <th mat-header-cell *matHeaderCellDef> Tax Year </th>
        <td mat-cell *matCellDef="let municipality"> {{municipality.taxYear}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="dueDate1">
        <th mat-header-cell *matHeaderCellDef> Due Date 1 </th>
        <td mat-cell *matCellDef="let municipality"> {{municipality.dueDate1 | date: 'M/d/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="dueDate2">
        <th mat-header-cell *matHeaderCellDef> Due Date 2 </th>
        <td mat-cell *matCellDef="let municipality"> {{municipality.dueDate2 | date: 'M/d/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="dueDate3">
        <th mat-header-cell *matHeaderCellDef> Due Date 3 </th>
        <td mat-cell *matCellDef="let municipality"> {{municipality.dueDate3 | date: 'M/d/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="dueDate4">
        <th mat-header-cell *matHeaderCellDef> Due Date 4 </th>
        <td mat-cell *matCellDef="let municipality"> {{municipality.dueDate4 | date: 'M/d/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="refreshDate">
      <th mat-header-cell *matHeaderCellDef> Run Date </th>
      <td mat-cell *matCellDef="let municipality"> {{municipality.refreshDate | date: 'M/d/yyyy'}} </td>
  </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
    <!-- Row shown when there is no matching municipality. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No municipality matching the filter "{{input.value}}"</td>
    </tr>
  </table>
  