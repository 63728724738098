<div class="container-fluid mt-4 bordered-container" style="width: 60%;" [style.color]="themeService.secondaryColor"
    [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.secondaryColor">

    <div class="row mt-2">
        <div class="col bold" align="center" style="font-size: 1.6em;">
            Contact Us
        </div>
    </div>
    <!-- <div class="row mt-2">
        <div class="col" align="center">
            Cape Office
        </div>
    </div>
    <div class="row">
        <div class="col" align="center">
            197 Palmer Ave
        </div>
    </div>
    <div class="row">
        <div class="col" align="center">
            Falmouth, MA 02540
        </div>
    </div>
    <div class="row">
        <div class="col" align="center">
            518.861.5661
        </div>
    </div>
    <div class="row mt-2">
        <div class="col" align="center">
            <iframe width="300" height="300"
                src="https://maps.google.com/maps?q=197%20Palmer%20Ave%2C%20Falmouth%2C%20MA&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </div>
    </div> -->

    <span *ngIf="thankYouMessage">
        <div class="row mt-2">
            <div class="col" align="center">
                {{thankYouMessage}}
            </div>
        </div>
    </span>

    <form *ngIf="!thankYouMessage" (submit)="submitContactForm($event)" id="contactForm">
        <div *ngIf="contactFormError" class="row mt-3">
            <div class="col" align="center">
                {{contactFormError}}
            </div>
        </div>
        <div class="row mt-2">
            <div class="col" align="center">
                <input type="text" class="login-input-style" placeholder="Full Name" id="fullname" />
            </div>
        </div>
        <div class="row mt-2">
            <div class="col" align="center">
                <input type="text" class="login-input-style" placeholder="Company" id="company" />
            </div>
        </div>
        <div class="row mt-2">
            <div class="col" align="center">
                <input type="text" class="login-input-style" placeholder="Email" id="email" />
            </div>
        </div>
        <div class="row mt-2">
            <div class="col" align="center">
                <input type="text" class="login-input-style" placeholder="Phone" id="phone" />
            </div>
        </div>
        <div class="row mt-2">
            <div class="col" align="center">
                <select class="login-input-style" id="subject">
                    <option value="" disabled selected>Subject</option>
                    <option>I need technical support</option>
                    <option>I would like a product demo</option>
                    <option>I need my password reset</option>
                    <option>I am interested in employment opportunities</option>
                    <option>I am interested in investment opportunities</option>
                    <option>I have a question</option>
                </select>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col" align="center">
                <textarea rows="4" placeholder="Message" class="login-input-style" id="message"
                    style="resize: both; height: unset"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col" align="center">
                <app-button label="Cancel" [clickFunction]="cancel.bind(this)"></app-button>
                <input class="action-button action-button_medium mt-3 mb-4 ml-2" type="submit" value="Submit Contact Form" #submitContactFormButton
                    [style.border-color]="themeService.secondaryColor"
                    [style.background-color]="themeService.secondaryColor"
                    (mouseover)="submitContactFormButton.style.backgroundColor = themeService.backgroundColor; submitContactFormButton.style.color = themeService.secondaryColor;"
                    (mouseout)="submitContactFormButton.style.backgroundColor = themeService.secondaryColor; submitContactFormButton.style.color = themeService.primaryColor;" />
            </div>
        </div>
    </form>
</div>
