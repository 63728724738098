import { Component, OnInit, ViewChildren, QueryList, Inject } from '@angular/core';
import { ThemeService } from '../theme.service';
import { OrderService } from '../order.service';
import { UntypedFormControl } from '@angular/forms';
import { DocumentsService } from '../documents.service';
import {
    faFileDownload, faEdit, faTimes, faExclamationTriangle,
    faCheck, faMap, faMapPin, faFileAlt,
    faFileInvoice, faPause, faPlay, faFilePdf,
    faBan, faEnvelope, faShare, faHourglass,
    faMagic, faUpload, faTrashAlt, faPlus,
    faSave, faStop, faPercent, faPrint, faKeyboard
} from '@fortawesome/free-solid-svg-icons';
import { ButtonComponent, ButtonType } from '../button/button.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';
import { CompanyService } from '../company.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemService } from '../system.service';
import Mustache from 'mustache';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpRequest, HttpEventType, HttpErrorResponse, HttpResponse, HttpClient } from '@angular/common/http';
import { SnackRouteService } from '../snack-route.service';
import { TeamService } from '../team.service';
import { WorkflowService } from '../workflow.service';
import { QualiaService } from '../qualia.service';
import { InputTextAreaFormat } from '../input-text-area/input-text-area.component';
import { iPrimengColumn } from '../model/primeng-column.model';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-searcher-order-edit',
    templateUrl: './searcher-order-edit.component.html',
    styleUrls: ['./searcher-order-edit.component.css']
})
export class SearcherOrderEditComponent implements OnInit {
    ButtonType = ButtonType
    InputTextAreaFormat = InputTextAreaFormat
    faFileDownload = faFileDownload;
    faEdit = faEdit;
    faMap = faMap
    faMapPin = faMapPin
    faFileAlt = faFileAlt
    faFileInvoice = faFileInvoice
    faPause = faPause
    faPlay = faPlay
    faFilePdf = faFilePdf
    faBan = faBan
    faEnvelope = faEnvelope
    faShare = faShare
    faHourglass = faHourglass
    faMagic = faMagic
    faUpload = faUpload
    faTrashAlt = faTrashAlt
    faPlus = faPlus
    faSave = faSave

    faStop = faStop
    faPercent = faPercent
    faPrint = faPrint
    faKeyboard = faKeyboard

    mustache = Mustache

    initialized = false;
    cacheOrder;
    searchTypeData;
    serviceById = {};
    entityServices = {};
    individualServices = {};
    orderStatuses
    orderStatusById = {}
    addressItem
    editingInternalNotes = false
    editingLongNotes = false
    internalNotesFC
    longNotesFC
    longNotes
    workflowView = {};
    documentView = {};
    orderLog;
    @ViewChildren(ButtonComponent) buttonComponents: QueryList<ButtonComponent>;
    documentTypeToDisplay = {};
    providerName;
    providerId;
    orderInvoiceId

    deliverySubject
    deliveryTo
    deliveryBcc
    deliveryBody
    deliveryCCFC = new UntypedFormControl()
    additionalCommentsFC = new UntypedFormControl()

    sblDocuments
    fileFormControl = new UntypedFormControl();
    currentSingleUploadFile = null;

    //note: deprecated document types are removed from the documentTypes array
    documentTypes = [];
    allDocumentTypes = [];
    uploadClicked = false;
    percentDone = null;
    uploadStatus = "Uploading";

    uploadError;
    showAddContainer = false;

    sblColumns: iPrimengColumn[] =
    [
      { header: 'Order #', field: 'order_number' },
      { header: 'Description', field: 'description' },
      { header: 'Type', field: 'document_type' },
      { header: 'Created', field: 'created' }
    ];

    historyColumns: iPrimengColumn[] = 
    [
      { header: 'Date', field: 'event_time' },
      { header: 'Company', field: 'principal_entity' },
      { header: 'Status', field: 'order_status' },
      { header: 'Desciption', field: 'log_text' },
      { header: 'Full Name', field: 'principal_name' },
      { header: 'Comments', field: 'comments' }
    ]

    emailDocs = {};

    get showActionButtons() {
        return true
    }

    get showNotesAndCosts() {
        return true
    }

    get showProgress() {
        return true
    }

    get showSBLDocuments() {
        return true
    }

    get showHistory() {
        return true
    }

    constructor(
        private _themeService: ThemeService,
        private _orderService: OrderService,
        private documentsService: DocumentsService,
        private router: Router,
        private _userService: UserService,
        private companyService: CompanyService,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog,
        private systemService: SystemService,
        private http: HttpClient,
        private snackRouteService: SnackRouteService,
        private activatedRoute: ActivatedRoute,
        private teamService: TeamService,
        private workflowService: WorkflowService,
        private qualiaService: QualiaService,
        private datePipe: DatePipe
    ) { }

    get userService() {
        return this._userService;
    }

    getDocumentTypesPromise() {
        return new Promise((resolve, reject) => {
            this.documentsService.getKCSDocumentTypes().subscribe((response) => {
                //console.log("document types: ", response);
                for (var i = 0; i < response.result.length; ++i) {
                    this.allDocumentTypes.push(response.result[i]);
                    if (!response.result[i].deprecated) {
                        this.documentTypes.push(response.result[i]);
                    }
                }
                resolve(null)
            });
        })
    }

    getSBLDocumentsPromise() {
        return new Promise((resolve, reject) => {
            this.documentsService.getDocumentsForSBL(this.cacheOrder.sbl, this.cacheOrder.uuid, this.cacheOrder.county_name).subscribe((response) => {
                //console.log("sbl documents fetch result", response);
                this.sblDocuments = response.result;
                resolve(null)
            });
        })
    }

    getDocumentTypeDisplay(documentType) {
        for (var i = 0; i < this.allDocumentTypes.length; ++i) {
            if (this.allDocumentTypes[i].id == documentType) {
                return this.allDocumentTypes[i].name;
            }
        }
    }

    scrollToAdd() {
        this.showAddContainer = !this.showAddContainer;
        if (this.showAddContainer) {
            setTimeout(function () {
                document.getElementById("addTitle").scrollIntoView(true);
            }, 0);
        }
    }

    handleFileInput(files) {
        this.currentSingleUploadFile = files[0];
        if (!(<HTMLInputElement>document.getElementById("newfile_description")).value) {
            (<HTMLInputElement>document.getElementById("newfile_description")).value = this.currentSingleUploadFile.name;
        }
    }

    clearForm() {
        (<HTMLInputElement>(document.getElementById("newfile_type"))).value = "-1";
        (<HTMLInputElement>(document.getElementById("newfile_description"))).value = "";
        this.fileFormControl.setValue(null);
        this.currentSingleUploadFile = null;
        this.uploadClicked = false;
    }

    upload() {
        if (this.uploadClicked) {
            console.log("upload already clicked");
            return;
        }

        if ((<HTMLInputElement>document.getElementById("newfile_type")).value == "-1") {
            alert("No attachment type selected");
            return;
        }

        if (this.currentSingleUploadFile == null) {
            alert("No file selected");
            return;
        }

        this.uploadStatus = "Uploading";

        this.uploadClicked = true;
        const status: { [key: string]: { progress: Observable<number> } } = {};

        // create a new multipart-form for every file
        const formData: FormData = new FormData();
        formData.append('fileKey', this.currentSingleUploadFile, this.currentSingleUploadFile.name);
        formData.append("document_type", (<HTMLInputElement>document.getElementById("newfile_type")).value);
        formData.append("state", "NY");
        formData.append("county", this.cacheOrder.county_name);
        formData.append("sbl", this.cacheOrder.sbl);
        formData.append("address", null);
        formData.append("order_uuid", this.cacheOrder.uuid);
        formData.append("description", (<HTMLInputElement>document.getElementById("newfile_description")).value);
        formData.append("status", "A");
        formData.append("scope", "private");
        formData.append("file_name", this.currentSingleUploadFile.name);

        formData.append("insert_without_order_uuid", "true");

        var url = "api/document";
        const req = new HttpRequest('PUT', url, formData, {
            reportProgress: true
        });

        const progress = new BehaviorSubject<number>(0);
        this.percentDone = 0;
        this.http.request(req).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {

                this.percentDone = Math.round(100 * event.loaded / event.total);
                if (this.percentDone == 100) {
                    this.uploadStatus = "Processing";
                }
                //console.log("progress report", this.percentDone);
                progress.next(this.percentDone);
            } else if (event instanceof HttpErrorResponse) {
                this.uploadError = (<any>event.message);
                this.uploadClicked = false;
            } else if (event instanceof HttpResponse) {

                progress.complete();
                this.percentDone = null;
                console.log(event);
                if ((<any>event.body).success) {
                    this.refetchOrderDocuments()
                    this.clearForm();
                } else {
                    this.uploadError = (<any>event.body).message;
                    this.uploadClicked = false;
                }
            }
        });

        // Save every progress-observable in a map of all observables
        status[this.currentSingleUploadFile.name] = {
            progress: progress.asObservable()
        };

        // return the map of progress.observables
        progress.asObservable().subscribe((result) => {
            console.log("progress:", result);
        })
        return status;
    }

    finishUpload() {
        this.documentsService.finishUpload(this.cacheOrder.order_id).subscribe(response => {
            if (response.success) {
                this.refetchOrderDocuments();
                window.scrollTo(0, 0);
                this.showAddContainer = false;
            }
        });
    }

    onFileDeleted()
    {
        console.log('File Deleted In Child Component');
        this.refetchOrderDocuments();
    }

    deleteOrderFile(doc) {
        console.log("deleting doc", doc)
        if (confirm('Are you sure you want to delete document ' + doc.description + "?")) {
            this.documentsService.deleteOrderFile(doc.uuid, this.orderUuid).subscribe((response) => {
                this.refetchOrderDocuments();
            });
        }
    }

    emailTemplate
    getDeliveryEmailTemplatePromise() {
        return new Promise((resolve, reject) => {
            this.systemService.getEmailTemplate("document_delivery").subscribe((response) => {
                //console.log("email response", response)
                if (response.success) {
                    this.emailTemplate = response.result[0]
                } else {
                    console.log("error while getting delivery email template", response)
                }
                resolve(null)
            })
        })
    }

    emailData
    getDeliveryEmailDataPromise(documentUuid) {
        return new Promise((resolve, reject) => {
            this.documentsService.getDeliveryEmailData(documentUuid).subscribe((response) => {
                //console.log("email data response", response)
                if (response.success) {
                    resolve(response.result[0])
                } else {
                    console.log("error while getting delivery email data", response)
                    reject(response)
                }
            })
        })
    }

    categoryHeaders = ["Final Documents", "Documents Associated to Order"]

    refetchOrderDocuments() {
        //console.log("refetching")
        this.documentView = {};
        this._orderService.getOrder(this.orderUuid).subscribe((response) => {
            console.log()
            //rearrange attached documents for display
            if (response.result.attached_documents) {
                for (var i = 0; i < response.result.attached_documents.length; ++i) {
                    //if (response.result.attached_documents[i].document_category == "F" || response.result.attached_documents[i].document_category == "C") {
                    // if (!this.documentView[response.result.attached_documents[i].category_name]) {
                    //     this.documentView[response.result.attached_documents[i].category_name] = [];
                    // }
                    // this.documentView[response.result.attached_documents[i].category_name].push(response.result.attached_documents[i]);
                    //}
                    var categoryHeader
                    if (response.result.attached_documents[i].document_category == "F") {
                        categoryHeader = "Final Documents"
                    } else {
                        categoryHeader = "Documents Associated to Order"
                    }
                    if (!this.documentView[categoryHeader]) {
                        this.documentView[categoryHeader] = [];
                    }
                    this.documentView[categoryHeader].push(response.result.attached_documents[i]);
                }
            }
        })
    }

    processWorkflowProgressForDisplay() {
        this.workflowView = {};
        if (this.cacheOrder.workflow_progress) {
            for (var i = 0; i < this.cacheOrder.workflow_progress.length; ++i) {
                //skip custom cost entries
                if (!this.cacheOrder.workflow_progress[i].uuid) {
                    continue;
                }
                if (!this.workflowView[this.cacheOrder.workflow_progress[i].workflow_name]) {
                    this.workflowView[this.cacheOrder.workflow_progress[i].workflow_name] = [];
                    this.workflowView[this.cacheOrder.workflow_progress[i].workflow_name].push(this.cacheOrder.workflow_progress[i]);
                    this.workflowView[this.cacheOrder.workflow_progress[i].workflow_name][0].serviceNames = [];
                }
                this.workflowView[this.cacheOrder.workflow_progress[i].workflow_name][0].serviceNames.push(
                    this.serviceById[this.cacheOrder.workflow_progress[i].service_id]);
                this.cacheOrder.workflow_progress[i].difficultyLevelFC = new UntypedFormControl(this.cacheOrder.workflow_progress[i].price_level)
                this.cacheOrder.workflow_progress[i].rushFC = new UntypedFormControl(this.cacheOrder.workflow_progress[i].rush)
            }
        }
        //console.log("workflow progress", this.cacheOrder.workflow_progress)
    }

    difficultyLevels = [
        { name: "1", id: 1 },
        { name: "2", id: 2 },
        { name: "3", id: 3 },
        { name: "4", id: 4 },
        { name: "5", id: 5 },
    ]

    difficultyLevelChange(workflow) {
        var priceLevel = workflow.difficultyLevelFC.value
        if (priceLevel == "null") {
            priceLevel = null
        }
        this.workflowService.updateWorkflowPriceLevel(workflow.uuid, priceLevel).subscribe((response) => {
            if (response.success) {
                this._snackBar.open("Workflow difficulty level updated.", null, {
                    duration: 4000,
                });
            } else {
                console.log("error while updating difficulty level", response)
            }
        })
    }

    rushChange(workflow) {
        var rush = workflow.rushFC.value
        if (rush == "null") {
            rush = null
        }
        this.workflowService.updateWorkflowRush(workflow.uuid, rush).subscribe((response) => {
            if (response.success) {
                this._snackBar.open("Workflow rush updated.", null, {
                    duration: 4000,
                });
            } else {
                console.log("error while updating rush", response)
            }
        })
    }

    refreshWorkflows() {
        return new Promise((resolve, reject) => {
            this._orderService.getOrder(this.orderUuid).subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                console.log("refreshing workflows", response)
                this.cacheOrder.workflow_progress = response.result.workflow_progress
                this.processWorkflowProgressForDisplay()
                resolve(null)
            })
        })
    }

    get additionalCostsEditable() {
        return this.cacheOrder.qualia_status_id != "S"
    }

    additionalCosts
    getAdditionalCostsPromise() {
        return new Promise((resolve, reject) => {
            this._orderService.getAdditionalCosts(this.orderUuid).subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                this.additionalCosts = response.result

                for (var i = 0; i < this.additionalCosts.length; ++i) {
                    this.additionalCosts[i].customDescriptionFC = new UntypedFormControl({
                        value: this.additionalCosts[i].custom_description,
                        disabled: !this.additionalCostsEditable
                    })
                    this.additionalCosts[i].priceFC = new UntypedFormControl({
                        value: this.additionalCosts[i].price,
                        disabled: !this.additionalCostsEditable
                    })
                    this.additionalCosts[i].quantityFC = new UntypedFormControl({
                        value: this.additionalCosts[i].quantity,
                        disabled: !this.additionalCostsEditable
                    })
                }
                resolve(null)
            })
        })
    }

    deleteAdditionalCost(additionalCost) {
        return new Promise((resolve, reject) => {
            this._orderService.deleteAdditionalCost(additionalCost.uuid).subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }

                for (var i = 0; i < this.additionalCosts.length; ++i) {
                    if (additionalCost.uuid == this.additionalCosts[i].uuid) {
                        this.additionalCosts.splice(i, 1)
                        break
                    }
                }

                resolve(null)
            })
        })
    }

    handleCostChange()
    {
        this.getAdditionalCostsPromise()
    }

    cancelAdditionalCost(additionalCost) {
        additionalCost.priceFC.setValue(additionalCost.price)
        additionalCost.priceFC.markAsPristine()

        additionalCost.quantityFC.setValue(additionalCost.quantity)
        additionalCost.quantityFC.markAsPristine()

        additionalCost.customDescriptionFC.setValue(additionalCost.custom_description)
        additionalCost.customDescriptionFC.markAsPristine()
    }

    orderUuid
    getOrderIdParamPromise() {
        return new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe(params => {
                this.orderUuid = params.orderUuid
                resolve(this.orderUuid)
            })
        })
    }

    userTeamIds
    getCurrentUserTeamsPromise() {
        return new Promise((resolve, reject) => {
            this.teamService.getTeams().subscribe((response) => {
                if (!response.success) {
                    console.log(response)
                }
                this.userTeamIds = []
                for (var i = 0; i < response.result.length; ++i) {
                    this.userTeamIds.push(response.result[i].id)
                }
                resolve(null)
            })
        })
    }
    workflowStatusById = {};
    workflowStatuses

    getWorkflowStatusesPromise() {
        new Promise((resolve, reject) => {
            this.workflowService.getWorkflowStatuses().subscribe((response) => {
                if (response.success) {
                    for (var i = 0; i < response.result.length; ++i) {
                        this.workflowStatusById[response.result[i].id] = response.result[i];
                    }
                    this.workflowStatuses = response.result
                } else {
                    console.log("error while getting workflow statuses")
                }
                resolve(null);
            });
        })
    }

    qualiaMessages
    getQualiaMessagesPromise() {
        new Promise((resolve, reject) => {
            this.qualiaService.getQualiaOrderMessages(this.cacheOrder.api_order_id).subscribe((response) => {
                if (response.success) {
                    this.qualiaMessages = response.result
                    this.qualiaMessagesRollupFiles()
                } else {
                    console.log("error while getting qualia messages", response)
                }
                resolve(null);
            })
        })
    }

    qualiaMessagesRolledUp
    qualiaMessagesRollupFiles() {
        this.qualiaMessagesRolledUp = []

        if (!this.qualiaMessages || this.qualiaMessages.length == 0 || this.qualiaMessages[0].message_uuid == null) {
            return
        }

        var lastQualiaMessage
        for (var i = 0; i < this.qualiaMessages.length; ++i) {
            if (lastQualiaMessage == null || lastQualiaMessage.message_uuid != this.qualiaMessages[i].message_uuid) {
                this.qualiaMessages[i].attachments = []
                this.qualiaMessagesRolledUp.push(this.qualiaMessages[i])
                lastQualiaMessage = this.qualiaMessages[i]
            }
            lastQualiaMessage.attachments.push({
                uuid: this.qualiaMessages[i].attachment_uuid,
                file_name: this.qualiaMessages[i].file_name,
            })
        }
    }

    refreshQualiaMessagesPromise() {
        if (this.cacheOrder.api_order_id) {
            new Promise((resolve, reject) => {
                this.qualiaService.getQualiaOrderMessagesRefresh(this.cacheOrder.api_order_id).subscribe((response) => {
                    if (response.success) {
                        this.qualiaMessages = response.result
                        this.qualiaMessagesRollupFiles()
                    } else {
                        console.log("error while refreshing qualia statuses", response)
                    }
                    resolve(null);
                })
            })
        }
        return Promise.resolve()
    }

    refreshOrderState() {
        this._orderService.getOrder(this.orderUuid).subscribe((response) => {
            this.cacheOrder = response.result;
        })
    }

    ngOnInit() {
        this.getOrderIdParamPromise().then(() => {
            return this.getCurrentUserTeamsPromise()
        }).then(() => {
            this.documentsService.getDocumentTypes().subscribe((response) => {
                //console.log("document types: ", response);
                for (var i = 0; i < response.result.length; ++i) {
                    this.documentTypeToDisplay[response.result[i].id] = response.result[i].name;
                }
                this._orderService.getPrimarySearchTypeData((searchTypeData) => {
                    this.searchTypeData = {};
                    for (var i = 0; i < searchTypeData.length; ++i) {
                        this.searchTypeData[searchTypeData[i].id] = searchTypeData[i];
                        searchTypeData[i].servicesById = {};
                        for (var j = 0; j < searchTypeData[i].services.length; ++j) {
                            searchTypeData[i].servicesById[searchTypeData[i].services[j].id] = searchTypeData[i].services[j];
                        }
                    }
                    //console.log("search type data", this.searchTypeData)
                    this._orderService.getAllServices().subscribe((response) => {
                        //console.log(response);
                        for (var i = 0; i < response.result.length; ++i) {
                            this.serviceById[response.result[i].id] = response.result[i].name;
                        }
                        //console.log(this.serviceById);
                        this._orderService.getOrder(this.orderUuid).subscribe((response) => {
                            this.cacheOrder = response.result;
                            this.providerId = this.cacheOrder.provider_id
                            //console.log("debug, order", this.cacheOrder)
                            if (this.cacheOrder && this.cacheOrder.property_seller) {
                                this.cacheOrder.property_seller_formatted = this.cacheOrder.property_seller.join(", ")
                            }
                            if (this.cacheOrder && this.cacheOrder.property_buyer) {
                                this.cacheOrder.property_buyer_formatted = this.cacheOrder.property_buyer.join(", ")
                            }
                            if (this.cacheOrder && this.cacheOrder.assessed_owner) {
                                this.cacheOrder.assessed_owner_formatted = this.cacheOrder.assessed_owner.join(", ")
                            }
                            if (this.cacheOrder) {
                                this.internalNotesFC = new UntypedFormControl(this.cacheOrder.internal_notes);
                                this.longNotesFC = new UntypedFormControl(this.cacheOrder.long_notes);
                                this.longNotes = this.cacheOrder.long_notes
                            }
                            if (this.cacheOrder && this.cacheOrder.entityServices) {
                                for (var name in this.cacheOrder.entityServices) {
                                    if (this.cacheOrder.entityServices.hasOwnProperty(name)) {
                                        for (var serviceProp in this.cacheOrder.entityServices[name]) {
                                            if (!this.entityServices[serviceProp]) {
                                                this.entityServices[serviceProp] = [];
                                            }
                                            this.entityServices[serviceProp].push(name);
                                        }
                                    }
                                }
                            }

                            if (this.cacheOrder && this.cacheOrder.individualServices) {
                                for (var name in this.cacheOrder.individualServices) {
                                    if (this.cacheOrder.individualServices.hasOwnProperty(name)) {
                                        for (var serviceProp in this.cacheOrder.individualServices[name]) {
                                            if (!this.individualServices[serviceProp]) {
                                                this.individualServices[serviceProp] = [];
                                            }
                                            this.individualServices[serviceProp].push(name);
                                        }
                                    }
                                }
                            }
                            this.orderService.getOrderStatuses().subscribe((response) => {
                                if (response.success) {
                                    this.orderStatuses = response.result
                                    for (var i = 0; i < response.result.length; ++i) {
                                        this.orderStatusById[response.result[i].id] = response.result[i];
                                    }
                                } else {
                                    console.log("error while getting order statuses", response);
                                }

                                this.processWorkflowProgressForDisplay()

                                //rearrange attached documents for display
                                if (this.cacheOrder.attached_documents) {
                                    for (var i = 0; i < this.cacheOrder.attached_documents.length; ++i) {
                                        var categoryHeader
                                        if (this.cacheOrder.attached_documents[i].document_category == "F") {
                                            categoryHeader = "Final Documents"
                                        } else {
                                            categoryHeader = "Documents Associated to Order"
                                        }
                                        if (!this.documentView[categoryHeader]) {
                                            this.documentView[categoryHeader] = [];
                                        }
                                        this.documentView[categoryHeader].push(this.cacheOrder.attached_documents[i]);

                                    }
                                }
                                this.getDocumentTypesPromise().then(() => {
                                    return this.getSBLDocumentsPromise()
                                }).then(() => {
                                    return this.getDeliveryEmailTemplatePromise()
                                }).then(() => {
                                    return this.getOrderHistory()
                                }).then(() => {
                                    return this.getAdditionalCostsPromise()
                                }).then(() => {
                                    return this.getWorkflowStatusesPromise()
                                }).then(() => {
                                    return this.getQualiaMessagesPromise()
                                }).then(() => {
                                    this.refreshQualiaMessagesPromise()
                                    this._orderService.orderHasInvoice(this.orderUuid).subscribe((response) => {
                                        if (response.success) {
                                            this.orderInvoiceId = null;
                                            if (response.result.length > 0) {
                                                this.orderInvoiceId = response.result[0].id
                                            }
                                        } else {
                                            console.log("error while checking order invoice", response)
                                        }
                                        this.deliverySubject = this.emailTemplate.email_subject
                                        this.deliveryBody = this.emailTemplate.email_body
                                        this.searchTypeDisplay = this.getSearchTypeDisplay()
                                        this.searchCategoryDisplay = this.getSearchCategoryDisplay()

                                        this.initialized = true;
                                    })
                                })
                            })
                        })
                    })
                })
            })
        })
    }

    searchTypeDisplay
    searchCategoryDisplay

    getOrderHistory() {
        return new Promise((resolve, reject) => {
            this.orderService.getOrderLog(this.cacheOrder.uuid).subscribe((response) => {
                //console.log("order log", response);
                if (response.success) {
                    this.orderLog = response.result;
                } else {
                    console.log("error while retrieving order history", response);
                }
                if (this.cacheOrder && this.cacheOrder.provider_id) {
                    this.companyService.getCompanyById(this.cacheOrder.provider_id).subscribe((response) => {
                        if (response.success && response.result && response.result.length > 0) {
                            this.providerName = response.result[0].name;
                        }
                        resolve(null);
                    })
                } else {
                    resolve(null);
                }
            });
        })
    }

    getFirstObjProp(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return prop;
            }
        }
    }

    get themeService() {
        return this._themeService;
    }

    get orderService() {
        return this._orderService;
    }

    getOrderText() {
        return JSON.stringify(this.cacheOrder, null, 4);
    }

    getSearchTypeDisplay() {
        var order = this.cacheOrder;
        var services = this.searchTypeData;
        for (var property in services) {
            if (services.hasOwnProperty(property)) {
                if (services[property].id == order.primary_service_id) {
                    return services[property].name;
                }
                if (services[property].services) {
                    for (var j = 0; j < services[property].services.length; ++j) {
                        if (services[property].services[j].id == order.primary_service_id) {
                            //return services[property].name + ": " + services[property].services[j].name;
                            return services[property].services[j].name;
                        }
                    }
                }
            }
        }
    }

    getSearchCategoryDisplay() {
        var order = this.cacheOrder;
        var services = this.searchTypeData;
        for (var property in services) {
            if (services.hasOwnProperty(property)) {
                if (services[property].id == order.service_group_id) {
                    return services[property].name;
                }
            }
        }
    }

    objectKeys(obj) {
        return Object.keys(obj);
    }

    hasPropertyServices() {
        return this.cacheOrder.propertyServices && this.getFirstObjProp(this.cacheOrder.propertyServices);
    }

    hasEntityServices() {
        return this.getFirstObjProp(this.entityServices);
    }

    hasIndividualServices() {
        return this.getFirstObjProp(this.individualServices);
    }

    clickEditInternalNotes() {
        this.editingInternalNotes = true;
    }

    clickCancelInternalNotes() {
        this.internalNotesFC.setValue(this.cacheOrder.internal_notes)
        this.editingInternalNotes = false;
        this.snackRouteService.snack("Quick notes change cancelled.")
    }

    clickSaveInternalNotes() {
        this.cacheOrder.internal_notes = this.internalNotesFC.value;
        this.orderService.updateInternalNotes(this.cacheOrder.uuid, this.cacheOrder.internal_notes).subscribe((response) => {
            if (!response.success) {
                return console.log("Internal notes save error", response)
            }
            this.editingInternalNotes = false;
            this.snackRouteService.snack("Quick notes saved.")
        });
    }

    clickEditLongNotes() {
        this.editingLongNotes = true;
    }

    clickCancelLongNotes() {
        this.longNotesFC.setValue(this.cacheOrder.long_notes)
        this.editingLongNotes = false;
        this.snackRouteService.snack("Internal notes change cancelled.")
    }

    clickSaveLongNotes() {
        this.cacheOrder.long_notes = this.longNotesFC.value;
        this.orderService.updateLongNotes(this.cacheOrder.uuid, this.cacheOrder.long_notes).subscribe((response) => {
            if (!response.success) {
                return console.log("Long notes save error", response)
            }
            this.editingLongNotes = false;
            this.snackRouteService.snack("Internal notes saved.")
        });
    }

    downloadQualiaMessageAttachment(attachment) {
        this.qualiaService.downloadQualiaMessageAttachment(attachment.uuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body]);
            const url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            link.download = attachment.file_name;
            link.click();
        });
    }

    download(doc) {
        var id = doc.uuid
        if (!id) {
            id = doc.guid
        }
        this.documentsService.download(id).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body]);
            const url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            link.download = doc.filename;
            link.click();
        });
    }

    downloadAll(documentCategory) {
        if (!this.documentView[documentCategory] || this.documentView[documentCategory].length == 0) {
            return this.snackRouteService.snack("No documents to download.")
        }
        for (var i = 0; i < this.documentView[documentCategory].length; ++i) {
            this.download(this.documentView[documentCategory][i]);
        }
    }

    downloadOrderSheetPDF() {
        this._orderService.getOrderSheetPDF(this.orderUuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body]);
            const url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            link.download = "ordersheet.pdf";//todo - add date, maybe sbl?
            link.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 0)
        });
    }

    viewOrderSheetPDF() {
        this._orderService.getOrderSheetPDF(this.orderUuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body], { type: 'application/pdf' });
            //console.log("blob name", blob)
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        });
    }

    nav(route) {
        this.router.navigate([route])
    }

    editSBLClick() {
        this.router.navigate(["/searcher-order-edit-sbl"], { queryParams: { orderUuid: this.orderUuid } })
    }

    viewOrderSheetClick() {
        this.router.navigate(["/order-sheet-html"], { queryParams: { orderUuid: this.orderUuid } })
    }

    sblClick() {
        window.open("/order-assessment-info?oid=" + this.cacheOrder.uuid, '_blank');
    }

    openOrderDetails(doc) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['/searcher-order-edit'], { queryParams: { orderUuid: doc.order_uuid } }));
    }

    showPlaceOnHold() {
        return this.cacheOrder.order_status == 'T' || this.cacheOrder.order_status == 'I'
    }

    showRemoveHold() {
        return this.cacheOrder.order_status == 'O'
    }

    showCancelOrder() {
        if(this.cacheOrder.order_status == 'C') {
            return false
        }
        if(this.cacheOrder.api_source_id == 'QUALIA') {
            return false
        }
        if(this.cacheOrder.order_status == 'E') {
            return false
        }
        return true
    }

    cancelOrder() {
        console.log(this.cacheOrder)
        if (this.cacheOrder.order_status == 'C') {
            return this.snackRouteService.snack("This order cannot be cancelled because it has been completed.")
        }
        if (this.cacheOrder.api_source_id == 'QUALIA') {
            return this.snackRouteService.snack("This order cannot be cancelled because it is a Qualia order.")
        }
        if(this.cacheOrder.order_status == 'E') {
            return this.snackRouteService.snack("This order has already been cancelled.")
        }

        this.openSetStatusDialog('E')
    }

    setOrderStatus(status, comments) {
        this.orderService.setOrderStatus(this.cacheOrder.uuid, status, comments).subscribe((response) => {
            if (response.success) {
                this.cacheOrder.order_status = status;
                this.getOrderHistory()
                //this.doSearch(listViewIndex);

                this._snackBar.open('Order ' + this.cacheOrder.order_id + " status set to " + this.orderStatusById[status].name, null, {
                    duration: 4000,
                });
            }
        })
    }

    revertOrderStatus(comments) {
        this.orderService.revertOrderStatus(this.cacheOrder.uuid, comments).subscribe((response) => {
            if (response.success) {
                this.cacheOrder.order_status = response.status;
                //this.doSearch(listViewIndex);
                this.getOrderHistory()

                this._snackBar.open('Order ' + this.cacheOrder.order_id + " status set to " + this.orderStatusById[response.status].name, null, {
                    duration: 4000,
                });
            }
        })
    }

    openRevertStatusDialog(): void {
        const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
            panelClass: 'custom-dialog-container',
            data: {
                orderId: this.cacheOrder.order_id,
                orderAddress: this.cacheOrder.address,
                newStatus: this.orderStatusById[this.cacheOrder.previous_order_status].name,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.revertOrderStatus(result.reason)
            } else {
                this._snackBar.open("Action cancelled", null, {
                    duration: 4000,
                });
            }
        });
    }
    openSetStatusDialog(status): void {
        const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
            panelClass: 'custom-dialog-container',
            data: {
                orderId: this.cacheOrder.order_id,
                orderAddress: this.cacheOrder.address,
                newStatus: this.orderStatusById[status].name,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.setOrderStatus(status, result.reason)
            } else {
                this._snackBar.open("Action cancelled", null, {
                    duration: 4000,
                });
            }
        });
    }

    showCreateInvoice() {
        return !this.orderInvoiceId
    }

    createInvoice() {
        if (this.cacheOrder.order_status != 'C') {
            return this.snackRouteService.snack("Order must be completed before creating an invoice.")
        }
        const url = this.router.serializeUrl(this.router.createUrlTree(['invoice-edit'],
            { queryParams: { createOrderUuid: this.orderUuid } }));
        window.open(url, '_blank');
    }

    showViewInvoice() {
        return this.orderInvoiceId
    }

    viewInvoice() {
        this.router.navigate(['invoice-view'], { queryParams: { invoiceId: this.orderInvoiceId } })
    }

    showCopyOrder() {
        return true
    }

        formatValue(value: any): string 
    {
        if (typeof value === 'string' && !isNaN(Date.parse(value))) 
        {
          const date = new Date(value);
          return this.datePipe.transform(date, 'MM/dd/yyyy');
        }
        return value;
    }

}

@Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: './searcher-order-edit-state-dialog.html',
})
export class DialogOverviewExampleDialog {
    faTimes = faTimes
    faExclamationTriangle = faExclamationTriangle
    faCheck = faCheck
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) { }

    yes() {
        this.dialogRef.close({ reason: (<HTMLInputElement>document.getElementById("reason")).value });
    }

    no() {
        this.dialogRef.close();
    }

}
