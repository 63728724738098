import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { iAgentOrder } from 'src/app/model/agent-order.model';
// import { AGENTORDER } from 'src/app/mock/agent-order-mock';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { OrdersService } from 'src/app/services/order.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CompanyService } from 'src/app/company.service';

@Component({
  selector: 'app-agent-dashboard-mat',
  templateUrl: './agent-dashboard-mat.component.html',
  styleUrls: ['./agent-dashboard-mat.component.css']
})
export class AgentDashboardMatComponent implements OnInit 
{
  orders: MatTableDataSource<iAgentOrder>;
  agentId: string;
  displayedColumns: string[] = 
  [
    'orderId',
    'workflowStatus',
    'referenceNumber',
    'serviceName',
    'countyName',
    'address',
    'sbl',
    'propertySeller',
    'propertyBuyer',
    'submissionDate'
  ];
  filterForm: FormGroup;
  @Input() orderStatus;

  pageSelect = new FormControl(true);
  
  constructor
  (
    private router: Router,
    private ordersService: OrdersService,
    private companyService: CompanyService,
  ) 
  {
    // this.orders = new MatTableDataSource(AGENTORDER)
    this.orders = new MatTableDataSource<iAgentOrder>([]);
  }

  initForm(): void
  {
    this.filterForm = new FormGroup
    ({
      filter: new FormControl('')
    })
  }

  applyFilter(orderStatus: string) 
  {
    let filterValue
    if (this.filterForm)
    {
      filterValue = this.filterForm.get('filter').value.trim().toLowerCase();
    }
    let displayedStatuses: string[];
    
    if (orderStatus)
    {
      switch (orderStatus.toUpperCase()) 
      {
        case 'A':
          displayedStatuses = ['C', 'I', 'E', 'M', 'N', 'O', 'Q', 'R', 'S'];
          break;
        case 'D':
          displayedStatuses = ['R'];
          break;
        case 'IP':
          displayedStatuses = ['I', 'M', 'N', 'Q', 'S'];
          break;
        case 'RC':
          displayedStatuses = ['C'];
          break;
        default:
          displayedStatuses = [];
          break;
      }
    }
  
    if (filterValue && orderStatus) 
    {
      // console.log('filtering with both')
      this.setFilterPredicateBoth(displayedStatuses, filterValue);
    } else if (orderStatus) 
    {
      // console.log('filtering with orderStatus')
      this.setFilterPredicateStatus(displayedStatuses);
    } else if (filterValue) 
    {
      // console.log('filtering with filterValue')
      this.setFilterPredicateFilter(filterValue);
    } else 
    {
      this.orders.filter = '';
    }
  }
  
  // Function to apply filter based on both filter value and order status
  private setFilterPredicateBoth(displayedStatuses: string[], filterValue: string): void 
  {
    this.orders.filterPredicate = (data: iAgentOrder) => {
      const matchesFilter = this.displayedColumns.some(column => {
        const value = data[column] ? data[column].toString().toLowerCase() : '';
        return value.includes(filterValue);
      });

      const orderStatus = data['orderStatus'].toUpperCase();
      const matchesStatus = displayedStatuses.includes(orderStatus);

      return matchesFilter && matchesStatus;
    };
    // Apply the filter
    this.orders.filter = filterValue;
  }

// Function to apply filter based only on order status
  private setFilterPredicateStatus(displayedStatuses: string[]): void 
  {
    this.orders.filterPredicate = (data: iAgentOrder) => {
      const orderStatus = data['orderStatus'].toUpperCase();
      return displayedStatuses.includes(orderStatus);
    };
    this.orders.filter = displayedStatuses.length > 0 ? 'true' : '';
  }


  // Function to apply filter based only on filter value
  private setFilterPredicateFilter(filterValue: string): void 
  {
    this.orders.filterPredicate = (data: iAgentOrder) => {
      const matchesFilter = this.displayedColumns.some(column => {
        const value = data[column] ? data[column].toString().toLowerCase() : '';
        return value.includes(filterValue);
      });

      return matchesFilter;
    };
    this.orders.filter = filterValue;
  }

  openOrderDownloads(orderUuid: string)
  {
    this.router.navigate(['/order-download-mat'], { queryParams: {orderUuid: orderUuid} })
  }

  openAssessmentInfo(orderUuid: string)
  {
    this.router.navigate(['/order-assessment-info'], { queryParams: { oid: orderUuid } });
  }

  getDashboardOrders(agentId): Observable<iAgentOrder[]> 
  {
    return this.ordersService.getOrdersbyAgentId(agentId).pipe(
      tap(result => {
        if (result) 
        {
          this.orders.data = result;
        }
      }),
      catchError(error => {
        console.error('Error occurred while getting dashboard data:', error);
        return throwError(error);
      })
    );
  }

  getAgentId(): Observable<string> {
    return this.companyService.getCompany().pipe(
      map(response => response.result[0].id),
      tap(agentId => {
        console.log('AgentId:', agentId);
        this.agentId = agentId;
      }),
      catchError(error => {
        console.error('Error occurred while getting agentId: ', error);
        return throwError(error);
      })
    );
  }

  loadDashboardData(): void {
    this.getAgentId().pipe(
      switchMap(agentId => this.getDashboardOrders(agentId))
    ).subscribe(
      orders => {
        console.log('Orders:', orders);
      },
      error => {
        console.error('Error occurred while loading dashboard data:', error);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.orderStatus) {
      const newOrderStatus = changes.orderStatus.currentValue;
      const previousOrderStatus = changes.orderStatus.previousValue;
      
      // console.log('Order status changed from', previousOrderStatus, 'to', newOrderStatus);

      this.applyFilter(newOrderStatus)
    }
  }

  ngOnInit() 
  {
    this.loadDashboardData();
    this.initForm();
    // console.log(this.orders.data)
    this.filterForm.valueChanges.subscribe(
      () => {
      this.applyFilter(this.orderStatus);
    });
  }

}
