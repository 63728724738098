<div *ngFor="let header of headers">
    <div class="header-container">
        <div class="col-8 attribute-h1 mt-4 mb-2 bold">
            {{header}}
        </div>
        <div class="col-4 mt-4 mb-2 link-rebrand" style="padding-top: 10px;"
            *ngIf="header == 'Final Documents'" (click)="downloadAll(data[header])">
            <i class="pi pi-download" style="padding-right: 4px;"></i>Download All Files
        </div>
    </div>
    <p-table [value]="data[header]" responsiveLayout="scroll">
        <ng-template pTemplate="header" >
            <tr>
                <th pSortableColumn="description" class="blue-tint-background">Description <p-sortIcon field="description"></p-sortIcon></th>
                <th pSortableColumn="document_type_name" class="blue-tint-background">Type <p-sortIcon field="document_type_name"></p-sortIcon></th>
                <th pSortableColumn="filename" class="blue-tint-background">File Name <p-sortIcon field="filename"></p-sortIcon></th>
                <th pSortableColumn="created" class="blue-tint-background">Created <p-sortIcon field="created"></p-sortIcon></th>
                <th class="blue-tint-background"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td (click)="download(rowData)" class="link">{{ rowData.description }}</td>
                <td>{{ rowData.document_type_name }}</td>
                <td>{{ rowData.filename }}</td>
                <td>{{ rowData.created | date: 'M/d/yyyy' }}</td>
                <td>
                    <p-button 
                        pRipple 
                        icon="pi pi-envelope" 
                        class="mr-2" 
                        [rounded]="true" 
                        [outlined]="true" 
                        severity="success"
                        *ngIf="header == 'Final Documents'" 
                        (click)="openEmailDialog(rowData)"/>
                    <p-button 
                        pRipple 
                        icon="pi pi-trash" 
                        severity="danger" 
                        [rounded]="true" 
                        [outlined]="true" 
                        (click)="confirmDelete($event, rowData)"/>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-confirmDialog />
<p-dialog header="Send Email" [modal]="true" [(visible)]="showEmailDialog">
    <span class="p-text-secondary">Edit email information.</span>
    <form [formGroup]="emailForm" *ngIf="emailForm">
        <div>Subject: {{ subject }}</div>
        <div>To: {{ toAddress }}</div>
        <div class="input-container">
            <label for="cc">Cc:</label>
            <input pInputText id="cc" autocomplete="off" formControlName="cc" />
        </div>
        <div class="input-container">
            <label for="notes">Additional Notes: </label>
            <textarea rows="5" cols="30" id="notes" pInputTextarea formControlName="additionalNotes">
            </textarea>
        </div>
        <div>File Name: {{ fileName }}</div>
        <div class="button-container">
            <p-button label="Send" (click)="sendEmail()" />
            <p-button label="Cancel" severity="secondary" (click)="closeEmailDialog()" />
        </div>
    </form>
</p-dialog>
<p-toast />
  