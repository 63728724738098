<span *ngFor="let item of fc.fcs; let i = index; let isFirst = first; let isLast = last;">
    <div class="row edit-height">
        <div class="col-4 pb-2 unedit-padding bold">
            <span *ngIf="isFirst">
                {{label}}
            </span>
        </div>
        <div class="col-8 pb-2">
            <select class="input-style" style="vertical-align: top; width: 80%;" [formControl]="item"
                (change)="doChange()">
                <option *ngFor="let enumValue of enumValues" value={{enumValue}}>
                    {{enumValue}}
                </option>
            </select>
            <fa-icon class="ml-2 pointer" [icon]="faMinus" (click)="removeFC(i)" title="remove">
            </fa-icon>
            <span *ngIf="isLast">
                <fa-icon class="ml-1 pointer" *ngIf="isLast" [icon]="faPlus" (click)="addFC(i)" title="add"></fa-icon>
            </span>
        </div>
    </div>
</span>