import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TeamService {

    constructor(
        private http: HttpClient,

    ) { 
        this.selectedTeamId = localStorage.getItem("selectedTeamId")
    }

    getTeams(): Observable<any> {
        return this.http.get<any>("api/teams/");
    }
    selectedTeamId;

    setSelectedTeam(selectedTeamId) {
        this.selectedTeamId = selectedTeamId
        localStorage.setItem("selectedTeamId", this.selectedTeamId)
    }

    getSelectedTeamId() {
        return this.selectedTeamId
    }

}
