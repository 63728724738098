<div *ngIf="format == InputNumberFormat.verticalLabel" class="row mt-2">
    <div class="col bold">
        {{label}}
        <span *ngIf="tooltip" data-toggle="tooltip" data-placement="right" [title]="tooltip" class="saq-tooltip ml-1">
            <fa-icon [icon]="faInfoCircle">
            </fa-icon>
        </span>
    </div>
</div>
<div *ngIf="format == InputNumberFormat.verticalLabel" class="row mt-1">
    <div *ngIf="!disabled" class="col">
        <input type="number" [formControl]="fc" (change)="doChange($event)" />
    </div>
    <div *ngIf="disabled" class="col">
        {{fc.value}}
    </div>
</div>

<div *ngIf="format == InputNumberFormat.horizontalLabel" class="row">
    <div class="col-4 unedit-padding bold">
        {{label}}
    </div>
    <div class="col-8">
        <input type="number" class="input-style" [formControl]="fc" (keyup)="doChange($event)"
            (keyup.enter)="doEnter()">
    </div>
</div>
<input *ngIf="format == InputNumberFormat.none" type="number" class="input-style" [formControl]="fc"
    (keyup)="doChange($event)" (keyup.enter)="doEnter()">