import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';
import { SystemService } from '../system.service';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
    selector: 'app-security-questions-challenge',
    templateUrl: './security-questions-challenge.component.html',
})
export class SecurityQuestionsChallengeComponent implements OnInit {
    initialized = false;
    questions;
    error;
    faChevronRight = faChevronRight;

    constructor(
        private _themeService: ThemeService,
        private systemService: SystemService,
    ) { }

    ngOnInit() {
        this.systemService.getUserSecurityQuestionsChallenge().subscribe(response => {
            console.log("challenge response", response);
            if (response.success) {
                this.questions = response.result;
            } else {
                console.log("error occurred while fetching security questions challenge", response);
            }
            this.initialized = true;
        })
    }

    get themeService() {
        return this._themeService;
    }

    submit() {
        var questionAnswers = [];
        for (var i = 0; i < this.questions.length; ++i) {
            var elem = document.getElementById(this.questions[i].id);
            var value = (<HTMLInputElement>elem).value;
            if (!value) {
                this.error = "A question was not answered";
                return;
            }
            questionAnswers.push({id: this.questions[i].id, answer: new Md5().appendStr(value).end()});
        }
        this.systemService.userSecurityQuestionsChallengeVerification({questionAnswers}).subscribe((response) => {
            console.log(response);
            if (response.success) {
                //navigate to reset password page
                console.log("challenge successfully completed");
            } else {
                //lock account
                console.log("challenge failed");
            }
        });
    }
}
