import { Component, OnInit } from '@angular/core';
import { SystemService } from '../system.service';
import { ThemeService } from '../theme.service';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
})
export class AboutComponent implements OnInit {
    initialized = false;
    commitCount;
    lastCommitTimestamp;
    commitId;
    branch;
    environmentType;

    constructor(
        private _themeService: ThemeService,
        private systemService: SystemService,
    ) { }

    ngOnInit() {
        this.systemService.getVersions().subscribe((response) => {
            console.log("versions", response);
            if (response.success) {
                for (var i = 0; i < response.result.length; ++i) {
                    if (response.result[i].name == "commit_count") {
                        this.commitCount = response.result[i].version;
                    }
                    if (response.result[i].name == "last_commit_timestamp") {
                        this.lastCommitTimestamp = response.result[i].version;
                    }
                    if (response.result[i].name == "branch") {
                        this.branch = response.result[i].version;
                    }
                    if (response.result[i].name == "last_commit_id") {
                        this.commitId = response.result[i].version;
                    }
                }
            }
            this.systemService.getEnvironmentType().subscribe((response) => {
                if (response.success) {
                    this.environmentType = response.result;
                }
                this.initialized = true;
            })
        });
    }

    get themeService() {
        return this._themeService;
    }
}
