import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccountService } from '../account.service';
import { Router } from '@angular/router';
import { faBan, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Md5 } from 'ts-md5';
// import { ChargebeeService } from '../chargebee.service';
import { UserService } from '../user.service';
// declare const Chargebee: any;

@Component({
    selector: 'app-account-review',
    templateUrl: './account-review.component.html',
})
export class AccountReviewComponent implements OnInit {
    faBan = faBan;
    faChevronLeft = faChevronLeft;
    chargebeeInstance;
    error;
    initialized = false;
    successfullyCheckedout = false;

    constructor(
        private _themeService: ThemeService,
        // private chargebeeService: ChargebeeService,
        private http: HttpClient,
        private _accountService: AccountService,
        private router: Router,
        private userService: UserService,
    ) { }

    get themeService() {
        return this._themeService;
    }

    get accountService() {
        return this._accountService;
    }

    back() {
        this.accountService.navPreviousStep();
    }

    cancel() {
        this.accountService.navCancelStep();
    }

    getFormUrlEncoded(toConvert) {
        const formBody = [];
        for (const property in toConvert) {
            const encodedKey = encodeURIComponent(property);
            if (toConvert[property]) {
                const encodedValue = encodeURIComponent(toConvert[property]);
                formBody.push(encodedKey + '=' + encodedValue);
            }
        }
        return formBody.join('&');
    }

    ngOnInit() {
        if (!this._accountService.accountCreatePlanId) {
            this.error = "A plan was not chosen in step 1.";
        } else if (!this._accountService.accountCreateEmail) {
            this.error = "An email was not specified in step 2.";
        } else if (!this._accountService.accountCreatePassword) {
            this.error = "An password was not specified in step 2.";
        }
        if (this.error) {
            this.initialized = true;
            return;
        }
        // removed all calls to chargebee, only using data stored in db until we rebuild chargebee

        // this.chargebeeService.getChargebeeParams().subscribe((response) => {
        //     //console.log("chargebee params", response.site, response.key);
        //     this.chargebeeInstance = Chargebee.init({
        //         site: response.site,
        //         publishableKey: response.key
        //     });
        //     this.initialized = true;

        //     this.chargebeeInstance.openCheckout({
        //         hostedPage: () => {
        //             return this._accountService.createAccount(
        //                 this._accountService.accountCreatePlanId,
        //                 this._accountService.accountCreateEmail,
        //                 this._accountService.accountCreateAddons,
        //             ).toPromise();
        //         },
        //         loaded: () => {
        //             //console.log("checkout opened");
        //         },
        //         error: (err) => {
        //             //console.log("error during checkout", err);
        //             // this.loading = false;
        //             // this.ref.markForCheck();
        //             // this.errMsg = true;
        //             //console.log("checkout error");
        //         },
        //         close: () => {
        //             // this.loading = false;
        //             // this.ref.detectChanges();
        //             //console.log("checkout closed");
        //             if (this.successfullyCheckedout) {
        //                 //this.router.navigate(["/login"]);
        //                 window.location.href = window.location.href.substring(0, window.location.href.length - window.location.pathname.length);
        //             }
        //         },
        //         success: (hostedPageId) => {
        //             this.successfullyCheckedout = true;
        //             //console.log("success:", hostedPageId);

        //             this._accountService.newAccount(hostedPageId, this._accountService.accountCreatePassword).subscribe((response) => {
        //                 if (response.success) {
        //                     localStorage.setItem("loginUsernameStored", response.username);
        //                     this._accountService.accountCreatePassword = null;
        //                     this.userService.sendInviteEmail(response.username).subscribe((response) => {
        //                         if (response.success) {

        //                         } else {
        //                             console.log("email verification send error");
        //                         }
        //                     });
        //                 } else {
        //                     console.log("error while creating account", response);
        //                 }
        //             });


        //             // Hosted page id will be unique token for the checkout that happened
        //             // You can pass this hosted page id to your backend 
        //             // and then call our retrieve hosted page api to get subscription details
        //             // https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page
        //         },
        //         step: (value) => {
        //             // value -> which step in checkout
        //             //console.log("step:", value);
        //         }
        //     });
        // });
    }
}
