import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ThemeProviderOrderCreateService {

    currentTheme;// = JSON.parse(localStorage.getItem("currentTheme"));

    constructor(
    ) {
        if (this.currentTheme == null) {
            this.currentTheme = {
                name: "currentTheme",
                theme_id: 1,
                primary_color: "black",
                text_on_primary_color: "white",
                secondary_color: "#3bbfb0",
                text_on_secondary_color: "white",
                tertiary_color: "white",
                text_on_tertiary_color: "black",
                background_color: "#013363",
                text_on_background_color: "black",
                link_color: "#117ac9",
            };
        }
    }

    get primaryColor() {
        return this.currentTheme.primary_color;
    }

    get textOnPrimaryColor() {
        return this.currentTheme.text_on_primary_color;
    }

    get secondaryColor() {
        return this.currentTheme.secondary_color;
    }

    get textOnSecondaryColor() {
        return this.currentTheme.text_on_secondary_color;
    }

    get tertiaryColor() {
        return this.currentTheme.tertiary_color;
    }

    get textOnTertiaryColor() {
        return this.currentTheme.text_on_tertiary_color;
    }

    get backgroundColor() {
        return this.currentTheme.background_color;
    }

    get textOnBackgroundColor() {
        return this.currentTheme.text_on_background_color;
    }

    get linkColor() {
        return this.currentTheme.link_color;
    }
}
