import { Component, OnInit, Input, AfterViewInit, Renderer2 } from '@angular/core';
import { Observable, BehaviorSubject, of, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, defaultIfEmpty, map } from 'rxjs/operators';
import { faEdit, faCheck, faBan, faHourglass, faCaretDown } from '@fortawesome/free-solid-svg-icons';

export enum InputSearchFormat {
    verticalLabel,
    none,
}

@Component({
    selector: 'app-input-search',
    templateUrl: './input-search.component.html',
})
export class InputSearchComponent implements OnInit, AfterViewInit {
    InputSearchFormat = InputSearchFormat
    faEdit = faEdit;
    faCheck = faCheck;
    faBan = faBan;
    faHourglass = faHourglass;
    faCaretDown = faCaretDown;

    @Input() label;
    @Input() fc;
    @Input() searchFn;
    @Input() getItemDisplayValue;
    @Input() itemSelect;
    @Input() change;
    @Input() requiredMarker;
    @Input() format = InputSearchFormat.verticalLabel;
    @Input() leftIcon;
    @Input() placeholderText;
    @Input() values$
    @Input() trigger$

    letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

    attributeIdentifier = this.randomString();
    searchedList$: Observable<any[]>;
    private addressSearchTerms;
    addressSearchClicked = false;

    autocompleteStrings = [];
    arrowedItem = null;

    constructor(
        private renderer: Renderer2,
    ) { }

    ngOnInit() {
        this.addressSearchTerms = new BehaviorSubject<string>("");
        
        let triggers: Observable<any>[] = [this.addressSearchTerms.pipe(debounceTime(50))]

        if (this.values$) {
            triggers.push(this.values$)
        } else {
            triggers.push(of([]))
        }

        if (this.trigger$) {
            triggers.push(this.trigger$)
        } else {
            triggers.push(of({}))
        }
        this.searchedList$ = <any>combineLatest(triggers).pipe(
            switchMap(([term, values, triggerVal]) => {
                return this.searchFn(term, values).pipe(
                    switchMap(val => {
                        if ((!val || (<any>val).length == 0) && term != "") {
                            return of([{ name: "No results matched", unselectable: true }]);
                        }
                        //console.log("search list", val);
                        return of(val);
                    })
                );
            }),
        );
    }

    getElementById(id) {
        return document.getElementById(id);
    }

    ngAfterViewInit() {
        this.updateCurrentValue(this.fc.value);
    }

    doChange() {
        if (this.change) {
            this.change();
        }
    }

    autocompleteString(name) {
        if (!this.autocompleteStrings[name]) {
            this.autocompleteStrings[name] = "off_" + this.randomString();
        }
        return this.autocompleteStrings[name];
    }

    randomString() {
        var str = "";
        for (var i = 0; i < 20; ++i) {
            str += this.letters.charAt(Math.random() * this.letters.length);
        }
        return str;
    }

    searchClick() {
        this.addressSearchClicked = true;
        this.toggleDropdown();
    }

    showDownArrow = false
    showDropdown() {
        this.renderer.setStyle(document.getElementById(this.attributeIdentifier + '_result_container'), 'display', "block");
        this.showDownArrow = true
    }

    hideDropdown() {
        this.renderer.setStyle(document.getElementById(this.attributeIdentifier + '_result_container'), 'display', "none");
    }

    toggleDropdown() {
        if (document.getElementById(this.attributeIdentifier + '_result_container').style.display == "block") {
            this.renderer.setStyle(document.getElementById(this.attributeIdentifier + '_result_container'), 'display', "none");
        } else {
            this.renderer.setStyle(document.getElementById(this.attributeIdentifier + '_result_container'), 'display', "block");
        }
    }

    upArrow() {
        if (this.arrowedItem != null) {
            var oldItemElem = document.getElementById("search_item_" + this.attributeIdentifier + "_" + this.arrowedItem);
            if (oldItemElem) {
                oldItemElem.style.background = "white";
                oldItemElem.style.color = "black";
                --this.arrowedItem;
                if (this.arrowedItem < 0) {
                    this.arrowedItem = null;
                }
            }
        }
        if (this.arrowedItem != null) {
            var itemElem = document.getElementById("search_item_" + this.attributeIdentifier + "_" + this.arrowedItem);
            itemElem.style.background = "blue";
            itemElem.style.color = "white";
        }
    }

    downArrow() {
        this.showDropdown();
        if (this.arrowedItem == null) {
            this.arrowedItem = 0;
        } else {
            var oldItemElem = document.getElementById("search_item_" + this.attributeIdentifier + "_" + this.arrowedItem);
            if (oldItemElem) {
                oldItemElem.style.background = "white";
                oldItemElem.style.color = "black";
                ++this.arrowedItem;
            }
        }
        var itemElem = document.getElementById("search_item_" + this.attributeIdentifier + "_" + this.arrowedItem);
        itemElem.style.background = "blue";
        itemElem.style.color = "white";
    }

    enter() {
        this.hideDropdown();
        if (this.arrowedItem != null) {
            var sub = this.searchedList$.subscribe(searchItems => {
                this.searchSelection(searchItems[this.arrowedItem]);
                sub.unsubscribe();
            });
        } else {
            var sub = this.searchedList$.subscribe(searchItems => {
                if (searchItems.length == 1) {
                    this.searchSelection(searchItems[0]);
                }
                sub.unsubscribe();
            });
        }
    }

    updateCurrentValue(value) {
        this.fc.setValue(value);
    }

    searchSelection(searchItem) {
        if (!searchItem || searchItem.unselectable) {
            return;
        }
        this.renderer.setStyle(document.getElementById(this.attributeIdentifier + '_result_container'), 'display', "none");
        //console.log("selection display", this.getItemDisplay(searchItem));
        this.updateCurrentValue(this.getItemDisplay(searchItem));
        if (this.itemSelect) {
            this.itemSelect(searchItem);
        }
    }

    doSearch() {
        this.doTheSearch((<HTMLInputElement>document.getElementById(this.autocompleteString(this.attributeIdentifier + '_id'))).value);
    }

    doTheSearch(term: string): void {
        this.updateCurrentValue(term);
        this.addressSearchTerms.next(term);
        this.showDropdown();
    }

    getItemDisplay(item) {
        if (this.getItemDisplayValue) {
            return this.getItemDisplayValue(item);
        }
        return item.name;
    }

    reset() {
        if (this.arrowedItem != null) {
            var oldItemElem = document.getElementById("search_item_" + this.attributeIdentifier + "_" + this.arrowedItem);
            oldItemElem.style.background = "white";
            oldItemElem.style.color = "black";
        }

        this.arrowedItem = null;
        this.fc.setValue("");
        this.addressSearchTerms.next("");
    }

    nextSearchTerm(term) {
        this.addressSearchTerms.next(term);
    }
}
