<div class="additional-cost-header">
    <h2>Additional Cost</h2>
    <button pButton type="button" label="Add Cost" (click)="showAdditionalCostDialog()"></button>
</div>

<p-table [columns]="additionalCostColumns" [value]="additionalCosts">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" pSortableColumn="{{ col.field }}" class="blue-tint-background">
                {{ col.header }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th pSortableColumn="total" class="blue-tint-background">Total <p-sortIcon field="total"></p-sortIcon></th>
            <th class="blue-tint-background"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns">
                {{ rowData[col.field] }}
            </td>
            <td>{{ rowData.price * rowData.quantity }}</td>
            <td>
                <p-button
                pRipple
                icon="pi pi-pencil"
                class="mr-2"
                [outlined]="true"
                severity="success"
                (click)="showEditCostDialog(rowData)" />
                <p-button
                pRipple
                icon="pi pi-trash"
                [outlined]="true"
                severity="danger"
                (click)="confirmDeleteCost($event, rowData)" />
            </td>
        </tr>
    </ng-template>
</p-table>

<!-- <p-confirmDialog /> FOR SOME REASON I DONT KNOW, ADDING THIS MAKES ALL THE CONFIRM DIALOGS OPEN 2 TIMES AND IF I LEAVE THIS OUT I CAN STILL USE THE CONFIRM DIALOG HERE -->
<p-dialog header="Add Cost" [modal]="true" [(visible)]="additionalCostDialogVisable">
    <form [formGroup]="costForm" *ngIf="costForm">
        <div class="input-container">
            <label for="type">Type:</label>
            <p-dropdown
            [options]="additionalCostDropDownOptions"
            [style]="{'width':'100%'}"
            optionLabel="name"
            optionValue="service_id"
            id="type"
            formControlName="type"
            placeholder="Select a type"
          ></p-dropdown>
        </div>
        <div class="input-container">
            <label for="description">Desciption:</label>
            <input pInputText id="description" autocomplete="off" formControlName="description">
        </div>
        <div class="input-container">
            <label for="price">Cost Per Unit:</label>
            <input pInputText id="price" autocomplete="off" formControlName="price">
        </div>
        <div class="input-container">
            <label for="quantity">Quantity:</label>
            <input pInputText id="quantity" autocomplete="off" formControlName="quantity">
        </div>
        <div class="button-container">
            <p-button label="Save" (click)="addCost()" />
            <p-button label="Cancel" severity="secondary" (click)="hideAdditionalCostDialog()" />
        </div>
    </form>
</p-dialog>


<p-dialog header="Edit Cost" [modal]="true" [(visible)]="editCostDialogVisable">
    <form [formGroup]="costForm" *ngIf="costForm">
        <div class="input-container">
            <label for="type">Type:</label>
            <p-dropdown
            [options]="additionalCostDropDownOptions"
            [style]="{'width':'100%'}"
            optionLabel="name"
            optionValue="service_id"
            id="type"
            formControlName="type"
            placeholder="Select a type"
          ></p-dropdown>
        </div>
        <div class="input-container">
            <label for="description">Desciption:</label>
            <input pInputText id="description" autocomplete="off" formControlName="description">
        </div>
        <div class="input-container">
            <label for="price">Cost Per Unit:</label>
            <input pInputText id="price" autocomplete="off" formControlName="price">
        </div>
        <div class="input-container">
            <label for="quantity">Quantity:</label>
            <input pInputText id="quantity" autocomplete="off" formControlName="quantity">
        </div>
        <div class="button-container">
            <p-button label="Save" (click)="saveCost()" />
            <p-button label="Cancel" severity="secondary" (click)="hideEditCostDialog()" />
        </div>
    </form>
</p-dialog>


