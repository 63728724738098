import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard-order-cancelled',
    templateUrl: './dashboard-order-cancelled.component.html',
})
export class DashboardOrderCancelledComponent implements OnInit {
    filter = {
        gate: "all",
        comparisons: [
            {
                field: "order_status",
                operator: "is",
                matchValue: "E"
            }
        ],
    }

    constructor() { }

    ngOnInit() {
    }

}
