<div
    style="width: 100%; height: 2.5em; background-color: lightgrey; padding-top: .5em; padding-left: .5em; position: relative">
    Confirmation
    <div #theButton style="position: absolute; right: .5em; top: .5em;">
        <fa-icon [icon]="faTimes" title="Cancel" (click)="no()" class="pointer"
            (mouseenter)="theButton.style.color='blue'" (mouseleave)="theButton.style.color=null">
        </fa-icon>
    </div>
</div>

<div class="mt-3"
    style="padding-left: 1em; padding-top: .5em; padding-bottom: .5em; border-top-width: 1px; border-bottom-width: 1px; border-left-width: 0px; border-right-width: 0px; border-style: solid; border-color: lightgrey">
    <fa-icon [icon]="faExclamationTriangle" title="Alert">
    </fa-icon>
    Are you sure you want to delete this item?
</div>
<div class="mt-3" style="width: 100%;" align="center">
    <app-button class="mx-1" label="No" [clickFunction]="no.bind(this)" [icon]="faTimes">
    </app-button>
    <app-button class="mx-1" label="Yes" [clickFunction]="yes.bind(this)" [icon]="faCheck">
    </app-button>
</div>