import { Component, OnInit, Input } from '@angular/core';
import { faBan, faChevronRight, faSave, faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ThemeService } from '../theme.service';

export enum ButtonType {
    default,
    cancel,
    save,
    delete,
    next,
}

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
})
export class ButtonComponent implements OnInit {
    ButtonType = ButtonType

    @Input() buttonType: ButtonType = ButtonType.default;
    @Input() label: string;
    @Input() clickFunction;
    @Input() icon;
    @Input() small: boolean;
    @Input() title: string;
    @Input() disabled: boolean;
    @Input() iconRight: boolean;

    processing = false;
    faSpinner = faSpinner;
    activated = false;

    constructor(
        private _themeService: ThemeService
    ) {
    }

    ngOnInit() {
        switch (this.buttonType) {
            case ButtonType.cancel:
                if (!this.icon) {
                    this.icon = faBan
                }
                if (!this.label) {
                    this.label = "Cancel"
                }
                break;
            case ButtonType.delete:
                if (!this.icon) {
                    this.icon = faTrashAlt
                }
                if (!this.label) {
                    this.label = "Delete"
                }
                break;
            case ButtonType.save:
                if (!this.icon) {
                    this.icon = faSave
                }
                if (!this.label) {
                    this.label = "Save"
                }
                break;
            case ButtonType.next:
                this.iconRight = true
                if (!this.icon) {
                    this.icon = faChevronRight
                }
                if (!this.label) {
                    this.label = "Next"
                }
                break;
        }

        if (!this.title) {
            this.title = this.label;
        }
    }

    get _disabled() {
        return this.disabled;
    }

    doClick() {
        if (this.clickFunction) {
            this.clickFunction()
        }
    }

}
