<app-order-create-step-circles [stepProvider]="orderService"></app-order-create-step-circles>
<span *ngIf="!initialized">
    <div class="container-fluid bordered-container-middle pt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.secondaryColor">
        Initializing...
    </div>
</span>

<div *ngIf="initialized" class="container-fluid bordered-container-middle light-blue-bordered" [style.color]="themeService.primaryColor"
    [style.background-color]="themeService.tertiaryColor">
    <div class="row pb-1">
        <div class="col pt-4 pb-3 attribute-h1 bold" align="center">
            Notifications
        </div>
    </div>
    <div class="row pb-1">
        <div class="col-md-3">
        </div>
        <div class="col-md-6">
            <app-input-text-area label="Searcher Notes" [fc]="searcherNotificationsNotesFC"
                [change]="saveOrderCache.bind(this)"></app-input-text-area>
        </div>
    </div>

    <div class="row pb-1">
        <div class="col-md-3">
        </div>
        <div class="col-md-6">
            <app-input-enum-list label="Change Watchers" [fc]="changeWatcherFC" [change]="saveOrderCache.bind(this)" [enumValues]="userEnumValues">
            </app-input-enum-list>
        </div>
    </div>
</div>
<app-order-create-buttons></app-order-create-buttons>