<span *ngIf="!initialized">
    <div class="container-fluid mt-4 search-panel">
        Initializing...
    </div>
</span>
<div *ngIf="initialized" class="container-fluid mt-4 py-2">
    <div class="row">
        <div class="col pb-2">
            <span #imageCol></span>
            <span class="ml-2 attribute-h1 bold">{{providerName}} Documents</span>
        </div>
    </div>
</div>

<div *ngIf="initialized" class="container-fluid mt-4 rounded-ninety">

    <span *ngFor="let document of documents; let first = first">
        <div class="row" *ngIf="first">
            <div class="col-8 attribute-h1 mt-4 mb-2">
                Documents Attached to Order {{document.order_id}}
            </div>
            <div class="col-4 mt-4 mb-2 link" style="padding-top: 10px;" (click)="downloadAll()">
                <fa-icon class="mr-1" [icon]="faFileDownload" size="1x"></fa-icon>Download All Files
            </div>
        </div>
        <div class="row py-4" *ngIf="first" style="font-weight: bold; background-color: #f5f6f9; color: grey;">
            <div class="col bold">
                Description
            </div>
            <div class="col bold">
                Type
            </div>
            <div class="col bold">
                Filename
            </div>
            <div class="col bold">
                Created
            </div>
        </div>
        <div class="row py-4">
            <div class="col link link-color" (click)="download(document)">
                {{document.description}}
            </div>
            <div class="col">
                {{document.document_type_name}}
            </div>
            <div class="col">
                {{document.file_name}}
            </div>
            <div class="col">
                {{document.created | date: 'M/d/yyyy'}}
            </div>
        </div>
    </span>
    <div class="row">
        <div class="col mt-4">
        </div>
    </div>
</div>