import { Component, OnInit, Input } from '@angular/core';
import { ButtonType } from 'src/app/button/button.component';
import { ChargebeeService } from 'src/app/chargebee.service';

@Component({
    selector: 'app-button-group-wizard-first',
    templateUrl: './button-group-wizard-first.component.html',
})
export class ButtonGroupWizardFirstComponent implements OnInit {
    ButtonType = ButtonType
    @Input() cancel
    @Input() save
    @Input() next
    @Input() delete
    @Input() isAgent

    constructor(private chargebeeService: ChargebeeService) { }

    is_agent = false
    ngOnInit() {
        this.chargebeeService.getPlan().subscribe((response) => {
            if(response.plan.includes('CLIENT')) {
                this.is_agent = true
            } else {
                this.is_agent = false
            }
            console.log(this.is_agent)
        })
    }

}
