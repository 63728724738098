import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Inject, Injectable, Input, Renderer2 } from '@angular/core';
import { ThemeService } from '../theme.service';
import { Router } from '@angular/router';
import { fromEvent, Observable, Subscription } from "rxjs";
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-account-step-circles',
    templateUrl: './account-step-circles.component.html',
    styleUrls: ['./account-step-circles.component.css']
})
export class AccountStepCirclesComponent implements OnInit {
    @Input() stepProvider;
    @Input() disableStepLinks;

    faCheck = faCheck;

    resizeObservable$;
    resizeSubscription$;
    currentView = 0;
    colWidth = localStorage.getItem("step_circles_col_width");

    constructor(
        private _themeService: ThemeService,
        private renderer: Renderer2,
        private router: Router,
        //private stepProvider: OrderService,
        private ref: ChangeDetectorRef,
    ) {
    }

    get steps(): any {
        return this.stepProvider.getSteps();
    }

    ngOnInit() {
        this.stepProvider.stepComponent = this;

        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
            this.ref.detectChanges();
        });
        for (var i = 0; i < this.steps.length; ++i) {
            if (this.router.url.startsWith(this.steps[i].route)) {
                this.currentView = i;
            }
        }
    }

    ngAfterViewInit(): void {
        for (var i = 0; i < this.steps.length; ++i) {
            if (i != 0) {
                this.steps[i].before = new ElementRef(document.getElementById("before_circle_" + i));
            }
            this.steps[i].col = new ElementRef(document.getElementById("col_" + i));
            this.steps[i].circle = new ElementRef(document.getElementById("circle_" + i));
        }
        this.updateCircleStylesForPage();
        this.applyWidths();
    }

    applyWidths() {
        for (var i = 0; i < this.steps.length; ++i) {
            var circleElem = document.getElementById("circle_" + i);
            circleElem.style.marginLeft = this.circleMarginLeft(i);

            var beforeElem = document.getElementById("before_circle_" + i);
            if (beforeElem) {
                beforeElem.style.width = this.beforeWidth();
                beforeElem.style.left = this.beforeLeft();
            }
        }
    }

    get themeService() {
        return this._themeService;
    }

    isStepPassed(i) {
        return i < this.currentView;
    }

    getCircleNumber(i) {
        var counter = 0;
        for (var j = 0; j < this.steps.length && j < i; ++j) {
            if (!this.steps[j].hidden) {
                ++counter;
            }
        }
        return counter + 1;
    }

    stepClick(newViewIndex) {
        if (this.disableStepLinks) {
            return;
        }
        this.router.navigate([this.steps[newViewIndex].route]);
    }

    getBeforeClass() {
        return { width: this.beforeWidth(), left: this.beforeLeft(), "subsequent-circle-before": true };
    }

    getCircleClass(step, i) {
        var classes = { 'link': !this.disableStepLinks, 'first-circle': i == 0, 'subsequent-circle': i != 0, 'hidden': step.hidden };
        if (i == this.currentView) {
            classes.link = false;
        }
        return classes;
    }

    getColWidth() {

        //get the first two non-hidden steps
        var firstStep;
        var secondStep;
        for (var i = 0; i < this.steps.length; ++i) {
            if (this.steps[i].hidden) {
                continue;
            }
            if (!firstStep) {
                firstStep = this.steps[i];
            } else {
                secondStep = this.steps[i];
                break;
            }
        }

        if (!firstStep.col) {
            if (!this.colWidth) {
                return "0";
            }
            return this.colWidth;
        }
        var calculated = (firstStep.col.nativeElement.offsetWidth - (secondStep.circle.nativeElement.offsetWidth)) + "";
        if (calculated != "0") {
            this.colWidth = calculated;
        }
        if (!this.colWidth) {
            this.colWidth = "0";
        }
        localStorage.setItem("step_circles_col_width", this.colWidth);
        return this.colWidth;
    }

    spaceWidth = 20;
    beforeWidth() {
        return (parseInt(this.getColWidth()) - (this.spaceWidth * 2)) + "px";
    }

    beforeLeft() {
        return ((-1 * (parseInt(this.getColWidth()) + 2)) + this.spaceWidth) + "px"
    }

    circleMarginLeft(i) {
        if (i == 0) {
            return "0px";
        }
        return this.getColWidth() + "px";
    }

    // futureStepColColor = this.themeService.tertiaryColor;
    // futureStepColor = this.themeService.tertiaryColor;
    // futureStepBackgroundColor = this.themeService.secondaryColor;
    // futureStepBorderColor = this.themeService.tertiaryColor;
    // futureStepBeforeBackgroundColor = this.themeService.tertiaryColor

    // currentStepColColor = this.themeService.primaryColor;
    // currentStepColor = this.themeService.primaryColor;
    // //currentStepBackgroundColor = this.themeService.primaryColor;
    // currentStepBorderColor = this.themeService.primaryColor
    // currentStepBeforeBackgroundColor = this.themeService.primaryColor

    // completedStepColColor = this.themeService.primaryColor;
    // //completedStepColor = this.themeService.primaryColor
    // completedStepBackgroundColor = this.themeService.primaryColor
    // completedStepBorderColor = this.themeService.primaryColor
    // completedStepBeforeBackgroundColor = this.themeService.primaryColor

    futureStepColor = "lightgrey";
    futureStepBackgroundColor = "white";
    futureStepBorderColor = "lightgrey";
    futureStepBeforeBackgroundColor = "lightgrey"

    currentStepColor = "white";
    currentStepBorderColor = "lightgrey"
    currentStepBackgroundColor = "#3bbfb0";
    currentStepBeforeBackgroundColor = "lightgrey"

    completedStepBackgroundColor = "white"
    completedStepBorderColor = "lightgrey"
    completedStepBeforeBackgroundColor = "lightgrey"

    resetCircleStyles() {
        for (var i = 0; i < this.steps.length; ++i) {
            this.renderer.setStyle(this.steps[i].circle.nativeElement, 'color', this.futureStepColor);
            this.renderer.setStyle(this.steps[i].circle.nativeElement, 'background-color', this.futureStepBackgroundColor);
            this.renderer.setStyle(this.steps[i].circle.nativeElement, 'border-color', this.futureStepBorderColor);
            if (this.steps[i].before != null) {
                this.renderer.setStyle(this.steps[i].before.nativeElement, 'background-color', this.futureStepBeforeBackgroundColor);
            }
        }
    }

    applyCircleStyles() {
        for (var i = 0; i < this.steps.length; ++i) {
            if (i == this.currentView) {
                this.renderer.setStyle(this.steps[i].circle.nativeElement, 'color', this.currentStepColor);
                this.renderer.setStyle(this.steps[i].circle.nativeElement, 'border-color', this.currentStepBorderColor);
                this.renderer.setStyle(this.steps[i].circle.nativeElement, 'background-color', this.currentStepBackgroundColor);
                if (this.steps[i].before != null) {
                    this.renderer.setStyle(this.steps[i].before.nativeElement, 'background-color', this.currentStepBeforeBackgroundColor);
                }
                break;
            } else {
                this.renderer.setStyle(this.steps[i].circle.nativeElement, 'background-color', this.completedStepBackgroundColor);
                this.renderer.setStyle(this.steps[i].circle.nativeElement, 'border-color', this.completedStepBorderColor);
                if (this.steps[i].before != null) {
                    this.renderer.setStyle(this.steps[i].before.nativeElement, 'background-color', this.completedStepBeforeBackgroundColor);
                }
            }
        }
    }

    updateCircleStylesForPage() {
        this.resetCircleStyles();
        this.applyCircleStyles();
    }
}
