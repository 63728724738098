<div class="container-fluid px-0 main-menu-container">
    <nav class="navbar navbar-expand-lg navbar-dark px-0 py-0">
        <a class="navbar-brand py-0" href="#"><img src="assets/search-it-logo-horizontal-white.png"
                class="ml-2 py-1" /></a>
        <span>
            <button class="navbar-toggler" style="vertical-align: text-bottom;" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <span class="d-inline d-lg-none px-2 dropdown">
                <a data-toggle="dropdown" href="#mobile-user-dropdown" id="navbarDropdown2" class="pt-2"
                    #profileImageElement2>
                </a>
                <!-- mobile user dropdown -->
                <div class="dropdown-menu dropdown-menu-right" id="mobileUserDropdown"
                    aria-labelledby="navbarDropdown2">
                    <span *ngFor="let userDropdownItem of userDropdown">
                        <span *ngIf="!userDropdownItem.hide">
                            <a *ngIf="!userDropdownItem.click && userDropdownItem.link.startsWith('/')"
                                class="dropdown-item" style="text-decoration: none;"
                                [routerLink]="userDropdownItem.link" [queryParams]="userDropdownItem.queryParams">
                                <fa-icon [icon]="userDropdownItem.icon" class="mr-2"></fa-icon>
                                {{userDropdownItem.name}}
                            </a>
                            <a *ngIf="!userDropdownItem.click && !userDropdownItem.link.startsWith('/')"
                                target="_blank"
                                class="dropdown-item" 
                                style="text-decoration: none;" 
                                [href]="userDropdownItem.link">
                                <fa-icon 
                                    [icon]="userDropdownItem.icon" 
                                    class="mr-2">
                                </fa-icon>
                                {{userDropdownItem.name}}
                            </a>
                            <a *ngIf="userDropdownItem.click" [routerLink]="" class="dropdown-item"
                                style="text-decoration: none;" (click)="userDropdownItem.click()">
                                <fa-icon [icon]="userDropdownItem.icon" class="mr-2"></fa-icon>
                                {{userDropdownItem.name}}
                            </a>
                        </span>
                    </span>
                </div>
            </span>
        </span>
        <div class="collapse navbar-collapse ml-2" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">

                <!-- fullscreen menu items -->
                <span *ngFor="let menuItem of menu">
                    <li *ngIf="!menuItem.ifDisplay || menuItem.ifDisplay()" class="mr-2 d-none d-lg-inline">
                        <span *ngIf="menuItem.link || menuItem.dropdown">
                            <a *ngIf="menuItem.link && menuItem.name" [routerLink]="menuItem.link" [queryParams]="menuItem.queryParams"
                                class="main-item thick-border-main"
                                style="white-space: nowrap; padding-top: .6em; padding-bottom: .75em; display: inline-block;"
                                [ngClass]="{'bold': isCurrentMenuItem(menuItem), 'selectedMenu': isCurrentMenuItem(menuItem), 'unselectedMenu': !isCurrentMenuItem(menuItem)}">
                                <fa-icon class="main-item-icon mr-1" [icon]="menuItem.icon"></fa-icon>{{menuItem.name}}
                            </a>
                            <span *ngIf="menuItem.dropdown" class="nav-item dropdown" style="display: inline-block"
                                [id]="'submenu-dropdown-' + menuItem.uuid">
                                <a class="dropdown-toggle main-item thick-border-main"
                                    style="padding-top: .4em; padding-bottom: .4em; display: inline-block;"
                                    [href]="'#submenu-dropdown-' + menuItem.uuid" [id]="'navbarDropdown_'+menuItem.uuid"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    [ngClass]="{'bold': isCurrentMenuItem(menuItem), 'selectedMenu': isCurrentMenuItem(menuItem), 'unselectedMenu': !isCurrentMenuItem(menuItem)}">
                                    <fa-icon [icon]="menuItem.icon"></fa-icon> {{menuItem.name}}
                                </a>
                                <div class="dropdown-menu dropdown-menu-right"
                                    [attr.aria-labelledby]="'navbarDropdown_'+menuItem.uuid">
                                    <div *ngFor="let dropdownItem of menuItem.dropdown">
                                        <a class="dropdown-item" style="text-decoration: none;"
                                            [routerLink]="dropdownItem.link" [queryParams]="dropdownItem.queryParams">
                                            <fa-icon [icon]="dropdownItem.icon" class="mr-1"></fa-icon>
                                            {{dropdownItem.name}}
                                        </a>
                                    </div>
                                </div>
                            </span>
                        </span>
                    </li>
                </span>
                <!-- mobile submenu dropdown -->
                <li *ngFor="let menuItem of menu" class="mr-2 d-inline d-lg-none">
                    <span *ngIf="!menuItem.ifDisplay || menuItem.ifDisplay()" class="nav-item dropdown"
                        style="display: inline-block" [id]="'submenu-dropdown2-' + menuItem.uuid">

                        <a *ngIf="!menuItem.dropdown && !menuItem.menu" class="main-item thick-border-main"
                            style="padding-top: .4em; padding-bottom: .4em; display: inline-block;"
                            [routerLink]="menuItem.link" [queryParams]="menuItem.queryParams"
                            [ngClass]="{'bold': isCurrentMenuItem(menuItem), 'selectedSubmenu': isCurrentMenuItem(menuItem), 'unselectedSubmenu': !isCurrentMenuItem(menuItem)}">
                            <fa-icon [icon]="menuItem.icon" class="mr-1"></fa-icon>{{menuItem.name}}
                        </a>

                        <a *ngIf="menuItem.dropdown || menuItem.menu"
                            class="dropdown-toggle main-item thick-border-main"
                            style="padding-top: .4em; padding-bottom: .4em; display: inline-block;"
                            [href]="'#submenu-dropdown2-' + menuItem.uuid" [id]="'navbarDropdown2_'+menuItem.uuid"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            [ngClass]="{'bold': isCurrentMenuItem(menuItem), 'selectedSubmenu': isCurrentMenuItem(menuItem), 'unselectedSubmenu': !isCurrentMenuItem(menuItem)}">
                            <fa-icon [icon]="menuItem.icon || faPlus"></fa-icon> {{menuItem.name}}
                        </a>

                        <div *ngIf="menuItem.dropdown" class="dropdown-menu dropdown-menu-right"
                            [attr.aria-labelledby]="'navbarDropdown2_'+menuItem.uuid">
                            <div *ngFor="let dropdownItem of menuItem.dropdown">
                                <a class="dropdown-item" style="text-decoration: none;" [routerLink]="dropdownItem.link"
                                    [queryParams]="dropdownItem.queryParams">
                                    <fa-icon [icon]="dropdownItem.icon" class="mr-1"></fa-icon>{{dropdownItem.name}}
                                </a>
                            </div>
                        </div>
                        <div *ngIf="menuItem.menu" class="dropdown-menu dropdown-menu-right"
                            [attr.aria-labelledby]="'navbarDropdown2_'+menuItem.uuid">
                            <div *ngFor="let dropdownItem of menuItem.menu">
                                <a *ngIf="!dropdownItem.dropdown" class="dropdown-item" style="text-decoration: none;"
                                    [routerLink]="dropdownItem.link" [queryParams]="dropdownItem.queryParams">
                                    <fa-icon *ngIf="dropdownItem.icon" [icon]="dropdownItem.icon" class="mr-1">
                                    </fa-icon>
                                    <img *ngIf="dropdownItem.img" [src]="dropdownItem.img" />
                                    {{dropdownItem.name}}
                                </a>

                                <div *ngIf="dropdownItem.dropdown" class="dropdown-submenu">
                                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false"> <span class="nav-label">
                                            {{dropdownItem.name}}
                                        </span><span class="caret"></span></a>
                                    <ul class="dropdown-submenu">
                                        <li *ngFor="let dropdownChildItem of dropdownItem.dropdown">
                                            <a href="#" style="text-decoration: none;"
                                                [routerLink]="dropdownChildItem.link"
                                                [queryParams]="dropdownChildItem.queryParams">
                                                <fa-icon [icon]="dropdownChildItem.icon" class="mr-1"></fa-icon>
                                                {{dropdownChildItem.name}}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </span>
                </li>
            </ul>

            <a class="d-none d-lg-inline pt-1" routerLink="/users">
                <fa-icon *ngIf="showSettingsMenu()" [icon]="faCog" class="mr-2 settings-icon"
                    [ngClass]="{'selectedMenu': isCurrentMenuItem(settingsMenuItem), 'unselectedMenu': !isCurrentMenuItem(settingsMenuItem)}">
                </fa-icon>
            </a>

            <a class="d-none d-lg-inline pt-2" data-toggle="dropdown" href="#fullscreen-user-dropdown"
                #profileImageElement>
            </a>
            <!-- fullscreen user dropdown -->
            <span class="d-none d-lg-inline nav-item dropdown" id="fullscreen-user-dropdown">
                <a class="nav-link dropdown-toggle px-0 mx-1 main-item" data-cy="navbarDropdown"
                    href="#fullscreen-user-dropdown" id="navbarDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                    <span *ngFor="let userDropdownItem of userDropdown">
                        <span *ngIf="!userDropdownItem.hide">
                            <a *ngIf="!userDropdownItem.click && userDropdownItem.link.startsWith('/')"
                                class="dropdown-item" style="text-decoration: none;"
                                [routerLink]="userDropdownItem.link" [queryParams]="userDropdownItem.queryParams">
                                <fa-icon [icon]="userDropdownItem.icon" class="mr-2"></fa-icon>
                                {{userDropdownItem.name}}
                            </a>
                            <a *ngIf="!userDropdownItem.click && !userDropdownItem.link.startsWith('/') && is_agent; else provider_faq"
                                target="_blank"
                                class="dropdown-item" 
                                style="text-decoration: none;" 
                                href="https://www.apexiem.com/faqs-agent">
                                <fa-icon 
                                    [icon]="userDropdownItem.icon" 
                                    class="mr-2">
                                </fa-icon>
                                {{userDropdownItem.name}}
                            </a>
                            <ng-template #provider_faq>
                                <a *ngIf="!userDropdownItem.click && !userDropdownItem.link.startsWith('/')"
                                    target="_blank"
                                    class="dropdown-item" 
                                    style="text-decoration: none;" 
                                    [href]="userDropdownItem.link">
                                    <fa-icon 
                                        [icon]="userDropdownItem.icon" 
                                        class="mr-2">
                                    </fa-icon>
                                    {{userDropdownItem.name}}
                                </a>
                            </ng-template>

                            <a *ngIf="userDropdownItem.click" [routerLink]="" class="dropdown-item"
                                style="text-decoration: none;" (click)="userDropdownItem.click()">
                                <fa-icon [icon]="userDropdownItem.icon" class="mr-2"></fa-icon>
                                {{userDropdownItem.name}}
                            </a>
                        </span>
                    </span>
                </div>
            </span>
        </div>
    </nav>
</div>

<!-- fullscreen submenu -->
<div class="container-fluid d-none d-lg-block">
    <div class="row submenu-row">
    <span *ngFor="let leftMenuItem of menu">
            <span *ngIf="isCurrentMenuItem(leftMenuItem)">
                <span *ngFor="let submenuItem of leftMenuItem.menu">
                    <span *ngIf="!submenuItem.ifDisplay || submenuItem.ifDisplay()">
                        <a *ngIf="!submenuItem.dropdown && submenuItem.link && submenuItem.link.startsWith('/')"
                            [routerLink]="submenuItem.link">
                            <div class="pointer thick-border py-2 px-2"
                                [ngClass]="{'selectedSubmenu': isCurrentMenuItem(submenuItem), 'unselectedSubmenu': !isCurrentMenuItem(submenuItem)}"
                                style="height: 3em; display: inline-block;">
                                <fa-icon *ngIf="submenuItem.icon" [icon]="submenuItem.icon" class="mr-1"></fa-icon>
                                {{submenuItem.name}}
                            </div>
                        </a>
                        <a *ngIf="!submenuItem.dropdown && submenuItem.link && !submenuItem.link.startsWith('/')"
                            [href]="submenuItem.link">
                            <div class="pointer thick-border py-2 px-2"
                                [ngClass]="{'selectedSubmenu': isCurrentMenuItem(submenuItem), 'unselectedSubmenu': !isCurrentMenuItem(submenuItem)}"
                                style="height: 3em; display: inline-block;">
                                <fa-icon *ngIf="submenuItem.icon" [icon]="submenuItem.icon" class="mr-1"></fa-icon>
                                {{submenuItem.name}}
                            </div>
                        </a>
                        <span *ngIf="submenuItem.click" (click)="submenuItem.click()">
                            <div class="pointer thick-border py-2 px-2"
                                [ngClass]="{'selectedSubmenu': isCurrentMenuItem(submenuItem), 'unselectedSubmenu': !isCurrentMenuItem(submenuItem)}"
                                style="height: 3em; display: inline-block;">
                                <fa-icon *ngIf="submenuItem.icon" [icon]="submenuItem.icon" class="mr-1"></fa-icon>
                                {{submenuItem.name}}
                            </div>
                        </span>
                        <span *ngIf="!submenuItem.dropdown && !submenuItem.link && !submenuItem.click">
                            <div class="pointer thick-border py-2 px-2"
                                [ngClass]="{'selectedSubmenu': isCurrentMenuItem(submenuItem), 'unselectedSubmenu': !isCurrentMenuItem(submenuItem)}"
                                style="height: 3em; display: inline-block;">
                                <fa-icon *ngIf="submenuItem.icon" [icon]="submenuItem.icon" class="mr-1"></fa-icon>
                                {{submenuItem.name}}
                            </div>
                        </span>
                        <span *ngIf="submenuItem.dropdown"
                            class="d-none d-lg-inline nav-item dropdown py-2 px-2 thick-border"
                            style="display: inline-block" [id]="'submenu-dropdown-' + submenuItem.uuid"
                            [ngClass]="{'selectedSubmenu': isCurrentMenuItem(submenuItem), 'unselectedSubmenu': !isCurrentMenuItem(submenuItem)}">

                            <a class="dropdown-toggle nav-link px-0 py-0" style="display: inline-block;"
                                [ngClass]="{'selectedSubmenu': isCurrentMenuItem(submenuItem), 'unselectedSubmenu': !isCurrentMenuItem(submenuItem)}"
                                [href]="'#submenu-dropdown-' + submenuItem.uuid"
                                [id]="'navbarDropdown_'+submenuItem.uuid" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <span style="vertical-align: top;">
                                    <fa-icon *ngIf="submenuItem.icon" [icon]="submenuItem.icon" class="mr-1"></fa-icon>
                                    <img *ngIf="submenuItem.img" [src]="submenuItem.img" />
                                    {{submenuItem.name}}
                                </span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right"
                                [attr.aria-labelledby]="'navbarDropdown_'+submenuItem.uuid">
                                <div *ngFor="let dropdownItem of submenuItem.dropdown">
                                    <a class="dropdown-item" style="text-decoration: none;"
                                        [routerLink]="dropdownItem.link">
                                        <fa-icon [icon]="dropdownItem.icon" class="mr-1"></fa-icon>{{dropdownItem.name}}
                                    </a>
                                </div>
                            </div>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    </div>
</div>