import { Component, OnInit, Input } from '@angular/core';

export enum InputTextAreaFormat {
    verticalLabel,
    none,
}

@Component({
    selector: 'app-input-text-area',
    templateUrl: './input-text-area.component.html',
})
export class InputTextAreaComponent implements OnInit {
    InputTextAreaFormat = InputTextAreaFormat
    @Input() label;
    @Input() fc;
    @Input() disabled;
    @Input() change;
    @Input() format: InputTextAreaFormat = InputTextAreaFormat.verticalLabel;
    @Input() rows;
    @Input() rowClass;
    @Input() placeholder
    @Input() mlength
    calcRowClass
    setMaxLength: boolean = false

    defaultRows = 4;
    rowCount

    constructor() { }

    ngOnInit() {
        this.rowCount = this.defaultRows;
        if (this.rows) {
            this.rowCount = this.rows;
        }
        this.calcRowClass = {};
        this.calcRowClass[this.rowClass] = true;
        if (!this.placeholder) {
            this.placeholder = ""
        }

        if(this.mlength) {
            this.setMaxLength = true
        } else {
            this.setMaxLength = false
        }
    }

    doChange() {
        if (this.change) {
            this.change();
        }
    }
}
