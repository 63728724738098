import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-user-role',
  templateUrl: './input-user-role.component.html',
  styleUrls: ['./input-user-role.component.css']
})
export class InputUserRoleComponent implements OnInit {
    @Input() label;
    @Input() fc;
    @Input() enumValues;
    @Input() enumValuesNameAndId;
    @Input() change;
    @Input() requiredMarker;
    @Input() disabled;
    letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    attributeIdentifier = this.randomString();
    @Input() rowClass;
    calcRowClass

    randomString() {
        var str = "";
        for (var i = 0; i < 20; ++i) {
            str += this.letters.charAt(Math.random() * this.letters.length);
        }
        return str;
    }

    getElementById(id) {
        return document.getElementById(id);
    }

    constructor() { }

    ngOnInit() {
        this.calcRowClass = {};
        this.calcRowClass[this.rowClass] = true;
    }

    getDisplayValue() {
        if (this.enumValues) {
            return this.fc.value;
        }
        if (this.enumValuesNameAndId) {
            for (var i = 0; i < this.enumValuesNameAndId.length; ++i) {
                if (this.enumValuesNameAndId[i].id == this.fc.value) {
                    return this.enumValuesNameAndId[i].name;
                }
            }
        }
    }

    doChange() {
        if (this.change) {
            this.change();
        }
    }
}
