import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes, faExclamationTriangle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { ProviderDashboardStateDialog } from '../provider-dashboard/provider-dashboard.component';

@Component({
    selector: 'app-delete-confirmation',
    templateUrl: './delete-confirmation.component.html',
})
export class DeleteConfirmationComponent {
    faTimes = faTimes
    faExclamationTriangle = faExclamationTriangle
    faCheck = faCheck
    constructor(
        public dialogRef: MatDialogRef<ProviderDashboardStateDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) { }

    yes() {
        this.dialogRef.close({});
    }

    no() {
        this.dialogRef.close();
    }
}
