import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faBan, faSave, faUser, faInfoCircle, faExclamationCircle, faKey, faMinus } from '@fortawesome/free-solid-svg-icons';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AccountService } from '../account.service';
import { AuthService } from '../auth.service';
import { ButtonType } from '../button/button.component';
import { CrudService } from '../crud.service';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { TimezoneService } from '../timezone.service';
import { UserService } from '../user.service';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
})
export class UserComponent implements OnInit {
    ButtonType = ButtonType
    faBan = faBan
    faSave = faSave
    initialized = false
    faUser = faUser
    faInfoCircle = faInfoCircle
    faExclamationCircle = faExclamationCircle
    faMinus = faMinus
    faKey = faKey

    phoneExampleText = "e.g. (123) 456-7890";
    passwordExampleText = "If a password isn't entered, the user will set it upon first login.";

    editableFields = [
        "email_address",
        "company_id",
        "full_name",
        "photo",
        "location",
        "role",
        "job_title",
        "department",
        "status",
        "time_zone",
        "phone",
        "password",
        "password2",
    ]
    formControls = {}

    availableRoles$ = this.userService.getAvailableRoles().pipe(map((response) => {
        //console.log("roles respo", response)
        return response.result
    }))
    timezones$ = this.timezoneService.getTimezones()
    companies$ = this.userService.getCompanies()

    get currentUserId() {
        return this.authService.userId
    }

    userUuid
    isNew

    userData$ = this.route.queryParams.pipe(
        switchMap((params) => {
            if (params["id"]) {
                this.userUuid = params["id"]
                this.isNew = false
                return this.crudService.search({
                    type: "data_user",
                    where: "id = ?",
                    whereArgs: [params["id"]],
                });
            } else {
                this.isNew = true
                return of({})
            }
        }),
        tap((response: any) => {
            //console.log("DATA RESP", response)
            for (var i = 0; i < this.editableFields.length; ++i) {
                this.formControls[this.editableFields[i]] = new UntypedFormControl()
            }
            if (!this.isNew) {
                this.formControls["email_address"].disable()
                for (var i = 0; i < this.editableFields.length; ++i) {
                    this.formControls[this.editableFields[i]].setValue(response.result[0][this.editableFields[i]])
                }
            }
        })
    )

    constructor(
        private userService: UserService,
        private router: Router,
        private timezoneService: TimezoneService,
        private accountService: AccountService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private crudService: CrudService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
    }
    formErrors = {}
    anyError = false


    validEmail(email) {
        return /\S+@\S+(\.\S+)?/.test(email)
    }

    passwordCheck
    isValid() {
        this.anyError = false

        if (!this.validEmail(this.formControls['email_address'].value)) {
            this.formErrors['email_address'] = "Please enter a valid email address."
            this.anyError = true
        } else {
            delete this.formErrors['email_address']
        }

        this.passwordCheck = this.userService.passwordCheckAndSum(this.formControls['password'].value, this.formControls['password2'].value, true);
        if (this.passwordCheck.error) {
            this.formErrors['password'] = this.passwordCheck.error
            this.anyError = true
        } else {
            delete this.formErrors['password']
        }

        if (this.userService.isAdmin() && !this.formControls['company_id'].value) {
            this.formErrors['company_id'] = "Please specify a company."
            this.anyError = true
        } else {
            delete this.formErrors['company_id']
        }

        if (!this.formControls['role'].value) {
            this.formErrors['role'] = "Please specify a role."
            this.anyError = true
        } else {
            delete this.formErrors['role']
        }

        if (!this.formControls['full_name'].value) {
            this.formErrors['full_name'] = "Please specify the user's full name."
            this.anyError = true
        } else {
            delete this.formErrors['full_name']
        }

        return !this.anyError
    }

    save() {
        if (!this.isValid()) {
            return
        }
        // var companyId;
        // if (this.userService.isAdmin()) {
        //     companyId = this.formControls['company_id'].value
        // } else {
        //     companyId = this.authService.companyId;
        // }
        var companyId = this.authService.companyId
        //console.log("company id for create check", companyId)
        if (this.isNew) {

            this.accountService.canUserBeCreated(
                this.formControls['email_address'].value,
                companyId,
            ).subscribe((response) => {
                if (response.success) {
                    if (!response.canCreate) {
                        this.formErrors["email_address"] = "This email is already in use."
                        this.anyError = true
                        return;
                    }
                } else {
                    this.formErrors["email_address"] = response.message;
                    this.anyError = true
                    if (response.error) {
                        console.log("response error", response);
                    }
                    return;
                }

                this.userService.createUser(
                    companyId,
                    this.formControls['email_address'].value,
                    this.passwordCheck.hash,
                    this.formControls['full_name'].value,
                    this.formControls['photo'].value,
                    this.formControls['location'].value,
                    this.formControls['role'].value,
                    this.formControls['job_title'].value,
                    this.formControls['department'].value,
                    this.formControls['time_zone'].value,
                    this.formControls['phone'].value,
                    false,
                ).subscribe((response) => {
                    if (response.success) {
                        this.userService.sendInviteEmail(this.formControls['email_address'].value).subscribe((response) => {
                            if (response.success) {
                                this.router.navigate(["/users"]);
                            } else {
                                console.log("error while sending verification email", response);
                            }
                        });
                    } else {
                        console.log(response);
                        this.formErrors["email_address"] = response.message;
                        this.anyError = true
                    }
                })

            })
        } else {
            this.userService.updateUser(
                this.userUuid,
                this.formControls['full_name'].value,
                this.formControls['photo'].value,
                this.formControls['location'].value,
                this.formControls['role'].value,
                this.formControls['job_title'].value,
                this.formControls['department'].value,
                this.formControls['status'].value,
                this.formControls['time_zone'].value,
                this.formControls['phone'].value,
            ).subscribe((response) => {
                if (response.success) {
                    if (response.success) {
                        this.router.navigate(["/users"]);
                    } else {
                        console.log(response);
                        this.formErrors["email_address"] = response.message;
                        this.anyError = true
                    }
                } else {
                    console.log(response);
                    this.formErrors["email_address"] = response.message;
                    this.anyError = true
                }
            })
        }
    }

    delete(companyData) {
        //console.log(companyData)
        if (companyData.result && companyData.result.length && companyData.result[0].data_users && companyData.result[0].data_users.length) {
            this._snackBar.open("Cannot delete company with users", null, {
                duration: 4000,
            });
            return
        }

        const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
            panelClass: 'delete-confirmation-dialog-container',
        });

        dialogRef.afterClosed().subscribe(result => {
            // if (result) {
            //     var companyObject = {
            //         id: this.companyId,
            //     }
            //     this.crudService.delete(
            //         "data_company",
            //         companyObject).subscribe((response) => {
            //             //console.log(response)
            //             this.router.navigate(["/clients"])
            //         })
            // } else {
            //     this._snackBar.open("Action cancelled", null, {
            //         duration: 4000,
            //     });
            // }
        });
    }

    back() {
        this.router.navigate(["/users"]);
    }

    phoneUpdate() {
        this.userService.phoneUpdate(this.formControls['phone']);
    }
}
