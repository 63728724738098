<div class="card professional-card">
  <div class="card-header">
    <h2>Order Search <a routerLink="/old-order-search" class="old-search-link">Old Search</a></h2>
    <div class="search-button-container">
      <button pButton type="button" label="Search" class="custom-search-btn" (click)="applyAllFilters()"></button>
      <button 
        pButton 
        type="button" 
        label="Clear Filters" 
        class="custom-clear-btn" 
        (click)="clearAllFilters()">
      </button>
      <button
        pButton
        type="button"
        label="Export to CSV"
        icon="pi pi-file"
        class="custom-export-btn"
        [disabled]="!tableData || tableData.length === 0"
        (click)="exportAllToCSV()">
      </button>
    </div>
  </div>

  <form [formGroup]="searchForm">
    <p-table
      #dt
      [value]="tableData"
      [columns]="columns"
      [paginator]="true"
      [rows]="10"
      responsiveLayout="scroll"
      class="professional-table"
    >
      <ng-template pTemplate="header">
        <tr>
          <!-- Order ID -->
          <th>
            <div class="column-header">
              Order ID
              <span class="filter-badge" *ngIf="filterTypes['orderId']">
                {{ getFilterLabel('orderId') }}
              </span>
            </div>
            <div class="filter-container">
              <input pInputText formControlName="orderId" placeholder="Search Order ID" class="filter-input" (input)="autoApplyFilter('orderId', $event)" />
              <i class="pi pi-filter filter-icon" [ngClass]="{'active-filter': filterTypes['orderId']}" (click)="toggleMenu(0, $event)"></i>
              <i *ngIf="filterTypes['orderId']" class="pi pi-times-circle clear-icon" (click)="clearFilter('orderId')"></i>
              <p-menu #filterMenu [popup]="true" [model]="filterOptions['orderId']" appendTo="body"></p-menu>
            </div>
          </th>

          <!-- Ref Number -->
          <th>
            <div class="column-header">
              Reference Number
              <span class="filter-badge" *ngIf="filterTypes['referenceNumber']">
                {{ getFilterLabel('referenceNumber') }}
              </span>
            </div>
            <div class="filter-container">
              <input pInputText formControlName="referenceNumber" placeholder="Search Reference Number" class="filter-input" (input)="autoApplyFilter('referenceNumber', $event)" />
              <i class="pi pi-filter filter-icon" [ngClass]="{'active-filter': filterTypes['referenceNumber']}" (click)="toggleMenu(1, $event)"></i>
              <i *ngIf="filterTypes['referenceNumber']" class="pi pi-times-circle clear-icon" (click)="clearFilter('referenceNumber')"></i>
              <p-menu #filterMenu [popup]="true" [model]="filterOptions['referenceNumber']" appendTo="body"></p-menu>
            </div>
          </th>

          <!-- Buyer -->
          <th>
            <div class="column-header">
              Buyer
              <span class="filter-badge" *ngIf="filterTypes['propertyBuyer']">
                {{ getFilterLabel('propertyBuyer') }}
              </span>
            </div>
            <div class="filter-container">
              <input pInputText formControlName="propertyBuyer" placeholder="Search Buyer" class="filter-input" (input)="autoApplyFilter('propertyBuyer', $event)" />
              <i class="pi pi-filter filter-icon" [ngClass]="{'active-filter': filterTypes['propertyBuyer']}" (click)="toggleMenu(2, $event)"></i>
              <i *ngIf="filterTypes['propertyBuyer']" class="pi pi-times-circle clear-icon" (click)="clearFilter('propertyBuyer')"></i>
              <p-menu #filterMenu [popup]="true" [model]="filterOptions['propertyBuyer']" appendTo="body"></p-menu>
            </div>
          </th>

          <!-- SBL -->
          <th>
            <div class="column-header">
              SBL
              <span class="filter-badge" *ngIf="filterTypes['sbl']">
                {{ getFilterLabel('sbl') }}
              </span>
            </div>
            <div class="filter-container">
              <input pInputText formControlName="sbl" placeholder="Search SBL" class="filter-input" (input)="autoApplyFilter('sbl', $event)" />
              <i class="pi pi-filter filter-icon" [ngClass]="{'active-filter': filterTypes['sbl']}" (click)="toggleMenu(3, $event)"></i>
              <i *ngIf="filterTypes['sbl']" class="pi pi-times-circle clear-icon" (click)="clearFilter('sbl')"></i>
              <p-menu #filterMenu [popup]="true" [model]="filterOptions['sbl']" appendTo="body"></p-menu>
            </div>
          </th>

          <!-- County -->
          <th>
            <div class="column-header">
              County
              <span class="filter-badge" *ngIf="filterTypes['county']">
                {{ getFilterLabel('county') }}
              </span>
            </div>
            <div class="filter-container">
              <input pInputText formControlName="county" placeholder="Search County" class="filter-input" (input)="autoApplyFilter('county', $event)" />
              <i class="pi pi-filter filter-icon" [ngClass]="{'active-filter': filterTypes['county']}" (click)="toggleMenu(4, $event)"></i>
              <i *ngIf="filterTypes['county']" class="pi pi-times-circle clear-icon" (click)="clearFilter('county')"></i>
              <p-menu #filterMenu [popup]="true" [model]="filterOptions['county']" appendTo="body"></p-menu>
            </div>
          </th>

          <!-- Address -->
          <th>
            <div class="column-header">
              Address
              <span class="filter-badge" *ngIf="filterTypes['address']">
                {{ getFilterLabel('address') }}
              </span>
            </div>
            <div class="filter-container">
              <input pInputText formControlName="address" placeholder="Search Address" class="filter-input" (input)="autoApplyFilter('address', $event)" />
              <i class="pi pi-filter filter-icon" [ngClass]="{'active-filter': filterTypes['address']}" (click)="toggleMenu(5, $event)"></i>
              <i *ngIf="filterTypes['address']" class="pi pi-times-circle clear-icon" (click)="clearFilter('address')"></i>
              <p-menu #filterMenu [popup]="true" [model]="filterOptions['address']" appendTo="body"></p-menu>
            </div>
          </th>

          <!-- Service -->
          <th>
            <div class="column-header">
              Service
              <span class="filter-badge" *ngIf="filterTypes['primaryServiceName']">
                {{ getFilterLabel('primaryServiceName') }}
              </span>
            </div>
            <div class="filter-container">
              <input pInputText formControlName="primaryServiceName" placeholder="Search Service" class="filter-input" (input)="autoApplyFilter('primaryServiceName', $event)" />
              <i class="pi pi-filter filter-icon" [ngClass]="{'active-filter': filterTypes['primaryServiceName']}" (click)="toggleMenu(6, $event)"></i>
              <i *ngIf="filterTypes['primaryServiceName']" class="pi pi-times-circle clear-icon" (click)="clearFilter('primaryServiceName')"></i>
              <p-menu #filterMenu [popup]="true" [model]="filterOptions['primaryServiceName']" appendTo="body"></p-menu>
            </div>
          </th>

          <!-- Order Date -->
          <th>
            <div class="column-header">
              Order Date
              <span class="filter-badge" *ngIf="filterTypes['submissionDate']">
                {{ getFilterLabel('submissionDate') }}
              </span>
            </div>
            <div class="filter-container">
              <p-calendar formControlName="submissionDate" placeholder="Search Order Date" [showIcon]="true" inputStyleClass="filter-input" dateFormat="mm/dd/yy" appendTo="body" selectionMode="single"
              [iconDisplay]="'input'" panelStyleClass="custom-calendar-panel" (onSelect)="autoApplyFilter('submissionDate', searchForm.get('submissionDate')?.value)" />
              <div class="filter-actions">
                <i class="pi pi-filter filter-icon" [ngClass]="{'active-filter': filterTypes['submissionDate']}" (click)="toggleMenu(7, $event)"></i>
                <i *ngIf="filterTypes['submissionDate']" class="pi pi-times-circle clear-icon" (click)="clearFilter('submissionDate')"></i>
              </div>
              <p-menu #filterMenu [popup]="true" [model]="filterOptions['submissionDate']" appendTo="body"></p-menu>
            </div>
          </th>

          <!-- Status -->
          <th>
            <div class="column-header">
              Status
              <span class="filter-badge" *ngIf="filterTypes['orderStatusName']">
                {{ getFilterLabel('orderStatusName') }}
              </span>
            </div>
            <div class="filter-container">
              <input pInputText formControlName="orderStatusName" placeholder="Search Status" class="filter-input" (input)="autoApplyFilter('orderStatusName', $event)" />
              <i class="pi pi-filter filter-icon" [ngClass]="{'active-filter': filterTypes['orderStatusName']}" (click)="toggleMenu(8, $event)"></i>
              <i *ngIf="filterTypes['orderStatusName']" class="pi pi-times-circle clear-icon" (click)="clearFilter('orderStatusName')"></i>
              <p-menu #filterMenu [popup]="true" [model]="filterOptions['orderStatusName']" appendTo="body"></p-menu>
            </div>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData>
        <tr [pSelectableRow]="rowData">
          <td>
            <a [routerLink]="['/searcher-order-edit']" [queryParams]="{ orderUuid: rowData.uuid }" class="link">
              {{ rowData.orderId }}
            </a>
          </td>
          <td>{{ rowData.referenceNumber }}</td>
          <td>{{ removeQuotes(rowData.propertyBuyer) }}</td>
          <td>{{ rowData.sbl }}</td>
          <td>{{ rowData.county }}</td>
          <td>{{ rowData.address }}</td>
          <td>{{ rowData.primaryServiceName }}</td>
          <td>{{ rowData.submissionDate | date:'MM/dd/yyyy' }}</td>
          <td>{{ rowData.orderStatusName }}</td>
        </tr>
      </ng-template>
    </p-table>
  </form>
</div>