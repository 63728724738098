import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dbtitlecase'
})
export class DbtitlecasePipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        if (!value) {
            return value;
        }
        return value.replace(/_/g, " ");
    }
}
