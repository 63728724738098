import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatTableDataSource} from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { SYSSCHOOL } from '../mock/sys-school-mock';
import { SysSchool } from '../model/sys-school.model';
import { SchoolService } from '../services/school.service';
import { SchoolServiceMock } from '../services/school.service.mock';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-sys-school',
  templateUrl: './sys-school.component.html',
  styleUrls: ['./sys-school.component.css']
})
export class SysSchoolComponent {

  //data used to populate the table
  ELEMENT_DATA = SYSSCHOOL

  schoolDataSource: MatTableDataSource<SysSchool>;
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  
  displayedColumns: string[] = 
  [
    'schoolDistrictCode',
    'schoolDistrictName',
    'address',
    'taxYear',
    'dueDate1',
    'dueDate2',
    'dueDate3',
    'dueDate4',
    'refreshDate'
  ]

  constructor(
    private dialog: MatDialog,
    private schoolService: SchoolService,
    private schoolServiceMock: SchoolServiceMock
  ) 
  {
    this.schoolDataSource = new MatTableDataSource([{}]);
    this.loadTableData();
  }

  private loadTableData(): void 
  {
    this.schoolService.getSchools().subscribe( response => {
      this.schoolDataSource.data = response.results
    });
  }

  ngAfterViewInit()
  {
    this.schoolDataSource.paginator = this.paginator;
  }


  //open school edit dialog
  editSchool(school: SysSchool): void
  {
      const dialogRef = this.dialog.open(EditSchoolDialog, {
      minWidth: '480px',
      data: school
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed, having data: ' + JSON.stringify(result));
      this.loadTableData();
    });
  }

    //filter functionality for search bar
    applyFilter(filterValue: string) {
      filterValue = filterValue.trim().toLowerCase();
      this.schoolDataSource.filterPredicate = (data: any, filter: string) => {
      const columnValue = data.schoolDistrictName.toLowerCase();
      return columnValue.includes(filter);
      };
      this.schoolDataSource.filter = filterValue;
    }
}

@Component({
  selector: 'sys-school-edit',
  templateUrl: './edit-school-dialog.html',
  styleUrls: ['./sys-school.component.css']
})
export class EditSchoolDialog implements OnInit 
{
  form = new UntypedFormGroup({
    //filling form with data from result of getCountyById function
    schoolDistrictCode: new UntypedFormControl({value: this.data.schoolDistrictCode, disabled: true}),
    schoolDistrictName: new UntypedFormControl({value: this.data.schoolDistrictName, disabled: true}),
    address: new UntypedFormControl(this.data.address, [Validators.required]),
    taxYear: new UntypedFormControl({value: this.data.taxYear, disabled: true}),
    taxPeriod: new UntypedFormControl(this.data.taxPeriod),
    lienDate: new UntypedFormControl({value: this.data.lienDate, disabled: true}),
    fiscalStartDate: new UntypedFormControl(this.data.fiscalStartDate,),
    fiscalEndDate: new UntypedFormControl(this.data.fiscalEndDate,),
    dueDate1: new UntypedFormControl(this.data.dueDate1, [Validators.required]),
    dueDate2: new UntypedFormControl(this.data.dueDate2),
    dueDate3: new UntypedFormControl(this.data.dueDate3),
    dueDate4: new UntypedFormControl(this.data.dueDate4),
    refreshDate: new UntypedFormControl(this.data.refreshDate, [Validators.required])
  })

  constructor(
    public DialogRef: MatDialogRef<EditSchoolDialog>,
    private schoolServiceMock: SchoolServiceMock,
    private schoolService: SchoolService,
    @Inject(MAT_DIALOG_DATA) public data: SysSchool
  )
  {}

  calculateTaxYearAndLienDate(date1: Date, date2: Date) 
  {
    //i had to change the way we declare the variables date1 and date2
    //declaring them top level in the is function didnt work
    //if one was null it would still be given a default date of 01/01/1970
    //this is jut the way js handles null dates
    
    let finalYear: string
    let finalDayAndMonth: string
    //extract month, day, and year from date
    if(date2 != null && date1 != null)
    {
      date1 = new Date(date1);
      date2 = new Date(date2);
      let date1Month = date1.getMonth() + 1;
      let date1Day = date1.getDate().toString();
      let date1Year = date1.getFullYear().toString();
      let date2Month = date2.getMonth() + 1;
      let date2Day = date2.getDate().toString();
      let date2Year = date2.getFullYear().toString();
      //concat month and day
      finalDayAndMonth = date1Month.toString() + '/' + date1Day + '-' + date2Month.toString() + '/' + date2Day;
      this.form.get('lienDate').setValue(finalDayAndMonth);

        //if the years are not the same return a range
        if(date1Year != date2Year)
        {
          finalYear = date1Year + '/' + date2Year;
          this.form.get('taxYear').setValue(finalYear)
        }
        //if the years are the same return a single year
        else if(date1Year == date2Year)
        {
          finalYear = date1.getFullYear().toString();
          this.form.get('taxYear').setValue(finalYear)
        }
    }
    else if(date2 == null && date1 != null)
    {
      date1 = new Date(date1);
      let date1Month = date1.getMonth() + 1;
      let date1Day = date1.getDate().toString();
      let date1Year = date1.getFullYear().toString();
      finalYear = date1Year;
      this.form.get('taxYear').setValue(finalYear)

      finalDayAndMonth = date1Month.toString() + '/' + date1Day;
      this.form.get('lienDate').setValue(finalDayAndMonth);
    }
    else if(date2 != null && date1 == null)
    {
      date2 = new Date(date2);
      let date2Month = date2.getMonth() + 1;
      let date2Day = date2.getDate().toString();
      let date2Year = date2.getFullYear().toString();
      finalYear = date2Year;
      this.form.get('taxYear').setValue(finalYear)

      finalDayAndMonth = date2Month.toString() + '/' + date2Day;
      this.form.get('lienDate').setValue(finalDayAndMonth);
    }
    else if (date2 == null && date1 == null)
    {
      finalYear = new Date().getFullYear().toString();
      this.form.get('taxYear').setValue(finalYear)
      finalDayAndMonth = '01/01-12/31';
      this.form.get('lienDate').setValue(finalDayAndMonth);
    }
    else
    {
    console.log('Something went wrong')
    }
  }


   save() 
   {
      //logic to update school
      if (this.form.valid) 
      {
        this.data.schoolDistrictCode = this.form.get('schoolDistrictCode').value;
        this.data.schoolDistrictName = this.form.get('schoolDistrictName').value;
        this.data.address =  this.form.get('address').value;
        this.data.taxYear = this.form.get('taxYear').value;
        this.data.taxPeriod = this.form.get('taxPeriod').value;
        this.data.lienDate =  this.form.get('lienDate').value;
        this.data.fiscalStartDate = this.form.get('fiscalStartDate').value;
        this.data.fiscalEndDate = this.form.get('fiscalEndDate').value;
        this.data.dueDate1 = this.form.get('dueDate1').value;
        this.data.dueDate2 = this.form.get('dueDate2').value;
        this.data.dueDate3 = this.form.get('dueDate3').value;
        this.data.dueDate4 = this.form.get('dueDate4').value;
        this.data.refreshDate = this.form.get('refreshDate').value;

        this.schoolService.updateSchool(this.data).subscribe(response => {
          if(response.success === true) {
            console.log(`School updated: ${this.data}`);
          } else {
            console.log(`Failed to update school: ${this.data}`);
          }
          // next: (response) => {
          //   console.log(`School updated: ${this.data}`);
          // },
          // error: (err) => {
          //   console.log(err);
          // }
        });
        this.DialogRef.close(this.data);
      }
      else
      {
        this.form.markAllAsTouched();
        console.log('Form is not valid');
      }

    }

    ngOnInit(): void {


      //watch for changes in fisacal start
      this.form.get('fiscalStartDate').valueChanges.subscribe(date1 => {
        let date2 = this.form.get('fiscalEndDate').value;
  
        this.calculateTaxYearAndLienDate(date1, date2)
      });
  
      //watch for changes in fiscal end
      this.form.get('fiscalEndDate').valueChanges.subscribe(date2 => {
        let date1 = this.form.get('fiscalStartDate').value;
  
        this.calculateTaxYearAndLienDate(date1, date2)
      });
    }
}

