<app-order-create-step-circles [stepProvider]="orderService" [orderType]="orderTypeFC.value"></app-order-create-step-circles>

<div 
    *ngIf="initialized" 
    class="container-fluid bordered-container-middle light-blue-bordered"
    [style.background-color]="themeService.tertiaryColor">

    <div class="row">
        <div class="col-md-6 pt-4 border-right">
            <div class="row">
                <div class="col bold attribute-h1" align="center">
                    Order Information
                </div>
            </div>
            <app-input-enum label="Order Type" [fc]="orderTypeFC" [enumValues]="orderTypeEnumValues"
                [change]="orderTypeChange.bind(this)"></app-input-enum>
            <app-input-search label="State" [fc]="stateFC" [searchFn]="searchStates.bind(this)" [values$]="states$"
                [change]="saveOrderCache.bind(this)" [itemSelect]="stateSelect.bind(this)"></app-input-search>
            <app-input-search label="County" [fc]="countyFC" [trigger$]="stateSelector$"
                [searchFn]="searchCounties.bind(this)" [values$]="counties$" [change]="saveOrderCache.bind(this)"
                [itemSelect]="countyItemSelect.bind(this)"></app-input-search>
            <app-input-search *ngIf="countySelected" label="Address" [fc]="addressFC"
                [searchFn]="searchAddress.bind(this)" [getItemDisplayValue]="getAddressItemDisplay.bind(this)"
                [itemSelect]="addressItemSelect.bind(this)" [change]="saveOrderCache.bind(this)">
            </app-input-search>
            <app-input-search #sblSearchInput *ngIf="countySelected" label="SBL" [fc]="sblFC"
                [searchFn]="searchSBL.bind(this)" [getItemDisplayValue]="getSBLItemDisplay.bind(this)"
                [itemSelect]="sblItemSelect.bind(this)" [change]="saveOrderCache.bind(this)">
            </app-input-search>
            <div *ngIf="sblOrderInProgress" class="row">
                <div class="col-4">
                </div>
                <div class="col">
                    <span class="validation-error">Note: An order is in progress for this SBL.</span>
                </div>
            </div>
            <app-input-text-list *ngIf="propertySelected" label="Seller" [fc]="sellerFC"
                [change]="saveOrderCache.bind(this)">
            </app-input-text-list>
            <app-input-text-list *ngIf="propertySelected" label="Buyer" [fc]="buyerFC"
                [change]="saveOrderCache.bind(this)">
            </app-input-text-list>
            <app-input-text-list *ngIf="propertySelected" label="Assessed Owner" [fc]="assessedOwnerFC"
                [change]="saveOrderCache.bind(this)" [exampleText]="'(If different than seller)'">
            </app-input-text-list>
            <app-input-enum *ngIf="propertySelected" label="Property Type" [fc]="propertyTypeFC"
                [enumValues]="propertyTypeEnumValues" [change]="saveOrderCache.bind(this)"></app-input-enum>

            <div *ngIf="hasTaxMapLink()" class="row edit-height" [style.background-color]="themeService.tertiaryColor">
                <div class="col-4 bold unedit-padding">
                    Tax Map
                </div>
                <div class="col-8 bold unedit-padding">
                    <a [href]="getTaxMapLink()" target="_blank">[view tax map]</a>
                </div>
            </div>
        </div>
        <div class="col-md-6 pt-4">
            <div class="row">
                <div class="col bold attribute-h1" align="center">
                    Internal Information
                </div>
            </div>

            <app-input-enum label="Provider" [fc]="providerFC" [enumValuesNameAndId]="providers"
                [change]="saveOrderCache.bind(this)" [disabled]="true">
            </app-input-enum>

            <app-input-text *ngIf="is_agent;else not_agent_company" label="Company Name" [fc]="companyNameFC"></app-input-text>

            <ng-template #not_agent_company>
                <app-input-search label="Company Name" [fc]="companyNameFC" [searchFn]="searchClientCompany.bind(this)"
                [getItemDisplayValue]="getClientCompanyDisplay.bind(this)" [itemSelect]="clientCompanySelect.bind(this)"
                [change]="clientCompanyChange.bind(this)">
                </app-input-search>
            </ng-template>

            <app-input-text *ngIf="is_agent;else not_agent_contact" label="Contact Name" [fc]="clientNameFC"></app-input-text>

            <ng-template #not_agent_contact>
                <app-input-search label="Contact Name" [fc]="clientNameFC" [searchFn]="searchClientUserFullName.bind(this)"
                [getItemDisplayValue]="getClientUserFullNameDisplay.bind(this)"
                [itemSelect]="clientUserFullNameSelect.bind(this)" [change]="clientUserFullNameChange.bind(this)">
                </app-input-search>
            </ng-template>
            

            <app-input-text *ngIf="is_agent;else not_agent_email" label="Client Email" [fc]="clientEmailFC"></app-input-text>

            <ng-template #not_agent_email>
                <app-input-search label="Contact Email" [fc]="clientEmailFC" [searchFn]="searchClientUserEmail.bind(this)"
                [getItemDisplayValue]="getClientUserEmailDisplay.bind(this)"
                [itemSelect]="clientUserEmailSelect.bind(this)" [change]="clientUserEmailChange.bind(this)">
                </app-input-search>
            </ng-template>

            <!-- old method with search feature we default to logged in user now
            <app-input-search label="Company Name" [fc]="companyNameFC" [searchFn]="searchClientCompany.bind(this)"
                [getItemDisplayValue]="getClientCompanyDisplay.bind(this)" [itemSelect]="clientCompanySelect.bind(this)"
                [change]="clientCompanyChange.bind(this)">
            </app-input-search>
            

            <app-input-search label="Contact Name" [fc]="clientNameFC" [searchFn]="searchClientUserFullName.bind(this)"
                [getItemDisplayValue]="getClientUserFullNameDisplay.bind(this)"
                [itemSelect]="clientUserFullNameSelect.bind(this)" [change]="clientUserFullNameChange.bind(this)">
            </app-input-search>

            <app-input-search label="Contact Email" [fc]="clientEmailFC" [searchFn]="searchClientUserEmail.bind(this)"
                [getItemDisplayValue]="getClientUserEmailDisplay.bind(this)"
                [itemSelect]="clientUserEmailSelect.bind(this)" [change]="clientUserEmailChange.bind(this)">
            </app-input-search>
            -->

            <div *ngIf="newClientError" class="row" align="">
                <div class="col-4">
                </div>
                <div class="col-8">
                    {{newClientError}}
                </div>
            </div>

            <div *ngIf="!this.cacheOrder.user_id" class="row" align="right">
                <div class="col-4">
                </div>
                <div class="col-8">
                    <app-button class="mx-1" label="Add Client" [clickFunction]="addClientCompany.bind(this)"
                        [icon]="faPlus" [small]="true">
                    </app-button>
                </div>
            </div>

            <app-input-text label="Reference #" [fc]="referenceNumberFC" [change]="saveOrderCache.bind(this)">
            </app-input-text>
            
            <app-input-text label="Client File #" [fc]="fileNumberFC" [change]="saveOrderCache.bind(this)">
            </app-input-text>
            
            <app-input-date label="Target Date of Closing" [fc]="targetDateOfClosingFC"
                [change]="saveOrderCache.bind(this)"></app-input-date>
            <app-input-date label="Due Date" [fc]="dueDateFC" [change]="saveOrderCache.bind(this)"></app-input-date>
            <app-input-text-area label="Internal Notes" [fc]="internalNotesFC" [change]="saveOrderCache.bind(this)">
            </app-input-text-area>
            <app-input-text *ngIf="propertySelected" label="Year Built" [fc]="yearBuiltFC"
                [change]="saveOrderCache.bind(this)" [disabled]="true"></app-input-text>
            <app-input-text *ngIf="propertySelected" label="Land Use" [fc]="landUseFC"
                [change]="saveOrderCache.bind(this)" [disabled]="true"></app-input-text>
            <app-input-text *ngIf="propertySelected" label="Municipality" [fc]="municipalityFC"
                [change]="saveOrderCache.bind(this)" [disabled]="true">
            </app-input-text>
            <app-input-text *ngIf="propertySelected" label="Municipality Code" [fc]="swisCodeFC" [disabled]="true">
            </app-input-text>
            <app-input-text *ngIf="propertySelected" label="School District" [fc]="schoolDistrictFC"
                [change]="saveOrderCache.bind(this)" [disabled]="true">
            </app-input-text>
        </div>
    </div>
    <div class="row edit-height">
        <div class="col-4 pb-2 unedit-padding bold">
            Search Type
        </div>
    </div>

    <span *ngFor="let searchType of searchTypeData; let isFirst = first; let isLast = last;">
        <div class="row edit-height">
            <div class="col-1 pb-2 unedit-padding">
            </div>
            <div class="col-11 pb-2 unedit-padding bold">
                <input type="radio" name="searchType" [formControl]="searchTypeFC" [value]="searchType.id"
                    (change)="saveOrderCache()" />
                <span (click)="selectPrimaryService(searchType)" class="pointer">
                    {{searchType.name}}
                </span>
            </div>
        </div>
        <span *ngIf="searchTypeFC.value == searchType.id">

            <div class="row">
                <div class="col-6">
                    <span *ngFor="let subType of searchType.services; let i = index;">
                        <div class="row" *ngIf="i <= searchType.services.length / 2">
                            <div class="col-3">
                            </div>
                            <div class="col-9 pb-2 unedit-padding">
                                <input type="radio" name="searchSubType" [formControl]="searchSubTypeFC"
                                    [value]="searchType.services[i].id" (change)="saveOrderCache()" />
                                <span (click)="searchSubTypeFC.setValue(searchType.services[i].id); saveOrderCache()"
                                    class="pointer">
                                    {{searchType.services[i].name}}</span>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="i <= searchType.services.length / 2 && searchType.services[i].name == 'Continuation'">
                            <div class="col-4">
                            </div>
                            <div class="col-8 pb-2 unedit-padding">
                                <div class="row">
                                    <div class="col-4 pb-2 unedit-padding bold">
                                        Start From
                                    </div>
                                    <div class="col-8 pb-2">
                                        <input type="date" [formControl]="continuationDateFC"
                                            (change)="saveOrderCache()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
                <div class="col-6">
                    <span *ngFor="let subType of searchType.services; let i = index;">
                        <div class="row" *ngIf="i > searchType.services.length / 2">
                            <div class="col pb-2 unedit-padding">
                                <input type="radio" name="searchSubType" [formControl]="searchSubTypeFC"
                                    [value]="searchType.services[i].id" (change)="saveOrderCache()" />
                                <span (click)="searchSubTypeFC.setValue(searchType.services[i].id); saveOrderCache()"
                                    class="pointer">
                                    {{searchType.services[i].name}}</span>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="i > searchType.services.length / 2 && searchType.services[i].name == 'Continuation'">
                            <div class="col-1">
                            </div>
                            <div class="col-8 pb-2 unedit-padding">
                                <div class="row">
                                    <div class="col-4 pb-2 unedit-padding bold">
                                        Start From
                                    </div>
                                    <div class="col-8 pb-2">
                                        <input type="date" [formControl]="continuationDateFC"
                                            (change)="saveOrderCache()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </span>
    </span>
    <div class="row edit-height">
        <div class="col-4 pb-2 unedit-padding bold">
            Include the following:
        </div>
    </div>
    <div class="col-11 pb-2 unedit-padding bold">
        <input type="checkbox" class="pointer" id="itfTaxSearch" name="itfTaxSearch" [formControl]="itfTaxSearch" (change)="saveOrderCache()" />
        <label for="itfTaxSearch" class="pointer">
            &nbsp;Tax Search
        </label>
    </div>
    <div class="col-11 pb-2 unedit-padding bold">
        <input type="checkbox" class="pointer" id="itfTaxSearchCont" name="itfTaxSearchCont" [formControl]="itfTaxSearchCont" (change)="saveOrderCache()" />
        <label for="itfTaxSearchCont" class="pointer">
            &nbsp;Tax Search Continuation
        </label>
    </div>
    <div class="col-11 pb-2 unedit-padding bold">
        <input type="checkbox" class="pointer" id="itfJudgeLien" name="itfJudgeLien" [formControl]="itfJudgeLien" (change)="saveOrderCache()" />
        <label for="itfJudgeLien" class="pointer">
            &nbsp;Judgment/Lien Search
        </label>
    </div>
    <div class="col-11 pb-2 unedit-padding bold">
        <input type="checkbox" class="pointer" id="itfCertOcc" name="itfCertOcc" [formControl]="itfCertOcc" (change)="saveOrderCache()" />
        <label for="itfCertOcc" class="pointer">
            &nbsp;Certificate of Occupancy
        </label>
    </div>
    <div class="col-11 pb-2 unedit-padding bold">
        <input type="checkbox" class="pointer" id="itfHouseBuild" name="itfHouseBuild" [formControl]="itfHouseBuild" (change)="saveOrderCache()" />
        <label for="itfHouseBuild" class="pointer">
            &nbsp;Housing and Building
        </label>
    </div>
    <div class="col-11 pb-2 unedit-padding bold">
        <input type="checkbox" class="pointer" id="itfStreetRep" name="itfStreetRep" [formControl]="itfStreetRep" (change)="saveOrderCache()" />
        <label for="itfStreetRep" class="pointer">
            &nbsp;Street Report
        </label>
    </div>
    <div class="col-11 pb-2 unedit-padding bold">
        <input type="checkbox" class="pointer" id="itfCourtesyDeed" name="itfCourtesyDeed" [formControl]="itfCourtesyDeed" (change)="saveOrderCache()" />
        <label for="itfCourtesyDeed" class="pointer">
            &nbsp;Courtesy Deed
        </label>
    </div>
</div>
<app-order-create-buttons [processNext]="doNextButton.bind(this)" [nextDisabler]="nextDisabler.bind(this)"
    [validator]="validate.bind(this)" [orderType]="orderTypeFC.value">
</app-order-create-buttons>