<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-sm-12 col-md-10 col-lg-8 m-3">
            <h1 class="error-title text-warning">Something Went Wrong</h1>
            <p class="error-note">
                We have encountered a problem with your request and it cannot be completed at this time. Please try again later or contact us if you continue to see this message.
            </p>
            <div class="error-panel">
                <label class="panel-label">Problem Details:</label>
                <pre class="panel-body json-code">{{errorMessage}}</pre>
                <!-- Uncomment if you want to include version info -->
                <!-- <div class="panel-footer">
                    <label class="footer-label">Build Version:</label>
                    <span>{{aboutData?.version ?? ''}}</span>
                </div> -->
            </div>
        </div>
    </div>
</div>