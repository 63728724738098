import { Injectable, ApplicationRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    private getServicesURL = 'api/getServices';
    private deleteDraftOrderURL = 'api/deleteDraftOrder';
    private saveOrderURL = 'api/saveOrder';
    private getOrderURL = 'api/getOrder';
    private sendOrderSubmissionEmailURL = 'api/sendOrderSubmissionEmail';
    private getOrderStatusesURL = 'api/getOrderStatuses';
    private searchOrdersURL = 'api/searchOrders';
    private searchOrders2URL = 'api/searchOrders2';
    private getOrderSheetPDFURL = "api/orderSheetPDF"
    private getRunSheetPDFURL = "api/runSheetPDF"
    private steps;
    stepComponent;
    private getSearchTypeDisplayURL = "api/getSearchTypeDisplay";
    private getServiceMappingURL = "api/getServiceMapping";
    private getServiceRowsURL = "api/getServiceRows";
    private setOrderStatusURL = "api/setOrderStatus";
    private revertOrderStatusURL = "api/revertOrderStatus";
    private orderUpdateSBLURL = "api/orderUpdateSBL"
    private getOrder3URL = "api/getOrder3"
    private orderHasInvoiceURL = "api/orderHasInvoice"

    constructor(
        private ref: ApplicationRef,
        private http: HttpClient,
    ) {
        this.resetSteps();
    }

    getOrderSheetPDF(order_id) {
        var options: any = { observe: 'response', responseType: 'blob' };
        return this.http.get<any>(this.getOrderSheetPDFURL + "/" + order_id, options);
    }

    getRunSheetPDF(order_workflow_id) {
        var options: any = { observe: 'response', responseType: 'blob' };
        return this.http.get<any>(this.getRunSheetPDFURL + "/" + order_workflow_id, options);
    }

    getSteps() {
        return this.steps;
    }

    hasBackTitleStep = false;
    hasDocumentStep = false;

    addBackTitleStep() {
        this.steps[1].hidden = false;
        this.ref.tick();
    }

    removeBackTitleStep() {
        this.steps[1].hidden = true;
    }

    addDocumentStep() {
        this.steps[6].hidden = false;
    }

    removeDocumentStep() {
        this.steps[6].hidden = true;
    }

    setServicesSteps(type) {
        if (type == "Property") {
            this.steps[2].hidden = false;
            this.steps[3].hidden = false;
            this.steps[4].hidden = false;
        } else if (type == "Individual") {
            this.steps[2].hidden = true;
            this.steps[3].hidden = false;
            this.steps[4].hidden = false;

            if (this.steps[3].label == "Entity Services") {
                var tmp = this.steps[3];
                this.steps[3] = this.steps[4];
                this.steps[4] = tmp;
            }
        } else if (type == "Entity") {
            this.steps[2].hidden = true;
            this.steps[3].hidden = false;
            this.steps[4].hidden = false;
            if (this.steps[3].label == "Individual Services") {
                var tmp = this.steps[3];
                this.steps[3] = this.steps[4];
                this.steps[4] = tmp;
            }
        } else {
            throw Error("unknown services type " + type);
        }
    }

    resetSteps() {
        this.steps = [
            { label: "Create", route: "/order-create" },
            { label: "Back Title", route: "/order-back-title", hidden: true },
            { label: "Property", route: "/order-services-property" },
            { label: "Individual", route: "/order-services-individual" },
            { label: "Entity", route: "/order-services-entity" },
            { label: "Notifications", route: "/order-notifications" },
            { label: "Documents", route: "/order-documents" },
            { label: "Review", route: "/order-review" },
        ];
        this.hasBackTitleStep = false;
        this.hasDocumentStep = false;
        if (this.stepComponent) {
            setTimeout(() => {
                var fn = this.stepComponent.ngAfterViewInit;
                fn.bind(this.stepComponent);
                fn();
            }, 0);
        }
    }

    getAllServices() {
        return this.http.get<any>(this.getServicesURL);
    }

    getServices(pageId) {
        return this.http.get<any>(this.getServicesURL + "/" + pageId);
    }

    getPrimarySearchTypeData(callback) {
        this.getServices(1).subscribe((response) => {
            var searchTypeData = [];
            if (response.success) {
                var lastGroupId = null;
                for (var i = 0; i < response.result.length; ++i) {
                    if (response.result[i].group_id != lastGroupId) {
                        searchTypeData.push({
                            id: response.result[i].group_id,
                            name: response.result[i].group_name,
                            services: [],
                        });
                    }
                    lastGroupId = response.result[i].group_id;

                    if (response.result[i].service_id) {
                        searchTypeData[searchTypeData.length - 1].services.push({
                            id: response.result[i].service_id,
                            name: response.result[i].service_name,
                        });
                    }
                }
            }
            callback(searchTypeData);
        });
    }

    deleteDraftOrder(orderId) {
        return this.http.delete<any>(this.deleteDraftOrderURL + "/" + orderId);
    }

    saveOrder(order, status) {
        return this.http.put<any>(this.saveOrderURL,
            { order: order, status: status }, httpOptions);
    }

    // getOrders(filters) {
    //     return this.http.put<any>(this.getOrdersURL,
    //         { filters: filters }, httpOptions);
    // }

    getOrder(id) {
        return this.http.get<any>(this.getOrderURL + "/" + id);
    }

    sendOrderSubmissionEmail(order_uuid, diff) {
        var urlPrefix = window.location.href.substring(0, window.location.href.length - window.location.pathname.length);
        return this.http.put<any>(this.sendOrderSubmissionEmailURL,
            { order_uuid: order_uuid, diff: diff, urlPrefix }, httpOptions);
    }

    getOrderStatuses() {
        return this.http.get<any>(this.getOrderStatusesURL);
    }

    search(searchCriteria, limitType) {
        return this.http.put<any>(this.searchOrdersURL,
            { searchCriteria: searchCriteria, limitType: limitType }, httpOptions);
    }

    search2(searchCriteria, limitType) {
        return this.http.put<any>(this.searchOrders2URL,
            { searchCriteria: searchCriteria, limitType: limitType }, httpOptions);
    }

    dashboardSearch(dashboard, currentPage, sort) {
        return this.http.put<any>("api/dashboardOrders",
            { dashboard: dashboard, currentPage: currentPage, sort: sort }, httpOptions);
    }

    updateInternalNotes(order_uuid, notes) {
        return this.http.post<any>("api/orderUpdateInternalNotes",
            { order_uuid: order_uuid, notes: notes }, httpOptions);
    }

    updateLongNotes(order_uuid, notes) {
        return this.http.post<any>("api/orderUpdateLongNotes",
            { order_uuid: order_uuid, notes: notes }, httpOptions);
    }

    getOrderLog(orderUuid) {
        return this.http.get<any>("api/orderLog/" + orderUuid);
    }

    getSearchTypeDisplay(order_uuid) {
        return this.http.get<any>(this.getSearchTypeDisplayURL + "/" + order_uuid, httpOptions);
    }

    getServiceMapping() {
        return this.http.get<any>(this.getServiceMappingURL, httpOptions);
    }

    getServiceRows(order_uuid) {
        return this.http.get<any>(this.getServiceRowsURL + "/" + order_uuid, httpOptions);
    }

    setOrderStatus(order_uuid, status, comments) {
        return this.http.post<any>(this.setOrderStatusURL,
            { order_uuid: order_uuid, status: status, comments }, httpOptions);
    }

    revertOrderStatus(order_uuid, comments) {
        return this.http.post<any>(this.revertOrderStatusURL,
            { order_uuid: order_uuid, comments }, httpOptions);
    }

    updateOrderSBL(
        order_uuid,
        tax_map_data_id,
        state,
        county,
        sbl,
        address,
        year_built,
        municipality_code,
        municipality,
        school_district_code,
        school_district,
        property_type,
        land_use,
        deed_book,
        deed_page,
    ) {
        return this.http.post<any>(this.orderUpdateSBLURL,
            {
                order_uuid: order_uuid,
                tax_map_data_id,
                state,
                county,
                sbl,
                address,
                year_built,
                municipality_code,
                municipality,
                school_district_code,
                school_district,
                property_type,
                land_use,
                deed_book,
                deed_page,
            }, httpOptions);
    }

    getOrder3(order_uuid) {
        return this.http.get<any>(this.getOrder3URL + "/" + order_uuid);
    }

    orderHasInvoice(order_uuid) {
        return this.http.get<any>(this.orderHasInvoiceURL + "/" + order_uuid);
    }

    downloadOrderSheetPDF(orderUuid) {
        var orderId
        this.getOrderIdByOrderUuid(orderUuid).subscribe((response) => {
            if (response.success) {
                orderId = response.order_id
                this.getOrderSheetPDF(orderUuid).subscribe((response) => {
                    var tmp: any = response;
                    var blob = new Blob([tmp.body], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    var link = document.createElement('a');
                    link.href = url;
                    link.download = "ordersheet_" + orderId + ".pdf";
                    link.click();
                    setTimeout(() => {
                        window.URL.revokeObjectURL(url);
                    }, 0)
                });
            } else {
                console.log("error occurred while getting order id by uuid", response)
            }
        })
    }

    viewOrderSheetPDF(orderUuid) {
        this.getOrderSheetPDF(orderUuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        });
    }

    downloadRunSheetPDF(orderUuid) {
        this.getRunSheetPDF(orderUuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            link.download = "runsheet.pdf";
            link.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 0)
        });
    }

    viewRunSheetPDF(orderUuid) {
        this.getRunSheetPDF(orderUuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        });
    }

    updateOrderDueDate(orderUuid, dueDate) {
        return this.http.post<any>("api/updateOrderDueDate", { order_uuid: orderUuid, due_date: dueDate }, httpOptions);
    }

    getOrderIdByOrderUuid(orderUuid) {
        return this.http.get<any>("api/orderIdByOrderUuid/" + orderUuid);
    }

    adornDocuments(order_uuid) {
        return this.http.get<any>("api/adornDocuments/" + order_uuid, httpOptions);
    }

    getAdditionalCosts(order_uuid) {
        return this.http.get<any>("api/orderAdditionalCosts/" + order_uuid, httpOptions);
    }

    addAdditionalCost(order_uuid, price, quantity, description, type, name) {
        return this.http.put<any>("api/orderAdditionalCosts", { order_uuid, price, quantity, description, type, name }, httpOptions);
    }

    updateAdditionalCost(order_uuid, cost_uuid, price, quantity, description, type, name) {
        return this.http.post<any>("api/orderAdditionalCosts", { order_uuid, cost_uuid, price, quantity, description, type, name }, httpOptions);
    }

    deleteAdditionalCost(cost_uuid) {
        return this.http.delete<any>("api/orderAdditionalCosts/" + cost_uuid, httpOptions);
    }

    getClientsForInvoice() {
        return this.http.get<any>("api/orderClientsForInvoice/", httpOptions);
    }

    getOrderForSBLExists(county, sbl) {
        return this.http.post<any>("api/orderForSBLExists/", { county, sbl }, httpOptions);
    }

    getAgentDashboard(agent_id: string, order_status: string) {
        return this.http.get<any>("api/agentDashboard/" + agent_id + "/" + order_status, httpOptions)
    }
}
