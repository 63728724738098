<div class="container-fluid pt-1">
    <app-dashboard-controls [title]="title" [pageNumber]="currentPage" [pageSize]="limit" [entryCount]="count"
        [setPage]="this.setPage.bind(this)" newRoute="/client">
    </app-dashboard-controls>
    <div class="row pt-1 pb-2">
        <div class="col">
            <table class="table table-striped">
                <thead>
                    <tr class="table-header table-header-grey">
                        <td *ngFor="let display_column of displayColumns" (click)="doSort(display_column.field)"
                            class="py-2 pointer nowrap">{{display_column.name}}<span
                                *ngIf="sortColumn == display_column.field">
                                <span *ngIf="!sortDirection">
                                    <fa-icon [icon]="faCaretDown" size="1x" title="sorted ascending"></fa-icon>
                                </span>
                                <span *ngIf="sortDirection">
                                    <fa-icon [icon]="faCaretUp" size="1x" title="sorted descending"></fa-icon>
                                </span>
                            </span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" *ngFor="let client of clients$ | async">
                        <td>
                            <!-- SIER-1761 This used to direct to /client with "id" param instead of uuid, in case we need to switch back -->
                            <a routerLink="/client" [queryParams]="{id: client.id}">
                                {{client.name}}
                            </a>
                        </td>
                        <td>
                            {{client.address}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <app-dashboard-controls [title]="title" [pageNumber]="currentPage" [pageSize]="limit" [entryCount]="count"
        [setPage]="this.setPage.bind(this)" [isFooter]="true">
    </app-dashboard-controls>
</div>