<div *ngIf="format == InputSearchFormat.verticalLabel" class="row">
    <div class="col">
        <div class="row">
            <div class="col mt-2 unedit-padding bold">
                {{label}}<span *ngIf="requiredMarker" style="position: relative;"
                    (mouseenter)="getElementById(attributeIdentifier + '_required_mouseover').style.display='block';"
                    (mouseleave)="getElementById(attributeIdentifier + '_required_mouseover').style.display='none';">*<span
                        style="position: absolute; display: none; top: 0px; left: 1.0em;"
                        id="{{attributeIdentifier}}_required_mouseover">(required)</span></span>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col" style="position: relative;">
                <div style="width: 100%">
                    <input [autocomplete]="autocompleteString(attributeIdentifier + '_auto')" class="input-style ml-2"
                        [id]="autocompleteString(attributeIdentifier + '_id')" (click)="searchClick()"
                        (input)="doSearch()" (blur)="hideDropdown()" (keydown.arrowup)="upArrow()"
                        (keydown.arrowdown)="downArrow()" (keydown.tab)="enter()" (keydown.enter)="enter()"
                        (keyup)="doChange()" [formControl]="fc" />

                    <fa-icon *ngIf="showDownArrow" class="dropdown-arrow pointer" [icon]="faCaretDown"
                        (click)="searchClick()" size="1x"></fa-icon>

                    <div [id]="attributeIdentifier + '_result_container'" class="dropdown-container">
                        <div *ngFor="let searchItem of searchedList$ | async; let searchItemIndex = index"
                            class="search-result" id="search_item_{{attributeIdentifier}}_{{searchItemIndex}}"
                            (mousedown)="searchSelection(searchItem)">
                            {{getItemDisplay(searchItem)}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="format == InputSearchFormat.none" class="row">
    <div class="col pb-2 relative">
        <div style="width: 100%">
            <input [autocomplete]="autocompleteString(attributeIdentifier + '_auto')" class="input-style"
                [id]="autocompleteString(attributeIdentifier + '_id')" (click)="searchClick()" (input)="doSearch()"
                (blur)="hideDropdown()" (keydown.arrowup)="upArrow()" (keydown.arrowdown)="downArrow()"
                (keydown.tab)="enter()" (keydown.enter)="enter()" (keyup)="doChange()" [formControl]="fc" />

            <fa-icon *ngIf="showDownArrow" class="dropdown-arrow pointer" [icon]="faCaretDown" (click)="searchClick()"
                size="1x"></fa-icon>

            <div [id]="attributeIdentifier + '_result_container'" class="dropdown-container">
                <div *ngFor="let searchItem of searchedList$ | async; let searchItemIndex = index" class="search-result"
                    id="search_item_{{attributeIdentifier}}_{{searchItemIndex}}"
                    (mousedown)="searchSelection(searchItem)">
                    {{getItemDisplay(searchItem)}}
                </div>
            </div>
        </div>
    </div>
</div>