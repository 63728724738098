<div class="container-fluid px-0">
    <div *ngFor="let notification of notifications; let i = index"
        [ngClass]="{'banner': i == 0, 'banner-below': i != 0}" class="px-2 relative">
        <span *ngIf="notification.type == types.orderCreated">
            Order <a *ngIf="!is_agent;else no_link" routerLink="searcher-order-edit"
                [queryParams]="{ orderUuid: notification.uuid }">{{notification.order_id}}</a><ng-template #no_link>{{notification.order_id}}</ng-template> has been submitted.
        </span>
        <span *ngIf="notification.type == types.draftOrderSaved">
            Draft Order <a routerLink="order-create"
                [queryParams]="{ orderUuid: notification.uuid }">{{notification.order_id}}</a> has been saved.
        </span>
        <div style="position: absolute; right: .5em; top: .0em;">
            <fa-icon [icon]="faTimes" class="dismiss-button pointer" title="Dismiss" (click)="dismiss(i)">
            </fa-icon>
        </div>
    </div>
</div>