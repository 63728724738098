<span *ngIf="!initialized">
    <div class="container-fluid mt-4 rounded-ninety" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor">
        Initializing...
    </div>
</span>
<div *ngIf="initialized" class="container-fluid mt-4">
    <div class="row mt-4">
        <div class="col bold" style="font-size: 1.2em;">
            History for Invoice {{invoice.invoice_id}}
        </div>
    </div>

    <div class="row table-header py-4">
        <div class="col-2">
            Action
        </div>
        <div class="col-3">
            User
        </div>
        <div class="col-3">
            Time
        </div>
        <div class="col-2">
            Previous Status
        </div>
        <div class="col-2">
            New Status
        </div>
    </div>
    <div class="container-striped-alt">
        <div *ngFor="let historyEntry of history" class="row py-4 table-row">
            <div class="col-2">
                {{historyEntry.action}}
            </div>
            <div class="col-3">
                {{historyEntry.user}}
            </div>
            <div class="col-3">
                {{historyEntry.event_time | date: 'medium'}}
            </div>
            <div class="col-2">
                {{historyEntry.previous_status_name}}
            </div>
            <div class="col-2">
                {{historyEntry.new_status_name}}
            </div>
        </div>
    </div>


    <div class="row py-3">
        <div class="col" align="center">
            <app-button label="Back" [clickFunction]="back.bind(this)" [icon]="faChevronLeft">
            </app-button>
        </div>
    </div>

</div>