import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class SchoolService
{

    constructor(private http: HttpClient) { 
        // Nothing to do
    }

    //update school
    updateSchool(school: any): Observable<any> {
        console.log(school)
        return this.http.put<any>("api/updateSchool", {school: school})
    }


    //get schools list
    getSchools(): Observable<any> {
        return this.http.get<any>("api/getSchools")
    }
}