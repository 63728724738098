<div *ngIf="initialized" class="container-fluid mt-2"
    style="background-color: white; border-style: solid; border-width: 2px; border-color: white;">

    <div class="row">
        <div class="col">
            <span class="pointer" (click)="orderService.viewRunSheetPDF(orderUuid)">
                View PDF
            </span>
            <span class="pointer ml-2" (click)="orderService.downloadRunSheetPDF(orderUuid)">
                Download PDF
            </span>
        </div>
    </div>

    <div class="row">
        <div class="col-8">
            <div class="row">
                <div class="col">

                    <div #imageCol style="height: 64px; display: inline-block;"></div>
                    <div style="display: inline-block;">
                        <span *ngIf="provider">{{provider.address}}</span><br />
                        <span *ngIf="provider && provider.phone">Phone: {{provider.phone}}<br /></span>
                        <span *ngIf="provider && provider.fax">Fax {{provider.fax}}</span>
                    </div>
                    <div *ngIf="provider && provider.notify_email_address">{{provider.notify_email_address}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    Current Owner(s):
                </div>
                <div class="col-8 bottom-border">
                    <span *ngIf="order.property_seller">
                        <span *ngFor="let seller of order.property_seller; let last = last">
                            {{seller}}<span *ngIf="!last">, </span>
                        </span>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    Property Address:
                </div>
                <div class="col-8 bottom-border">
                    {{order.address}}
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    &nbsp;
                </div>
                <div class="col-8 bottom-border">
                </div>
            </div>
        </div>
        <div class="col-4" align="right">
            <div class="row">
                <div class="col-4">
                    Searcher:
                </div>
                <div class="col-8 bottom-border">
                </div>
            </div>
            <div class="row py-2">
                <div class="col">
                    <div *ngFor="let difficulty of difficulties" class="difficulty-number">
                        {{difficulty}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    County:
                </div>
                <div class="col-8 bottom-border">
                    {{order.county_name}}
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    Order #:
                </div>
                <div class="col-8 bottom-border">
                    NEEDS BA
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    Type:
                </div>
                <div class="col-8 bottom-border">
                    {{searchTypeDisplay}}
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    Item #:
                </div>
                <div class="col-8 bottom-border">
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    File #:
                </div>
                <div class="col-8 bottom-border">
                    {{order.file_number}}
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-1" align="right">
            SBL
        </div>
        <div class="col-2 bottom-border">
            {{order.sbl}}
        </div>
        <div class="col-2" align="right">
            Deed Reference
        </div>
        <div class="col-2 bottom-border">
            <span *ngIf="order.deed_book || order.deed_page">
                {{order.deed_book}}/{{order.deed_page}}
            </span>
        </div>
        <div class="col-2" align="right">
            Effective Date
        </div>
        <div class="col-3 bottom-border">
        </div>
    </div>

    <div class="row pt-2 last-names-searched-row">
        <div class="col-4">
            NAMES SEARCHED
        </div>
        <div class="col-4">
            DATES SEARCHED
        </div>
        <div class="col px-0" align="center">
            GI
        </div>
        <div class="col px-0" align="center">
            MI
        </div>
        <div class="col px-0" align="center">
            JD*
        </div>
        <div class="col px-0" align="center">
            FTL&dagger;
        </div>
        <div class="col px-0" align="center">
            CL
        </div>
        <div class="col px-0" align="center">
            LP
        </div>
        <div class="col px-0" align="center">
            TT
        </div>
        <div class="col px-0" align="center">
            CD
        </div>
        <div class="col px-0" align="center">
            SC
        </div>
        <div class="col px-0" align="center">
            IR
        </div>
    </div>

    <div class="row names-searched-row" *ngFor="let namesSearchedRow of namesSearchedRows; let last = last"
        [ngClass]="{'last-names-searched-row': last}">
        <div class="col-4 names-searched-col first-names-searched-col">
            &nbsp;
        </div>
        <div class="col-4 names-searched-col">
            &nbsp;
        </div>
        <div class="col px-0 names-searched-col">
            &nbsp;
        </div>
        <div class="col px-0 names-searched-col">
            &nbsp;
        </div>
        <div class="col px-0 names-searched-col">
            &nbsp;
        </div>
        <div class="col px-0 names-searched-col">
            &nbsp;
        </div>
        <div class="col px-0 names-searched-col">
            &nbsp;
        </div>
        <div class="col px-0 names-searched-col">
            &nbsp;
        </div>
        <div class="col px-0 names-searched-col">
            &nbsp;
        </div>
        <div class="col px-0 names-searched-col">
            &nbsp;
        </div>
        <div class="col px-0 names-searched-col">
            &nbsp;
        </div>
        <div class="col px-0 names-searched-col last-names-searched-col">
            &nbsp;
        </div>
    </div>

    <div class="row pt-2 last-names-searched-row">
        <div class="col-2">
            DEEDS L/CP
        </div>
        <div class="col-2">
            DESCRIPTION
        </div>
        <div class="col-2">
            MORTS. L/MP
        </div>
        <div class="col-2">
            DESCRIPTION
        </div>
        <div class="col-2">
            MISC
        </div>
        <div class="col-2">
        </div>
    </div>

    <div class="row" *ngFor="let deedsMortsSearchedRow of deedsMortsSearchedRows; let last = last"
        [ngClass]="{'last-names-searched-row': last}">
        <div class="col-2 deeds-col first-names-searched-col" [ngClass]="{'deeds-col-last': last}">
            &nbsp;
        </div>
        <div class="col-2 deeds-col" [ngClass]="{'deeds-col-last': last}">
            &nbsp;
        </div>
        <div class="col-2 deeds-col" [ngClass]="{'deeds-col-last': last}">
            &nbsp;
        </div>
        <div class="col-2 deeds-col" [ngClass]="{'deeds-col-last': last}">
            &nbsp;
        </div>
        <div class="col-2 deeds-col" [ngClass]="{'deeds-col-last': last}">
            &nbsp;
        </div>
        <div class="col-2 deeds-unlabeled-col" [ngClass]="{'deeds-col-last': last}">
            &nbsp;
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-9">
            <div class="row">
                <div class="col-3" align="right">
                    Judgments:
                </div>
                <div class="col-1 bottom-border">
                    &nbsp;
                </div>
                <div class="col-3" align="right">
                    NYS Tax Warrants:
                </div>
                <div class="col-1 bottom-border">
                    &nbsp;
                </div>
                <div class="col-3" align="right">
                    Total Copies:
                </div>
                <div class="col-1 bottom-border">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-3" align="right">
                    UCC's:
                </div>
                <div class="col-1 bottom-border">
                    &nbsp;
                </div>
                <div class="col-3" align="right">
                    Federal Tax Liens:
                </div>
                <div class="col-1 bottom-border">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-3" align="right">
                    Lis Pendens:
                </div>
                <div class="col-1 bottom-border">
                    &nbsp;
                </div>
                <div class="col-3" align="right">
                    Mechanics Liens:
                </div>
                <div class="col-1 bottom-border">
                    &nbsp;
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="row">
                <div class="col-10 pr-0">
                    <div class="row">
                        <div class="col pr-0">
                            Auxilary Run Sheet
                        </div>
                    </div>
                    <div class="row">
                        <div class="col pr-0">
                            attached if box is checked
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="box-to-check"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-6">
            *Federal Judgments (20 years)
        </div>
        <div class="col-6">
            &dagger;Federal Tax Liens, Judgments and Tax Warrants (10 years)
        </div>
    </div>

</div>