import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-input-enum-and-text',
  templateUrl: './input-enum-and-text.component.html',
})
export class InputEnumAndTextComponent implements OnInit {
    @Input() label;
    @Input() fc;
    @Input() textFC;
    @Input() enumValues;
    @Input() enumValuesNameAndId;
    @Input() change;
    @Input() requiredMarker;
    @Input() disabled;
    @Input() includeUnset;
    @Input() includeUnsetDisplay;
    @Input() style;
    @Input() naturalWidth;
    letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    attributeIdentifier = this.randomString();
    @Input() rowClass;
    calcRowClass

    randomString() {
        var str = "";
        for (var i = 0; i < 20; ++i) {
            str += this.letters.charAt(Math.random() * this.letters.length);
        }
        return str;
    }

    getElementById(id) {
        return document.getElementById(id);
    }

    constructor() { }

    ngOnInit() {
        this.calcRowClass = {};
        this.calcRowClass[this.rowClass] = true;
        this.includeUnset = true
    }

    getDisplayValue() {
        if (this.enumValues) {
            return this.fc.value;
        }
        if (this.enumValuesNameAndId) {
            for (var i = 0; i < this.enumValuesNameAndId.length; ++i) {
                if (this.enumValuesNameAndId[i].id == this.fc.value) {
                    return this.enumValuesNameAndId[i].name;
                }
            }
        }
    }

    doChange(e) {
        if (this.change) {
            this.change(e);
        }
    }
}
