import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-input-enum-list',
    templateUrl: './input-enum-list.component.html',
})
export class InputEnumListComponent implements OnInit {
    @Input() label;
    @Input() fc;
    @Input() enumValues;
    @Input() change;
    faMinus = faMinus;
    faPlus = faPlus;

    constructor() { }

    ngOnInit() {
        if (this.fc.fcs.length == 0) {
            this.fc.fcs = [new UntypedFormControl()];
        }
    }

    addFC(index) {
        this.fc.fcs.splice(index + 1, 0, new UntypedFormControl());
        this.doChange();
    }

    removeFC(index) {
        this.fc.fcs.splice(index, 1);
        if (this.fc.fcs.length == 0) {
            this.addFC(0);
        }
        this.doChange();
    }

    doChange() {
        if (this.change) {
            this.change();
        }
    }
}
