import { HttpClient } from "@angular/common/http";
import { SYSCOUNTY } from "../mock/sys-county-mock";
import { Observable, of } from "rxjs";
import { SysCounty } from "../model/sys-county.model";
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class CountyServiceMock
{

    constructor(private http: HttpClient) 
    { 
        // Nothing to do
    }

    //update counties
    updateCounty(county: SysCounty): Observable<SysCounty>
    {
        let result = SYSCOUNTY.find(c => c.id === county.id);
        result.countyId = county.countyId;
        result.id = county.id;
        result.municipalityCode = county.municipalityCode;
        result.state = county.state;
        result.countyName = county.countyName;
        result.type = county.type;
        result.deprecated = county.deprecated;
        result.address = county.address;
        result.taxYear = county.taxYear;
        result.taxPeriod = county.taxPeriod;
        result.lienDate = county.lienDate;
        result.fiscalStartDate = county.fiscalStartDate;
        result.fiscalEndDate = county.fiscalEndDate;
        result.dueDate1 = county.dueDate1;
        result.dueDate2 = county.dueDate2;
        result.dueDate3 = county.dueDate3;
        result.dueDate4 = county.dueDate4;
        result.refreshDate = county.refreshDate;
        return of(result)
    }

    //get county list
    getCounties(): Observable<SysCounty[]>
    {
        return of(SYSCOUNTY);
    }
}