import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of} from "rxjs"; 
import { SysMunicipality } from "../model/sys-municipality.model";
import { SysTaxType } from "../model/sys-tax-type.model";

@Injectable({
    providedIn: 'root'
})

export class MunicipalitySevice {
    
    constructor(private http: HttpClient) 
    { 
        // Nothing to do
    }

    //Update municipality
    updateMunicipality(municipality: SysMunicipality): Observable<any> {
        return this.http.put<any>('api/updateMunicipality', {municipality: municipality});
    }

    getMunicipalities(): Observable<any> {
        return this.http.get<any>('api/getMunicipality');
    }

    getTaxTypes(): Observable<any> {
        return this.http.get<any>('api/taxTypes');
    }
}
