import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TimezoneService {


    constructor(
    ) {
    }


    // insert into time_zone(name, utc_offset_seconds, utc_offset_hour_minute, display_value, display_order) values 
    // ('America/New_York', '-18000', '-05:00', 'America/New_York (-05:00)', 152), 
    // ('America/Chicago', '-21600', '-06:00', 'America/Chicago (-06:00)', 87), 
    // ('America/Phoenix', '-25200', '-07:00', 'America/Phoenix (-07:00)', 163), 
    // ('America/Denver', '-25200', '-07:00', 'America/Denver (-07:00)', 96), 
    // ('America/Los_Angeles', '-28800', '-08:00', 'America/Los_Angeles (-08:00)', 133), 
    // ('America/Anchorage', '-32400', '-09:00', 'America/Anchorage (-09:00)', 53), 
    // ('UTC', '0', '+00:00', 'UTC (+00:00)', 424);

    getTimezones() {
        return of([
            {
                name: "America/New_York",
                utc_offset_seconds: -18000,
                utc_offset_hour_minute: "-05:00",
                display_value: "America/New_York (-05:00)",
                display_order: 152,
            }, {
                name: "America/Chicago",
                utc_offset_seconds: -21600,
                utc_offset_hour_minute: "-06:00",
                display_value: "America/Chicago (-06:00)",
                display_order: 87,
            }, {
                name: "America/Phoenix",
                utc_offset_seconds: -25200,
                utc_offset_hour_minute: "-07:00",
                display_value: "America/Phoenix (-07:00)",
                display_order: 163,
            }, {
                name: "America/Denver",
                utc_offset_seconds: -25200,
                utc_offset_hour_minute: "-07:00",
                display_value: "America/Denver (-07:00)",
                display_order: 96,
            }, {
                name: "America/Los_Angeles",
                utc_offset_seconds: -28800,
                utc_offset_hour_minute: "-08:00",
                display_value: "America/Los_Angeles (-08:00)",
                display_order: 133,
            }, {
                name: "America/Anchorage",
                utc_offset_seconds: -32400,
                utc_offset_hour_minute: "-09:00",
                display_value: "America/Anchorage (-09:00)",
                display_order: 53,
            }, {
                name: "UTC",
                utc_offset_seconds: 0,
                utc_offset_hour_minute: "+00:00",
                display_value: "UTC (+00:00)",
                display_order: 424,
            }
        ]);
    }
}
