import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { InvoiceService } from '../invoice.service';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../company.service';
import { faMoneyBillWaveAlt, faFilePdf, faShareSquare, faEdit, faPrint, faSave, faChevronDown, faTrashAlt, faBan, faCheck, faBoxOpen, faPenAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { SystemService } from '../system.service';
import { UntypedFormControl } from '@angular/forms';
import Mustache from 'mustache';
import { ButtonComponent, ButtonType } from '../button/button.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InputCheckboxFormat } from '../input-checkbox/input-checkbox.component';
import { InputTextAreaFormat } from '../input-text-area/input-text-area.component';

enum SubComponentView {
    none,
    edit_invoice,
    send_invoice,
    record_payment,
    delete,
    mark_as_sent,
}

@Component({
    selector: 'app-invoice-view',
    templateUrl: './invoice-view.component.html',
    styleUrls: ['./invoice-view.component.scss']
})
export class InvoiceViewComponent implements OnInit {
    ButtonType = ButtonType
    InputCheckboxFormat = InputCheckboxFormat
    InputTextAreaFormat = InputTextAreaFormat
    initialized = false
    faMoneyBillWaveAlt = faMoneyBillWaveAlt
    faFilePdf = faFilePdf
    faShareSquare = faShareSquare
    faEdit = faEdit
    faPrint = faPrint
    faChevronDown = faChevronDown
    faSave = faSave
    faTrashAlt = faTrashAlt
    faBan = faBan
    faCheck = faCheck
    faBoxOpen = faBoxOpen
    faPenAlt = faPenAlt
    faTimes = faTimes
    invoice
    itemTypes
    invoiceTotal = 0
    itemTypesNameById = {}
    providerCompany
    clientCompany
    badgeColor = "grey"
    @ViewChild("imageCol", { read: ElementRef }) imageCol: ElementRef
    allRecipientsList = []
    allRecipientsSet = {}
    messageSubjectFC = new UntypedFormControl()
    messageBodyFC = new UntypedFormControl()
    includePDFFC = new UntypedFormControl(true)
    mustache = Mustache
    SubComponentView = SubComponentView;
    subComponentView = this.SubComponentView.none;
    paymentDateFC = new UntypedFormControl(new Date())
    paymentAmountFC = new UntypedFormControl()
    paymentNotesFC = new UntypedFormControl()

    markAsSentDateFC = new UntypedFormControl(new Date())
    logoSource
    latestActivity: any = {}

    editBillToCompanyNameFC = new UntypedFormControl()
    editBillToCompanyAddressFC = new UntypedFormControl()
    editBillToCompanyPhoneFC = new UntypedFormControl()
    showEditBillToCompanyInfo

    additionalRecipientsList = []
    addAdditionalRecipient() {
        this.additionalRecipientsList.push({
            fc: new UntypedFormControl(true),
            nameFC: new UntypedFormControl()
        })
    }

    removeAdditionalContact(index) {
        this.additionalRecipientsList.splice(index, 1)
    }

    @ViewChildren(ButtonComponent) buttonComponents: QueryList<ButtonComponent>;

    constructor(
        private invoiceService: InvoiceService,
        private _themeService: ThemeRebrandService,
        private router: Router,
        private companyService: CompanyService,
        private systemService: SystemService,
        private _snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
    ) { }

    setStatusBadgeColor(status) {
        switch (status) {
            case "D":
                this.badgeColor = "grey";
                break;
            case "L":
                this.badgeColor = "red";
                break;
            case "P":
                this.badgeColor = "green";
                break;
            case "S":
                this.badgeColor = "orange";
                break;
            case "W":
                this.badgeColor = "green";
                break;
        }
    }

    addSingleRecipientEmail(email) {
        if (!this.allRecipientsSet[email]) {
            if (email) {
                this.allRecipientsSet[email] = true
            }
            this.allRecipientsList.push({
                email: email,
                fc: new UntypedFormControl(),
            })
        }
    }

    addRecipientEmail(email) {
        var emails = email.split(",");
        for (var i = 0; i < emails.length; ++i) {
            if (emails[i].trim()) {
                this.addSingleRecipientEmail(emails[i].trim())
            }
        }
    }

    addCompanyEmail(company) {
        if (!company) {
            return;
        }
        if (company.notify_email_address) {
            this.addRecipientEmail(company.notify_email_address)
        }

        if (company.invoice_email_address) {
            this.addRecipientEmail(company.invoice_email_address)
        }
    }
    messageBodyTemplate = `------------------
Invoice Summary
------------------
Invoice Id: {{invoice.invoice_id}}
Invoice Date: {{&invoice.invoice_date_formatted}}
Client: {{{client.name}}}
P. O. Number: {{{invoice.po_number}}}
Amount: \${{invoice.balance}}
Due {{&invoice.due_date_formatted}} {{invoice.payment_term_name}}`

    initInvoiceEmail() {

        this.messageSubjectFC.setValue(this.invoice.subject);

        this.invoice.due_date_formatted = new Date(this.invoice.due_date).toLocaleDateString()
        this.invoice.invoice_date_formatted = new Date(this.invoice.invoice_date).toLocaleDateString()
        if (this.invoice.balance) {
            this.invoice.balance = this.invoice.balance.toFixed(2)
        }

        var bodyView = {
            invoice: this.invoice,
            client: this.clientCompany
        }

        this.messageBodyFC.setValue(this.mustache.render(this.messageBodyTemplate, bodyView))
    }

    initInvoicePromise() {
        return new Promise((resolve, reject) => {
            this.invoiceService.getInvoice(this.invoiceId).subscribe((response) => {
                if (response.success) {
                    this.invoice = response.result[0]
                    //console.log("invoice", this.invoice)
                    this.setNotesHTMLStyled()
                    this.setStatusBadgeColor(this.invoice.status)
                    this.editBillToCompanyNameFC.setValue(this.invoice.bill_to_company_name)
                    this.editBillToCompanyAddressFC.setValue(this.invoice.bill_to_company_address)
                    this.editBillToCompanyPhoneFC.setValue(this.invoice.bill_to_company_phone)
                } else {
                    console.log(response)
                }
                resolve(null);
            })
        })
    }

    notesObject = [{
        part: null,
        bold: false,
        italics: false,
    }]
    setNotesHTMLStyled() {
        if (!this.invoice.notes) {
            return
        }
        var partIndex = 0
        var html = ""
        var mark = 0
        for (var i = 0; i < this.invoice.notes.length; ++i) {
            if (this.invoice.notes.charAt(i) == "*" || this.invoice.notes.charAt(i) == "_") {
                this.notesObject[partIndex].part = this.invoice.notes.substring(mark, i)

                this.notesObject.push({
                    part: null,
                    bold: this.notesObject[partIndex].bold,
                    italics: this.notesObject[partIndex].italics,
                })
                ++partIndex
                if (this.invoice.notes.charAt(i) == "*") {
                    this.notesObject[partIndex].bold = !this.notesObject[partIndex].bold
                }
                if (this.invoice.notes.charAt(i) == "_") {
                    this.notesObject[partIndex].italics = !this.notesObject[partIndex].italics
                }
                mark = i + 1
            }
        }
        if (mark < this.invoice.notes.length) {
            this.notesObject[partIndex].part = this.invoice.notes.substring(mark)
        }
        this.invoice.notes_html_style = html
    }

    invoiceId
    getInvoiceIdParamPromise() {
        return new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe(params => {
                this.invoiceId = params.invoiceId
                resolve(this.invoiceId)
            })
        })
    }

    ngOnInit() {
        //this._themeService.triggerTheme();
        this.addRecipientEmail("no_reply@search-itsolutions.com")

        this.getInvoiceIdParamPromise().then(() => {
            var promises = [];
            if (this.invoiceId) {
                promises.push(this.initInvoicePromise().then(() => {
                    if (this.invoice && this.invoice.submitter_email) {
                        this.addRecipientEmail(this.invoice.submitter_email)
                    }
                    var nextPromises = [];
                    nextPromises.push(new Promise((resolve, reject) => {
                        this.companyService.getCompanyById(this.invoice.provider_id).subscribe((response) => {
                            if (response.success) {
                                this.providerCompany = response.result[0]
                                this.addCompanyEmail(this.providerCompany)
                            } else {
                                console.log(response)
                            }
                            resolve(null)
                        })
                    }))
                    nextPromises.push(new Promise((resolve, reject) => {
                        this.companyService.getCompanyById(this.invoice.bill_to_company_id).subscribe((response) => {
                            if (response.success) {
                                this.clientCompany = response.result[0]
                                this.addCompanyEmail(this.clientCompany)
                            } else {
                                console.log(response)
                            }
                            resolve(null)
                        })
                    }))
                    return Promise.all(nextPromises)
                }))
            }

            promises.push(new Promise((resolve, reject) => {
                this.invoiceService.getItemTypes().subscribe((response) => {
                    if (response.success) {
                        this.itemTypes = response.result;
                        for (var i = 0; i < this.itemTypes.length; ++i) {
                            this.itemTypesNameById[this.itemTypes[i].id] = this.itemTypes[i].name
                        }
                    } else {
                        console.log(response)
                    }
                    resolve(null)
                })
            }))

            promises.push(new Promise((resolve, reject) => {
                this.invoiceService.getLatestActivity(this.invoiceId).subscribe((response) => {
                    if (response.success) {
                        if (response.result[0]) {
                            this.latestActivity = response.result[0]
                        }
                        //console.log("latest activity", this.latestActivity)
                    } else {
                        console.log(response)
                    }
                    resolve(null)
                })
            }))

            return Promise.all(promises)
        }).then(() => {
            return this.loadImagePromise();
        }).then(() => {
            // console.log("this invoice", this.invoice)
            // console.log(this.providerCompany)
            // console.log(this.clientCompany)
            this.initInvoiceEmail();

            this.setStatusBadgeColor(this.invoice.status)
            this.paymentAmountFC.setValue(this.invoice.balance)

            this.initialized = true;
            this.finishLoadImage()
        }).catch((err) => {
            console.log(err)
        })
    }

    sendInvoiceButton
    editInvoiceButton
    recordPaymentButton

    initButtons() {
        if (!this.sendInvoiceButton) {
            this.buttonComponents.forEach(writer => {
                if (writer.label == "Send Invoice") {
                    this.sendInvoiceButton = writer;
                }
                if (writer.label == "Edit Invoice") {
                    this.editInvoiceButton = writer;
                }
                if (writer.label == "Record Payment") {
                    this.recordPaymentButton = writer;
                }
            });
        }
    }

    deactivateButtons() {
        this.initButtons()
        this.sendInvoiceButton.activated = false;
        this.editInvoiceButton.activated = false;
        this.recordPaymentButton.activated = false;
    }

    reactivateButtons() {
        this.sendInvoiceButton.activated = true;
        this.editInvoiceButton.activated = true;
        this.recordPaymentButton.activated = true;
    }

    getImageHeight(width, height, newWidth) {
        var ratio = width / height;
        return newWidth / ratio;
    }

    loadImagePromise() {
        if (this.providerCompany && this.providerCompany.company_logo) {
            return new Promise((resolve, reject) => {
                this.systemService.getProfileFile(this.providerCompany.company_logo).subscribe((response) => {
                    var reader = new FileReader();
                    reader.readAsDataURL(<any>response);
                    reader.onloadend = () => {
                        this.logoSource = reader.result
                        resolve(null)
                    }
                });
            })
        }
        return Promise.resolve()
    }

    finishLoadImage() {
        if (!this.logoSource) {
            return;
        }
        var img = document.createElement('img');
        (<any>img.src) = this.logoSource;

        img.onload = function () {
            var newWidth = 100;
            var newHeight = this.getImageHeight(img.width, img.height, newWidth);
            img.width = newWidth;
            img.height = newHeight;
            while (this.imageCol.nativeElement.firstChild) {
                this.imageCol.nativeElement.removeChild(this.imageCol.nativeElement.firstChild);
            }
            this.imageCol.nativeElement.appendChild(img);
            this.none = false;
        }.bind(this);

    }

    get themeService() {
        return this._themeService;
    }

    editInvoice() {
        this.deactivateButtons()
        //this.router.navigate(["/invoice-edit"]);
        if (this.subComponentView == this.SubComponentView.edit_invoice) {
            this.subComponentView = this.SubComponentView.none
        } else {
            this.subComponentView = this.SubComponentView.edit_invoice
            this.editInvoiceButton.activated = true
        }
    }

    cancelEdit() {
        this.deactivateButtons()
        this.subComponentView = this.SubComponentView.none
    }

    onSaveEditNavigate() {
        this.subComponentView = this.SubComponentView.none
        this.reactivateButtons()
    }

    sendInvoice() {
        this.deactivateButtons()
        if (this.subComponentView == this.SubComponentView.send_invoice) {
            this.subComponentView = this.SubComponentView.none
        } else {
            this.subComponentView = this.SubComponentView.send_invoice
            this.sendInvoiceButton.activated = true
        }
    }

    recordPayment() {
        this.deactivateButtons()
        if (this.subComponentView == this.SubComponentView.record_payment) {
            this.subComponentView = this.SubComponentView.none
        } else {
            this.subComponentView = this.SubComponentView.record_payment
            this.recordPaymentButton.activated = true
        }
    }

    doSendInvoice() {

        var to;
        for (var i = 0; i < this.allRecipientsList.length; ++i) {
            if (this.allRecipientsList[i].fc.value) {
                if (to) {
                    to += ", "
                } else {
                    to = ""
                }
                to += this.allRecipientsList[i].email
            }
        }

        for (var i = 0; i < this.additionalRecipientsList.length; ++i) {
            if (this.additionalRecipientsList[i].fc.value) {
                if (to) {
                    to += ", "
                } else {
                    to = ""
                }
                to += this.additionalRecipientsList[i].nameFC.value
            }
        }

        var text = this.messageBodyFC.value
        var mailOptions = {
            subject: this.messageSubjectFC.value,
            text: text,
            to: to,
        }
        this.invoiceService.sendInvoice(this.invoiceId, mailOptions, this.includePDFFC.value).subscribe((response) => {
            if (response.success) {
                this.invoice.status = 'S'
                this.invoice.status_name = "Sent"
                this.setStatusBadgeColor(this.invoice.status)
            } else {
                console.log(response)
            }
        })

        this.deactivateButtons()
        this.subComponentView = this.SubComponentView.none
    }

    doSendCancel() {
        this.deactivateButtons()
        this.subComponentView = this.SubComponentView.none
    }

    downloadInvoicePDF() {
        this.invoiceService.getInvoicePDF(this.invoiceId).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            link.download = "invoice.pdf";
            link.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 0)
        });
    }

    viewInvoicePDF() {
        this.invoiceService.getInvoicePDF(this.invoiceId).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        });
    }

    printInvoicePDF() {
        this.invoiceService.getInvoicePDF(this.invoiceId).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);

            var iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = 'none';
            iframe.onload = function () {
                setTimeout(function () {
                    iframe.focus();
                    iframe.contentWindow.print();
                    URL.revokeObjectURL(fileURL)
                }, 1);
            };
            iframe.src = fileURL;
        })
    }

    savePaymentError
    savePayment() {
        var paymentDate = this.paymentDateFC.value;
        if (paymentDate) {
            paymentDate /= 1000;
        }
        this.invoiceService.recordPayment(
            this.invoiceId,
            paymentDate,
            this.paymentAmountFC.value,
            this.paymentNotesFC.value,
        ).subscribe((response) => {
            if (response.success) {
                //todo - snackbar message
                //todo - update balance
                //todo - update latest activity message
                this.deactivateButtons()
                this.subComponentView = this.SubComponentView.none
                this.initInvoicePromise()
            } else {
                this.savePaymentError = response.message
                console.log(response)

                //console.log("err", this.savePaymentError)
            }
        })

    }

    cancelPayment() {
        this.deactivateButtons()
        this.subComponentView = this.SubComponentView.none
    }

    showActions = false;
    toggleActionsDropdown() {
        this.showActions = !this.showActions;
    }

    markAsSent() {
        this.showActions = false
        if (this.subComponentView == this.SubComponentView.mark_as_sent) {
            this.subComponentView = this.SubComponentView.none
        } else {
            this.subComponentView = this.SubComponentView.mark_as_sent
        }

    }

    delete() {
        this.showActions = false
        if (this.subComponentView == this.SubComponentView.delete) {
            this.subComponentView = this.SubComponentView.none
        } else {
            this.subComponentView = this.SubComponentView.delete
        }
    }

    cancelMarkAsSent() {
        this.deactivateButtons()
        this.subComponentView = this.SubComponentView.none
    }

    doMarkAsSent() {
        this.invoiceService.markAsSent(this.invoiceId, this.markAsSentDateFC.value / 1000).subscribe((response) => {
            if (response.success) {
                var snack = "Invoice marked as sent."
                this._snackBar.open(snack, null, { duration: 2000, });
                this.subComponentView = this.SubComponentView.none
                this.initInvoicePromise()
            } else {
                console.log(response)
            }
        })
    }

    cancelDelete() {
        this.subComponentView = this.SubComponentView.none
    }

    doDelete() {
        this.invoiceService.deleteInvoice(this.invoiceId).subscribe((response) => {
            if (response.success) {
                var snack = "Invoice deleted."
                this._snackBar.open(snack, null, { duration: 2000, });
                this.router.navigate(["invoice-list"])
            } else {
                console.log(response)
            }
        })
    }

    showMarkAsSent() {
        return this.invoice.status == 'D'
    }

    showMarkAsUnsent() {
        return this.invoice.status == 'S' || this.invoice.status == 'L'
    }

    markAsUnsent() {
        this.showActions = false
        this.invoiceService.markAsUnsent(this.invoiceId).subscribe((response) => {
            if (response.success) {
                var snack = "Invoice marked as unsent."
                this._snackBar.open(snack, null, { duration: 2000, });
                this.initInvoicePromise()
            } else {
                console.log(response)
            }
        })
    }

    showWriteOff() {
        return this.invoice.status == 'S' || this.invoice.status == 'L'
    }

    writeOff() {
        this.showActions = false
        this.invoiceService.writeOff(this.invoiceId).subscribe((response) => {
            if (response.success) {
                var snack = "Invoice written off."
                this._snackBar.open(snack, null, { duration: 2000, });
                this.initInvoicePromise()
            } else {
                console.log(response)
            }
        })
    }

    getQuantityValue(item) {
        if (item.quantity) {
            return item.quantity.toFixed(0)
        }
        return ""
    }

    editBillToCompanyInfo() {
        this.showEditBillToCompanyInfo = !this.showEditBillToCompanyInfo
    }

    cancelBillToCompanyInfo() {
        this.showEditBillToCompanyInfo = false
    }

    saveBillToCompanyInfo() {
        this.invoiceService.updateBillToCompanyInfo(
            this.invoiceId,
            this.editBillToCompanyNameFC.value,
            this.editBillToCompanyAddressFC.value,
            this.editBillToCompanyPhoneFC.value
        ).subscribe((response) => {
            if (response.success) {
                this.cancelBillToCompanyInfo()
                this.initInvoicePromise()
            }
        })
    }
}
