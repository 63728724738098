import { Component, OnInit, Inject, Input } from '@angular/core';
import { SettingsService } from '../settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    faPlus, faCaretDown, faCaretUp, faPaperclip, faEllipsisH, faFileAlt,
    faPlay, faStop, faPause, faPrint, faTimes, faExclamationTriangle, faCheck,
    faFileExport, faHome, faUpload, faBan
} from '@fortawesome/free-solid-svg-icons';
import { OrderService } from '../order.service';
import { UserService } from '../user.service';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { DocumentsService } from '../documents.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { SnackRouteService } from '../snack-route.service';
import { CompanyService } from '../company.service';

@Component({
    selector: 'app-agent-dashboard',
    templateUrl: './agent-dashboard.component.html',
})
export class AgentDashboardComponent implements OnInit {
    i = 0
    faCaretDown = faCaretDown;
    faCaretUp = faCaretUp;
    faPlus = faPlus;
    faPaperclip = faPaperclip
    faHome = faHome;
    faUpload = faUpload;
    faEllipsisH = faEllipsisH
    initialized = false;
    faPlay = faPlay
    faFileAlt = faFileAlt
    faStop = faStop
    faPause = faPause
    faPrint = faPrint
    faFileExport = faFileExport
    faBan = faBan
    _listView;
    maxUsers;
    @Input() name;
    @Input() title;
    @Input() filter;

    pageSelect = new FormControl(true);
    
    constructor(
        protected _themeService: ThemeRebrandService,
        protected _settingsService: SettingsService,
        private router: Router,
        private orderService: OrderService,
        private userService: UserService,
        private documentsService: DocumentsService,//vscode thinks this is unused. it isn't.
        private _snackBar: MatSnackBar,
        public dialog: MatDialog,
        private snackRouteService: SnackRouteService,
        private route: ActivatedRoute,
        private companyService: CompanyService
    ) {
    }

    fetchCachedDashboardPageSort() {
        for (var i = 0; i < this.lists.length; ++i) {
            var cachedPage = localStorage.getItem(this.name + "_client_dashboard_page_" + this.lists[i].dashboard);
            if (cachedPage) {
                this.lists[i].currentPage = parseInt(cachedPage);
            }
            var sortColumn = localStorage.getItem(this.name + "_client_dashboard_sort_col_" + this.lists[i].dashboard);
            if (sortColumn) {
                this.lists[i].sort.field = sortColumn;
            }
            var sortDirection = localStorage.getItem(this.name + "_client_dashboard_sort_dir_" + this.lists[i].dashboard);
            if (sortDirection) {
                this.lists[i].sort.direction = parseInt(sortDirection);
            }
        }
    }

    cacheDashboardPage(dashboard, page) {
        localStorage.setItem(this.name + "_client_dashboard_page_" + dashboard, page)
    }
    cacheDashboardPageByIndex(listViewIndex) {
        var page = this.lists[listViewIndex].currentPage;
        this.cacheDashboardPage(this.lists[listViewIndex].dashboard, page);
    }

    cacheDashboardSort(dashboard, column, direction) {
        localStorage.setItem(this.name + "_client_dashboard_sort_col_" + dashboard, column);
        localStorage.setItem(this.name + "_client_dashboard_sort_dir_" + dashboard, direction);
    }
    cacheDashboardSortByIndex(listViewIndex) {
        var column = this.lists[listViewIndex].sort.field;
        var direction = this.lists[listViewIndex].sort.direction;
        this.cacheDashboardSort(this.lists[listViewIndex].dashboard, column, direction)
    }

    resetDashboardCache(dashboard) {
        localStorage.removeItem(this.name + "_client_dashboard_page_" + dashboard)
        localStorage.removeItem(this.name + "_client_dashboard_sort_col_" + dashboard)
        localStorage.removeItem(this.name + "_client_dashboard_sort_dir_" + dashboard)

    }
    resetDashboardCacheByIndex(listViewIndex) {
        this.resetDashboardCache(this.lists[listViewIndex].dashboard);
        this.lists[listViewIndex].sort.field = "order_id";
        this.lists[listViewIndex].sort.direction = 1;
        this.lists[listViewIndex].currentPage = 1;
    }

    listItems = [];

    lists = [
        {
            title: "Draft Orders",
            dashboard: "DRAFT",
            currentPage: 1,
            limit: null,
            count: null,
            lastPage: null,
            sort: {
                "field": "order_id",
                "direction": 1
            },
            hideIfEmpty: false,
            hidden: false,
        },
        {
            title: "Orders In Progress",
            dashboard: "IN_PROGRESS",
            currentPage: 1,
            limit: null,
            count: null,
            lastPage: null,
            sort: {
                "field": "order_id",
                "direction": 1
            },
        },
        {
            title: "Orders On Hold",
            dashboard: "ON_HOLD",
            currentPage: 1,
            limit: null,
            count: null,
            lastPage: null,
            sort: {
                "field": "order_id",
                "direction": 1
            },
            hideIfEmpty: true,
        },
        {
            title: "Recently Completed Orders",
            dashboard: "COMPLETE",
            currentPage: 1,
            limit: null,
            count: null,
            lastPage: null,
            sort: {
                "field": "order_id",
                "direction": 1
            },
        },
    ];
    searchTypeData;
    orderStatusById = {};

    get showClientDropdown() {
        return this.router.url == '/dashboard-order-to-be-invoiced'
    }

    setClientForInvoiceOnFilter(filter) {
        if (!this.clientsForInvoiceFC.value || this.clientsForInvoiceFC.value == "null") {
            //remove the comparison
            for (var i = 0; i < filter.comparisons.length; ++i) {
                if (filter.comparisons[i].field == "agent_id") {
                    filter.comparisons.splice(i, 1)
                    break
                }
            }
        } else {
            var clientUuidComparisonMatched = false
            for (var i = 0; i < filter.comparisons.length; ++i) {
                if (filter.comparisons[i].field == "agent_id") {
                    clientUuidComparisonMatched = true
                    filter.comparisons[i].matchValue = this.clientsForInvoiceFC.value
                }
            }
            if (!clientUuidComparisonMatched) {
                filter.comparisons.push(
                    {
                        field: "agent_id",
                        operator: "is",
                        matchValue: this.clientsForInvoiceFC.value
                    },
                )
            }
        }
    }

 

    clientsForInvoice
    clientsForInvoiceFC = new UntypedFormControl()
    getInvoiceClientsPromise() {
        return new Promise((resolve, reject) => {
            this.orderService.getClientsForInvoice().subscribe((response) => {
                //console.log(response)
                this.clientsForInvoice = response.result
                resolve(null)
            })
        })
    }

    clientForInvoiceChange() {
        //this.initialized = false
        var promises = []
        for (var i = 0; i < this.lists.length; ++i) {
            promises.push(this.dashboardSearchPromise(i));
        }
        Promise.all(promises).then(() => {
            //this.initialized = true
        })
    }

    order_status: string
    agent_id: string
    agent_company_name: string
    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.order_status = params['order_status']
        })

        let promises = []

        this.getInvoiceClientsPromise().then(() => {
            /* multi view dashboard logic removed - brock 12/15/22
            for (var i = 0; i < this.lists.length; ++i) {
                this.listViewEntries.push({
                    "title": this.title,
                    "display_column": this.displayColumn,
                    "sort": this.sort,
                })
            }
            */
           // singular view logic
           this.listViewEntries.push({
                "title": this.title,
                "display_column": this.displayColumn,
                "sort": this.sort,
           })

            this.fetchCachedDashboardPageSort()

            promises.push(new Promise((resolve, reject) => {
                this.orderService.getPrimarySearchTypeData((searchTypeData: any) => {
                    console.log(searchTypeData)
                    this.searchTypeData = {}
                    for (var i = 0; i < searchTypeData.length; ++i) {
                        this.searchTypeData[searchTypeData[i].id] = searchTypeData[i]
                        searchTypeData[i].servicesById = {}
                        for (var j = 0; j < searchTypeData[i].services.length; ++j) {
                            searchTypeData[i].servicesById[searchTypeData[i].services[j].id] = searchTypeData[i].services[j]
                        }
                    }
                    resolve(null)
                });
            }));
            promises.push(new Promise((resolve, reject) => {
                this.orderService.getOrderStatuses().subscribe((response) => {
                    if (response.success) {
                        for (var i = 0; i < response.result.length; ++i) {
                            this.orderStatusById[response.result[i].id] = response.result[i]
                        }
                    } else {
                        console.log("error while getting order statuses")
                    }
                    resolve(null)
                })
            }))

            /*
            for (var i = 0; i < this.lists.length; ++i) {
                promises.push(this.dashboardSearchPromise(i))
            }
            */
            promises.push(this.dashboardSearchPromise(1))
            return Promise.all(promises)
        }).then(() => {
            this.initialized = true
            for (var i = 0; i < this.lists.length; ++i) {
                setTimeout(function (i) {
                    var slider = document.getElementById('cntnr-' + i)
                    if (!slider) {
                        return
                    }
                    let isDown = false
                    let startX: any
                    let scrollLeft: any

                    slider.addEventListener('mousedown', (e) => {
                        isDown = true
                        startX = (<any>e).pageX - (<any>slider).offsetLeft
                        scrollLeft = slider.scrollLeft
                    })
                    slider.addEventListener('mouseleave', () => {
                        isDown = false
                    })
                    slider.addEventListener('mouseup', () => {
                        isDown = false
                    })
                    slider.addEventListener('mousemove', (e) => {
                        if (!isDown) return
                        const x = (<any>e).pageX - (<any>slider).offsetLeft
                        const walk = (x - startX) * 1
                        slider.scrollLeft = scrollLeft - walk
                    })
                }.bind(this, i), 0)
            }
        }).catch((err) => {
            this.initialized = true
            console.log("error while searching orders", err)
        })
    }
   
    dashboardSearchPromise(i) {
        return new Promise((resolve, reject) => {
            this.companyService.getCompany().subscribe((response) => {
                this.agent_id = response.result[0].id
                this.agent_company_name = response.result[0].name
                
                this.route.queryParams.subscribe((params) => {
                    this.order_status = params['order_status']

                    this.orderService.getAgentDashboard(this.agent_id, this.order_status).subscribe((response) => {
                        if(response.result.length >= 1) {
                            this.initialized = true
                        }
                        console.log(response.result)

                        for(let i = 0; i <= response.result.length; i++) {
                            this.listItems[i] = response.result
                            
                            /*
                            for(let j = 0; j <= this.listItems[i].length; j++) {
                                if(this.listItems[i][j].property_buyer) {
                                    console.log(this.listItems[i][j].property_buyer.split('"').join(''))
                                } 
                            }
                            */
                        
                        }

                    })
                })
            })



            // search2 function seemingly second implementation
            /*
            this.orderService.search2(
            {
                allAny: "all",
                page: this.lists[i].currentPage,
                sort: this.lists[i].sort,
                filter: this.filter,
            }, "dashLimit"
            ).subscribe(function (i: number, response: any) {
                console.log(response)
                if (!response.success) {
                    console.log("error occurred while searching dashboard", response)
                    return reject(response);
                }
                console.log('dashboard search response', response.result)
                this.listItems[i] = response.result;
                for (var j = 0; j < response.result.length; ++j) {
                    if (response.result[j].due_date) {
                        response.result[j].dueDateFC = new FormControl(formatDate(response.result[j].due_date, 'yyyy-MM-dd', 'en'))
                    } else {
                        response.result[j].dueDateFC = new FormControl()
                    }
                }
                this.lists[i].count = response.count;
                this.lists[i].limit = response.limit;
                this.lists[i].lastPage = Math.ceil(response.count / response.limit);
                if (this.lists[i].hideIfEmpty) {
                    if (this.lists[i].count == 0) {
                        this.lists[i].hidden = true;
                    }
                }

                for (var j = 0; j < this.listItems[i].length; ++j) {
                    var item = this.listItems[i][j];
                    item.dashboard_action_buttons = this.getItemActionButtons(item, i, j)
                }
                resolve(null);
            }.bind(this, i))
            */

            // alternate dashboardSearch initial implementation
            /*
            this.orderService.dashboardSearch(
                this.lists[i].dashboard,
                this.lists[i].currentPage,
                this.lists[i].sort).subscribe(function (i, response) {
                    console.log("dashboard search response", response.result);
                    if (response.success) {
                        this.listItems[i] = response.result;
                        this.lists[i].count = response.count;
                        this.lists[i].limit = response.limit;
                        this.lists[i].lastPage = Math.ceil(response.count / response.limit);
                        if (this.lists[i].hideIfEmpty) {
                            if (this.lists[i].count == 0) {
                                this.lists[i].hidden = true;
                            }
                        }
                        resolve(null);
                    } else {
                        reject(response);
                    }
                }.bind(this, i))
                */

        }).catch(function (err) {
            console.log("error while searching entries", err);
        })
    }

    getSearchTypeDisplay(order) {
        var services = this.searchTypeData;
        for (var property in services) {
            if (services.hasOwnProperty(property)) {
                if (services[property].id == order.service_group_id && !order.primary_service_id) {
                    return services[property].name;
                }
                if (services[property].services) {
                    for (var j = 0; j < services[property].services.length; ++j) {
                        if (services[property].services[j].id == order.primary_service_id) {
                            //return services[property].name + ": " + services[property].services[j].name;
                            return services[property].services[j].name;
                        }
                    }
                }
            }
        }
    }

    showingMessage(listViewIndex) {
        if (!this.listItems[listViewIndex]) {
            return "Showing 0 to 0 of 0 - error occurred while fetching entries";
        }
        var firstEntry = (((this.lists[listViewIndex].currentPage - 1) * this.lists[listViewIndex].limit) + 1);
        if (this.lists[listViewIndex].count == 0) {
            firstEntry = 0;
        }
        return "Showing " + firstEntry + " to " + (((this.lists[listViewIndex].currentPage - 1) * this.lists[listViewIndex].limit)
            + this.listItems[listViewIndex].length)
            + " of " + this.lists[listViewIndex].count;
    }

    getPageLinks(listViewIndex) {
        var pageLinks = [];
        for (var i = this.lists[listViewIndex].currentPage - 5; i <= this.lists[listViewIndex].lastPage && pageLinks.length < 10; ++i) {
            if (i > 0) {
                pageLinks.push(i);
            }
        }
        for (var i = this.lists[listViewIndex].currentPage - 5; i > 0 && pageLinks.length < 10; --i) {
            pageLinks.unshift(i);
        }
        return pageLinks;
    }

    doSearch(listViewIndex) {
        this.dashboardSearchPromise(listViewIndex);
    }

    setPage(listViewIndex, page) {
        this.listItems[listViewIndex] = [];
        this.lists[listViewIndex].currentPage = page;
        this.cacheDashboardPageByIndex(listViewIndex);
        this.doSearch(listViewIndex);
    }

    doFirst(listViewIndex) {
        this.setPage(listViewIndex, 1);
    }

    doPrevious(listViewIndex) {
        if (this.lists[listViewIndex].currentPage <= 1) {
            return;
        }
        this.setPage(listViewIndex, this.lists[listViewIndex].currentPage - 1);
    }

    doNext(listViewIndex) {
        if (this.lists[listViewIndex].currentPage >= this.lists[listViewIndex].lastPage) {
            return;
        }
        this.setPage(listViewIndex, this.lists[listViewIndex].currentPage + 1);
    }

    doLast(listViewIndex) {
        this.setPage(listViewIndex, this.lists[listViewIndex].lastPage);
    }

    get themeService() {
        return this._themeService;
    }

    get settingsService() {
        return this._settingsService;
    }

    getListItems(i) {
        return this.listItems[i];
    }

    hasNote(listViewIndex, j) {
        return false;
    }

    entryValueColor(display_column) {
        if (display_column.link != null) {
            return this._themeService.linkColor;
        }
        return "";
    }

    doReset(listViewIndex) {
        this.resetDashboardCacheByIndex(listViewIndex);
        this.doSearch(listViewIndex);
    }

    doSort(listView, display_column_name, listViewIndex) {
        if (this.lists[listViewIndex].sort.field == display_column_name) {
            if (this.lists[listViewIndex].sort.direction) {
                this.lists[listViewIndex].sort.direction = 0;
            } else {
                this.lists[listViewIndex].sort.direction = 1;
            }
        } else {
            this.lists[listViewIndex].sort.field = display_column_name;
            this.lists[listViewIndex].sort.direction = 0;
        }
        this.cacheDashboardSortByIndex(listViewIndex);
        this.doSearch(listViewIndex);
    }

    getEntryFieldValue(entry, display_column, j) {
        console.log(display_column.name)
        if (display_column.name == "primary_service_id") {
            return this.getSearchTypeDisplay(entry);
        }
        if (display_column.name == "order_status") {
            return this.orderStatusById[entry[display_column.name]].name;
        }
        if (display_column.name == "client_name") {
            //console.log("entry, client name display", entry)
            if (entry.client_company_name) {
                return entry.client_company_name
            }
        }
        return entry[display_column.name];
    }

    isArray(val) {
        return Array.isArray(val);
    }

    displayColumn = [
        {
            title: "Order #",
            name: "order_id",
        }, {
            title: "Status",
            name: "order_status",
        },
        {
            title: "Ref #",
            name: "reference_number",
        },
        {
            title: "Search Type",
            name: "service_name",
        },
        {
            title: "County",
            name: "county_name",
        },
        {
            title: "Address",
            name: "address",
        },
        {
            title: "SBL",
            name: "sbl",
        },
        {
            title: "Seller",
            name: "property_seller",
        },
        {
            title: "Buyer",
            name: "property_buyer",
        },
        {
            title: "Ordered",
            name: "submission_date",
        }
    ];

    sort: [
        {
            "list_view_sort": {
                "field": "id",
                "direction": 1
            }
        }
    ];

    listViewEntries = [];
    selectedOrderListViewIndex = null;
    selectedOrderJ = null;

    clickDocumentEventListener;
    doShowActionsMenu(listViewIndex, j) {
        document.getElementById('actions_dropdown_menu_' + listViewIndex + '_' + j).style.display = 'block'
        this.clickDocumentEventListener = this.hideActionsMenu.bind(this, listViewIndex, j)
        setTimeout(() => {
            document.addEventListener("click", this.clickDocumentEventListener);
        }, 0)
    }

    hideActionsMenu(listViewIndex, j) {
        document.removeEventListener("click", this.clickDocumentEventListener);
        var elem = document.getElementById('actions_dropdown_menu_' + listViewIndex + '_' + j)
        if (!elem) {
            return;
        }
        elem.style.display = 'none'
        this.selectedOrderListViewIndex = null
        this.selectedOrderJ = null
    }

    clickActionsMenu(listViewIndex, j) {
        var curDisplay = document.getElementById('actions_dropdown_menu_' + listViewIndex + '_' + j).style.display;
        if (curDisplay == "none") {
            if (this.selectedOrderListViewIndex != null && this.selectedOrderJ != null) {
                this.hideActionsMenu(this.selectedOrderListViewIndex, this.selectedOrderJ)
            }
            this.doShowActionsMenu(listViewIndex, j)
            this.selectedOrderListViewIndex = listViewIndex
            this.selectedOrderJ = j
        }
    }

    setOrderStatus(order_uuid, status, listViewIndex, j, comments) {
        this.orderService.setOrderStatus(order_uuid, status, comments).subscribe((response) => {
            if (response.success) {
                this.doSearch(listViewIndex);
                var item = this.listItems[listViewIndex][j];
                this._snackBar.open('Order ' + item.order_id + " status set to " + this.orderStatusById[status].name, null, {
                    duration: 4000,
                });
            }
        })
    }

    revertOrderStatus(order_uuid, listViewIndex, j, comments) {
        this.orderService.revertOrderStatus(order_uuid, comments).subscribe((response) => {
            if (response.success) {
                this.doSearch(listViewIndex);
                var item = this.listItems[listViewIndex][j];
                this._snackBar.open('Order ' + item.order_id + " status set to " + this.orderStatusById[response.status].name, null, {
                    duration: 4000,
                });
            }
        })
    }

    printPDF(listViewIndex, j) {
        this.orderService.getOrderSheetPDF(this.listItems[listViewIndex][j].uuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);

            var iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = 'none';
            iframe.onload = function () {
                setTimeout(function () {
                    iframe.focus();
                    iframe.contentWindow.print();
                    URL.revokeObjectURL(fileURL)
                }, 1);
            };
            iframe.src = fileURL;
        })
    }

    showCreateInvoice(listViewIndex, j) {
        return !this.listItems[listViewIndex][j].invoice_id
    }

    showViewInvoice(listViewIndex, j) {
        return this.listItems[listViewIndex][j].invoice_id
    }

    viewInvoice(listViewIndex, j) {
        if (this.listItems[listViewIndex][j].order_status != 'C') {
            return this.snackRouteService.snack("Order must be completed before viewing an invoice.")
        }
        this.router.navigate(['invoice-view'], { queryParams: { invoiceId: this.listItems[listViewIndex][j].invoice_id } })
    }

    createInvoice(listViewIndex, j) {
        if (this.listItems[listViewIndex][j].order_status != 'C') {
            return this.snackRouteService.snack("Order must be completed before creating an invoice.")
        }
        this.router.navigate(['invoice-edit'], { queryParams: { createOrderUuid: this.listItems[listViewIndex][j].uuid } })
    }

    dueDateChange(order, listViewIndex) {
        //console.log("due date changed", order.uuid, order.dueDateFC.value)
        var dueDateVal
        if (order.dueDateFC.value) {
            dueDateVal = order.dueDateFC.value / 1000
        }
        this.orderService.updateOrderDueDate(order.uuid, dueDateVal).subscribe((response) => {
            if (response.success) {
                this.doSearch(listViewIndex);
                this._snackBar.open('Order ' + order.order_id + " due date updated.", null, {
                    duration: 4000,
                });
            } else {
                console.log("error while updating due date", response)
            }
        })
    }

    getAgentDashboard(order_uuid: string) {
        console.log(order_uuid)
    }
}
