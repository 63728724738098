<div *ngIf="initialized" class="container-fluid mt-2"
    style="background-color: white; border-style: solid; border-width: 2px; border-color: white;">

    <div class="row">
        <div class="col">
            <span class="pointer" (click)="orderService.viewOrderSheetPDF(orderUuid)">
                View PDF
            </span>
            <span class="pointer ml-2" (click)="orderService.downloadOrderSheetPDF(orderUuid)">
                Download PDF
            </span>
        </div>
    </div>

    <div class="row">
        <div class="col-8">
            <div #imageCol style="height: 64px; display: inline-block;"></div>
            <div style="display: inline-block;">
                <span *ngIf="provider">{{provider.address}}</span><br />
                <span *ngIf="provider && provider.phone">Phone: {{provider.phone}}<br /></span>
                <span *ngIf="provider && provider.fax">Fax {{provider.fax}}</span>
            </div>
        </div>
        <div class="col-2 px-0">
            <br />
            <div class="data-cell">
                Due Date:
            </div>
        </div>
        <div class="col-2 px-0">
            <br />
            <div *ngIf="order.due_date" class="data-cell">
                {{order.due_date | date: 'shortDate'}}
            </div>
        </div>
    </div>
    <div class="row">
        <span *ngIf="provider && provider.notify_email_address">{{provider.notify_email_address}}<br /></span>&nbsp;
    </div>
    <div class="row">
        <div class="col-4 col-md-2 px-0 data-cell">
            Order ID:
        </div>
        <div class="col-4 col-md-2 px-0 data-cell">
            {{order.order_id}}
        </div>
        <div class="col-md-2 px-0">
        </div>
        <div class="col-4 col-md-2 px-0 data-cell">
            Client Name:
        </div>
        <div class="col-4 px-0 data-cell">
            {{agent.name}}
        </div>
    </div>
    <div class="row">
        <div class="col-4 col-md-2 px-0 data-cell">
            Search Type:
        </div>
        <div class="col-4 px-0 data-cell">
            {{searchTypeDisplay}}
        </div>
        <div class="w-100 d-block d-md-none"></div>
        <div class="col-4 col-md-2 px-0 data-cell">
            Reference #:
        </div>
        <div class="col-4 col-md-2 px-0 data-cell">
            {{order.reference_number}}
        </div>
    </div>
    <div class="row">
        <div class="col-4 col-md-2 px-0 data-cell">
            Current Owner:
        </div>
        <div class="col-8 col-md-10 px-0 data-cell">
            {{arrayDisplay(order.property_seller)}}
        </div>
    </div>
    <div class="row">
        <div class="col-4 col-md-2 px-0 data-cell">
            Assessed Owner:
        </div>
        <div class="col-8 col-md-10 px-0 data-cell">
            {{arrayDisplay(order.assessed_owner)}}
        </div>
    </div>
    <div class="row">
        <div class="col-2 px-0 data-cell">
            Address:
        </div>
        <div class="col-6 px-0 data-cell">
            {{order.address}}
        </div>
        <div class="col-2 px-0 data-cell">
            County:
        </div>
        <div class="col-2 px-0 data-cell">
            {{order.name}}
        </div>
    </div>
    <div class="row">
        <div class="col-2 px-0 data-cell">
            SBL:
        </div>
        <div class="col-6 px-0 data-cell">
            {{order.sbl}}
        </div>
    </div>
    <div class="row">
        <div class="col-4 col-md-2 px-0 data-cell">
            Buyers:
        </div>
        <div class="col-8 col-md-10 px-0 data-cell">
            {{arrayDisplay(order.property_buyer)}}
        </div>
    </div>

    <div *ngIf="order.continuation_date" class="row">
        <div class="col-4 col-md-2 px-0 data-cell">
            Continuation Date:
        </div>
        <div class="col-8 col-md-10 px-0 data-cell">
            {{order.continuation_date | date: 'shortDate'}}
        </div>
    </div>


    <div class="row">
        <div class="col-md-6 px-0">
            <div class="data-cell" style="padding-top: .5em; padding-bottom: .5em;">
                <div *ngFor="let instruction of getInstructionsSplit();">
                    {{instruction}}
                </div>
            </div>

            <div style="display: inline-block;">Client Notes</div>
            <div class="data-cell" style="height: 10em;"></div>
            <div style="display: inline-block;">Provider Notes</div>
            <div class="data-cell" style="height: 10em;"></div>

        </div>
        <div class="col-md-6 px-0">
            <div class="data-cell-unpad px-3 py-3">
                <span *ngFor="let row of serviceRows; let index = index;">
                    <div *ngIf="row.type == 'title'">
                        {{row.text}}&nbsp;
                    </div>
                    <div *ngIf="row.type == 'value'" class="data-cell">
                        {{row.text}}
                    </div>
                    <div *ngIf="row.type == 'subvalue'" class="data-cell">
                        - {{row.text}}
                    </div>
                </span>
            </div>
        </div>
    </div>

</div>