<div *ngIf="format == InputEnumFormat.verticalLabel" class="row mt-3">
    <div class="col bold">
        {{label}}
    </div>
</div>
<div *ngIf="format == InputEnumFormat.verticalLabel" class="row mt-1">
    <div *ngIf="disabled" class="col unedit-padding input-style ml-2">
        {{getDisplayValue()}}
    </div>
    <div *ngIf="!disabled && enumValues" class="col">
        <select class="input-style" style="vertical-align: top;" [formControl]="fc" (change)="doChange()"
            [ngStyle]="{width: naturalWidth ? 'unset': null}">
            <option *ngFor="let enumValue of enumValues" value={{enumValue}}>
                {{enumValue}}
            </option>
        </select>
    </div>
    <div *ngIf="!disabled && enumValuesNameAndId" class="col">
        <select class="input-style ml-2" style="vertical-align: top;" [formControl]="fc" (change)="doChange()"
            [ngStyle]="{width: naturalWidth ? 'unset': null}">
            <option *ngIf="includeUnset" value="null">
            </option>
            <option *ngFor="let enumValue of enumValuesNameAndId" value={{enumValue.id}}>
                {{enumValue.name}}
            </option>
        </select>
    </div>
</div>

<span *ngIf="format == InputEnumFormat.none" class="mt-1">
    <span *ngIf="disabled" class="px-0 pb-2 unedit-padding">
        <span>{{getDisplayValue()}}</span>
    </span>
    <span *ngIf="!disabled && enumValues">
        <select class="input-style-in-list" style="vertical-align: top;" [formControl]="fc" (change)="doChange()"
            [ngStyle]="{width: naturalWidth ? 'unset': null}">
            <option *ngFor="let enumValue of enumValues" value={{enumValue}}>
                {{enumValue}}
            </option>
        </select>
    </span>
    <span *ngIf="!disabled && enumValuesNameAndId">
        <select class="input-style-in-list" style="vertical-align: top;" [formControl]="fc" (change)="doChange()"
            [ngStyle]="{width: naturalWidth ? 'unset': null}">
            <option *ngIf="includeUnset" value="null">
                {{includeUnsetDisplay}}
            </option>
            <option *ngFor="let enumValue of enumValuesNameAndId" value={{enumValue.id}}>
                {{enumValue.name}}
            </option>
        </select>
    </span>
</span>
