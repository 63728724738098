<div class="container-fluid">
    <app-dashboard-controls [title]="'All Invoices'" [pageNumber]="currentPageAll" [pageSize]="allListLimit"
        [entryCount]="allListCount" [setPage]="this.setPageAll.bind(this)">
    </app-dashboard-controls>
</div>

<div *ngIf="initialized" class="container-fluid dashboard-header">

    <div class="row table-header">
        <div *ngFor="let columnTitle of columnTitles" class="col py-4"
            [align]="columnTitle.align ? columnTitle.align : 'left'">
            <span class="pointer" (click)="allSortClick(columnTitle)">{{columnTitle.display}}<span
                    *ngIf="allSortField == columnTitle.fieldName">
                    <span *ngIf="allSortDirection == 'desc'">
                        <fa-icon [icon]="faCaretDown" size="1x" title="sorted ascending"></fa-icon>
                    </span>
                    <span *ngIf="allSortDirection == 'asc'">
                        <fa-icon [icon]="faCaretUp" size="1x" title="sorted descending"></fa-icon>
                    </span>
                </span>
            </span>
        </div>
    </div>
    <div class="container-striped-alt">
        <div *ngFor="let invoice of allInvoices" class="row py-4 table-row">
            <div class="col">
                <a routerLink="/invoice-view" [queryParams]="{ invoiceId: invoice.id }">{{invoice.invoice_id}}</a>
            </div>
            <div class="col">
                {{invoice.invoice_date * 1000 | date: 'M/d/yy'}}
            </div>
            <div class="col">
                {{invoice.for_company_name}}
            </div>
            <div class="col">
                {{invoice.invoice_created * 1000 | date: 'medium'}}
            </div>
            <div class="col">
                <span class="px-2 status-badge" [ngClass]="['invoice-status-' + invoice.status ]">
                    {{invoice.status_name | uppercase}}
                </span>
            </div>
            <div class="col">
                {{invoice.balance | currency : 'USD'}}
            </div>
        </div>
    </div>

    <app-dashboard-controls [title]="''" [pageNumber]="currentPageAll" [pageSize]="allListLimit"
        [entryCount]="allListCount" [setPage]="this.setPageAll.bind(this)" [isFooter]="true">
    </app-dashboard-controls>

</div>