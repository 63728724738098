import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard-search-todo',
    templateUrl: './dashboard-search-todo.component.html',
})
export class DashboardSearchTodoComponent implements OnInit {

    filter = {
        gate: "all",
        comparisons: [
            {
                field: "team_id",
                operator: "is",
                matchValue: null
            },
            {
                field: "workflow_status",
                operator: "is",
                matchValue: "T"
            },
        ],
    }

    constructor() { }

    ngOnInit() {
    }

}
