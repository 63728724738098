import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';
import { UntypedFormControl } from '@angular/forms';
import { UserService } from '../user.service';
import { OrderService } from '../order.service';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-order-notifications',
    templateUrl: './order-notifications.component.html',
})
export class OrderNotificationsComponent implements OnInit {
    initialized = false;
    services;
    cacheOrder;
    people;
    searcherNotificationsNotesFC;
    changeWatcherFC = { fcs: [] };
    userEnumValues;

    constructor(
        private _themeService: ThemeService,
        private userService: UserService,
        private _orderService: OrderService,
        private authService: AuthService,
    ) { }

    ngOnInit() {
        this.loadOrderCache();
        var watcherCompany = this.authService.companyId
        //if (this.userService.isCurrentPortal('Provider Portal')) { 
            watcherCompany = this.cacheOrder.agent_id
        //}
        this.userService.getUsersByCompany(watcherCompany).subscribe((response) => {
            //console.log(response);
            if (response.success) {
                //this._data = response.result;
                this.userEnumValues = [];
                for (var i = 0; i < response.result.length; ++i) {
                    this.userEnumValues.push(response.result[i].email_address);
                }
                this.initialized = true;
            }
        });
    }

    get themeService() {
        return this._themeService;
    }

    get orderService() {
        return this._orderService;
    }

    loadOrderCache() {
        var dataItem = localStorage.getItem("cached_order_draft");
        if (!dataItem) {
            this.cacheOrder = {};
        } else {
            this.cacheOrder = JSON.parse(dataItem);
            if (!this.cacheOrder) {
                this.cacheOrder = {};
            }
        }
        if (this.cacheOrder.searcher_notifications_notes) {
            this.searcherNotificationsNotesFC = new UntypedFormControl(this.cacheOrder.searcher_notifications_notes);
        } else {
            this.searcherNotificationsNotesFC = new UntypedFormControl();
        }
        if (this.cacheOrder.change_watchers) {
            for (var i = 0; i < this.cacheOrder.change_watchers.length; ++i) {
                this.changeWatcherFC.fcs.push(new UntypedFormControl(this.cacheOrder.change_watchers[i]));
            }
        }
    }

    saveOrderCache() {
        this.cacheOrder.searcher_notifications_notes = this.searcherNotificationsNotesFC.value;

        this.cacheOrder.change_watchers = [];
        for (var i = 0; i < this.changeWatcherFC.fcs.length; ++i) {
            this.cacheOrder.change_watchers.push(this.changeWatcherFC.fcs[i].value);
        }

        //console.log("saving cache order", this.cacheOrder);
        localStorage.setItem("cached_order_draft", JSON.stringify(this.cacheOrder));
    }
}
