import { Component, OnInit } from '@angular/core';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { InvoiceService } from '../invoice.service';

@Component({
    selector: 'app-invoice-all',
    templateUrl: './invoice-all.component.html',
    styleUrls: ['./invoice-all.component.css']
})
export class InvoiceAllComponent implements OnInit {
    initialized = false
    currentPageAll = 1
    currentPageOpen = 1
    allListLimit
    openListLimit
    allListCount
    openListCount

    allSortField = "invoice_created" // was invoice_date changed to created 6/5/23 - Brock
    allSortDirection = "desc" 
    openSortField = "invoice_created" // was invoice_date changed to created 6/5/23 - Brock
    openSortDirection = "desc"
    
    openInvoices
    allInvoices
    faCaretDown = faCaretDown
    faCaretUp = faCaretUp

    columnTitles = [
        {
            display: "ID",
            fieldName: "invoice_id"
        },
        {
            display: "Invoice Date",
            fieldName: "invoice_date"
        },
        {
            display: "Client",
            fieldName: "for_company_name"
        },
        {
            display: "Created",
            fieldName: "invoice_created"
        },
        {
            display: "Status",
            fieldName: "status_name"
        },
        {
            display: "Balance",
            fieldName: "balance",
            // align: "right" // ???????!?!?!?
        }
    ]

    constructor(
        private invoiceService: InvoiceService,
    ) { }

    allSortClick(columnTitle) {
        if (this.allSortField == columnTitle.fieldName) {
            if (this.allSortDirection == "desc") {
                this.allSortDirection = "asc"
            } else {
                this.allSortDirection = "desc"
            }
        } else {
            this.allSortField = columnTitle.fieldName
            this.allSortDirection = "desc"
        }
        this.getAllInvoicesPromise()
    }


    ngOnInit() {
        this.getAllInvoicesPromise().then(() => {
            this.initialized = true
        })
    }

    getAllInvoicesPromise() {
        return new Promise((resolve, reject) => {
            this.invoiceService.getAllInvoices(this.allSortField, this.allSortDirection, this.currentPageAll).subscribe((response) => {
                //console.log("all response", response)
                if (response.success) {
                    this.allInvoices = response.result
                    this.allListLimit = response.limit
                    this.allListCount = response.count
                } else {
                    console.log(response)
                }
                //console.log("all", this.allInvoices)
                resolve(null)
            })
        })
    }

    setPageAll(page) {
        this.currentPageAll = page
        this.getAllInvoicesPromise()
    }


}
