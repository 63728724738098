<div *ngIf="teamData$ | async as teamData" class="container-fluid pt-2">
    <div class="row">
        <div class="col-2 d-none d-md-block">
        </div>
        <div class="col-12 col-md-8">

            <div class="row">
                <div class="col bold" style="font-size: 1.6em; color: #117ac9">
                    <span *ngIf="isNew">New</span>
                    <span *ngIf="!isNew">Edit</span>
                    Team
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <app-input-text label="Name" [fc]="formControls['name']" [disabled]="isReadOnly">
                    </app-input-text>
                    <span *ngIf="formErrors['name']" class="validation-error mt-1">{{formErrors['name']}}</span>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col">
                    <span class="bold" style="font-size: 1.6em; color: #117ac9">
                        Coverage
                    </span>
                    <app-button label="New" class="ml-2" [icon]="faPlus"
                        [clickFunction]="newCoverage.bind(this, teamData)" [small]="true">
                    </app-button>
                </div>
            </div>

            <div *ngFor="let coverage of teamData.result[0].data_team_coverages; let index = index" class="row">
                <div class="col-md">
                    <app-input-enum label="County" [fc]="coverage.countyFC" [disabled]="isReadOnly"
                        [enumValuesNameAndId]="countyJurisdictions$ | async">
                    </app-input-enum>
                    <span *ngIf="coverage.countyError" class="validation-error mt-1">{{coverage.countyError}}</span>
                    <app-input-enum label="Workflow" [enumValuesNameAndId]="workflows$ | async"
                        [fc]="coverage.workflowFC" [disabled]="isReadOnly">
                    </app-input-enum>
                    <span *ngIf="coverage.workflowError" class="validation-error mt-1">{{coverage.workflowError}}</span>
                    <app-button label="Delete" class="ml-2" [icon]="faMinus"
                        [clickFunction]="deleteCoverage.bind(this, teamData, index)" [small]="true">
                    </app-button>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col">
                    <span class="bold" style="font-size: 1.6em; color: #117ac9">
                        Members
                    </span>
                    <app-button label="New" class="ml-2" [icon]="faPlus"
                        [clickFunction]="newMember.bind(this, teamData)" [small]="true">
                    </app-button>
                </div>
            </div>

            <div *ngFor="let member of teamData.result[0].data_team_members; let index = index" class="row">
                <div class="col-md">
                    <app-input-enum label="User" [enumValuesNameAndId]="companyUsers$ | async" [fc]="member.userFC"
                        [disabled]="isReadOnly">
                    </app-input-enum>
                    <span *ngIf="member.userError" class="validation-error mt-1">{{member.userError}}</span>
                    <app-button label="Delete" class="ml-2" [icon]="faMinus"
                        [clickFunction]="deleteMember.bind(this, teamData, index)" [small]="true">
                    </app-button>
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="anyError" class="row mt-3 validation-error-banner py-2">
        <div class="col-12" align="center">
            <fa-icon [icon]="faExclamationCircle"></fa-icon>
            Please address errors above and re-submit.
        </div>
    </div>

    <div class="row pt-3">
    </div>
    <app-button-group-create *ngIf="isNew" [create]="save.bind(this, teamData)" [cancel]="back.bind(this)">
    </app-button-group-create>
    <app-button-group-save *ngIf="!isNew" [save]="save.bind(this, teamData)" [cancel]="back.bind(this)"></app-button-group-save>

</div>