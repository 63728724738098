import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getProducts(): Observable<any> {
        return this.http.get<any>("api/products");
    }

    getProductGroups(): Observable<any> {
        return this.http.get<any>("api/productGroups");
    }

    getProduct(id): Observable<any> {
        return this.http.get<any>("api/product/" + id);
    }
}
