<div *ngIf="initialized">

    <div *ngIf="showClientDropdown" class="row mt-2">
        <div class="col-2 col-md-8">
        </div>
        <div class="col-10 col-md-4">
            <app-input-enum label="Client" [fc]="clientsForInvoiceFC" [enumValuesNameAndId]="clientsForInvoice"
                includeUnsetDisplay="(All Clients)" [change]="clientForInvoiceChange.bind(this)">
            </app-input-enum>
        </div>
    </div>

    <span *ngFor="let listView of listViewEntries; let listViewIndex = index">
        <div *ngIf="!lists[listViewIndex].hidden" class="container-fluid mt-2">
            <app-dashboard-controls [title]="listView.title" [pageNumber]="lists[listViewIndex].currentPage"
                [pageSize]="lists[listViewIndex].limit" [entryCount]="lists[listViewIndex].count"
                [setPage]="this.setPage.bind(this, listViewIndex)">
            </app-dashboard-controls>
        </div>
        <div *ngIf="!lists[listViewIndex].hidden" class="container-fluid dashboard-header mt-2"
            id="cntnr-{{listViewIndex}}" [style.background-color]="themeService.tertiaryColor">
            <div class="row">
                <div class="col">
                    <table class="table table-striped">
                        <thead>
                            <tr class="table-header">
                                <td class="py-2 text-center">Actions</td>
                                <td></td>
                                <td *ngFor="let display_column of listView.display_column; let isFirst = first; let k = index"
                                    style="white-space: nowrap;"
                                    (click)="doSort(listView, display_column.name, listViewIndex)" class="py-2 pointer">
                                    {{display_column.title}}<span
                                        *ngIf="lists[listViewIndex].sort && lists[listViewIndex].sort.field == display_column.name">
                                        <span *ngIf="lists[listViewIndex].sort.direction == 1">
                                            <fa-icon [icon]="faCaretDown" size="1x" title="sorted ascending"></fa-icon>
                                        </span>
                                        <span *ngIf="lists[listViewIndex].sort.direction == 0">
                                            <fa-icon [icon]="faCaretUp" size="1x" title="sorted descending"></fa-icon>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="table-row"
                                *ngFor="let order of getListItems(listViewIndex); let j = index; let isLastOrder = last">
                                <td>
                                    <app-input-dropdown-buttons [buttons]="order.dashboard_action_buttons">
                                    </app-input-dropdown-buttons>
                                </td>
                                <td>
                                    <span *ngIf="this.listItems[listViewIndex][j].rush">
                                        <fa-icon [icon]="faCircle" size="1x" title="Rush" style="color: orange;">
                                        </fa-icon>
                                    </span>
                                </td>
                                <td>
                                    <a [routerLink]="this.listItems[listViewIndex][j].order_status == 'R' ? '/order-create' : '/searcher-order-edit'"
                                        [queryParams]="{orderUuid: this.listItems[listViewIndex][j].uuid}">{{order.order_id}}
                                        <span *ngIf="(order.attached_documents && order.attached_documents.length > 0)
                                            || order.documents && order.documents.length > 0
                                            || order.document_count > 0" class="paperclip-documents">
                                            <fa-icon class="ml-1" [icon]="faPaperclip" size="1x"
                                                title="Documents are attached to order">
                                            </fa-icon>
                                        </span>
                                        <span *ngIf="order.sbl_document_count" class="paperclip-sbl">
                                            <fa-icon class="ml-1" [icon]="faHome" size="1x"
                                                title="Documents are attached to sbl"></fa-icon>
                                        </span>
                                        <span *ngIf="order.final_document_count" class="paperclip-final">
                                            <fa-icon class="ml-1" [icon]="faUpload" size="1x"
                                                title="Final Documents are attached to order"></fa-icon>
                                        </span>
                                        <span *ngIf="order.api_icon">
                                            <img src="assets/{{order.api_icon}}" />
                                        </span>
                                    </a>
                                    &nbsp;
                                    <span *ngIf="(order.attached_documents && order.attached_documents.length > 0)
                                        || order.documents && order.documents.length > 0
                                        || order.document_count > 0">
                                        <!-- new order-document link test, matt mentioned will be moving to full link based on dashboard ~brock
                                        <a [routerLink]="['/order-document']" [queryParams]="{orderUuid: this.listItems[listViewIndex][j].uuid}">
                                            <fa-icon [icon]="faFileDownload"></fa-icon>
                                        </a>
                                        -->
                                    </span>
                                </td>
                                <td>
                                    {{order.service_name}}
                                    <!-- {{order.service_group_name}}<span *ngIf="order.primary_service_name">:
                                        {{order.primary_service_name}}</span> -->
                                </td>
                                <td>
                                    <app-input-date [fc]="order.dueDateFC"
                                        [change]="dueDateChange.bind(this, order, listViewIndex)"
                                        [format]="InputDateFormat.none">
                                    </app-input-date>
                                </td>
                                <td>
                                    <span class="status-badge" [ngClass]="['order-status-' + order.order_status ]">
                                        {{order.order_status_name | uppercase}}
                                    </span>
                                </td>
                                <td>
                                    {{order.searcher}}
                                </td>
                                <td>
                                    <a routerLink="/order-assessment-info" [queryParams]="{ oid: order.uuid }">
                                        {{order.sbl}}
                                    </a>
                                </td>
                                <td>
                                    {{order.address}}
                                </td>
                                <td>
                                    {{order.county_name}}
                                </td>
                                <td>
                                    {{order.effective_client_name}}
                                </td>
                                <td>
                                    {{order.reference_number}}
                                </td>
                                <td>
                                    {{order.property_seller ? order.property_seller.join(", ") : ""}}
                                </td>
                                <td>
                                    {{order.submission_date | date : 'shortDate'}}
                                </td>
                                <td>
                                    {{order.complete_date | date : 'shortDate'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="!lists[listViewIndex].hidden" class="container-fluid mt-4 py-2">
            <app-dashboard-controls [title]="listView.title" [pageNumber]="lists[listViewIndex].currentPage"
                [pageSize]="lists[listViewIndex].limit" [entryCount]="lists[listViewIndex].count"
                [setPage]="this.setPage.bind(this, listViewIndex)" [isFooter]="true">
            </app-dashboard-controls>
        </div>
    </span>
</div>