<div class="row mt-3">
    <div class="col bold">
        {{label}}<span *ngIf="requiredMarker" style="position: relative;"
            (mouseenter)="getElementById(attributeIdentifier + '_required_mouseover').style.display='block';"
            (mouseleave)="getElementById(attributeIdentifier + '_required_mouseover').style.display='none';">*<span
                style="position: absolute; display: none; top: 0px; left: 1.0em;"
                id="{{attributeIdentifier}}_required_mouseover">(required)</span></span>

        <span *ngIf="tooltip" data-toggle="tooltip" data-placement="right" [title]="tooltip" class="saq-tooltip ml-1">
            <fa-icon [icon]="faInfoCircle">
            </fa-icon>
        </span>
    </div>
</div>
<div class="row mt-1">
    <div *ngIf="!disabled" class="col">
        {{prefix}}
        <input *ngIf="!prefix" type="text" class="input-style" [formControl]="fc" (keyup)="doChange($event)"
            (keyup.enter)="doEnter()" [placeholder]="placeholder">
        <input *ngIf="prefix" type="text" class="input-style ml-2" style="width: unset;" [formControl]="fc"
            (keyup)="doChange($event)" (keyup.enter)="doEnter()">
    </div>
    <div *ngIf="disabled" class="col ml-2">
        {{fc.value}}
    </div>
</div>
<div *ngIf="exampleText && !disabled" class="row">
    <div class="col" style="font-size: .8em; color: darkgrey;">
        {{exampleText}}
    </div>
</div>
<!-- <div *ngIf="style == 'edit-sbl'" class="row mt-1">
    <div class="col" [ngClass]="calcColClass">
        <input *ngIf="!disabled" type="text" class="input-style" [formControl]="fc" (keyup)="doChange($event)"
            (keyup.enter)="doEnter()">
        <span *ngIf="disabled">{{fc.value}}</span>
    </div>
</div> -->