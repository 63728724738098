import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../account.service';
import { faBan, faLongArrowAltLeft, faLongArrowAltRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { ThemeRebrandService } from '../theme-rebrand.service';

@Component({
    selector: 'app-account-choose-company-type',
    templateUrl: './account-choose-company-type.component.html',
    styleUrls: ['./account-choose-company-type.component.css']
})
export class AccountChooseCompanyTypeComponent implements OnInit {
    faBan = faBan;
    initialized = false;
    companyTypes;
    faLongArrowAltLeft = faLongArrowAltLeft;
    faLongArrowAltRight = faLongArrowAltRight;
    faCheck = faCheck;
    imgs = new Array();
    error;

    constructor(
        private _themeService: ThemeRebrandService,
        private router: Router,
        private _accountService: AccountService,
        private renderer: Renderer2,
    ) { }

    get accountService() {
        return this._accountService;
    }

    ngOnInit() {
        this.accountService.getCompanyTypes().subscribe((response) => {
            //console.log(response);
            this.companyTypes = response.result;
            for (var i = 0; i < this.companyTypes; ++i) {
                var img = new Image();
                img.src = this.getMouseoverImageForCompanyType(this.companyTypes[i]);
                this.imgs.push(img);
            }
            this.initialized = true;
        })
    }

    ngAfterViewInit(): void {
        this.renderer.setStyle(document.getElementsByTagName('body')[0], "background-image", "url('assets/background.png')");
        this.renderer.setStyle(document.getElementsByTagName('body')[0], "background-position", "center top");
    }

    get themeService() {
        return this._themeService;
    }

    selectType(type) {
        this.accountService.accountCreateCompanyType = type.id;
    }

    next() {
        //if company not selected, show error
        if (!this.accountService.accountCreateCompanyType) {
            this.error = "Please select a company type.";
            return;
        }
        this.accountService.navNextStep();
    }

    // cancel() {
    //     this.router.navigate(['/login']);
    // }

    back() {
        this.accountService.navPreviousStep();
    }

    getImageForCompanyType(type) {
        switch (type.id) {
            case "title-agent":
                return "assets/choose_company_title_agent.png"
            case "searcher":
                return "assets/choose_company_searcher.png";
            case "search-company":
                return "assets/choose_company_search_abstract_company.png";
            case "real-estate-professional":
                return "assets/choose_company_real_estate_professional.png";
        }
    }

    getMouseoverImageForCompanyType(type) {
        var imgPath = this.getImageForCompanyType(type);
        return imgPath.substring(0, imgPath.length - 4) + "_mouseover.png";
    }

    tileMouseover(type) {
        var elem = document.getElementById(type.id + "_img");
        (<HTMLImageElement>elem).src = this.getMouseoverImageForCompanyType(type);
        elem = document.getElementById(type.id + "_check");
        if (elem) {
            this.renderer.setStyle(elem, "color", "white");
        }
    }
    tileMouseout(type) {
        var elem = document.getElementById(type.id + "_img");
        (<HTMLImageElement>elem).src = this.getImageForCompanyType(type);
        elem = document.getElementById(type.id + "_check");
        if (elem) {
            this.renderer.setStyle(elem, "color", "#003263");
        }
    }
}
