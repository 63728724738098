import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ContactsService {

    constructor(
        private http: HttpClient,
    ) { }

    getContacts(sortField, sortIsDescending, limit, offset?) {
        var url = "api/getContacts/" + sortField + "/" + sortIsDescending + "/" + limit
        if (offset) {
            url += "/" + offset
        }
        return this.http.get<any>(url);
    }

}
