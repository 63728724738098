import { Component, OnInit, Input } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-dashboard-controls-button',
    templateUrl: './dashboard-controls-button.component.html',
    styleUrls: ['./dashboard-controls-button.component.css']
})
export class DashboardControlsButtonComponent {
    @Input() label;
    @Input() clickFunction;
    @Input() icon;
    @Input() altStyle;
    processing = false;
    faSpinner = faSpinner;
    activated = false
}
