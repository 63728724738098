import { Component, OnInit } from '@angular/core';
import { QualiaService } from '../qualia.service';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faUpload, faFileAlt, faDollarSign, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-qualia-dashboard',
    templateUrl: './qualia-dashboard.component.html',
})
export class QualiaDashboardComponent implements OnInit {
    initialized = false
    faUpload = faUpload
    faFileAlt = faFileAlt
    faDollarSign = faDollarSign
    faCaretDown = faCaretDown
    faCaretUp = faCaretUp

    currentPage = 1
    sortField = "qualia_status_id"
    sortDirection = 1

    cacheDashboardSort() {
        localStorage.setItem("qualia_dashboard_sort_col", this.sortField);
        localStorage.setItem("qualia_dashboard_sort_dir", this.sortDirection + "");
    }

    fetchCachedDashboardPageSort() {
        var cachedPage = localStorage.getItem("qualia_dashboard_page");
        if (cachedPage) {
            this.currentPage = parseInt(cachedPage);
        }
        var sortColumn = localStorage.getItem("qualia_dashboard_sort_col");
        if (sortColumn) {
            this.sortField = sortColumn;
        }
        var sortDirection = localStorage.getItem("qualia_dashboard_sort_dir");
        if (sortDirection) {
            this.sortDirection = parseInt(sortDirection);
        }
    }

    constructor(
        protected _themeService: ThemeRebrandService,
        private qualiaService: QualiaService,
        private router: Router,
        private _snackBar: MatSnackBar,

    ) { }

    get themeService() {
        return this._themeService;
    }

    setPage(page) {
        this.currentPage = page;
        this.getDashboardPromise()
    }

    qualiaOrders
    displayOrders

    columnDefinitions = [
        {
            title: "Action",
            field: "",
            center: true,
        },
        {
            title: "Apexiem Order #",
            field: "order_id",
            hideValue: true,
        },
        {
            title: "Qualia Order #",
            field: "order_number",
        },
        {
            title: "Status",
            field: "qualia_status_id",
            hideValue: true,
        },
        {
            title: "Buyer",
            field: "property_buyer",
        },
        {
            title: "Seller",
            field: "property_seller",
        },
    ]

    qualiaOrderStatusesById = {}

    getQualiaOrderStatusesPromise() {
        return new Promise((resolve, reject) => {
            this.qualiaService.getQualiaOrderStatuses().subscribe((response) => {
                response.result.map((item) => {
                    this.qualiaOrderStatusesById[item.id] = item
                })
                return resolve(null)
            })
        })
    }
    limit
    count

    dashTitle = "Qualia Orders Ready To Upload"

    getDashboardPromise() {

        var filterType = "ready"
        switch (this.router.url) {
            case "/qualia-dashboard-new":
                filterType = "new"
                this.dashTitle = "New Qualia Orders"
                break
            case "/qualia-dashboard-in-progress":
                filterType = "progress"
                this.dashTitle = "Qualia Orders In Progress"
                break
            case "/qualia-dashboard-submitted":
                filterType = "submitted"
                this.dashTitle = "Submitted Qualia Orders"
                break
            case "/qualia-dashboard-cancelled":
                filterType = "cancelled"
                this.dashTitle = "Cancelled Qualia Orders"
                break
            case "/qualia-dashboard-errored":
                filterType = "errored"
                this.dashTitle = "Errored Qualia Orders"
                break
        }

        return new Promise((resolve, reject) => {
            this.qualiaService.getQualiaDashboard(
                this.currentPage,
                this.sortField,
                this.sortDirection,
                filterType,
            ).subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                //console.log("dash resp", response)
                this.limit = response.limit
                this.count = response.count

                this.qualiaOrders = response.result
                for (var i = 0; i < this.qualiaOrders.length; ++i) {
                    if (this.qualiaOrders[i].apexiem_order_numbers && this.qualiaOrders[i].apexiem_order_uuids) {
                        var orderNumbers = this.qualiaOrders[i].apexiem_order_numbers.split(",")
                        var orderUuids = this.qualiaOrders[i].apexiem_order_uuids.split(",")
                        this.qualiaOrders[i].apexiemOrders = []
                        for (var j = 0; j < orderNumbers.length; ++j) {
                            this.qualiaOrders[i].apexiemOrders.push({
                                uuid: orderUuids[j].trim(),
                                order_id: orderNumbers[j].trim()
                            })
                        }
                    }
                }
                //console.log("qualia orders debug", this.qualiaOrders)

                return resolve(null)
            })
        })
    }

    doSort(fieldName) {
        if (!fieldName) {
            return
        }
        if (this.sortField == fieldName) {
            if (this.sortDirection) {
                this.sortDirection = 0;
            } else {
                this.sortDirection = 1;
            }
        } else {
            this.sortField = fieldName;
            this.sortDirection = 1;
        }
        this.cacheDashboardSort();
        this.getDashboardPromise();
    }

    ngOnInit() {
        this.fetchCachedDashboardPageSort()
        Promise.all([
            this.getQualiaOrderStatusesPromise(),
            this.getDashboardPromise()
        ]).then(() => {
            this.initialized = true
        })
    }

    entryClick(orderId) {
        this.router.navigate(["searcher-order-edit"], { queryParams: { orderUuid: orderId } });
    }

    doUpload(qualiaOrderId) {
        this.qualiaService.getQualiaOrderUpload(qualiaOrderId).subscribe((response) => {
            if (response.success) {
                this.getDashboardPromise()
                this._snackBar.open("Order upload complete", null, { duration: 2000, });
            } else {
                var errorMessage = "Error occurred during upload"
                if (response.error
                    && response.error.errors
                    && response.error.errors.length > 0) {
                    errorMessage += ": "
                    for (var i = 0; i < response.error.errors.length; ++i) {
                        if (i != 0) {
                            errorMessage += ", "
                        }
                        errorMessage += response.error.errors[i].message
                    }
                }
                this._snackBar.open(errorMessage, null, { duration: 2000, });
            }
        })
    }

    doNothingYet(snack) {
        this._snackBar.open(snack, null, { duration: 2000, });
    }
    doAddAdditionalCost() {
        this.doNothingYet("Add Additional Cost has not yet been implemented.")
    }
    doAttachAdditionalFiles() {
        this.doNothingYet("Attach Additional Files has not yet been implemented.")
    }
}
