import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ThemeService } from '../theme.service';
import { JurisdictionService } from '../jurisdiction.service';
import { AddressService } from '../address.service';
import { TitleCasePipe } from '@angular/common';
import { NumberFormatPipe } from '../number-format.pipe';
import { ResearcherToolsResultsComponent } from '../researcher-tools-results/researcher-tools-results.component';
import { faMinus, faPlus, faSearch, faBan, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-researcher-tools',
    templateUrl: './researcher-tools.component.html',
})
export class ResearcherToolsComponent implements OnInit {
    @ViewChild('countySearchInput') countySearchInput;
    @ViewChild('addressSearchInput') addressSearchInput;
    @ViewChild('sblSearchInput') sblSearchInput;
    @ViewChild(ResearcherToolsResultsComponent) results;

    initialized = false;
    stateFC = new UntypedFormControl("New York");
    countyFC = new UntypedFormControl();
    addressFC = new UntypedFormControl();
    sblFC = new UntypedFormControl();
    selectedItem;
    faBan = faBan;

    constructor(
        private _themeService: ThemeService,
        private _jurisdictionService: JurisdictionService,
        private _addressService: AddressService,
        private titlecase: TitleCasePipe,
        private numberFormat: NumberFormatPipe,
    ) { }

    ngOnInit() {
        document.body.style.backgroundColor = "#f7f8f9"
        document.body.style.backgroundImage = null
        this._addressService.selectedAddress = null;
        this._jurisdictionService.getJurisdictionData().subscribe((response) => {
            this._jurisdictionService.setJurisdictionData(response);
            this.initialized = true;
        });
    }

    get themeService() {
        return this._themeService;
    }

    get jurisdictionService() {
        return this._jurisdictionService;
    }

    get addressService() {
        return this._addressService;
    }

    searchAddress(term) {
        return this._addressService.searchAddress(this._jurisdictionService.getCountySwisByName(this.countyFC.value), term);
    }

    searchSBL(term) {
        return this._addressService.searchSBL(this._jurisdictionService.getCountySwisByName(this.countyFC.value), term);
    }

    getAddressItemDisplay(item) {
        if (item.unselectable) {
            return item.name;
        }
        return this.titlecase.transform(item.property_full_address + ", " + item.property_town_municipality);
    }

    getSBLItemDisplay(item) {
        if (item.unselectable) {
            return item.name;
        }
        return item.print_key;
    }

    addressItemSelect(item) {
        this._jurisdictionService.getTaxInformation(item.county_number_code).subscribe(response3 => {
            this._addressService.taxInformation = response3;
            this._jurisdictionService.getParcelInformation(item.county_number_code).subscribe(response4 => {
                this.addressFC.setValue(this.getAddressItemDisplay(item));
                this.sblFC.setValue(this.getSBLItemDisplay(item));
                this._addressService.parcelInformation = response4;
                this._addressService.setSelectedAddress(item);
                this.results.setPropInfo();
            });
        });
    }

    sblItemSelect(item) {
        return this.addressItemSelect(item);
    }

    clear() {
        this.countySearchInput.reset();
        this.addressSearchInput.reset();
        this.sblSearchInput.reset();
        this._addressService.selectedAddress = null;
    }
}
