<span *ngIf="!initialized">
    <div class="container-fluid mt-4 rounded-ninety" [style.background-color]="themeService.tertiaryColor">
        Initializing...
    </div>
</span>

<div *ngIf="initialized" class="container-fluid mt-4 rounded-ninety"
    [style.background-color]="themeService.tertiaryColor">

    <div class="row pb-1">
        <div class="col-8 pb-3 attribute-h1 bold">
            Report
        </div>
    </div>

    <div class="row dark-row py-2">
        <div class="col-4 bold">
            Report Type
        </div>
        <div class="col-8">
            <input class="pointer" type="radio" [id]="invoiceReportId" name="reportType" [value]="ReportType.invoice"
                [formControl]="reportTypeFC" />
            <label class="ml-1 mb-0 pointer" [for]="invoiceReportId">Invoice Report</label>

            <input class="ml-2 pointer" type="radio" [id]="paymentsReceivedReportId" name="reportType"
                [value]="ReportType.payments" [formControl]="reportTypeFC" />
            <label class="ml-1 mb-0 pointer" [for]="paymentsReceivedReportId">Payments Received
                Report</label>
        </div>
    </div>

    <app-input-enum label="Timeframe" [fc]="timeframeFC" [enumValues]="timeframeEnumValues" rowClass="dark-row">
    </app-input-enum>
    <app-input-date *ngIf="timeframeFC.value == 'Custom'" label="Start Date" [fc]="customStartDateFC"
        rowClass="dark-row"></app-input-date>
    <app-input-date *ngIf="timeframeFC.value == 'Custom'" label="End Date" [fc]="customEndDateFC" rowClass="dark-row">
    </app-input-date>
    <app-input-enum label="Client" [fc]="clientFC" [enumValuesNameAndId]="clientCompanies" rowClass="dark-row">
    </app-input-enum>

    <div class="row dark-row py-2">
        <div class="col-4 bold">
            Status
        </div>
        <div class="col-8">
            <span *ngFor="let status of invoiceStatuses; let first = first">
                <input [ngClass]="{ 'ml-2': !first }" [id]="status.generated_id" type="checkbox"
                    [formControl]="status.fc" class="pointer">
                <label class="ml-1 mb-0 pointer" [for]="status.generated_id">{{status.name}}</label>
            </span>
        </div>
    </div>
    <div class="row dark-row pt-3">
        <div class="col-4">
        </div>
        <div class="col-8">
            <app-button label="Generate Report" [clickFunction]="generateReport.bind(this)" [icon]="faFileInvoice">
            </app-button>
            <app-button label="Cancel" class="ml-2" [clickFunction]="cancel.bind(this)" [icon]="faBan">
            </app-button>
        </div>
    </div>
    <div class="row pt-3 dark-row">
    </div>

    <span *ngIf="displayedReportType == ReportType.invoice">
        <div class="row mt-4" style="border-radius: .3em .3em 0em 0em; background-color:#003263; color: white;">
            <div class="col-10 bold" style="font-size: 1.2em;">
                {{displayedReport.length}} invoices with {{searchCriteriaString}}
            </div>
            <div class="col-2" align="right">
                <app-button label="Export" [clickFunction]="export.bind(this)" [icon]="faFileExport" [small]="'true'">
                </app-button>
            </div>
        </div>

        <div class="row dark-row">
            <div class="col-1">
                Status
            </div>
            <div class="col-2">
                Invoice Date
            </div>
            <div class="col-1">
                Paid In
            </div>
            <div class="col-2">
                ID
            </div>
            <div class="col-3">
                Client
            </div>
            <div class="col-1 px-0" align="right">
                Invoice
            </div>
            <div class="col-1 px-0" align="right">
                Paid
            </div>
            <div class="col-1 px-0" align="right">
                Balance
            </div>
        </div>

        <div *ngFor="let invoice of displayedReport" class="row py-2"
            style="border-style: solid; border-top-width: 1px; border-right-width: 0px; border-left-width: 0px; border-bottom-width: 0px; border-color: lightgrey;">
            <div class="col-1">
                {{invoice.status_name}}
            </div>
            <div class="col-2">
                {{invoice.invoice_date * 1000 | date: 'M/d/yy'}}
            </div>
            <div class="col-1">
                {{invoice.paid | currency : 'USD'}}
            </div>
            <div class="col-2">
                {{invoice.invoice_id}}
            </div>
            <div class="col-3">
                {{invoice.for_company_name}}
            </div>
            <div class="col-1 px-0" align="right">
                {{invoice.amount_due | currency : 'USD'}}
            </div>
            <div class="col-1 px-0" align="right">
                {{invoice.paid | currency : 'USD'}}
            </div>
            <div class="col-1 px-0" align="right">
                {{invoice.balance | currency : 'USD'}}
            </div>
        </div>

    </span>

    <span *ngIf="displayedReportType == ReportType.payments">
        <div class="row mt-4" style="border-radius: .3em .3em 0em 0em; background-color:#003263; color: white;">
            <div class="col-10 bold" style="font-size: 1.2em;">
                {{displayedReport.length}} payments with {{searchCriteriaString}}
            </div>
            <div class="col-2" align="right">
                <app-button label="Export" [clickFunction]="export.bind(this)" [icon]="faFileExport" [small]="'true'">
                </app-button>
            </div>
        </div>

        <div class="row dark-row">
            <div class="col-1">
                Status
            </div>
            <div class="col-2">
                Invoice Date
            </div>
            <div class="col-1">
                Paid In
            </div>
            <div class="col-2">
                ID
            </div>
            <div class="col-3">
                Client
            </div>
            <div class="col-1 px-0" align="right">
                Invoice
            </div>
            <div class="col-1 px-0" align="right">
                Paid
            </div>
            <div class="col-1 px-0" align="right">
                Balance
            </div>
        </div>

        <div *ngFor="let invoice of displayedReport" class="row py-2"
            style="border-style: solid; border-top-width: 1px; border-right-width: 0px; border-left-width: 0px; border-bottom-width: 0px; border-color: lightgrey;">
            <div class="col-1">
                {{invoice.status_name}}
            </div>
            <div class="col-2">
                {{invoice.invoice_date * 1000 | date: 'M/d/yy'}}
            </div>
            <div class="col-1">
                {{invoice.payment_amount | currency : 'USD'}}
            </div>
            <div class="col-2">
                {{invoice.invoice_id}}
            </div>
            <div class="col-3">
                {{invoice.for_company_name}}
            </div>
            <div class="col-1 px-0" align="right">
                {{invoice.amount_due | currency : 'USD'}}
            </div>
            <div class="col-1 px-0" align="right">
                {{invoice.paid | currency : 'USD'}}
            </div>
            <div class="col-1 px-0" align="right">
                {{invoice.balance | currency : 'USD'}}
            </div>
        </div>

    </span>
</div>