import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard-search-all-open',
    templateUrl: './dashboard-search-all-open.component.html',
})
export class DashboardSearchAllOpenComponent implements OnInit {
    filter = {
        gate: "all",
        comparisons: [
            {
                field: "team_id",
                operator: "is",
                matchValue: null
            },
            {
                field: "workflow_status",
                operator: "is not",
                matchValue: "D"
            },
        ],
    }

    constructor() { }

    ngOnInit() {
    }

}
