import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import {
    faCaretDown, faCaretUp,
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-input-dropdown-buttons',
    templateUrl: './input-dropdown-buttons.component.html',
    styleUrls: ['./input-dropdown-buttons.component.css']
})
export class InputDropdownButtonsComponent implements OnInit {
    faCaretDown = faCaretDown
    faCaretUp = faCaretUp
    firstButton
    @Input() buttons;
    @ViewChild("actions_dropdown_menu", { read: ElementRef }) dropdownMenu: ElementRef;

    constructor() { }

    ngOnInit() {
        this.firstButton = this.buttons.shift()
    }

    clickDocumentEventListener;
    isCaretUp = false
    doShowActionsMenu() {
        this.dropdownMenu.nativeElement.style.display = 'block'
        this.clickDocumentEventListener = this.hideActionsMenu.bind(this)
        this.isCaretUp = true
        setTimeout(() => {
            document.addEventListener("click", this.clickDocumentEventListener);
        }, 0)
    }

    hideActionsMenu() {
        document.removeEventListener("click", this.clickDocumentEventListener);
        if (!this.dropdownMenu.nativeElement) {
            return;
        }
        this.dropdownMenu.nativeElement.style.display = 'none'
        this.isCaretUp = false
    }

    clickActionsMenu() {
        var curDisplay = this.dropdownMenu.nativeElement.style.display;
        if (curDisplay == "none") {
            this.doShowActionsMenu()
        }
    }
}
