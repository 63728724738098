import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'attributeNameDisplay',
})
export class AttributeNameDisplayPipe implements PipeTransform {

    transform(value: string): any {
        if (value == null) {
            return null;
        }
        if (typeof value != "string") {
            return value;
        }
        return value.replace(/_/g, ' ');
    }

}
