import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard-order-overdue',
    templateUrl: './dashboard-order-overdue.component.html',
})
export class DashboardOrderOverdueComponent implements OnInit {
    //note - this filter eliminates all results, since due date requirements have not yet been defined
    filter = {
        gate: "all",
        comparisons: [
            {
                field: "due_date",
                operator: "less-than-now",
            },
            {
                field: "order_status",
                operator: "is not",
                matchValue: "C",
            },
        ],
    }

    constructor() { }

    ngOnInit() {
    }

}
