import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';
import { UserService } from '../user.service';
import { SettingsService } from '../settings.service';
import { Router } from '@angular/router';
import { faPlus, faCaretDown, faCaretUp, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { AccountService } from '../account.service';
import { ChargebeeService } from '../chargebee.service';
import { AuthService } from '../auth.service';
import { DatePipe } from '@angular/common';
import { ButtonType } from '../button/button.component';

@Component({
    selector: 'app-user-list',
    templateUrl: 'user-list.component.html',
    providers: [DatePipe],
})
export class UserListComponent implements OnInit {
    ButtonType = ButtonType
    faPlus = faPlus;
    faCaretDown = faCaretDown;
    faCaretUp = faCaretUp;
    faTrashAlt = faTrashAlt;

    currentPage = 1
    count = 0
    limit = 25
    sortColumn = "unsorted"
    sortDirection = false

    initialized = false;
    _listView;
    _data = [];
    maxUsers;
    isOwner;
    userRolePermissionsLevels = {};
    title = "Users"

    displayColumns = [
        {
            name: "Username",
            field: "email_address",
        }, {
            name: "Full Name",
            field: "full_name",
        }, {
            name: "Role",
            field: "role_name",
        }, {
            name: "Last Login",
            field: "last_login",
        }, {
            name: "Actions",
        }
    ]

    constructor(
        protected _themeService: ThemeService,
        protected _settingsService: SettingsService,
        private userService: UserService,
        private router: Router,
        private accountService: AccountService,
        private chargebeeService: ChargebeeService,
        private authService: AuthService,
        private datePipe: DatePipe,
    ) {
    }

    get listView() {
        return this._listView;
    }

    get data() {
        return this._data;
    }

    get themeService() {
        return this._themeService;
    }

    get settingsService() {
        return this._settingsService;
    }

    doSort(display_column_name) {
        console.log("sorting not yet implemented", display_column_name);
    }

    entryValueColor(display_column) {
        if (display_column.link != null) {
            //return this._themeService.linkColor;
            return "#117ac9"
        }
        return "";
    }

    isCurrentUser(user) {
        return this.authService.username == user.email_address;
    }

    ngOnInit() {

        this.userService.isOwner().subscribe((response) => {
            if (response.success) {
                this.isOwner = response.result;
            } else {
                console.log("error while checking ownership", response);
            }
            this._listView = {
                title: "Users in " + this.authService.companyname,
                display_columns: [
                    {
                        "label": "Username",
                        "field": "email_address",
                        "size": 3,
                        link: true,
                    },
                    {
                        "label": "Full Name",
                        "field": "full_name",
                        "size": 3,
                    },
                    {
                        "label": "Role",
                        "field": "role_name",
                        "size": 2,
                    },
                    {
                        "label": "Last Login",
                        "field": "last_login",
                        "size": 2,
                    },
                    // {
                    //     "label": "Action",
                    //     "size": 2,
                    //     link: true,
                    // },
                ],
            }
            this.userService.getUsersByCompany(this.authService.companyId).subscribe((response) => {
                //console.log(response);
                if (response.success) {
                    this._data = response.result;
                    var additionalUsers = 0;
                    this.chargebeeService.getPlan().subscribe((response2) => {
                        //console.log(response2);
                        if (response2.addons) {
                            for (var i = 0; i < response2.addons.length; ++i) {
                                if (response2.addons[i].id = "searcher-hq-portal") {
                                    additionalUsers += response2.addons[i].quantity;
                                }
                            }
                        }
                        this.accountService.getPlanDetails(response2.plan).subscribe((response3) => {
                            //console.log("plan deeets", response3)
                            if (response3.result.length > 0) {
                                this.maxUsers = response3.result[0].max_users;
                            }
                            this.maxUsers += additionalUsers;

                            this.userService.getAllUserRoles().subscribe((response) => {
                                if (response.success) {
                                    for (var i = 0; i < response.result.length; ++i) {
                                        this.userRolePermissionsLevels[response.result[i].id] = response.result[i].permission_level
                                    }
                                }
                                this.initialized = true;
                            });
                        })
                    });
                }
            });
        })
    }

    refreshList() {
        this.userService.getUsersByCompany(this.authService.companyId).subscribe((response) => {
            //console.log(response);
            if (response.success) {
                this._data = response.result;
            }
        });
        this.userService.isOwner().subscribe((response) => {
            if (response.success) {
                this.isOwner = response.result;
            } else {
                console.log("error while checking ownership", response);
            }
        });
    }

    delete(j) {
        //console.log(this.data[j]);
        if (confirm("Are you sure you want to delete user " + this.data[j].email_address + "?")) {
            this.userService.deleteUser(this.data[j].id).subscribe((response) => {
                this.data.splice(j, 1);
            })
        }
    }

    setOwner(j) {
        this.userService.setOwner(this.data[j].id).subscribe((response) => {
            //this.data.splice(j, 1);
            this.refreshList();
        })
    }

    entryClick(display_column, j) {
        if (display_column.label == "Action") {
            this.userService.deleteUser(this.data[j].id).subscribe((response) => {
                this.data.splice(j, 1);
            })
        } else {
            this.router.navigate(["user-edit"], { queryParams: { userUuid: this.data[j].id } });
        }
    }

    userLimitReached() {
        //return this.maxUsers && this._data.length >= this.maxUsers;
        return false;
    }

    createUser() {
        if (this.userLimitReached()) {
            console.log("too many users configured");
        } else {
            this.router.navigate(["user-create"]);
        }
    }

    getCreateUserTitleText() {
        if (this.userLimitReached()) {
            return "Licensed user limit has been reached";
        } else {
            return "Create User";
        }
    }

    getEntryFieldValue(entry, display_column) {
        if (display_column.label == "Action") {
            if (entry.role == "owner") {
                return null;
            }
            return "Delete";
        }
        return entry[display_column.field];
    }

    getCreateUserButtonBackground() {
        if (this.userLimitReached()) {
            return "grey";
        } else {
            return this.themeService.secondaryColor;
        }
    }

    getCreateUserButtonBackgroundMousedOver() {
        if (this.userLimitReached()) {
            return "grey";
        } else {
            return this.themeService.backgroundColor;
        }
    }

    getCreateUserButtonColorMousedOver() {
        if (this.userLimitReached()) {
            return this.themeService.primaryColor;
        } else {
            return this.themeService.secondaryColor;
        }
    }

    getColumnClass(display_column, checkLink, listView, k) {
        var cssClasses: any = {};
        if (display_column.mobile_size) {
            cssClasses["col-md-" + display_column.size + " col-" + display_column.mobile_size] = true;
        } else {
            cssClasses["col-" + display_column.size] = true;
        }
        if (display_column.hidden_on_mobile) {
            cssClasses["d-none d-md-block"] = true;
        }

        //only add left margin if k == 0 or all previous columns are hidden
        var addLeft = true;
        for (var i = 0; i < k; ++i) {
            if (!(listView.display_columns[i].hidden_on_mobile && window.innerWidth < 768)) {
                addLeft = false;
                break;
            }
        }

        if (addLeft) {
            cssClasses["pl-1 pr-0"] = true;
        } else {
            cssClasses["px-0"] = true;
        }
        if (checkLink) {
            cssClasses.link = display_column.link != null;
        }
        return cssClasses;
    }

    canDelete(user) {
        return !this.isCurrentUser(user) && this.userRolePermissionsLevels[this.authService.userrole] < this.userRolePermissionsLevels[user.role];
    }

    canSetOwner(user) {
        return !this.isCurrentUser(user) && this.isOwner;
    }
}
