import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { TaxSearchService } from '../tax-search.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    faBan, faSave, faFilePdf, faPlus, faMinus, faFlagCheckered, faUnlock,
    faChevronLeft, faEdit, faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { AddressService } from '../address.service';
import { WorkflowService } from '../workflow.service';
import { JurisdictionService } from '../jurisdiction.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderService } from '../order.service';
import { ButtonType } from '../button/button.component';
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-tax-search',
    templateUrl: './tax-search.component.html',
})
export class TaxSearchComponent implements OnInit {
    ButtonType = ButtonType
    initialized = false
    taxSearchStatuses
    faBan = faBan
    faSave = faSave
    faFilePdf = faFilePdf
    faPlus = faPlus
    faMinus = faMinus
    faFlagCheckered = faFlagCheckered
    faUnlock = faUnlock
    faChevronLeft = faChevronLeft
    faEdit = faEdit
    faInfoCircle = faInfoCircle

    detailPaymentFCSet = []

    assessedToFC = new UntypedFormControl()
    premesisFC = new UntypedFormControl()
    countyFC = new UntypedFormControl()
    townFC = new UntypedFormControl()
    schoolDistrictFC = new UntypedFormControl()
    assessedLandValueTownFC = new UntypedFormControl()
    totalTownFC = new UntypedFormControl()
    exemptionsFC = new UntypedFormControl()
    taxClassFC = new UntypedFormControl()
    swisFC = new UntypedFormControl()
    sblFC = new UntypedFormControl()
    acreageFC = new UntypedFormControl()
    villageFC = new UntypedFormControl()
    assessedLandValueVillageFC = new UntypedFormControl()
    totalVillageFC = new UntypedFormControl()
    accountNumberFC = new UntypedFormControl()
    countyNotes = new UntypedFormControl()

    taxInfoURL

    addDetail() {
        var newDetail = {
            muniTypeFC: new UntypedFormControl(),
            addressFC: new UntypedFormControl(),
            notesFC: new UntypedFormControl(),
            taxYearFC: new UntypedFormControl(),
            taxLienFC: new UntypedFormControl(),
            taxLevyFC: new UntypedFormControl(),
            billedAmtFC: new UntypedFormControl(),
            woExemptionFC: new UntypedFormControl(),
            payments: []
        }
        this.detailPaymentFCSet.push(
            newDetail
        )
        return newDetail
    }

    removeDetail(index) {
        this.detailPaymentFCSet.splice(index, 1)
    }

    addPayment(detailIndex) {
        this.detailPaymentFCSet[detailIndex].payments.push(
            {
                instance: new UntypedFormControl(),
                taxesDue: new UntypedFormControl(),
                status: new UntypedFormControl(),
                dueDates: new UntypedFormControl(),
            }
        )
    }

    addPaymentToDetail(detail) {
        var payment = {
            instance: new UntypedFormControl(),
            taxesDue: new UntypedFormControl(),
            status: new UntypedFormControl(),
            dueDates: new UntypedFormControl(),
        }
        detail.payments.push(
            payment
        )
        return payment
    }

    constructor(
        protected _themeService: ThemeRebrandService,
        private taxSearchService: TaxSearchService,
        private router: Router,
        private addressService: AddressService,
        private workflowService: WorkflowService,
        private jurisdictionService: JurisdictionService,
        private _snackBar: MatSnackBar,
        private orderService: OrderService,
        private activatedRoute: ActivatedRoute,
    ) { }

    get themeService() {
        return this._themeService;
    }

    locked = false
    townCity
    village
    schoolDistrictSWIS
    saveError

    getMuniDataPromise() {
        return new Promise((resolve, reject) => {
            this.taxSearchService.getTaxSearchMuniDataForEdit(this.orderWorkflowUuid).subscribe((response) => {
                resolve(response)
            })
        })
    }

    fillTaxProfilePromise(juris, address) {
        var response
        return this.getMuniDataPromise().then((muniDataResponse: any) => {
            response = muniDataResponse
            if (!response.success) {
                console.log("error while fetching muni type")
                console.log(response.message)
                console.log(response.error)
                return
            }
            if (response.result && response.result.length > 0) {
                for (var i = 0; i < response.result.length; ++i) {
                    var detail = this.addDetail()
                    detail.muniTypeFC.setValue(response.result[i].tax_type_name)
                    detail.addressFC.setValue(response.result[i].address)
                    detail.taxYearFC.setValue(response.result[i].tax_year)
                    detail.taxLienFC.setValue(response.result[i].lien_date)
                    detail.taxLevyFC.setValue(response.result[i].tax_period)

                    for (var j = 0; j < 4; ++j) {
                        if (response.result[i]["due_date_" + (j + 1)]) {
                            var payment = this.addPaymentToDetail(detail)
                            payment.dueDates.setValue(formatDate(response.result[i]["due_date_" + (j + 1)], 'yyyy-MM-dd', 'en'))
                        }
                    }
                }
            }
        })
    }

    orderHeader
    getHeaderFromOrderPromise() {
        return new Promise((resolve, reject) => {
            this.taxSearchService.getTaxSearchHeaderFromOrder(this.orderWorkflowUuid).subscribe((response) => {
                if (response.success) {
                    this.orderHeader = response.result[0]
                    this.assessedToFC.setValue(this.orderHeader.assessed_owner_parsed)
                    this.sblFC.setValue(this.orderHeader.sbl)
                } else {
                    console.log("error while fetching order header")
                    console.log(response.message)
                    console.log(response.error)
                }
                resolve(null)
            })
        })
    }

    orderUuid
    getOrderIdParamPromise() {
        return new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe(params => {
                this.orderUuid = params.orderUuid
                resolve(this.orderUuid)
            })
        })
    }

    orderWorkflowUuid
    getOrderWorkflowIdParamPromise() {
        return new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe(params => {
                this.orderWorkflowUuid = params.orderWorkflowUuid
                resolve(this.orderWorkflowUuid)
            })
        })
    }

    refresh
    getRefreshParamPromise() {
        return new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe(params => {
                this.refresh = params.refresh
                resolve(null)
            })
        })
    }

    taxInfoInfo
    getShowTaxInfoLookupButtonPromise() {
        return new Promise((resolve, reject) => {
            this.taxSearchService.getShowTaxInfoLookupButton(this.orderWorkflowUuid).subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                if (response.result && response.result.length > 0) {
                    this.taxInfoInfo = response.result[0]
                }
                resolve(null)
            })
        })
    }

    ngOnInit() {
        this.getOrderIdParamPromise().then(() => {
            return this.getOrderWorkflowIdParamPromise()
        }).then(() => {
            return this.getRefreshParamPromise()
        }).then(() => {
            return this.getShowTaxInfoLookupButtonPromise()
        }).then(() => {
            return this.getHeaderFromOrderPromise()
        }).then(() => {
            if (!this.orderHeader.real_property_code) {
                this.router.navigate(["/searcher-order-edit-sbl"], { queryParams: { orderUuid: this.orderUuid } })
                return
            }
            return this.jurisdictionService.initPromise()
                //return this.jurisdictionService.getJurisdictionByRealPropertyCodePromise(this.orderHeader.real_property_code)
                .then((jurisResult) => {
                    //console.log("juris result", jurisResult)
                    //if (jurisResult.type)

                    //     if j.type = 'village' then
                    //     data_order_workflow_tax.village  = j.name (data_order.municipality)
                    //     data_order_workflow_tax.town_city=   select name from sys_jurisdiction  where id =  r.parent_id
                    //   ekse
                    //     data_order_workflow_tax.village  = null
                    //     data_order_workflow_tax.town_city = j.name (data_order.municipality)            



                    var juris = this.jurisdictionService.getJurisdictionByRPCode(this.orderHeader.real_property_code)
                    if (!juris) {
                        console.log("error - jurisdiction not found", this.orderHeader.real_property_code)
                        return
                    }
                    var parentJuris = this.jurisdictionService.getJurisdictionByJID(juris.city_town_jurisdiction_id)
                    if (juris.type == "village") {
                        this.townCity = parentJuris.name
                        this.village = juris.name
                    } else {
                        this.townCity = juris.name
                    }

                    //console.log(this.orderWorkflowUuid)
                    this.taxSearchService.getTaxSearch(this.orderWorkflowUuid).subscribe((response) => {
                        console.log("debug, tax search response", response)
                        var promises = []

                        if (response.result.length == 0 || this.refresh) {
                            //if jurisdiction has a profile, fill in profile
                            //console.log("no results, fill:", juris)
                            promises.push(this.fillTaxProfilePromise(juris, this.orderHeader.address))
                            this.premesisFC.setValue(this.orderHeader.address)
                            this.countyFC.setValue(this.orderHeader.county_name)
                            this.townFC.setValue(this.townCity)
                            this.schoolDistrictFC.setValue(this.orderHeader.school_district_name)

                            this.assessedLandValueTownFC.setValue(this.orderHeader.land_assed_value)
                            this.totalTownFC.setValue(this.orderHeader.assessed_value)
                            this.taxClassFC.setValue(this.orderHeader.use_code)
                            this.swisFC.setValue(this.orderHeader.real_property_code)
                            this.acreageFC.setValue(this.orderHeader.acreage)
                            this.villageFC.setValue(this.village)
                            this.accountNumberFC.setValue(this.orderHeader.account_number)

                        } else {
                            this.assessedToFC.setValue(response.result[0].assessed_owner)
                            this.premesisFC.setValue(response.result[0].premises)
                            this.countyFC.setValue(response.result[0].county)
                            this.townFC.setValue(response.result[0].town_city)
                            this.schoolDistrictFC.setValue(response.result[0].school_district)
                            this.assessedLandValueTownFC.setValue(response.result[0].assessed_land_value_1)
                            this.totalTownFC.setValue(response.result[0].total_assessed_value_1)
                            this.exemptionsFC.setValue(response.result[0].exemptions)
                            this.countyNotes.setValue(response.result[0].county_notes)
                            this.taxClassFC.setValue(response.result[0].tax_class)
                            this.swisFC.setValue(response.result[0].swis)
                            this.acreageFC.setValue(response.result[0].acreage)
                            this.villageFC.setValue(response.result[0].village)
                            this.assessedLandValueVillageFC.setValue(response.result[0].assessed_land_value_2)
                            this.totalVillageFC.setValue(response.result[0].total_assessed_value_2)
                            this.accountNumberFC.setValue(response.result[0].account_number)

                            this.locked = response.result[0].locked
                            var curDetailId = null;
                            var detail;
                            for (var i = 0; i < response.result.length; ++i) {
                                if (response.result[i].detail_uuid != curDetailId) {
                                    curDetailId = response.result[i].detail_uuid
                                    detail = this.addDetail()
                                    detail.muniTypeFC.setValue(response.result[i].muni_type)
                                    detail.addressFC.setValue(response.result[i].mailing_address)
                                    detail.notesFC.setValue(response.result[i].tax_notes)
                                    detail.taxYearFC.setValue(response.result[i].tax_year)
                                    detail.taxLienFC.setValue(response.result[i].tax_lien_year)
                                    detail.taxLevyFC.setValue(response.result[i].tax_levy_year)
                                    detail.billedAmtFC.setValue(response.result[i].billed_amount)
                                    detail.woExemptionFC.setValue(response.result[i].wo_exemptions)
                                }
                                if (response.result[i].payment_uuid) {
                                    var payment = this.addPaymentToDetail(detail)
                                    payment.instance.setValue(response.result[i].instance)
                                    payment.taxesDue.setValue(response.result[i].tax_payment)
                                    payment.status.setValue(response.result[i].tax_payment_status)
                                    if (response.result[i].tax_due_date) {
                                        payment.dueDates.setValue(formatDate(response.result[i].tax_due_date, 'yyyy-MM-dd', 'en'))
                                        console.log("FLIPPAH", payment.dueDates.value)
                                    }
                                }
                            }
                        }
                        Promise.all(promises).then(() => {

                            this.taxSearchService.getTaxStatuses().subscribe((response) => {
                                if (response.success) {
                                    this.taxSearchStatuses = response.result
                                } else {
                                    console.log(response)
                                }
                                if (this.taxInfoInfo) {
                                    this.taxInfoURL = this.taxInfoInfo.url
                                        .replace("$sbl.getSbl()", this.orderHeader.sbl)
                                        .replace("$tempTaxSearch.getTaxYer()", this.taxInfoInfo.tax_year)
                                        .replace("$tempTaxSearch.getTaxYear()", this.taxInfoInfo.tax_year)
                                }
                                this.initialized = true
                            })
                        })
                    })
                })
        })
    }

    doBack() {
        this.router.navigate(["searcher-order-edit"], { queryParams: { orderUuid: this.orderUuid } });
        // window.history.back()
        // var curLocation = window.location.href;
        // setTimeout(() => {
        //     if (window.location.href == curLocation) {
        //         this.router.navigate(["searcher-order-edit"], { queryParams: { orderUuid: this.orderUuid } });
        //     }
        // }, 500);
    }

    cancel() {
        this._snackBar.open("Tax Search Changes Cancelled", null, { duration: 2000, });
        this.doBack()
    }

    back() {
        this.doBack()
    }

    viewOrder() {
        this.router.navigate(["searcher-order-edit"], { queryParams: { orderUuid: this.orderUuid } });
        return true
    }

    saveForLater() {
        this.savePromise(this.locked).then(() => {
            if (!this.saveError) {
                this._snackBar.open("Tax Search Saved for Order " + this.orderHeader.order_id, null, { duration: 2000, })
                this.doBack()
            }
        })
    }

    getMoneyValue(stringValue) {
        if (!stringValue) {
            return null
        }
        return stringValue.toString().replaceAll("$", "").replaceAll(",", "")
    }

    savePromise(doLock) {
        this.saveError = null
        var details = []
        for (var i = 0; i < this.detailPaymentFCSet.length; ++i) {
            var payments = [];
            for (var j = 0; j < this.detailPaymentFCSet[i].payments.length; ++j) {
                payments.push({
                    instance: j + 1,
                    tax_payment: this.getMoneyValue(this.detailPaymentFCSet[i].payments[j].taxesDue.value),
                    tax_payment_status: this.detailPaymentFCSet[i].payments[j].status.value,
                    tax_due_date: this.detailPaymentFCSet[i].payments[j].dueDates.value,
                })
            }

            details.push({
                muni_type: this.detailPaymentFCSet[i].muniTypeFC.value,
                mailing_address: this.detailPaymentFCSet[i].addressFC.value,
                tax_notes: this.detailPaymentFCSet[i].notesFC.value,
                tax_year: this.detailPaymentFCSet[i].taxYearFC.value,
                tax_lien_year: this.detailPaymentFCSet[i].taxLienFC.value,
                tax_levy_year: this.detailPaymentFCSet[i].taxLevyFC.value,
                billed_amount: this.getMoneyValue(this.detailPaymentFCSet[i].billedAmtFC.value),
                wo_exemptions: this.getMoneyValue(this.detailPaymentFCSet[i].woExemptionFC.value),
                payments
            })
        }

        var requestObj = {
            order_workflow_id: this.orderWorkflowUuid,
            assessed_owner: this.assessedToFC.value,
            tax_class: this.taxClassFC.value,
            premises: this.premesisFC.value,
            exemptions: this.exemptionsFC.value,
            acreage: this.acreageFC.value,
            account_number: this.accountNumberFC.value,
            assessed_land_value_1: this.getMoneyValue(this.assessedLandValueTownFC.value),
            total_assessed_value_1: this.getMoneyValue(this.totalTownFC.value),
            assessed_land_value_2: this.getMoneyValue(this.assessedLandValueVillageFC.value),
            total_assessed_value_2: this.getMoneyValue(this.totalVillageFC.value),
            locked: doLock,
            county: this.countyFC.value,
            town_city: this.townFC.value,
            school_district: this.schoolDistrictFC.value,
            swis: this.swisFC.value,
            village: this.villageFC.value,
            county_notes: this.countyNotes.value,
            details
        };
        return new Promise((resolve, reject) => {
            this.taxSearchService.saveTaxSearch(requestObj).subscribe((response) => {
                if (response.success) {
                    resolve(null)
                } else {
                    console.log(response)
                    this.saveError = "Error encountered during save"
                    if (response.message) {
                        this.saveError += ": " + response.message
                    }
                    resolve(null)
                }
            })
        })
    }

    previewPDF() {
        this.savePromise(this.locked).then(() => {
            if (!this.saveError) {
                this.taxSearchService.viewOrderTaxPDF(this.orderWorkflowUuid)
            }
        })
    }

    finish() {
        this.savePromise(true).then(() => {
            if (!this.saveError) {
                this.locked = true
                this.taxSearchService.uploadOrderTaxPDF(this.orderWorkflowUuid).subscribe((response) => {
                    if (response.success) {
                        this._snackBar.open("Tax Search Finished", null, { duration: 2000, });
                        this.router.navigate(["searcher-order-edit"], { queryParams: { orderUuid: this.orderUuid } })
                    } else {
                        console.log("error while uploading pdf", response)
                    }
                })
            }
        })
    }

    unlock() {
        this.taxSearchService.orderTaxUnlock(this.orderWorkflowUuid).subscribe((response) => {
            if (response.success) {
                this._snackBar.open("Tax Search Unlocked", null, { duration: 2000, });
                this.locked = false;
            } else {
                console.log(response)
            }
        })
    }

    removePayment(i, j) {
        this.detailPaymentFCSet[i].payments.splice(j, 1)
    }

    sblClick() {
        window.open("/order-assessment-info?oid=" + this.orderUuid, '_blank');
    }

    navTaxInfo() {
        window.open(this.taxInfoURL, '_blank');
    }

    editMuniData() {
        this.router.navigate(["/tax-profile-edit"], { queryParams: { orderUuid: this.orderUuid, workflowUuid: this.orderWorkflowUuid } })
        //window.open("/tax-profile-edit?workflowUuid=" + this.orderWorkflowUuid, '_blank');
    }
}
