export class StringBuilder
{
    private parts: string[] = [];

    constructor(base?: string)
    {
        if(base){
            this.parts.push(base);
        }
    }

    public append(part: string): void
    {
        this.parts.push(part);
    }

    public toString(): string
    {
        return this.parts.join('');
    }

    public toTrimString(): string
    {
        return this.parts.join('').trim();
    }
}