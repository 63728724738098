<app-order-create-step-circles [stepProvider]="orderService"></app-order-create-step-circles>

<span *ngIf="!initialized">
    <div class="container-fluid bordered-container-middle pt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.secondaryColor">
        Initializing...
    </div>
</span>

<div *ngIf="initialized" class="container-fluid bordered-container-middle light-blue-bordered"
    [style.color]="themeService.primaryColor" [style.background-color]="themeService.tertiaryColor">
    <div class="row pb-1">
        <div class="col pt-4 pb-3 attribute-h1 bold" align="center">
            Property Services
        </div>
    </div>

    <div class="row pb-1">
        <div class="col-md-6 pt-1 border-right">
            <span *ngFor="let service of services; let i = index">
                <div *ngIf="i < services.length / 2" class="row pb-1">
                    <div class="col">
                        <input type="checkbox" class="mr-2 checkbox-style" id="{{service.service_id}}"
                            (change)="serviceChange(service)" [checked]="isChecked(service)"
                            [disabled]="service.isDisabled" />
                        <span (click)="serviceClick(service)" class="pointer">
                            {{service.service_name}}
                        </span>
                        <span *ngIf="service.popup_help" style="position: relative; vertical-align: super;">
                            <fa-icon [icon]="faQuestionCircle" class="ml-1" size="1x" id="popup_help_{{i}}"
                                (mouseenter)="questionMouseEnter('popup_help_' + i)"
                                (mouseleave)="questionMouseLeave('popup_help_' + i)"
                                (click)="toggleDisplay('popup_help_text_' + i); toggleDisplay('popup_help_arrow_' + i)">
                            </fa-icon>
                            <span id="popup_help_text_{{i}}" class="popuptext" style="display:none;"
                                [style.color]="themeService.primaryColor"
                                [style.background-color]="themeService.tertiaryColor"
                                [style.border-color]="themeService.secondaryColor" align="center">
                                {{service.popup_help}}
                                <span style="position: absolute; z-index: 2; left: 9em; top: -6px; cursor: pointer;">
                                    <fa-icon [icon]="faWindowClose" id="popup_close_{{i}}"
                                        (mouseenter)="questionCloseMouseEnter('popup_close_' + i)"
                                        (mouseleave)="questionCloseMouseLeave('popup_close_' + i)"
                                        (click)="toggleDisplay('popup_help_text_' + i); toggleDisplay('popup_help_arrow_' + i)">
                                    </fa-icon>
                                </span>
                            </span>
                            <span id="popup_help_arrow_{{i}}" class="popuptextarrow"
                                style="display: none; position: absolute; left: 27px; top: 6px;"
                                [style.color]="themeService.primaryColor"
                                [style.background-color]="themeService.tertiaryColor"
                                [style.border-color]="themeService.secondaryColor">
                            </span>
                        </span>
                    </div>
                </div>
            </span>
        </div>
        <div class="col-md-6 pt-1">
            <span *ngFor="let service of services; let i = index">
                <div *ngIf="i >= services.length / 2" class="row pb-1">
                    <div class="col">
                        <input type="checkbox" class="mr-2 checkbox-style" id="{{service.service_id}}"
                            (change)="serviceChange(service)" [checked]="isChecked(service)"
                            [disabled]="service.isDisabled" />
                        <span (click)="serviceClick(service)" class="pointer">
                            {{service.service_name}}
                        </span>
                        <span *ngIf="service.popup_help" style="position: relative; vertical-align: super;">
                            <fa-icon [icon]="faQuestionCircle" class="ml-1" size="1x" id="popup_help_{{i}}"
                                (mouseenter)="questionMouseEnter('popup_help_' + i)"
                                (mouseleave)="questionMouseLeave('popup_help_' + i)"
                                (click)="toggleDisplay('popup_help_text_' + i); toggleDisplay('popup_help_arrow_' + i)">
                            </fa-icon>
                            <span id="popup_help_text_{{i}}" class="popuptext" style="display:none;"
                                [style.color]="themeService.primaryColor"
                                [style.background-color]="themeService.tertiaryColor"
                                [style.border-color]="themeService.secondaryColor" align="center">
                                {{service.popup_help}}
                                <span style="position: absolute; z-index: 2; left: 9em; top: -6px; cursor: pointer;">
                                    <fa-icon [icon]="faWindowClose" id="popup_close_{{i}}"
                                        (mouseenter)="questionCloseMouseEnter('popup_close_' + i)"
                                        (mouseleave)="questionCloseMouseLeave('popup_close_' + i)"
                                        (click)="toggleDisplay('popup_help_text_' + i); toggleDisplay('popup_help_arrow_' + i)">
                                    </fa-icon>
                                </span>
                            </span>
                            <span id="popup_help_arrow_{{i}}" class="popuptextarrow"
                                style="display: none; position: absolute; left: 27px; top: 6px;"
                                [style.color]="themeService.primaryColor"
                                [style.background-color]="themeService.tertiaryColor"
                                [style.border-color]="themeService.secondaryColor">
                            </span>
                        </span>
                    </div>
                </div>
            </span>
        </div>
    </div>
    <div class="row pb-1 mt-3">
        <div class="col-md-3">
        </div>
        <div class="col-md-6">
            <app-input-text-area label="Searcher Notes" [fc]="searcherPropertyNotesFC"
                [change]="saveOrderCache.bind(this)"></app-input-text-area>
        </div>
    </div>
</div>
<app-order-create-buttons></app-order-create-buttons>