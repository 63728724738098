import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CrudService } from '../crud.service';
import { TimezoneService } from '../timezone.service';
import { UserService } from '../user.service';
import { v4 as uuid } from 'uuid';
import { AuthService } from '../auth.service';
import { faBan, faExclamationCircle, faMinus, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InvoiceService } from '../invoice.service';
import { ProductService } from '../product.service';
import { SnackRouteService } from '../snack-route.service';
import { JurisdictionService } from '../jurisdiction.service';

@Component({
    selector: 'app-team',
    templateUrl: './team.component.html',
})
export class TeamComponent implements OnInit {
    faBan = faBan
    faSave = faSave
    faPlus = faPlus
    faMinus = faMinus
    faExclamationCircle = faExclamationCircle
    phoneExampleText = "e.g. (123) 456-7890";

    isReadOnly = false

    editableFields = [
        "name",
        "activated",
    ]
    formControls = {}

    nextOrderIdPrefix
    minNextOrderIdLength = 8

    timezones$ = this.timezoneService.getTimezones().pipe(
        map(response => response.map(a => a.display_value)),
    )

    dueDates$ = this.invoiceService.getDueDates().pipe(
        map(response => response.result)
    )

    isNew
    teamId
    isNewOverride
    providerOverrideId

    companyUsers$ = this.userService.getUsersByCompany(this.authService.companyId).pipe(
        map((response) => {
            for (var i = 0; i < response.result.length; ++i) {
                response.result[i].name = response.result[i].email_address
            }
            return response.result
        }),
        tap((result) => {
            //console.log("company users result", result)
        })
    )

    workflows$ = this.crudService.search({ type: "data_workflow" }).pipe(
        map((response) => response.result),
        tap((resule) => {
            //console.log("result val", resule)
        })
    )

    teamData$ = this.route.queryParams.pipe(
        switchMap((params) => {
            if (params["id"]) {
                this.teamId = params["id"]
                this.isNew = false
                return this.crudService.search({
                    type: "data_team { data_team_coverage, data_team_member }",
                    where: "id = ?",
                    whereArgs: [params["id"]],
                });
            } else {
                this.isNew = true
                return of({ result: [{ data_team_coverages: [], data_team_members: [] }] })
            }
        }),
        tap((response: any) => {
            //console.log("DATA RESP", response)
            this.formControls["name"] = new UntypedFormControl()
            if (response.result) {
                for (var i = 0; i < response.result.length; ++i) {
                    if (response.result[i].data_team_coverages) {
                        for (var j = 0; j < response.result[i].data_team_coverages.length; ++j) {
                            response.result[i].data_team_coverages[j].countyFC = new UntypedFormControl(response.result[i].data_team_coverages[j].county)
                            response.result[i].data_team_coverages[j].workflowFC = new UntypedFormControl(response.result[i].data_team_coverages[j].workflow_id)
                        }
                    } else {
                        response.result[i].data_team_coverages = []
                    }
                    if (response.result[i].data_team_members) {
                        for (var j = 0; j < response.result[i].data_team_members.length; ++j) {
                            response.result[i].data_team_members[j].userFC = new UntypedFormControl(response.result[i].data_team_members[j].user_id)
                        }
                    } else {
                        response.result[i].data_team_members = []
                    }
                }
            }
            if (!this.isNew) {
                this.formControls["name"].setValue(response.result[0].name)
                this.formControls["name"].disable()
            }
        })
    )

    countyJurisdictions$ = this.jurisdictionService.getCountyJurisdictions().pipe(
        map(o => o.result),
    )

    constructor(
        private route: ActivatedRoute,
        private crudService: CrudService,
        private userService: UserService,
        private timezoneService: TimezoneService,
        private authService: AuthService,
        private router: Router,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private invoiceService: InvoiceService,
        private productService: ProductService,
        private snackRouteService: SnackRouteService,
        private jurisdictionService: JurisdictionService,
    ) { }

    ngOnInit() {
    }

    back() {
        this.router.navigate(["/teams"])
    }

    cancel() {
        this.router.navigate(["/teams"])
    }

    isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n)
    }

    formErrors = {}
    anyError = false
    isValid(data) {
        this.anyError = false

        if (!this.formControls["name"].value) {
            this.formErrors['name'] = "Please specify a name."
            this.anyError = true
        } else {
            delete this.formErrors['name']
        }

        for (var i = 0; i < data.result[0].data_team_coverages.length; ++i) {
            if (!data.result[0].data_team_coverages[i].countyFC.value) {
                data.result[0].data_team_coverages[i].countyError = "Please specify a county."
                this.anyError = true
            } else {
                delete data.result[0].data_team_coverages[i].countyError
            }
            if (!data.result[0].data_team_coverages[i].workflowFC.value) {
                data.result[0].data_team_coverages[i].workflowError = "Please specify a workflow."
                this.anyError = true
            } else {
                delete data.result[0].data_team_coverages[i].workflowError
            }
        }
        for (var i = 0; i < data.result[0].data_team_members.length; ++i) {
            if (!data.result[0].data_team_members[i].userFC.value) {
                data.result[0].data_team_members[i].userError = "Please specify a user."
                this.anyError = true
            } else {
                delete data.result[0].data_team_members[i].userError
            }
        }
        return !this.anyError
    }

    save(data) {
        // //console.log("save isnew", this.isNew)
        if (!this.isValid(data)) {
            return
        }

        if (this.isNew) {

            var operations = []


            var teamId = uuid()
            operations.push({
                operation: "insert",
                type: "data_team",
                value: {
                    id: teamId,
                    company_id: this.authService.companyId,
                    name: this.formControls["name"].value,
                    display: 1,
                }
            })

            for (var i = 0; i < data.result[0].data_team_coverages.length; ++i) {
                operations.push({
                    operation: "insert",
                    type: "data_team_coverage",
                    value: {
                        team_id: teamId,
                        county: data.result[0].data_team_coverages[i].countyFC.value,
                        workflow_id: data.result[0].data_team_coverages[i].workflowFC.value,
                    }
                })
            }

            for (var i = 0; i < data.result[0].data_team_members.length; ++i) {
                operations.push({
                    operation: "insert",
                    type: "data_team_member",
                    value: {
                        team_id: teamId,
                        uder_id: data.result[0].data_team_members[i].userFC.value,
                    }
                })
            }
            var sub = this.crudService.apply({ entries: operations }).subscribe((response) => {
                //console.log(response)
                sub.unsubscribe()
                if (response.success) {
                    this.snackRouteService.snack("Team Updated")
                    this.router.navigate(["/teams"])
                } else {
                    console.log(response)
                    this.snackRouteService.snack("error occurred")
                }
            })
        } else {

            var operations = []


            operations.push({
                operation: "update",
                type: "data_team",
                value: {
                    id: this.teamId,
                    company_id: this.authService.companyId,
                    name: this.formControls["name"].value,
                    display: 1,
                }
            })
            operations.push({
                operation: "clear",
                type: "data_team_coverage",
                value: {
                    team_id: this.teamId,
                }
            })

            operations.push({
                operation: "clear",
                type: "data_team_member",
                value: {
                    team_id: this.teamId,
                }
            })

            for (var i = 0; i < data.result[0].data_team_coverages.length; ++i) {
                operations.push({
                    operation: "insert",
                    type: "data_team_coverage",
                    value: {
                        team_id: this.teamId,
                        county: data.result[0].data_team_coverages[i].countyFC.value,
                        workflow_id: data.result[0].data_team_coverages[i].workflowFC.value,
                    }
                })
            }

            for (var i = 0; i < data.result[0].data_team_members.length; ++i) {
                operations.push({
                    operation: "insert",
                    type: "data_team_member",
                    value: {
                        team_id: this.teamId,
                        user_id: data.result[0].data_team_members[i].userFC.value,
                    }
                })
            }
            var sub = this.crudService.apply({ entries: operations }).subscribe((response) => {
                //console.log("update response", response)
                sub.unsubscribe()
                if (response.success) {
                    this.snackRouteService.snack("Team Updated")
                    this.router.navigate(["/teams"])
                } else {
                    console.log(response)
                    this.snackRouteService.snack("error occurred")
                }
            })
        }
    }

    delete() {
        const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
            panelClass: 'delete-confirmation-dialog-container',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var operations = []
                operations.push({
                    operation: "clear",
                    type: "data_team_coverage",
                    value: {
                        team_id: this.teamId,
                    }
                })

                operations.push({
                    operation: "clear",
                    type: "data_team_member",
                    value: {
                        team_id: this.teamId,
                    }
                })

                operations.push({
                    operation: "delete",
                    type: "data_team",
                    value: {
                        id: this.teamId,
                    }
                })

                var sub = this.crudService.apply({ entries: operations }).subscribe((response) => {
                    sub.unsubscribe()
                    if (response.success) {
                        this.snackRouteService.snack("Team Deleted")
                        this.router.navigate(["/teams"])
                    } else {
                        console.log(response)
                        this.snackRouteService.snack("error occurred")
                    }
                })
            } else {
                this._snackBar.open("Action cancelled", null, {
                    duration: 4000,
                });
            }
        });
    }

    newCoverage(data) {
        data.result[0].data_team_coverages.push({ countyFC: new UntypedFormControl(), workflowFC: new UntypedFormControl() })
    }

    deleteCoverage(data, index) {
        data.result[0].data_team_coverages.splice(index, 1)
    }

    newMember(data) {
        data.result[0].data_team_members.push({ userFC: new UntypedFormControl() })
    }

    deleteMember(data, index) {
        data.result[0].data_team_members.splice(index, 1)
    }

}
