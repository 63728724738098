<p-table [value]="workflowTableData" responsiveLayout="scroll">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="workflowId" class="blue-tint-background">
        Workflow<p-sortIcon field="workflowId"></p-sortIcon>
      </th>
      <th pSortableColumn="service" class="blue-tint-background">
        Service<p-sortIcon field="service"></p-sortIcon>
      </th>
      <th pSortableColumn="workflowStatus" class="blue-tint-background">
        Status<p-sortIcon field="workflowStatus"></p-sortIcon>
      </th>
      <th pSortableColumn="priceLevel" class="blue-tint-background">
        Price Level<p-sortIcon field="priceLevel"></p-sortIcon>
      </th>
      <th pSortableColumn="rush" class="blue-tint-background">
        Rush<p-sortIcon field="rush"></p-sortIcon>
      </th>
      <th pSortableColumn="delivery" class="blue-tint-background">
        Delivery<p-sortIcon field="delivery"></p-sortIcon>
      </th>
      <th pSortableColumn="searcherId" class="blue-tint-background">
        Searcher<p-sortIcon field="searcherId"></p-sortIcon>
      </th>
      <th class="blue-tint-background">Actions</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>{{ rowData.workflowName }}</td>

      <td>{{ rowData.services }}</td>

      <td>
        <p-dropdown
          [options]="workflowStatuses"
          [(ngModel)]="rowData.workflowStatus"
          (onChange)="updateRow('workflowStatus', rowData)"
          optionLabel="name"
          optionValue="id"
          placeholder="Select Status"
          appendTo="body"
          [disabled]="!editable"
        ></p-dropdown>
      </td>

      <td>
        <p-dropdown
          [options]="difficultyLevels"
          [(ngModel)]="rowData.priceLevel"
          (onChange)="updateRow('priceLevel', rowData)"
          optionLabel="name"
          optionValue="id"
          appendTo="body"
          [disabled]="!editable"
        ></p-dropdown>
      </td>

      <td>
        <p-checkbox
          [(ngModel)]="rowData.rush"
          binary="true"
          (onChange)="updateRow('rush', rowData)"
          [disabled]="!editable"
        ></p-checkbox>
      </td>

      <td>{{ rowData.delivery ? (rowData.delivery | date: 'M/d/yyyy') : 'N/A' }}</td>

      <td>{{ rowData.fullName ?? 'Unassigned' }}</td>

      <td>
        <p-button 
          pRipple 
          icon="pi pi-file-edit" 
          class="mr-2" 
          [rounded]="true" 
          [outlined]="true" 
          severity="success"
          (onClick)="checkPriceLevel(rowData)"
        ></p-button>

        <ng-container *ngIf="getActionButtonLink(rowData.report, rowData.uuid) as linkData">
          <p-button
            *ngIf="linkData.path"
            pRipple
            icon="pi pi-external-link"
            class="mr-2"
            [rounded]="true"
            [outlined]="true"
            severity="primary"
            [routerLink]="linkData.path"
            [queryParams]="linkData.queryParams"
          ></p-button>
        
          <p-button
            *ngIf="linkData.action"
            pRipple
            icon="pi pi-file-pdf"
            class="mr-2"
            [rounded]="true"
            [outlined]="true"
            severity="primary"
            (click)="linkData.action()"
          ></p-button>
        </ng-container>        
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog
  [(visible)]="displayDialog"
  header="Upload File"
  [style]="{ width: '50vw' }"
  [modal]="true"
  [closable]="false"
  class="custom-dialog"
>
  <ng-template pTemplate="content">
    <div class="upload-container blue-tint-background">
      <h3 class="upload-header">Select files to upload or drag and drop</h3>
      <p-fileUpload
        #fileUploader
        name="files[]"
        [customUpload]="true"
        (uploadHandler)="onFileUpload($event)"
        (onSelect)="onFileSelect($event)" 
        [multiple]="true"
        dragDrop="true"
        accept=".pdf,.doc,.docx,.txt,.jpeg,.png,.webp,.svg,.gif"
        [maxFileSize]="20000000"
        chooseLabel="Browse Files"
        [showUploadButton]="false"
        [showCancelButton]="false">
        
        <ng-template pTemplate="file" let-file let-index="index" let-removeFileCallback="removeFileCallback">
          <div class="p-fileupload-row custom-file-row">
            <span class="file-name">{{ file.name }}</span>
            <span class="file-type">{{ file.type || 'Unknown type' }}</span>
            <span class="file-size">{{ formatSize(file.size) }}</span>
            <p-dropdown
              [options]="attachmentTypes"
              [ngModel]="selectedAttachmentTypes[index]"
              (onChange)="onAttachmentTypeChange(index, $event.value)"
              optionLabel="name"
              placeholder="Select an attachment type"
              appendTo="body"
              class="attachment-dropdown"/>
            <button type="button" class="custom-remove-button" (click)="fileUploader.remove($event, index)">
              <i class="pi pi-times"></i>
            </button>
          </div>
        </ng-template>        
      </p-fileUpload>
      <p class="file-restrictions">
        Supported file types: PDF, JPEG, PNG, GIF, WEBP, SVG, TXT, DOC, DOCX
      </p>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="dialog-footer">
      <button
        pButton
        label="Close"
        icon="pi pi-times"
        class="p-button-text"
        (click)="closeDialog()"
      ></button>
      <button
        pButton
        label="Upload and Send"
        icon="pi pi-upload"
        class="p-button-success"
        (click)="fileUploader.upload()"
      ></button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="showPriceLevelDialog" header="Price Level Required" [modal]="true" [closable]="false">
  <p>Please set the price level to a value other than 0.</p>
  <p-footer>
    <p-button label="Okay" (onClick)="showPriceLevelDialog = false"></p-button>
  </p-footer>
</p-dialog>
