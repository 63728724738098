export class SysCounty {
    countyId?: number;
    id?: string;
    municipalityCode?: number;
    state?: string
    countyName?: string;
    type?: string;
    deprecated?: string;
    address?: string;
    taxYear?: string;
    taxPeriod?: string;
    lienDate?: string;
    fiscalStartDate?: Date;
    fiscalEndDate?: Date;
    dueDate1?: Date;
    dueDate2?: Date;
    dueDate3?: Date;
    dueDate4?: Date;
    refreshDate?: Date;
}