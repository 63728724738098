import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    private getCompanyURL = 'api/company';
    private getCompanyByIdURL = 'api/companyById';
    private getProvidersURL = 'api/providers';
    private updateCompanyURL = "api/company";
    private getDefaultProviderURL = "api/defaultProvider"
    private getClientCompaniesURL = 'api/clientCompanies';

    constructor(
        private http: HttpClient,
    ) {
    }

    getCompany() {
        return this.http.get<any>(this.getCompanyURL);
    }

    getCompanyById(companyId) {
        return this.http.get<any>(this.getCompanyByIdURL + "/" + companyId);
    }

    getProviders() {
        return this.http.get<any>(this.getProvidersURL);
    }

    getDefaultProvider() {
        return this.http.get<any>(this.getDefaultProviderURL);
    }

    getClientCompanies() {
        return this.http.get<any>(this.getClientCompaniesURL);
    }

    updateCompany(
        companyName,
        address,
        invoiceRemitAddress,
        companyLogo,
        webAddress,
        phoneNumber,
        faxNumber,
        accountOwner,
        fiscalYearStart,
        orderIdPrefix,
        nextOrderId,
        notifyEmailAddress,
        defaultProvider,
        defaultTimeZone,
        invoiceEmailAddress,
        documentDeliverySignature,
    ): Observable<any> {
        var params = {
            name: companyName,
            address: address,
            invoice_remit_address: invoiceRemitAddress,
            company_logo: companyLogo,
            web_address: webAddress,
            phone: phoneNumber,
            fax: faxNumber,
            account_owner: accountOwner,
            fiscal_year_start: fiscalYearStart,
            order_id_prefix: orderIdPrefix,
            next_order_id: nextOrderId,
            notify_email_address: notifyEmailAddress,
            default_provider: defaultProvider,
            default_time_zone: defaultTimeZone,
            invoice_email_address: invoiceEmailAddress,
            document_delivery_signature: documentDeliverySignature,
        };
        //console.log("update company params", params);
        return this.http.post<any>(this.updateCompanyURL, params, httpOptions);
    }

    updateClientCompany(body) {
        return this.http.post<any>("api/clientCompany", body, httpOptions);
    }
}
