import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-status-indicator',
    templateUrl: './status-indicator.component.html',
})
export class StatusIndicatorComponent implements OnInit {
    @Input() bgColor
    @Input() text

    constructor() { }

    ngOnInit() {
    }

}
