<div class="row">
    <div class="col" align="center">
        <app-button [buttonType]="ButtonType.cancel" [clickFunction]="cancel">
        </app-button>
        <app-button *ngIf="delete" [buttonType]="ButtonType.delete" [clickFunction]="delete">
        </app-button>
        <app-button class="float-right" [buttonType]="ButtonType.next" [clickFunction]="next">
        </app-button>
        <app-button *ngIf="!is_agent" class="float-right" [buttonType]="ButtonType.save" [clickFunction]="save" label="Save For Later">
        </app-button>
    </div>
</div>