import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faCog, faUser, faQuestionCircle, faComments, faWrench, faSignOutAlt, faCookie, faPlus, faFileInvoice, faBox, faBuilding, faFileAlt, faUsers, faList } from '@fortawesome/free-solid-svg-icons';
import { AccountService } from '../account.service';
import { AuthService } from '../auth.service';
import { ChargebeeService } from '../chargebee.service';
import { SystemService } from '../system.service';
import { UserService } from '../user.service';
import { v4 as uuid } from 'uuid';
import { map } from 'rxjs/operators';



@Component({
  selector: 'app-agent-menu',
  templateUrl: './agent-menu.component.html',
  styleUrls: ['./agent-menu.component.scss']
})
export class AgentMenuComponent implements OnInit {
  faCog = faCog
  @ViewChild('profileImageElement', { read: ElementRef }) profileImageElement: ElementRef;
  @ViewChild('profileImageElement2', { read: ElementRef }) profileImageElement2: ElementRef;

  constructor(
    private authService: AuthService,
    private router: Router,
    private chargebeeService: ChargebeeService,
    private accountService: AccountService,
    private userService: UserService,
    private systemService: SystemService
  ) { }

  userDropdown = [
    {
        name: "Me",
        icon: faUser,
        link: "/user",
        queryParams: { id: this.authService.userId, viewMode: true },
    }, {
        name: "Help",
        link: "https://www.apexiem.com/faqs",
        icon: faQuestionCircle,
    }, {
        name: "Send Feedback",
        link: "/user-feedback",
        icon: faComments,
    }, {
        name: "Utility Reports",
        link: "/utility-report",
        icon: faWrench,
        hide: !(this.authService.userrole === 'administrator'
            || this.authService.userrole === 'manager'
            || this.authService.userrole === 'system-admin'),
    }, {
        name: "Logout",
        click: this.authService.doLogout.bind(this.authService),
        icon: faSignOutAlt,
    }, {
        name: "Button Demo",
        link: "/button-demo",
        icon: faCookie,
        hide: this.authService.username != 'admin'
    }
  ]

  settingsMenuItem = {
    name: "Settings",
    icon: faCog,
    ifDisplay: this.showSettingsMenu.bind(this),
    menu: [
       {
            name: "Users",
            icon: faUser,
            link: "/users",
            additionalRoutes: ["/user-create", "/user-edit"],
        }
    ]
  }

  menu = [
    {
        name: "New Order",
        icon: faPlus,
        link: "/order-create",
        queryParams: { orderType: "Quick" },
    }, {
        name: "Dashboard",
        icon: faFileInvoice,
        link: "/agent-dashboard",
    }
  ]

  isCurrentLink(link) {
    //console.log("COMPARE", this.router.url, link)
    return this.router.url == link || this.router.url.startsWith(link + "?")
 }

  isCurrentMenuItem(menuItem) {
    if (this.isCurrentLink(menuItem.link)) {
        return true;
    }
    if (menuItem.menu) {
        for (var i = 0; i < menuItem.menu.length; ++i) {
            if (this.isCurrentMenuItem(menuItem.menu[i])) {
                return true;
            }
        }
    }
    if (menuItem.dropdown) {
        for (var i = 0; i < menuItem.dropdown.length; ++i) {
            if (this.isCurrentMenuItem(menuItem.dropdown[i])) {
                return true;
            }
        }
    }
    if (menuItem.additionalRoutes) {
        for (var i = 0; i < menuItem.additionalRoutes.length; ++i) {
            if (this.isCurrentLink(menuItem.additionalRoutes[i])) {
                return true;
            }
        }
    }
    return false;
  }

  showSettingsMenu() {
    return this.authService.userrole === 'administrator'
        || this.authService.userrole === 'manager'
        || this.authService.userrole === 'system-admin'
  }
  
  isAdminOrManager() {
    return this.authService.userrole === 'administrator'
        || this.authService.userrole === 'manager'
        || this.authService.userrole === 'system-admin'
  }

  isAdmin() {
    return this.authService.userrole === 'administrator'
        || this.authService.userrole === 'system-admin'
  }

  initialized

  setMenuUuid(item) {
      (<any>item).uuid = uuid()
      if (item.menu) {
          item.menu.forEach((item) => {
              this.setMenuUuid(item)
          })
      }
      if (item.dropdown) {
          item.dropdown.forEach((item) => {
              this.setMenuUuid(item)
          })
      }
  }

  ngOnInit() {

      this.menu.forEach((item => {
          this.setMenuUuid(item)
      }))

      this.initialized = true
      this.doLoadProfileImage()
  }

  userFullName$ = this.userService.getUserByName(this.authService.username).pipe(
      map((response) => {
          return response.result[0].full_name
      }),
  )

  getImageHeight(width, height, newWidth) {
      var ratio = width / height;
      return newWidth / ratio;
  }

  getImageWidth(width, height, newHeight) {
      var ratio = width / height;
      return ratio * newHeight;
  }

  getUserLetters(fullName) {
      if (fullName && fullName.trim()) {
          var nameParts = fullName.split(" ")
          if (nameParts.length == 1) {
              return fullName.trim().substring(0, 1).toUpperCase()
          } else {
              return nameParts[0].substring(0, 1).toUpperCase() + nameParts[nameParts.length - 1].substring(0, 1).toUpperCase()
          }
      }
      return this.authService.username.trim().substring(0, 1).toUpperCase();
  }

  photo
  userFullName
  doLoadProfileImage() {
      this.userService.getUserByName(this.authService.username).subscribe((response) => {
          this.photo = response.result[0].photo;
          this.userFullName = response.result[0].full_name;
          setTimeout(() => {
              this.accountService.profileImageId.next(this.photo);
              this.accountService.profileImageId.subscribe((photoId) => {
                  this.photo = photoId;
                  this.loadProfileImage();
              });
          }, 0);
      })
  }

  loadLetter(element, userFullName) {
      var img = document.createElement('div');
      img.style.display = 'inline-block';
      img.style.backgroundColor = '#337ab7';
      img.style.height = '32px';
      img.style.width = '32px';
      img.style.borderRadius = '32px';
      img.style.color = 'white';
      img.align = 'center';
      img.style.position = 'relative';

      if (element == this.profileImageElement2) {
          img.style.marginTop = '10px'
      }

      var letter = document.createElement('span');
      letter.style.fontSize = '20px';
      letter.style.fontWeight = 'normal';
      letter.innerHTML = this.getUserLetters(userFullName);
      letter.className = 'center';
      img.appendChild(letter);
      if (element) {
          while (element.nativeElement.firstChild) {
              element.nativeElement.removeChild(element.nativeElement.firstChild);
          }
          element.nativeElement.appendChild(img);
      }
  }

  setImage(elem, readerResult) {
      var img = document.createElement('img');
      (<any>img.src) = readerResult;
      img.onload = function () {
          var newHeight = 32;
          var newWidth = this.getImageWidth(img.width, img.height, newHeight);
          img.width = newWidth;
          img.height = newHeight;
          if (elem) {
              while (elem.nativeElement.firstChild) {
                  elem.nativeElement.removeChild(elem.nativeElement.firstChild);
              }
              elem.nativeElement.appendChild(img);
          }
      }.bind(this);
  }


  manageSubscriptionClick() {
    this.doOpenPortal();
  }


  loadProfileImage() {
    if (this.photo) {
        this.systemService.getProfileFile(this.photo).subscribe((response) => {
            var reader = new FileReader();
            reader.readAsDataURL(<any>response);
            reader.onloadend = () => {
                this.setImage(this.profileImageElement, reader.result)
                this.setImage(this.profileImageElement2, reader.result)
            };
        });
    } else {
        this.loadLetter(this.profileImageElement, this.userFullName)
        this.loadLetter(this.profileImageElement2, this.userFullName)
    }
  }

  doOpenPortal() {
    this.chargebeeService.getPlan().subscribe((response) => {
        //console.log('chargebee get plan response', response);
        if (response.success) {
            this.accountService.getPortalUrl(response.subscriptionId).subscribe((response) => {
                if (response.success) {
                    window.open(response.url);
                } else {
                    console.log('error while fetching portal url');
                }
            });
        }
    });
  }
}

