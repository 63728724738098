import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ThemeRebrandService } from '../theme-rebrand.service';

@Component({
    selector: 'app-provider-portal',
    templateUrl: './provider-portal.component.html',
})
export class ProviderPortalComponent implements OnInit {
    initialized = false
    isSearcher = false
    isProvider = false

    constructor(
        protected _themeService: ThemeRebrandService,
        private authService: AuthService,
    ) { }

    ngOnInit() {
        var promises = [];

        promises.push(this.authService.isProviderPromise().then((isProvider: boolean) => {
            this.isProvider = isProvider
        }))
        promises.push(this.authService.isSearcherPromise().then((isSearcher: boolean) => {
            this.isSearcher = isSearcher
        }))
        Promise.all(promises).then(() => {
            this.initialized = true;
        })
    }

    get themeService() {
        return this._themeService;
    }
}
