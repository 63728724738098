import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentsService } from '../documents.service';
import { SystemService } from '../system.service';
import {
    faFileDownload,
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-document-download',
    templateUrl: './document-download.component.html',
})
export class DocumentDownloadComponent implements OnInit {
    faFileDownload = faFileDownload

    initialized = false
    @ViewChild("imageCol", { read: ElementRef }) imageCol: ElementRef;

    constructor(
        private activatedRoute: ActivatedRoute,
        private documentsService: DocumentsService,
        private systemService: SystemService,
    ) { }
    providerLogoGuid
    providerName

    documents

    getImageWidth(width, height, newHeight) {
        var ratio = width / height;
        return ratio * newHeight;
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.documentsService.getDocumentFromToken(params.token).subscribe((response) => {
                //console.log("doc resp", response)
                if (response.success) {
                    this.documents = response.result
                } else {
                    console.log("error while fetching document", response)
                }

                this.documentsService.getProviderLogoFromToken(params.token).subscribe((response) => {
                    if (response.success) {
                        if (!response.result || response.result.length != 1) {

                        } else {
                            this.providerLogoGuid = response.result[0].company_logo
                            this.providerName = response.result[0].name
                            this.loadLogo()
                        }
                    }
                })
                this.initialized = true
            })
        })
    }

    download(doc) {
        this.documentsService.download(doc.guid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body]);
            const url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            link.download = doc.file_name;
            link.click();
        });
    }

    downloadAll() {
        for (var i = 0; i < this.documents.length; ++i) {
            this.download(this.documents[i]);
        }
    }

    loadLogo() {
        if (this.providerLogoGuid) {
            this.systemService.getProfileFile(this.providerLogoGuid).subscribe((response) => {
                var reader = new FileReader();
                reader.readAsDataURL(<any>response);
                reader.onloadend = () => {
                    var img = document.createElement('img');
                    (<any>img.src) = reader.result;
                    img.onload = function () {
                        var newHeight = 64;
                        var newWidth = this.getImageWidth(img.width, img.height, newHeight);
                        img.width = newWidth;
                        img.height = newHeight;
                        while (this.imageCol.nativeElement.firstChild) {
                            this.imageCol.nativeElement.removeChild(this.imageCol.nativeElement.firstChild);
                        }
                        this.imageCol.nativeElement.appendChild(img);
                    }.bind(this);
                }
            });
        }
    }
}
