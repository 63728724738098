import { Injectable } from '@angular/core';
import { SystemService } from './system.service';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    currentTheme;// = JSON.parse(localStorage.getItem("currentTheme"));

    constructor(
        private systemService: SystemService,
    ) {
        if (this.currentTheme == null) {
            this.currentTheme = {
                name: "currentTheme",
                theme_id: 1,
                primary_color: "#343a40",//dark blue
                text_on_primary_color: "white",
                secondary_color: "#f68e1d",//orange
                text_on_secondary_color: "white",
                tertiary_color: "white",
                text_on_tertiary_color: "black",
                background_color: "#013363",
                text_on_background_color: "white",
                link_color: "#117ac9",
            };
            // this.currentTheme = {
            //     name: "currentTheme",
            //     theme_id: 1,
            //     primary_color: "#3bbfb0",//light blue
            //     text_on_primary_color: "white",
            //     secondary_color: "#000000",
            //     text_on_secondary_color: "white",
            //     //tertiary_color: "#48637e",//dark blue
            //     tertiary_color: "white",
            //     text_on_tertiary_color: "black",
            //     background_color: "white",
            //     text_on_background_color: "black",
            //     link_color: "#117ac9",
            // };
        }
    }

    get primaryColor() {
        return this.currentTheme.primary_color;
    }

    get textOnPrimaryColor() {
        return this.currentTheme.text_on_primary_color;
    }

    get secondaryColor() {
        return this.currentTheme.secondary_color;
    }

    get textOnSecondaryColor() {
        return this.currentTheme.text_on_secondary_color;
    }

    get tertiaryColor() {
        return this.currentTheme.tertiary_color;
    }

    get textOnTertiaryColor() {
        return this.currentTheme.text_on_tertiary_color;
    }

    get backgroundColor() {
        return this.currentTheme.background_color;
    }

    get textOnBackgroundColor() {
        return this.currentTheme.text_on_background_color;
    }

    get linkColor() {
        return this.currentTheme.link_color;
    }

    // triggerTheme() {
    //     document.body.style.color = this.currentTheme.alternate_text_color;
    //     document.body.style.backgroundColor = this.currentTheme.background_color;
    //     document.body.style.backgroundImage = "";
    //     this.systemService.setFooterView("original");
    // }

    getRowBackgroundColor(i, startColor) {
        if (typeof startColor === "undefined" || startColor === null || startColor === 0) {
            startColor = 0;
        } else {
            startColor = 1;
        }

        if (startColor == 0) {
            startColor = 1;
        } else {
            startColor = 0;
        }

        var even = false;
        if (i % 2 == startColor) {
            even = true;
        }

        if (even) {
            return this.tertiaryColor;
        }
        return this.backgroundColor;
    }

    getRowTextColor(i, startColor) {
        if (typeof startColor === "undefined" || startColor === null || startColor === 0) {
            startColor = 0;
        } else {
            startColor = 1;
        }

        var even = false;
        if (i % 2 == startColor) {
            even = true;
        }

        if (even) {
            return this.textOnTertiaryColor;
        }
        return this.textOnBackgroundColor;
    }
}
