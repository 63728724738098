import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'breakingNonSpaceBeforeSlash'
})
export class BreakingNonSpaceBeforeSlashPipe implements PipeTransform {

    transform(value: any): any {
        if (!value) {
            return "";
        }
        const tempElement = document.createElement("div")
        tempElement.innerHTML = value.replace(new RegExp('/', 'g'), "&#8203;/")
        return tempElement.innerText
    }
}
