import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class WorkflowService {
    private getWorkflowsURL = 'api/getWorkflows';
    private searchWorkflowsURL = 'api/searchWorkflows';
    private assignSearcherURL = 'api/workflowAssignSearcher'
    private workflowSetStatusURL = "api/workflowSetStatus"
    private getWorkflowStatusesURL = 'api/getWorkflowStatuses'
    private getTaxMapIdFromOrderWorkflowIdURL = 'api/getTaxMapIdFromOrderWorkflowId'

    constructor(
        private http: HttpClient,
    ) { }

    search(searchCriteria, limitType) {
        return this.http.put<any>(this.searchWorkflowsURL,
            { searchCriteria: searchCriteria, limitType: limitType }, httpOptions);
    }

    getWorkflows() {
        return this.http.get<any>(this.getWorkflowsURL, httpOptions);
    }

    assignSearcher(workflow_id, searcher_id) {
        return this.http.put<any>(this.assignSearcherURL,
            { workflow_id, searcher_id }, httpOptions);
    }

    setWorkflowStatus(workflow_id, status) {
        return this.http.put<any>(this.workflowSetStatusURL,
            { workflow_id, status }, httpOptions);
    }

    getWorkflowStatuses() {
        return this.http.get<any>(this.getWorkflowStatusesURL);
    }

    getFindingTypes() {
        return this.http.get<any>("api/getFindingTypes");
    }

    getLocationTypes() {
        return this.http.get<any>("api/getLocationTypes");
    }

    getRunSheetHeaderOverride(workflowUuid): Observable<any> {
        return this.http.get<any>("api/runSheetHeaderOverride/" + workflowUuid);
    }

    getRunSheetHeaderFromOrder(orderWorkflowUuid): Observable<any> {
        return this.http.get<any>("api/runSheetHeaderFromOrder/" + orderWorkflowUuid);
    }

    saveRunSheetHeader(request) {
        return this.http.post<any>("api/saveRunSheetHeader", request, httpOptions);
    }

    saveRunSheetFindings(request) {
        return this.http.post<any>("api/workflowFindings", request, httpOptions);
    }

    getRunSheetFindings(orderWorkflowUuid): Observable<any> {
        return this.http.get<any>("api/workflowFindings/" + orderWorkflowUuid);
    }

    getManualRunSheetPDF(order_workflow_id) {
        var options: any = { observe: 'response', responseType: 'blob' };
        return this.http.get<any>("api/manualRunSheetPDF/" + order_workflow_id, options);
    }

    viewManualRunSheetPDF(orderWorkflowUuid) {
        this.getManualRunSheetPDF(orderWorkflowUuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        });
    }

    manualRunSheetUnlock(order_workflow_id) {
        return this.http.post<any>("api/runsheetUnlock", { order_workflow_id }, httpOptions);
    }

    uploadManualRunSheetPDF(order_workflow_id) {
        return this.http.put<any>("api/uploadRunSheetPDF", { order_workflow_id }, httpOptions);
    }

    updateWorkflowPriceLevel(workflowUuid, priceLevel) {
        return this.http.post<any>("api/updateWorkflowPriceLevel", { workflow_uuid: workflowUuid, price_level: priceLevel }, httpOptions);
    }

    updateWorkflowRush(workflowUuid, rush) {
        return this.http.post<any>("api/updateWorkflowRush", { workflow_uuid: workflowUuid, rush: rush }, httpOptions);
    }

    getAvailableUsersByWorkflow(workflowUuid) {
        return this.http.get<any>("api/availableUsersByWorkflow/" + workflowUuid, httpOptions);
    }
}
