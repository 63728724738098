import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SystemService {
    systemVersionsURL = "api/getSystemVersions";
    profileFileURL = "api/profileFile";
    allSecurityQuestionsURL = "api/allSecurityQuestions"
    userSecurityQuestionsURL = "api/userSecurityQuestions"
    userHasSecurityQuestionsURL = "api/userHasSecurityQuestions"
    saveUserSecurityQuestionsURL = "api/saveUserSecurityQuestions"
    userSecurityQuestionsChallengeURL = "api/userSecurityQuestionsChallenge"
    userSecurityQuestionsChallengeVerificationURL = "api/userSecurityQuestionsChallengeVerification";
    clientError = [];

    constructor(
        private http: HttpClient,
    ) {
    }

    getVersions() {
        return this.http.get<any>(this.systemVersionsURL);
    }

    getProfileFile(uuid) {
        var options: any = { responseType: 'blob' };
        return this.http.get<any>(this.profileFileURL + "/" + uuid, options);
    }

    getEnvironmentType() {
        return this.http.get<any>("api/getEnvironmentType");
    }

    getAllSecurityQuestions() {
        return this.http.get<any>(this.allSecurityQuestionsURL);
    }

    getUserSecurityQuestions() {
        return this.http.get<any>(this.userSecurityQuestionsURL);
    }

    userHasSecurityQuestions() {
        return this.http.get<any>(this.userHasSecurityQuestionsURL);
    }

    saveUserSecurityQuestions(req) {
        return this.http.post<any>(this.saveUserSecurityQuestionsURL, req);
    }

    getUserSecurityQuestionsChallenge() {
        return this.http.get<any>(this.userSecurityQuestionsChallengeURL);
    }

    userSecurityQuestionsChallengeVerification(req) {
        return this.http.post<any>(this.userSecurityQuestionsChallengeVerificationURL, req);
    }

    getEmailTemplate(templateName) {
        return this.http.get<any>("api/emailTemplate/" + templateName);
    }

    getInactivityTimeout() {
        return this.http.get<any>("api/inactivityTimeoutInSeconds");
    }
}
