import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { iAgentOrder } from "../model/agent-order.model";

@Injectable({
    providedIn: 'root'
})

export class OrdersService
{
    constructor(private http: HttpClient) 
    { 
        // Nothing to do
    }

    getOrdersbyAgentId(agentId: string): Observable<iAgentOrder[]>
    {
        return this.http.get<iAgentOrder[]>('api/ordersByAgentId/' + agentId)
    }

    getAdditionalCostOptions(pid: string)
    {
        return this.http.get<any>("api/additionalCostOption/" + pid)
    }
}