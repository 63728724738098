<span *ngIf="!initialized">
    <div class="container-fluid mt-4 rounded-ninety">
        Initializing...
    </div>
</span>
<div *ngIf="initialized" class="container-fluid py-2 rounded-ninety">

    <div class="row edit-height">
        <div class="col pb-2" style="font-size: 1.6em;">
            Enter Search Criteria
        </div>
    </div>
    <div class="row edit-height">
        <div class="col pb-2">
            Find orders that match <select class="input-style" style="width: unset;" [formControl]="allAnyFormControl">
                <option>all</option>
                <option>any</option>
            </select> of the following criteria:
        </div>
    </div>
    <div *ngFor="let criteria of currentSearchCriteria; let i = index;" class="row edit-height">
        <div class="col-1 pb-2">
        </div>
        <div class="col-3 pb-2">
            <select [formControl]="criteria.searchFieldControlIndex" class="input-style" (change)="fieldChange(i, hasValues(searchFieldControls[criteria.searchFieldControlIndex.value]))">
                <option *ngFor="let field of searchFieldControls; let i = index" [value]="i">
                    {{field.name}}
                </option>
            </select>
        </div>
        <div class="col-3 pb-2">
            <select *ngIf="hasValues(searchFieldControls[criteria.searchFieldControlIndex.value])" class="input-style"
                [formControl]="criteria.searchTypeIndex">
                <option *ngIf="!searchFieldControls[criteria.searchFieldControlIndex.value].suppressIs" selected
                    value="0">
                    is
                </option>
                <option value="1">
                    is not
                </option>
            </select>
            <select *ngIf="!hasValues(searchFieldControls[criteria.searchFieldControlIndex.value])" class="input-style"
                [formControl]="criteria.searchTypeIndex">
                <option *ngIf="!searchFieldControls[criteria.searchFieldControlIndex.value].suppressIs" value="0">
                    is
                </option>
                <option value="1">
                    contains
                </option>
            </select>
        </div>
        <div class="col-3 pb-2">
            <input *ngIf="!hasValues(searchFieldControls[criteria.searchFieldControlIndex.value])" type="text"
                class="input-style" [formControl]="criteria.matchValue" />
            <select *ngIf="hasValues(searchFieldControls[criteria.searchFieldControlIndex.value])" class="input-style"
                [formControl]="criteria.matchValueIndex">
                <option
                    *ngFor="let val of getValues(searchFieldControls[criteria.searchFieldControlIndex.value]); let i = index;"
                    [value]="i">
                    {{val.name}}
                </option>
            </select>
        </div>
        <div class="col-2 pb-2">
            <fa-icon [icon]="faMinus" (click)="removeSearchCriteria(i)" class="pointer" class="pointer" title="remove">
            </fa-icon>

            <fa-icon class="ml-1" [icon]="faPlus" (click)="addSearchCriteria(i)" class="pointer" title="add">
            </fa-icon>
        </div>
    </div>
    <div class="row edit-height">
        <div class="col pb-2" align="center">
            <app-button label="Search" [clickFunction]="searchButtonClick.bind(this)" [icon]="faSearch">
            </app-button>
            <app-button label="Clear" [clickFunction]="doClear.bind(this)" [icon]="faBan">
            </app-button>
        </div>
    </div>
</div>

<div *ngIf="searchResults" class="container-fluid">
    <app-dashboard-controls [title]="'Search Results'" [pageNumber]="currentPage" [pageSize]="searchResultLimit"
        [entryCount]="searchResultCount" [setPage]="setPage.bind(this)">
    </app-dashboard-controls>
</div>
<div *ngIf="searchResults" class="container-fluid search-result-container rounded-ninety">

    <div class="row">
        <div class="col px-0">
            <table class="table table-striped">
                <thead>
                    <tr class="table-header">
                        <td *ngFor="let columnHeader of columnHeaders" (click)="doSort(columnHeader.sortField)"
                            class="py-4 pointer nowrap">
                            {{columnHeader.name}}<span *ngIf="sort == columnHeader.sortField">
                                <span *ngIf="sortDirection == 1">
                                    <fa-icon [icon]="faCaretDown" size="1x" title="sorted ascending"></fa-icon>
                                </span>
                                <span *ngIf="sortDirection == 0">
                                    <fa-icon [icon]="faCaretUp" size="1x" title="sorted descending"></fa-icon>
                                </span>
                            </span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" *ngFor="let result of searchResults; let j = index; let isLastOrder = last">
                        <td>
                            <a [routerLink]="result.order_status == 'R' ? '/order-create' : '/searcher-order-edit'"
                                [queryParams]="{orderUuid: result.uuid}">{{result.order_id}}</a>
                        </td>
                        <td>
                            {{getSearchTypeDisplay(result)}}
                        </td>
                        <td>
                            <span class="status-badge" [ngClass]="['order-status-' + result.order_status ]">
                                {{orderStatusNameById[result.order_status] | uppercase}}
                            </span>
                        </td>
                        <td>
                            {{result.property_seller}}
                        </td>
                        <td>
                            {{result.address}}
                        </td>
                        <td>
                            <a class="bold" routerLink="/order-assessment-info"
                                [queryParams]="{ oid: result.uuid }">{{result.sbl}}</a>
                        </td>
                        <td>
                            {{result.client_company_name}}
                        </td>
                        <td>
                            {{result.county}}
                        </td>
                        <td>
                            {{result.reference_number}}
                        </td>
                        <td>
                            {{result.file_number}}
                        </td>
                        <td>
                            {{result.property_buyer}}
                        </td>
                        <td>
                            {{result.assessed_owner}}
                        </td>
                        <td>
                            {{result.submission_date | date: 'M/d/yy'}}
                        </td>
                        <td>
                            {{result.order_complete_date | date: 'M/d/yy'}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div *ngIf="searchResults" class="container-fluid mt-4 py-2">
    <app-dashboard-controls [title]="'Search Results'" [pageNumber]="currentPage" [pageSize]="searchResultLimit"
        [entryCount]="searchResultCount" [setPage]="setPage.bind(this)" [isFooter]="true">
    </app-dashboard-controls>
</div>