import { Component, OnInit } from '@angular/core';
import { SystemService } from '../system.service';
import { ThemeService } from '../theme.service';

@Component({
    selector: 'app-system-error',
    templateUrl: './system-error.component.html',
})
export class SystemErrorComponent implements OnInit {

    constructor(
        private _themeService: ThemeService,
        private _systemService: SystemService,
    ) { }

    ngOnInit() {
    }

    get themeService() {
        return this._themeService;
    }

    get systemService() {
        return this._systemService;
    }

}
