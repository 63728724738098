import { Component, Inject, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SYSMUNICIPALITY } from '../mock/sys-municipality-mock';
import { SysMunicipality } from '../model/sys-municipality.model';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MunicipalitySeviceMock } from '../services/municipality.service.mock';
import { SysTaxType } from '../model/sys-tax-type.model';
import { MunicipalitySevice } from '../services/municipality.service';

@Component({
  selector: 'app-sys-municipality',
  templateUrl: './sys-municipality.component.html',
  styleUrls: ['./sys-municipality.component.css']
})
export class SysMunicipalityComponent implements AfterViewInit
{

  //data used to populate the table
  ELEMENT_DATA = SYSMUNICIPALITY

  municipalityDataSource: MatTableDataSource<SysMunicipality>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = 
  [
    'state',
    'county',
    'municipalityName',
    'type',
    'code',
    'taxType',
    'address',
    'taxYear',
    'dueDate1',
    'dueDate2',
    'dueDate3',
    'dueDate4',
    'refreshDate'
  ]

  constructor(
    private municipalityServiceMock: MunicipalitySeviceMock,
    private municipalityService: MunicipalitySevice,
    public dialog: MatDialog
  ) 
  { 
    this.municipalityDataSource = new MatTableDataSource([{}])
    this.loadTableData();
  
  }

  private loadTableData(): void
  {
    this.municipalityService.getMunicipalities().subscribe(response => {
      this.municipalityDataSource.data = response.results
      // console.log(this.municipalityDataSource.data)
    });
  }

  ngAfterViewInit(): void
  {
    this.municipalityDataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase();
    this.municipalityDataSource.filterPredicate = (data: any, filter: string) => {
    const columnValue = data.municipalityName.toLowerCase();
    return columnValue.includes(filter);
    };
    this.municipalityDataSource.filter = filterValue;
  }

  openDialog(municipality: SysMunicipality): void {
  
    const dialogRef = this.dialog.open(EditMunicipalityDialog, {
      width: '480px',
      data: municipality
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed, having data: ', JSON.stringify(result));
      this.loadTableData();
    });
  }
}

@Component({
  selector: 'edit-municipality-dialog',
  templateUrl: 'edit-municipality-dialog.html',
  styleUrls: ['./sys-municipality.component.css']
})
export class EditMunicipalityDialog implements OnInit
{
  taxType: SysTaxType[]

  form = new UntypedFormGroup({
    state: new UntypedFormControl({value: this.data.state, disabled: true}, [Validators.required]),
    countyName: new UntypedFormControl({value: this.data.countyName, disabled: true}, [Validators.required]),
    municipalityName: new UntypedFormControl({value: this.data.municipalityName, disabled: true}, [Validators.required]),
    type: new UntypedFormControl({value: this.data.type, disabled: true}, [Validators.required]),
    parentId: new UntypedFormControl(this.data.parentId),
    childId: new UntypedFormControl(this.data.childId),
    code: new UntypedFormControl({value: this.data.realPropertyCode, disabled: true}),
    taxType: new UntypedFormControl(this.data.taxTypeId),
    address: new UntypedFormControl(this.data.address, [Validators.required]),
    taxYear: new UntypedFormControl({value: this.data.taxYear, disabled: true}),
    taxPeriod: new UntypedFormControl(this.data.taxPeriod),
    lienDate: new UntypedFormControl({value: this.data.lienDate, disabled: true}),
    fiscalStartDate: new UntypedFormControl(this.data.fiscalStartDate),
    fiscalEndDate: new UntypedFormControl(this.data.fiscalEndDate),
    dueDate1: new UntypedFormControl(this.data.dueDate1, [Validators.required]),
    dueDate2: new UntypedFormControl(this.data.dueDate2),
    dueDate3: new UntypedFormControl(this.data.dueDate3),
    dueDate4: new UntypedFormControl(this.data.dueDate4),
    refreshDate: new UntypedFormControl(this.data.refreshDate, [Validators.required])
  })

  constructor
  (
    public dialogRef: MatDialogRef<EditMunicipalityDialog>,
    private municipalityServiceMock: MunicipalitySeviceMock,
    private municipalityService: MunicipalitySevice,
    @Inject(MAT_DIALOG_DATA) public data: SysMunicipality
  ) 
  {
    //console.log('Dialog Data: ', JSON.stringify(data));
    this.taxType = []
    this.loadTaxTypes();
  }

  private loadTaxTypes(): void 
  {
    this.municipalityService.getTaxTypes().subscribe(response => {
      this.taxType = response.results
      console.log(this.taxType)
    });
  }
  
  calculateTaxYearAndLienDate(date1: Date, date2: Date) 
  {
    //i had to change the way we declare the variables date1 and date2
    //declaring them top level in the is function didnt work
    //if one was null it would still be given a default date of 01/01/1970
    //this is jut the way js handles null dates
    
    let finalYear: string
    let finalDayAndMonth: string
    //extract month, day, and year from date
    if(date2 != null && date1 != null)
    {
      date1 = new Date(date1);
      date2 = new Date(date2);
      let date1Month = date1.getMonth() + 1;
      let date1Day = date1.getDate().toString();
      let date1Year = date1.getFullYear().toString();
      let date2Month = date2.getMonth() + 1;
      let date2Day = date2.getDate().toString();
      let date2Year = date2.getFullYear().toString();
      //concat month and day
      finalDayAndMonth = date1Month.toString() + '/' + date1Day + '-' + date2Month.toString() + '/' + date2Day;
      this.form.get('lienDate').setValue(finalDayAndMonth);

        //if the years are not the same return a range
        if(date1Year != date2Year)
        {
          finalYear = date1Year + '/' + date2Year;
          this.form.get('taxYear').setValue(finalYear)
        }
        //if the years are the same return a single year
        else if(date1Year == date2Year)
        {
          finalYear = date1.getFullYear().toString();
          this.form.get('taxYear').setValue(finalYear)
        }
    }
    else if(date2 == null && date1 != null)
    {
      date1 = new Date(date1);
      let date1Month = date1.getMonth() + 1;
      let date1Day = date1.getDate().toString();
      let date1Year = date1.getFullYear().toString();
      finalYear = date1Year;
      this.form.get('taxYear').setValue(finalYear)

      finalDayAndMonth = date1Month.toString() + '/' + date1Day;
      this.form.get('lienDate').setValue(finalDayAndMonth);
    }
    else if(date2 != null && date1 == null)
    {
      date2 = new Date(date2);
      let date2Month = date2.getMonth() + 1;
      let date2Day = date2.getDate().toString();
      let date2Year = date2.getFullYear().toString();
      finalYear = date2Year;
      this.form.get('taxYear').setValue(finalYear)

      finalDayAndMonth = date2Month.toString() + '/' + date2Day;
      this.form.get('lienDate').setValue(finalDayAndMonth);
    }
    else if (date2 == null && date1 == null)
    {
      finalYear = new Date().getFullYear().toString();
      this.form.get('taxYear').setValue(finalYear)
      finalDayAndMonth = '01/01-12/31';
      this.form.get('lienDate').setValue(finalDayAndMonth);
    }
    else
    {
    console.log('Something went wrong')
    }
  }
  

  onSave(): void
  {
    if(this.form.valid)
    {
      this.data.state = this.form.get("state").value;
      this.data.countyName = this.form.get('countyName').value;
      this.data.municipalityName = this.form.get('municipalityName').value;
      this.data.type = this.form.get('type').value;
      this.data.parentId = this.form.get('parentId').value;
      this.data.childId = this.form.get('childId').value;
      this.data.realPropertyCode = this.form.get('code').value;
      this.data.taxTypeId = this.form.get('taxType').value;
      this.data.address = this.form.get('address').value;
      this.data.taxYear = this.form.get('taxYear').value;
      this.data.taxPeriod = this.form.get('taxPeriod').value;
      this.data.lienDate = this.form.get('lienDate').value;
      this.data.fiscalStartDate = this.form.get('fiscalStartDate').value;
      this.data.fiscalEndDate = this.form.get('fiscalEndDate').value;
      this.data.dueDate1 = this.form.get('dueDate1').value;
      this.data.dueDate2 = this.form.get('dueDate2').value;
      this.data.dueDate3 = this.form.get('dueDate3').value;
      this.data.dueDate4 = this.form.get('dueDate4').value;
      this.data.refreshDate = this.form.get('refreshDate').value;
      
      this.municipalityService.updateMunicipality(this.data).subscribe(response => {
          console.log(response)
          // next: (result) => {
          //   console.log(`Update result: ${this.data}`)
          // },
          // error: (error) => {
          //   console.log(error);
          // }
      });
      this.dialogRef.close(this.data);
    }
    else
    {
      this.form.markAllAsTouched();
      return;
    }
  }

  ngOnInit(): void {


    //watch for changes in fisacal start
    this.form.get('fiscalStartDate').valueChanges.subscribe(date1 => {
      let date2 = this.form.get('fiscalEndDate').value;

      this.calculateTaxYearAndLienDate(date1, date2)
    });

    //watch for changes in fiscal end
    this.form.get('fiscalEndDate').valueChanges.subscribe(date2 => {
      let date1 = this.form.get('fiscalStartDate').value;

      this.calculateTaxYearAndLienDate(date1, date2)
    });
  }
}