import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from './header.interceptor';
import { UserListComponent } from './user-list/user-list.component';
import { BreakingNonSpaceBeforeSlashPipe } from './breaking-non-space-before-slash.pipe';
import { BreakingNonSpaceModPipe } from './breaking-non-space-mod.pipe';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { GlobalErrorHandler } from './global-error-handler';
import { AttributeNameDisplayPipe } from './attribute-name-display.pipe';
import { CompanySettingsComponent } from './company-settings/company-settings.component';
import { AboutComponent } from './about/about.component';
import { NewlineBreakPipe } from './newline-break.pipe';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { OrderCreateComponent } from './order-create/order-create.component';
import { OrderCreateStepCirclesComponent } from './order-create-step-circles/order-create-step-circles.component';
import { OrderServicesPropertyComponent } from './order-services-property/order-services-property.component';
import { OrderServicesEntityComponent } from './order-services-entity/order-services-entity.component';
import { OrderServicesIndividualComponent } from './order-services-individual/order-services-individual.component';
import { OrderNotificationsComponent } from './order-notifications/order-notifications.component';
import { OrderDocumentsComponent } from './order-documents/order-documents.component';
import { OrderReviewComponent } from './order-review/order-review.component';
import { OrderCreateButtonsComponent } from './order-create-buttons/order-create-buttons.component';
import { InputTextComponent } from './input-text/input-text.component';
import { InputTextListComponent } from './input-text-list/input-text-list.component';
import { InputEnumComponent } from './input-enum/input-enum.component';
import { InputSearchComponent } from './input-search/input-search.component';
import { InputDateComponent } from './input-date/input-date.component';
import { InputTextAreaComponent } from './input-text-area/input-text-area.component';
import { InputEnumListComponent } from './input-enum-list/input-enum-list.component';
import { AccountCreateComponent } from './account-create/account-create.component';
import { AccountChoosePlanComponent } from './account-choose-plan/account-choose-plan.component';
import { AccountReviewComponent } from './account-review/account-review.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { ResearcherToolsComponent } from './researcher-tools/researcher-tools.component';
import { ResearcherToolsAdvancedComponent } from './researcher-tools-advanced/researcher-tools-advanced.component';
import { NumberFormatPipe } from './number-format.pipe';
import { InputTextAndEnumComponent } from './input-text-and-enum/input-text-and-enum.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ResearcherToolsResultsComponent } from './researcher-tools-results/researcher-tools-results.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SearcherDashboardComponent, SearcherDashboardStateDialog } from './searcher-dashboard/searcher-dashboard.component';
import { ProviderDashboardComponent, ProviderDashboardStateDialog } from './provider-dashboard/provider-dashboard.component';
import { ClientSearchComponent } from './client-search/client-search.component';
import { ForgotPasswordResetComponent } from './forgot-password-reset/forgot-password-reset.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { FileSizePipe } from './file-size.pipe';
import { InputImageComponent } from './input-image/input-image.component';
import { SystemErrorComponent } from './system-error/system-error.component';
import { AccountChooseCompanyTypeComponent } from './account-choose-company-type/account-choose-company-type.component';
import { InitialPageComponent } from './initial-page/initial-page.component';
import { SecurityQuestionsSetComponent } from './security-questions-set/security-questions-set.component';
import { SecurityQuestionsChallengeComponent } from './security-questions-challenge/security-questions-challenge.component';
import { AccountStepCirclesComponent } from './account-step-circles/account-step-circles.component';
import { OrderSheetHtmlComponent } from './order-sheet-html/order-sheet-html.component';
import { OrderAssessmentInfoComponent } from './order-assessment-info/order-assessment-info.component';
import { SearcherOrderDocumentsComponent } from './searcher-order-documents/searcher-order-documents.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SearcherOrderEditComponent, DialogOverviewExampleDialog } from './searcher-order-edit/searcher-order-edit.component';
import { SearcherOrderEditSblComponent } from './searcher-order-edit-sbl/searcher-order-edit-sbl.component';
import { MatDialogModule } from '@angular/material/dialog';
import { InvoiceEditComponent } from './invoice-edit/invoice-edit.component';
import { InvoiceViewComponent } from './invoice-view/invoice-view.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoiceReportComponent } from './invoice-report/invoice-report.component';
import { InvoiceConfigureComponent } from './invoice-configure/invoice-configure.component';
import { InputCheckboxComponent } from './input-checkbox/input-checkbox.component';
import { InvoiceHistoryComponent } from './invoice-history/invoice-history.component';
import { ProviderPortalComponent } from './provider-portal/provider-portal.component';
import { DashboardSearchMyComponent } from './dashboard-search-my/dashboard-search-my.component';
import { DashboardSearchAllOpenComponent } from './dashboard-search-all-open/dashboard-search-all-open.component';
import { DashboardOrderAllOpenComponent } from './dashboard-order-all-open/dashboard-order-all-open.component';
import { DashboardOrderOverdueComponent } from './dashboard-order-overdue/dashboard-order-overdue.component';
import { DashboardOrderOnHoldComponent } from './dashboard-order-on-hold/dashboard-order-on-hold.component';
import { DashboardSearchOverdueComponent } from './dashboard-search-overdue/dashboard-search-overdue.component';
import { RunSheetHtmlComponent } from './run-sheet-html/run-sheet-html.component';
import { TaxSearchComponent } from './tax-search/tax-search.component';
import { DashboardOrderToBeInvoicedComponent } from './dashboard-order-to-be-invoiced/dashboard-order-to-be-invoiced.component';
import { RunSheetDataEntryComponent } from './run-sheet-data-entry/run-sheet-data-entry.component';
import { InputEnumAndTextComponent } from './input-enum-and-text/input-enum-and-text.component';
import { DashboardControlsComponent } from './dashboard-controls/dashboard-controls.component';
import { DashboardControlsButtonComponent } from './dashboard-controls-button/dashboard-controls-button.component';
import { StatusIndicatorComponent } from './status-indicator/status-indicator.component';
import { DashboardSearchCompletedComponent } from './dashboard-search-completed/dashboard-search-completed.component';
import { DocumentDownloadComponent } from './document-download/document-download.component';
import { UserFeedbackComponent } from './user-feedback/user-feedback.component';
import { UserFeedbackConfirmationComponent } from './user-feedback-confirmation/user-feedback-confirmation.component';
import { QualiaDashboardComponent } from './qualia-dashboard/qualia-dashboard.component';
import { InputDropdownButtonsComponent } from './input-dropdown-buttons/input-dropdown-buttons.component';
import { WorkflowActionDropdownComponent, WorkflowActionDropdownStateDialog } from './workflow-action-dropdown/workflow-action-dropdown.component';
import { AgentDashboardComponent } from './agent-dashboard/agent-dashboard.component';
import { DashboardOrderCancelledComponent } from './dashboard-order-cancelled/dashboard-order-cancelled.component';
import { CustomerCompanyComponent } from './customer-company/customer-company.component';
import { UtilityReportComponent } from './utility-report/utility-report.component';
import { DashboardSearchPausedComponent } from './dashboard-search-paused/dashboard-search-paused.component';
import { DocumentSearchComponent } from './document-search/document-search.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { ProductsComponent } from './products/products.component';
import { ClientsComponent } from './clients/clients.component';
import { VendorsComponent } from './vendors/vendors.component';
import { ContactsComponent } from './contacts/contacts.component';
import { VendorComponent } from './vendor/vendor.component';
import { ClientComponent } from './client/client.component';
import { ContactComponent } from './contact/contact.component';
import { ProductComponent } from './product/product.component';
import { DashboardSearchTodoComponent } from './dashboard-search-todo/dashboard-search-todo.component';
import { DashboardSearchInProgressComponent } from './dashboard-search-in-progress/dashboard-search-in-progress.component';
import { DashboardSearchTypingComponent } from './dashboard-search-typing/dashboard-search-typing.component';
import { DashboardSearchWaitingComponent } from './dashboard-search-waiting/dashboard-search-waiting.component';
import { DashboardOrderNewComponent } from './dashboard-order-new/dashboard-order-new.component';
import { DashboardOrderInProgressComponent } from './dashboard-order-in-progress/dashboard-order-in-progress.component';
import { DashboardOrderCompletedComponent } from './dashboard-order-completed/dashboard-order-completed.component';
import { SearchSearchComponent } from './search-search/search-search.component';
import { OverdueReportComponent } from './overdue-report/overdue-report.component';
import { SearchSummaryComponent } from './search-summary/search-summary.component';
import { ArReportComponent } from './ar-report/ar-report.component';
import { InvoiceOpenComponent } from './invoice-open/invoice-open.component';
import { InvoiceAllComponent } from './invoice-all/invoice-all.component';
import { InputFiveStarRatingComponent } from './input-five-star-rating/input-five-star-rating.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { BannerNotificationComponent } from './banner-notification/banner-notification.component';
import { TeamComponent } from './team/team.component';
import { TeamsComponent } from './teams/teams.component';
import { BannerMessageComponent } from './banner-message/banner-message.component';
import { InputCurrencyComponent } from './input-currency/input-currency.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { InputUserRoleComponent } from './input-user-role/input-user-role.component';
import { UserResetPasswordComponent } from './user-reset-password/user-reset-password.component';
import { UserComponent } from './user/user.component';
import { DbtitlecasePipe } from './dbtitlecase.pipe';
import { ButtonDemoComponent } from './button-demo/button-demo.component';
import { ButtonGroupCreateComponent } from './button-group/button-group-create/button-group-create.component';
import { ButtonGroupSaveComponent } from './button-group/button-group-save/button-group-save.component';
import { ButtonGroupWizardFirstComponent } from './button-group/button-group-wizard-first/button-group-wizard-first.component';
import { ButtonGroupWizardInterimComponent } from './button-group/button-group-wizard-interim/button-group-wizard-interim.component';
import { ButtonGroupWizardFinalComponent } from './button-group/button-group-wizard-final/button-group-wizard-final.component';
import { ButtonComponent } from './button/button.component';
import { TaxProfileEditComponent } from './tax-profile-edit/tax-profile-edit.component';
import { AgentOrderDownloadComponent } from './agent-order-download/agent-order-download.component';
import { AgentMenuComponent } from './agent-menu/agent-menu.component';
import { SysCountyComponent, EditCountyDialog } from './sys-county/sys-county.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { SysMunicipalityComponent, EditMunicipalityDialog } from './sys-municipality/sys-municipality.component';
import { SysSchoolComponent, EditSchoolDialog } from './sys-school/sys-school.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { OrderDownloadMatComponent } from './agent/order-download-mat/order-download-mat.component';
import { AgentDashboardMatComponent } from './agent/agent-dashboard-mat/agent-dashboard-mat.component';
import { DocumentTableComponent } from './document-table/document-table.component';
import { InternalNotesComponent } from './internal-notes/internal-notes.component';
import { AdditionalCostsComponent } from './additional-costs/additional-costs.component';

// Prime-ng Imports
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { InputGroupModule } from 'primeng/inputgroup';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        UserListComponent,
        BreakingNonSpaceBeforeSlashPipe,
        BreakingNonSpaceModPipe,
        AttributeNameDisplayPipe,
        CompanySettingsComponent,
        AboutComponent,
        NewlineBreakPipe,
        ForgotPasswordComponent,
        OrderCreateComponent,
        OrderCreateStepCirclesComponent,
        OrderServicesPropertyComponent,
        OrderServicesEntityComponent,
        OrderServicesIndividualComponent,
        OrderNotificationsComponent,
        OrderDocumentsComponent,
        OrderReviewComponent,
        OrderCreateButtonsComponent,
        InputTextComponent,
        InputTextListComponent,
        InputEnumComponent,
        InputSearchComponent,
        InputDateComponent,
        InputTextAreaComponent,
        InputEnumListComponent,
        AccountCreateComponent,
        AccountChoosePlanComponent,
        AccountReviewComponent,
        InputPasswordComponent,
        ResearcherToolsComponent,
        ResearcherToolsAdvancedComponent,
        NumberFormatPipe,
        InputTextAndEnumComponent,
        WelcomeComponent,
        ResearcherToolsResultsComponent,
        ContactUsComponent,
        TermsOfUseComponent,
        PrivacyPolicyComponent,
        SearcherDashboardComponent,
        ProviderDashboardComponent,
        ClientSearchComponent,
        ForgotPasswordResetComponent,
        VerifyEmailComponent,
        FileSizePipe,
        InputImageComponent,
        SystemErrorComponent,
        AccountChooseCompanyTypeComponent,
        InitialPageComponent,
        SecurityQuestionsSetComponent,
        SecurityQuestionsChallengeComponent,
        AccountStepCirclesComponent,
        OrderSheetHtmlComponent,
        OrderAssessmentInfoComponent,
        SearcherOrderDocumentsComponent,
        SearcherOrderEditComponent,
        SearcherOrderEditSblComponent,
        DialogOverviewExampleDialog,
        SearcherDashboardStateDialog,
        ProviderDashboardStateDialog,
        InvoiceEditComponent,
        InvoiceViewComponent,
        InvoiceListComponent,
        InvoiceReportComponent,
        InvoiceConfigureComponent,
        InputCheckboxComponent,
        InvoiceHistoryComponent,
        ProviderPortalComponent,
        DashboardSearchMyComponent,
        DashboardSearchAllOpenComponent,
        DashboardOrderAllOpenComponent,
        DashboardOrderOverdueComponent,
        DashboardOrderOnHoldComponent,
        DashboardSearchOverdueComponent,
        RunSheetHtmlComponent,
        TaxSearchComponent,
        DashboardOrderToBeInvoicedComponent,
        RunSheetDataEntryComponent,
        InputEnumAndTextComponent,
        DashboardControlsComponent,
        DashboardControlsButtonComponent,
        StatusIndicatorComponent,
        DashboardSearchCompletedComponent,
        DocumentDownloadComponent,
        UserFeedbackComponent,
        UserFeedbackConfirmationComponent,
        QualiaDashboardComponent,
        InputDropdownButtonsComponent,
        WorkflowActionDropdownComponent,
        WorkflowActionDropdownStateDialog,
        AgentDashboardComponent,
        DashboardOrderCancelledComponent,
        CustomerCompanyComponent,
        UtilityReportComponent,
        DashboardSearchPausedComponent,
        DocumentSearchComponent,
        MainMenuComponent,
        ProductsComponent,
        ClientsComponent,
        VendorsComponent,
        ContactsComponent,
        VendorComponent,
        ClientComponent,
        ContactComponent,
        ProductComponent,
        DashboardSearchTodoComponent,
        DashboardSearchInProgressComponent,
        DashboardSearchTypingComponent,
        DashboardSearchWaitingComponent,
        DashboardOrderNewComponent,
        DashboardOrderInProgressComponent,
        DashboardOrderCompletedComponent,
        SearchSearchComponent,
        OverdueReportComponent,
        SearchSummaryComponent,
        ArReportComponent,
        InvoiceOpenComponent,
        InvoiceAllComponent,
        InputFiveStarRatingComponent,
        DeleteConfirmationComponent,
        BannerNotificationComponent,
        TeamComponent,
        TeamsComponent,
        BannerMessageComponent,
        InputCurrencyComponent,
        InputNumberComponent,
        InputUserRoleComponent,
        UserResetPasswordComponent,
        UserComponent,
        DbtitlecasePipe,
        ButtonDemoComponent,
        ButtonGroupCreateComponent,
        ButtonGroupSaveComponent,
        ButtonGroupWizardFirstComponent,
        ButtonGroupWizardInterimComponent,
        ButtonGroupWizardFinalComponent,
        ButtonComponent,
        TaxProfileEditComponent,
        AgentOrderDownloadComponent,
        AgentMenuComponent,
        SysCountyComponent,
        EditCountyDialog,
        SysMunicipalityComponent,
        EditMunicipalityDialog,
        SysSchoolComponent,
        EditSchoolDialog,
        DatePickerComponent,
        OrderDownloadMatComponent,
        AgentDashboardMatComponent,
        DocumentTableComponent,
        InternalNotesComponent,
        AdditionalCostsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        MatDialogModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatOptionModule,
        MatSelectModule,
        MatPaginatorModule,
        MatSortModule,
        MatSlideToggleModule,
        TableModule,
        ButtonModule,
        ConfirmDialogModule,
        DialogModule,
        ToastModule,
        InputGroupModule,
        InputTextModule,
        InputTextareaModule,
        CardModule,
        DropdownModule
    ],
    providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HeaderInterceptor,
          multi: true
        },
        TitleCasePipe, 
        NumberFormatPipe,
        DatePipe,
        {
          provide: ErrorHandler,
          useClass: GlobalErrorHandler
        },
        ConfirmationService, 
        MessageService       
      ],
    bootstrap: [AppComponent]
})
export class AppModule { }
