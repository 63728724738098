import { Component, Input, OnInit } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ButtonType } from 'src/app/button/button.component';

@Component({
    selector: 'app-button-group-wizard-final',
    templateUrl: './button-group-wizard-final.component.html',
})
export class ButtonGroupWizardFinalComponent implements OnInit {
    ButtonType = ButtonType
    faChevronLeft = faChevronLeft
    @Input() cancel
    @Input() save
    @Input() previous
    constructor() { }

    ngOnInit() {
    }

}
