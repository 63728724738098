<span *ngIf="!initialized">
    <div class="container-fluid mt-4 search-panel">
        Initializing...
    </div>
</span>

<div *ngIf="initialized" class="container-fluid mt-4 rounded-ninety">

    <div class="row">
        <div class="col-8 welcome-intro">
            <div class="row">
                <div class="col pb-2 pt-1 bold" style="font-size: 2em;">
                    Welcome!
                </div>
            </div>
            <div class="row">
                <div class="col pb-2">
                    Our robust search feature allows you to quickly obtain real property information for New York State
                    parcels. This information includes:
                </div>
            </div>
            <div class="row">
                <div class="col pb-2">
                    <fa-icon [icon]="faCheck" style="vertical-align: text-top;" class="mx-2"></fa-icon>General
                    Property<br />
                    <fa-icon [icon]="faCheck" style="vertical-align: text-top;" class="mx-2"></fa-icon>Information
                    (Section Block Lot, Owner, Municipality, etc.)<br />
                    <fa-icon [icon]="faCheck" style="vertical-align: text-top;" class="mx-2"></fa-icon>Tax and
                    Assessment Data<br />
                    <fa-icon [icon]="faCheck" style="vertical-align: text-top;" class="mx-2"></fa-icon>Property
                    Details (year built, square footage, number of rooms, etc.)<br />
                    <fa-icon [icon]="faCheck" style="vertical-align: text-top;" class="mx-2"></fa-icon>Tax map<br />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="col mt-3 bold">
                        <app-button label="SEARCH" [clickFunction]="doSearch.bind(this)" [icon]="faSearch">
                        </app-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-4 pl-4 pr-0">
            <div class="welcome-news px-3">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col pb-2 bold" style="font-size: 1.6em;">
                                News
                            </div>
                        </div>
                        <div class="row">
                            <div class="col bold">
                                9/17/19
                            </div>
                        </div>
                        <div class="row">
                            <div class="col pb-2">
                                We have enhanced our search results to include Heat Type, Number of Rooms, and Property
                                Improvements!
                            </div>
                        </div>
                        <div class="row">
                            <div class="col bold">
                                8/2/19
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                2 counties have been updated with new data!
                            </div>
                        </div>
                        <div class="row">
                            <div class="col pb-2 ml-4">
                                &#8226; Orleans <div class="ml-2" style="display: inline-block;"></div> &#8226; Clinton
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="welcome-news pl-3 mt-4">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col pb-2 bold" style="font-size: 1.6em;">
                                Useful Links
                            </div>
                        </div>
                        <div class="row">
                            <div class="col pb-2">
                                <fa-icon [icon]="faLink" style="vertical-align: text-top;" class="mx-2"></fa-icon><a
                                    href="https://www.sdgnys.com/project/image-mate-online/" target="_blank">Image
                                    Mate</a><br />
                                <fa-icon [icon]="faLink" style="vertical-align: text-top;" class="mx-2"></fa-icon><a
                                    href="http://www.appleminer.com/" target="_blank">Apple Miner</a><br />
                                <fa-icon [icon]="faLink" style="vertical-align: text-top;" class="mx-2"></fa-icon>
                                <a href="http://taxlookup.net/" target="_blank">TaxLookup.net</a><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4 py-3 table-header">
        <div class="col-3" align="center">
            County
        </div>
        <div class="col-3" align="center">
            Updated as of
        </div>
        <div class="col-3" align="center">
            Link to Tax Information
        </div>
        <div class="col-3" align="center">
            Link to Parcel Information
        </div>
    </div>
    <span class="container-striped-alt" *ngIf="countyData">
        <div class="row py-3 border-bottom table-row"
            *ngFor="let county of countyData; let even = even; let first = first; let last=last;">
            <div class="col-3" align="center">
                {{county.name}}
            </div>
            <div class="col-3" align="center">
                {{county.effective_date | date : 'M/d/y'}}
            </div>
            <div class="col-3">
            </div>
            <div class="col-3" align="center">
                <span *ngIf="county.parcelAvailability">
                    <fa-icon [icon]="faCheck" size="1x"></fa-icon>
                </span>
            </div>
        </div>
    </span>
    <div class="row pb-2">
        <div class="col-3">
        </div>
    </div>
</div>