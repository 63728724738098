import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
//import { SettingsService } from './settings.service';
import { ThemeService } from './theme.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(
        private router: Router,
        private themeService: ThemeService,
        private authService: AuthService,
    ) { }

    private localLogin() {
        this.router.navigate(['/login']);
    }

    failure(state) {
        this.localLogin();
    }

    success() {
        //this.themeService.triggerTheme();
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.hasValidAuthenticationToken()) {
            return true;
        } else {
            this.localLogin();
        }
    }
}
