<span *ngIf="!initialized">
    <div class="container-fluid mt-4 rounded-ninety" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor">
        Initializing...
    </div>
</span>

<div *ngIf="initialized" class="container-fluid mt-4 rounded-ninety" [style.color]="themeService.primaryColor"
    [style.background-color]="themeService.tertiaryColor">

    <div class="row pb-1">
        <div class="col unedit-padding pt-4 pb-3 attribute-h1 bold" align="center">
            Order Edit SBL
        </div>
    </div>
    <div class="row edit-height">
        <div class="col-1"></div>
        <div class="col-3 unedit-padding bold">
            Order ID
        </div>
        <div class="col-8 unedit-padding">
            {{cacheOrder.order_id}}
        </div>
    </div>
    <div class="row pb-1">
        <div class="col-1"></div>
        <div class="col-3 unedit-padding bold">
            State
        </div>
        <div class="col-3 unedit-padding">
            {{cacheOrder.state}}
        </div>
        <div class="col-3">
            <app-input-enum label="State" [fc]="stateFC" [style]="'edit-sbl'" [enumValues]="stateEnumValues">
            </app-input-enum>
        </div>
    </div>
    <div class="row pb-1">
        <div class="col-1"></div>

        <div class="col-3 unedit-padding bold">
            County
        </div>
        <div class="col-3 unedit-padding">
            {{cacheOrder.name}}
        </div>
        <div class="col-3">
            <app-input-search label="County" [fc]="countyFC" [style]="'edit-sbl'"
                [searchFn]="jurisdictionService.searchCounty.bind(jurisdictionService)"></app-input-search>
        </div>
    </div>
    <div class="row pb-1">
        <div class="col-1"></div>
        <div class="col-3 unedit-padding bold">
            SBL
        </div>
        <div class="col-3 unedit-padding">
            {{cacheOrder.sbl}}
        </div>
        <div class="col-3">
            <app-input-search #sblSearchInput label="SBL" [fc]="sblFC" [style]="'edit-sbl'"
                [searchFn]="searchSBL.bind(this)" [getItemDisplayValue]="getSBLItemDisplay.bind(this)"
                [itemSelect]="sblItemSelect.bind(this)">
            </app-input-search>
        </div>
        <div class="col-1">
            <app-button label="Ok" [small]="true" [clickFunction]="sblOk.bind(this)" [icon]="faCheck">
            </app-button>
        </div>

    </div>
    <div class="row pb-1">
        <div class="col-1"></div>
        <div class="col-3 unedit-padding bold">
            Address
        </div>
        <div class="col-3 unedit-padding">
            {{cacheOrder.address}}
        </div>
        <div class="col-3">
            <input type="text" class="input-style" [formControl]="addressFC">
        </div>
    </div>

    <div class="row pb-1">
        <div class="col-1"></div>
        <div class="col-3 unedit-padding bold">
            Year Built
        </div>
        <div class="col-3 unedit-padding">
            {{cacheOrder.year_built}}
        </div>
        <div class="col-3">
            <input type="text" class="input-style" [formControl]="yearBuiltFC">
        </div>
    </div>

    <div class="row pb-1">
        <div class="col-1"></div>
        <div class="col-3 unedit-padding bold">
            Land Use
        </div>
        <div class="col-3 unedit-padding">
            {{cacheOrder.land_use}}
        </div>
        <div class="col-3">
            <input type="text" class="input-style" [formControl]="landUseFC">
        </div>
    </div>

    <div class="row pb-1">
        <div class="col-1"></div>
        <div class="col-3 unedit-padding bold">
            Municipality Code
        </div>
        <div class="col-3 unedit-padding">
            {{cacheOrder.real_property_code}}
        </div>
        <div class="col-3">
            <input type="text" class="input-style" [formControl]="swisCodeFC" />
        </div>
    </div>


    <div class="row pb-1">
        <div class="col-1"></div>
        <div class="col-3 unedit-padding bold">
            Municipality
        </div>
        <div class="col-3 unedit-padding">
            {{cacheOrder.municipality}}
        </div>
        <div class="col-3">
            <input type="text" class="input-style" [formControl]="municipalityFC" />
        </div>
    </div>

    <div class="row pb-1">
        <div class="col-1"></div>
        <div class="col-3 unedit-padding bold">
            School District Code
        </div>
        <div class="col-3 unedit-padding">
            {{cacheOrder.school_district_swis}}
        </div>
        <div class="col-3">
            <input type="text" class="input-style" [formControl]="schoolDistrictSwisFC" />
        </div>
    </div>

    <div class="row pb-1">
        <div class="col-1"></div>
        <div class="col-3 unedit-padding bold">
            School District
        </div>
        <div class="col-3 unedit-padding">
            {{cacheOrder.school_district_name}}
        </div>
        <div class="col-3">
            <input type="text" class="input-style" [formControl]="schoolDistrictFC" />
        </div>
    </div>
    <div class="row pb-1">
        <div class="col-1"></div>
        <div class="col-3 unedit-padding bold">
            Property Type
        </div>
        <div class="col-3 unedit-padding">
            {{cacheOrder.property_type}}
        </div>
        <div class="col-3">
            <app-input-enum label="Property Type" [fc]="propertyTypeFC" [style]="'edit-sbl'"
                [enumValues]="propertyTypeEnumValues">
            </app-input-enum>
        </div>
    </div>
    <div class="row edit-height">
        <div class="col pb-2" align="center">
            <app-button [buttonType]="ButtonType.cancel" [clickFunction]="cancel.bind(this)">
            </app-button>
            <app-button [clickFunction]="save.bind(this)" [buttonType]="ButtonType.save">
            </app-button>
        </div>
    </div>
</div>