<mat-paginator style="float: right;" [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons role="table"></mat-paginator>

<mat-form-field class="container" style="margin-top: 10px;">
    <mat-label>Filter</mat-label>
    <input matInput (input)="applyFilter($event.target.value)" placeholder="Ex. Albany" #input>
</mat-form-field>
  
  <table mat-table [dataSource]="countyDataSource" class="mat-elevation-z8">
  
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef> State </th>
      <td mat-cell *matCellDef="let county"> {{county.state}} </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="countyName">
      <th mat-header-cell *matHeaderCellDef> County </th>
      <td mat-cell *matCellDef="let county">
        <span>
          <a class="county-link" style="color: #337ab7;"
          (click)="editCounty(county)">
             {{county.countyName}} 
          </a>
        </span>
      </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef> Address </th>
      <td mat-cell *matCellDef="let county"> {{county.address}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="tax_year">
      <th mat-header-cell *matHeaderCellDef> Tax Year </th>
      <td mat-cell *matCellDef="let county"> {{county.taxYear}} </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="tax_period">
      <th mat-header-cell *matHeaderCellDef class="tax-period-column"> Tax Period </th>
      <td mat-cell *matCellDef="let county" class="tax-period-column"> {{county.taxPeriod}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="due_date_1">
        <th mat-header-cell *matHeaderCellDef> Due Date 1 </th>
        <td mat-cell *matCellDef="let county"> {{county.dueDate1 | date: 'M/d/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="due_date_2">
        <th mat-header-cell *matHeaderCellDef> Due Date 2 </th>
        <td mat-cell *matCellDef="let county"> {{county.dueDate2 | date: 'M/d/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="due_date_3">
        <th mat-header-cell *matHeaderCellDef> Due Date 3 </th>
        <td mat-cell *matCellDef="let county"> {{county.dueDate3 | date: 'M/d/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="due_date_4">
        <th mat-header-cell *matHeaderCellDef> Due Date 4 </th>
        <td mat-cell *matCellDef="let county"> {{county.dueDate4 | date: 'M/d/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="refresh_date">
      <th mat-header-cell *matHeaderCellDef> Run Date </th>
      <td mat-cell *matCellDef="let county"> {{county.refreshDate | date: 'M/d/yyyy'}} </td>
  </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
    <!-- Row shown when there is no matching county. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No county matching the filter "{{input.value}}"</td>
    </tr>
  </table>