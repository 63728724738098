import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { v4 as uuid } from 'uuid';
import { UserService } from '../user.service';
import { map, tap } from 'rxjs/operators';
import { 
    faBan, faBookReader, faBox, faBuilding, 
    faChartBar, faCheck, faCog, faCogs, 
    faComments, faCookie, faExclamationCircle, faFile, 
    faFileAlt, faFileInvoice, faFileInvoiceDollar, faFileMedical, 
    faFileUpload, faFolderMinus, faHome, faList, 
    faPause, faPlus, faPlusSquare, faQuestionCircle, 
    faSearch, faSignOutAlt, faSpinner, faStoreAlt, 
    faUser, faUsers, faWrench, faLockOpen
} from '@fortawesome/free-solid-svg-icons';
import { SystemService } from '../system.service';
import { AccountService } from '../account.service';
import { ChargebeeService } from '../chargebee.service';
import { SessionService } from '../session.service';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
    faCog = faCog
    @ViewChild('profileImageElement', { read: ElementRef }) profileImageElement: ElementRef;
    @ViewChild('profileImageElement2', { read: ElementRef }) profileImageElement2: ElementRef;

    faPlus = faPlus;

    userDropdown = [
        {
            name: "Me",
            icon: faUser,
            link: "/user",
            queryParams: { id: this.authService.userId, viewMode: true },
        }, {
            name: "Help",
            link: "https://www.apexiem.com/faqs",
            icon: faQuestionCircle,
        }, {
            name: "Send Feedback",
            link: "/user-feedback",
            icon: faComments,
        }, {
            name: "Report Utility",
            link: "/utility-report",
            icon: faWrench,
            hide: !(this.authService.userrole === 'administrator'
                || this.authService.userrole === 'manager'
                || this.authService.userrole === 'system-admin'),
        },
        {
            name: "Administration",
            link: "/sys-county",
            icon: faLockOpen,
            hide: this.isNotSysAdmin(),
        },
        {
            name: "Logout",
            click: this.authService.doLogout.bind(this.authService),
            icon: faSignOutAlt,
        }, {
            name: "Button Demo",
            link: "/button-demo",
            icon: faCookie,
            hide: this.authService.username != 'admin'
        }
    ]

    settingsMenuItem = {}
    menu = []

    isCurrentLink(link) {
        //console.log("COMPARE", this.router.url, link)
        return this.router.url == link || this.router.url.startsWith(link + "?")
    }

    isCurrentMenuItem(menuItem) {
        if (this.isCurrentLink(menuItem.link)) {
            return true;
        }
        if (menuItem.menu) {
            for (var i = 0; i < menuItem.menu.length; ++i) {
                if (this.isCurrentMenuItem(menuItem.menu[i])) {
                    return true;
                }
            }
        }
        if (menuItem.dropdown) {
            for (var i = 0; i < menuItem.dropdown.length; ++i) {
                if (this.isCurrentMenuItem(menuItem.dropdown[i])) {
                    return true;
                }
            }
        }
        if (menuItem.additionalRoutes) {
            for (var i = 0; i < menuItem.additionalRoutes.length; ++i) {
                if (this.isCurrentLink(menuItem.additionalRoutes[i])) {
                    return true;
                }
            }
        }
        return false;
    }

    constructor(
        private _authService: AuthService,
        private router: Router,
        private _userService: UserService,
        private systemService: SystemService,
        private accountService: AccountService,
        private chargebeeService: ChargebeeService,
        private sessionService: SessionService
    ) { }

    get authService() {
        return this._authService
    }

    get userService() {
        return this._userService;
    }

    isNotSysAdmin(): boolean {
        if(this.sessionService.isAdmin() === true) {
            return false
        } else {
            return true
        }
    }

    showSettingsMenu() {
        return this.authService.userrole === 'administrator'
            || this.authService.userrole === 'manager'
            || this.authService.userrole === 'system-admin'
    }

    isAdminOrManager() {
        return this.authService.userrole === 'administrator'
            || this.authService.userrole === 'manager'
            || this.authService.userrole === 'system-admin'
    }

    isAdmin() {
        return this.authService.userrole === 'administrator'
            || this.authService.userrole === 'system-admin'
    }

    menuType() {
        this.chargebeeService.getPlan().subscribe((result) => {
            if(result.plan.includes('CLIENT')) {
                this.settingsMenuItem = {
                    name: "Settings",
                    icon: faCog,
                    ifDisplay: this.showSettingsMenu.bind(this),
                    menu: [
                        {
                            name: "Users",
                            icon: faUser,
                            link: "/users",
                            additionalRoutes: ["/user-create", "/user-edit"],
                        }
                    ]
                }

                this.menu = [
                    {
                        name: "New Order",
                        icon: faPlus,
                        link: "/order-create",
                        queryParams: { orderType: "Quick" },
                    }, {
                        name: "Dashboard",
                        icon: faHome,
                        link: "/agent-dashboard",

                        menu: [         
                            {
                                name: "Draft",
                                click: this.getAgentDrafts.bind(this)
                            },
                            {
                                name: "In Progress",
                                click: this.getAgentInProgress.bind(this)
                            },
                            {
                                name: "Recently Completed",
                                click: this.getAgentRecentlyCompleted.bind(this)
                            },
                            {
                                name: "All",
                                click: this.getAgentAll.bind(this)
                            }

                        ]
                    },
                    this.settingsMenuItem,
                ]
            } else {
                this.settingsMenuItem = {
                    name: "Settings",
                    icon: faCog,
                    ifDisplay: this.showSettingsMenu.bind(this),
                    menu: [
                        {
                            name: "Products",
                            icon: faBox,
                            link: "/products",
                            additionalRoutes: ["/product"],
                        }, {
                            name: "Company",
                            icon: faBuilding,
                            link: "/company-settings",
                        }, {
                            name: "Orders",
                            icon: faFileAlt,
                            link: "/order-information",
                        }, {
                            name: "Invoices",
                            icon: faFileInvoice,
                            link: "/invoice-configure",
                        }, {
                            name: "Teams",
                            icon: faUsers,
                            link: "/teams",
                            additionalRoutes: ["/team"],
                        }, {
                            name: "Users",
                            icon: faUser,
                            link: "/users",
                            additionalRoutes: ["/user-create", "/user-edit"],
                        }, {
                            name: "Subscription",
                            icon: faList,
                            click: this.manageSubscriptionClick.bind(this),
                            additionalRoutes: ["/user-create", "/user-edit"],
                            ifDisplay: this.isAdmin.bind(this)
                        }
                    ]
                }
            
                this.menu = [
                    {
                        name: "New Order",
                        icon: faPlus,
                        link: "/order-create",
                        queryParams: { orderType: "Quick" },
                    }, {
                        name: "Searches",
                        icon: faFileInvoice,
                        link: "/dashboard-search-all-open",
                        menu: [
                            {
                                name: "My Searches",
                                link: "/dashboard-search-my"
                            }, {
                                name: "All",
                                link: "/dashboard-search-all-open"
                            }, {
                                name: "To-Do",
                                link: "/dashboard-search-todo"
                            }, {
                                name: "In Progress",
                                link: "/dashboard-search-in-progress"
                            }, {
                                name: "Typing",
                                link: "/dashboard-search-typing"
                            }, {
                                name: "Waiting",
                                link: "/dashboard-search-waiting"
                            }, {
                                name: "Paused",
                                link: "/dashboard-search-paused"
                            }, {
                                name: "Done",
                                link: "/dashboard-search-completed"
                            }
                        ],
                    }, {
                        name: "Orders",
                        icon: faFileAlt,
                        link: "/dashboard-order-all-open",
                        ifDisplay: this.isAdminOrManager.bind(this),
                        menu: [
                            {
                                name: "All",
                                link: "/dashboard-order-all-open",
                            }, {
                                name: "New",
                                link: "/dashboard-order-new",
                            }, {
                                name: "In Progress",
                                link: "/dashboard-order-in-progress",
                            }, {
                                name: "On Hold",
                                link: "/dashboard-order-on-hold",
                            }, {
                                name: "To Be Invoiced",
                                link: "/dashboard-order-to-be-invoiced",
                            }, {
                                name: "Completed",
                                link: "/dashboard-order-completed",
                            }, {
                                name: "Cancelled",
                                link: "/dashboard-order-cancelled",
                            }, {
                                name: "Qualia",
                                img: "assets/qualia_logo_symbol.png",
                                dropdown: [
                                    {
                                        name: "New",
                                        icon: faPlusSquare,
                                        link: "/qualia-dashboard-new",
                                    }, {
                                        name: "In Progress",
                                        icon: faSpinner,
                                        link: "/qualia-dashboard-in-progress",
                                    }, {
                                        name: "Submitted",
                                        icon: faFileInvoice,
                                        link: "/qualia-dashboard-submitted",
                                    }, {
                                        name: "Ready",
                                        icon: faFileUpload,
                                        link: "/qualia-dashboard-ready",
                                    }, {
                                        name: "Cancelled",
                                        icon: faBan,
                                        link: "/qualia-dashboard-cancelled",
                                    }, {
                                        name: "Errored",
                                        icon: faExclamationCircle,
                                        link: "/qualia-dashboard-errored",
                                    },
                                ]
                            }
                        ],
                    }, {
                        name: "Find",
                        icon: faSearch,
                        link: "/order-search",
                        menu: [
                            {
                                name: "Searches",
                                icon: faFileAlt,
                                link: "/search-search",
                            }, {
                                name: "Orders",
                                icon: faFileAlt,
                                link: "/order-search",
                            }, {
                                name: "Documents",
                                icon: faFile,
                                link: "/document-search",
                            }
                        ]
                    }, {
                        name: "Invoices",
                        icon: faFileInvoiceDollar,
                        link: "/invoice-list",
                        ifDisplay: this.isAdminOrManager.bind(this),
                        menu: [
                            {
                                name: "Overview",
                                icon: faBookReader,
                                link: "/invoice-list",
                                additionalRoutes: ["/invoice-edit", "/invoice-view"]
                            }, {
                                name: "Open",
                                icon: faChartBar,
                                link: "/invoice-open",
                            }, {
                                name: "All Invoices",
                                icon: faFileInvoice,
                                link: "/invoice-all",
                            }
                        ]
                    }, {
                        name: "Reports",
                        icon: faFile,
                        link: "/search-summary",
                        ifDisplay: this.isAdminOrManager.bind(this),
                        menu: [
                            {
                                name: "Search Summary",
                                icon: faChartBar,
                                link: "/search-summary"
                            }, {
                                name: "Overdue",
                                icon: faChartBar,
                                link: "/overdue-report"
                            }, {
                                name: "AR Report",
                                icon: faChartBar,
                                link: "/ar-report"
                            }, {
                                name: "Invoice",
                                icon: faChartBar,
                                link: "/invoice-report"
                            }
                        ]
                    }, {
                        name: "Manage",
                        icon: faCogs,
                        link: "/clients",
                        menu: [
                            {
                                name: "Clients",
                                icon: faUsers,
                                link: "/clients",
                                additionalRoutes: ["/client"],
                            }, {
                                name: "Vendors",
                                icon: faStoreAlt,
                                link: "/vendors",
                                additionalRoutes: ["/vendor"],
                            }, {
                                name: "Contacts",
                                icon: faUsers,
                                link: "/contacts",
                                additionalRoutes: ["/contact"],
                            }
                        ]
                    },
                    {
                        name: null,
                        link: "/sys-county",
                        menu: [
                            {
                                name: "County",
                                link: "/sys-county"
                            },
                            {
                                name: "Municipality",
                                link: "/sys-municipality"
                            },
                            {
                                name: "School",
                                link: "/sys-school"
                            }
                        ]
                    },
                    this.settingsMenuItem,
                ]
            }
        })
    }

    initialized

    setMenuUuid(item) {
        (<any>item).uuid = uuid()
        if (item.menu) {
            item.menu.forEach((item) => {
                this.setMenuUuid(item)
            })
        }
        if (item.dropdown) {
            item.dropdown.forEach((item) => {
                this.setMenuUuid(item)
            })
        }
    }

    getAgentDrafts() {
        this.router.navigate(['/agent-dashboard'], { queryParams: { order_status: "D" }})
    }

    getAgentInProgress() {
        this.router.navigate(['/agent-dashboard'], { queryParams: { order_status: "IP" }})
    }

    getAgentRecentlyCompleted() {
        this.router.navigate(['/agent-dashboard'], { queryParams: { order_status: "RC" }})
    }

    getAgentAll() {
        this.router.navigate(['/agent-dashboard'], { queryParams: { order_status: "A" }} )
    }

    is_agent: boolean
    claims: any
    ngOnInit() {
        this.claims = this.sessionService.getClaims()
        this.is_agent = false
        this.chargebeeService.getPlan().subscribe((response) => {
            if(response.plan.includes('CLIENT')) {
                this.is_agent = true
            } else {
                this.is_agent = false
            }
        })

        this.menuType()

        this.menu.forEach((item => {
            this.setMenuUuid(item)
        }))

        this.initialized = true
        this.doLoadProfileImage()
    }

    userFullName$ = this.userService.getUserByName(this.authService.username).pipe(
        map((response) => {
            return response.result[0].full_name
        }),
    )

    getImageHeight(width, height, newWidth) {
        var ratio = width / height;
        return newWidth / ratio;
    }

    getImageWidth(width, height, newHeight) {
        var ratio = width / height;
        return ratio * newHeight;
    }

    getUserLetters(fullName) {
        if (fullName && fullName.trim()) {
            var nameParts = fullName.split(" ")
            if (nameParts.length == 1) {
                return fullName.trim().substring(0, 1).toUpperCase()
            } else {
                return nameParts[0].substring(0, 1).toUpperCase() + nameParts[nameParts.length - 1].substring(0, 1).toUpperCase()
            }
        }
        return this.authService.username.trim().substring(0, 1).toUpperCase();
    }

    photo
    userFullName
    doLoadProfileImage() {
        this.userService.getUserByName(this.authService.username).subscribe((response) => {
            this.photo = response.result[0].photo;
            this.userFullName = response.result[0].full_name;
            setTimeout(() => {
                this.accountService.profileImageId.next(this.photo);
                this.accountService.profileImageId.subscribe((photoId) => {
                    this.photo = photoId;
                    this.loadProfileImage();
                });
            }, 0);
        })
    }

    loadLetter(element, userFullName) {
        var img = document.createElement('div');
        img.style.display = 'inline-block';
        img.style.backgroundColor = '#337ab7';
        img.style.height = '32px';
        img.style.width = '32px';
        img.style.borderRadius = '32px';
        img.style.color = 'white';
        img.align = 'center';
        img.style.position = 'relative';

        if (element == this.profileImageElement2) {
            img.style.marginTop = '10px'
        }

        var letter = document.createElement('span');
        letter.style.fontSize = '20px';
        letter.style.fontWeight = 'normal';
        letter.innerHTML = this.getUserLetters(userFullName);
        letter.className = 'center';
        img.appendChild(letter);
        if (element) {
            while (element.nativeElement.firstChild) {
                element.nativeElement.removeChild(element.nativeElement.firstChild);
            }
            element.nativeElement.appendChild(img);
        }
    }

    setImage(elem, readerResult) {
        var img = document.createElement('img');
        (<any>img.src) = readerResult;
        img.onload = function () {
            var newHeight = 32;
            var newWidth = this.getImageWidth(img.width, img.height, newHeight);
            img.width = newWidth;
            img.height = newHeight;
            if (elem) {
                while (elem.nativeElement.firstChild) {
                    elem.nativeElement.removeChild(elem.nativeElement.firstChild);
                }
                elem.nativeElement.appendChild(img);
            }
        }.bind(this);
    }

    loadProfileImage() {
        if (this.photo) {
            this.systemService.getProfileFile(this.photo).subscribe((response) => {
                var reader = new FileReader();
                reader.readAsDataURL(<any>response);
                reader.onloadend = () => {
                    this.setImage(this.profileImageElement, reader.result)
                    this.setImage(this.profileImageElement2, reader.result)
                };
            });
        } else {
            this.loadLetter(this.profileImageElement, this.userFullName)
            this.loadLetter(this.profileImageElement2, this.userFullName)
        }
    }

    manageSubscriptionClick() {
        this.doOpenPortal();
    }

    doOpenPortal() {
        this.chargebeeService.getPlan().subscribe((response) => {
            //console.log('chargebee get plan response', response);
            if (response.success) {
                this.accountService.getPortalUrl(response.subscriptionId, response.customerId).subscribe((response) => {
                    if (response.success) {
                        window.open(response.url);
                    } else {
                        console.log('error while fetching portal url');
                    }
                });
            }
        });
    }

}
