<div *ngIf="!isFooter" class="row">
    <div class="col">
        <div class="bold" style="float: left; font-size: 1.3em;" [ngClass]="{ 'pt-2': newRoute, 'py-2': !newRoute }">
            {{title}}
        </div>
        <a style="float: right" *ngIf="newRoute" [routerLink]="newRoute">
            <app-button style="float: right;" label="New" class="ml-2" [icon]="faPlus">
            </app-button>
        </a>
        <div *ngIf="!hidePaging" style="float: right" class="pt-2">
            <span (click)="previous()" class="link mx-3">Previous</span>

            <app-dashboard-controls-button *ngIf="pageNumber > 2" class="mx-1" label="..."
                [clickFunction]="first.bind(this)">
            </app-dashboard-controls-button>
            <app-dashboard-controls-button *ngIf="pageNumber > 1" class="mx-1" [label]="pageNumber - 1"
                [clickFunction]="previous.bind(this)">
            </app-dashboard-controls-button>
            <app-dashboard-controls-button class="mx-1" [label]="pageNumber" [clickFunction]="current.bind(this)"
                [altStyle]="'true'">
            </app-dashboard-controls-button>
            <app-dashboard-controls-button *ngIf="pageNumber < maxPage" class="mx-1" [label]="pageNumber + 1"
                [clickFunction]="next.bind(this)">
            </app-dashboard-controls-button>
            <app-dashboard-controls-button *ngIf="pageNumber < maxPage - 1" class="mx-1" label="..."
                [clickFunction]="afterNext.bind(this)">
            </app-dashboard-controls-button>
            <app-dashboard-controls-button *ngIf="pageNumber < maxPage - 2" class="mx-1" [label]="maxPage"
                [clickFunction]="last.bind(this)">
            </app-dashboard-controls-button>
            <span (click)="next()" class="link mx-3">Next</span>
        </div>

        <div *ngIf="!hidePaging" class="mr-3 pt-2" style="float: right; font-size: 1.1em;">
            Showing {{firstResultNum}}-{{lastResultNum}} / {{entryCount}} Results
        </div>
    </div>
</div>

<div *ngIf="isFooter && !hidePaging" class="row">
    <div class="col">
        <div class="mr-3" style="float: left; font-size: 1.1em;">
            Showing {{firstResultNum}}-{{lastResultNum}} / {{entryCount}} Results
        </div>

        <div style="float: right">
            <span (click)="previous()" class="link mx-3">Previous</span>

            <app-dashboard-controls-button *ngIf="pageNumber > 2" class="mx-1" label="..."
                [clickFunction]="first.bind(this)">
            </app-dashboard-controls-button>
            <app-dashboard-controls-button *ngIf="pageNumber > 1" class="mx-1" [label]="pageNumber - 1"
                [clickFunction]="previous.bind(this)">
            </app-dashboard-controls-button>
            <app-dashboard-controls-button class="mx-1" [label]="pageNumber" [clickFunction]="current.bind(this)"
                [altStyle]="'true'">
            </app-dashboard-controls-button>
            <app-dashboard-controls-button *ngIf="pageNumber < maxPage" class="mx-1" [label]="pageNumber + 1"
                [clickFunction]="next.bind(this)">
            </app-dashboard-controls-button>
            <app-dashboard-controls-button *ngIf="pageNumber < maxPage - 1" class="mx-1" label="..."
                [clickFunction]="afterNext.bind(this)">
            </app-dashboard-controls-button>
            <app-dashboard-controls-button *ngIf="pageNumber < maxPage - 2" class="mx-1" [label]="maxPage"
                [clickFunction]="last.bind(this)">
            </app-dashboard-controls-button>
            <span (click)="next()" class="link mx-3">Next</span>
        </div>

    </div>
</div>
