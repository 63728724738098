import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService 
{
    private errorsBehavior: BehaviorSubject<Array<string>>;
    private errors: Array<string>;
    private unhandledError: any;

    constructor() 
    { 
        // Errors Messages
        this.errorsBehavior = new BehaviorSubject<Array<string>>([]);
        this.errors = [];

        // Unhandled Errors
        this.unhandledError = 'Unknown Error';
    }

    setUnhandledError(error: any) : void
    {
        this.unhandledError = error;
    }
    
    getUnhandledError() : any
    {
        return this.unhandledError;
    }

    addError(error : string) : void
    {
        if(this.errors == null)
        {
            this.errors = new Array<string>();
        }
        this.errors.push(error);
        this.errorsBehavior.next(this.errors);
    }

    getErrorsBehavior() : BehaviorSubject<Array<string>>
    {
        return this.errorsBehavior;
    } 
}
