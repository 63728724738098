<form [formGroup]="filterForm">
    <div class="form-container">
      <mat-form-field class="filter-field">
        <mat-label>Search</mat-label>
        <input matInput formControlName="filter" placeholder="Type to search (Limited to current status)">
      </mat-form-field>
    </div>
</form>

<table mat-table [dataSource]="orders" class="mat-elevation-z8">  
    <!-- Order Number Column -->
    <ng-container matColumnDef="orderId">
    <th mat-header-cell *matHeaderCellDef> Order # </th>
    <td mat-cell *matCellDef="let order" class="clickable-data" (click)="openOrderDownloads(order.uuid)"> {{order.orderId}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="workflowStatus">
    <th mat-header-cell *matHeaderCellDef> Status </th>
    <td mat-cell *matCellDef="let order"> 
        {{ order.workflowStatus }}
    </td>
    </ng-container>

    <!-- Reference Number Column -->
    <ng-container matColumnDef="referenceNumber">
    <th mat-header-cell *matHeaderCellDef> Reference # </th>
    <td mat-cell *matCellDef="let order"> {{order.referenceNumber}} </td>
    </ng-container>

    <!-- Search Type Column -->
    <ng-container matColumnDef="serviceName">
    <th mat-header-cell *matHeaderCellDef> Search Type </th>
    <td mat-cell *matCellDef="let order"> {{order.serviceName}} </td>
    </ng-container>

    <!-- County Column -->
    <ng-container matColumnDef="countyName">
    <th mat-header-cell *matHeaderCellDef> County </th>
    <td mat-cell *matCellDef="let order"> {{order.countyName}} </td>
    </ng-container>

    <!-- Address Column -->
    <ng-container matColumnDef="address">
    <th mat-header-cell *matHeaderCellDef> Address </th>
    <td mat-cell *matCellDef="let order"> {{order.address}} </td>
    </ng-container>

    <!-- SBL Column -->
    <ng-container matColumnDef="sbl">
    <th mat-header-cell *matHeaderCellDef> SBL </th>
    <td mat-cell *matCellDef="let order" class="clickable-data" (click)="openAssessmentInfo(order.uuid)"> {{order.sbl}} </td>
    </ng-container>

    <!-- Seller Column -->
    <ng-container matColumnDef="propertySeller">
    <th mat-header-cell *matHeaderCellDef> Seller </th>
    <td mat-cell *matCellDef="let order"> {{order.propertySeller}} </td>
    </ng-container>

    <!-- Buyer Column -->
    <ng-container matColumnDef="propertyBuyer">
    <th mat-header-cell *matHeaderCellDef> Buyer </th>
    <td mat-cell *matCellDef="let order"> {{order.propertyBuyer}} </td>
    </ng-container>

    <!-- Ordered Column -->
    <ng-container matColumnDef="submissionDate">
    <th mat-header-cell *matHeaderCellDef> Ordered </th>
    <td mat-cell *matCellDef="let order"> {{order.submissionDate | date:'MM/dd/yyyy'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
  