<span *ngIf="!initialized">
    <div class="container-fluid mt-4  rounded-ninety" [style.background-color]="themeService.tertiaryColor">
        Initializing...
    </div>
</span>

<div *ngIf="initialized" class="container-fluid rounded-ninety" [style.background-color]="themeService.tertiaryColor">

    <div class="row pt-3 pb-1">
        <div class="col-8 pb-3 bold">
            <app-button class="mx-1" label="New Invoice" [clickFunction]="newInvoice.bind(this)" [icon]="faPlus">
            </app-button>
        </div>
        <div class="col-4 pb-3">
            <app-input-search label="Invoice #" [fc]="searchInputFC" [searchFn]="searchInvoicesById.bind(this)"
                [itemSelect]="selectInvoice.bind(this)" [style]="'invoice'" [leftIcon]="faSearch"
                [getItemDisplayValue]="searchItemDisplay.bind(this)" [placeholderText]="'Search by Invoice ID'">
            </app-input-search>
        </div>
    </div>
    <div class="row pb-1">
        <div class="col-3 pb-3 bold">
            <div
                style="border-style: solid; border-width: 1px; border-color: lightgrey; width: 100%; padding-left: .3em">
                <div class="pt-2 pl-2">
                    Total Open
                </div>
                <div class="pl-2" style="font-size: 1.4em; font-weight: bold;">
                    {{totalOpen | currency : 'USD'}}
                </div>
                
            </div>
            <div
                style="border-style: solid; border-width: 1px; border-color: lightgrey; width: 100%; padding-left: .3em">
                <br />
                <div class="pt-2 pl-2">
                    Total Paid Amount
                </div>
                <div class="pl-2" style="font-size: 1.4em; font-weight: bold;">
                    {{totalPaid | currency : 'USD'}}
                </div>
                
            </div>
        </div>
        <div class="col-9 pb-3 bold">
            <div class="row">
                <div class="col" align="center">
                    <div [style.max-width]="'60em'" align="right">
                        {{graphHeaderLabel}}
                        <div class="graph-button" (click)="changeGraphOffset(-1)">
                            <fa-icon [icon]="faChevronLeft"></fa-icon>
                        </div>
                        <div class="graph-label">
                            {{graphIntervalLabel}}
                        </div>
                        <div class="graph-button" (click)="changeGraphOffset(1)">
                            <fa-icon [icon]="faChevronRight"></fa-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col" align="center">
                    <div style="display: block;" [style.max-width]="'60em'">
                </div>
            </div>
        </div>
    </div>
</div>