import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';
import { OrderService } from '../order.service';
import { faQuestionCircle, faWindowClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-order-services-individual',
  templateUrl: './order-services-individual.component.html',
})
export class OrderServicesIndividualComponent implements OnInit {
    faQuestionCircle = faQuestionCircle;
    faWindowClose = faWindowClose;
    faPlus = faPlus;
    initialized = false;
    services;
    cacheOrder;
    people;
    searcherIndividualNotesFC;

    constructor(
        private _themeService: ThemeService,
        private _orderService: OrderService,
    ) { }

    ngOnInit() {
        this._orderService.getServices(4).subscribe((response) => {
            //console.log(response);
            this.services = response.result;
            this.loadOrderCache();

            this.people = [];
            if (this.cacheOrder.property_seller) {
                for (var i = 0; i < this.cacheOrder.property_seller.length; ++i) {
                    if (this.cacheOrder.property_seller[i]) {
                        this.people.push({
                            name: this.cacheOrder.property_seller[i],
                            role: "Property Seller",
                        })
                    }
                }
            }
            if (this.cacheOrder.property_buyer) {
                for (var i = 0; i < this.cacheOrder.property_buyer.length; ++i) {
                    if (this.cacheOrder.property_buyer[i]) {
                        this.people.push({
                            name: this.cacheOrder.property_buyer[i],
                            role: "Property Buyer"
                        })
                    }
                }
            }
            if (this.cacheOrder.assessed_owner) {
                for (var i = 0; i < this.cacheOrder.assessed_owner.length; ++i) {
                    if (this.cacheOrder.assessed_owner[i]) {
                        this.people.push({
                            name: this.cacheOrder.assessed_owner[i],
                            role: "Assessed Owner"
                        })
                    }
                }
            }

            if (this.cacheOrder.additionalIndividual) {
                for (var i = 0; i < this.cacheOrder.additionalIndividual.length; ++i) {
                    this.people.push({
                        formControl: new UntypedFormControl(this.cacheOrder.additionalIndividual[i])
                    })
                }
            }
            this.addFourPeople();
            this.initialized = true;
        });
    }

    addFourPeople() {
        if (!this.cacheOrder.additionalIndividual) {
            this.cacheOrder.additionalIndividual = [];
        }
        for (var i = 0; i < 4; ++i) {
            this.people.push({
                formControl: new UntypedFormControl()
            })
            this.cacheOrder.additionalIndividual.push("");
        }
    }

    loadOrderCache() {
        var dataItem = localStorage.getItem("cached_order_draft");
        this.cacheOrder = JSON.parse(dataItem);
        if (!this.cacheOrder) {
            this.cacheOrder = {};
        }
        if (!this.cacheOrder.individualServices) {
            this.cacheOrder.individualServices = {};
        }
        if (this.cacheOrder.additionalIndividual) {
            for (var i = 0; i < this.cacheOrder.additionalIndividual.length; ++i) {
                if (!this.cacheOrder.additionalIndividual[i]) {
                    this.cacheOrder.additionalIndividual.splice(i, 1);
                    --i;
                }
            }
        }
        if (this.cacheOrder.searcher_individual_notes) {
            this.searcherIndividualNotesFC = new UntypedFormControl(this.cacheOrder.searcher_individual_notes);
        } else {
            this.searcherIndividualNotesFC = new UntypedFormControl();
        }
    }

    saveOrderCache() {
        this.cacheOrder.searcher_individual_notes = this.searcherIndividualNotesFC.value;
        //console.log("saving cache order", this.cacheOrder);
        localStorage.setItem("cached_order_draft", JSON.stringify(this.cacheOrder));
    }

    get themeService() {
        return this._themeService;
    }

    get orderService() {
        return this._orderService;
    }

    questionMouseEnter(refName) {
        var elem = document.getElementById(refName);
        elem.style.color = this.themeService.secondaryColor;
    }

    questionMouseLeave(refName) {
        var elem = document.getElementById(refName);
        elem.style.color = this.themeService.textOnBackgroundColor;
    }

    questionCloseMouseEnter(refName) {
        var elem = document.getElementById(refName);
        elem.style.color = this.themeService.secondaryColor;
    }

    questionCloseMouseLeave(refName) {
        var elem = document.getElementById(refName);
        elem.style.color = this.themeService.primaryColor;
    }

    addPeopleMouseLeave(refName) {
        document.getElementById(refName).style.color = this.themeService.primaryColor;
    }

    toggleDisplay(refName) {
        var elem = document.getElementById(refName);
        if (elem.style.display == "none") {
            elem.style.display = "inline-block";
        } else {
            elem.style.display = "none";
        }
    }

    serviceChange(person, service, personIndex) {
        var elem = document.getElementById(personIndex + "_" + service.service_id);
        var serviceChecked = (<HTMLInputElement>elem).checked;
        this.doServiceChange(person, service, serviceChecked);
    }

    doServiceChange(person, service, serviceChecked) {
        var personIdentifier;
        if (person.name) {
            personIdentifier = person.name;
        } else {
            personIdentifier = person.formControl.value;
        }

        if (!this.cacheOrder.individualServices[personIdentifier]) {
            this.cacheOrder.individualServices[personIdentifier] = {};
        }
        this.cacheOrder.individualServices[personIdentifier][service.service_id] = serviceChecked;
        if (!serviceChecked) {
            delete this.cacheOrder.individualServices[personIdentifier][service.service_id];
        }
        this.saveOrderCache();
    }

    getPersonIdentifier(person) {
        var personIdentifier;
        if (person.formControl) {
            personIdentifier = person.formControl.value;
        } else {
            personIdentifier = person.name;
        }
        return personIdentifier;
    }

    isChecked(person, service, personIndex) {
        var personIdentifier = this.getPersonIdentifier(person);

        if (!this.cacheOrder.individualServices[personIdentifier]) {
            return false;
        }
        return this.cacheOrder.individualServices[personIdentifier][service.service_id];
    }

    serviceAllChange(service) {
        var elem = document.getElementById(service.service_id + "_all");
        var serviceChecked = (<HTMLInputElement>elem).checked;
        for (var i = 0; i < this.people.length; ++i) {
            if (this.getPersonIdentifier(this.people[i])) {
                this.doServiceChange(this.people[i], service, serviceChecked);
            }
        }
    }

    isAllChecked(service) {
        for (var i = 0; i < this.people.length; ++i) {
            if (this.getPersonIdentifier(this.people[i])) {
                if (!this.isChecked(this.people[i], service, i)) {
                    return false;
                }
            }
        }
        return true;
    }

    additionalIndividualNameChange(i) {
        var additionalIndex = i - this.cacheOrder.property_seller.length - this.cacheOrder.property_buyer.length - this.cacheOrder.assessed_owner.length;
        //console.log("name changed from", this.cacheOrder.additionalIndividual[additionalIndex]);
        //console.log("name changed to", this.people[i].formControl.value);

        this.cacheOrder.individualServices[this.people[i].formControl.value] = this.cacheOrder.individualServices[this.cacheOrder.additionalIndividual[additionalIndex]];
        delete this.cacheOrder.individualServices[this.cacheOrder.additionalIndividual[additionalIndex]];

        this.cacheOrder.additionalIndividual[additionalIndex] = this.people[i].formControl.value;
        this.saveOrderCache();
    }
}
