import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    settingsUrl;
    settings;

    constructor(
        private http: HttpClient,
    ) {
        this.settings = JSON.parse(localStorage.getItem("settings"));
        this.settingsUrl = "api/settings";
    }

    setSettings(settings) {
        this.settings = settings;
        if (this.settings) {
            localStorage.setItem('settings', JSON.stringify(this.settings));
        }
    }

    getSettings() {
        return this.settings;
    }

    updateSettings(success, failure?) {
        //console.log("calling to update settings with url", this.settingsUrl);
        var params: any = {};

        this.http.put<any>(this.settingsUrl, params).subscribe((response) => {
            if (response.success) {
                //console.log("settings update success");
                this.setSettings(response.settings);
                success();
            } else {
                //console.log("settings update failure");
                if (failure) {
                    failure();
                }
            }
        });
    }
}
