import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ThemeService } from '../theme.service';
import { faBan, faSave, faEdit, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { CompanyService } from '../company.service';
import { ChargebeeService } from '../chargebee.service';
import { AccountService } from '../account.service';
import { TimezoneService } from '../timezone.service';
import { UserService } from '../user.service';
import { InputTextComponent } from '../input-text/input-text.component';
import { InputImageComponent } from '../input-image/input-image.component';
import { InputDateComponent } from '../input-date/input-date.component';
import { InputEnumComponent } from '../input-enum/input-enum.component';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
// declare const Chargebee: any;
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-company-settings',
    templateUrl: './company-settings.component.html',
})
export class CompanySettingsComponent implements OnInit {
    faBan = faBan;
    faSave = faSave;
    faEdit = faEdit;
    faExclamationCircle = faExclamationCircle
    initialized = false;
    editing = false;

    @ViewChildren(InputTextComponent) inputTextComponents: QueryList<InputTextComponent>;
    @ViewChildren(InputImageComponent) inputImageComponents: QueryList<InputImageComponent>;
    @ViewChildren(InputDateComponent) inputDateComponents: QueryList<InputDateComponent>;
    @ViewChildren(InputEnumComponent) inputEnumComponents: QueryList<InputEnumComponent>;

    companyNameFormControl = new UntypedFormControl({ value: '', disabled: true });
    addressFormControl = new UntypedFormControl();
    invoiceRemitFormControl = new UntypedFormControl();
    companyLogoFormControl = new UntypedFormControl();
    webAddressFormControl = new UntypedFormControl();
    phoneNumberFormControl = new UntypedFormControl();
    faxNumberFormControl = new UntypedFormControl();
    accountOwnerFormControl = new UntypedFormControl();
    fiscalYearStartFormControl = new UntypedFormControl();
    orderIdPrefixFormControl = new UntypedFormControl({ value: '', disabled: true });
    nextOrderIdFormControl = new UntypedFormControl();
    notifyEmailAddressFormControl = new UntypedFormControl();
    defaultProviderFormControl = new UntypedFormControl();
    defaultTimeZoneFormControl = new UntypedFormControl();
    invoiceEmailAddressFormControl = new UntypedFormControl()
    documentDeliverySignatureFC = new UntypedFormControl()

    chargebeeInstance;
    options = {
        icon: true,
        classes: {
            focus: 'focus',
            invalid: 'invalid',
            empty: 'empty',
            complete: 'complete',
        }
    }
    plan;
    subscriptionId;
    customerId;
    timezones;
    phoneExampleText = "e.g. (123) 456-7890";
    providers;
    companyUsers;
    nextOrderIdPrefix
    minNextOrderIdLength = 8

    months = [
        {
            id: "1",
            name: "January",
        },
        {
            id: "2",
            name: "February",
        },
        {
            id: "3",
            name: "March",
        },
        {
            id: "4",
            name: "April",
        },
        {
            id: "5",
            name: "May",
        },
        {
            id: "6",
            name: "June",
        },
        {
            id: "7",
            name: "July",
        },
        {
            id: "8",
            name: "August",
        },
        {
            id: "9",
            name: "September",
        },
        {
            id: "10",
            name: "October",
        },
        {
            id: "11",
            name: "November",
        },
        {
            id: "12",
            name: "December",
        },
    ]

    constructor(
        private _themeService: ThemeService,
        private _companyService: CompanyService,
        private chargebeeService: ChargebeeService,
        private accountService: AccountService,
        private timezoneService: TimezoneService,
        private userService: UserService,
        private authService: AuthService,
        private _router: Router,
        private _snackBar: MatSnackBar,
    ) { }

    get router() {
        return this._router;
    }

    setNextOrderIdPrefix() {
        this.nextOrderIdPrefix = ""
        if (this.nextOrderIdFormControl.value.toString().length + this.nextOrderIdPrefix.length < this.minNextOrderIdLength) {
            this.nextOrderIdPrefix += "1"
            while (this.nextOrderIdFormControl.value.toString().length + this.nextOrderIdPrefix.length < this.minNextOrderIdLength) {
                this.nextOrderIdPrefix += "0"
            }
        }
    }

    isReadOnly = true

    ngOnInit() {
        this.isReadOnly = this.authService.userrole != "administrator"
            && this.authService.userrole != "system-admin"

        this._companyService.getCompany().subscribe((response) => {
            this.companyNameFormControl.setValue(response.result[0].name);
            this.addressFormControl.setValue(response.result[0].address);
            this.invoiceRemitFormControl.setValue(response.result[0].invoice_remit_address);
            this.companyLogoFormControl.setValue(response.result[0].company_logo);
            this.webAddressFormControl.setValue(response.result[0].web_address);
            this.phoneNumberFormControl.setValue(response.result[0].phone);
            this.faxNumberFormControl.setValue(response.result[0].fax);
            this.accountOwnerFormControl.setValue(response.result[0].account_owner);
            this.fiscalYearStartFormControl.setValue(response.result[0].fiscal_year_start_month);
            this.orderIdPrefixFormControl.setValue(response.result[0].order_id_prefix);
            this.nextOrderIdFormControl.setValue(response.result[0].next_order_id);

            this.setNextOrderIdPrefix()

            this.notifyEmailAddressFormControl.setValue(response.result[0].notify_email_address);
            this.defaultProviderFormControl.setValue(response.result[0].default_provider);
            this.defaultTimeZoneFormControl.setValue(response.result[0].default_time_zone);
            this.invoiceEmailAddressFormControl.setValue(response.result[0].invoice_email_address);
            this.documentDeliverySignatureFC.setValue(response.result[0].document_delivery_signature)

            this._companyService.getProviders().subscribe((response) => {
                this.providers = response.result;

                // removed all calls to chargebee, only using data stored in db until we rebuild chargebee

                // this.chargebeeService.getChargebeeParams().subscribe((response) => {
                //     this.chargebeeInstance = Chargebee.init({
                //         site: response.site,
                //         publishableKey: response.key
                //     });
                    this.chargebeeService.getPlan().subscribe((response) => {
                        if (response.success) {
                            this.plan = response.plan;
                            this.subscriptionId = response.subscriptionId;
                            this.customerId = response.customerId
                        }
                        this.timezoneService.getTimezones().subscribe((timezones) => {
                            this.timezones = [];
                            for (var i = 0; i < timezones.length; ++i) {
                                this.timezones.push(timezones[i].display_value);
                            }
                            this.userService.getUsersByCompany(this.authService.companyId).subscribe((response) => {
                                for (var i = 0; i < response.result.length; ++i) {
                                    response.result[i].name = response.result[i].full_name;
                                }
                                this.companyUsers = response.result;
                                this.initialized = true;
                            })
                        })
                    });
                // });
            });
        });
    }

    doOpenPortal() {
        this.accountService.getPortalUrl(this.subscriptionId, this.customerId).subscribe((response) => {
            if (response.success) {
                window.open(response.url);
            } else {
                console.log("error while fetching portal url");
            }
        })
    }

    get themeService() {
        return this._themeService;
    }

    edit() {
        this.editing = true;
        this.inputTextComponents.forEach(writer => {
            writer.disabled = false;
        });
        this.inputImageComponents.forEach(writer => {
            writer.disabled = false;
        });
        this.inputDateComponents.forEach(writer => {
            writer.disabled = false;
        });
        this.inputEnumComponents.forEach(writer => {
            writer.disabled = false;
        });
    }
    formErrors = {}
    anyError = false
    isValid() {
        this.anyError = false;

        if (this.router.url == '/company-settings') {
            if (this.webAddressFormControl.value && !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
                this.webAddressFormControl.value)) {
                this.formErrors["web_address"] = "Please enter a valid URL."
                this.anyError = true
            } else {
                delete this.formErrors["web_address"]
            }
        }

        if (this.router.url == '/order-information') {

        }
        return !this.anyError
    }

    save() {
        if (!this.isValid()) {
            return
        }

        this._companyService.updateCompany(
            this.companyNameFormControl.value,
            this.addressFormControl.value,
            this.invoiceRemitFormControl.value,
            this.companyLogoFormControl.value,
            this.webAddressFormControl.value,
            this.phoneNumberFormControl.value,
            this.faxNumberFormControl.value,
            this.accountOwnerFormControl.value,
            this.fiscalYearStartFormControl.value,
            this.orderIdPrefixFormControl.value,
            this.nextOrderIdFormControl.value,
            this.notifyEmailAddressFormControl.value,
            this.defaultProviderFormControl.value == "null" ? null : this.defaultProviderFormControl.value,
            this.defaultTimeZoneFormControl.value,
            this.invoiceEmailAddressFormControl.value,
            this.documentDeliverySignatureFC.value,
        ).subscribe(response => {
            console.log("company save response", response);
            if (response.success) {
                var snack = "Settings saved."
                this._snackBar.open(snack, null, { duration: 2000, });
            }
        });
    }

    setUnedit() {
        this.editing = false;
        this.inputTextComponents.forEach(writer => {
            writer.disabled = true;
        });
        this.inputImageComponents.forEach(writer => {
            writer.disabled = true;
        });
        this.inputDateComponents.forEach(writer => {
            writer.disabled = true;
        });
        this.inputEnumComponents.forEach(writer => {
            writer.disabled = true;
        });
    }

    back() {
        this.userService.navigateToInitialPage();
    }

    cancel() {
        this.setUnedit();
        this.ngOnInit();
    }

    faxUpdate() {
        this.userService.phoneUpdate(this.faxNumberFormControl);
    }

    phoneUpdate() {
        this.userService.phoneUpdate(this.phoneNumberFormControl);
    }
}
