import { Component, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-banner-message',
    templateUrl: './banner-message.component.html',
    styleUrls: ['./banner-message.component.css']
})
export class BannerMessageComponent implements OnInit {
    faTimes = faTimes
    dismissed = false
    constructor() { }

    ngOnInit() {
    }

    dismiss() {
        this.dismissed = true
    }

}
