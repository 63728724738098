import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { iOrderWorkflow } from '../model/workflow.model';
import { OrdersService } from '../services/order.service';
import { WorkflowService } from '../services/workflow.service';
import { MessageService } from "primeng/api";
import { iWorkflowStatus } from '../model/workflow-status.model';
import { OrderService } from '../order.service';
import { FileUpload } from 'primeng/fileupload';
import { iAttachmentType } from '../model/attachment-type.model';

@Component({
  selector: 'app-order-progress',
  templateUrl: './order-progress.component.html',
  styleUrl: './order-progress.component.css',
})
export class OrderProgressComponent implements OnInit
{
  @Input() orderData: any;
  @Output() fileUploadCompleted = new EventEmitter<{}>();
  @ViewChild('fileUploader') fileUploader!: FileUpload;
  editable: boolean;
  showPriceLevelDialog: boolean = false;
  displayDialog: boolean = false;
  workflowTableData: iOrderWorkflow[] = [];
  workflowStatuses: iWorkflowStatus[] = [];
  queuedFiles: any[] = [];
  attachmentTypes: iAttachmentType[] = [];
  selectedAttachmentTypes: { [key: number]: iAttachmentType } = {};
  difficultyLevels =
  [
    { name: '0', id: 0 },
    { name: '1', id: 1 },
    { name: '2', id: 2 },
    { name: '3', id: 3 },
    { name: '4', id: 4 },
    { name: '5', id: 5 }
  ];
  description: string = '';
  type: any = null;
  

  constructor(
    private orderService: OrdersService,
    private oldOrderService: OrderService,
    private workflowService: WorkflowService,
    private messageService: MessageService
  ) {}

  ngOnInit()
  {
    this.fetchWorkflowData();
    this.fetchWorkflowStatuses();
    this.fetchAttachmentTypes();
  }

  private fetchWorkflowData()
  {
    this.editable = this.orderData.order_status !== 'C';
    this.orderService.getOrderWorkflowByOrderUuid(this.orderData.uuid).subscribe({
      next: (workflows) => {
        this.workflowTableData = workflows.map((item) => ({
          ...item,
          rush: item.rush === 1,
        }));
      },
      error: (error) => {
        console.error('Error while getting orderWorkflow', error.message);
      },
    });
  }

  private fetchAttachmentTypes()
  {
    this.workflowService.getAttachmentTypes().subscribe({
      next: (attachmentTypes) => {
        this.attachmentTypes = attachmentTypes;
      },
      error: (err) => {
        console.error('Error fetching attachment types:', err);
      }
    });
  }
  
  private fetchWorkflowStatuses()
  {
    this.workflowService.getWorkflowOptions().subscribe({
      next: (statuses) => {
        this.workflowStatuses = statuses;
      },
      error: (error) => {
        console.error('Error while getting workflow options', error);
      },
    });
  }

  updateRow(field: string, rowData: any)
  {
    this.saveToDatabase(rowData, field);
  }

  saveToDatabase(workflow: iOrderWorkflow, field: string)
  {
    const payload =
    {
      ...workflow,
      rush: workflow.rush ? 1 : 0,
      orderUuid: this.orderData.uuid, 
      field,
      assignSearcher: workflow.searcherId === null,
    };
  
    this.workflowService.saveWorkflow(payload, field).subscribe({
      next: (response) => {
        if (response.orderUpdated || response.assignmentUpdated) {
          this.fetchWorkflowData(); 
        }
      },
      error: (error) => console.error('Error processing workflow:', error),
    });
  }

  checkPriceLevel(rowData: any)
  {
    if (rowData.priceLevel === 0)
    {
      this.showPriceLevelDialog = true;
    } else
    {
      this.openDialog();
    }
  }

  openDialog()
  {
    this.displayDialog = true;
  }

  closeDialog()
  {
    this.displayDialog = false;
  }

  formatSize(bytes: number): string
  {
    if (bytes === 0)
    {
      return '0 B';
    }
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  removeFile(event, file, removeFileCallback, index)
  {
    removeFileCallback(event, index);
  }

  onAttachmentTypeChange(fileIndex: number, selectedType: iAttachmentType)
  {
    this.selectedAttachmentTypes[fileIndex] = selectedType;
  }

  onFileSelect(event: any)
  {
    if (!event.currentFiles || !Array.isArray(event.currentFiles)) 
    {
      console.error('Invalid file event structure:', event);
      return;
    }
  
    const defaultType = this.attachmentTypes.find(type => type.id === 'SYS_FINAL');
  
    event.currentFiles.forEach((file: File, index: number) => {
      const fileIndex = Object.keys(this.selectedAttachmentTypes).length;
      this.selectedAttachmentTypes[fileIndex] = defaultType ?? null;
    });
  }
  
  onFileUpload(event: any)
  {
    const uploadedFiles: File[] = event.files;
  
    uploadedFiles.forEach((file, index) => {
      const formData = new FormData();
  
      const randomSuffix = Math.random().toString(36).substring(2, 8);
      const fileKey = `${randomSuffix}_${file.name}`;

      const selectedType = this.selectedAttachmentTypes[index];
      const documentType = selectedType ? selectedType.id : 'SYS_FINAL';
  
      formData.append('fileKey', fileKey);
      formData.append('file', file);
      formData.append('orderUuid', this.orderData.uuid || '');
      formData.append('document_type', documentType);
      formData.append('file_name', file.name);
      formData.append('state', this.orderData.state);
      formData.append('county', this.orderData.county);
      formData.append('description', file.name);
      formData.append('sbl', this.orderData.sbl);
      formData.append('status', 'A');
      formData.append('scope', 'private')
      formData.append('insertWithoutOrderUuid', 'true')
  
      // console.log('FormData contents:');
      // formData.forEach((value, key) => console.log(`${key}:`, value));
  
      this.orderService.uploadDocument(formData).subscribe({
        next: (response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Upload Successful',
            detail: `File "${file.name}" uploaded successfully.`,
          });
          const dataForEmail: {} = 
          {
            'uuid': response.uuid,
            'filename': file.name
          }
          this.fileUploadCompleted.emit(dataForEmail);
          this.closeDialog();
        },
        error: (error) => {
          console.error(`Failed to upload file ${file.name}`, error);

          this.messageService.add({
            severity: 'error',
            summary: 'Upload Failed',
            detail: `Failed to upload file "${file.name}". Please try again.`,
          });
        },
      });
    });
  
    if (event.originalEvent?.target?.clear)
    {
      event.originalEvent.target.clear();
    } else
    {
      console.warn('Ensure the file upload component supports clear().');
    }
  }

  getActionButtonLink(report: string, orderWorkflowUuid: string): { path?: string; queryParams?: any; action?: () => void } | null
  {
    const runsheetWorkflows = ['runsheet'];
    const taxWorkflows = ['tax-search'];
  
    const reportList = report.split(',').map(r => r.trim());
  
    const result: { path?: string; queryParams?: any; action?: () => void } = {};
  
    if (reportList.some(r => runsheetWorkflows.includes(r)))
    {
      result.action = () => this.printRunSheetPDF(orderWorkflowUuid);
    }
  
    if (reportList.some(r => taxWorkflows.includes(r)))
    {
      result.path = '/tax-search';
      result.queryParams = { orderUuid: this.orderData.uuid, orderWorkflowUuid };
    }
  
    return Object.keys(result).length ? result : null;
  }
  
  printRunSheetPDF(orderWorkflowUuid: string)
  {
    this.oldOrderService.getRunSheetPDF(orderWorkflowUuid).subscribe({
      next: (response: any) => {
        const blob = new Blob([response.body], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        const newWindow = window.open(fileURL, '_blank');
        if (newWindow)
        {
          newWindow.onload = () => {
            setTimeout(() => {
              newWindow.print();
              URL.revokeObjectURL(fileURL);
            }, 100);
          };
        } else
        {
          console.error('Failed to open the PDF in a new tab.');
        }
      },
      error: (err) => {
        console.error('Error retrieving the PDF:', err);
      },
    });
  }  
}
