<h1 mat-dialog-title>Edit School</h1>
<mat-dialog-content>
<form class="example-form" [formGroup]="form">
    <p>
        <mat-form-field appearance="fill">
          <mat-label>School District Code</mat-label>
          <input matInput formControlName="schoolDistrictCode">
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="fill">
          <mat-label>School District Name</mat-label>
          <input matInput formControlName="schoolDistrictName">
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="fill">
          <mat-label>Address</mat-label>
          <textarea required matInput formControlName="address" class="textbox"></textarea>
          <mat-error *ngIf="form.controls.address?.invalid">Please Enter an Address</mat-error>
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="fill">
          <mat-label>Tax Year</mat-label>
          <input matInput formControlName="taxYear">
        </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="fill">
        <mat-label>Lien Date</mat-label>
        <input matInput formControlName="lienDate">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="fill">
        <mat-label>Tax Period</mat-label>
        <input matInput formControlName="taxPeriod">
      </mat-form-field>
    </p>

      <mat-form-field appearance="fill">
        <mat-label>Fiscal Start Date</mat-label>
        <input matInput [matDatepicker]="picker1" formControlName="fiscalStartDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Fiscal End Date</mat-label>
        <input matInput [matDatepicker]="picker2" formControlName="fiscalEndDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Due Date 1</mat-label>
        <input required matInput [matDatepicker]="picker3" formControlName="dueDate1">
        <mat-error *ngIf="form.controls.dueDate1?.invalid">Please Enter a Date</mat-error>
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Due Date 2</mat-label>
        <input matInput [matDatepicker]="picker4" formControlName="dueDate2">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Due Date 3</mat-label>
        <input matInput [matDatepicker]="picker5" formControlName="dueDate3">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
        <mat-datepicker #picker5></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Due Date 4</mat-label>
        <input matInput [matDatepicker]="picker6" formControlName="dueDate4">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
        <mat-datepicker #picker6></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Run Date</mat-label>
        <input required matInput [matDatepicker]="picker7" formControlName="refreshDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker7"></mat-datepicker-toggle>
        <mat-datepicker #picker7></mat-datepicker>
      </mat-form-field>    
</form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button class="save-button" (click)="save()">Save</button>
    <button mat-raised-button mat-dialog-close class="cancel-button">Cancel</button>
</mat-dialog-actions>