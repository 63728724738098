import { Observable, throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError, timeout } from 'rxjs/operators';
import { SystemService } from './system.service';
import { Router } from '@angular/router';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

    constructor(
        private injector: Injector,
    ) {
    }

    wrapErrorHandling(handled) {
        return handled.pipe(
            timeout(60000),
            catchError((error: HttpErrorResponse) => {
                var systemService = this.injector.get(SystemService);
                let errorMessage = '';
                if (error.error instanceof ErrorEvent) {
                    // client-side error
                    errorMessage = `Error: ${error.error.message}`;
                } else {
                    // server-side error
                    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                }
                var eMsg = error.headers.get('error_message')
                if (eMsg) {
                    errorMessage += " - " + eMsg
                }
                systemService.clientError.push(errorMessage);
                this.injector.get(Router).navigate(['system-error']);
                return throwError(errorMessage);
            })
        );
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var token = localStorage.getItem('authToken');
        if (token) {
            return this.wrapErrorHandling(next.handle(req.clone({
                setHeaders: {
                    'Authorization': token,
                }
            })));
        } else {
            return this.wrapErrorHandling(next.handle(req));
        }
    }
}
