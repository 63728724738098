import { HttpClient } from "@angular/common/http";
import { SYSSCHOOL } from "../mock/sys-school-mock";
import { Observable, of } from "rxjs";
import { SysSchool } from "../model/sys-school.model";
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class SchoolServiceMock
{

    constructor(private http: HttpClient) 
    { 
        // Nothing to do
    }

    //update school
    updateSchool(school: SysSchool): Observable<SysSchool>
    {
        let result = SYSSCHOOL.find(c => c.schoolDistrictCode === school.schoolDistrictCode);
        result.schoolDistrictCode = school.schoolDistrictCode
        result.schoolDistrictName = school.schoolDistrictName
        result.address = school.address
        result.taxYear = school.taxYear;
        result.taxPeriod = school.taxPeriod;
        result.lienDate = school.lienDate;
        result.fiscalStartDate = school.fiscalStartDate;
        result.fiscalEndDate = school.fiscalEndDate;
        result.dueDate1 = school.dueDate1;
        result.dueDate2 = school.dueDate2;
        result.dueDate3 = school.dueDate3;
        result.dueDate4 = school.dueDate4;
        result.refreshDate = school.refreshDate;
        return of(result)
    }

    //get school list
    getSchools(): Observable<SysSchool[]>
    {
        return of(SYSSCHOOL);
    }
}