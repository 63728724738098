<div class="row pb-1">
    <div class="col pt-4 pb-3 attribute-h1 bold" align="center">
        Order Documents
    </div>
</div>
<div class="row pt-3 pb-2 data-border">
    <div class="col-9 attribute-h1">
        Documents Attached to Order ID {{paramOID}}
    </div>
    <div class="col-3 pt-3">
        <span class="button pointer" (click)="scrollToAdd()">
            <fa-icon [icon]="faMagic" size="1x"></fa-icon>
            Attach File
        </span>
    </div>
</div>

<div class="row pb-2 data-border py-2" *ngIf="!orderDocuments || orderDocuments.length == 0">
    <div class="col">
        None
    </div>
</div>
<div class="row data-border py-2 header-row" *ngIf="orderDocuments && orderDocuments.length > 0">
    <div class="col-3">
        Description
    </div>
    <div class="col-2">
        Type
    </div>
    <div class="col-3">
        Created
    </div>
    <div class="col-2">
    </div>
    <div class="col-2">
    </div>
</div>
<span *ngFor="let orderDoc of orderDocuments; let i = index">
    <div class="row data-border py-2" [ngClass]="{'grey-background': i % 2 == 1}">
        <div *ngIf="!descriptionEdits[i]" title="{{orderDoc.description}}" class="col-2 text-truncate link pr-0"
            (click)="download(orderDoc)">
            {{orderDoc.description}}
        </div>
        <div *ngIf="descriptionEdits[i]" class="col-2 pl-0">
            <input class="pl-2" id="description_edit_{{i}}" type="text" value="{{orderDoc.description}}"
                style="width: 100%" />
        </div>
        <div *ngIf="!descriptionEdits[i]" class="col-1 pl-0">
            <fa-icon [icon]="faEdit" size="1x" (click)="descriptionEdits[i] = true;"></fa-icon>
        </div>
        <div *ngIf="descriptionEdits[i]" class="col-1 pl-0">
            <fa-icon [icon]="faCheck" size="1x" (click)="saveDescription(orderDoc, i); descriptionEdits[i] = false;">
            </fa-icon>
            <fa-icon class="ml-2" [icon]="faBan" size="1x" (click)="descriptionEdits[i] = false;"></fa-icon>
        </div>
        <div class="col-2">
            {{getDocumentTypeDisplay(orderDoc.document_type)}}
        </div>
        <div class="col-3">
            {{orderDoc.created | date: 'MM/dd/yyyy h:mm:ss a'}}
        </div>
        <div *ngIf="!isFinal(orderDoc)" class="col-2">
        </div>
        <div *ngIf="!emailDocs[orderDoc.guid] && isFinal(orderDoc)" class="col-2">
            <span class="link" (click)="email(orderDoc)">
                <fa-icon [icon]="faEnvelope" size="1x" style="margin-right: .5em"></fa-icon>Email File
            </span>
        </div>
        <div *ngIf="emailDocs[orderDoc.guid]" class="col-2">
            <span class="link" (click)="cancelEmail(orderDoc)">
                <fa-icon [icon]="faBan" size="1x" style="margin-right: .5em"></fa-icon>Cancel Email File
            </span>
        </div>
        <div class="col-2">
            <span class="link" (click)="deleteOrderFile(orderDoc)">
                <fa-icon [icon]="faTrashAlt" size="1x" style="margin-right: .5em"></fa-icon>Delete
            </span>
        </div>
    </div>
    <!-- <div *ngIf="emailDocs[orderDoc.guid] && tooLargeToEmail(orderDoc.file_size)" class="row py-2"
        [ngClass]="{'grey-background': i % 2 == 1}">
        <div class="col-2">
        </div>
        <div class="col-8" style="text-align: center;">
            <span style="font-weight: bold;">Important Note</span><br />
            Due to file size limits, large files cannot be directly emailed though this web page.<br />
            Please download the file and attach it to a new message within your email client (Outlook).<br />
            Please copy and paste the to, subject, and body into your email.<br />
        </div>
        <div class="col-2">
        </div>
    </div>
    <div *ngIf="emailDocs[orderDoc.guid]" class="row py-2" [ngClass]="{'grey-background': i % 2 == 1}">
        <div class="col-4" style="text-align: right;">
            Subject:
        </div>
        <div class="col-8">
            {{getSubject(orderDoc)}}
        </div>
    </div>
    <div *ngIf="emailDocs[orderDoc.guid]" class="row py-2" [ngClass]="{'grey-background': i % 2 == 1}">
        <div class="col-4" style="text-align: right;">
            To:
        </div>
        <div class="col-8">
            {{paramTo}}
        </div>
    </div>
    <div *ngIf="emailDocs[orderDoc.guid]" class="row pt-2" [ngClass]="{'grey-background': i % 2 == 1}">
        <div class="col-4" style="text-align: right;">
            Cc:
        </div>
        <div class="col-8">
            <input type="text" id="cc_{{orderDoc.guid}}" style="width: 400px;" />
        </div>
    </div>
    <div *ngIf="emailDocs[orderDoc.guid]" class="row pb-2" [ngClass]="{'grey-background': i % 2 == 1}">
        <div class="col-4" style="text-align: right;">
        </div>
        <div class="col-8">
            <span style="font-size: .8em">
                Separate email addresses by comma.
            </span>
        </div>
    </div>
    <div *ngIf="emailDocs[orderDoc.guid]" class="row py-2" [ngClass]="{'grey-background': i % 2 == 1}">
        <div class="col-4" style="text-align: right;">
            Bcc:
        </div>
        <div class="col-8">
            {{paramBcc}}
        </div>
    </div>
    <div *ngIf="emailDocs[orderDoc.guid]" class="row py-2" [ngClass]="{'grey-background': i % 2 == 1}">
        <div class="col-4" style="text-align: right;">
            Body:
        </div>
        <div class="col-8">
            <textarea id="body_{{orderDoc.guid}}" style="resize: both;-webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box; width: 400px;" rows="9">{{getEmailBody(orderDoc)}}</textarea>
        </div>
    </div>
    <div *ngIf="emailDocs[orderDoc.guid]" class="row py-2" [ngClass]="{'grey-background': i % 2 == 1}">
        <div class="col-4" style="text-align: right;">
            File Name:
        </div>
        <div class="col-8">
            {{orderDoc.file_name}}
        </div>
    </div>
    <div *ngIf="emailDocs[orderDoc.guid]" class="row data-border py-2" [ngClass]="{'grey-background': i % 2 == 1}">
        <div class="col-12" style="text-align: center;">
            <span *ngIf="!sendingDocs[orderDoc.guid]" class="button mr-2" (click)="cancelEmail(orderDoc);">
                <fa-icon [icon]="faBan" size="1x" style="margin-right: .5em"></fa-icon>Cancel
            </span>
            <span *ngIf="!tooLargeToEmail(orderDoc.file_size) && !sendingDocs[orderDoc.guid]" class="button"
                (click)="sendEmail(orderDoc);">
                <fa-icon [icon]="faShare" size="1x" style="margin-right: .5em"></fa-icon>Send
            </span>
            <span *ngIf="tooLargeToEmail(orderDoc.file_size)" class="button-disabled">
                <fa-icon [icon]="faBan" size="1x" style="margin-right: .5em"></fa-icon>File too large to send
            </span>
            <span *ngIf="sendingDocs[orderDoc.guid]" class="button">
                <fa-icon [icon]="faHourglass" size="1x" style="margin-right: .5em"></fa-icon>Sending...
            </span>
        </div>
    </div> -->
</span>
<div class="row py-2 data-border" *ngIf="orderDocuments && orderDocuments.length > 0">
    <div class="col-8">
    </div>
    <div class="col-4">
        <span class="link pointer" (click)="downloadAll()">
            <fa-icon [icon]="faFileDownload" size="1x" style="margin-right: .5em"></fa-icon>Download All Files
        </span>
    </div>
</div>

<div class="row pt-3 pb-2 data-border">
    <div class="col attribute-h1">
        Documents Associated with SBL {{paramSBL}}
    </div>
</div>
<div class="row pb-2" *ngIf="!sblDocuments || sblDocuments.length == 0">
    <div class="col">
        None
    </div>
</div>
<div class="row data-border py-2 header-row" *ngIf="sblDocuments && sblDocuments.length > 0">
    <div class="col">
        Order #
    </div>
    <div class="col">
        Description
    </div>
    <div class="col">
        Type
    </div>
    <div class="col">
        Created
    </div>
</div>
<div class="row data-border py-2" *ngFor="let doc of sblDocuments; let i = index"
    [ngClass]="{'grey-background': i % 2 == 1}">
    <div class="col">
        {{doc.kcs_order_id}}
    </div>
    <div class="col link text-truncate" title="{{doc.description}}" (click)="download(doc)">
        {{doc.description}}
    </div>
    <div class="col">
        {{getDocumentTypeDisplay(doc.document_type)}}
    </div>
    <div class="col">
        {{doc.created | date: 'MM/dd/yyyy'}}
    </div>
</div>

<span *ngIf="percentDone != null" class="mt-4 mb-3">
    <div class="row mt-3 py-2 ">
        <div class="col title">
            {{uploadStatus}}
        </div>
    </div>
    <div class="row py-2 ">
        <div class="col">
            <div style="position: relative; height: 1.6em;">
                <div [ngStyle]="{ 'background-color': 'lightblue', 'width': percentDone + '%'}"
                    style="height: 1.6em; position: absolute; left: 0; top: 0;">
                </div>
                <div [ngStyle]="{ 'width':  '100%'}"
                    style="height: 1.6em; position: absolute; left: 0; top: 0; font-weight: bold;text-align: center;">
                    {{percentDone}}%
                </div>
                <div
                    style="width: 100%; border-style: solid; border-width: 1px; border-color: black; height: 1.6em; position: absolute; left: 0; top: 0;">
                </div>
            </div>
        </div>
    </div>
</span>

<span *ngIf="showAddContainer" class="mt-4 mb-3">
    <div class="row pt-2" id="addTitle">
        <div class="col" style="font-weight: bold;">
            Attachment Type
        </div>
    </div>
    <div class="row">
        <div class="col" style="font-weight: bold;">
            <select style="border-color: lightgrey;" id="newfile_type">
                <option value="-1">Please choose an option...</option>
                <option *ngFor="let documentType of documentTypes; let i = index" value="{{documentType.id}}">
                    {{documentType.name}}
                </option>
            </select>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col" style="font-weight: bold;">
            Additional Attachment Description
        </div>
    </div>
    <div class="row">
        <div class="col" style="font-weight: bold;">
            <input type="text" style="width: 60%; border-color: lightgrey;" id="newfile_description" />
        </div>
    </div>
    <div class="row">
        <div class="col mt-2" style="font-weight: bold;">
            <div class="p-2" style="border-style: solid; border-width: 1px; border-color: lightgrey; width: 60%">
                <input type="file" [formControl]="fileFormControl" id="file"
                    (change)="handleFileInput($event.target.files)">
            </div>
        </div>
    </div>
    <div *ngIf="uploadError" class="row">
        <div class="col" style="font-weight: bold; color: red;">
            {{uploadError}}
        </div>
    </div>
    <div class="row">
        <div class="col" style="font-weight: bold;">
            <span class="p-2 mt-2 mb-2" (click)="upload()"
                style="border-radius: .5em; border-style: solid; border-width: 1px; border-color: lightgrey; cursor: pointer; display: inline-block; color: white; background-color: green;">
                <fa-icon [icon]="faUpload" size="1x"></fa-icon> Upload
            </span>
            <span *ngIf="tempDocuments && tempDocuments.length > 0" class="p-2 mt-2 mb-2" (click)="finishUpload()"
                style="border-radius: .5em; border-style: solid; border-width: 1px; border-color: lightgrey; cursor: pointer; display: inline-block; color: white; background-color: #012f5b;">
                <fa-icon [icon]="faUpload" size="1x"></fa-icon> Finish Upload
            </span>
        </div>
    </div>
</span>

<span *ngIf="showAddContainer" class="mt-4 mb-3">
    <div class="row mt-3 data-border py-2 " *ngIf="tempDocuments && tempDocuments.length > 0">
        <div class="col attribute-h1">
            Documents Ready To Attach
        </div>
    </div>
    <div class="row data-border py-2 header-row" *ngIf="tempDocuments && tempDocuments.length > 0">
        <div class="col-2">
            Document Type
        </div>
        <div class="col-3">
            Description
        </div>
        <div class="col-2">
            Date Uploaded
        </div>
        <div class="col-1">
            Type
        </div>
        <div class="col-2">
            Size
        </div>
        <div class="col-2">
            Action
        </div>
    </div>
    <div class="row  data-border py-2" *ngFor="let doc of tempDocuments; let i = index"
        [ngClass]="{'grey-background': i % 2 == 1}">
        <div class="col-2">
            {{doc.document_type}}
        </div>
        <div *ngIf="!tempDescriptionEdits[i]" class="col-2 text-truncate" title="{{doc.description}}">
            {{doc.description}}
        </div>
        <div *ngIf="tempDescriptionEdits[i]" class="col-2">
            <input class="pl-2" id="temp_description_edit_{{i}}" type="text" value="{{doc.description}}" />
        </div>
        <div *ngIf="!tempDescriptionEdits[i]" class="col-1">
            <fa-icon [icon]="faEdit" size="1x" (click)="tempDescriptionEdits[i] = true;"></fa-icon>
        </div>
        <div *ngIf="tempDescriptionEdits[i]" class="col-1">
            <fa-icon [icon]="faCheck" size="1x" (click)="saveTempDescription(doc, i); tempDescriptionEdits[i] = false;">
            </fa-icon>
            <fa-icon class="ml-2" [icon]="faBan" size="1x" (click)="tempDescriptionEdits[i] = false;"></fa-icon>
        </div>
        <div class="col-2">
            {{doc.created | date: 'MM/dd/yyyy'}}
        </div>
        <div class="col-1">
            {{doc.mime_type}}
        </div>
        <div class="col-2">
            {{doc.file_size | fileSize}}
        </div>
        <div class="col-2">
            <span class="link" (click)="deleteTempFile(doc);">
                <fa-icon [icon]="faTrashAlt" size="1x"></fa-icon> Delete
            </span>
        </div>
    </div>
</span>