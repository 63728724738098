import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from '../theme.service';
import { HttpClient } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';
import { UserService } from '../user.service';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { AccountService } from '../account.service';
import { AuthService } from '../auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SessionService } from '../session.service';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    faEnvelope = faEnvelope;
    faLock = faLock;
    @ViewChild("username", { read: ElementRef, static: true }) username: ElementRef;
    @ViewChild("password", { read: ElementRef, static: true }) password: ElementRef;
    @ViewChild("loginButton", { read: ElementRef, static: true }) loginButton: ElementRef;

    constructor(
        private _themeService: ThemeRebrandService,
        private activatedRoute: ActivatedRoute,
        private httpClient: HttpClient,
        private userService: UserService,
        private _accountService: AccountService,
        private authService: AuthService,
        private router: Router,
        private sessionService: SessionService
    ) { }

    get accountService() {
        return this._accountService;
    }

    loginUsername;
    loginError = false;
    loginMessage;
    params;

    private next;
    private dest;

    ngOnInit() {
        //this._themeService.triggerTheme();
        document.body.className = "withBackground";
        this.loginUsername = localStorage.getItem("loginUsernameStored");

        //do logout
        localStorage.removeItem("authToken");

        this.activatedRoute.queryParams.subscribe(params => {
            this.params = params;
            if (params.next) {
                this.next = params.next;
            }
            if (params.dest) {
                this.dest = params.dest;
            }
            if (params.loginMessage) {
                this.loginMessage = params.loginMessage;
            }
        });
    }

    ngOnDestroy() {
        document.body.className = "";
    }

    ngAfterViewInit(): void {
        if (this.loginUsername) {
            this.password.nativeElement.focus();
        } else {
            this.username.nativeElement.focus();
        }
    }

    get themeService() {
        return this._themeService;
    }

    get destination() {
        return this.dest;
    }

    remembermeChanged(event) {
        if (event.target.checked) {
            localStorage.setItem("loginUsernameStored", (<HTMLInputElement>this.username.nativeElement).value);
        } else {
            localStorage.removeItem("loginUsernameStored");
        }
    }

    loginFailure = false;
    loginUser(event) {
        event.preventDefault();
        this.loginFailure = false;
        this.loginError = false;
        this.loginMessage = null;

        var target = event.target;
        var username = target.username.value;
        var password = target.password.value;
        (<HTMLInputElement>this.password.nativeElement).value = "";

        //note: this is not redundant to remembermeChanged
        if (target.rememberme.checked) {
            localStorage.setItem("loginUsernameStored", username);
        } else {
            localStorage.removeItem("loginUsernameStored");
        }

        var hashedPass = new Md5().appendStr(password).end();

        this.httpClient.post('api/auth', {
            username: username,
            password: hashedPass
        }).subscribe((response: any) => {
            //console.log("auth response", response);
            if (response.success) {
                localStorage.setItem("authToken", response.token);
                sessionStorage.setItem("access_token", response.access_token)
                const helper = new JwtHelperService()
                const claims = helper.decodeToken(response.access_token)
                console.log(claims)

                this.sessionService.setClaims(claims)
                console.log(this.sessionService.getClaims())

                if (this.next) {
                    var newLocation = this.next + "?dest=" + this.dest + "&token=" + response.token;

                    for (var property in this.params) {
                        if (this.params.hasOwnProperty(property)) {
                            if (property != "dest" && property != "next") {
                                newLocation += "&" + property + "=" + this.params[property];
                            }
                        }
                    }
                    window.location.href = newLocation;
                } else if (this.dest) {
                    var newLocation = this.dest + "?token=" + response.token;

                    for (var property in this.params) {
                        if (this.params.hasOwnProperty(property)) {
                            if (property != "dest" && property != "next") {
                                newLocation += "&" + property + "=" + this.params[property];
                            }
                        }
                    }
                    window.location.href = newLocation;
                } else {
                    this.authService.stillLoggedInCheck();
                    this.userService.navigateToInitialPage();
                }
            } else {
                this.loginFailure = true;
            }
        });
    }

    signupClick() {
        this.accountService.setOriginalSteps()
        this.router.navigate([this.accountService.getFirstStepRoute()])
    }
}
