import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { iAgentOrder } from "../model/agent-order.model";
import { iOrderWorkflow } from "../model/workflow.model";
import { ApiResponse } from "../model/api-responce.model";
import { iOrder } from "../model/order.model";
import { iOrderServiceDropdown } from "../model/order-service-dropdown.model";

@Injectable({
    providedIn: 'root'
})

export class OrdersService
{
    constructor(private http: HttpClient) 
    { 
        // Nothing to do
    }

    getOrdersbyAgentId(agentId: string): Observable<iAgentOrder[]>
    {
        return this.http.get<iAgentOrder[]>('api/ordersByAgentId/' + agentId)
    }

    getAdditionalCostOptions(pid: string)
    {
        return this.http.get<any>("api/additionalCostOption/" + pid)
    }

    getOrderWorkflowByOrderUuid(orderUuid: string): Observable<iOrderWorkflow[]>
    {
        return this.http
            .get<ApiResponse<iOrderWorkflow[]>>(`/api/orderWorkflow/${orderUuid}`)
            .pipe(map(ApiResponse.unwrapApiResponse));
    }

    getOrderServiceDropdown(): Observable<iOrderServiceDropdown[]>
    {
        return this.http
            .get<ApiResponse<iOrderServiceDropdown[]>>(`/api/orderServiceDropdown`)
            .pipe(map(ApiResponse.unwrapApiResponse));
    }

    getOrderSearch(filters: { [key: string]: { value: string; type: string } }): Observable<any>
    {
        let params = new HttpParams();
        Object.keys(filters).forEach((key) => {
            if (filters[key])
            {
                params = params.set(key, JSON.stringify(filters[key]));
            }
        });
        return this.http
            .get<ApiResponse<iOrder[]>>(`/api/orderSearch`, { params })
            .pipe(map(ApiResponse.unwrapApiResponse));
    }

    saveOrderParticipants(orderData: any): Observable<any>
    {
        return this.http.post(`/api/saveOrderParticipants`, orderData);
    }    

    uploadDocument(formData: FormData): Observable<any>
    {
        return this.http.put('/api/documentUpload', formData,);
    }

    updateOrderStatus(statusData: any)
    {
        return this.http.post('/api/updateOrderStatus', statusData,);  
    }
}
