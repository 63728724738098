import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';

@Component({
    selector: 'app-initial-page',
    templateUrl: './initial-page.component.html',
})
export class InitialPageComponent implements OnInit {

    constructor(
        private userService: UserService,
    ) { }

    ngOnInit() {
        this.userService.navigateToInitialPage();
    }
}
