<span *ngIf="!initialized">
    <div class="container-fluid bordered-container mt-4" [style.background-color]="themeService.tertiaryColor"
        [style.border-color]="themeService.tertiaryColor">
        Initializing
    </div>
</span>
<span *ngIf="initialized">
    <div class="container-fluid mt-4 py-2 rounded-ninety" [style.background-color]="themeService.tertiaryColor">

        <div class="row">
            <div class="col-2">

            </div>
            <div class="col-8">
                <app-input-enum label="Feedback Type" [fc]="typeFC" [enumValues]="types">
                </app-input-enum>

                <app-input-text-area label="Feedback" [fc]="feedbackFC">
                </app-input-text-area>

            </div>
        </div>
        <div class="row">
            <div class="col-2">
            </div>
            <div class="col-8">
                <div class="row pt-3 pb-2">
                    <div class="col pb-2" align="center">
                        <app-button [buttonType]="ButtonType.cancel" [clickFunction]="cancel.bind(this)">
                        </app-button>
                        <app-button label="Send" [clickFunction]="send.bind(this)" [icon]="faShare"
                            [buttonType]="ButtonType.save">
                        </app-button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</span>