<div *ngIf="companyData$ | async as companyData" class="container-fluid pt-2">
    <div class="row">
        <div class="col-2 d-none d-md-block">
        </div>
        <div class="col-12 col-md-8">

            <div class="row">
                <div class="col bold" style="font-size: 1.6em; color: #117ac9">
                    Client Details
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <app-input-image label="Company Logo" [fc]="formControls['company_logo']"
                        [supportedTypes]="['image/jpeg']" [disabled]="isReadOnly">
                    </app-input-image>
                    <span *ngIf="formErrors['company_logo']"
                        class="validation-error mt-1">{{formErrors['company_logo']}}</span>
                </div>
                <div class="col-md">
                    <app-input-text label="Web Address" [fc]="formControls['web_address']" [disabled]="isReadOnly"
                        placeholder="e.g. https://www.example.com">
                    </app-input-text>
                    <span *ngIf="formErrors['web_address']"
                        class="validation-error mt-1">{{formErrors['web_address']}}</span>
                    <app-input-text-area label="Address" [fc]="formControls['address']" [disabled]="isReadOnly" rows="3"
                        placeholder="Please enter the company's physical address.">
                    </app-input-text-area>
                    <span *ngIf="formErrors['address']" class="validation-error mt-1">{{formErrors['address']}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <app-input-text label="Company Name" [fc]="formControls['name']" [disabled]="isReadOnly"
                        placeholder="Please enter the company name." tooltip="This is a company name test tooltip.">
                    </app-input-text>
                    <span *ngIf="formErrors['name']" class="validation-error mt-1">{{formErrors['name']}}</span>
                </div>
                <div class="col-md">
                    <app-input-enum label="Default Time Zone" [fc]="formControls['default_time_zone']"
                        [enumValues]="timezones$ | async" [disabled]="isReadOnly">
                    </app-input-enum>
                    <span *ngIf="formErrors['default_time_zone']"
                        class="validation-error mt-1">{{formErrors['default_time_zone']}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <app-input-text label="Phone Number" [fc]="formControls['phone']" [change]="phoneUpdate.bind(this)"
                        [disabled]="isReadOnly" [placeholder]="phoneExampleText">
                    </app-input-text>
                    <span *ngIf="formErrors['phone']" class="validation-error mt-1">{{formErrors['phone']}}</span>
                </div>
                <div class="col-md">
                    <app-input-text label="Fax Number" [fc]="formControls['fax']" [change]="faxUpdate.bind(this)"
                        [disabled]="isReadOnly" [placeholder]="phoneExampleText">
                    </app-input-text>
                    <span *ngIf="formErrors['fax']" class="validation-error mt-1">{{formErrors['fax']}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <app-input-text label="Email for invoices" [fc]="formControls['invoice_email_address']">
                    </app-input-text>
                    <span *ngIf="formErrors['invoice_email_address']" class="validation-error mt-1">{{formErrors['invoice_email_address']}}</span>
                </div>
                <div class="col-md">
                    <!--
                    <app-input-text label="Payment terms" [fc]="formControls['paymentTerms']">
                    </app-input-text>
                    -->
                    <app-input-enum label="Payment Terms" [fc]="formControls['default_payment_term_id']" [enumValuesNameAndId]="dueDates">
                    </app-input-enum>
                    <span *ngIf="formErrors['default_payment_term_id']" class="validation-error mt-1">{{formErrors['default_payment_term_id']}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md" *ngIf="salesTaxJurisdictions">
                    <!--<app-input-text label="Sales Tax Jurisdiction" [fc]="formControls['taxJurisdictionName']"></app-input-text>-->
                    <app-input-search label="Sales Tax Jurisdiction" [fc]="jurisdictionName"
                    [searchFn]="searchJurisdictions.bind(this)"
                    [getItemDisplayValue]="getItemJurisdictionName.bind(this)"
                    [itemSelect]="selectJurisdiction.bind(this)">
                    </app-input-search>
                    <span *ngIf="formErrors['jurisdictionName']" class="validation-error mt-1">{{formErrors['jurisdictionName']}}</span>
                </div>
                <div class="col-md"></div>
            </div>

            <div class="row">
                <div class="col">
                    <app-input-text-area label="Client Notes (internal)" [fc]="formControls['internal_billing_notes']">
                    </app-input-text-area>
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="anyError" class="row mt-3 validation-error-banner py-2">
        <div class="col-12" align="center">
            <fa-icon [icon]="faExclamationCircle"></fa-icon>
            Please address errors above and re-submit.
        </div>
    </div>

    <div class="row pt-3">
    </div>
    <app-button-group-create *ngIf="isNew" [create]="save.bind(this)" [cancel]="back.bind(this)">
    </app-button-group-create>
    <app-button-group-save *ngIf="!isNew" [save]="save.bind(this)" [cancel]="back.bind(this)"
        [delete]="delete.bind(this, companyData)"></app-button-group-save>

</div>