import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AboutService 
{
    private aboutUrl: string;

    // constructor(private http: HttpClient) { 
    //     this.aboutUrl = `${environment.apiUrl}/afl/api/about-json`;
    // }

    // public getConfiguration(): string{
    //     return environment.configuration;
    // }

    // getAboutData(): Observable<About>
    // {
    //     return this.http.get<About>(this.aboutUrl);
    // }
}
