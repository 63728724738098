import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Inject, Injectable, Input, Renderer2 } from '@angular/core';
import { ThemeService } from '../theme.service';
import { Router } from '@angular/router';
import { fromEvent, Observable, Subscription } from "rxjs";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../user.service';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { ThemeProviderOrderCreateService } from '../theme-provider-order-create.service';

@Component({
    selector: 'app-order-create-step-circles',
    templateUrl: './order-create-step-circles.component.html',
    styleUrls: ['./order-create-step-circles.component.scss']
})
export class OrderCreateStepCirclesComponent implements OnInit {
    @Input() stepProvider;
    @Input() disableStepLinks;
    @Input() orderType

    faCheck = faCheck;

    resizeObservable$;
    resizeSubscription$;
    currentView = 0;
    colWidth = localStorage.getItem("step_circles_col_width");

    constructor(
        private _themeService: ThemeService,
        private renderer: Renderer2,
        private router: Router,
        //private stepProvider: OrderService,
        private ref: ChangeDetectorRef,
        private userService: UserService,
        private _themeRebrandService: ThemeProviderOrderCreateService,
    ) {
    }

    get steps(): any {
        return this.stepProvider.getSteps();
    }

    ngOnInit() {
        this.stepProvider.stepComponent = this;

        console.log(this.stepProvider)

        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
            this.ref.detectChanges();
        });
        for (var i = 0; i < this.steps.length; ++i) {
            if (this.router.url == this.steps[i].route) {
                this.currentView = i;
            }
        }
    }

    get themeService() {
        return this._themeRebrandService;
    }

    isStepPassed(i) {
        return i < this.currentView;
    }

    getCircleNumber(i) {
        var counter = 0;
        for (var j = 0; j < this.steps.length && j < i; ++j) {
            if (!this.steps[j].hidden) {
                ++counter;
            }
        }
        return counter + 1;
    }

    stepClick(newViewIndex) {
        if (this.disableStepLinks) {
            return;
        }
        this.router.navigate([this.steps[newViewIndex].route]);
    }

    getBeforeClass() {
        return { width: this.beforeWidth(), left: this.beforeLeft(), "subsequent-circle-before": true };
    }

    getCircleClass(step, i) {
        var classes = { 'link': !this.disableStepLinks, 'first-circle': i == 0, 'subsequent-circle': i != 0, 'hidden': step.hidden };
        if (i == this.currentView) {
            classes.link = false;
        }
        return classes;
    }

    getColWidth() {

        //get the first two non-hidden steps
        var firstStep;
        var secondStep;
        for (var i = 0; i < this.steps.length; ++i) {
            if (this.steps[i].hidden) {
                continue;
            }
            if (!firstStep) {
                firstStep = this.steps[i];
            } else {
                secondStep = this.steps[i];
                break;
            }
        }

        if (!firstStep.col) {
            if (!this.colWidth) {
                return "0";
            }
            return this.colWidth;
        }
        var calculated = (firstStep.col.nativeElement.offsetWidth - (secondStep.circle.nativeElement.offsetWidth)) + "";
        if (calculated != "0") {
            this.colWidth = calculated;
        }
        if (!this.colWidth) {
            this.colWidth = "0";
        }
        localStorage.setItem("step_circles_col_width", this.colWidth);
        return this.colWidth;
    }

    beforeWidth() {
        return this.getColWidth() + "px";
    }

    beforeLeft() {
        return (-1 * (parseInt(this.getColWidth()) + 2)) + "px"
    }

    circleMarginLeft(i) {
        if (i == 0) {
            return "0px";
        }
        return this.getColWidth() + "px";
    }

    resetCircleStyles() {
        for (var i = 0; i < this.steps.length; ++i) {
            this.renderer.setStyle(this.steps[i].col.nativeElement, 'color', 'white');
            this.themeService.backgroundColor == null ? this.renderer.removeStyle(this.steps[i].circle.nativeElement, 'color') : this.renderer.setStyle(this.steps[i].circle.nativeElement, 'color', this.themeService.backgroundColor);
            this.renderer.setStyle(this.steps[i].circle.nativeElement, 'background-color', 'white');
            this.themeService.tertiaryColor == null ? this.renderer.removeStyle(this.steps[i].circle.nativeElement, 'border-color') : this.renderer.setStyle(this.steps[i].circle.nativeElement, 'border-color', this.themeService.tertiaryColor);
            if (this.steps[i].before != null) {
                this.themeService.tertiaryColor == null ? this.renderer.removeStyle(this.steps[i].before.nativeElement, 'background-color') : this.renderer.setStyle(this.steps[i].before.nativeElement, 'background-color', this.themeService.tertiaryColor);
            }
        }
    }

    applyCircleStyles() {
        for (var i = 0; i < this.steps.length; ++i) {
            if (i == this.currentView) {
                this.themeService.backgroundColor == null ? this.renderer.removeStyle(this.steps[i].col.nativeElement, 'color') : this.renderer.setStyle(this.steps[i].col.nativeElement, 'color', this.themeService.backgroundColor);
                this.themeService.backgroundColor == null ? this.renderer.removeStyle(this.steps[i].circle.nativeElement, 'color') : this.renderer.setStyle(this.steps[i].circle.nativeElement, 'color', this.themeService.backgroundColor);
                this.themeService.backgroundColor == null ? this.renderer.removeStyle(this.steps[i].circle.nativeElement, 'border-color') : this.renderer.setStyle(this.steps[i].circle.nativeElement, 'border-color', this.themeService.backgroundColor);
                if (this.steps[i].before != null) {
                    this.themeService.backgroundColor == null ? this.renderer.removeStyle(this.steps[i].before.nativeElement, 'background-color') : this.renderer.setStyle(this.steps[i].before.nativeElement, 'background-color', this.themeService.backgroundColor);
                }
                break;
            } else {
                this.renderer.setStyle(this.steps[i].circle.nativeElement, 'color', 'white');
                this.themeService.backgroundColor == null ? this.renderer.removeStyle(this.steps[i].circle.nativeElement, 'background-color') : this.renderer.setStyle(this.steps[i].circle.nativeElement, 'background-color', this.themeService.backgroundColor);
                this.themeService.backgroundColor == null ? this.renderer.removeStyle(this.steps[i].circle.nativeElement, 'border-color') : this.renderer.setStyle(this.steps[i].circle.nativeElement, 'border-color', this.themeService.backgroundColor);
                this.themeService.backgroundColor == null ? this.renderer.removeStyle(this.steps[i].col.nativeElement, 'color') : this.renderer.setStyle(this.steps[i].col.nativeElement, 'color', this.themeService.backgroundColor);
                if (this.steps[i].before != null) {
                    this.themeService.backgroundColor == null ? this.renderer.removeStyle(this.steps[i].before.nativeElement, 'background-color') : this.renderer.setStyle(this.steps[i].before.nativeElement, 'background-color', this.themeService.backgroundColor);
                }
            }
        }
    }

    updateCircleStylesForPage() {
        this.resetCircleStyles();
        this.applyCircleStyles();
    }
}
