<div class="container-fluid pt-1" *ngFor="let listView of listViewEntries; let listViewIndex = index">
    <span *ngIf="initialized">
        <div *ngIf="showTeams" class="row">
            <div class="col">
                <span style="float: right;">
                    Team <app-input-enum label="Team" [fc]="teamFC" [enumValuesNameAndId]="teams"
                        [format]="InputEnumFormat.none" [change]="teamChange.bind(this)" [includeUnset]="true"
                        [includeUnsetDisplay]="'(Unassigned)'">
                    </app-input-enum>
                </span>
            </div>
        </div>
        <app-dashboard-controls [title]="listView.title" [pageNumber]="lists[listViewIndex].currentPage"
            [pageSize]="lists[listViewIndex].limit" [entryCount]="lists[listViewIndex].count"
            [setPage]="this.setPage.bind(this, listViewIndex)">
        </app-dashboard-controls>

        <div class="row">
            <div class="col">
                <table class="table table-striped">
                    <thead>
                        <tr class="table-header table-header-grey">
                            <td class="py-2 text-center">Actions</td>
                            <td></td>
                            <td *ngFor="let display_column of listView.display_column; let isFirst = first; let k = index"
                                (click)="doSort(listView, display_column.name, listViewIndex)" class="py-2 pointer">
                                {{display_column.title}}<span
                                    *ngIf="lists[listViewIndex].sort && lists[listViewIndex].sort.field == display_column.name">
                                    <span *ngIf="lists[listViewIndex].sort.direction == 1">
                                        <fa-icon [icon]="faCaretDown" size="1x" title="sorted ascending"></fa-icon>
                                    </span>
                                    <span *ngIf="lists[listViewIndex].sort.direction == 0">
                                        <fa-icon [icon]="faCaretUp" size="1x" title="sorted descending"></fa-icon>
                                    </span>
                                </span>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="table-row"
                            *ngFor="let order of getListItems(listViewIndex); let j = index; let isLastOrder = last">

                            <td>
                                <app-workflow-action-dropdown [orderUuid]="order.order_uuid" [orderId]="order.order_id"
                                    [workflowUuid]="order.uuid" [workflowName]="order.workflow_status_name"
                                    [workflowStatusId]="order.workflow_status" [orderAddress]="order.address"
                                    [dataRefreshCallback]="dashboardSearchPromise.bind(this, listViewIndex)"
                                    [order]="listItems[listViewIndex][j]"
                                    [orderStatusId]="listItems[listViewIndex][j].order_status"
                                    [orderTeamId]="order.team_id" [userTeamIds]="userTeamIds" [skipTeamCheck]="true"
                                    [workflowStatuses]="workflowStatuses">
                                </app-workflow-action-dropdown>
                            </td>
                            <td>
                                <span *ngIf="this.listItems[listViewIndex][j].rush">
                                    <fa-icon [icon]="faCircle" size="1x" title="Rush" style="color: orange;">
                                    </fa-icon>
                                </span>
                            </td>
                            <td>
                                <a [routerLink]="this.listItems[listViewIndex][j].order_status == 'R' ? '/order-create' : '/searcher-order-edit'"
                                    [queryParams]="{orderUuid: this.listItems[listViewIndex][j].order_uuid}">{{order.order_id}}
                                    <span *ngIf="(order.attached_documents && order.attached_documents.length > 0)
                                            || order.documents && order.documents.length > 0
                                            || order.document_count > 0" class="paperclip-documents">
                                        <fa-icon class="ml-1" [icon]="faPaperclip" size="1x"
                                            title="Documents are attached to order">
                                        </fa-icon>
                                    </span>
                                    <span *ngIf="order.sbl_document_count" class="paperclip-sbl">
                                        <fa-icon class="ml-1" [icon]="faHome" size="1x"
                                            title="Documents are attached to sbl"></fa-icon>
                                    </span>
                                    <span *ngIf="order.final_document_count" class="paperclip-final">
                                        <fa-icon class="ml-1" [icon]="faUpload" size="1x"
                                            title="Final Documents are attached to order"></fa-icon>
                                    </span>
                                    <span *ngIf="order.api_icon">
                                        <img src="assets/{{order.api_icon}}" />
                                    </span>
                                </a>
                            </td>
                            <td>
                                {{order.order_type}}
                            </td>
                            <td>
                                {{order.due_date | date : 'shortDate'}}
                            </td>
                            <td>
                                <span class="px-2 status-badge"
                                    [ngClass]="['workflow-status-' + order.workflow_status ]">
                                    {{order.workflow_status_name | uppercase}}
                                </span>
                            </td>
                            <td>
                                <app-input-enum *ngIf="listItems[listViewIndex][j].availableSearchers" label="Searcher"
                                    [fc]="listItems[listViewIndex][j].searcherFC"
                                    [enumValuesNameAndId]="listItems[listViewIndex][j].availableSearchers"
                                    [format]="InputEnumFormat.none"
                                    [change]="setSearcher.bind(this, listItems[listViewIndex][j])" [includeUnset]="true"
                                    [includeUnsetDisplay]="'Unassigned'"></app-input-enum>
                            </td>
                            <td>
                                <a routerLink="/order-assessment-info" [queryParams]="{ oid: order.order_uuid }">
                                    {{order.sbl}}
                                </a>
                            </td>
                            <td>
                                {{order.address}}
                            </td>
                            <td>
                                {{order.county_name}}
                            </td>
                            <td>
                                {{order.effective_client_name}}
                            </td>
                            <td>
                                {{order.reference_number}}
                            </td>
                            <td>
                                {{order.property_seller ? order.property_seller.join(", ") : ""}}
                            </td>
                            <td>
                                {{order.submission_date | date : 'shortDate'}}
                            </td>
                            <td>
                                <app-input-enum [fc]="order.difficultyLevelFC" [enumValuesNameAndId]="difficultyLevels"
                                    [format]="InputEnumFormat.none" [change]="difficultyLevelChange.bind(this, order)">
                                </app-input-enum>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <app-dashboard-controls [title]="listView.title" [pageNumber]="lists[listViewIndex].currentPage"
            [pageSize]="lists[listViewIndex].limit" [entryCount]="lists[listViewIndex].count"
            [setPage]="this.setPage.bind(this, listViewIndex)" [isFooter]="true">
        </app-dashboard-controls>
    </span>
</div>
