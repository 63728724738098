<div class="card flex justify-content-center">
    <p-card>
        <ng-template pTemplate="header">
            <div class="header-content">
                Internal Notes 
                <i class="pi pi-pen-to-square link" style="margin-left: 0.5rem;" (click)="showInternalNotesDialog()"></i>
            </div>
        </ng-template>
        <p class="m-0">
            {{ internalNotes }}
        </p>
    </p-card>
</div>

<p-dialog header="Internal Notes" [modal]="true" [(visible)]="internalNotesDialogVisable" [style]="{ width: '40rem' }">
    <form [formGroup]="internalNotesForm" *ngIf="internalNotesForm">
        <div class="input-container">
            <label for="internalNotes">Edit Internal Notes: </label>
            <textarea pInputTextarea id="internalNotes" autocomplete="off" formControlName="internalNotes"></textarea>
        </div>
    </form>

    <div class="button-container">
        <p-button label="Save" (click)="saveNotes()" />
        <p-button label="Cancel" severity="secondary" (click)="hideInternalNotesDialog()" />
    </div>
</p-dialog>
