<div class="container-fluid step-circle-container mt-4" *ngIf="orderType != 'Quick'">
    <div class="row pt-1">
        <div class="col-12" align="center">
            <span *ngFor="let step of steps; let i = index" id="circle_{{i}}" [ngClass]="getCircleClass(step, i)"
                (click)="stepClick(i)" [ngStyle]="{'margin-left': circleMarginLeft(i)}">
                <span *ngIf="i != 0" id="before_circle_{{i}}" [ngClass]="getBeforeClass()"
                    [ngStyle]="{width: this.beforeWidth(), left: this.beforeLeft()}">
                </span>
                <span *ngIf="isStepPassed(i)">
                    <fa-icon [icon]="faCheck" size="1x"></fa-icon>
                </span>
                <span *ngIf="!isStepPassed(i)">
                    {{ getCircleNumber(i) }}
                </span>
            </span>
        </div>
    </div>
    <div class="row flex-nowrap">
        <div *ngFor="let step of steps; let i = index" class="col step-label px-0" [ngClass]="{'hidden': step.hidden }"
            align="center" id="col_{{i}}">
            {{ step.label }}
        </div>
    </div>
</div>
