import { Component, OnInit } from '@angular/core';
import { ClientsService } from '../services/client.service';
import { iClient } from '../model/client.model';
import { iPrimengColumn } from '../model/primeng-column.model';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-clients-primeng',
  templateUrl: './clients-primeng.component.html',
  styleUrl: './clients-primeng.component.css'
})
export class ClientsPrimengComponent implements OnInit
{
  clientTableData: iClient[] = [];
  clientColumns: iPrimengColumn[] = 
  [
    { header: 'Name', field: 'name' },
    { header: 'Address', field: 'address' }
  ]
  pagedClients: iClient[] = [];
  currentPage: number = 0;
  pageSize: number = 1;
  constructor(
    private clientsService: ClientsService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit()
  {
    this.clientsService.getClientele(this.authService.companyId, 'client').subscribe({
      next: (clients) => {
        this.clientTableData = (clients || []).sort((a, b) => a.name.localeCompare(b.name));
        this.loadPageData();
      },
      error: (err) => {
        console.error('Error loading client data', err);
        this.clientTableData = [];
      }
    });
  }

  goToClientPage(client: iClient)
  {
    this.router.navigate(['/client'], { queryParams: { id: client.id } });
  }

  addClient()
  {
    this.router.navigate(['/client']);
  }

  onPageChange(event: any)
  {
    this.currentPage = event.page;
    this.pageSize = event.rows;
    this.loadPageData();
  }

  loadPageData()
  {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.pagedClients = this.clientTableData.slice(startIndex, endIndex);
  }

}
