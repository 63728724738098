import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CrudService } from '../crud.service';
import { TimezoneService } from '../timezone.service';
import { UserService } from '../user.service';
import { v4 as uuid } from 'uuid';
import { AuthService } from '../auth.service';
import { faBan, faExclamationCircle, faMinus, faSave } from '@fortawesome/free-solid-svg-icons';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InvoiceService } from '../invoice.service';

@Component({
    selector: 'app-vendor',
    templateUrl: './vendor.component.html',
})
export class VendorComponent implements OnInit {
    faBan = faBan
    faSave = faSave
    faMinus = faMinus
    faExclamationCircle = faExclamationCircle
    phoneExampleText = "e.g. (123) 456-7890";

    isReadOnly = false

    editableFields = [
        "name",
        "address",
        "company_logo",
        "invoice_remit_address",
        "web_address",
        "phone",
        "fax",
        "fiscal_year_start_month",
        // "order_id_prefix",
        // "next_order_id",
        "notify_email_address",
        "default_time_zone",
        // "next_invoice_id",
        "invoice_subject_template",
        "use_order_id_as_invoice_id",
        "default_invoice_notes",
        "default_payment_term_id",
        "invoice_email_address",
        "document_delivery_signature",
        "default_sales_tax_id",
        "internal_billing_notes",
        "track_1099",
        "ein",
    ]
    formControls = {}

    nextOrderIdPrefix
    minNextOrderIdLength = 8

    // setNextOrderIdPrefix() {
    //     this.nextOrderIdPrefix = ""
    //     if (this.formControls["next_order_id"].value.toString().length + this.nextOrderIdPrefix.length < this.minNextOrderIdLength) {
    //         this.nextOrderIdPrefix += "1"
    //         while (this.formControls["next_order_id"].value.toString().length + this.nextOrderIdPrefix.length < this.minNextOrderIdLength) {
    //             this.nextOrderIdPrefix += "0"
    //         }
    //     }
    // }

    timezones$ = this.timezoneService.getTimezones().pipe(
        map(response => response.map(a => a.display_value)),
    )

    dueDates$ = this.invoiceService.getDueDates().pipe(
        map(response => response.result)
    )

    isNew
    companyId

    companyData$ = this.route.queryParams.pipe(
        switchMap((params) => {
            if (params["id"]) {
                this.companyId = params["id"]
                this.isNew = false
                return this.crudService.search({
                    type: "data_company { data_user }",
                    where: "id = ?",
                    whereArgs: [params["id"]],
                });
            } else {
                this.isNew = true
                return of({})
            }
        }),
        tap((response: any) => {
            //console.log("DATA RESP", response)
            for (var i = 0; i < this.editableFields.length; ++i) {
                this.formControls[this.editableFields[i]] = new UntypedFormControl()
            }
            if (!this.isNew) {
                this.formControls["name"].disable()
                //this.formControls["order_id_prefix"].disable()
                for (var i = 0; i < this.editableFields.length; ++i) {
                    this.formControls[this.editableFields[i]].setValue(response.result[0][this.editableFields[i]])
                }
                //this.setNextOrderIdPrefix()
            }
        })
    )

    constructor(
        private route: ActivatedRoute,
        private crudService: CrudService,
        private userService: UserService,
        private timezoneService: TimezoneService,
        private authService: AuthService,
        private router: Router,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private invoiceService: InvoiceService,
    ) { }

    ngOnInit() {
    }

    back() {
        //this.userService.navigateToInitialPage();
        this.router.navigate(["/vendors"])
    }

    cancel() {
        // this.setUnedit();
        // this.ngOnInit();

        this.router.navigate(["/vendors"])
    }

    faxUpdate() {
        this.userService.phoneUpdate(this.formControls["fax"]);
    }

    phoneUpdate() {
        this.userService.phoneUpdate(this.formControls["phone"]);
    }

    formErrors = {}
    anyError = false
    isValid() {
        this.anyError = false
        if (!this.formControls['name'].value) {
            this.formErrors["name"] = "Please specify a name."
            this.anyError = true
        } else if (this.formControls["name"].value.length > 255) {
            this.formErrors["name"] = "Value must not exceed 255 characters in length."
            this.anyError = true
        } else {
            delete this.formErrors["name"]
        }
        ["phone", "web_address", "fax"].forEach((item) => {
            if (this.formControls[item].value && this.formControls[item].value.length > 255) {
                this.formErrors[item] = "Value must not exceed 255 characters in length"
            } else {
                delete this.formErrors[item]
            }
        })

        if (this.formControls['notify_email_address'].value && !/\S+@\S+(\.\S+)?/.test(this.formControls["notify_email_address"].value)) {
            this.formErrors["notify_email_address"] = "Please enter a valid email address."
            this.anyError = true
        } else {
            delete this.formErrors["notify_email_address"]
        }

        if (this.formControls['invoice_email_address'].value && !/\S+@\S+(\.\S+)?/.test(this.formControls["invoice_email_address"].value)) {
            this.formErrors["invoice_email_address"] = "Please enter a valid email address."
            this.anyError = true
        } else {
            delete this.formErrors["invoice_email_address"]
        }

        if (this.formControls["web_address"].value && !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
            this.formControls["web_address"].value)) {
            this.formErrors["web_address"] = "Please enter a valid URL."
            this.anyError = true
        } else {
            delete this.formErrors["web_address"]
        }

        if (this.formControls["ein"].value && !/^\d\d\d\d\d\d\d\d\d$/.test(this.formControls["ein"].value)) {
            this.formErrors["ein"] = "Please enter nine digits."
            this.anyError = true
        } else {
            delete this.formErrors["ein"]
        }

        return !this.anyError
    }

    save() {
        //console.log("save isnew", this.isNew)
        if (!this.isValid()) {
            return
        }

        if (this.isNew) {
            var newCompanyObject = {
                id: uuid(),
                parent_company_uuid: this.authService.companyId,
                parent_company_relation_type: 'vendor',
                order_id_prefix: this.formControls["name"].value.substring(0, 3),
                next_order_id: "1000",
                next_invoice_id: "1",
            }
            for (var i = 0; i < this.editableFields.length; ++i) {
                newCompanyObject[this.editableFields[i]] = this.formControls[this.editableFields[i]].value
            }
            this.crudService.create(
                "data_company",
                newCompanyObject).subscribe((response) => {
                    //console.log(response)
                    this.router.navigate(["/vendors"])
                })
        } else {
            var companyObject = {
                id: this.companyId,
            }
            for (var i = 0; i < this.editableFields.length; ++i) {
                companyObject[this.editableFields[i]] = this.formControls[this.editableFields[i]].value
            }
            this.crudService.update(
                "data_company",
                companyObject).subscribe((response) => {
                    //console.log(response)
                    this.router.navigate(["/vendors"])
                })
        }
    }

    delete(companyData) {
        //console.log(companyData)
        if (companyData.result && companyData.result.length && companyData.result[0].data_users && companyData.result[0].data_users.length) {
            this._snackBar.open("Cannot delete company with users", null, {
                duration: 4000,
            });
            return
        }

        const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
            panelClass: 'delete-confirmation-dialog-container',
            // data: {
            //     orderId: this.listItems[listViewIndex][j].order_id,
            //     orderAddress: this.listItems[listViewIndex][j].address,
            //     newStatus: this.orderStatusById[status].name,
            // }
        });

        dialogRef.afterClosed().subscribe(result => {
            //console.log("detle resu", result)
            if (result) {
                var companyObject = {
                    id: this.companyId,
                }
                this.crudService.delete(
                    "data_company",
                    companyObject).subscribe((response) => {
                        //console.log(response)
                        this.router.navigate(["/vendors"])
                    })
            } else {
                this._snackBar.open("Action cancelled", null, {
                    duration: 4000,
                });
            }
        });
    }
}
