import { SysSchool } from "../model/sys-school.model";

export const SYSSCHOOL: SysSchool[] = 
[
    // {
    //   "schoolDistrictCode": "school_district_code",
    //   "schoolDistrictName": "school_district_name",
    //   "address": "address",
    //   "taxYear": "tax_year",
    //   "taxPeriod": "tax_period",
    //   "lienDate": "lien_date",
    //   "fiscalStartDate": "fiscal_start_date",
    //   "fiscalEndDate": "fiscal_end_date",
    //   "dueDate1": "due_date_1",
    //   "dueDate2": "due_date_2",
    //   "dueDate3": "due_date_3",
    //   "dueDate4": "due_date_4",
    //   "refreshDate": "refresh_date"
    // },
    {
      "schoolDistrictCode": "010100",
      "schoolDistrictName": "Albany City",
      "address": "PO BOX 15133\nALBANY, NY 12212\np: 518-475-6035",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("2022-09-30"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()    
    },
    {
      "schoolDistrictCode": "010300",
      "schoolDistrictName": "Cohoes City School District",
      "address": "PO BOX 14166\nALBANY, NY 12212\np: 518-237-0100",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("2022-09-30"),
      "dueDate2": new Date("3/31/2023"),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "011800",
      "schoolDistrictName": "Watervliet",
      "address": "1245 HILLSIDE DR\nWATERVLIET, NY 12189\np: 518-629-3203",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "012001",
      "schoolDistrictName": "Berne-Knox-Westerlo Central School District",
      "address": "PO BOX 13745\nALBANY, NY 12212\np: 518-872-0909",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "012206",
      "schoolDistrictName": "Bethlehem Central School District",
      "address": "PO BOX 12905\nALBANY, NY 12212",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "012402",
      "schoolDistrictName": "Ravena-Coeymans-Selkirk School District",
      "address": "PO BOX 14195\nALBANY, NY 12212\np: 518-756-5200",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "012601",
      "schoolDistrictName": "South Colonie Central School District",
      "address": "534 NEW LOUDON RD\nLATHAM, NY 12110\np: 518-783-2730",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "012605",
      "schoolDistrictName": "North Colonie Central School District",
      "address": "534 NEW LOUDON RD\nLATHAM, NY 12110\np: 518-783-2730",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "012615",
      "schoolDistrictName": "Menands",
      "address": "534 NEW LOUDON RD\nLATHAM, NY 12110\np: 518-783-2730",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "012801",
      "schoolDistrictName": "Green Island School District",
      "address": "171 HUDSON AVE \nGREEN ISLAND, NY 12183\np: 518-273-1422",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "013002",
      "schoolDistrictName": "Guilderland",
      "address": "PO BOX 339\nGUILDERLAND, NY 12084\np: 518-356-1980",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "013403",
      "schoolDistrictName": "Voorheesville Central School District",
      "address": "PO BOX 201\nVOORHEESVILLE, NY 12186\np: 518-765-3313",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "022001",
      "schoolDistrictName": "Alfred-Almond Central",
      "address": "6795 STATE ROUTE 21\nALMOND, NY 14804\np: 607-276-6513 | f: 607-276-6511",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "023001",
      "schoolDistrictName": "Andover Central",
      "address": "PO BOX G 31-35 Elm St\nANDOVER, NY 14806\np: 607-478-8491 | f: 607-478-8833",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "023202",
      "schoolDistrictName": "Genesee Valley",
      "address": "1 JAGUAR DR\nBELMONT, NY 14813\np: 585-268-7900",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "023401",
      "schoolDistrictName": "Belfast Central School District",
      "address": "1 King St\nBelfast, NY 14711\np: 585-307-8724",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "024002",
      "schoolDistrictName": "Canaseraga Central School District",
      "address": "PO BOX 222 10 Main St\nCANASERAGA, NY 14822\np: 607-545-6509/60 | f: 607-545-6265",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "024801",
      "schoolDistrictName": "Cuba-Rushford Central School District",
      "address": "5476 ROUTE 305\nCUBA, NY 14727\np: 585-968-2650 | f: 585-968-2651",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "025001",
      "schoolDistrictName": "Friendship",
      "address": "46 WEST MAIN ST\nFRIENDSHIP, NY 14739\np: 716-376-8386",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "025801",
      "schoolDistrictName": "Fillmore",
      "address": "20 N. GENESEE ST PO Box 302\nFILLMORE, NY 14735\np: 585-567-2666",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "026001",
      "schoolDistrictName": "Whitesville",
      "address": "851 Co Rte 19\nWhitesville, NY 14897\np: 607-356-3333",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "026601",
      "schoolDistrictName": "Scio",
      "address": "3968 WASHINGTON ST\nSCIO, NY 14880\np: 585-593-5510",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "027001",
      "schoolDistrictName": "Wellsville",
      "address": "126 WEST STATE STREET\nWELLSVILLE, NY 14895\np: 585-596-2170",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "027602",
      "schoolDistrictName": "Bolivar-Richburg Central School District",
      "address": "100 School St\nBolivar, NY 14715\np: 585-928-2933",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "030200",
      "schoolDistrictName": "Binghamton City School District",
      "address": "PO BOX 2087\nBINGHAMTON, NY 13902\np: 607-778-2169",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "032001",
      "schoolDistrictName": "Chenango Forks Central School District",
      "address": "60 HALWEY ST 2ND FLOOR\nBIGHAMTON, NY 13901\np: 607-778-2169 60",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "032601",
      "schoolDistrictName": "Harpursville",
      "address": "",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "032801",
      "schoolDistrictName": "Susquehanna Valley",
      "address": "PO BOX 2087\nBINGHAMTON, NY 13902\np: 607-778-2169",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "033001",
      "schoolDistrictName": "Chenango Valley Central School District",
      "address": "PO BOX 287\nBINGHAMTON, NY 13902\np: 607-778-2169",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "033801",
      "schoolDistrictName": "Maine",
      "address": "",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "034201",
      "schoolDistrictName": "Deposit Central School District",
      "address": "171 Second St\nDeposit, NY 13754\np: 607-467-5823",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "034401",
      "schoolDistrictName": "Whitney Point",
      "address": "60 HAWLEY ST 2ND FLOOR \nBINGHAMTON, NY 13901\np: 607-778-2169/60",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "034601",
      "schoolDistrictName": "Union-Endicott",
      "address": "PO BOX 2087\nBINGHAMTON, NY 13902\np: 607-778-2169",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "034602",
      "schoolDistrictName": "Johnson City",
      "address": "PO BOX 2087\nBINGHAMTON, NY 13902\np: 607-778-2169",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "034801",
      "schoolDistrictName": "Vestal",
      "address": "PO BOX 2087\nBINGHAMTON, NY 13902\np: 607-778-2169",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "035001",
      "schoolDistrictName": "Windsor",
      "address": "PO BOX 332\nWINDSOR, NY 13865\np: 607-655-8218",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "041200",
      "schoolDistrictName": "Olean",
      "address": "410 W SULLIVAN ST \nOLEAN, NY 14760\np: 716-375-8020 | f: 716-375-8025",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "041600",
      "schoolDistrictName": "Salamanca City School District",
      "address": "50 IROQUOIS DR\nSALAMANCA, NY 14779\np: 716-945-240 EXT | f: 716-945-1298",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "042001",
      "schoolDistrictName": "Allegany-Limestone Central",
      "address": "3131 FIVE MILE RD \nALLEGANY, NY 14706\np: 716-375-6600 EX | f: 716-375-66229",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "042204",
      "schoolDistrictName": "West Valley",
      "address": "9377  ROUTE 240 \nWEST VALLEY, NY 14171\np: 716-642-6016 EX | f: 716-942-3957",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "043601",
      "schoolDistrictName": "Ellicottville",
      "address": "5873 ROUTE 219\nELLICOTTVILLE, NY 14731\np: 716-699-2316 EX | f: 716-699-5423",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "044001",
      "schoolDistrictName": "Franklinville",
      "address": "31 N. MAIN ST\nFRANKLINVILLE, NY 14737\np: 716-676-8005 | f: 716-676-8041",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "044601",
      "schoolDistrictName": "Hinsdale",
      "address": "3701 MAIN ST\nHINSDALE, NY 14743\np: 716-376-8256",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "046402",
      "schoolDistrictName": "Cattaraugus-Little Valley Central School District",
      "address": "25 FRANKLIN ST N. \nCATTARAUGUS, NY 14719\np: 716-257-5294 | f: 716-257-5298",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "047201",
      "schoolDistrictName": "Gowanda Central School District",
      "address": "10674 PROSPECT ST \nGOWANDA, NY 1407\np: 716-532-3325 EX | f: 716-995-2154",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "047401",
      "schoolDistrictName": "Portville",
      "address": "PO BOX 790 500 Elm St\nPORTVILLE, NY 14770\np: 716-933-7126",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "047601",
      "schoolDistrictName": "Randolph Academy",
      "address": "",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "048401",
      "schoolDistrictName": "Yorkshire-Pioneer",
      "address": "PO BOX 579 12125 COUNTYU LIE\n716-9                                             , 71 14240-1987\np: 716-492-9300 EX",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "050100",
      "schoolDistrictName": "Auburn City School District",
      "address": "78 THORNTIN AVE\nAUBURN, NY 13021\np: 315-255-8806",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date("11/30/2022"),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "052201",
      "schoolDistrictName": "Weedsport",
      "address": "PO BOX 720\nWEEDSPORT, NY 13166\np: 315-834-9398",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "052401",
      "schoolDistrictName": "Cato-Meridian Central School District",
      "address": "2851 ROUTE 270 EAST\nFCATO, NY 13033\np: 315-626-3320",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "053001",
      "schoolDistrictName": "Southern Cayuga",
      "address": "2384 ROUTE 34B\nAURORA, NY 13026\np: 315-364-8711",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "053801",
      "schoolDistrictName": "Port Byron",
      "address": "126 MAIN ST PO BOX 711\nPORT BYRON, NY 13140\np: 315-776-5369",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "054201",
      "schoolDistrictName": "Moravia",
      "address": "PO BOX 467\nMORAVIA, NY 13118\np: 315-497-0269",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "055401",
      "schoolDistrictName": "Union Springs",
      "address": "2035 PINCKNEY RD\nAUBURN, NY 13021\np: 315-730-8510",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "060300",
      "schoolDistrictName": "Dunkirk City School District",
      "address": "PO BOX 216\nDUNKIRK, NY 14048\np: 716-366-9300",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "060800",
      "schoolDistrictName": "Jamestown",
      "address": "",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "062201",
      "schoolDistrictName": " Southwestern",
      "address": "SOUTHWESTERN CSD\nPO BOX 1289\nBUFFALO, NY 14240-1289\np: 716-484-6800",
      "taxYear": "2022/2023",
      "taxPeriod": "-",
      "lienDate": "-",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date(),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "062401",
      "schoolDistrictName": "Frewsburg",
      "address": "PO BOX 690\nFREWSBUG, NY 14738\np: 716-569-7048 | f: 716-569-7050",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "062601",
      "schoolDistrictName": "Cassadaga Valley Central School District",
      "address": "PO BOX 540\nSINCLAIRVILLE, NY 14782\np: 716-962-5155 EX",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "062803",
      "schoolDistrictName": "Chautauqua Lake Central School District",
      "address": "PO BOX 3354\nBUFFALO, NY 14240\np: 716-753-7342 EX",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "063001",
      "schoolDistrictName": "Pine Valley",
      "address": "",
      "taxYear": "2022/2023",
      "taxPeriod": "-",
      "lienDate": "-",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "063201",
      "schoolDistrictName": "Clymer Central School District",
      "address": "PO BOX 1595\nBUFFALO, NY 14240\np: 716-355-4490",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "063601",
      "schoolDistrictName": "Bemus Point Central School District",
      "address": "PO BOX 58\nBUFFALO, NY 14240\np: 716-386-2375",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "063801",
      "schoolDistrictName": "Falconer",
      "address": "PO BOX 5011\nBUFFALO, NY 14240\np: 716-665-5317",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "064601",
      "schoolDistrictName": "Silver Creek",
      "address": "PO BOX 1188\nBUFFALO, NY 14240\np: 716-934-2603",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "064603",
      "schoolDistrictName": "Forestville",
      "address": "PO BOX 177 \nFORESTVILLE, NY 14062\np: 716-965-6540 | f: 716-965-2117",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "064801",
      "schoolDistrictName": "Panama",
      "address": "5962 NIOBE RD\nPANAMA, NY 14767\np: 716-782-4640",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "065801",
      "schoolDistrictName": "Fredonia",
      "address": "PO BOX 6 \nFREDONIA, NY 14063\np: 716-673-9270",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "066001",
      "schoolDistrictName": "Brocton Central School",
      "address": "PO BOX 2116\nBUFFALO, NY 14240\np: 716-792-2170",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "066201",
      "schoolDistrictName": "Ripley",
      "address": "",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "066601",
      "schoolDistrictName": "Sherman",
      "address": "PO BOX 1494\nBUFFALO, NY 14240\np: 716-761-6781",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "067201",
      "schoolDistrictName": "Westfield",
      "address": "23 ELM ST\nWESTFIELD, NY 14787\np: 716-326-3211",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("9/30/2022"),
      "dueDate2": new Date(),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    },
    {
      "schoolDistrictCode": "070400",
      "schoolDistrictName": "Elmira",
      "address": "951 HOFFMAN ST\nELMIRA, NY 14905\np: 607-735-3017 | f: 607-735-3002",
      "taxYear": "2022/2023",
      "taxPeriod": "7/1-6/30",
      "lienDate": "7/1",
      "fiscalStartDate": new Date(),
      "fiscalEndDate": new Date(),
      "dueDate1": new Date("10/31/2022"),
      "dueDate2": new Date("3/31/2023"),
      "dueDate3": new Date(),
      "dueDate4": new Date(),
      "refreshDate": new Date()
    }
  ]