<div *ngIf="productData$ | async as companyData" class="container-fluid pt-2">
    <div class="row">
        <div class="col-2 d-none d-md-block">
        </div>
        <div class="col-12 col-md-8">

            <div class="row">
                <div class="col bold" style="font-size: 1.6em; color: #117ac9">
                    <span *ngIf="isNew">New</span>
                    <span *ngIf="!isNew">Edit</span>
                    Product
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <app-input-text label="Name" [fc]="formControls['name']" [disabled]="isReadOnly">
                    </app-input-text>
                    <span *ngIf="formErrors['name']" class="validation-error mt-1">{{formErrors['name']}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <app-input-checkbox label="Active" [fc]="formControls['active']" [disabled]="isReadOnly">
                    </app-input-checkbox>
                    <span *ngIf="formErrors['active']" class="validation-error mt-1">{{formErrors['active']}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <app-input-text label="Price" [fc]="formControls['price']" [disabled]="isReadOnly">
                    </app-input-text>
                    <span *ngIf="formErrors['price']" class="validation-error mt-1">{{formErrors['price']}}</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="anyError" class="row mt-3 validation-error-banner py-2">
        <div class="col-12" align="center">
            <fa-icon [icon]="faExclamationCircle"></fa-icon>
            Please address errors above and re-submit.
        </div>
    </div>

    <div class="row pt-3">
    </div>
    <app-button-group-create *ngIf="isNew" [create]="save.bind(this)" [cancel]="back.bind(this)">
    </app-button-group-create>
    <app-button-group-save *ngIf="!isNew" [save]="save.bind(this)" [cancel]="back.bind(this)"></app-button-group-save>
</div>