import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class ClientService {

    constructor(
        private http: HttpClient,
    ) { }

    getClients() {
        return this.http.get<any>("api/clients");
    }

    newCompany(companyName): Observable<any> {
        return this.http.put<any>("api/clientCompany", {
            name: companyName,
        }, httpOptions);
    }

    newUser(companyUuid, name, email): Observable<any> {
        return this.http.put<any>("api/clientUser", {
            company_id: companyUuid,
            full_name: name,
            email_address: email
        }, httpOptions);
    }
}
