import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-input-text-and-enum',
    templateUrl: './input-text-and-enum.component.html',
})
export class InputTextAndEnumComponent implements OnInit {
    @Input() label;
    @Input() fc;
    @Input() disabled;
    @Input() change;
    @Input() keyupEnter;
    @Input() enumFC;
    @Input() enumValues;
    @Input() style;

    constructor() { }

    ngOnInit() {
    }

    doChange() {
        if (this.change) {
            this.change();
        }
    }
    doEnter() {
        if (this.keyupEnter) {
            this.keyupEnter();
        }
    }
}
