<div class="container-fluid mt-5">
    <div class="row">
        <div class="col" align="center">
            <img src="assets/logo.png" />
        </div>
    </div>
</div>
<!-- <span *ngIf="!initialized">
    <div class="container-fluid bordered-container mt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.tertiaryColor"
        [style.border-color]="themeService.tertiaryColor">
        Initializing...
    </div>
</span> -->
<div *ngIf="initialized" class="container-fluid mt-5" style="width: 800px; border-radius: .5em;">
    <app-account-step-circles [stepProvider]="accountService" disableStepLinks="true">
    </app-account-step-circles>
</div>
<div *ngIf="initialized" class="container-fluid mt-5" style="max-width: 1000px; border-radius: .5em;">
    <div *ngIf="plans && plans.length > 0" class="row pt-3">
        <div *ngFor="let plan of plans" class="col-md text-center mx-2 my-3 choose-plan-tile" id="{{plan.plan.id}}_tile"
            (mouseenter)="tileMouseover(plan.plan)" (mouseleave)="tileMouseout(plan.plan)">

            <p class="my-4 bold tile-title" style="font-size: 1.6em;" id="{{plan.plan.id}}_title">{{plan.plan.name}}</p>
            <p class="my-2 user-count">&nbsp;{{getUserCount(plan)}}</p>
            <p class="mt-3"><span style="font-size: 2.5em; font-weight: bold;">${{plan.plan.totalPrice / 100}}</span>
                <span style="color: grey;"> / per {{plan.plan.period_unit}}</span>
            </p>

            <div style="min-height: 8em;">
                <p class="my-2 mx-2 plan-text" style="line-height: 1.8em;"
                    [innerHTML]="plan.plan.description | newlineBreak">
                </p>
                <span *ngIf="plan.plan.addon_applicability == 'restricted'">
                    <span *ngFor="let addon of plan.plan.attached_addons">
                        <div class="row">
                            <div class="col plan-text pointer" align="center">
                                <input type="checkbox" (change)="checkChange(plan.plan.id, addon.id)"
                                    [id]="plan.plan.id + '_' + addon.id"
                                    style="height: 1.3em; width: 1.3em; vertical-align: middle;" />
                                <label [for]="plan.plan.id + '_' + addon.addon.id" class='ml-1'>
                                    {{getAddonNameById(addon.id)}}
                                    (${{getAddonCostById(addon.id)}})
                                </label>
                            </div>
                        </div>
                        <div *ngIf="isAddonChecked(plan.plan.id, addon.id) && isAddonQuantity(addon.id)" class="row">
                            <div class="col-2"></div>
                            <div class="col-9" align="left">
                                Quantity <input type="text" size="2" (keyup)="checkChange(plan.plan.id, addon.id)"
                                    [id]="plan.plan.id + '_' + addon.id + '_quantity'" value="1" />
                            </div>
                        </div>
                    </span>
                </span>
                <span *ngIf="plan.plan.addon_applicability == 'all'">
                    <span *ngFor="let addon of addons">
                        <div class="row">
                            <div class="col plan-text pointer" align="center">
                                <input type="checkbox" (change)="checkChange(plan.plan.id, addon.addon.id)"
                                    [id]="plan.plan.id + '_' + addon.addon.id"
                                    style="height: 1.3em; width: 1.3em; vertical-align: middle;" />
                                <label [for]="plan.plan.id + '_' + addon.addon.id" class='ml-1'>
                                    {{addon.addon.name}}
                                    (${{addon.addon.price/100}})
                                </label>
                            </div>
                        </div>
                        <div *ngIf="isAddonChecked(plan.plan.id, addon.addon.id) && isAddonQuantity(addon.addon.id)"
                            class="row">
                            <div class="col-2"></div>
                            <div class="col-9 plan-text" align="left">
                                Quantity <input type="text" size="2" (keyup)="checkChange(plan.plan.id, addon.addon.id)"
                                    [id]="plan.plan.id + '_' + addon.addon.id + '_quantity'" value="1" />
                            </div>
                        </div>
                    </span>
                </span>
            </div>
            <div class="row">
                <div class="col" align="center">
                    <div class="action-button action-button_primary my-3" (click)="planChosen(plan.plan.id)">GET STARTED
                        <fa-icon [icon]="faLongArrowAltRight" class="ml-2">
                        </fa-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!plans || plans.length == 0" class="row pt-3">
        <div class="col-md text-center mx-4 my-3"
            style="border-radius: 10px; min-height: 100px; min-width: 250px; background: #ffffff; border: 1px solid #012f5b"
            onmouseover="this.style.color='#ffffff'; this.style.backgroundColor='#012f5b'; this.style.borderColor='#012f5b';"
            onmouseout="this.style.color='#000000'; this.style.backgroundColor='#ffffff';">
            <br />
            There are not yet any plans for that company type. Please come back soon.
        </div>
    </div>
    <div class="row mt-4 pb-5">
        <div class="col mt-3 bold" align="center">
            <div class="action-button action-button_secondary" (click)="back()">
                <fa-icon [icon]="faLongArrowAltLeft" class="mr-2"></fa-icon>BACK
            </div>
        </div>
        <div class="col mt-3 bold" align="center">
            <!-- <div class="account-create-next-button" (click)="next()">NEXT<fa-icon [icon]="faLongArrowAltRight"
                    style="vertical-align: text-top;" class="ml-2">
                </fa-icon>
            </div> -->
        </div>
    </div>
</div>
