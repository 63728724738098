import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-input-password',
    templateUrl: './input-password.component.html',
})
export class InputPasswordComponent implements OnInit {
    @Input() label;
    @Input() fc;
    @Input() disabled;
    @Input() change;
    @Input() keyupEnter;
    @Input() exampleText;
    @Input() style;
    @Input() exampleIcon;
    @Input() hideRules;

    constructor() { }

    ngOnInit() {
    }

    doChange() {
        if (this.change) {
            this.change();
        }
    }

    doEnter() {
        if (this.keyupEnter) {
            this.keyupEnter();
        }
    }
}
