import { Component, OnInit, Input } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ButtonType } from 'src/app/button/button.component';
import { ChargebeeService } from 'src/app/chargebee.service';

@Component({
    selector: 'app-button-group-wizard-interim',
    templateUrl: './button-group-wizard-interim.component.html',
})
export class ButtonGroupWizardInterimComponent implements OnInit {
    ButtonType = ButtonType
    faChevronLeft = faChevronLeft
    @Input() cancel
    @Input() save
    @Input() next
    @Input() previous
    
    constructor(private chargebeeService: ChargebeeService) { }

    is_agent = false
    ngOnInit() {
        this.chargebeeService.getPlan().subscribe((response) => {
            if(response.plan.includes('CLIENT')) {
                this.is_agent = true
            } else {
                this.is_agent = false
            }
            console.log(this.is_agent)
        })
    }

}
