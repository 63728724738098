import { Component, OnInit, Inject, Input } from '@angular/core';
import { SettingsService } from '../settings.service';
import { Router } from '@angular/router';
import {
    faPlus, faCaretDown, faCaretUp, faPaperclip, faEllipsisH,
    faFileAlt, faPlay, faStop, faPause, faPrint, faTimes,
    faExclamationTriangle, faCheck, faSearch, faKeyboard, faHome, faUpload, faPercent, faCircle
} from '@fortawesome/free-solid-svg-icons';
import { OrderService } from '../order.service';
import { UserService } from '../user.service';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkflowService } from '../workflow.service';
import { UntypedFormControl } from '@angular/forms';
import { AuthService } from '../auth.service';
import { TeamService } from '../team.service';
import { DocumentsService } from '../documents.service';
import { InputEnumFormat } from '../input-enum/input-enum.component';

@Component({
    selector: 'app-searcher-dashboard',
    templateUrl: './searcher-dashboard.component.html',
    styleUrls: ['./searcher-dashboard.component.css']
})
export class SearcherDashboardComponent implements OnInit {
    InputEnumFormat = InputEnumFormat
    i = 0
    faCaretDown = faCaretDown
    faCaretUp = faCaretUp
    faPlus = faPlus
    faPaperclip = faPaperclip
    faHome = faHome
    faUpload = faUpload
    faEllipsisH = faEllipsisH
    initialized = false
    faPlay = faPlay
    faFileAlt = faFileAlt
    faStop = faStop
    faPause = faPause
    faPrint = faPrint
    faSearch = faSearch
    faKeyboard = faKeyboard
    faPercent = faPercent
    faCircle = faCircle
    _listView
    maxUsers
    @Input() name
    @Input() title
    @Input() filter
    @Input() allAny
    difficultyLevels = [
        { name: "1", id: 1 },
        { name: "2", id: 2 },
        { name: "3", id: 3 },
        { name: "4", id: 4 },
        { name: "5", id: 5 },
    ]
    teams
    teamFC = new UntypedFormControl()

    constructor(
        protected _themeService: ThemeRebrandService,
        protected _settingsService: SettingsService,
        private router: Router,
        private orderService: OrderService,
        private userService: UserService,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog,
        private workflowService: WorkflowService,
        private authService: AuthService,
        private teamService: TeamService,
        private documentsService: DocumentsService,//IS used, even if vscode says it isn't
    ) {
    }

    get showTeams() {
        return this.router.url != '/dashboard-search-my'
    }
    userTeamIds

    getTeamsPromise() {
        return new Promise((resolve, reject) => {
            this.teamService.getTeams().subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                this.userTeamIds = []
                for (var i = 0; i < response.result.length; ++i) {
                    this.userTeamIds.push(response.result[i].id)
                }
                this.teams = response.result
                //console.log("teams", this.teams)
                if (this.teams && this.teams.length > 0) {
                    if (this.teamService.getSelectedTeamId()) {
                        this.teamFC.setValue(this.teamService.getSelectedTeamId())
                    } else {
                        this.teamFC.setValue(this.teams[0].id)
                    }
                }
                resolve(null)
            })
        })
    }

    teamChange() {
        this.teamService.setSelectedTeam(this.teamFC.value)
        this.resetPagination()
        for (var i = 0; i < this.lists.length; ++i) {
            this.dashboardSearchPromise(i);
        }
    }

    fetchCachedDashboardPageSort() {
        for (var i = 0; i < this.lists.length; ++i) {
            var cachedPage = localStorage.getItem("dashboard_page_" + this.lists[i].dashboard);
            if (cachedPage) {
                this.lists[i].currentPage = parseInt(cachedPage);
            }
            var sortColumn = localStorage.getItem("dashboard_sort_col_" + this.lists[i].dashboard);
            if (sortColumn) {
                this.lists[i].sort.field = sortColumn;
            }
            var sortDirection = localStorage.getItem("dashboard_sort_dir_" + this.lists[i].dashboard);
            if (sortDirection) {
                this.lists[i].sort.direction = parseInt(sortDirection);
            }
        }
    }

    cacheDashboardPage(dashboard, page) {
        localStorage.setItem("dashboard_page_" + dashboard, page)
    }
    cacheDashboardPageByIndex(listViewIndex) {
        var page = this.lists[listViewIndex].currentPage
        this.cacheDashboardPage(this.lists[listViewIndex].dashboard, page)
    }

    cacheDashboardSort(dashboard, column, direction) {
        localStorage.setItem("dashboard_sort_col_" + dashboard, column)
        localStorage.setItem("dashboard_sort_dir_" + dashboard, direction)
    }
    cacheDashboardSortByIndex(listViewIndex) {
        var column = this.lists[listViewIndex].sort.field
        var direction = this.lists[listViewIndex].sort.direction
        this.cacheDashboardSort(this.lists[listViewIndex].dashboard, column, direction)
    }

    resetDashboardCache(dashboard) {
        localStorage.removeItem("dashboard_page_" + dashboard)
        localStorage.removeItem("dashboard_sort_col_" + dashboard)
        localStorage.removeItem("dashboard_sort_dir_" + dashboard)
    }

    resetDashboardCacheByIndex(listViewIndex) {
        this.resetDashboardCache(this.lists[listViewIndex].dashboard)
        this.lists[listViewIndex].sort.field = "order_id"
        this.lists[listViewIndex].sort.direction = 1
        this.lists[listViewIndex].currentPage = 1
    }

    listItems = [];

    lists = [
        {
            title: "Searcher Orders",
            dashboard: "SEARCHER",
            currentPage: 1,
            limit: null,
            count: null,
            lastPage: null,
            sort: {
                "field": "order_id",
                "direction": 1
            },
            hideIfEmpty: false,
            hidden: false,
        },
    ];

    workflowStatusById = {};
    workflowStatuses

    setTeamId(filter) {
        for (var i = 0; i < filter.comparisons.length; ++i) {
            if (filter.comparisons) {
                if (filter.comparisons[i].field == "team_id") {
                    if (this.teamFC.value == "null") {
                        filter.comparisons[i].matchValue = null
                    } else {
                        filter.comparisons[i].matchValue = this.teamFC.value
                    }
                }
            }
            if (filter.filters) {
                for (var j = 0; j < filter.filters.length; ++j) {
                    this.setTeamId(filter.filters[j])
                }
            }
        }
    }

    dashboardSearchPromise(i) {
        return new Promise((resolve, reject) => {

            if (this.showTeams) {
                this.setTeamId(this.filter)
            }

            this.workflowService.search({
                allAny: this.allAny,
                page: this.lists[i].currentPage,
                sort: this.lists[i].sort,
                fields: this.filter,
            }, "dashLimit").subscribe(function (i, response) {
                if (!response.success) {
                    return reject(response);
                }

                this.listItems[i] = response.result;
                console.log('listItems', this.listItems[i])
                
                for (var j = 0; j < response.result.length; ++j) {
                    //console.log("setting price level of", response.result[j])
                    response.result[j].difficultyLevelFC = new UntypedFormControl(response.result[j].price_level)
                }

                this.lists[i].count = response.count;
                this.lists[i].limit = response.limit;
                this.lists[i].lastPage = Math.ceil(response.count / response.limit);

                if (this.lists[i].hideIfEmpty) {
                    if (this.lists[i].count == 0) {
                        this.lists[i].hidden = true;
                    }
                }

                for (var j = 0; j < this.listItems[i].length; ++j) {
                    var item = this.listItems[i][j];
                    for (var k = 0; k < item.availableSearchers.length; ++k) {
                        if (item.availableSearchers[k].id == this.authService.userId) {
                            item.availableSearchers.unshift({ id: "me", name: "assign to me" })
                            break
                        }
                    }
                    item.searcherFC = new UntypedFormControl(item.searcher_id);
                }
                resolve(null)
            }.bind(this, i))
        }).catch(function (err) {
            console.log("error while searching entries", err);
        })
    }

    usersEmailByIndex = {};
    ngOnInit() {
        this.getTeamsPromise().then(() => {
            for (var i = 0; i < this.lists.length; ++i) {
                this.listViewEntries.push({
                    // "title": this.lists[i].title,
                    "title": this.title,
                    "display_column": this.displayColumn,
                    "sort": this.sort,
                })
            }

            this.fetchCachedDashboardPageSort()
            var promises = []

            promises.push(new Promise((resolve, reject) => {
                this.workflowService.getWorkflowStatuses().subscribe((response) => {
                    if (response.success) {
                        for (var i = 0; i < response.result.length; ++i) {
                            this.workflowStatusById[response.result[i].id] = response.result[i];
                        }
                        this.workflowStatuses = response.result
                    } else {
                        console.log("error while getting order statuses")
                    }
                    resolve(null);
                });
            }));

            for (var i = 0; i < this.lists.length; ++i) {
                promises.push(this.dashboardSearchPromise(i));
            }
            return Promise.all(promises)
        }).then(() => {
            this.initialized = true;
        }).catch((err) => {
            this.initialized = true;
            console.log("error while searching orders", err);
        });
    }

    isListHidden(list) {
        return list.hidden;
    }

    showingMessage(listViewIndex) {
        if (!this.listItems[listViewIndex]) {
            return "Showing 0 to 0 of 0 - error occurred while fetching entries";
        }
        var firstEntry = (((this.lists[listViewIndex].currentPage - 1) * this.lists[listViewIndex].limit) + 1);
        if (this.lists[listViewIndex].count == 0) {
            firstEntry = 0;
        }
        return "Showing " + firstEntry + " to " + (((this.lists[listViewIndex].currentPage - 1) * this.lists[listViewIndex].limit)
            + this.listItems[listViewIndex].length)
            + " of " + this.lists[listViewIndex].count;
    }

    getPageLinks(listViewIndex) {
        var pageLinks = [];
        for (var i = this.lists[listViewIndex].currentPage - 5; i <= this.lists[listViewIndex].lastPage && pageLinks.length < 10; ++i) {
            if (i > 0) {
                pageLinks.push(i);
            }
        }
        for (var i = this.lists[listViewIndex].currentPage - 5; i > 0 && pageLinks.length < 10; --i) {
            pageLinks.unshift(i);
        }
        return pageLinks;
    }

    doSearch(listViewIndex) {
        this.dashboardSearchPromise(listViewIndex);
    }

    setPage(listViewIndex, page) {
        this.listItems[listViewIndex] = [];
        this.lists[listViewIndex].currentPage = page;
        this.cacheDashboardPageByIndex(listViewIndex);
        this.doSearch(listViewIndex);
    }

    resetPagination() {
        for (var i = 0; i < this.lists.length; ++i) {
            this.doFirst(i)
        }
    }

    doFirst(listViewIndex) {
        this.setPage(listViewIndex, 1);
    }

    doPrevious(listViewIndex) {
        if (this.lists[listViewIndex].currentPage <= 1) {
            return;
        }
        this.setPage(listViewIndex, this.lists[listViewIndex].currentPage - 1);
    }

    doNext(listViewIndex) {
        if (this.lists[listViewIndex].currentPage >= this.lists[listViewIndex].lastPage) {
            return;
        }
        this.setPage(listViewIndex, this.lists[listViewIndex].currentPage + 1);
    }

    doLast(listViewIndex) {
        this.setPage(listViewIndex, this.lists[listViewIndex].lastPage);
    }

    get themeService() {
        return this._themeService;
    }

    get settingsService() {
        return this._settingsService;
    }

    getListItems(i) {
        return this.listItems[i];
    }

    hasNote(listViewIndex, j) {
        return false;
    }

    entryValueColor(display_column) {
        if (display_column.link != null) {
            return this._themeService.linkColor;
        }
        return "";
    }

    doReset(listViewIndex) {
        this.resetDashboardCacheByIndex(listViewIndex);
        this.doSearch(listViewIndex);
    }

    doSort(listView, display_column_name, listViewIndex) {
        if (this.lists[listViewIndex].sort.field == display_column_name) {
            if (this.lists[listViewIndex].sort.direction) {
                this.lists[listViewIndex].sort.direction = 0;
            } else {
                this.lists[listViewIndex].sort.direction = 1;
            }
        } else {
            this.lists[listViewIndex].sort.field = display_column_name;
            this.lists[listViewIndex].sort.direction = 0;
        }
        this.cacheDashboardSortByIndex(listViewIndex);
        this.doSearch(listViewIndex);
    }

    isArray(val) {
        return Array.isArray(val);
    }

    displayColumn = [
        {
            title: "Order #",
            name: "order_id",
        },
        {
            title: "Order Type",
            name: "order_type",
        },
        {
            title: "Due Date",
            name: "due_date",
        },
        {
            title: "Status",
            name: "status",
        },
        {
            title: "Searcher",
            name: "searcher_email",
        },
        {
            title: "SBL",
            name: "sbl",
        },
        {
            title: "Address",
            name: "address",
        },
        {
            title: "County",
            name: "county_name",
        },
        {
            title: "Client",
            name: "effective_client_name",
        },
        {
            title: "Client Ref",
            name: "reference_number",
        },
        {
            title: "Seller",
            name: "property_seller",
        },
        {
            title: "Date Ordered",
            name: "submission_date",
        },
        {
            title: "Difficulty Level",
            name: "price_level",
        },
    ];

    sort: [
        {
            "list_view_sort": {
                "field": "id",
                "direction": 1
            }
        }
    ];

    listViewEntries = [];
    selectedOrderListViewIndex = null;
    selectedOrderJ = null;

    clickDocumentEventListener;
    doShowActionsMenu(listViewIndex, j) {
        document.getElementById('actions_dropdown_menu_searcher_' + listViewIndex + '_' + j).style.display = 'block'
        this.clickDocumentEventListener = this.hideActionsMenu.bind(this, listViewIndex, j)
        setTimeout(() => {
            document.addEventListener("click", this.clickDocumentEventListener);
        }, 0)
    }

    hideActionsMenu(listViewIndex, j) {
        document.removeEventListener("click", this.clickDocumentEventListener);
        var elem = document.getElementById('actions_dropdown_menu_searcher_' + listViewIndex + '_' + j)
        if (!elem) {
            return;
        }
        elem.style.display = 'none'
        this.selectedOrderListViewIndex = null
        this.selectedOrderJ = null
    }

    clickActionsMenu(listViewIndex, j) {
        var curDisplay = document.getElementById('actions_dropdown_menu_searcher_' + listViewIndex + '_' + j).style.display;
        if (curDisplay == "none") {
            if (this.selectedOrderListViewIndex != null && this.selectedOrderJ != null) {
                this.hideActionsMenu(this.selectedOrderListViewIndex, this.selectedOrderJ)
            }
            this.doShowActionsMenu(listViewIndex, j)
            this.selectedOrderListViewIndex = listViewIndex
            this.selectedOrderJ = j
        }
    }

    setWorkflowStatus(workflow_uuid, status, listViewIndex, j) {
        this.workflowService.setWorkflowStatus(workflow_uuid, status).subscribe((response) => {
            if (response.success) {
                this.doSearch(listViewIndex);
                var item = this.listItems[listViewIndex][j];
                this._snackBar.open('Order ' + item.order_id + " workflow " + item.workflow_name
                    + " status set to " + this.workflowStatusById[status].name, null,
                    {
                        duration: 4000,
                    }
                );
            } else {
                console.log("set workflow response", response)
            }
        })
    }

    printOrderSheetPDF(listViewIndex, j) {
        this.orderService.getOrderSheetPDF(this.listItems[listViewIndex][j].order_uuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);

            var iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = 'none';
            iframe.onload = function () {
                setTimeout(function () {
                    iframe.focus();
                    iframe.contentWindow.print();
                    URL.revokeObjectURL(fileURL)
                }, 1);
            };
            iframe.src = fileURL;
        })
    }

    printRunSheetPDF(listViewIndex, j) {
        this.orderService.getRunSheetPDF(this.listItems[listViewIndex][j].uuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);

            var iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = 'none';
            iframe.onload = function () {
                setTimeout(function () {
                    iframe.focus();
                    iframe.contentWindow.print();
                    URL.revokeObjectURL(fileURL)
                }, 1);
            };
            iframe.src = fileURL;
        })
    }

    runSheetDataEntry(listViewIndex, j) {
        this.router.navigate(["run-sheet-data-entry"], {
            queryParams: {
                orderUuid: this.listItems[listViewIndex][j].order_uuid,
                orderWorkflowUuid: this.listItems[listViewIndex][j].uuid
            }
        })
    }

    taxSearchNav(listViewIndex, j) {
        window.open("/tax-search?order_uuid=" + this.listItems[listViewIndex][j].order_uuid
            + "&order_workflow_uuid=" + this.listItems[listViewIndex][j].uuid,
            '_blank');
    }

    openSetStatusDialog(workflow_uuid, status, listViewIndex, j): void {
        const dialogRef = this.dialog.open(SearcherDashboardStateDialog, {
            panelClass: 'custom-dialog-container-no-reason',
            data: {
                orderId: this.listItems[listViewIndex][j].order_id,
                orderAddress: this.listItems[listViewIndex][j].address,
                newStatus: this.workflowStatusById[status].name,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            //console.log("dialog closed...")
            if (result) {
                //console.log("setting status...")
                this.setWorkflowStatus(workflow_uuid, status, listViewIndex, j)
            } else {
                this._snackBar.open("Action cancelled", null, {
                    duration: 4000,
                });
            }
        });
    }

    setSearcher(item) {
        if (item.searcherFC.value == "me") {
            item.searcherFC.setValue(this.authService.userId)
        }
        //console.log("debug", item, item.workflow_uuid, item.searcherFC.value)
        var searcherFCValue = item.searcherFC.value
        if (searcherFCValue == "null") {
            searcherFCValue = null
        }
        this.workflowService.assignSearcher(item.uuid, searcherFCValue).subscribe((response) => {
            if (response.success) {

                var snackbarMessage;
                if (searcherFCValue) {
                    snackbarMessage = 'Order ' + item.order_id + " workflow " + item.workflow_name + " has been assigned to " + this.usersEmailByIndex[item.searcherFC.value]
                } else {
                    snackbarMessage = 'Order ' + item.order_id + " workflow " + item.workflow_name + " has been unassigned."
                }

                this._snackBar.open(snackbarMessage, null, {
                    duration: 4000,
                });

                for (var i = 0; i < this.lists.length; ++i) {
                    this.dashboardSearchPromise(i);
                }
            } else {
                console.log("error while assigning searcher", response)
            }
        })
    }

    difficultyLevelChange(workflow) {
        var priceLevel = workflow.difficultyLevelFC.value
        if (priceLevel == "null") {
            priceLevel = null
        }
        this.workflowService.updateWorkflowPriceLevel(workflow.uuid, priceLevel).subscribe((response) => {
            if (response.success) {
                this._snackBar.open("Workflow difficulty level updated.", null, {
                    duration: 4000,
                });
            } else {
                console.log("error while updating difficulty level", response)
            }
        })
    }
}

@Component({
    selector: 'searcher-dashboard-state-dialog',
    templateUrl: './searcher-dashboard-order-state-dialog.html',
})
export class SearcherDashboardStateDialog {
    faTimes = faTimes
    faExclamationTriangle = faExclamationTriangle
    faCheck = faCheck
    constructor(
        public dialogRef: MatDialogRef<SearcherDashboardStateDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) { }

    yes() {
        this.dialogRef.close({});
    }

    no() {
        this.dialogRef.close();
    }
}
