import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

    constructor() { }

    transform(value: number, decimalSeparator?: string, thousandsSeparator?: string, multipleThousandsSeparators?: boolean): any {
        if (value == null) {
            return "";
        }
        var strRep = value.toString();
        var intPart;
        var dotIndex = strRep.indexOf(".");
        if (dotIndex == -1) {
            intPart = strRep;
        } else {
            intPart =  strRep.substring(0, dotIndex);
        }
        var decimalPart = "";
        if (dotIndex > -1) {
            decimalPart = strRep.substring(dotIndex + 1, strRep.length);
        }

        var counterUp;
        var newIntPart = "";
        for (var i = intPart.length - 1; i >= 0; --i) {
            counterUp = intPart.length - i;
            newIntPart = intPart.charAt(i) + newIntPart;
            if (counterUp != 0 && counterUp % 3 == 0 && i != 0) {
                newIntPart = thousandsSeparator + newIntPart;
                if (!multipleThousandsSeparators) {
                    newIntPart = intPart.substring(0, i) + newIntPart;
                    break;
                }
            }
        }
        if (decimalPart.length > 0) {
            return newIntPart + decimalSeparator + decimalPart;
        }
        return newIntPart;
    }
}
