import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';
import { ChargebeeService } from '../chargebee.service';
import { HttpClient } from '@angular/common/http';
import { AccountService } from '../account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { faBan, faLongArrowAltLeft, faLongArrowAltRight, faCheck } from '@fortawesome/free-solid-svg-icons';
// declare const Chargebee: any;

@Component({
    selector: 'app-account-choose-plan',
    templateUrl: './account-choose-plan.component.html',
    styleUrls: ['./account-choose-plan.component.css']
})
export class AccountChoosePlanComponent implements OnInit {
    faBan = faBan;
    faLongArrowAltLeft = faLongArrowAltLeft;
    faLongArrowAltRight = faLongArrowAltRight;
    chargebeeInstance;
    initialized = false;
    plans;
    addons;
    planDetails;

    constructor(
        private _themeService: ThemeRebrandService,
        private chargebeeService: ChargebeeService,
        private _accountService: AccountService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) { }

    // removed all calls to chargebee, only using data stored in db until we rebuild chargebee

    // getAddons() {
    //     this.chargebeeService.listAddons().subscribe((response) => {
    //         this.addons = response.result.list;
    //         this.initialized = true;
    //     })
    // }

    ngOnInit() {
        this.accountService.getAllPlanDetails().subscribe((response) => {
            if (response.result) {

                this.planDetails = {};
                for (var i = 0; i < response.result.length; ++i) {
                    this.planDetails[response.result[i].plan_id] = response.result[i];
                }
            }
            //console.log(response);
            // this.chargebeeService.getChargebeeParams().subscribe((response) => {
            //     this.chargebeeInstance = Chargebee.init({
            //         site: response.site,
            //         publishableKey: response.key
            //     });

                // this.activatedRoute.queryParams.subscribe(params => {
                //     if (params.companyType) {
                //         this.accountService.accountCreateCompanyType = params.companyType;
                //     }
                // this.chargebeeService.listPlans().subscribe((response) => {
                //     if (response.success) {
                //         this.plans = response.result.list;
                //         for (var i = 0; i < this.plans.length; ++i) {
                //             this.plans[i].plan.totalPrice = this.plans[i].plan.price;
                //         }
                //     }
                    if (this.accountService.accountCreateCompanyType) {
                        this.accountService.getPlansByCompanyType(this.accountService.accountCreateCompanyType).subscribe((response) => {
                            if (response.success) {
                                for (var i = 0; i < this.plans.length; ++i) {
                                    var matchFound = false;
                                    for (var j = 0; j < response.result.length; ++j) {
                                        if (this.plans[i].plan.id == response.result[j].plan_id) {
                                            matchFound = true;
                                            break;
                                        }
                                    }
                                    if (!matchFound) {
                                        this.plans.splice(i, 1);
                                        --i;
                                    }
                                }
                            }
                            // this.getAddons();
                        })
                    } else {
                        // this.getAddons();
                    }
                // });
                // });
            // });
        });
    }

    getAddonNameById(id) {
        for (var i = 0; i < this.addons.length; ++i) {
            if (this.addons[i].addon.id == id) {
                return this.addons[i].addon.name;
            }
        }
    }

    getAddonCostById(id) {
        for (var i = 0; i < this.addons.length; ++i) {
            if (this.addons[i].addon.id == id) {
                return this.addons[i].addon.price / 100;
            }
        }
    }

    get themeService() {
        return this._themeService;
    }

    get accountService() {
        return this._accountService;
    }

    isAddonChecked(planId, addonId) {
        var elem = document.getElementById(planId + "_" + addonId);
        if (!elem) {
            return false;
        }
        return (<HTMLInputElement>document.getElementById(planId + "_" + addonId)).checked;
    }

    isAddonQuantity(addonId) {
        return this.getAddonById(addonId).addon.type == "quantity";
    }

    getSelectedAddonsForPlan(planId) {
        var plan = this.getPlanById(planId);
        var addonList = [];
        if (plan.plan.addon_applicability == 'restricted' && plan.plan.attached_addons) {
            for (var i = 0; i < plan.plan.attached_addons.length; ++i) {
                addonList.push(this.getAddonById(plan.plan.attached_addons[i].id));
            }
        } else if (plan.plan.addon_applicability == 'all') {
            addonList = this.addons;
        }

        var selectedAddonIds = [];
        for (var i = 0; i < addonList.length; ++i) {
            var elem = document.getElementById(planId + "_" + addonList[i].addon.id);
            if (elem && (<HTMLInputElement>elem).checked) {
                var elem = document.getElementById(planId + "_" + addonList[i].addon.id + "_quantity");
                var quantity = null;
                if (elem) {
                    quantity = (<HTMLInputElement>elem).value;
                }
                selectedAddonIds.push({ id: addonList[i].addon.id, quantity: quantity });
            }
        }
        return selectedAddonIds;
    }

    planChosen(planId) {
        this._accountService.accountCreatePlanId = planId;
        this._accountService.accountCreateAddons = this.getSelectedAddonsForPlan(planId);
        this.accountService.navNextStep();
    }

    cancel() {
        this.accountService.navCancelStep();
    }

    getPlanById(planId) {
        for (var i = 0; i < this.plans.length; ++i) {
            if (this.plans[i].plan.id == planId) {
                return this.plans[i];
            }
        }
    }

    getAddonById(addonId) {
        for (var i = 0; i < this.addons.length; ++i) {
            if (this.addons[i].addon.id == addonId) {
                return this.addons[i];
            }
        }
    }

    checkChange(planId, addonId) {
        setTimeout(() => {
            var plan = this.getPlanById(planId);
            var addonList;
            if (plan.plan.addon_applicability == 'restricted') {
                addonList = [];
                for (var i = 0; i < plan.plan.attached_addons.length; ++i) {
                    addonList.push(this.getAddonById(plan.plan.attached_addons[i].id));
                }
            } else if (plan.plan.addon_applicability == 'all') {
                addonList = this.addons;
            }

            var priceSum = plan.plan.price;
            for (var i = 0; i < addonList.length; ++i) {
                if ((<HTMLInputElement>document.getElementById(planId + "_" + addonList[i].addon.id)).checked) {
                    if (this.isAddonQuantity(addonList[i].addon.id)) {
                        var quantity = (<HTMLInputElement>document.getElementById(planId + "_" + addonList[i].addon.id + "_quantity")).value;
                        var quantValue = 0;
                        quantValue = parseInt(quantity);
                        if (isNaN(quantValue)) {
                            quantValue = 0;
                        }
                        priceSum += addonList[i].addon.price * quantValue;
                    } else {
                        priceSum += addonList[i].addon.price;
                    }
                }
            }
            plan.plan.totalPrice = priceSum;
        }, 0);
    }

    changeCheck(planId, addonId) {
        (<HTMLInputElement>document.getElementById(planId + "_" + addonId)).checked = !(<HTMLInputElement>document.getElementById(planId + "_" + addonId)).checked;
        this.checkChange(planId, addonId);
    }

    back() {
        this.accountService.navPreviousStep();
    }

    tileMouseover(plan) {
        var tileElem = document.getElementById(plan.id + "_tile");
        tileElem.classList.add('shadow');

        var titleElem = document.getElementById(plan.id + "_title");
        titleElem.classList.add('choose-plan-title');
    }
    tileMouseout(plan) {
        var tileElem = document.getElementById(plan.id + "_tile");
        tileElem.classList.remove('shadow');

        var titleElem = document.getElementById(plan.id + "_title");
        titleElem.classList.remove('choose-plan-title');
    }

    getUserCount(plan) {
        var count = this.planDetails[plan.plan.id].max_users;
        if (!count) {
            return "";
        }
        if (count == 1) {
            return "1 User";
        }
        return "Up to " + count + " users";
    }
}
