import { SysTaxType } from '../model/sys-tax-type.model';


export const SYSTAXTYPE: SysTaxType[] =
[
    // { data mapping
    //   "id": "id",
    //   "name": "name"
    // },
    {
      "id": "CITY",
      "name": "County (City), School"
    },
    {
      "id": "COUNTY_CITY",
      "name": "County, City, School"
    },
    {
      "id": "DEFAULT",
      "name": "County (Town), Village, School"
    },
    {
      "id": "SCHOOL",
      "name": "City (School), County"
    },
    {
      "id": "TOWN",
      "name": "County (Town), School"
    }
  ]