import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UtilityReportService {

    constructor(
        private http: HttpClient,
    ) { }

    getReports(): Observable<any> {
        return this.http.get<any>("api/utilityReports")
    }

    getReport(id): Observable<any> {
        return this.http.get<any>("api/utilityReport/" + id)
    }

    getReportSchema(id): Observable<any> {
        return this.http.get<any>("api/utilityReportSchema/" + id)
    }
}
