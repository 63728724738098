import { Component, Input, OnInit } from '@angular/core';
import { ButtonType } from 'src/app/button/button.component';

@Component({
    selector: 'app-button-group-create',
    templateUrl: './button-group-create.component.html',
})
export class ButtonGroupCreateComponent implements OnInit {
    ButtonType = ButtonType
    @Input() create
    @Input() cancel

    constructor() { }

    ngOnInit() {
    }

}
