<div *ngIf="addressService.selectedAddress" id="propDesc" class="container-fluid mt-4 search-panel"
    style="background-color: white;">
    <div class="row">
        <div class="col-6">
            <span *ngFor="let propSet of propInfo">
                <div class="row pt-3">
                    <div class="col bold">
                        {{propSet.title}}
                    </div>
                </div>
                <div *ngFor="let prop of propSet.props" class="row">
                    <div class="col-2 pr-0" align="right">
                        <div style="display: inline-block;" class="mr-1 ml-4">&#8226;</div>
                    </div>
                    <div class="col-4 pl-0">
                        <span class="propertyLabel">{{prop.label}}</span>
                    </div>
                    <div class="col-6" [align]="prop.align">
                        <span style="vertical-align: middle">{{prop.value}}</span>
                    </div>
                </div>
            </span>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col pt-3 pb-4">
                    <div class="row">
                        <div class="col">
                            <div class="map relative">
                                <iframe class="map" [src]="addressService.taxMapSrc" frameborder="0" allowfullscreen>
                                </iframe>
                                <div style="position: absolute; top: .3em; right: .5em;">
                                    <app-button class="mx-1" label="View larger map" [clickFunction]="viewLargerMap.bind(this)"
                                        [icon]="faExpandArrowsAlt">
                                    </app-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col">
                            <iframe class="map" [src]="addressService.mapsAddress" frameborder="0" style="border:0"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row pt-3">
        <div class="col bold">
            Property Improvements
        </div>
    </div>
    <div class="row">
        <div class="col-2 pr-0" align="right">
        </div>
        <div class="col-2 bold">
            <div style="display: inline-block; color: white;" class="mr-1">&#8226;</div>
            Year
        </div>
        <div class="col-8 bold">
            Improvement Description
        </div>
    </div>

    <div class="row" *ngIf="addressService.selectedAddress.home_improvement_year_built_1">
        <div class="col-2 pr-0" align="right">
        </div>
        <div class="col-2">
            <div style="display: inline-block;" class="mr-1">&#8226;</div>
            {{addressService.selectedAddress.home_improvement_year_built_1}}
        </div>
        <div class="col-8">
            {{addressService.selectedAddress.home_improvement_1_description | titlecase}}
        </div>
    </div>

    <div class="row" *ngIf="addressService.selectedAddress.home_improvement_year_built_2">
        <div class="col-2 pr-0" align="right">

        </div>
        <div class="col-2">
            <div style="display: inline-block;" class="mr-1">&#8226;</div>
            {{addressService.selectedAddress.home_improvement_year_built_2}}
        </div>
        <div class="col-8">
            {{addressService.selectedAddress.home_improvement_2_description | titlecase}}
        </div>
    </div>

    <div class="row" *ngIf="addressService.selectedAddress.home_improvement_year_built_3">
        <div class="col-2 pr-0" align="right">
        </div>
        <div class="col-2">
            <div style="display: inline-block;" class="mr-1">&#8226;</div>
            {{addressService.selectedAddress.home_improvement_year_built_3}}
        </div>
        <div class="col-8">
            {{addressService.selectedAddress.home_improvement_3_description | titlecase}}
        </div>
    </div>

    <div class="row" *ngIf="addressService.selectedAddress.home_improvement_year_built_4">
        <div class="col-2 pr-0" align="right">
        </div>
        <div class="col-2">
            <div style="display: inline-block;" class="mr-1">&#8226;</div>
            {{addressService.selectedAddress.home_improvement_year_built_4}}
        </div>
        <div class="col-8">
            {{addressService.selectedAddress.home_improvement_1_description_4 | titlecase}}
        </div>
    </div>

    <div class="row pt-3">
        <div class="col bold">
            Information Available Online
        </div>
    </div>
    <div class="row">
        <div class="col-2 pr-0" align="right">
            <div style="display: inline-block;" class="mr-1 ml-4">&#8226;</div>
        </div>
        <div class="col pl-0">
            <a [href]="getTaxMapLink(addressService.selectedAddress)" target="_blank">Tax Map</a>
        </div>
    </div>

    <div *ngFor="let taxYear of addressService.taxInformation" class="row">
        <div class="col-2 pr-0" align="right">
            <div style="display: inline-block;" class="mr-1 ml-4">&#8226;</div>
        </div>
        <div class="col pl-0">
            <a [href]="getTaxLink(taxYear)" target="_blank">{{taxYear.tax_year}} Tax Information</a>
        </div>
    </div>

    <div *ngFor="let parcel of addressService.parcelInformation" class="row">
        <div class="col-2 pr-0" align="right">
            <div style="display: inline-block;" class="mr-1 ml-4">&#8226;</div>
        </div>
        <div class="col pl-0">
            <a [href]="getParcelLink(parcel)" target="_blank">{{parcel.description}}</a>
        </div>
    </div>
    <div *ngIf="clearFunction" class="row pt-3">
        <div class="col bold">
            <div class="action-button action-button_secondary ml-2" style="display: inline-block;" (click)="clear()"
                 align="center">
                <fa-icon [icon]="faBan" style="vertical-align: text-top;"></fa-icon> RESET SEARCH
            </div>
        </div>
    </div>
    <div class="row pb-3">
    </div>
</div>
