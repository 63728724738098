import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class TaxSearchService {
    private orderTaxSearchURL = "api/orderTax"
    private orderTaxStatusesURL = "api/orderTaxStatuses"
    private orderTaxUnlockURL = "api/orderTaxUnlock"
    private orderTaxPDFURL = "api/taxSearchPDF"
    private uploadTaxSearchPDFURL = "api/uploadTaxSearchPDF"
    private schoolDistrictTaxProfileIdURL = "api/schoolDistrictTaxProfileId"

    constructor(
        private http: HttpClient,
    ) { }

    getTaxSearch(order_workflow_id): Observable<any> {
        return this.http.get<any>(this.orderTaxSearchURL + "/" + order_workflow_id);
    }

    saveTaxSearch(taxSearchObj) {
        return this.http.put<any>(this.orderTaxSearchURL, taxSearchObj, httpOptions);
    }

    getTaxStatuses(): Observable<any> {
        return this.http.get<any>(this.orderTaxStatusesURL);
    }

    orderTaxUnlock(order_workflow_id) {
        return this.http.post<any>(this.orderTaxUnlockURL, { order_workflow_id }, httpOptions);
    }

    orderTaxPDF(order_workflow_id) {
        var options: any = { observe: 'response', responseType: 'blob' };
        return this.http.get<any>(this.orderTaxPDFURL + "/" + order_workflow_id, options);
    }

    viewOrderTaxPDF(orderWorkflowUuid) {
        this.orderTaxPDF(orderWorkflowUuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        });
    }

    uploadOrderTaxPDF(order_workflow_id) {
        return this.http.put<any>(this.uploadTaxSearchPDFURL, { order_workflow_id }, httpOptions);
    }

    getSchoolTaxProfile(schoolSWIS): Observable<any> {
        return this.http.get<any>(this.schoolDistrictTaxProfileIdURL + schoolSWIS);
    }

    getTaxSearchHeaderFromOrder(orderWorkflowUuid): Observable<any> {
        return this.http.get<any>("api/taxSearchHeaderFromOrder/" + orderWorkflowUuid);
    }

    getTaxSearchMuniData(orderWorkflowUuid): Observable<any> {
        return this.http.get<any>("api/taxSearchMuniData/" + orderWorkflowUuid);
    }

    getShowTaxInfoLookupButton(orderWorkflowUuid): Observable<any> {
        return this.http.get<any>("api/taxSearchShowTaxInfoButton/" + orderWorkflowUuid);
    }

    getTaxSearchMuniDataForEdit(orderWorkflowUuid): Observable<any> {
        return this.http.get<any>("api/taxSearchMuniDataForEdit/" + orderWorkflowUuid);
    }

    saveTaxSearchMuniData(data): Observable<any> {
        return this.http.post<any>("api/saveTaxSearchMuniData", data);
    }
}
