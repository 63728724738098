import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentsService } from '../documents.service';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { SnackRouteService } from '../snack-route.service';

@Component({
  selector: 'app-agent-order-download',
  templateUrl: './agent-order-download.component.html',
  styleUrls: ['./agent-order-download.component.css']
})
export class AgentOrderDownloadComponent implements OnInit {
  initialized = false
  documents: any
  faFileDownload = faFileDownload
  documentView = {}

  constructor(
    private activatedRoute: ActivatedRoute,
    private documentsService: DocumentsService,
    private snackRouteService: SnackRouteService
  ) { }

  
  download(doc) {
    console.log(doc)
    var id = doc.uuid
    if (!id) {
        id = doc.guid
    }
    this.documentsService.download(id).subscribe((response) => {
        var tmp: any = response;
        var blob = new Blob([tmp.body]);
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = doc.file_name;
        link.click();
    });
  }

  downloadAll(documents) {
    documents.forEach(doc => {
      let id = doc.uuid
      if(!id) {
        id = doc.guid
      }

      this.documentsService.download(id).subscribe((response) => {
        let tmp: any = response
        let blob = new Blob([tmp.body])
        const url = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.href = url
        link.download = doc.file_name
        link.click()
      })
    })

    // old matt peret method 
    // if (!this.documentView[documentCategory] || this.documentView[documentCategory].length == 0) {
    //     return this.snackRouteService.snack("No documents to download.")
    // }
    // for (var i = 0; i < this.documentView[documentCategory].length; ++i) {
    //     this.download(this.documentView[documentCategory][i]);
    // }
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      let orderUuid = params.orderUuid      
      this.documentsService.getOrderDocument(orderUuid).subscribe((response) => {
        if (response.success === true) {
          this.documents = response.result
        } else {
          console.log("error while fetching document", response)
        }
      })
      this.initialized = true
    })
  }
}
