<div>
    <span *ngIf="!initialized">
        <div class="container-fluid mt-4 rounded-ninety">
            Initializing...
        </div>
    </span>

    <div *ngIf="initialized" class="container-fluid rounded-ninety">
        <div class="row grey-list-title-row" style="font-weight: bold">
            <div class="col py-4" style="vertical-align: middle; line-height: 1.3em;">
                <span class="bold" style="float: left; font-size: 1.3em;">Order Details</span>
                <a routerLink="/dashboard-order-all-open" style="float: right;">Return to Orders</a>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-3 col-sm-12 bold" style="font-size: 1.8em;">
                {{cacheOrder.order_id}}
            </div>
            <div class="col-md-3 col-sm-12">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <span class="bold">Order Date</span><br />
                        <span>{{cacheOrder.submission_date | date: 'M/d/yyyy'}}</span>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <span class="bold">Due Date</span><br />
                        <span>{{cacheOrder.due_date | date: 'M/d/yyyy'}}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-12">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <span style="white-space: nowrap;">
                            <span class="bold">Quick Notes</span>
                            <fa-icon *ngIf="!editingInternalNotes" [icon]="faEdit" size="1x" title="Edit" class="ml-2"
                                (click)="clickEditInternalNotes()"></fa-icon>
                            <fa-icon *ngIf="editingInternalNotes" [icon]="faSave" size="1x" title="Save" class="ml-2"
                                (click)="clickSaveInternalNotes()"></fa-icon>
                            <fa-icon *ngIf="editingInternalNotes" [icon]="faBan" size="1x" title="Cancel" class="ml-1"
                                (click)="clickCancelInternalNotes()"></fa-icon>
                        </span>
                        <br />
                        <app-input-text-area label="Internal Notes" [fc]="internalNotesFC"
                            [format]="InputTextAreaFormat.none" [disabled]="!editingInternalNotes">
                        </app-input-text-area>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <span class="bold">Status</span><br />
                        <span class="status-badge" [ngClass]="['order-status-' + cacheOrder.order_status ]">
                            {{orderStatusById[cacheOrder.order_status].name | uppercase}}
                        </span>
                    </div>
                </div>
            </div>
        </div>        

        <div class="row mt-3">
            <div class="col">
                <hr />
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-3">
                <span class="bold">Order Type</span><br />
                <span>{{cacheOrder.order_type}}</span>
            </div>
            <div class="col-3">
                <span class="bold">SBL 
                    <i class="pi pi-pen-to-square link" (click)="editSBLClick()"></i>
                </span><br />
                <a class="bold" routerLink="/order-assessment-info"
                    [queryParams]="{ oid: this.orderUuid }">{{cacheOrder.sbl}}</a>
            </div>
            <div class="col-3">
                <span class="bold">Property Buyer 
                    <i *ngIf="cacheOrder.order_status !== 'C'" class="pi pi-pencil link ml-2" (click)="openBuyerSellerDialog()"></i>
                </span><br />
                <span>{{cacheOrder.property_buyer_formatted}}</span>
            </div>
            <div class="col-3">
                <span class="bold">Provider</span><br />
                <span>{{providerName}}</span>
            </div>
        </div>
        

        <div class="row mt-3">
            <div class="col-3">
                <span class="bold">Search Category</span><br />
                <span>{{searchCategoryDisplay}}</span>
            </div>
            <div class="col-3">
                <span class="bold">Address</span><br />
                <span>{{cacheOrder.address}}</span>
            </div>
            <div class="col-3">
                <span class="bold">Property Seller</span><br />
                <span>{{cacheOrder.property_seller_formatted}}</span>
            </div>
            <div *ngIf="cacheOrder.provider_created" class="col-3">
                <span class="bold">Client Company</span><br />
                <span>{{cacheOrder.client_company_name}}</span>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-3">
                <span class="bold">Search Type</span><br />
                <span>{{searchTypeDisplay}}</span>
            </div>
            <div class="col-3">
                <span class="bold">County</span><br />
                <span>{{cacheOrder.county_name}}</span>
            </div>

            <div class="col-3">
                <span class="bold">Assessed Owner</span><br />
                <span>{{cacheOrder.assessed_owner_formatted}}</span>
            </div>
            <div *ngIf="cacheOrder.provider_created" class="col-3">
                <span class="bold">Client Contact</span><br />
                <span>{{cacheOrder.client_user_name}}</span>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-3">
                <span class="bold">State</span><br />
                <span>{{cacheOrder.state}}</span>
            </div>
            <div class="col-3">
                <span class="bold">School District</span><br />
                <span>{{cacheOrder.school_district_name}}</span>
            </div>
            <div class="col-3">
                <span class="bold">Property Type</span><br />
                <span>{{cacheOrder.property_type}}</span>
            </div>
            <div *ngIf="cacheOrder.provider_created" class="col-3">
                <span class="bold">Contact Email</span><br />
                <span>{{cacheOrder.client_user_email}}</span>
            </div>
        </div>

        <div class="row mt-3">

            <div class="col-3">
                <span class="bold">Reference #</span><br />
                <span>{{cacheOrder.reference_number}}</span>
            </div>
            <div class="col-3">
                <span class="bold">Swis Code</span><br />
                <span>{{cacheOrder.real_property_code}}</span>
            </div>
            <div class="col-3">
                <span class="bold">Target Date Of Closing</span><br />
                <span>{{cacheOrder.target_close_date | date: 'M/d/yyyy'}}</span>
            </div>
            <div class="col-3">
                <span class="bold">File #</span><br />
                <span>{{cacheOrder.file_number}}</span>
            </div>
        </div>

        <div *ngIf="cacheOrder.continuation_date" class="row mt-3">
            <div class="col-3">
                <span class="bold">Continuation Date</span><br />
                <span style="color: grey;">{{cacheOrder.continuation_date | date: 'M/d/yyyy'}}</span>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col">
                <hr />
            </div>
        </div>
    </div>

    <div *ngIf="showAddContainer" class="container-fluid rounded-ninety">
        <div class="row pt-2" id="addTitle">
            <div class="col" style="font-weight: bold;">
                Attachment Type
            </div>
        </div>
        <div class="row">
            <div class="col" style="font-weight: bold;">
                <select style="border-color: lightgrey;" id="newfile_type">
                    <option value="-1">Please choose an option...</option>
                    <option *ngFor="let documentType of documentTypes; let i = index" value="{{documentType.id}}">
                        {{documentType.name}}
                    </option>
                </select>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col" style="font-weight: bold;">
                Additional Attachment Description
            </div>
        </div>
        <div class="row">
            <div class="col" style="font-weight: bold;">
                <input type="text" style="width: 60%; border-color: lightgrey;" id="newfile_description" />
            </div>
        </div>
        <div class="row">
            <div class="col mt-2" style="font-weight: bold;">
                <div class="p-2" style="border-style: solid; border-width: 1px; border-color: lightgrey; width: 60%">
                    <input type="file" [formControl]="fileFormControl" id="file"
                        (change)="handleFileInput($event.target.files)">
                </div>
            </div>
        </div>
        <div *ngIf="uploadError" class="row">
            <div class="col" style="font-weight: bold; color: red;">
                {{uploadError}}
            </div>
        </div>
        <div class="row pt-2 pl-2">
            <app-button label="Upload" class="ml-2" [clickFunction]="upload.bind(this)" [icon]="faUpload">
            </app-button>
        </div>
    </div>

    <div *ngIf="initialized" class="container-fluid mt-4 rounded-ninety">
        <div class="row pb-1">
            <div class="col-3">
                <div class="row pb-1">
                    <div class="col">
                        <span *ngIf="hasPropertyServices()">
                            <div class="row pb-1 mt-2">
                                <div class="col bold">
                                    Property Services
                                </div>
                            </div>
                            <div class="row pb-1">
                                <div class="col">
                                    <ul>
                                        <li
                                            *ngFor="let service of objectKeys(cacheOrder.propertyServices); let isFirst = first">
                                            {{serviceById[service]}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </span>

                        <span *ngIf="hasEntityServices()">
                            <div class="row pb-1 mt-2">
                                <div class="col bold">
                                    Entity Services
                                </div>
                            </div>
                            <span *ngFor="let service of objectKeys(entityServices); let isFirst = first">
                                <div class="row pb-1 mt-2">
                                    <div class="col">
                                        {{serviceById[service]}}
                                    </div>
                                </div>

                                <div class="row pb-1">
                                    <div class="col">
                                        <ul>
                                            <li *ngFor="let item of entityServices[service]; let isFirst = first">
                                                {{item}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </span>
                        </span>

                        <span *ngIf="hasIndividualServices()">
                            <div class="row pb-1 mt-2">
                                <div class="col bold">
                                    Individual Services
                                </div>
                            </div>
                            <span *ngFor="let service of objectKeys(individualServices); let isFirst = first">
                                <div class="row pb-1 mt-2">
                                    <div class="col">
                                        {{serviceById[service]}}
                                    </div>
                                </div>

                                <div class="row pb-1">
                                    <div class="col">
                                        <ul>
                                            <li *ngFor="let item of individualServices[service]; let isFirst = first">
                                                {{item}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="row pb-1 mt-2">
                    <div class="col bold">
                        Notes
                    </div>
                </div>
                <div class="row pb-1">
                    <div class="col-6">
                        Searcher Property Notes
                    </div>
                    <div class="col-6">
                        {{cacheOrder.searcher_property_notes}}
                    </div>
                </div>
                <div class="row pb-1">
                    <div class="col-6">
                        Searcher Entity Notes
                    </div>
                    <div class="col-6">
                        {{cacheOrder.searcher_entity_notes}}
                    </div>
                </div>
                <div class="row pb-1">
                    <div class="col-6">
                        Searcher Individual Notes
                    </div>
                    <div class="col-6">
                        {{cacheOrder.searcher_individual_notes}}
                    </div>
                </div>
                <div class="row pb-1">
                    <div class="col-6">
                        Searcher Notes
                    </div>
                    <div class="col-6">
                        {{cacheOrder.searcher_notifications_notes}}
                    </div>
                </div>
                <span *ngIf="cacheOrder.change_watchers && cacheOrder.change_watchers.length > 0">
                    <div class="row pb-1 mt-4">
                        <div class="col bold">
                            Change Watchers
                        </div>
                    </div>
                    <div class="row pb-1">
                        <div class="col-6">
                            <ul>
                                <li *ngFor="let item of cacheOrder.change_watchers; let isFirst = first">
                                    {{item}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div *ngIf="showActionButtons" class="row mt-3 pb-3">
            <div class="col mt-2">

                <div class="mt-2" style="display: inline-block">
                    <app-button label="View Order Sheet" class="ml-2" [clickFunction]="viewOrderSheetClick.bind(this)"
                        [icon]="faFilePdf">
                    </app-button>
                </div>

                <div class="mt-2" style="display: inline-block">
                    <app-button label="Attach File" class="ml-2" [clickFunction]="scrollToAdd.bind(this)"
                        [icon]="faMagic">
                    </app-button>
                </div>

                <div *ngIf="showCreateInvoice()" class="mt-2" style="float: right;">
                    <app-button label="Create Invoice" class="ml-2" [clickFunction]="createInvoice.bind(this)"
                        [icon]="faFileInvoice">
                    </app-button>
                </div>

                <div *ngIf="showViewInvoice()" class="mt-2" style="float: right;">
                    <app-button label="View Invoice" class="ml-2" [clickFunction]="viewInvoice.bind(this)"
                        [icon]="faFileInvoice">
                    </app-button>
                </div>

                <div *ngIf="showCopyOrder()" class="mt-2" style="float: right;">
                    <a routerLink="/order-create" [queryParams]="{orderUuid: orderUuid, copy: true}">
                        <app-button label="Copy Order" class="ml-2" [icon]="faEdit">
                        </app-button>
                    </a>
                </div>

                <div *ngIf="showCancelOrder()" class="mt-2" style="float: right;">
                    <app-button label="Cancel Order" class="ml-2" [icon]="faBan" (click)="openCancelOrHoldDialog('E')">
                    </app-button>
                </div>

                <div *ngIf="showPlaceOnHold()" class="mt-2" style="float: right;">
                    <app-button label="Place On Hold" class="ml-2" (click)="openCancelOrHoldDialog('O')"
                     [icon]="faPause">
                    </app-button>
                </div>

                <div *ngIf="showRemoveHold()" class="mt-2" style="float: right;">
                    <app-button label="Remove Hold" class="ml-2" (click)="openCancelOrHoldDialog(cacheOrder.previous_order_status)"
                        [icon]="faPlay">
                    </app-button>
                </div>

            </div>
        </div>
    </div>

    <div *ngIf="initialized && cacheOrder.api_order_id" class="container-fluid mt-4 rounded-ninety"
        [style.background-color]="themeService.tertiaryColor">

        <div class="row mt-4">
            <div class="col" style="overflow-x: hidden;">
                <span style="white-space: nowrap;">
                    <span class="attribute-h1 bold">Qualia Order Information</span>
                </span>
            </div>
        </div>
        <div class="row py-4 table-header">
            <div class="col bold">
                Qualia Order ID
            </div>
            <div class="col bold">
                Qualia Status
            </div>
            <div class="col bold">
                Download Date
            </div>
            <div class="col bold px-0">
                Upload Date
            </div>
        </div>
    </div>
    <div *ngIf="initialized && cacheOrder.api_order_id && qualiaMessages && qualiaMessages.length > 0"
        class="container-striped-alt">
        <div class="row table-row py-4">
            <div class="col">
                {{qualiaMessages[0].order_number}}
            </div>
            <div class="col">
                {{qualiaMessages[0].status_display}}
            </div>
            <div class="col">
                {{qualiaMessages[0].download_date}}
            </div>
            <div class="col">
                {{qualiaMessages[0].upload_date}}
            </div>
        </div>
    </div>
    <div *ngIf="initialized && cacheOrder.api_order_id" class="container-striped-alt">
        <div class="row py-4 table-row"
            *ngIf="!qualiaMessages || qualiaMessages.length == 0 || !qualiaMessages[0].message_uuid">
            <div class="col" align="center">
                &lt;&nbsp;No Qualia Messages&nbsp;&gt;
            </div>
        </div>
        <span *ngIf="qualiaMessages && qualiaMessages.length > 0 && qualiaMessages[0].message_uuid">
            <div class="row py-4 table-row" *ngFor="let qualiaMessage of qualiaMessagesRolledUp">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            {{qualiaMessage.from_name}} sent a message at {{qualiaMessage.created | date : 'h:mm:ss a on
                            EEEE, MMMM d'}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            {{qualiaMessage.text}}
                        </div>
                    </div>
                    <div class="row" *ngFor="let attachment of qualiaMessage.attachments">
                        <div class="col link" (click)="downloadQualiaMessageAttachment(attachment)">
                            {{attachment.file_name}}
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </div>

    <div *ngIf="initialized && showNotesAndCosts" class="container-fluid mt-4 rounded-ninety"
        [style.background-color]="themeService.tertiaryColor">

        <app-internal-notes [orderUuid]="orderUuid" [internalNotes]="longNotes"></app-internal-notes>
        <br/>
        <app-additional-costs
        [additionalCosts]="additionalCosts"
        [providerId]="providerId"
        [orderUuid]="orderUuid"
        (costChange)="handleCostChange()">
        </app-additional-costs>
    </div>

    <div *ngIf="initialized && showProgress" class="container-fluid mt-4 rounded-ninety">
        <div class="row pb-1">
            <div class="col mt-4 attribute-h1 bold">
                Workflow - Order Progress
            </div>
        </div>
        <app-order-progress [orderData]="cacheOrder" (fileUploadCompleted)="handleFileUploadComplete($event)"></app-order-progress>
    </div>

<div *ngIf="initialized" class="container-fluid mt-4 rounded-ninety">
    <app-document-table
    #emailDialog
    [data]="documentView"
    [headers]="categoryHeaders"
    [orderUuid]="orderUuid"
    (deleteCompleted)="onFileDeleted()"></app-document-table>

    <div class="row">
        <div class="col mt-4">
        </div>
    </div>
</div>

<div *ngIf="initialized && showSBLDocuments" class="container-fluid mt-4 rounded-ninety">
    <div class="row pb-1">
        <div class="col mt-4 attribute-h1 bold">
            Documents Associated with SBL {{cacheOrder.sbl}}
        </div>
    </div>
    <p-table [columns]="sblColumns" [value]="sblDocuments">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" class="blue-tint-background">
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns">
                <ng-container [ngSwitch]="col.field">
                    <ng-container *ngSwitchCase="'order_number'">
                        <span class="link" (click)="openOrderDetails(rowData)">
                            {{ rowData[col.field] }}
                        </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'description'">
                        <span class="link" (click)="download(rowData)">
                            {{ rowData[col.field] }}
                        </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'document_type'">
                        <span>
                            {{ getDocumentTypeDisplay(rowData[col.field]) }}
                        </span>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{ formatValue(rowData[col.field]) }}
                    </ng-container>
                </ng-container>
            </td>    
        </tr>    
    </ng-template>
    </p-table>
</div>

<div *ngIf="initialized && showHistory" class="container-fluid mt-4 rounded-ninety">
    <div class="row mt-4">
        <div class="col pb-1 attribute-h1 bold">
            Order History
        </div>
    </div>

    <p-table [columns]="historyColumns" [value]="orderLog">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" class="blue-tint-background">
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns">
                    {{ formatValue(rowData[col.field]) }}
                </td>    
            </tr>    
        </ng-template>
    </p-table>

<p-dialog header="Edit Order" [(visible)]="displayDialog" [modal]="true" [style]="{width: '550px'}">
    <div class="reset-container">
        <form [formGroup]="buyerSellerForm" (ngSubmit)="onSubmit()">
            <div class="form-field">
                <label for="buyer" class="bold block mb-1">Buyer</label>
                <div *ngFor="let buyer of buyersList; let i = index" class="mb-2">
                    <div class="flex align-items-center input-row">
                        <input [id]="'buyer-' + i" type="text" pInputText [formControl]="buyer" class="input-fixed-width" />
                        <i *ngIf="i > 0" class="pi pi-minus ml-2" style="cursor: pointer;" (click)="removeBuyer(i)"></i>
                        <i *ngIf="i === buyersList.length - 1" class="pi pi-plus ml-2" style="cursor: pointer;" (click)="addBuyer()"></i>
                    </div>
                </div>
            </div>

            <div class="form-field">
                <label for="seller" class="bold block mb-1">Seller</label>
                <div *ngFor="let seller of sellersList; let i = index" class="mb-2">
                    <div class="flex align-items-center input-row">
                        <input [id]="'seller-' + i" type="text" pInputText [formControl]="seller" class="input-fixed-width" />
                        <i *ngIf="i > 0" class="pi pi-minus ml-2" style="cursor: pointer;" (click)="removeSeller(i)"></i>
                        <i *ngIf="i === sellersList.length - 1" class="pi pi-plus ml-2" style="cursor: pointer;" (click)="addSeller()"></i>
                    </div>
                </div>
            </div>

            <div class="form-field">
                <label for="assessedOwner" class="bold block mb-1">Assessed Owner</label>
                <div *ngFor="let owner of assessedOwnersList; let i = index" class="mb-2">
                    <div class="flex align-items-center input-row">
                        <input [id]="'assessedOwner-' + i" type="text" pInputText [formControl]="owner" class="input-fixed-width" />
                        <i *ngIf="i > 0" class="pi pi-minus ml-2" style="cursor: pointer;" (click)="removeAssessedOwner(i)"></i>
                        <i *ngIf="i === assessedOwnersList.length - 1" class="pi pi-plus ml-2" style="cursor: pointer;" (click)="addAssessedOwner()"></i>
                    </div>
                </div>
            </div>

            <div *ngIf="cacheOrder.service_group_id === 'NOTES_ONLY'" class="form-field">
                <label for="primaryServiceType" class="bold block mb-1">Primary Service Type</label>
                <div>
                    <div class="flex align-items-center input-row">
                    <p-dropdown
                        [options]="serviceTypeOptions"
                        formControlName="primaryServiceType"
                        placeholder="Select a service type"
                        appendTo="body"
                        optionLabel="name"
                        optionValue="serviceId"
                    ></p-dropdown>
                    </div>
                </div>
            </div>

            <div class="dialog-footer">
                <button pButton type="button" label="Cancel" severity="danger" class="p-button-text" (click)="closeDialog()"></button>
                <button pButton type="submit" label="Save" severity="success" [disabled]="buyerSellerForm.invalid"></button>
            </div>
        </form>
    </div>
</p-dialog>

<p-dialog 
  header="Confirmation" 
  [style]="{width: '50%'}" 
  [modal]="true" 
  [(visible)]="isCancelorHoldDialogVisible" 
  (onHide)="onDialogClose()"
>
  <div class="p-2">
    <div>
      Order ID: {{cacheOrder.order_id}} - {{cacheOrder.address}}
    </div>
    <div>
      New status: {{newStatusName}}
    </div>
    <div class="ml-2 mt-1">
      Reason:
    </div>
    <div class="flex justify-content-center">
      <textarea 
        pInputTextarea 
        id="reason" 
        rows="4" 
        [style]="{'width': '90%'}"
        class="mt-1"
        [(ngModel)]="reason"
      ></textarea>
    </div>

    <div class="mt-3 p-2 border-top border-bottom border-1 border-gray-300">
      <i class="pi pi-exclamation-triangle mr-2" title="Alert"></i>
      Are you sure you want to set the status of order {{cacheOrder.order_id}} to {{newStatusName}}?
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="dialog-footer">
      <button 
        pButton 
        type="button" 
        label="Cancel" 
        severity="danger" 
        class="p-button-text" 
        (click)="onCancel()"
      ></button>
      <button 
        pButton 
        type="button" 
        label="Confirm" 
        severity="success" 
        (click)="onConfirm()"
      ></button>
    </div>
  </ng-template>
</p-dialog>