import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';
import { AwsSesService } from '../aws-ses.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
})
export class ContactUsComponent implements OnInit {
    contactFormError;
    thankYouMessage;

    constructor(
        private _themeService: ThemeService,
        private awsSes: AwsSesService,
        private router: Router,
    ) { }

    ngOnInit() {
    }

    get themeService() {
        return this._themeService;
    }

    hasAuthenticationToken() {
        return localStorage.getItem('authToken') != null;
    }

    cancel() {
        if (this.hasAuthenticationToken()) {
            this.router.navigate(["/welcome"]);
        } else {
            this.router.navigate(["/"]);
        }
    }

    submitClick() {
        (<HTMLFormElement>document.getElementById('contactForm')).submit();
        return false;
    }

    submitContactForm(event) {
        event.preventDefault();

        if (event.target.subject.value == "Subject") {
            this.contactFormError = "Please choose a subject.";
            return;
        }

        if (!event.target.fullname.value.trim()) {
            this.contactFormError = "Please provide your full name.";
            return;
        }

        if (!event.target.company.value.trim()) {
            this.contactFormError = "Please provide your company's name.";
            return;
        }

        if (!event.target.email.value.trim()) {
            this.contactFormError = "Please provide your email address.";
            return;
        }

        this.awsSes.sendContactUsEmail(
            event.target.fullname.value,
            event.target.company.value,
            event.target.email.value,
            event.target.phone.value,
            event.target.subject.value,
            event.target.message.value,

        ).subscribe((response) => {
            if (response.success) {
                this.thankYouMessage = "Thank you for contacting us. We expect to respond shortly.";
            } else {
                this.contactFormError = "There was an error processing your request. " + response.message + " Please try again.";
            }
        });
    }
}
