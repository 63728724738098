import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { OrderService } from '../order.service';
import { SystemService } from '../system.service';
import { CompanyService } from '../company.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-run-sheet-html',
    templateUrl: './run-sheet-html.component.html',
    styleUrls: ['./run-sheet-html.component.css']
})
export class RunSheetHtmlComponent implements OnInit {
    @ViewChild("imageCol", { read: ElementRef }) imageCol: ElementRef;
    difficulties = [1, 2, 3, 4, 5]

    namesSearchedRows = []
    deedsMortsSearchedRows = []

    initialized = false;
    order;
    provider;

    constructor(
        private _orderService: OrderService,
        private systemService: SystemService,
        private companyService: CompanyService,
        private activatedRoute: ActivatedRoute,
    ) {
        for (var i = 0; i < 8; ++i) {
            this.namesSearchedRows.push(null)
        }
        for (var i = 0; i < 12; ++i) {
            this.deedsMortsSearchedRows.push(null)
        }
    }
    serviceMapping;
    searchTypeDisplay;
    serviceRows;
    agent;

    get orderService() {
        return this._orderService
    }

    getOrderPromise() {
        return new Promise((resolve, reject) => {
            this._orderService.getOrder3(this.orderUuid).subscribe((response) => {
                this.order = response.result
                //console.log(this.order)
                resolve(null)
            })
        })
    }

    getClientCompanyPromise() {
        return new Promise((resolve, reject) => {
            this.companyService.getCompanyById(this.order.agent_id).subscribe((response) => {
                this.agent = response.result[0]
                resolve(null)
            })
        })
    }

    getProviderCompanyPromise() {
        return new Promise((resolve, reject) => {
            this.companyService.getCompanyById(this.order.provider_id).subscribe((response) => {
                this.provider = response.result[0];
                resolve(null)
            })
        })
    }

    orderUuid
    getOrderIdParamPromise() {
        return new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe(params => {
                this.orderUuid = params.orderUuid
                resolve(this.orderUuid)
            })
        })
    }

    ngOnInit() {
        this.getOrderIdParamPromise().then(() => {
            return this.getOrderPromise()
        }).then(() => {
            var promises = []

            promises.push(this.getClientCompanyPromise())
            promises.push(this.getProviderCompanyPromise())

            promises.push(new Promise((resolve, reject) => {
                this._orderService.getSearchTypeDisplay(this.orderUuid).subscribe((response) => {
                    this.searchTypeDisplay = response.result;
                    //console.log(this.searchTypeDisplay)
                    resolve(null)
                })
            }))

            Promise.all(promises).then(() => {
                this.initialized = true;
                this.loadLogo();
            })
        })
    }

    getImageWidth(width, height, newHeight) {
        var ratio = width / height;
        return ratio * newHeight;
    }

    loadLogo() {
        if (this.order.provider_id && this.provider) {
            this.systemService.getProfileFile(this.provider.company_logo).subscribe((response) => {
                var reader = new FileReader();
                reader.readAsDataURL(<any>response);
                reader.onloadend = () => {
                    var img = document.createElement('img');
                    (<any>img.src) = reader.result;
                    img.onload = function () {
                        var newHeight = 64;
                        var newWidth = this.getImageWidth(img.width, img.height, newHeight);
                        img.width = newWidth;
                        img.height = newHeight;
                        while (this.imageCol.nativeElement.firstChild) {
                            this.imageCol.nativeElement.removeChild(this.imageCol.nativeElement.firstChild);
                        }
                        this.imageCol.nativeElement.appendChild(img);
                    }.bind(this);
                }
            });
        }
    }

    arrayDisplay(array) {
        var display = "";
        if (array) {
            for (var i = 0; i < array.length; ++i) {
                if (i != 0) {
                    display += ", ";
                }
                display += array[i];
            }
        }
        return display;
    }
}
