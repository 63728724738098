import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class JurisdictionService {
    jurisdictions;
    private getAllJurisdictionsURL = 'api/getJurisdictionData';
    private getTaxInformationURL = 'api/getTaxInformation';
    private getParcelInformationURL = 'api/getParcelInformation';
    private getCountyDataURL = "api/getCountyData";
    private getCountyParcelAvailabilityURL = "api/getCountyParcelAvailability";
    private getSchoolDistrictNameURL = "api/schoolDistrictName/"

    constructor(
        private http: HttpClient,
    ) {
    }

    setJurisdictionData(data) {
        this.jurisdictions = data;

        for (var i = 0; i < data.length; ++i) {
            var lastCityName = null;
            for (var j = 0; j < data[i].cities.length; ++j) {
                if (lastCityName != null && lastCityName == data[i].cities[j].name) {
                    data[i].cities[j].name += " (" + data[i].cities[j].type + ")";
                    data[i].cities[j - 1].name += " (" + data[i].cities[j - 1].type + ")";
                }
                lastCityName = data[i].cities[j].name
            }
        }
        //console.log(this.jurisdictions);
    }

    getJurisdictionData() {
        return this.http.get<any>(this.getAllJurisdictionsURL);
    }

    initPromise() {
        return new Promise((resolve, reject) => {
            this.getJurisdictionData().subscribe((response) => {
                this.setJurisdictionData(response);
                resolve(null)
            });
        })
    }

    getCountyData() {
        return this.http.get<any>(this.getCountyDataURL);
    }

    getNYCountyData() {
        return this.http.get<any>("api/getNYCountyData");
    }

    searchCounty(term) {
        if (!this.jurisdictions) {
            return of([]);
        }
        var results = [];
        for (var i = 0; i < this.jurisdictions.length; ++i) {
            if (this.jurisdictions[i].name.toLowerCase().startsWith(term.trim().toLowerCase())) {
                results.push(this.jurisdictions[i]);
            }
        }
        return of(results);
    }

    searchMunicipality(county, term) {
        if (!this.jurisdictions || !county) {
            return of([{ name: "No results matched", unselectable: true }]);
        }

        var results = [];
        for (var i = 0; i < this.jurisdictions.length; ++i) {
            if (this.jurisdictions[i].name == county) {
                for (var j = 0; j < this.jurisdictions[i].cities.length; ++j) {
                    if (!term) {
                        results.push(this.jurisdictions[i].cities[j]);
                    } else if (this.jurisdictions[i].cities[j].name.toLowerCase().startsWith(term.trim().toLowerCase())) {
                        results.push(this.jurisdictions[i].cities[j]);
                    }
                }
                break;
            }
        }
        if (results.length == 0) {
            return of([{ name: "No results matched", unselectable: true }]);
        }
        return of(results);
    }

    getCountySwisByName(countyName) {
        if (this.jurisdictions) {
            for (var i = 0; i < this.jurisdictions.length; ++i) {
                if (this.jurisdictions[i].name == countyName) {
                    return this.jurisdictions[i].swis;
                }
            }
        }
        return null;
    }

    getTaxInformation(realPropertyCode) {
        return this.http.put<any>(this.getTaxInformationURL, { "real_property_code": realPropertyCode }, httpOptions);
    }

    getParcelInformation(realPropertyCode) {
        return this.http.put<any>(this.getParcelInformationURL, { "real_property_code": realPropertyCode }, httpOptions);
    }

    getJurisdictionByRPCode(rp_code) {
        var swisToMatch = rp_code;
        for (var i = 0; i < this.jurisdictions.length; ++i) {
            for (var j = 0; j < this.jurisdictions[i].cities.length; ++j) {
                for (var k = 0; k < this.jurisdictions[i].cities[j].state_rp_code.length; ++k) {
                    if (this.jurisdictions[i].cities[j].state_rp_code[k] == swisToMatch) {
                        return this.jurisdictions[i].cities[j];
                    }
                }
                for (var k = 0; k < this.jurisdictions[i].cities[j].villages.length; ++k) {
                    for (var l = 0; l < this.jurisdictions[i].cities[j].villages[k].state_rp_code.length; ++l) {
                        if (this.jurisdictions[i].cities[j].villages[k].state_rp_code[l] == swisToMatch) {
                            return this.jurisdictions[i].cities[j].villages[k];
                        }
                    }
                }
            }
        }
        //console.log("could not match jurisdiction by rp code", rp_code);
        return null;
    }

    getJurisdictionByJID(jid) {
        for (var i = 0; i < this.jurisdictions.length; ++i) {
            for (var j = 0; j < this.jurisdictions[i].cities.length; ++j) {
                if (this.jurisdictions[i].cities[j].jurisdiction_id == jid) {
                    return this.jurisdictions[i].cities[j];
                }
                for (var k = 0; k < this.jurisdictions[i].cities[j].villages.length; ++k) {
                    if (this.jurisdictions[i].cities[j].villages[k].jurisdiction_id == jid) {
                        return this.jurisdictions[i].cities[j].villages[k];
                    }
                }
            }
        }
        //console.log("could not match jurisdiction by rp code", rp_code);
        return null;
    }

    getCountyParcelAvailability() {
        return this.http.get<any>(this.getCountyParcelAvailabilityURL);
    }

    getSchoolDistrictName(schoolDistrictSWIS) {
        return this.http.get<any>(this.getSchoolDistrictNameURL + schoolDistrictSWIS);
    }

    getJurisdictionByRealPropertyCodeQuery(rpCode) {
        return this.http.get<any>("api/jurisdictionByRealPropertyCode/" + rpCode);
    }

    getJurisdictionByRealPropertyCodePromise(rpCode) {
        var subscription
        return new Promise((resolve, reject) => {
            subscription = this.getJurisdictionByRealPropertyCodeQuery(rpCode).subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                resolve(response.result)
            })
        }).finally(() => {
            if (subscription) {
                subscription.unsubscribe()
            }
        })
    }

    getStates() {
        return this.http.get<any>("api/states");
    }

    getCounties(stateId) {
        return this.http.get<any>("api/counties/" + stateId);
    }

    getCountyJurisdictions() {
        return this.http.get<any>("api/countyJurisdictions")
    }
}
