<span *ngIf="!initialized">
    <div class="container-fluid mt-4 search-panel">
        Initializing...
    </div>
</span>
<div *ngIf="initialized" class="container-fluid mt-4 search-panel">
    <div class="row search-header-row pb-2">
        <div class="col-6 bold mt-2" style="font-size: 2em;">
            Quick Search
        </div>
        <div class="col-6 mt-3">
            <app-input-text label="State" [fc]="stateFC" [disabled]="true"></app-input-text>
        </div>
    </div>
    <div class="row pt-1">
        <div class="col-4">
            <app-input-search #countySearchInput label="County" [fc]="countyFC"
                [searchFn]="jurisdictionService.searchCounty.bind(jurisdictionService)" [requiredMarker]="true">
            </app-input-search>
        </div>
        <div class="col-4">
            <app-input-search #addressSearchInput label="Address" [fc]="addressFC" [searchFn]="searchAddress.bind(this)"
                [getItemDisplayValue]="getAddressItemDisplay.bind(this)" [itemSelect]="addressItemSelect.bind(this)">
            </app-input-search>
        </div>
        <div class="col-4">
            <app-input-search #sblSearchInput label="SBL" [fc]="sblFC" [searchFn]="searchSBL.bind(this)"
                [getItemDisplayValue]="getSBLItemDisplay.bind(this)" [itemSelect]="sblItemSelect.bind(this)">
            </app-input-search>
        </div>
    </div>

    <div class="row pb-3">
        <div class="col mt-3 bold" align="center">
            <app-button label="RESET SEARCH" [clickFunction]="clear.bind(this)" [icon]="faBan">
            </app-button>
        </div>
    </div>

</div>

<app-researcher-tools-results #results [clearFunction]="clear.bind(this)"></app-researcher-tools-results>