<div class="container-fluid pt-2">

    <div class="row">
        <div class="col">
            Special Buttons:<br />
            &nbsp;&nbsp; Delete: red<br />
            &nbsp;&nbsp; Save (or update, create, save for later, etc): green<br />
            &nbsp;&nbsp; Next: blue<br />
            Mouseover causes special button background color to darken.<br />
            All other buttons have a white background, light blue border and light blue text.<br />
            On mouseover, text becomes white, background and border become navy.<br />
        </div>
    </div>

    <div class="row mt-3">
        <div class="col">
            2 button example
        </div>
    </div>

    <app-button-group-create [cancel]="doButtonTest" [create]="doButtonTest"></app-button-group-create>

    <div class="row">
        <div class="col">
            2 button plus delete
        </div>
    </div>
    <app-button-group-save [cancel]="doButtonTest" [save]="doButtonTest" [delete]="doButtonTest">
    </app-button-group-save>

    <div class="row">
        <div class="col">
            3 button example (same as 2 button plus delete)
        </div>
    </div>
    <app-button-group-save [cancel]="doButtonTest" [save]="doButtonTest" [delete]="doButtonTest">
    </app-button-group-save>

    <div class="row">
        <div class="col">
            3+ button example - multi page
        </div>
    </div>

    <div class="row">
        <div class="col">
            first page (no previous button)
        </div>
    </div>

    <app-button-group-wizard-first [cancel]="doButtonTest" [save]="doButtonTest" [next]="doButtonTest">
    </app-button-group-wizard-first>

    <div class="row">
        <div class="col">
            interim pages
        </div>
    </div>

    <app-button-group-wizard-interim [cancel]="doButtonTest" [save]="doButtonTest" [next]="doButtonTest"
        [previous]="doButtonTest">
    </app-button-group-wizard-interim>

    <div class="row">
        <div class="col">
            final page
        </div>
    </div>
    <app-button-group-wizard-final [cancel]="doButtonTest" [save]="doButtonTest" [previous]="doButtonTest">
    </app-button-group-wizard-final>


    <div class="row">
        <div class="col">
            small buttons are used in special cases within forms
        </div>
    </div>
    <div class="row">
        <div class="col" align="center">
            <app-button label="Previous" [icon]="faChevronLeft" [small]="true">
            </app-button>
            <app-button [buttonType]="ButtonType.cancel" [small]="true">
            </app-button>
            <app-button class="d-none d-md-block" [buttonType]="ButtonType.next" style="float:right;" [small]="true">
            </app-button>
            <app-button class="d-md-none" [buttonType]="ButtonType.next" [small]="true">
            </app-button>
            <app-button class="d-none d-md-block" [buttonType]="ButtonType.save" style="float:right;" [small]="true">
            </app-button>
            <app-button class="d-md-none" [buttonType]="ButtonType.save" [small]="true">
            </app-button>
        </div>
    </div>

</div>