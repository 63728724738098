import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faInfoCircle, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-input-text-list',
    templateUrl: './input-text-list.component.html',
})
export class InputTextListComponent implements OnInit {
    @Input() label;
    @Input() fc;
    @Input() change;
    @Input() exampleText;
    faMinus = faMinus;
    faPlus = faPlus;
    @Input() tooltip
    faInfoCircle = faInfoCircle

    constructor() {
    }

    ngOnInit() {
        if (this.fc.fcs.length == 0) {
            this.fc.fcs = [new UntypedFormControl()];
        }
    }

    addFC(index) {
        this.fc.fcs.splice(index + 1, 0, new UntypedFormControl());
        this.doChange();
    }

    removeFC(index) {
        this.fc.fcs.splice(index, 1);
        if (this.fc.fcs.length == 0) {
            this.addFC(0);
        }
        this.doChange();
    }

    doChange() {
        if (this.change) {
            this.change();
        }
    }

}
