import { Component, OnInit } from '@angular/core';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { UserService } from '../user.service';
import {
    faCheck
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-user-feedback-confirmation',
    templateUrl: './user-feedback-confirmation.component.html',
})
export class UserFeedbackConfirmationComponent implements OnInit {
    faCheck = faCheck
    initialized = false

    constructor(
        protected _themeService: ThemeRebrandService,
        private userService: UserService,
    ) { }

    get themeService() {
        return this._themeService;
    }

    ngOnInit() {
        this.initialized = true
    }

    ok() {
        this.userService.navigateToInitialPage()
    }

}
