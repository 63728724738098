<span *ngIf="!initialized">
    <div class="container-fluid bordered-container mt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.tertiaryColor"
        [style.border-color]="themeService.tertiaryColor">
        Initializing
    </div>
</span>
<span *ngIf="initialized">
    <div class="container-fluid mt-4 py-2 rounded-ninety" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor">

        <div class="row">
            <div class="col-2">

            </div>
            <div class="col-8" align="center">
                Thank you for submitting feedback. <br />
                We will respond shortly if requested.
            </div>
        </div>
        <div class="row">
            <div class="col-2">
            </div>
            <div class="col-8">
                <div class="row pt-3 pb-2">
                    <div class="col pb-2" align="center">
                        <app-button class="mx-1" label="Ok" [clickFunction]="ok.bind(this)" [icon]="faCheck">
                        </app-button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</span>