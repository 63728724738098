<div *ngIf="format == InputCheckboxFormat.none">
    <input [id]="id" type="checkbox" [formControl]="fc" (click)="doClick($event)" (keyup)="doChange($event)"
        (keyup.enter)="doEnter()" class="pointer">
    <label class="ml-1 mb-0" [for]="id" class="pointer">{{label}}</label>
</div>

<div *ngIf="format == InputCheckboxFormat.verticalLabel" class="row mt-3">
    <div class="col bold">
        {{label}}
        <span *ngIf="tooltip" data-toggle="tooltip" data-placement="right" [title]="tooltip" class="ml-1">
            <fa-icon [icon]="faInfoCircle">
            </fa-icon>
        </span>
    </div>
</div>
<div *ngIf="format == InputCheckboxFormat.verticalLabel" class="row mt-1">
    <div *ngIf="!disabled" class="col">
        <input class="input-style ml-2" [id]="id" type="checkbox" [formControl]="fc" (click)="doClick($event)"
            (keyup)="doChange($event)" (keyup.enter)="doEnter()" class="pointer">
    </div>
    <div *ngIf="disabled" class="col">
        {{fc.value}}
    </div>
</div>