<span *ngIf="!initialized">
    <div class="container-fluid mt-4 search-panel">
        Initializing...
    </div>
</span>


<div *ngIf="initialized" class="container-fluid mt-4 rounded-ninety">
    <span *ngIf="!documents;else showDocs">No documents attached to this order.</span>
    <ng-template #showDocs>
        <span *ngFor="let document of documents; let first = first">
            <div class="row" *ngIf="first">
                <div class="col-8 attribute-h1 mt-4 mb-2">
                    Documents Attached to Order {{document.order_id}}
                </div>
                <div class="col-4 mt-4 mb-2 link" style="padding-top: 10px;" (click)="downloadAll(documents)">
                    <fa-icon [icon]="faFileDownload" class="mr-1" size="1x"></fa-icon>Download All Files
                </div>
            </div>
            <div class="row py-4 table-header" *ngIf="first">
                <div class="col bold">
                    Description
                </div>
                <div class="col bold">
                    Type
                </div>
                <div class="col bold">
                    Filename
                </div>
                <div class="col bold">
                    Created
                </div>
            </div>
            <div class="row py-4">
                <div class="col link link-color" (click)="download(document)">
                    {{document.description}}
                </div>
                <div class="col">
                    {{document.document_type_name}}
                </div>
                <div class="col">
                    {{document.file_name}}
                </div>
                <div class="col">
                    {{document.created | date: 'M/d/yyyy'}}
                </div>
            </div>
        </span>
    </ng-template>
</div>
