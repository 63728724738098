<span *ngIf="!initialized">
    <div class="container-fluid mt-4 search-panel">
        Initializing...
    </div>
</span>

<div *ngIf="initialized" class="container-fluid mt-4 search-panel">
    <div class="row search-header-row pb-2">
        <div class="col-6 bold mt-2" style="font-size: 2em;">
            Advanced Search
        </div>
        <div class="col-6 mt-3">
            <app-input-text label="State" [fc]="stateFC" [disabled]="true" ></app-input-text>
        </div>
    </div>

    <div class="row pt-1">
        <div class="col-4">
            <app-input-search #countySearchInput label="County" [fc]="countyFC" [itemSelect]="countySelected.bind(this)"
                [searchFn]="jurisdictionService.searchCounty.bind(jurisdictionService)" [requiredMarker]="true"
                >
            </app-input-search>
        </div>
        <div class="col-4">
            <app-input-search #municipalitySearchInput label="Municipality" [fc]="municipalityFC"
                [itemSelect]="municipalitySelected.bind(this)" [searchFn]="searchMunicipality.bind(this)"
                ></app-input-search>
        </div>
        <div class="col-4">
            <app-input-text-and-enum label="SBL" [fc]="sblFC" [enumFC]="sblMatchTypeFC" [enumValues]="matchTypeValues"
                >
            </app-input-text-and-enum>
        </div>
    </div>

    <div class="row">
        <div class="col-4">
            <app-input-text-and-enum label="Owner Name" [fc]="ownerFC" [enumFC]="ownerMatchTypeFC"
                [enumValues]="matchTypeValues" >
            </app-input-text-and-enum>
        </div>
        <div class="col-4">
            <app-input-text-and-enum label="Street #" [fc]="streetNumberFC" [enumFC]="streetNumberMatchTypeFC"
                [enumValues]="matchTypeValues" >
            </app-input-text-and-enum>
        </div>
        <div class="col-4">
            <app-input-text-and-enum label="Street Name" [fc]="streetNameFC" [enumFC]="streetNameMatchTypeFC"
                [enumValues]="matchTypeValues" >
            </app-input-text-and-enum>
        </div>
    </div>
    <div *ngIf="error" class="row">
        <div class="col">
            {{error}}
        </div>
    </div>
    <div class="row pb-3">
        <div class="col mt-3 bold">
            <div class="action-button action-button_primary" (click)="doSearch()">
                <fa-icon *ngIf="processing" [icon]="faSpinner" [spin]="true" size="1x"></fa-icon>
                <fa-icon *ngIf="!processing" [icon]="faSearch"></fa-icon> SEARCH
            </div>
            <div class="action-button action-button_secondary ml-2" (click)="clear()"
                 align="center">
                <fa-icon [icon]="faBan"></fa-icon> RESET SEARCH
            </div>
        </div>
    </div>
</div>

<span id="searchResultsSpan">
    <div *ngIf="searchResults" class="container-fluid mt-4">
        <div class="row mt-4 py-3 table-header">
            <!-- <div class="col-1 px-0">
            </div> -->
            <div class="col-2 pr-0" align="center">
                SBL
            </div>
            <div class="col-1 px-0" align="center">
                County
            </div>
            <div class="col-2 px-0" align="center">
                Property Address
            </div>
            <div class="col-2 px-0" align="center">
                Municipality
            </div>
            <div class="col-2 px-0" align="center">
                Owner(s)
            </div>
            <div class="col-2 px-0" align="center">
                Class Name
            </div>
            <div class="col-1 px-0" align="center">
                Use Code
            </div>
        </div>

        <div class="row mt-3" *ngIf="searchResults.length == 0">
            <div class="col" align="center">
                No results found.
            </div>
        </div>

        <span class="link"
            *ngFor="let searchResult of searchResults; let i = index; let first = first; let last = last;"
            (click)="addressClick(searchResult)">
            <div *ngIf="!first" class="row table-row" style="background-color: white;">
                <div class="col" align="center">
                    <div class="table-break"></div>
                </div>
            </div>

            <div class="row py-3 table-row" [ngClass]="{'table-bottom': last}" style="background-color: white;">
                <!-- <div class="col-1" align="right">
                <input type="radio" [formControl]="searchResultSelectionFormControl" value="{{i}}" />
            </div> -->
                <div class="col-2" align="center">
                    {{ searchResult.print_key }}
                </div>
                <div class="col-1 px-0" align="center">
                    {{ searchResult.county_name | titlecase }}
                </div>
                <div class="col-2 px-0" align="center">
                    {{ searchResult.property_full_address | titlecase }}
                </div>
                <div class="col-2 px-0" align="center">
                    {{ searchResult.town_long_description | titlecase }}
                </div>
                <div class="col-2 px-0" align="center">
                    {{ getOwnerString(searchResult) | titlecase }}
                </div>
                <div class="col-2 px-0" align="center">
                    {{ searchResult.land_use_long_description | titlecase }}
                </div>
                <div class="col-1 px-0" align="center">
                    {{ searchResult.use_code }}
                </div>
            </div>
        </span>
        <div class="row mt-4 pb-3">
            <div class="col mt-3 bold">
                <div class="action-button action-button_secondary ml-2" (click)="clear()"
                     align="center">
                    <fa-icon [icon]="faBan"></fa-icon> RESET SEARCH
                </div>
            </div>
        </div>
    </div>
</span>

<app-researcher-tools-results [clearFunction]="clear.bind(this)"></app-researcher-tools-results>
