<div *ngIf="!initialized" class="container-fluid mt-4 rounded-ninety" [style.color]="themeService.textOnTertiaryColor"
    [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.secondaryColor">
    Initializing...
</div>
<div *ngIf="initialized" class="container-fluid mt-4 rounded-ninety" [style.color]="themeService.textOnTertiaryColor"
    [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.tertiaryColor"
    [style.border-color]="themeService.tertiaryColor">

    <div class="row mt-3">
        <div class="col">

        </div>
    </div>
    <div class="row mt-3">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10 bold" style="font-size: 1.2em;">
            Please select and answer at least three security questions.
        </div>
        <div class="col-1 d-none d-md-block">
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            You should choose questions with answers that are easiest for you to remember.
        </div>
        <div class="col-1 d-none d-md-block">
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            Note: Answers are stored using a one-way cryptographic hash, so we will never be able to access your
            answers.
        </div>
        <div class="col-1 d-none d-md-block">
        </div>
    </div>
    <span *ngFor="let question of questions">
        <div class="row mt-3">
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col-1" align="right">
                <input id="{{question.id}}_checkbox" type="checkbox" (click)="toggleDisabled(question.id);"
                    [checked]="answerExists(question.id)" />
            </div>
            <div class="col-5 col-md-4">
                <span (click)="toggleCheckbox(question.id);"> {{question.question}}</span>
            </div>
            <div class="col-6 col-md-5">
                <input [id]="question.id" type="text" [placeholder]="getPlaceholder(question.id)"
                    [disabled]="!answerExists(question.id)" class="input-style"
                    (keyup)="checkShowCancel(question.id)" />
            </div>
            <div class="col-1 d-none d-md-block">
            </div>
        </div>
        <div class="row">
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col-6 col-md-5">
            </div>
            <div class="col-6 col-md-5 bold">
                <span id="{{question.id}}_showcancel" style="display: none; color: red; cursor: pointer;"
                    (click)="cancelChange(question.id);">
                    cancel change</span>
            </div>
            <div class="col-1 d-none d-md-block">
            </div>
        </div>
    </span>
    <div *ngIf="error" class="row mt-3">
        <div class="col bold" align="center" style="color: red;">
            {{error}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col" align="center">
            <app-button class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
            </app-button>
            <app-button class="mx-1" label="Save Security Questions" [clickFunction]="submit.bind(this)"
                [icon]="faSave">
            </app-button>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col">
        </div>
    </div>
</div>