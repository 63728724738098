import { SysMunicipality } from "../model/sys-municipality.model";

export const SYSMUNICIPALITY: SysMunicipality[] =

[
  // { data mapping
  //   "state": "state",
  //   "countyId": "county_id",
  //   "municipalityName": "name",
  //   "type": "type",
  //   "parentId": "parent_id",
  //   "childId": "child_id",
  //   "realPropertyCode": "real_property_code",
  //   "taxTypeId": "tax_type_id",
  //   "address": "address",
  //   "taxYear": "tax_year",
  //   "taxPeriod": "tax_period",
  //   "lienDate": "lien_date",
  //   "fiscalStartDate": "fiscal_start_date",
  //   "fiscalEndDate": "fiscal_end_date",
  //   "dueDate1": "due_date_1",
  //   "dueDate2": "due_date_2",
  //   "dueDate3": "due_date_3",
  //   "dueDate4": "due_date_4",
  //   "refreshDate": "refresh_date"
  // },

    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Albany",
      "type": "city",
      "parentId": "36001_010000",
      "childId": "36001_010100",
      "realPropertyCode": "010100",
      "taxTypeId": "CITY",
      "address": "City of Albany\nPO Box 1878\nAlbany, NY 12201\np: 518-434-5035",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Altamont",
      "type": "village",
      "parentId": "36001_013000",
      "childId": "36001_013001",
      "realPropertyCode": "013001",
      "taxTypeId": "DEFAULT",
      "address": "PO BOX 643 115 MAIN ST\nALTAMONT, NY 12009\np: 58-861-8554| f: 518-861-5379",
      "taxYear": "2022/2023",
      "taxPeriod": "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("01-Jul-22"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Berne",
      "type": "town",
      "parentId": "36001_010000",
      "childId": "36001_012000",
      "realPropertyCode": "012000",
      "taxTypeId": "TOWN",
      "address": "311 LONG RD\nEAST BERNE, NY 12059\np: 518-872-0331",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Bethlehem",
      "type": "town",
      "parentId": "36001_010000",
      "childId": "36001_012200",
      "realPropertyCode": "012200",
      "taxTypeId": "TOWN",
      "address": "445 DELAWARE AVE ROOM 112 \nDELMAR, NY 12054\np: 581-439-4955",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Coeymans",
      "type": "town",
      "parentId": "36001_010000",
      "childId": "36001_012400",
      "realPropertyCode": "012489",
      "taxTypeId": "TOWN",
      "address": "",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": new Date("01-Jan-22"),
      "fiscalEndDate": new Date("31-Dec-22"),
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Cohoes",
      "type": "city",
      "parentId": "36001_010000",
      "childId": "36001_010300",
      "realPropertyCode": "010300",
      "taxTypeId": "CITY",
      "address": "CITY OF COHOES\n97 MOHAWK ST\nCohoes, NY 12047\np: 518-233-2111",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("31-Mar-23"),
      "dueDate2": new Date("30-Sep-23"),
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Colonie",
      "type": "town",
      "parentId": "36001_010000",
      "childId": "36001_012600",
      "realPropertyCode": "012689",
      "taxTypeId": "TOWN",
      "address": "",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": new Date("01-Jan-22"),
      "fiscalEndDate": new Date("31-Dec-22"),
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Colonie",
      "type": "village",
      "parentId": "36001_012600",
      "childId": "36001_012601",
      "realPropertyCode": "012601",
      "taxTypeId": "DEFAULT",
      "address": "2 THUNDER RD \nCOLONIE, NY 12205\np: 518-869-7562",
      "taxYear": "2022/2023",
      "taxPeriod": "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("01-Jul-22"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Green Island",
      "type": "town",
      "parentId": "36001_010000",
      "childId": "36001_012800",
      "realPropertyCode": "",
      "taxTypeId": "DEFAULT",
      "address": "",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": new Date("01-Jan-22"),
      "fiscalEndDate": new Date("31-Dec-22"),
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Green Island",
      "type": "village",
      "parentId": "36001_012800",
      "childId": "36001_012801",
      "realPropertyCode": "012801",
      "taxTypeId": "DEFAULT",
      "address": "20 CLINTON ST\nGREEN ISLAND, NY 12183\np: 518-273-2201| f: 518-273-2235",
      "taxYear": "2022/2023",
      "taxPeriod": "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("01-Jul-22"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Guilderland",
      "type": "town",
      "parentId": "36001_010000",
      "childId": "36001_013000",
      "realPropertyCode": "013089",
      "taxTypeId": "TOWN",
      "address": "",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": new Date("01-Jan-23"),
      "fiscalEndDate": new Date("31-Dec-23"),
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Knox",
      "type": "town",
      "parentId": "36001_010000",
      "childId": "36001_013200",
      "realPropertyCode": "013200",
      "taxTypeId": "TOWN",
      "address": "2192 BERNE-ALTTAMONT RD\nKNOX, NY 12107\np: 518-872-2551",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Menands",
      "type": "village",
      "parentId": "36001_012600",
      "childId": "36001_012603",
      "realPropertyCode": "012603",
      "taxTypeId": "DEFAULT",
      "address": "250 BROADWAY\nMENANDS, NY 12204\np: 518-434-2922",
      "taxYear": "2022/2023",
      "taxPeriod": "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("01-Jul-22"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "New Scotland",
      "type": "town",
      "parentId": "36001_010000",
      "childId": "36001_013400",
      "realPropertyCode": "013489",
      "taxTypeId": "TOWN",
      "address": "",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": new Date("01-Jan-22"),
      "fiscalEndDate": new Date("31-Dec-22"),
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Ravena",
      "type": "village",
      "parentId": "36001_012400",
      "childId": "36001_012401",
      "realPropertyCode": "012401",
      "taxTypeId": "DEFAULT",
      "address": "15 MOUNTIAN RD\nRAVENA, NY 12143\np: 518-756-8233",
      "taxYear": "2022/2023",
      "taxPeriod": "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("01-Jul-22"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Rensselaerville",
      "type": "town",
      "parentId": "36001_010000",
      "childId": "36001_013600",
      "realPropertyCode": "013600",
      "taxTypeId": "TOWN",
      "address": "87 BARGER RD\nMEDUSA, NY 12120\np: 518-797-3798",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Voorheesville",
      "type": "village",
      "parentId": "36001_013400",
      "childId": "36001_013401",
      "realPropertyCode": "013401",
      "taxTypeId": "DEFAULT",
      "address": "PO BOX 367\nVOORHEESVILLE, NY 12186\np: 518-765-2692",
      "taxYear": "2022/2023",
      "taxPeriod": "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("01-Jul-22"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Watervliet",
      "type": "city",
      "parentId": "36001_010000",
      "childId": "36001_011800",
      "realPropertyCode": "011800",
      "taxTypeId": "CITY",
      "address": "CITY HALL\n2 15TH ST\nWATERVLIET, NY 12189\np: 518-270-3800 EX",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Albany",
      "municipalityName": "Westerlo",
      "type": "town",
      "parentId": "36001_010000",
      "childId": "36001_013800",
      "realPropertyCode": "013800",
      "taxTypeId": "TOWN",
      "address": "PO BOX 148\nWESTERLO, NY 12193\np: 518-797-3111",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Schenectady",
      "municipalityName": "Delanson",
      "type": "village",
      "parentId": "36093_422000",
      "childId": "36093_422001",
      "realPropertyCode": "422001",
      "taxTypeId": "DEFAULT",
      "address": "1797 MAIN ST \nDELANSON, NY 12053\np: 518-895-2199",
      "taxYear": "2021/2022",
      "taxPeriod": "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("01-Jun-21"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Schenectady",
      "municipalityName": "Duanesburg",
      "type": "town",
      "parentId": "36093_420000",
      "childId": "36093_422000",
      "realPropertyCode": "422089",
      "taxTypeId": "TOWN",
      "address": "5853 WESTERN TURNPIKE\nDUANESBURG, NY 12056\np: 518-895-8920",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Schenectady",
      "municipalityName": "Glenville",
      "type": "town",
      "parentId": "36093_420000",
      "childId": "36093_422200",
      "realPropertyCode": "422289",
      "taxTypeId": "TOWN",
      "address": "",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": new Date("01-Jan-22"),
      "fiscalEndDate": new Date("31-Dec-22"),
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Schenectady",
      "municipalityName": "Niskayuna",
      "type": "town",
      "parentId": "36093_420000",
      "childId": "36093_422400",
      "realPropertyCode": "422400",
      "taxTypeId": "TOWN",
      "address": "ONE NISKAYUNA CIRCLE\nNISKAYUNA, NY 12309\np: 518-386-4540| f: 518-386-4509",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Schenectady",
      "municipalityName": "Princetown",
      "type": "town",
      "parentId": "36093_420000",
      "childId": "36093_422600",
      "realPropertyCode": "422600",
      "taxTypeId": "TOWN",
      "address": "165 PRINCETOWN PLAZA\nSCHENECTADY, NY 12306\np: 518-357-4045",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Schenectady",
      "municipalityName": "Rotterdam",
      "type": "town",
      "parentId": "36093_420000",
      "childId": "36093_422800",
      "realPropertyCode": "422800",
      "taxTypeId": "TOWN",
      "address": "1100 SUNRISE BLVD\nROTTERDAM, NY 12306\np: 518-355-7575",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("31-Jan-23"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Schenectady",
      "municipalityName": "Schenectady",
      "type": "city",
      "parentId": "36093_420000",
      "childId": "36093_421500",
      "realPropertyCode": "421500",
      "taxTypeId": "CITY",
      "address": "CITY COMPTROLLER\n105 JAY ST\nSchenectady, NY 12305\np: 518-382-5016",
      "taxYear": "2023",
      "taxPeriod": "1/1-12/31",
      "lienDate": "1/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("17-Jan-23"),
      "dueDate2": new Date("17-Apr-23"),
      "dueDate3": new Date("17-Jul-23"),
      "dueDate4": new Date("16-Oct-23"),
      "refreshDate": new Date("31-Jan-2024")
    },
    {
      "state": "NY",
      "countyId": "Schenectady",
      "municipalityName": "Scotia",
      "type": "village",
      "parentId": "36093_422200",
      "childId": "36093_422201",
      "realPropertyCode": "422201",
      "taxTypeId": "DEFAULT",
      "address": "4 NORTH TEN BROECK ST\nSCOTIA, NY 12302\np: 518-374-1071",
      "taxYear": "2022/2023",
      "taxPeriod": "6/1-5/31",
      "lienDate": "6/1",
      "fiscalStartDate": null,
      "fiscalEndDate": null,
      "dueDate1": new Date("01-Jul-22"),
      "dueDate2": null,
      "dueDate3": null,
      "dueDate4": null,
      "refreshDate": new Date("31-Jan-2024")
    }
  ]