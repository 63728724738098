<div *ngIf="format == InputDateFormat.verticalLabel" class="row mt-3">
    <div class="col bold">
        {{label}}
        <span *ngIf="tooltip" data-toggle="tooltip" data-placement="right" [title]="tooltip" class="saq-tooltip ml-1">
            <fa-icon [icon]="faInfoCircle">
            </fa-icon>
        </span>
    </div>
</div>
<div *ngIf="format == InputDateFormat.verticalLabel" class="row mt-1">
    <div *ngIf="!disabled" class="col">
        <input type="date" class="input-style" [formControl]="fc" (change)="doChange()" />
    </div>
    <div *ngIf="disabled" class="col">
        {{fc.value}}
    </div>
</div>
<span *ngIf="format == InputDateFormat.none">
    <div *ngIf="!disabled" class="col">
        <input type="date" class="input-style" [formControl]="fc" (change)="doChange()" />
    </div>
    <div *ngIf="disabled" class="col">
        {{fc.value}}
    </div>
</span>