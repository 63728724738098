<p-card *ngIf="dataLoaded">
  <div class="card-header">
    <h2>{{ isNew ? 'Create New Client' : 'Edit Client' }}</h2>
    <button *ngIf="!isNew" pButton type="button" label="Client Merge" (click)="displayDialog = true" class="open-dialog-button"></button>
</div>

    <form [formGroup]="clientForm" (ngSubmit)="onSubmit()" class="client-form">
      <div class="form-row">

        <div class="form-group p-col-12 p-md-6">
          <label for="fileUpload">Upload Company Logo</label>
          <p-fileUpload 
              name="logo" 
              [customUpload]="true" 
              (uploadHandler)="uploadFile($event)" 
              accept="image/*" 
              [maxFileSize]="1000000" 
              chooseLabel="Choose File" 
              uploadLabel="Upload" 
              cancelLabel="Cancel">
          </p-fileUpload>
          <div *ngIf="uploadStatus" class="status">{{ uploadStatus }}</div>
          <div *ngIf="percentDone !== null" class="progress">Progress: {{ percentDone }}%</div>
          <div *ngIf="error" class="error">{{ error }}</div>
        </div>

        <div class="form-group p-col-12 p-md-6">
          <label for="name">Client Name</label>
          <input id="name" pInputText formControlName="name" />
          <small *ngIf="clientForm.get('name')?.invalid && (clientForm.get('name')?.touched || clientForm.get('name')?.dirty)">
            {{ formErrors.name }}
          </small>
        </div>
  
        <div class="form-group p-col-12 p-md-6">
          <label for="webAddress">Website URL</label>
          <input id="webAddress" pInputText formControlName="webAddress" />
          <div class="error-message" *ngIf="webAddress?.touched && webAddress?.invalid">
            <span *ngIf="webAddress?.errors?.['required']">Web Address is required.</span>
            <span *ngIf="webAddress?.errors?.['pattern']">Enter a valid URL (e.g., www.example.com).</span>
          </div>
        </div>
  
        <div class="form-group p-col-12 p-md-6">
          <label for="address">Address</label>
          <input id="address" pInputText formControlName="address" />
        </div>
  
        <div class="form-group p-col-12 p-md-6">
          <label for="phone">Phone Number</label>
          <input id="phone" pInputText formControlName="phone" />
          <small *ngIf="clientForm.get('phone')?.invalid && (clientForm.get('phone')?.touched || clientForm.get('phone')?.dirty)">
            {{ formErrors.phone }}
          </small>
        </div>
  
        <div class="form-group p-col-12 p-md-6">
          <label for="fax">Fax Number</label>
          <input id="fax" pInputText formControlName="fax" />
        </div>
  
        <div class="form-group p-col-12 p-md-6">
          <label for="invoiceEmailAddress">Invoice Email Address</label>
          <input id="invoiceEmailAddress" pInputText formControlName="invoiceEmailAddress" />
          <div class="error-message" *ngIf="email?.touched && email?.invalid">
            <span *ngIf="email?.errors?.['required']">Email is required.</span>
            <span *ngIf="email?.errors?.['email']">Enter a valid email address.</span>
          </div>
        </div>
  
        <div class="form-group p-col-12 p-md-6">
          <label for="defaultPaymentTermId">Payment Term</label>
          <p-dropdown 
              id="defaultPaymentTermId" 
              formControlName="defaultPaymentTermId" 
              [options]="paymentTerms" 
              optionLabel="name" 
              placeholder="Select a Payment Term">
          </p-dropdown>
          <small *ngIf="clientForm.get('defaultPaymentTermId')?.invalid && (clientForm.get('defaultPaymentTermId')?.touched || clientForm.get('defaultPaymentTermId')?.dirty)">
            {{ formErrors.defaultPaymentTermId }}
          </small>
        </div>
  
        <div class="form-group p-col-12 p-md-6">
          <label for="jurisdictionName">Sales Tax Jurisdiction</label>
          <p-dropdown 
              id="jurisdictionName" 
              formControlName="jurisdictionName" 
              [options]="salesTaxJurisdictions" 
              optionLabel="name" 
              placeholder="Select a Sales Tax Jurisdiction">
          </p-dropdown>
        </div>
  
        <div class="form-group p-col-12 p-md-6">
          <label for="defaultTimeZone">Time Zone</label>
          <p-dropdown 
              id="defaultTimeZone" 
              formControlName="defaultTimeZone" 
              [options]="timeZones" 
              optionLabel="name" 
              placeholder="Select a Time Zone">
          </p-dropdown>
        </div>
      </div>
  
      <div class="form-group full-width">
        <label for="internalBillingNotes">Internal Billing Notes</label>
        <textarea id="internalBillingNotes" pInputTextarea formControlName="internalBillingNotes"></textarea>
      </div>
  
      <div class="button-group">
        <button pButton type="submit" label="Submit" severity="success" [disabled]="clientForm.invalid"></button>
        <button pButton type="button" label="Cancel" [outlined]="true" (click)="back()"></button>
      </div>
    </form>
</p-card>

<p-dialog 
    header="Merge Client" 
    [(visible)]="displayDialog" 
    [modal]="true" 
    [closable]="true" 
    [resizable]="false" 
    [draggable]="false" 
    [style]="{ width: '600px' }"
    (onShow)="patchClientMergeData(clientData)">
  
  <form [formGroup]="clientMergeForm" (ngSubmit)="onMergeSubmit()">
    <div class="form-group">
      <label for="companyName">Company Name to Keep</label>
      <input id="companyName" pInputText formControlName="companyName" placeholder="Enter Company Name" />
    </div>

    <div class="form-group">
      <label for="mergeCompanyName">Company Name to Remove</label>
      <p-autoComplete 
        id="mergeCompanyName" 
        formControlName="mergeCompanyName" 
        [suggestions]="filteredMergeClients" 
        (completeMethod)="searchMergeClients($event)" 
        field="name" 
        placeholder="Search Merge Company" 
        [minLength]="1" 
        [style]="{'width':'100%'}"
        [inputStyle]="{'width':'100%'}">
      </p-autoComplete>
    </div>

    <div class="form-group">
      <label for="confirmInput">Confirm Merge</label>
      <input id="confirmInput" pInputText formControlName="confirmInput" placeholder="Type 'yes' to confirm" />
    </div>


    <p-footer class="custom-footer">
      <button pButton type="button" label="Cancel" class="p-button-danger" icon="pi pi-times" (click)="displayDialog = false"></button>
      <button
        pButton
        type="submit"
        label="Merge"
        icon="pi pi-check"
        [severity]="isMergeDisabled ? 'secondary' : 'info'"
        [disabled]="isMergeDisabled"
        [ngClass]="{ 'custom-enabled': !isMergeDisabled }">
      </button>
    </p-footer>
  </form>
</p-dialog>

<p-toast></p-toast>