<app-order-create-step-circles [stepProvider]="orderService"></app-order-create-step-circles>

<span *ngIf="!initialized">
    <div class="container-fluid bordered-container-middle pt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.secondaryColor">
        Initializing
    </div>
</span>

<div *ngIf="initialized" class="container-fluid bordered-container-middle light-blue-bordered"
    [style.color]="themeService.primaryColor" [style.background-color]="themeService.tertiaryColor">
    <div class="row pb-1">
        <div class="col pt-4 pb-3 attribute-h1 bold" align="center">
            Individual Services
        </div>
    </div>
    <div class="row py-2" [style.color]="themeService.textOnBackgroundColor"
        [style.background-color]="themeService.backgroundColor"
        style="font-weight: bold; border-radius: 0.9em 0.9em 0em 0em">
    </div>

    <div class="row py-2" [style.color]="themeService.textOnBackgroundColor"
        [style.background-color]="themeService.backgroundColor">
        <div *ngFor="let service of services; let i = index; let last = last" class="col px-0" align="center"
           >
            {{service.service_name}}<br />
            <span *ngIf="service.popup_help" style="position: relative; vertical-align: super;">
                <fa-icon [icon]="faQuestionCircle" class="ml-1 pointer" size="1x" id="popup_help_{{i}}"
                    (mouseenter)="questionMouseEnter('popup_help_' + i)"
                    (mouseleave)="questionMouseLeave('popup_help_' + i)"
                    (click)="toggleDisplay('popup_help_text_' + i); toggleDisplay('popup_help_arrow_' + i)">
                </fa-icon>
                <span id="popup_help_text_{{i}}" [ngClass]="{'popuptext': !last, 'popuptextleft': last}"
                    style="display:none;" [style.color]="themeService.primaryColor"
                    [style.background-color]="themeService.tertiaryColor"
                    [style.border-color]="themeService.secondaryColor">
                    {{service.popup_help}}
                    <span style="position: absolute; z-index: 2; left: 9em; top: -6px; cursor: pointer;">
                        <fa-icon [icon]="faWindowClose" id="popup_close_{{i}}"
                            (mouseenter)="questionCloseMouseEnter('popup_close_' + i)"
                            (mouseleave)="questionCloseMouseLeave('popup_close_' + i)"
                            (click)="toggleDisplay('popup_help_text_' + i); toggleDisplay('popup_help_arrow_' + i)">
                        </fa-icon>
                    </span>
                </span>

                <span id="popup_help_arrow_{{i}}" [ngClass]="{'popuptextarrow': !last, 'popuptextarrowleft': last}"
                    style="display: none;" [style.color]="themeService.primaryColor"
                    [style.background-color]="themeService.tertiaryColor"
                    [style.border-color]="themeService.secondaryColor">
                </span>
            </span>
        </div>
    </div>

    <div class="row py-2" [style.color]="themeService.textOnBackgroundColor"
        [style.background-color]="themeService.backgroundColor">
        <div *ngFor="let service of services" class="col" align="center">
            <input type="checkbox" class="checkbox-style mr-2" style="vertical-align: baseline"
                id="{{service.service_id}}_all" (change)="serviceAllChange(service)"
                [checked]="isAllChecked(service)" />
            all
        </div>
    </div>

    <span *ngFor="let person of people; let i = index; let isLast = last">
        <div class="row" [style.border-color]="themeService.backgroundColor" [class.pt-2]="true" [class.pb-1]="true">

            <div *ngIf="person.formControl" class="col-2 px-0" align="center">
                <input class="input-style" style="vertical-align: top; width: 100%;" type="text"
                    [formControl]="person.formControl" (keyup)="additionalIndividualNameChange(i)" />
            </div>
            <div *ngIf="person.formControl" class="col-2 px-0" align="center"></div>

            <div *ngIf="!person.formControl" class="col-6 px-2" align="left">{{person.name}} - {{person.role}}</div>

        </div>
        <div class="row" [style.border-color]="themeService.backgroundColor" [ngClass]="{'data-border': true }"
            [class.pt-2]="true" [class.pb-1]="true">

            <div *ngFor="let service of services; let k = index" class="col px-0" align="center">
                <input type="checkbox" class="checkbox-style" style="vertical-align: baseline"
                    id="{{i}}_{{service.service_id}}" (change)="serviceChange(person, service, i)"
                    [checked]="isChecked(person, service, i)" />
            </div>
        </div>
    </span>

    <div class="row" [style.border-color]="themeService.backgroundColor" [class.pt-2]="true" [class.pb-1]="true">
        <div class="col-2 px-0" align="right">
            <fa-icon [icon]="faPlus" class="ml-1 pointer" size="1x" id="addPeople"
                (mouseenter)="questionMouseEnter('addPeople')" (mouseleave)="addPeopleMouseLeave('addPeople')"
                (click)="addFourPeople()">
            </fa-icon>
        </div>
        <div class="col-2 px-0" align="center"></div>
        <div *ngFor="let service of services; let k = index" class="col px-0" align="center">
        </div>
    </div>

    <div class="row py-2" [style.color]="themeService.textOnBackgroundColor"
        [style.background-color]="themeService.backgroundColor" style="border-radius: 0em 0em .9em .9em;">
        <div class="col" align="center">
            &nbsp;
        </div>
    </div>
    <div class="row pb-1 mt-3">
        <div class="col-md-3">
        </div>
        <div class="col-md-6">
            <app-input-text-area label="Searcher Notes" [fc]="searcherIndividualNotesFC"
                [change]="saveOrderCache.bind(this)"></app-input-text-area>
        </div>
    </div>
</div>

<app-order-create-buttons></app-order-create-buttons>