import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CrudService } from '../crud.service';
import { TimezoneService } from '../timezone.service';
import { UserService } from '../user.service';
import { v4 as uuid } from 'uuid';
import { AuthService } from '../auth.service';
import { faBan, faExclamationCircle, faMinus, faSave } from '@fortawesome/free-solid-svg-icons';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InvoiceService } from '../invoice.service';
import { ProductService } from '../product.service';
import { SnackRouteService } from '../snack-route.service';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
})
export class ProductComponent implements OnInit {
    faBan = faBan
    faSave = faSave
    faMinus = faMinus
    faExclamationCircle = faExclamationCircle
    phoneExampleText = "e.g. (123) 456-7890";

    isReadOnly = false

    editableFields = [
        "name",
        "activated",
    ]
    formControls = {}

    nextOrderIdPrefix
    minNextOrderIdLength = 8

    // setNextOrderIdPrefix() {
    //     this.nextOrderIdPrefix = ""
    //     if (this.formControls["next_order_id"].value.toString().length + this.nextOrderIdPrefix.length < this.minNextOrderIdLength) {
    //         this.nextOrderIdPrefix += "1"
    //         while (this.formControls["next_order_id"].value.toString().length + this.nextOrderIdPrefix.length < this.minNextOrderIdLength) {
    //             this.nextOrderIdPrefix += "0"
    //         }
    //     }
    // }

    timezones$ = this.timezoneService.getTimezones().pipe(
        map(response => response.map(a => a.display_value)),
    )

    dueDates$ = this.invoiceService.getDueDates().pipe(
        map(response => response.result)
    )

    isNew
    productId
    isNewOverride
    providerOverrideId

    productGroups$ = this.productService.getProductGroups().pipe(
        map((response) => response.result)
    )

    productData$ = this.route.queryParams.pipe(
        switchMap((params) => {
            if (params["id"]) {
                this.productId = params["id"]
                this.isNew = false
                return this.productService.getProduct(this.productId)
            } else {
                //todo - throw error? isNew = true isn't valid
                this.isNew = true
                return of({})
            }
        }),
        tap((response: any) => {
            console.log("DATA RESP", response)

            this.formControls["name"] = new UntypedFormControl()
            this.formControls["active"] = new UntypedFormControl()
            this.formControls["price"] = new UntypedFormControl()
            this.formControls["groups"] = []


            if (!this.isNew) {
                this.formControls["name"].setValue(response.result[0].name)
                this.formControls["name"].disable()
                // if price is null, use default and set active true
                if (response.result[0].price_1 == null) {
                    this.isNewOverride = true
                    this.formControls["active"].setValue(true)
                    this.formControls["price"].setValue(response.result[0].default_price_1)
                } else {
                    this.isNewOverride = false
                    this.providerOverrideId = response.result[0].price_id
                    this.formControls["price"].setValue(response.result[0].price_1)
                    this.formControls["active"].setValue(response.result[0].active)
                    for (var i = 0; i < response.result[0].groups.length; ++i) {
                        this.formControls["groups"].push(new UntypedFormControl(response.result[0].groups[i].id))
                    }
                }
            }
        })
    )

    constructor(
        private route: ActivatedRoute,
        private crudService: CrudService,
        private userService: UserService,
        private timezoneService: TimezoneService,
        private authService: AuthService,
        private router: Router,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private invoiceService: InvoiceService,
        private productService: ProductService,
        private snackRouteService: SnackRouteService,
    ) { }

    ngOnInit() {
    }

    back() {
        //this.userService.navigateToInitialPage();
        this.router.navigate(["/products"])
    }

    cancel() {
        // this.setUnedit();
        // this.ngOnInit();

        this.router.navigate(["/products"])
    }

    isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n)
    }

    formErrors = {}
    anyError = false
    isValid() {
        this.anyError = false

        if (!this.formControls['price'].value) {
            this.formErrors['price'] = "Please specify a price."
            this.anyError = true
        } else if (!this.isNumber(this.formControls['price'].value)) {
            this.formErrors['price'] = "Please specify a valid number."
            this.anyError = true
        } else {
            delete this.formErrors['price']
        }
        return !this.anyError
    }

    save() {
        //console.log("save isnew", this.isNew)
        if (!this.isValid()) {
            return
        }

        if (this.isNewOverride) {
            var providerProductObject = {
                id: uuid(),
                service_id: this.productId,
                provider_id: this.authService.companyId,
                price_1: this.formControls['price'].value,
                active: this.formControls['active'].value,
            }
            var sub = this.crudService.create(
                "data_service_price",
                providerProductObject).subscribe((response) => {
                    //console.log(response)
                    sub.unsubscribe()
                    this.snackRouteService.snack("Product Updated")
                    this.router.navigate(["/products"])
                })
        } else {
            var providerProductUpdateObject = {
                id: this.providerOverrideId,
                service_id: this.productId,
                provider_id: this.authService.companyId,
                price_1: this.formControls['price'].value,
                active: this.formControls['active'].value,
            }
            var sub = this.crudService.update(
                "data_service_price",
                providerProductUpdateObject).subscribe((response) => {
                    //console.log(response)
                    sub.unsubscribe()
                    this.snackRouteService.snack("Product Updated")
                    this.router.navigate(["/products"])
                })
        }
    }

    delete(companyData) {
        // console.log(companyData)
        // if (companyData.result && companyData.result.length && companyData.result[0].data_users && companyData.result[0].data_users.length) {
        //     this._snackBar.open("Cannot delete company with users", null, {
        //         duration: 4000,
        //     });
        //     return
        // }

        // const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
        //     panelClass: 'delete-confirmation-dialog-container',
        //     // data: {
        //     //     orderId: this.listItems[listViewIndex][j].order_id,
        //     //     orderAddress: this.listItems[listViewIndex][j].address,
        //     //     newStatus: this.orderStatusById[status].name,
        //     // }
        // });

        // dialogRef.afterClosed().subscribe(result => {
        //     console.log("detle resu", result)
        //     if (result) {
        //         var companyObject = {
        //             id: this.productId,
        //         }
        //         this.crudService.delete(
        //             "data_company",
        //             companyObject).subscribe((response) => {
        //                 //console.log(response)
        //                 this.router.navigate(["/products"])
        //             })
        //     } else {
        //         this._snackBar.open("Action cancelled", null, {
        //             duration: 4000,
        //         });
        //     }
        // });
    }
}
