import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ThemeService } from '../theme.service';
import { JurisdictionService } from '../jurisdiction.service';
import { AddressService } from '../address.service';
import { TitleCasePipe } from '@angular/common';
import { OrderService } from '../order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../company.service';
import { UserService } from '../user.service';
import { faPlus, faBan } from '@fortawesome/free-solid-svg-icons';
import { ClientService } from '../client.service';
import { BehaviorSubject, of } from 'rxjs';
import { InputSearchComponent } from '../input-search/input-search.component';
import { ThemeProviderOrderCreateService } from '../theme-provider-order-create.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountService } from '../account.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { ChargebeeService } from '../chargebee.service';

@Component({
    selector: 'app-order-create',
    templateUrl: './order-create.component.html',
})
export class OrderCreateComponent implements OnInit {
    faPlus = faPlus
    faBan = faBan

    clientNameFC = new UntypedFormControl()
    companyNameFC = new UntypedFormControl()
    clientEmailFC = new UntypedFormControl()
    @ViewChildren(InputSearchComponent) inputSearchComponents: QueryList<InputSearchComponent>;
    initialized = false;

    orderTypeFC = new UntypedFormControl();
    stateFC = new UntypedFormControl("New York");
    stateId = 36
    stateAbbreviation = "NY"
    countyFC = new UntypedFormControl();
    addressFC = new UntypedFormControl();
    sblFC = new UntypedFormControl();
    sellerFC = { fcs: [] };
    buyerFC = { fcs: [] };
    assessedOwnerFC = { fcs: [] };

    providerFC = new UntypedFormControl();
    referenceNumberFC = new UntypedFormControl();
    fileNumberFC = new UntypedFormControl();
    dueDateFC = new UntypedFormControl()
    targetDateOfClosingFC = new UntypedFormControl();
    internalNotesFC = new UntypedFormControl();
    yearBuiltFC = new UntypedFormControl();
    landUseFC = new UntypedFormControl();
    municipalityFC = new UntypedFormControl();
    swisCodeFC = new UntypedFormControl();
    schoolDistrictFC = new UntypedFormControl();
    schoolDistrictSwisFC = new UntypedFormControl();

    searchTypeFC = new UntypedFormControl();
    searchSubTypeFC = new UntypedFormControl();
    continuationDateFC = new UntypedFormControl();
    continuationDateDisplayFC = new UntypedFormControl();
    propertyTypeFC = new UntypedFormControl("Residential");

    itfTaxSearch = new UntypedFormControl(false)
    itfTaxSearchCont = new UntypedFormControl(false)
    itfJudgeLien = new UntypedFormControl(false)
    itfCertOcc = new UntypedFormControl(false)
    itfHouseBuild = new UntypedFormControl(false)
    itfStreetRep = new UntypedFormControl(false)
    itfCourtesyDeed = new UntypedFormControl(false)

    deedBookFC = new UntypedFormControl()
    deedPageFC = new UntypedFormControl()

    cacheOrder;
    propertySelected = false;
    countySelected = false;
    providers;

    orderTypeEnumValues = [
        "Quick",
        "Property",
        "Entity",
        "Individual",
    ];
    searchTypeData = [];
    propertyTypeEnumValues = [
        "Residential",
        "Commercial",
    ];

    states$ = this._jurisdictionService.getStates().pipe(map((response) => {
        return response.result
    }))

    searchStates(term, states) {
        if (!states) {
            return of([])
        }
        var matches = []
        for (var i = 0; i < states.length; ++i) {
            if (states[i].name.toLowerCase().startsWith(term)) {
                matches.push(states[i])
            }
        }
        return of(matches)
    }


    stateSelector$ = new BehaviorSubject(36)

    stateSelect(item) {
        this.stateSelector$.next(item.id)
        this.stateId = item.id
        this.stateAbbreviation = item.abbreviation
    }

    counties$ = this.stateSelector$.pipe(
        switchMap((stateId) => {
            return this._jurisdictionService.getCounties(stateId)
        }),
        map((response) => {
            return response.result
        })
    )

    searchCounties(term, counties) {
        if (!counties) {
            return of([])
        }
        var matches = []
        for (var i = 0; i < counties.length; ++i) {
            if (counties[i].name.toLowerCase().startsWith(term)) {
                matches.push(counties[i])
            }
        }
        return of(matches)
    }

    constructor(
        private _themeService: ThemeService,
        private _themeRebrandService: ThemeProviderOrderCreateService,
        private _jurisdictionService: JurisdictionService,
        private _addressService: AddressService,
        private titleCase: TitleCasePipe,
        private _orderService: OrderService,
        private activatedRoute: ActivatedRoute,
        private _companyService: CompanyService,
        private _userService: UserService,
        private clientService: ClientService,
        private _snackBar: MatSnackBar,
        private accountService: AccountService,
        private router: Router,
        private authService: AuthService,
        private chargebeeService: ChargebeeService
    ) {
    }

    get userService() {
        return this._userService
    }

    uniqueClientCompanies = []
    clients
    newClientError = null
    sblOrderInProgress = false

    getClientsPromise() {
        return new Promise((resolve, reject) => {
            this.clientService.getClients().subscribe((response) => {
                if (response.success) {
                    this.clients = response.result
                    var lastClientCompanyId = null
                    for (var i = 0; i < this.clients.length; ++i) {
                        if (lastClientCompanyId == null || lastClientCompanyId != this.clients[i].id) {
                            this.uniqueClientCompanies.push(this.clients[i])
                            lastClientCompanyId = this.clients[i].id
                        }
                    }
                    //console.log("debug clients", this.clients)
                    resolve(null)
                } else {
                    console.log("error while getting clients", response)
                    reject(response)
                }
            })
        })
    }

    doesClientNameMatchExisting() {
        var term = this.clientNameFC.value
        if (term) {
            term = term.trim()
        }

        if(this.clients.length == 0) {
            if(term == this.clientNameFC.value) {
                return true
            }
        }

        for (var i = 0; i < this.clients.length; ++i) {
            if (this.cacheOrder.agent_id && this.cacheOrder.agent_id != this.clients[i].id) {
                continue
            }
            if (!this.clients[i].full_name) {
                continue
            }
            if (!this.clients[i].full_name.toLowerCase() == term) {
                continue
            }
            if (this.clients[i].user_uuid != this.cacheOrder.user_id) {
                continue
            }
            return true
        }
        return false
    }

    doesClientEmailMatchExisting() {
        var term = this.clientEmailFC.value
        if (term) {
            term = term.trim().toLowerCase()
        }

        if(this.clients.length == 0) {
            if(term == this.clientEmailFC.value) {
                return true
            }
        }
        //console.log("checking client email match", term, this.cacheOrder.agent_id, this.clients, this.cacheOrder.user_id)
        for (var i = 0; i < this.clients.length; ++i) {
            if (this.cacheOrder.agent_id != this.clients[i].id) {
                //console.log("no match, wrong client company id", this.cacheOrder.agent_id, this.clients[i].id)
                continue
            }

            if (!this.clients[i].email_address) {
                //console.log("no match, no client email exists")
                continue
            }
            if (this.clients[i].email_address.toLowerCase() != term) {
                //console.log("no match, email does not match", this.clients[i].email_address.toLowerCase(), term)
                continue
            }
            if (this.clients[i].user_uuid != this.cacheOrder.user_id) {
                //console.log("no match, user uuid does not match", this.clients[i].user_uuid, this.cacheOrder.user_id)
                continue
            }
            //console.log("match found")
            return true
        }
        return false
    }

    doesCompanyNameMatchExisting() {
        var term = this.companyNameFC.value
        if(this.clients.length == 0) {
            if(term == this.cacheOrder.agent_company_name) {
                return true
            }
        }
        for (var i = 0; i < this.clients.length; ++i) {
            if (this.clients[i].name == term && this.clients[i].id == this.cacheOrder.agent_id) {
                return true
            }
        }
        return false
    }

    setClientCompany(uuid, name) {
        this.cacheOrder.agent_id = uuid
        this.cacheOrder.agent_company_name = name

        //console.log("input components", this.inputSearchComponents)

        this.inputSearchComponents.forEach(inputSearchComponent => {
            if (inputSearchComponent.fc === this.clientNameFC) {
                inputSearchComponent.nextSearchTerm(this.clientNameFC.value)
            }
            if (inputSearchComponent.fc === this.clientEmailFC) {
                inputSearchComponent.nextSearchTerm(this.clientEmailFC.value)
            }
        });

        this.saveOrderCache();
    }

    searchClientCompany(term) {
        if (!this.clients) {
            return of([]);
        }
        var results = [];
        for (var i = 0; i < this.uniqueClientCompanies.length; ++i) {
            if (this.uniqueClientCompanies[i].name.toLowerCase().includes(term.trim().toLowerCase())) {
                results.push(this.uniqueClientCompanies[i]);
            }
        }
        return of(results);
       
    }
    clientCompanyChange() {
    }
    clientCompanySelect(item) {
        this.setClientCompany(item.id, item.name)
    }
    getClientCompanyDisplay(item) {
        return item.name
    }

    searchClientUserFullName(term) {
        if (!this.clients) {
            return of([]);
        }
        var results = [];

        //console.log("user full name search, selected client", this.cacheOrder.agent_id)

        for (var i = 0; i < this.clients.length; ++i) {
            if (this.cacheOrder.agent_id != this.clients[i].id) {
                continue
            }
            if (this.clients[i].full_name && (term == null || this.clients[i].full_name.toLowerCase().startsWith(term.trim().toLowerCase()))) {
                results.push(this.clients[i]);
            }
        }
        return of(results);
    }
    clientUserFullNameChange(item) {
    }
    clientUserFullNameSelect(item) {
        this.newClientError = null
        //console.log("full name select item", item)
        this.cacheOrder.user_id = item.user_uuid
        this.clientEmailFC.setValue(item.email_address)
        this.saveOrderCache();
    }
    getClientUserFullNameDisplay(item) {
        return item.full_name
    }

    searchClientUserEmail(term) {
        if (!this.clients) {
            return of([]);
        }
        var results = [];
        for (var i = 0; i < this.clients.length; ++i) {
            if (this.cacheOrder.agent_id != this.clients[i].id) {
                continue
            }
            if (this.clients[i].email_address && (term == null || this.clients[i].email_address.toLowerCase().startsWith(term.trim().toLowerCase()))) {
                results.push(this.clients[i]);
            }
        }
        return of(results);
    }
    clientUserEmailChange() {
    }
    clientUserEmailSelect(item) {
        this.newClientError = null
        this.cacheOrder.user_id = item.user_uuid
        this.clientNameFC.setValue(item.full_name)
        this.saveOrderCache();
    }
    getClientUserEmailDisplay(item) {
        return item.email_address
    }

    filter = {
        gate: "all",
        comparisons: [
            {
                field: "uuid",
                operator: "is",
                matchValue: null,
            },
        ],
    }

    orderUuid
    getOrderIdParamPromise() {
        return new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe(params => {
                this.orderUuid = params.orderUuid
                if (this.orderUuid) {
                    this.filter.comparisons[0].matchValue = this.orderUuid
                    this.orderService.search2({
                        filter: this.filter,
                    }, "dashLimit").subscribe(function (response) {
                        if (response.success) {
                            if (params.copy) {
                                delete response.result[0].uuid
                                delete response.result[0].service_group_id
                                delete response.result[0].service_name
                                delete response.result[0].target_close_date
                                delete response.result[0].target_close_time
                                delete response.result[0].continuation_date
                                delete response.result[0].due_date
                                delete response.result[0].reference_number
                                delete response.result[0].file_number
                            }
                            localStorage.setItem("cached_order_draft", JSON.stringify(response.result[0]));
                        }
                    })
                }
                resolve(this.orderUuid)
            })
        })
    }

    is_agent: boolean
    ngOnInit() {
        this.is_agent = false
        this.chargebeeService.getPlan().subscribe((response) => {
            if(response.plan.includes('CLIENT')) {
                this.is_agent = true
            } else {
                this.is_agent = false
            }
        })

        this.getOrderIdParamPromise().then(() => {
            return this.getClientsPromise()
        }).then(() => {

            this._jurisdictionService.getJurisdictionData().subscribe((response) => {
                //console.log("j data", response)
                this._jurisdictionService.setJurisdictionData(response);

                this._orderService.getPrimarySearchTypeData((searchTypeData) => {
                    this.searchTypeData = searchTypeData;
                    this.loadOrderCache();

                    //re-adorn documents, as they are not set when accessing draft order via provider portal
                    var adornDocumentsPromise = Promise.resolve()
                    if (this.cacheOrder.uuid) {
                        adornDocumentsPromise = new Promise((resolve, reject) => {
                            this._orderService.adornDocuments(this.cacheOrder.uuid).subscribe((response) => {
                                //console.log("adorn docs response", response)
                                if (response.success) {
                                    this.cacheOrder.attached_documents = response.order.attached_documents
                                }
                                resolve()
                            })
                        })
                    }

                    adornDocumentsPromise.then(() => {
                        this.activatedRoute.queryParams.subscribe(params => {
                            if (params['orderType']) {
                                this.cacheOrder.order_type = params['orderType'];
                                this.orderTypeFC = new UntypedFormControl(params['orderType']);
                            }

                            this._companyService.getProviders().subscribe((response) => {
                                this.providers = response.result;
                                if (!this.cacheOrder.provider_id) {
                                    this._companyService.getDefaultProvider().subscribe((response) => {
                                        if (response.result.length != 1) {
                                            console.log("non-singular result when fetching default provider");
                                        } else {
                                            this.cacheOrder.provider_id = response.result[0].default_provider;
                                            this.providerFC = new UntypedFormControl(this.cacheOrder.provider_id);
                                            this.saveOrderCache();
                                            this.initialized = true;
                                        }
                                    })
                                } else {
                                    this.saveOrderCache();
                                    this.initialized = true;
                                }
                                this.checkSBLOrderExists()
                            });
                        });
                    })
                });
            });
        }).catch((err) => {
            console.log("error while getting clients", err)
        })
    }

    get orderService() {
        return this._orderService;
    }

    loadOrderCache() {
        //localStorage.setItem("cached_order_draft", "")
        var dataItem = localStorage.getItem("cached_order_draft");
        if (!dataItem) {
            this.cacheOrder = {};
        } else {
            if (!this.cacheOrder) {
                this.cacheOrder = {};
            }

            this.cacheOrder = JSON.parse(dataItem);

            console.log("loading cache order", this.cacheOrder);
            if (this.cacheOrder.order_type) {
                this.orderTypeFC = new UntypedFormControl(this.cacheOrder.order_type);
            }
            if (this.cacheOrder.state) {
                this.stateFC = new UntypedFormControl(this.cacheOrder.state);
            }
            if (this.cacheOrder.stateId) {
                this.stateId = this.cacheOrder.stateId
            }
            if (this.cacheOrder.stateAbbreviation) {
                this.stateAbbreviation = this.cacheOrder.stateAbbreviation
            }
            if (this.cacheOrder.name) {
                this.countyFC = new UntypedFormControl(this.cacheOrder.name);
                this.countySelected = true;
            } else if (this.cacheOrder.county_name) {
                this.countyFC.setValue(this.cacheOrder.county_name)
                this.countySelected = true;
            }
            if (this.cacheOrder.address) {
                this.addressFC = new UntypedFormControl(this.cacheOrder.address);
                this.propertySelected = true;
            }
            if (this.cacheOrder.sbl) {
                this.sblFC = new UntypedFormControl(this.cacheOrder.sbl);
                this.propertySelected = true;
            }
            if (this.cacheOrder.property_seller) {
                for (var i = 0; i < this.cacheOrder.property_seller.length; ++i) {
                    this.sellerFC.fcs.push(new UntypedFormControl(this.cacheOrder.property_seller[i]));
                }
            }
            if (this.cacheOrder.property_buyer) {
                for (var i = 0; i < this.cacheOrder.property_buyer.length; ++i) {
                    this.buyerFC.fcs.push(new UntypedFormControl(this.cacheOrder.property_buyer[i]));
                }
            }
            if (this.cacheOrder.assessed_owner) {
                for (var i = 0; i < this.cacheOrder.assessed_owner.length; ++i) {
                    this.assessedOwnerFC.fcs.push(new UntypedFormControl(this.cacheOrder.assessed_owner[i]));
                }
            }
            if (this.cacheOrder.provider_id) {
                this.providerFC = new UntypedFormControl(this.cacheOrder.provider_id);
            }
            if (this.cacheOrder.reference_number) {
                this.referenceNumberFC = new UntypedFormControl(this.cacheOrder.reference_number);
            }
            if (this.cacheOrder.file_number) {
                this.fileNumberFC = new UntypedFormControl(this.cacheOrder.file_number);
            }
            if (this.cacheOrder.target_close_date) {
                this.targetDateOfClosingFC = new UntypedFormControl(this.cacheOrder.target_close_date);
            }
            if (this.cacheOrder.internal_notes) {
                this.internalNotesFC = new UntypedFormControl(this.cacheOrder.internal_notes);
            }
            if (this.cacheOrder.year_built) {
                this.yearBuiltFC = new UntypedFormControl(this.cacheOrder.year_built);
            }
            if (this.cacheOrder.municipality) {
                this.municipalityFC = new UntypedFormControl(this.cacheOrder.municipality);
            }
            if (this.cacheOrder.real_property_code) {
                this.swisCodeFC = new UntypedFormControl(this.cacheOrder.real_property_code);
            }
            if (this.cacheOrder.school_district_name) {
                this.schoolDistrictFC = new UntypedFormControl(this.cacheOrder.school_district_name);
            }
            if (this.cacheOrder.school_district_swis) {
                this.schoolDistrictSwisFC = new UntypedFormControl(this.cacheOrder.school_district_swis);
                this.getSchoolDistrictName();
            }
            if (this.cacheOrder.service_group_id) {
                for (var i = 0; i < this.searchTypeData.length; ++i) {
                    if (this.searchTypeData[i].id == this.cacheOrder.service_group_id) {
                        this.searchTypeFC = new UntypedFormControl(this.searchTypeData[i].id);
                        break;
                    }
                }
            }
            if (this.cacheOrder.primary_service_id) {
                //console.log(this.cacheOrder.primary_service_id)
                var subtypeFound = false;
                for (var i = 0; i < this.searchTypeData.length; ++i) {
                    //console.log(this.searchTypeData[i].services)
                    if (this.searchTypeData[i].services && this.searchTypeFC.value == this.searchTypeData[i].id) {
                        for (var j = 0; j < this.searchTypeData[i].services.length; ++j) {
                            if (this.searchTypeData[i].services[j].id == this.cacheOrder.primary_service_id) {
                                this.searchSubTypeFC = new UntypedFormControl(this.searchTypeData[i].services[j].id);
                                subtypeFound = true;
                                break;
                            }
                        }
                        if (subtypeFound) {
                            break;
                        }
                    }
                }
            }
            if (this.cacheOrder.continuation_date) {
                this.continuationDateFC = new UntypedFormControl(this.cacheOrder.continuation_date);
            }
            if (this.cacheOrder.property_type) {
                this.propertyTypeFC = new UntypedFormControl(this.cacheOrder.property_type);
            }
            if (this.cacheOrder.land_use) {
                this.landUseFC = new UntypedFormControl(this.cacheOrder.land_use);
            }

            if (this.cacheOrder.deed_book) {
                this.deedBookFC = new UntypedFormControl(this.cacheOrder.deed_book)
            }
            if (this.cacheOrder.deed_page) {
                this.deedBookFC = new UntypedFormControl(this.cacheOrder.deed_page)
            }

            if (this.cacheOrder.agent_id) {
                this.companyNameFC.setValue(this.cacheOrder.agent_company_name)
            }
            if (this.cacheOrder.user_id) {
                this.clientNameFC.setValue(this.cacheOrder.user_name)
                this.clientEmailFC.setValue(this.cacheOrder.user_email)
            }
            this.dueDateFC.setValue(this.cacheOrder.due_date)

            if(this.cacheOrder.itf_tax_search) {
                this.itfTaxSearch.setValue(this.cacheOrder.itf_tax_search)
            }

            if(this.cacheOrder.itf_tax_search_cont) {
                this.itfTaxSearchCont.setValue(this.cacheOrder.itf_tax_search_cont)
            }
            if(this.cacheOrder.itf_cert_occ) {
                this.itfCertOcc.setValue(this.cacheOrder.itf_cert_occ)
            }

            if(this.cacheOrder.itf_house_build) {
                this.itfHouseBuild.setValue(this.cacheOrder.itf_house_build)
            }

            if(this.cacheOrder.itf_street_rep) {
                this.itfStreetRep.setValue(this.cacheOrder.itf_street_rep)
            }

            if(this.cacheOrder.itf_courtesy_deed) {
                this.itfCourtesyDeed.setValue(this.cacheOrder.itf_courtesy_deed)
            }

            if(this.cacheOrder.individualServices) {
                if(Object.keys(this.cacheOrder.individualServices).length >= 1) {
                    //console.log(this.cacheOrder.individualServices)
                    this.itfJudgeLien.setValue(true)
                }
            }

        }
        
        this.cacheOrder.api_source_id = "PROVIDER_PORTAL"
    }

    doNextButton() {
        this.saveOrderCache();
    }

    updateContinuationDisabled() {
        if (this.searchSubTypeFC.value == 'CONTINUATION_TYPED'
            || this.searchSubTypeFC.value == 'CONTINUATION') {
            this.continuationDateFC.enable()
        } else {
            this.continuationDateFC.disable()
        }
    }


    orderTypeChange() {
        this.router.navigate(['order-create'], { queryParams: { orderType: this.orderTypeFC.value }}).then(() => {
            window.location.reload()
        })
    }

    saveOrderCache() {
        this.updateContinuationDisabled()
 
        this.cacheOrder.order_type = this.orderTypeFC.value;
        this.cacheOrder.state = this.stateFC.value;
        this.cacheOrder.stateId = this.stateId
        this.cacheOrder.stateAbbreviation = this.stateAbbreviation
        this.cacheOrder.name = this.countyFC.value;
        this.cacheOrder.address = this.addressFC.value;
        this.cacheOrder.sbl = this.sblFC.value;
        this.cacheOrder.property_seller = [];
        for (var i = 0; i < this.sellerFC.fcs.length; ++i) {
            this.cacheOrder.property_seller.push(this.sellerFC.fcs[i].value);
        }
        this.cacheOrder.property_buyer = [];
        for (var i = 0; i < this.buyerFC.fcs.length; ++i) {
            this.cacheOrder.property_buyer.push(this.buyerFC.fcs[i].value);
        }
        this.cacheOrder.assessed_owner = [];
        for (var i = 0; i < this.assessedOwnerFC.fcs.length; ++i) {
            this.cacheOrder.assessed_owner.push(this.assessedOwnerFC.fcs[i].value);
        }
        this.cacheOrder.provider_id = this.providerFC.value;
        this.cacheOrder.reference_number = this.referenceNumberFC.value;
        this.cacheOrder.file_number = this.fileNumberFC.value;
        this.cacheOrder.target_close_date = this.targetDateOfClosingFC.value;
        this.cacheOrder.due_date = this.dueDateFC.value
        this.cacheOrder.internal_notes = this.internalNotesFC.value;
        this.cacheOrder.year_built = this.yearBuiltFC.value;
        this.cacheOrder.municipality = this.municipalityFC.value;
        this.cacheOrder.real_property_code = this.swisCodeFC.value;
        this.cacheOrder.school_district_name = this.schoolDistrictFC.value;
        this.cacheOrder.school_district_swis = this.schoolDistrictSwisFC.value;
        this.cacheOrder.service_group_id = this.searchTypeFC.value;
        this.cacheOrder.primary_service_id = this.searchSubTypeFC.value
        this.cacheOrder.itf_tax_search = false
        this.cacheOrder.itf_tax_search_cont = false
        this.cacheOrder.itf_cert_occ = false
        this.cacheOrder.itf_house_build = false
        this.cacheOrder.itf_street_rep = false
        this.cacheOrder.itf_courtesy_deed = false
        this.cacheOrder.propertyServices = {}
        this.cacheOrder.individualServices = {}


        if(this.itfTaxSearch.value === true) {
            this.cacheOrder.itf_tax_search = true
            this.cacheOrder.propertyServices['TAX'] = this.cacheOrder.itf_tax_search
        }

        if(this.itfTaxSearchCont.value === true) {
            this.cacheOrder.itf_tax_search_cont = true
            this.cacheOrder.propertyServices['TAX_CONTIN'] = this.cacheOrder.itf_tax_search_cont
        }

        if(this.itfCertOcc.value === true) {
            this.cacheOrder.itf_cert_occ = true
            this.cacheOrder.propertyServices['OCCUPANCY'] = this.cacheOrder.itf_cert_occ
        }

        if(this.itfHouseBuild.value === true) {
            this.cacheOrder.itf_house_build = true
            this.cacheOrder.propertyServices['HOUSING'] = this.cacheOrder.itf_house_build
        }

        if(this.itfStreetRep.value === true) {
            this.cacheOrder.itf_street_rep = true
            this.cacheOrder.propertyServices['STREET'] = this.cacheOrder.itf_street_rep
        }

        if(this.itfCourtesyDeed.value === true) {
            this.cacheOrder.itf_courtesy_deed = true
            this.cacheOrder.propertyServices['COURTESY_DEED'] = this.cacheOrder.itf_courtesy_deed
        }

        if(this.itfJudgeLien.value === true) {
            if(this.sellerFC.fcs.length >= 1) {
                Object.keys(this.sellerFC.fcs).forEach(key => {
                    if(this.sellerFC.fcs[key].value != null) {
                        if(!this.cacheOrder.individualServices[this.sellerFC.fcs[key].value]) {
                            this.cacheOrder.individualServices[this.sellerFC.fcs[key].value] = {}
                        }
                
                        if(this.sellerFC.fcs[key].value === "") {
                            this.cacheOrder.individualServices[this.sellerFC.fcs[key].value] = {}
                        } else {
                            this.cacheOrder.individualServices[this.sellerFC.fcs[key].value]['LIEN'] = true
                        }
                    }
                    
                })
            }
            if(this.buyerFC.fcs.length >= 1) {
                Object.keys(this.buyerFC.fcs).forEach(key => {
                    //console.log(this.buyerFC.fcs[key].value)
                    if(this.buyerFC.fcs[key].value != null) {
                        if(!this.cacheOrder.individualServices[this.buyerFC.fcs[key].value]) {
                            this.cacheOrder.individualServices[this.buyerFC.fcs[key].value] = {}
                        }
                        if(this.buyerFC.fcs[key].value === "") {
                            this.cacheOrder.individualServices[this.buyerFC.fcs[key].value] = {}
                        } else {
                            this.cacheOrder.individualServices[this.buyerFC.fcs[key].value]['LIEN'] = true
                        }
                    }
                })
            }
            //console.log(this.cacheOrder.individualServices)
        } else {
            this.cacheOrder.individualServices = {}
        }


        //this.cacheOrder.primary_service_id = this.searchSubTypeFC.value;
        this.cacheOrder.continuation_date = this.continuationDateFC.value;

        this.cacheOrder.property_type = this.propertyTypeFC.value;

        this.cacheOrder.land_use = this.landUseFC.value;

        this.cacheOrder.deed_book = this.deedBookFC.value
        this.cacheOrder.deed_page = this.deedPageFC.value

        this.cacheOrder.provider_created = true

        if(this.is_agent) {
            this._companyService.getCompany().subscribe((response) => {
                this.cacheOrder.agent_id = response.result[0].id
                this.cacheOrder.agent_company_name = response.result[0].name
                this.companyNameFC = new UntypedFormControl(this.cacheOrder.agent_company_name)
            })

            this.userService.getUser(this.authService.userId).subscribe((response) => {
                this.cacheOrder.user_name = response.result[0].full_name
                this.cacheOrder.user_id = this.authService.userId
                this.clientNameFC = new UntypedFormControl(this.cacheOrder.user_name)
            })
            this.cacheOrder.user_email = this.authService.username
            this.clientEmailFC = new UntypedFormControl(this.cacheOrder.user_email)
        } else {
            this.cacheOrder.agent_company_name = this.companyNameFC.value
            this.cacheOrder.user_name = this.clientNameFC.value
            this.cacheOrder.user_email = this.clientEmailFC.value
        }


        if(this.cacheOrder.agent_company_name == 'Dunder Mifflin (KCS test)') {
            this.cacheOrder.agent_company_name = null
            this.companyNameFC.setValue(this.cacheOrder.agent_company_name)
        }
        //console.log("saving cache order", this.cacheOrder);
        localStorage.setItem("cached_order_draft", JSON.stringify(this.cacheOrder));

    }

    get themeService() {
        return this._themeService;
    }

    get jurisdictionService() {
        return this._jurisdictionService;
    }

    get addressService() {
        return this._addressService;
    }

    hasTaxMapLink() {
        return false;
    }

    getTaxMapLink() {
        return null;
    }

    searchAddress(term) {
        return this._addressService.searchAddress(this._jurisdictionService.getCountySwisByName(this.countyFC.value), term).pipe(tap((data) => {
            console.log("address search data", data)
        }));
    }

    getAddressItemDisplay(item) {
        if (item.unselectable) {
            return item.name;
        }
        return this.titleCase.transform(item.property_full_address + ", " + item.property_town_municipality);
    }

    addressItemSelect(item) {
        this.sblFC.setValue(item.print_key);
        this.propertyItemSelect(item);
    }

    searchSBL(term) {
        return this._addressService.searchSBL(this._jurisdictionService.getCountySwisByName(this.countyFC.value), term);
    }

    sblItemSelect(item) {
        this.addressFC.setValue(this.getAddressItemDisplay(item));
        this.propertyItemSelect(item);
    }

    getSBLItemDisplay(item) {
        if (item.unselectable) {
            return item.name;
        }
        return item.print_key;
    }

    countyItemSelect(item) {
        this.cacheOrder.county = item.jurisdiction_id;
        if (!this.cacheOrder.county) {
            this.cacheOrder.county = item.name;
        }
        this.countySelected = true;

        this.addressFC.setValue(null);
        this.sblFC.setValue(null);
        this.sellerFC.fcs = [new UntypedFormControl()];
        this.yearBuiltFC.setValue(null);
        this.municipalityFC.setValue(null);
        this.swisCodeFC.setValue(null);
        this.schoolDistrictFC.setValue(null);
        this.saveOrderCache();
    }

    getSchoolDistrictName() {
        this._jurisdictionService.getSchoolDistrictName(this.schoolDistrictSwisFC.value).subscribe((response) => {
            if (response.success) {
                this.cacheOrder.school_district_name = response.result;
                this.schoolDistrictFC.setValue(response.result);
            }
        })
    }

    checkSBLOrderExists() {
        this.orderService.getOrderForSBLExists(this.cacheOrder.county, this.sblFC.value).subscribe((response) => {
            if (response.success && response.result.length > 0 && response.result[0].count > 0) {
                this.sblOrderInProgress = true
            } else {
                this.sblOrderInProgress = false
            }
        })
    }

    propertyItemSelect(item) {
        //console.log(item);
        this.cacheOrder.tax_map_data_id = item.id;
        this.sellerFC.fcs = [new UntypedFormControl(item.owner_name_1)];
        if (item.owner_name_2) {
            this.sellerFC.fcs.push(new UntypedFormControl(item.owner_name_2));
        }

        this.yearBuiltFC.setValue(item.year_built);
        this.landUseFC.setValue(this.titleCase.transform(item.land_use_long_description));
        this.municipalityFC.setValue(this.titleCase.transform(item.property_town_municipality));
        this.swisCodeFC.setValue(item.county_number_code);
        this.deedBookFC.setValue(item.deed_book)
        this.deedPageFC.setValue(item.deed_page)
        this.schoolDistrictSwisFC.setValue(item.school_district_code);
        this.getSchoolDistrictName();

        this.cacheOrder.use_code = item.use_code
        this.cacheOrder.land_assed_value = item.land_assed_value
        this.cacheOrder.account_number = item.account_number
        this.cacheOrder.assessed_value = item.assessed_value
        this.cacheOrder.lot_size = item.lot_size
        this.cacheOrder.acreage = item.lot_size_numeric

        this.cacheOrder.tax_map_county_code = item.county_code
        this.cacheOrder.tax_map_town_code = item.town_code
        this.cacheOrder.tax_map_tax_id = item.tax_id

        this.saveOrderCache();
        localStorage.setItem("order_create_selected_item", JSON.stringify(item));
        this.propertySelected = true;
        this.checkSBLOrderExists()
    }

    selectPrimaryService(searchType) {
        this.searchTypeFC.setValue(searchType.id);
        if (searchType.id == "TAX") {
            this.searchSubTypeFC.setValue(searchType.id);
            setTimeout(() => {
                this.searchSubTypeFC.setValue(searchType.id);
            }, 0)
        } else {
            this.searchSubTypeFC.setValue(null);
        }
        this.saveOrderCache();
    }

    nextDisabler() {
        return !this.searchTypeFC.value
    }

    createCompanyPromise() {
        return new Promise((resolve, reject) => {
            this.clientService.newCompany(this.companyNameFC.value).subscribe((response) => {
                //console.log("company create response", response)
                if (!response.success) {
                    reject(response)
                    console.log("error occurred during company create", response)
                    return this.newClientError = "Error while creating company. " + response.message
                }
                this.cacheOrder.agent_id = response.uuid
                resolve(null)
            })
        })
    }

    createUserPromise() {
        return new Promise((resolve, reject) => {
            this.clientService.newUser(this.cacheOrder.agent_id, this.clientNameFC.value, this.clientEmailFC.value).subscribe((response) => {
                //console.log("client create response", response)
                if (!response.success) {
                    reject()
                    console.log("error occurred during user create", response)
                    return this.newClientError = "Error while creating user. " + response.message
                }
                this.cacheOrder.user_id = response.id
                resolve(null)
            })
        })
    }

    addClientCompany() {
        if (!this.companyNameFC.value) {
            return this.newClientError = "Please enter a company name for the new client."
        }

        if (!this.clientNameFC.value) {
            return this.newClientError = "Please enter a name for the new client."
        }

        if (!this.clientEmailFC.value) {
            return this.newClientError = "Please enter an email address for the new client."
        }

        if (this.doesClientEmailMatchExisting()) {
            return this.newClientError = "Email address already exists."
        }
        this.accountService.getCanCreateClientUser(this.clientEmailFC.value, this.cacheOrder.agent_id).subscribe((response) => {
            if (!response.success) {
                return this.newClientError = "Error occurred while checking email address"
            }
            if (!response.canCreate) {
                return this.newClientError = "Please specify a different email address."
            }
            this.newClientError = null

            Promise.resolve().then(() => {
                if (!this.doesCompanyNameMatchExisting()) {
                    return this.createCompanyPromise()
                }
            }).then(() => {
                return this.createUserPromise()
            }).then(() => {
                this.saveOrderCache()
                return this.getClientsPromise()
            }).then(() => {
                this._snackBar.open("Client successfully added.", null, {
                    duration: 4000,
                });
            }).catch((err) => {
                console.log("error while adding client", err)
                this._snackBar.open("Error occurred while adding client.", null, {
                    duration: 4000,
                });
            })
        })
    }

    validate() {
        if (!this.searchTypeFC.value) {
            return "Please select a search type."
        }

        //further validation checks only apply to provider portal

        if (!this.cacheOrder.agent_id) {
            return "Please select a client company."
        }
        if (!this.cacheOrder.user_id) {
            return "Please select a client user."
        }
        var existingCompanyByName = this.doesCompanyNameMatchExisting()
        if (!existingCompanyByName) {
            return "The client company name has been changed. Please either save the current values as a new client or reselect an existing client."
        }

        if (!this.doesClientEmailMatchExisting()) {
            return "The client contact email has been changed. Please either save the current values as a new client or reselect an existing client."
        }

        if (!this.doesClientNameMatchExisting()) {
            return "The client contact name has been changed. Please either save the current values as a new client or reselect an existing client."
        }
    }

}
