import { Component, OnInit } from '@angular/core';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-input-five-star-rating',
    templateUrl: './input-five-star-rating.component.html',
})
export class InputFiveStarRatingComponent implements OnInit {
    farStar = farStar
    fasStar = fasStar

    rating = 0
    mouseRating = null

    constructor() { }

    ngOnInit() {
    }

    starMouseEnter(i) {
        this.mouseRating = i
    }

    starMouseLeave(i) {
        this.mouseRating = null
    }

    starClick(i) {
        this.rating = i
    }

}
