import { Injectable } from '@angular/core';
import { BannerNotificationType } from './banner-notification-type';

@Injectable({
    providedIn: 'root'
})
export class BannerNotificationService {

    private _notifications = [
        // { order_id: "1234", uuid: "1234", type: BannerNotificationType.orderCreated }
    ]

    public clearNotifications() {
        this._notifications = []
    }

    public addNotification(orderUuid, orderId, type: BannerNotificationType) {
        this.clearNotifications()
        this._notifications.push({
            order_id: orderId,
            uuid: orderUuid,
            type,
        })
    }

    get notifications() {
        return this._notifications
    }

    constructor() { }
}
