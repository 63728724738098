import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CrudService } from '../crud.service';
import { TimezoneService } from '../timezone.service';
import { UserService } from '../user.service';
import { v4 as uuid } from 'uuid';
import { AuthService } from '../auth.service';
import { faBan, faExclamationCircle, faMinus, faSave, faUser } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { ButtonType } from '../button/button.component';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
})
export class ContactComponent implements OnInit {
    ButtonType = ButtonType
    faBan = faBan
    faSave = faSave
    faUser = faUser
    faMinus = faMinus
    faExclamationCircle = faExclamationCircle
    phoneExampleText = "e.g. (123) 456-7890";

    isReadOnly = false

    editableFields = [
        "company_id",
        "full_name",
        "photo",
        "email_address",
        "location",
        "job_title",
        "department",
        "phone",
        "time_zone",
    ]

    formControls = {}

    nextOrderIdPrefix
    minNextOrderIdLength = 8

    timezones$ = this.timezoneService.getTimezones().pipe(
        map(response => response.map(a => a.display_value)),
    )

    isNew
    contactId

    companiesData$ = this.crudService.search({
        type: "data_company",
        where: "parent_company_uuid = ? and parent_company_relation_type in (?, ?)",
        whereArgs: [this.authService.companyId, 'client', 'vendor'],
    }).pipe(
        map(result => result.result),
        tap((r) => {
            //console.log("r", r)
        }))

    userData$ = this.route.queryParams.pipe(
        switchMap((params) => {
            if (params["id"]) {
                this.contactId = params["id"]
                this.isNew = false
                return this.crudService.search({
                    type: "data_user",
                    where: "id = ?",
                    whereArgs: [params["id"]],
                });
            } else {
                this.isNew = true
                return of({})
            }
        }),
        tap((response: any) => {

            //console.log("DATA RESP", response)

            for (var i = 0; i < this.editableFields.length; ++i) {
                this.formControls[this.editableFields[i]] = new UntypedFormControl()
            }

            if (!this.isNew) {
                this.formControls["email_address"].disable()
                for (var i = 0; i < this.editableFields.length; ++i) {
                    this.formControls[this.editableFields[i]].setValue(response.result[0][this.editableFields[i]])
                }
            }
        })
    )

    constructor(
        private route: ActivatedRoute,
        private crudService: CrudService,
        private userService: UserService,
        private timezoneService: TimezoneService,
        private authService: AuthService,
        private router: Router,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
    }

    back() {
        //this.userService.navigateToInitialPage();
        this.router.navigate(["/contacts"])
    }

    cancel() {
        // this.setUnedit();
        // this.ngOnInit();

        this.router.navigate(["/contacts"])
    }

    faxUpdate() {
        this.userService.phoneUpdate(this.formControls["fax"]);
    }

    phoneUpdate() {
        this.userService.phoneUpdate(this.formControls["phone"]);
    }

    formErrors = {}
    anyError = false
    isValid() {
        this.anyError = false;

        //required fields
        ['company_id', 'email_address', 'full_name'].forEach(item => {
            if (!this.formControls[item].value) {
                this.formErrors[item] = "Please specify a value."
                this.anyError = true
            } else if (this.formControls[item].value.length > 255) {
                this.formErrors[item] = "Value must not exceed 255 characters in length."
                this.anyError = true
            } else {
                delete this.formErrors[item]
            }
        });

        if (!this.formControls['email_address'].value || !/\S+@\S+(\.\S+)?/.test(this.formControls["email_address"].value)) {
            this.formErrors["email_address"] = "Please enter a valid email address."
            this.anyError = true
        } else {
            delete this.formErrors["email_address"]
        }

        //optional fields, max length check
        ["phone", "location", "job_title", "department"].forEach((item) => {
            if (this.formControls[item].value && this.formControls[item].value.length > 255) {
                this.formErrors[item] = "Value must not exceed 255 characters in length"
                this.anyError = true
            } else {
                delete this.formErrors[item]
            }
        })
        return !this.anyError
    }


    save() {
        if (!this.isValid()) {
            return
        }
        if (this.isNew) {
            var newContactObject = {
                id: uuid(),
                email_address_lowercase: this.formControls['email_address'].value.toLowerCase(),
                role: "user",
                status: "A",
                provider_created: true,
            }
            for (var i = 0; i < this.editableFields.length; ++i) {
                newContactObject[this.editableFields[i]] = this.formControls[this.editableFields[i]].value
            }
            this.crudService.create(
                "data_user",
                newContactObject
            ).subscribe((response) => {
                //console.log(response)
                this.router.navigate(["/contacts"])
            })
        } else {
            var contactObject = {
                id: this.contactId,
            }
            for (var i = 0; i < this.editableFields.length; ++i) {
                contactObject[this.editableFields[i]] = this.formControls[this.editableFields[i]].value
            }
            this.crudService.update(
                "data_user",
                contactObject
            ).subscribe((response) => {
                //console.log(response)
                this.router.navigate(["/contacts"])
            })
        }
    }

    delete(userData) {
        const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
            panelClass: 'delete-confirmation-dialog-container',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var companyObject = {
                    id: this.contactId,
                }
                this.crudService.delete(
                    "data_user",
                    companyObject).subscribe((response) => {
                        //console.log(response)
                        this.router.navigate(["/contacts"])
                    })
            } else {
                this._snackBar.open("Action cancelled", null, {
                    duration: 4000,
                });
            }
        });
    }
}
