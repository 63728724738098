import { Component, OnInit } from '@angular/core';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { UntypedFormControl } from '@angular/forms';
import { InvoiceService } from '../invoice.service';
import { faSave, faPlus, faBan, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ButtonType } from '../button/button.component';

@Component({
    selector: 'app-invoice-configure',
    templateUrl: './invoice-configure.component.html',
    styleUrls: ['./invoice-configure.component.scss']
})
export class InvoiceConfigureComponent implements OnInit {
    ButtonType = ButtonType
    initialized = false
    faSave = faSave
    faPlus = faPlus
    faBan = faBan
    faEdit = faEdit

    defaultPaymentTermFC = new UntypedFormControl()
    invoiceSubjectTemplateFC = new UntypedFormControl()
    defaultInvoiceNotesFC = new UntypedFormControl()
    nextInvoiceIdFC = new UntypedFormControl()
    useOrderIdAsInvoiceIdFC = new UntypedFormControl()
    fiscalYearStartFC = new UntypedFormControl()

    dueDates
    itemTypes
    defaultItemTypeId
    trueFalseEnumValues = [
        { id: 0, name: "False", },
        { id: 1, name: "True", },
    ]
    monthEnumValues = [
        { id: 0, name: "January", },
        { id: 1, name: "February", },
        { id: 2, name: "March", },
        { id: 3, name: "April", },
        { id: 4, name: "May", },
        { id: 5, name: "June", },
        { id: 6, name: "July", },
        { id: 7, name: "August", },
        { id: 8, name: "September", },
        { id: 9, name: "October", },
        { id: 10, name: "November", },
        { id: 11, name: "December", },
    ]

    newItemTypeName = new UntypedFormControl()
    editItemTypeName = new UntypedFormControl()
    newItemError

    constructor(
        private _themeService: ThemeRebrandService,
        private invoiceService: InvoiceService,
        private router: Router,
        private _snackBar: MatSnackBar,
    ) { }

    getItemTypesPromise() {
        return new Promise((resolve, reject) => {
            this.invoiceService.getItemTypes().subscribe((response) => {
                if (response.success) {
                    this.itemTypes = response.result;
                    //todo - could add is_default column to sys_invoice_item_type table, and use that instead of "Searcher"
                    for (var i = 0; i < this.itemTypes.length; ++i) {
                        if (this.itemTypes[i].name == "Searcher") {
                            this.defaultItemTypeId = this.itemTypes[i].id;
                        }
                    }
                } else {
                    console.log(response)
                }
                //console.log(this.itemTypes)
                resolve(null)
            })
        })
    }

    ngOnInit() {
        var promises = [];
        promises.push(new Promise((resolve, reject) => {
            this.invoiceService.getDueDates().subscribe((response) => {
                if (response.success) {
                    this.dueDates = response.result;
                } else {
                    console.log(response)
                }
                resolve(null)
            })
        }))
        promises.push(this.getItemTypesPromise())
        promises.push(new Promise((resolve, reject) => {
            this.invoiceService.getInvoiceConfiguration().subscribe((response) => {
                if (response.success) {
                    //console.log(response)
                    this.nextInvoiceIdFC.setValue(response.result[0].next_invoice_id)
                    this.invoiceSubjectTemplateFC.setValue(response.result[0].invoice_subject_template)
                    this.useOrderIdAsInvoiceIdFC.setValue(response.result[0].use_order_id_as_invoice_id)
                    this.defaultInvoiceNotesFC.setValue(response.result[0].default_invoice_notes)
                    this.defaultPaymentTermFC.setValue(response.result[0].default_payment_term_id)
                    if (response.result[0].fiscal_year_start_month) {
                        this.fiscalYearStartFC.setValue(response.result[0].fiscal_year_start_month)
                    } else {
                        this.fiscalYearStartFC.setValue(0)
                    }
                } else {
                    console.log(response)
                }
                resolve(null)
            })
        }))

        Promise.all(promises).then(() => {
            this.initialized = true;
        })
    }

    get themeService() {
        return this._themeService;
    }

    saveConfiguration() {
        var config = {
            next_invoice_id: this.nextInvoiceIdFC.value,
            invoice_subject_template: this.invoiceSubjectTemplateFC.value,
            use_order_id_as_invoice_id: this.useOrderIdAsInvoiceIdFC.value,
            default_invoice_notes: this.defaultInvoiceNotesFC.value,
            default_payment_term_id: this.defaultPaymentTermFC.value,
            fiscal_year_start_month: this.fiscalYearStartFC.value,
        }
        this.invoiceService.updateInvoiceConfiguration(config).subscribe((response) => {
            if (response.success) {
                var snack = "Configuration saved."
                this._snackBar.open(snack, null, { duration: 2000, });
                this.router.navigate(["invoice-list"])
            } else {
                console.log(response)
            }
        })
    }

    cancelConfiguration() {
        this.router.navigate(["invoice-list"])
    }

    showNewItemForm = false
    newItemType() {
        this.showNewItemForm = !this.showNewItemForm
        this.newItemError = null
    }

    private processCreateErrorResponse(response) {
        this.newItemError = response.message
        if (!this.newItemError && response.error) {
            console.log(response.error.sqlMessage)
            if (response.error.sqlMessage.startsWith("Duplicate entry")) {
                this.newItemError = "Duplicate entry"
            } else {
                this.newItemError = response.error.sqlMessage
            }
        }
    }

    createNewItemType() {
        this.newItemError = null
        this.invoiceService.addItemType(this.newItemTypeName.value).subscribe((response) => {
            if (response.success) {
                var snack = "Item type created."
                this._snackBar.open(snack, null, { duration: 2000, });

                this.showNewItemForm = false
                this.getItemTypesPromise()
                this.newItemTypeName.setValue(null)
            } else {
                this.processCreateErrorResponse(response)
                console.log(response)
            }
        })
    }

    cancelNewItemType() {
        this.newItemError = null
        this.showNewItemForm = false
    }

    editItemId

    edit(itemType) {
        if (this.editItemId == itemType.id) {
            this.editItemId = null;
        } else {
            this.editItemId = itemType.id
            this.editItemTypeName.setValue(itemType.name)
        }
    }

    deleteItemError
    private processDeleteErrorResponse(response) {
        this.deleteItemError = response.message
        if (!this.deleteItemError && response.error) {
            console.log(response.error.sqlMessage)
            if (response.error.sqlMessage.startsWith("Cannot delete or update a parent row: a foreign key constraint fails")) {
                this.deleteItemError = "Item type is in use"
            } else {
                this.deleteItemError = response.error.sqlMessage
            }
        }
    }

    delete(itemType) {
        this.deleteItemError = null
        this.invoiceService.deleteItemType(itemType.id).subscribe((response) => {
            if (response.success) {
                var snack = "Item type deleted."
                this._snackBar.open(snack, null, { duration: 2000, });

                this.getItemTypesPromise()
            } else {
                this.processDeleteErrorResponse(response)
                console.log(response)
            }
        })
    }

    updateItemError
    private processUpdateErrorResponse(response) {
        this.updateItemError = response.message
        if (!this.updateItemError && response.error) {
            console.log(response.error.sqlMessage)
            if (response.error.sqlMessage.startsWith("Duplicate entry")) {
                this.updateItemError = "Duplicate entry"
            } else {
                this.updateItemError = response.error.sqlMessage
            }
        }
    }
    save(itemType) {
        this.updateItemError = null
        this.invoiceService.updateItemType(itemType.id, this.editItemTypeName.value).subscribe((response) => {
            if (response.success) {
                var snack = "Item type updated."
                this._snackBar.open(snack, null, { duration: 2000, });
                this.getItemTypesPromise()
                this.editItemId = null;
            } else {
                this.processUpdateErrorResponse(response)
                console.log(response)
            }
        })
    }

    cancel() {
        this.editItemId = null;
    }
}
