<div *ngIf="userData$ | async as userData" class="container-fluid mt-4 py-2">
    <div class="row">
        <div class="col-2 d-none d-md-block">
        </div>
        <div class="col-12 col-md-8">

            <div class="row">
                <div class="col bold" style="font-size: 1.6em; color: #117ac9">
                    <span *ngIf="isNew">New</span>
                    <span *ngIf="!isNew">Edit</span>
                    Contact
                </div>
            </div>

            <div class="row">
                <div class="col-sm-4">
                    <app-input-image label="Photo" [fc]="formControls['photo']" [unsetIcon]="faUser">
                    </app-input-image>
                </div>
                <div class="col-sm-8">
                    <app-input-enum label="Company" [fc]="formControls['company_id']" [requiredMarker]="true"
                        [enumValuesNameAndId]="companiesData$ | async">
                    </app-input-enum>
                    <span *ngIf="formErrors['company_id']"
                        class="validation-error mt-1">{{formErrors['company_id']}}</span>
                    <app-input-text label="Email Address" [fc]="formControls['email_address']" [requiredMarker]="true">
                    </app-input-text>
                    <span *ngIf="formErrors['email_address']"
                        class="validation-error mt-1">{{formErrors['email_address']}}</span>
                    <app-input-text label="Full Name" [fc]="formControls['full_name']" [requiredMarker]="true">
                    </app-input-text>
                    <span *ngIf="formErrors['full_name']"
                        class="validation-error mt-1">{{formErrors['full_name']}}</span>
                    <app-input-text label="Location" [fc]="formControls['location']">
                    </app-input-text>
                    <span *ngIf="formErrors['location']" class="validation-error mt-1">{{formErrors['location']}}</span>
                    <app-input-text label="Job Title" [fc]="formControls['job_title']">
                    </app-input-text>
                    <span *ngIf="formErrors['job_title']"
                        class="validation-error mt-1">{{formErrors['job_title']}}</span>
                    <app-input-text label="Department" [fc]="formControls['department']">
                    </app-input-text>
                    <span *ngIf="formErrors['department']"
                        class="validation-error mt-1">{{formErrors['department']}}</span>
                    <app-input-text label="Phone" [fc]="formControls['phone']" [change]="phoneUpdate.bind(this)"
                        [exampleText]="phoneExampleText"></app-input-text>
                    <span *ngIf="formErrors['phone']" class="validation-error mt-1">{{formErrors['phone']}}</span>
                    <app-input-enum label="Time Zone" [fc]="formControls['time_zone']"
                        [enumValues]="timezones$ | async">
                    </app-input-enum>
                    <span *ngIf="formErrors['time_zone']"
                        class="validation-error mt-1">{{formErrors['timezone']}}</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="anyError" class="row mt-3 validation-error-banner py-2">
        <div class="col-12" align="center">
            <fa-icon [icon]="faExclamationCircle"></fa-icon>
            Please address errors above and re-submit.
        </div>
    </div>

    <div class="row pt-3">
    </div>
    <app-button-group-create *ngIf="isNew" [create]="save.bind(this)" [cancel]="back.bind(this)">
    </app-button-group-create>
    <app-button-group-save *ngIf="!isNew" [save]="save.bind(this)" [cancel]="back.bind(this)"
        [delete]="delete.bind(this, userData)"></app-button-group-save>

</div>