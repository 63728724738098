<div *ngIf="format == InputTextAreaFormat.verticalLabel" class="row mt-3">
    <div class="col bold">
        {{label}}
    </div>
</div>
<div *ngIf="format == InputTextAreaFormat.verticalLabel" class="row mt-1">
    <div *ngIf="setMaxLength === true" class="col">
        <textarea *ngIf="!disabled" maxlength="1000" [rows]="rowCount" class="ml-2" [formControl]="fc" (keyup)="doChange()"
        style="vertical-align: top; width: 100%; resize: both; border-radius: .3em;">
        </textarea>
        <span *ngIf="disabled" style="white-space: pre-wrap;">{{fc.value}}</span>
    </div>
    <div *ngIf="setMaxLength === false" class="col">
        <textarea *ngIf="!disabled" [rows]="rowCount" class="ml-2" [formControl]="fc" (keyup)="doChange()"
            style="vertical-align: top; width: 100%; resize: both; border-radius: .3em;">
        </textarea>

        <span *ngIf="disabled" style="white-space: pre-wrap;">{{fc.value}}</span>
    </div>
</div>

<span *ngIf="format == InputTextAreaFormat.none">
    <textarea *ngIf="!disabled" [rows]="rowCount" [formControl]="fc" (keyup)="doChange()"
        style="vertical-align: top; width: 100%; resize: both; border-radius: .3em;">
    </textarea>
    <span *ngIf="disabled" style="white-space: pre-wrap;">{{fc.value}}</span>
</span>