<span *ngIf="!initialized">
    <div class="container-fluid bordered-container mt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor">
        Initializing...
    </div>
</span>
<div *ngIf="initialized" class="container-fluid mt-4 py-2 rounded-ninety" [style.color]="themeService.textOnTertiaryColor"
    [style.background-color]="themeService.tertiaryColor">

    <div class="row edit-height">
        <div class="col pb-2" style="font-size: 1.6em;">
            About
        </div>
    </div>
    <div class="row edit-height">
        <div class="col-2">
        </div>
        <div class="col-4">
            Branch
        </div>
        <div class="col-4">
            {{branch}}
        </div>
    </div>
    <div class="row edit-height">
        <div class="col-2">
        </div>
        <div class="col-4">
            Commit Number
        </div>
        <div class="col-4">
            {{commitCount}}
        </div>
    </div>
    <div class="row edit-height">
        <div class="col-2">
        </div>
        <div class="col-4">
            Last Commit Identifier
        </div>
        <div class="col-4">
            {{commitId}}
        </div>
    </div>
    <div class="row edit-height">
        <div class="col-2">
        </div>
        <div class="col-4">
            Last Commit Timestamp
        </div>
        <div class="col-4">
            {{lastCommitTimestamp}}
        </div>
    </div>
    <div class="row edit-height">
        <div class="col-2">
        </div>
        <div class="col-4">
            Environment Type
        </div>
        <div class="col-4">
            {{environmentType}}
        </div>
    </div>
</div>