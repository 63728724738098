import { Component, OnInit } from '@angular/core';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { InvoiceService } from '../invoice.service';

@Component({
    selector: 'app-invoice-open',
    templateUrl: './invoice-open.component.html',
})
export class InvoiceOpenComponent implements OnInit {
    initialized
    currentPageAll = 1
    currentPageOpen = 1
    allListLimit
    openListLimit
    allListCount
    openListCount

    /*
    * 6/5/23 Brock Ramsey Comments
    * Default sort field was previously invoice_date, switched to created.
    * Default sort order for both sort directions was descending (desc)
    */
   
    allSortField = "invoice_created" // was invoice_date
    allSortDirection = "desc" // default to descending
    openSortField = "invoice_created" // was invoice_date
    openSortDirection = "desc" // default to descending
    
    openInvoices
    allInvoices
    faCaretDown = faCaretDown
    faCaretUp = faCaretUp

    columnTitles = [
        {
            display: "ID",
            fieldName: "invoice_id"
        },
        {
            display: "Invoice Date",
            fieldName: "invoice_date"
        },
        {
            display: "Client",
            fieldName: "for_company_name"
        },
        {
            display: "Created",
            fieldName: "invoice_created"
        },
        {
            display: "Status",
            fieldName: "status_name"
        },
        {
            display: "Balance",
            fieldName: "balance",
            // align: "right"
        }
    ]

    constructor(
        private invoiceService: InvoiceService,
    ) { }

    openSortClick(columnTitle) {
        if (this.openSortField == columnTitle.fieldName) {
            if (this.openSortDirection == "desc") {
                this.openSortDirection = "asc"
            } else {
                this.openSortDirection = "desc"
            }
        } else {
            this.openSortField = columnTitle.fieldName
            this.openSortDirection = "desc"
        }
        this.getOpenInvoicesPromise()
    }

    ngOnInit() {
        this.getOpenInvoicesPromise().then(() => {
            this.initialized = true
        })
    }

    getOpenInvoicesPromise() {
        return new Promise((resolve, reject) => {
            this.invoiceService.getOpenInvoices(this.openSortField, this.openSortDirection, this.currentPageOpen).subscribe((response) => {
                console.log("open response", response)
                if (response.success) {
                    this.openInvoices = response.result
                    this.openListLimit = response.limit
                    this.openListCount = response.count
                } else {
                    console.log(response)
                }
                //console.log("open", this.openInvoices)
                resolve(null)
            })
        })
    }
    setPageOpen(page) {
        this.currentPageOpen = page
        this.getOpenInvoicesPromise()
    }

}
