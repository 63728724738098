<div *ngIf="!style" class="row"
    [ngClass]="{'pb-2': !exampleText && !disabled && hideRules, 'edit-height': !exampleText && !disabled && hideRules}">
    <div class="col-4 unedit-padding bold">
        {{label}}
    </div>
    <div *ngIf="!disabled" class="col-8">
        <input type="password" class="input-style" [formControl]="fc" (keyup)="doChange()" (keyup.enter)="doEnter()">
    </div>
    <div *ngIf="disabled" class="col-8">
        {{fc.value}}
    </div>
</div>
<div *ngIf="!style && exampleText && !disabled" class="row">
    <div class="col-4 pb-2">
    </div>
    <div class="col-8 pb-2" style="font-size: .8em">
        {{exampleText}}
    </div>
</div>

<div *ngIf="style == 'rebrand2'" class="row mt-3">
    <div class="col bold">
        {{label}}
    </div>
</div>
<div *ngIf="style == 'rebrand2'" class="row mt-1">
    <div class="col">
        <input type="password" class="input-style ml-2" [formControl]="fc" (keyup)="doChange()"
            (keyup.enter)="doEnter()">
    </div>
</div>
<div *ngIf="style == 'rebrand2' && exampleText && !disabled" class="row">
    <div class="col" style="font-size: .8em;" align="right">
        <span *ngIf="exampleIcon">
            <fa-icon [icon]="exampleIcon"></fa-icon>
        </span> {{exampleText}}
    </div>
</div>
<span *ngIf="!style && !hideRules && !disabled">
    <div class="row">
        <div class="col-4">
        </div>
        <div class="col" style="font-size: .8em;">
            Password must:
            <ul class="mb-1">
                <li>be at least 8 characters in length</li>
                <li>contain at least 1 uppercase character</li>
                <li>contain at least 1 lowercase character</li>
                <li>contain at least 1 digit</li>
                <li>contain at least one special character (!, &#64;, #, $, %, ^, &, or *)</li>
            </ul>
        </div>
    </div>
</span>

<span *ngIf="style == 'rebrand2' && !hideRules && !disabled">
    <div class="row">
        <div class="col" style="font-size: .8em;">
            <span class="ml-2">
                Password must:
                <ul class="mb-1">
                    <li>be at least 8 characters in length</li>
                    <li>contain at least 1 uppercase character</li>
                    <li>contain at least 1 lowercase character</li>
                    <li>contain at least 1 digit</li>
                    <li>contain at least one special character (!, &#64;, #, $, %, ^, &, or *)</li>
                    </ul>
            </span>
        </div>
    </div>
</span>
