import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class QualiaService {

    constructor(
        private http: HttpClient,
    ) { }

    getQualiaDashboard(page, sortField, sortDirection, filterType) {
        return this.http.get<any>("api/qualiaDashboard/" + page + "/" + sortField + "/" + sortDirection + "/" + filterType);
    }

    getQualiaOrderStatuses() {
        return this.http.get<any>("api/qualiaOrderStatuses");
    }

    getQualiaOrderUpload(qualiaOrderId) {
        return this.http.get<any>("api/qualiaOrderUpload/" + qualiaOrderId);
    }

    getQualiaOrderMessages(qualiaOrderId) {
        return this.http.get<any>("api/qualiaOrderMessages/" + qualiaOrderId);
    }

    getQualiaOrderMessagesRefresh(qualiaOrderId) {
        return this.http.get<any>("api/qualiaOrderMessagesRefresh/" + qualiaOrderId);
    }

    downloadQualiaMessageAttachment(uuid) {
        var options: any = { observe: 'response', responseType: 'blob' };
        return this.http.get<any>("api/qualiaMessageAttachment/" + uuid, options);
    }
}
