import { Component, OnInit } from '@angular/core';
import { ThemeService } from './theme.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { AuthService } from './auth.service';
import { SystemService } from './system.service';
import { Location, PopStateEvent } from "@angular/common";
import { AccountService } from './account.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    title = 'angular-sierra';

    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    public constructor(
        private _themeService: ThemeService,
        private router: Router,
        private authService: AuthService,
        private _systemService: SystemService,
        private location: Location,
    ) {
    }

    get themeService() {
        return this._themeService;
    }

    get systemService() {
        return this._systemService;
    }

    hasAuthenticationToken() {
        return this.authService.hasValidAuthenticationToken() && !this.router.url.startsWith('/login');
    }


    ngOnInit() {
        this.authService.stillLoggedInCheck();

        // document.addEventListener('keydown', function (event) {
        //     if (document.activeElement.tagName == "BODY" && event.altKey && event.which == 67) {
        //         this._routerRedirectService.doRouteRedirectTo("/draft-order-create");
        //     }
        // }.bind(this));

        //from https://stackoverflow.com/questions/39601026/angular-2-scroll-to-top-on-route-change

        this.location.subscribe((ev:PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });

        this.router.events.subscribe((ev:any) => {
            if (ev instanceof NavigationStart) {
                if (ev.url != this.lastPoppedUrl)
                    this.yScrollStack.push(window.scrollY);
            } else if (ev instanceof NavigationEnd) {
                if (ev.url == this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else
                    window.scrollTo(0, 0);
            }
        });

    }

}
