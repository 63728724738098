import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { ChargebeeService } from './chargebee.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemService } from './system.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BannerNotificationService } from './banner-notification.service';
import { faDollyFlatbed } from '@fortawesome/free-solid-svg-icons';
const helper = new JwtHelperService();

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    loggedInCheckIntervalId;
    inactivityCheckIntervalId
    inactivityTimeoutInSeconds
    lastActivityTimestamp

    constructor(
        private router: Router,
        private chargebeeService: ChargebeeService,
        private http: HttpClient,
        private systemService: SystemService,
        private _snackBar: MatSnackBar,
        private bannerNotificationService: BannerNotificationService
    ) {
        this.lastActivityTimestamp = Date.now()
        var updateLastActivityTimestamp = (event) => {
            //console.log("activity detected")
            this.lastActivityTimestamp = Date.now()
        }
        document.addEventListener('mousemove', updateLastActivityTimestamp);
        document.addEventListener('keydown', updateLastActivityTimestamp);
        document.addEventListener('keyup', updateLastActivityTimestamp);
        window.addEventListener('scroll', updateLastActivityTimestamp, true);
        document.addEventListener('click', updateLastActivityTimestamp);
    }

    hasValidAuthenticationToken() {
        return localStorage.getItem('authToken') != null
            && helper.decodeToken(localStorage.getItem("authToken"))
            && !helper.isTokenExpired(localStorage.getItem("authToken"));
    }

    logLogout() {
        return this.http.post<any>("api/logLogout", null, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        });
    }

    doLogout(reason?) {
        this.bannerNotificationService.clearNotifications()
        var snack = "You have been signed out"
        if (reason) {
            snack += " due to " + reason
        }
        snack += "."
        this._snackBar.open(snack, null, { duration: 2000, });
        this.logLogout()
        //localStorage.removeItem("authToken");
        localStorage.clear()
        if (this.loggedInCheckIntervalId) {
            clearInterval(this.loggedInCheckIntervalId)
            this.loggedInCheckIntervalId = null
        }
        if (this.inactivityCheckIntervalId) {
            clearInterval(this.inactivityCheckIntervalId)
            this.inactivityCheckIntervalId = null
        }
        this.router.navigate(['/login']);
    }

    stillLoggedInCheck() {
        if (!this.loggedInCheckIntervalId && this.hasValidAuthenticationToken()) {
            this.loggedInCheckIntervalId = setInterval(() => {
                if (!this.hasValidAuthenticationToken()) {
                    this.doLogout("No token. You may have another tab open that logged out.");
                }
            }, 100);
        }

        if (!this.inactivityCheckIntervalId && this.hasValidAuthenticationToken()) {
            this.systemService.getInactivityTimeout().subscribe((response) => {
                if (response.success) {
                    this.inactivityTimeoutInSeconds = response.inactivityTimeoutInSeconds
                } else {
                    this.inactivityTimeoutInSeconds = 360
                }
                this.inactivityCheckIntervalId = setInterval(() => {
                    if (Date.now() - this.lastActivityTimestamp > this.inactivityTimeoutInSeconds * 1000) {
                        if (this.hasValidAuthenticationToken()) {
                            this.doLogout("inactivity")
                        }
                    }
                }, 1000)
            })
        }
    }

    private getField(fieldName) {
        const decodedToken = helper.decodeToken(localStorage.getItem("authToken"));
        if (!decodedToken) {
            return null;
        }
        return decodedToken[fieldName];
    }

    get username() {
        return this.getField("email");
    }

    get companyname() {
        return this.getField("companyName");
    }

    get companyId() {
        return this.getField("companyId");
    }

    get userrole() {
        return this.getField("role");
    }

    get userId() {
        return this.getField("userId");
    }

    bypassAuthPromise() {
        if (this.companyname == "SaQus" || this.companyname == "Search-it") {
            return Promise.resolve(true)
        }
        return new Promise((resolve, reject) => {
            this.systemService.getEnvironmentType().subscribe((response) => {
                resolve(response.result == "dev")
            })
        })
    }

    isProviderPromise() {
        if (this.companyname == "Search-it") {
            return Promise.resolve(true)
        }
        return new Promise((resolve, reject) => {
            this.chargebeeService.getPlan().subscribe((response) => {
                var checkBypassPromise: Promise<unknown> = Promise.resolve()
                if (!response.success) {
                    checkBypassPromise = this.bypassAuthPromise()
                }
                checkBypassPromise.then((doBypass) => {
                    if (response.success || doBypass) {
                        var planId = response.plan;
                        this.chargebeeService.getFeatures(planId).subscribe((response) => {
                            //console.log("is provider features", response.result)
                            var features = response.result;
                            for (var i = 0; i < response.result.length; ++i) {
                                if (response.result[i].feature_name == "Provider Portal") {
                                    return resolve(true)
                                }
                            }
                            resolve(false)
                        });
                    }
                })
            });
        })
    }

    isSearcherPromise() {
        if (this.companyname == "Search-it") {
            return Promise.resolve(true)
        }
        return new Promise((resolve, reject) => {
            this.chargebeeService.getPlan().subscribe((response) => {
                var checkBypassPromise: Promise<unknown> = Promise.resolve()
                if (!response.success) {
                    checkBypassPromise = this.bypassAuthPromise()
                }
                checkBypassPromise.then((doBypass) => {
                    if (response.success || doBypass) {
                        var planId = response.plan;
                        this.chargebeeService.getFeatures(planId).subscribe((response) => {
                            var features = response.result;
                            for (var i = 0; i < response.result.length; ++i) {
                                if (response.result[i].feature_name == "Searcher Portal") {
                                    return resolve(true)
                                }
                            }
                            resolve(false)
                        });
                    } else {
                        resolve(false)
                    }
                })

            });
        })
    }

    isCurrentUserOwner(): Observable<any> {
        return this.http.get<any>("api/isCurrentUserOwner");
    }

    isOwnerPromise() {
        return new Promise((resolve, reject) => {
            this.isCurrentUserOwner().subscribe((response) => {
                if (response.success) {
                    resolve(response.isOwner)
                } else {
                    reject(response)
                }
            })
        })
    }
}
