export class ApiResponse<T>
{
    success: boolean;
    message: string;
    data: T;

    static unwrapApiResponse<T>(response: ApiResponse<T>): T
    { 
        if (!response.success)
        {
            throw new Error(response.message || 'API Error');
        }
        return response.data;
    }
}
