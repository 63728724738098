export class SysSchool {
    schoolDistrictCode?: string;
    schoolDistrictName?: string;
    address?: string;
    taxYear?: string;
    taxPeriod?: string;
    lienDate?: string;
    fiscalStartDate?: Date;
    fiscalEndDate?: Date;
    dueDate1?: Date;
    dueDate2?: Date;
    dueDate3?: Date;
    dueDate4?: Date;
    refreshDate?: Date;
}