<span *ngFor="let item of fc.fcs; let i = index; let isFirst = first; let isLast = last;">
    <!-- <div class="row" [ngClass]="{'edit-height': !(isFirst && exampleText)}">
        <div class="col-4 unedit-padding bold">
            <span *ngIf="isFirst">
                {{label}}
            </span>
        </div>
        <div class="col-8" [ngClass]="{'pb-2': !(isFirst && exampleText)}">
            <input type="text" class="input-style" style="width: 80%;" [formControl]="item" (keyup)="doChange()">
            <fa-icon class="ml-2 pointer" [icon]="faMinus" (click)="removeFC(i)" title="remove">
            </fa-icon>
            <span *ngIf="isLast">
                <fa-icon class="ml-1 pointer" *ngIf="isLast" [icon]="faPlus" (click)="addFC(i)" title="add"></fa-icon>
            </span>
        </div>
    </div> -->

    <div class="row mt-3">
        <div class="col bold">
            <span *ngIf="isFirst">
                {{label}}
                <span *ngIf="tooltip" data-toggle="tooltip" data-placement="right" [title]="tooltip"
                    class="saq-tooltip ml-1">
                    <fa-icon [icon]="faInfoCircle">
                    </fa-icon>
                </span>
            </span>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col">
            <input type="text" class="input-style" style="width: 80%;" [formControl]="item" (keyup)="doChange()">
            <fa-icon class="ml-2 pointer" [icon]="faMinus" (click)="removeFC(i)" title="remove">
            </fa-icon>
            <span *ngIf="isLast">
                <fa-icon class="ml-1 pointer" *ngIf="isLast" [icon]="faPlus" (click)="addFC(i)" title="add"></fa-icon>
            </span>
        </div>
    </div>

    <div *ngIf="isFirst && exampleText" class="row">
        <div class="col-4 pb-2 unedit-padding bold">
        </div>
        <div class="col-8 pb-2" style="font-size: .8em;">
            {{exampleText}}
        </div>
    </div>
</span>