import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard-search-typing',
    templateUrl: './dashboard-search-typing.component.html',
})
export class DashboardSearchTypingComponent implements OnInit {
    filter = {
        gate: "all",
        comparisons: [
            {
                field: "team_id",
                operator: "is",
                matchValue: null
            },
            {
                field: "workflow_status",
                operator: "is",
                matchValue: "Y"
            },
        ],
    }
    constructor() { }

    ngOnInit() {
    }

}
