import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { InvoiceService } from '../invoice.service';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { faWrench, faPlusSquare, faBan, faTimes, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../company.service';
import { OrderService } from '../order.service';
import { v4 as uuid } from 'uuid';
import Mustache from 'mustache';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { SalesTaxService } from '../sales-tax.service';
import { ClientService } from '../client.service';
import { InputTextAreaFormat } from '../input-text-area/input-text-area.component';
import { InputEnumFormat } from '../input-enum/input-enum.component';
import { formatDate } from '@angular/common';
import { ButtonType } from '../button/button.component';
import { InputNumberFormat } from '../input-number/input-number.component';
import { InputCurrencyFormat } from '../input-currency/input-currency.component';

@Component({
    selector: 'app-invoice-edit',
    templateUrl: './invoice-edit.component.html',
})
export class InvoiceEditComponent implements OnInit {
    ButtonType = ButtonType
    InputTextAreaFormat = InputTextAreaFormat
    InputEnumFormat = InputEnumFormat
    InputNumberFormat = InputNumberFormat
    InputCurrencyFormat = InputCurrencyFormat
    initialized = false
    @Input() cancelFunction
    @Input() onSaveNavigate
    faWrench = faWrench
    faPlusSquare = faPlusSquare
    faBan = faBan
    faTimes = faTimes
    faPlus = faPlus
    faEdit = faEdit
    mustache = Mustache

    invoiceIdFC = new UntypedFormControl({ value: null, disabled: true })
    poNumberFC = new UntypedFormControl()
    invoiceDateFC = new UntypedFormControl(formatDate(new Date(), 'yyyy-MM-dd', 'en'))
    paymentTermFC = new UntypedFormControl()
    dueDateFC = new UntypedFormControl()
    billToCompanyFC = new UntypedFormControl()
    taxRateFC = new UntypedFormControl()
    //taxLocationFC = new FormControl({ value: null, disabled: true })
    taxLocationFC = new UntypedFormControl()
    taxCodeFC = new UntypedFormControl()
    discountFC = new UntypedFormControl()
    currencyFC = new UntypedFormControl()
    subjectFC = new UntypedFormControl()
    notesFC = new UntypedFormControl()
    itemTypeFCSets = []
    statusValue = "D"
    currencies
    dueDates
    itemTypes
    defaultItemTypeId
    order
    editOrderId
    clientCompanies
    invoiceConfiguration
    salesTaxJurisdictions
    removedItems = []
    internalNotes

    uniqueClientCompanies = []
    constructor(
        private _invoiceService: InvoiceService,
        private _themeService: ThemeRebrandService,
        private router: Router,
        private companyService: CompanyService,
        private orderService: OrderService,
        private _snackBar: MatSnackBar,
        private salesTaxService: SalesTaxService,
        private activatedRoute: ActivatedRoute,
        private clientService: ClientService,
    ) { }

    get invoiceService() {
        return this._invoiceService
    }

    getRole(order, name, fallback) {
        if (order.property_seller) {
            for (var i = 0; i < order.property_seller.length; ++i) {
                if (order.property_seller[i] == name) {
                    return "Property Seller";
                }
            }
        }
        if (order.property_buyer) {
            for (var i = 0; i < order.property_buyer.length; ++i) {
                if (order.property_buyer[i] == name) {
                    return "Property Buyer";
                }
            }
        }
        if (order.assessed_owner == name) {
            return "Assessed Owner";
        }
        return fallback;
    }

    setDueDate() {
        if (this.paymentTermFC.value) {
            var dueDate = new Date(this.invoiceDateFC.value);
            dueDate.setDate(dueDate.getDate() + parseInt(this.paymentTermFC.value));
            this.dueDateFC.setValue(formatDate(dueDate, 'yyyy-MM-dd', 'en'))
        }
    }

    searchJurisdictions(term) {
        var results = []
        term = term.toLowerCase()
        for (var i = 0; i < this.salesTaxJurisdictions.length; ++i) {
            if (this.salesTaxJurisdictions[i].jurisdiction_name.toLowerCase().startsWith(term)) {
                results.push(this.salesTaxJurisdictions[i])
            }
        }
        return of(results)
    }

    getItemJurisdictionName(item) {
        return item.jurisdiction_name
    }

    selectJurisdiction(item) {
        //this.taxLocationFC.setValue(item.jurisdiction_id)
        this.taxCodeFC.setValue(item.code)
        this.taxRateFC.setValue(item.rate)
    }

    invoiceId
    getInvoiceIdParamPromise() {
        return new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe(params => {
                this.invoiceId = params.invoiceId
                resolve(this.invoiceId)
            })
        })
    }

    createOrderUuid
    getCreateFromOrderUuidParamPromise() {
        return new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe(params => {
                this.createOrderUuid = params.createOrderUuid
                resolve(this.createOrderUuid)
            })
        })
    }

    isSubjectSpecialCharacter = function (character) {
        switch (character) {
            case ">":
            case "<":
            case ":":
            case "\"":
            case "/":
            case "\\":
            case "|":
            case "?":
            case "*":
            case ",":
            case "&":
                return true;
        }
        return false;
    }

    replaceSubjectSpecialCharacters = function (subject) {
        for (var i = 0; i < subject.length; ++i) {
            if (this.isSubjectSpecialCharacter(subject.charAt(i))) {
                subject = subject.substring(0, i) + "-" + subject.substring(i + 1, subject.length);
            }
        }
        return subject;
    }

    forCompanyName

    ngOnInit() {
        this.getInvoiceIdParamPromise().then(() => {
            return this.getCreateFromOrderUuidParamPromise()
        }).then(() => {

            var promises = [];

            promises.push(new Promise((resolve, reject) => {
                this.salesTaxService.getSalesTaxes().subscribe((response) => {
                    if (response.success) {
                        this.salesTaxJurisdictions = response.result
                    }
                    resolve(null)
                })
            }))

            if (this.invoiceId) {
                promises.push(new Promise((resolve, reject) => {
                    this.invoiceService.getInvoice(this.invoiceId).subscribe((response) => {
                        //console.log(response);
                        if (response.success) {
                            this.forCompanyName = response.result[0].for_company_name
                            this.editOrderId = response.result[0].order_uuid
                            this.statusValue = response.result[0].status
                            this.invoiceIdFC.setValue(response.result[0].invoice_id);
                            this.poNumberFC.setValue(response.result[0].po_number);
                            this.invoiceDateFC.setValue(formatDate(response.result[0].invoice_date, 'yyyy-MM-dd', 'en'))
                            this.dueDateFC.setValue(formatDate(response.result[0].due_date, 'yyyy-MM-dd', 'en'));
                            this.paymentTermFC.setValue(response.result[0].payment_term_id)
                            this.billToCompanyFC.setValue(response.result[0].bill_to_company_id);
                            this.discountFC.setValue(response.result[0].discount ? response.result[0].discount * 100 : response.result[0].discount);
                            this.currencyFC.setValue(response.result[0].currency_id);
                            this.subjectFC.setValue(response.result[0].subject);
                            this.notesFC.setValue(response.result[0].notes);
                            this.taxRateFC.setValue(response.result[0].tax_rate ? (response.result[0].tax_rate * 100).toFixed(4) : response.result[0].tax_rate)
                            this.taxLocationFC.setValue(response.result[0].tax_location)
                            this.taxCodeFC.setValue(response.result[0].tax_code)
                            for (var i = 0; i < response.result[0].items.length; ++i) {
                                this.itemTypeFCSets.push({
                                    item_id: response.result[0].items[i].id,
                                    typeFC: new UntypedFormControl(response.result[0].items[i].type),
                                    descriptionFC: new UntypedFormControl(response.result[0].items[i].description),
                                    quantityFC: new UntypedFormControl(response.result[0].items[i].quantity),
                                    unitPriceFC: new UntypedFormControl(response.result[0].items[i].unit_price),
                                    taxableFC: new UntypedFormControl(response.result[0].items[i].taxable),
                                    amount: response.result[0].items[i].quantity * response.result[0].items[i].unit_price,
                                    detailId: response.result[0].items[i].order_detail_id
                                })
                            }
                        } else {
                            console.log(response)
                        }
                        resolve(null);
                    })
                }))

            } else {
                promises.push(
                    new Promise((resolve, reject) => {
                        this.invoiceService.getInvoiceConfiguration().subscribe((response) => {
                            if (response.success) {
                                this.invoiceConfiguration = response.result[0]
                                this.paymentTermFC.setValue(response.result[0].default_payment_term_id)
                                this.notesFC.setValue(response.result[0].default_invoice_notes)
                                this.setDueDate()
                            } else {
                                console.log(response)
                            }
                            resolve(null)
                        })
                    }).then(() => {
                        if (this.createOrderUuid) {
                            return new Promise((resolve, reject) => {
                                this.orderService.getOrder3(this.createOrderUuid).subscribe((response) => {
                                    if (!response.success) {
                                        console.log(response)
                                        return reject(response)
                                    }
                                    //console.log("debug order", response.result)

                                    this.order = response.result
                                    this.invoiceIdFC.setValue(this.order.order_id);

                                    this.salesTaxService.getCountySalesTax(this.order.county).subscribe((response) => {
                                        if (response.success) {
                                            if (response.result.length > 0) {
                                                this.taxRateFC.setValue(response.result[0].rate)
                                                this.taxLocationFC.setValue(response.result[0].jurisdiction_name)
                                                this.taxCodeFC.setValue(response.result[0].code)
                                            }
                                        }
                                        var subjectTemplate = this.invoiceConfiguration.invoice_subject_template;
                                        var view = {
                                            order: this.order,
                                        }

                                        this.subjectFC.setValue(
                                            this.replaceSubjectSpecialCharacters(
                                                Mustache.render(subjectTemplate, view)))
                                        this.billToCompanyFC.setValue(this.order.bill_to_company_id)
                                        this.poNumberFC.setValue(this.order.reference_number)

                                        var addService = (description, detailId, price, quantity) => {
                                            this.itemTypeFCSets.push({
                                                item_id: uuid(),
                                                typeFC: new UntypedFormControl("24f87dc9-e2b3-4574-a651-d810f2398119"),//searcher type id
                                                descriptionFC: new UntypedFormControl(description),
                                                quantityFC: new UntypedFormControl(quantity),
                                                unitPriceFC: new UntypedFormControl(price),
                                                taxableFC: new UntypedFormControl(1),
                                                amount: quantity * price,
                                                detailId
                                            })
                                        }

                                        for (var i = 0; i < this.order.detail.length; ++i) {
                                            console.log(this.order.detail[i]);
                                            var description;
                                            if (this.order.detail[i].detail_service_name) {
                                                var individualOrEntityDescription = "";
                                                if (this.order.detail[i].name && this.order.detail[i].name !== this.order.detail[i].detail_service_name) {
                                                    individualOrEntityDescription = " - " + this.order.detail[i].name;
                                                }
                                                description =
                                                    this.order.order_id
                                                    + " - " + this.order.detail[i].detail_service_name
                                                    + individualOrEntityDescription
                                                    + " - " + this.order.address;
                                            } else {
                                                description =
                                                    this.order.order_id
                                                    + " - " + "Custom Cost"
                                                    + " - " + this.order.address;
                                            }
                                            if (this.order.detail[i].custom_description) {
                                                description =
                                                    this.order.order_id
                                                    + " - " + this.order.detail[i].custom_description
                                                    + " - " + this.order.address;
                                            }
                                            addService(
                                                description,
                                                this.order.detail[i].uuid,
                                                this.order.detail[i].price,
                                                this.order.detail[i].quantity
                                            );
                                        }
                                        if (this.order.bill_to_company_id) {
                                            this.updateBillToCompanyPromise(this.order.bill_to_company_id).then(() => {
                                                resolve(null)
                                            })
                                        } else {
                                            resolve(null)
                                        }
                                    })
                                })
                            })
                        }
                    })
                )
            }

            promises.push(new Promise((resolve, reject) => {
                this.clientService.getClients().subscribe((response) => {
                    if (response.success) {
                        this.clientCompanies = response.result;
                        var lastClientCompanyId = null
                        for (var i = 0; i < this.clientCompanies.length; ++i) {
                            if (lastClientCompanyId == null || lastClientCompanyId != this.clientCompanies[i].id) {
                                this.uniqueClientCompanies.push(this.clientCompanies[i])
                                lastClientCompanyId = this.clientCompanies[i].id
                            }
                        }
                    }
                    resolve(null)
                })
            }))

            promises.push(new Promise((resolve, reject) => {
                this.invoiceService.getCurrencies().subscribe((response) => {
                    if (response.success) {
                        this.currencies = response.result;
                        if (!this.currencyFC.value) {
                            for (var i = 0; i < this.currencies.length; ++i) {
                                if (this.currencies[i].is_default) {
                                    this.currencyFC.setValue(this.currencies[i].id);
                                    break;
                                }
                            }
                        }
                    } else {
                        console.log(response);
                    }
                    resolve(null);
                })
            }))

            promises.push(new Promise((resolve, reject) => {
                this.invoiceService.getDueDates().subscribe((response) => {
                    if (response.success) {
                        this.dueDates = response.result;
                    } else {
                        console.log(response)
                    }
                    resolve(null)
                })
            }))

            promises.push(new Promise((resolve, reject) => {
                this.invoiceService.getItemTypes().subscribe((response) => {
                    if (response.success) {
                        this.itemTypes = response.result;
                        //todo - could add is_default column to sys_invoice_item_type table, and use that instead of "Searcher"
                        for (var i = 0; i < this.itemTypes.length; ++i) {
                            if (this.itemTypes[i].name == "Searcher") {
                                this.defaultItemTypeId = this.itemTypes[i].id;
                            }
                        }
                    } else {
                        console.log(response)
                    }
                    resolve(null)
                })
            }))

            Promise.all(promises).then(() => {
                this.updateInvoiceTotal()
                if (this.createOrderUuid || this.invoiceId) {
                    var matchedBillToCompany = false
                    for (var i = 0; i < this.uniqueClientCompanies.length; ++i) {
                        if (this.uniqueClientCompanies[i].id == this.billToCompanyFC.value) {
                            matchedBillToCompany = true
                            break
                        }
                    }
                    if (!matchedBillToCompany) {
                        var companyName
                        if (this.createOrderUuid) {
                            companyName = this.order.bill_to_company_name
                        } else {
                            companyName = this.forCompanyName
                        }
                        if (companyName && this.billToCompanyFC.value) {
                            this.uniqueClientCompanies.push({
                                name: companyName,
                                id: this.billToCompanyFC.value
                            })
                        }
                    }
                }
                this.uniqueClientCompanies.sort((a, b) => { return a.name.localeCompare(b.name) })
                this.initialized = true;
            })
        });
    }

    get themeService() {
        return this._themeService;
    }

    addItem() {
        this.itemTypeFCSets.push({
            typeFC: new UntypedFormControl(this.defaultItemTypeId),
            descriptionFC: new UntypedFormControl(),
            quantityFC: new UntypedFormControl(1),
            unitPriceFC: new UntypedFormControl(),
            taxableFC: new UntypedFormControl(1),
            amount: 0,
        })
    }

    removeItem(index) {
        if (this.itemTypeFCSets[index].detailId) {
            this.removedItems.push(this.itemTypeFCSets[index].detailId)
        }
        this.itemTypeFCSets.splice(index, 1)
        this.updateInvoiceTotal()
    }

    padTimeNumber(num) {
        var numString = num.toString()
        while (numString.length < 2) {
            numString = "0" + numString
        }
        return numString
    }

    create() {
        if (!this.dueDateFC.value) {
            this.error = "Please specify a due date."
            return;
        }

        var uuid;
        var order_id;
        if (this.order) {
            uuid = this.order.uuid;
            order_id = this.order.order_id;
        }

        var currentTime = new Date()
        var invoiceDate = this.invoiceDateFC.value + " "
            + this.padTimeNumber(currentTime.getHours()) + ":" 
            + this.padTimeNumber(currentTime.getMinutes()) + ":" 
            + this.padTimeNumber(currentTime.getSeconds())

        var dueDate = this.dueDateFC.value;
        var taxRate = this.taxRateFC.value;
        if (taxRate) {
            taxRate /= 100;
        }
        var discount = this.discountFC.value;
        if (discount) {
            discount /= 100;
        }

        var invoiceObject = {
            order_uuid: uuid,
            order_id: order_id,
            po_number: this.poNumberFC.value,
            invoice_date: invoiceDate,
            payment_term_id: this.paymentTermFC.value,
            due_date: dueDate,
            bill_to_company_id: this.billToCompanyFC.value,
            tax_rate: taxRate,
            tax_location: this.taxLocationFC.value,
            tax_code: this.taxCodeFC.value,
            discount: discount,
            currency_id: this.currencyFC.value,
            subject: this.subjectFC.value,
            status: this.statusValue,
            notes: this.notesFC.value,
            invoice_items: [],
            removed_invoice_items: this.removedItems,
        }

        for (var i = 0; i < this.itemTypeFCSets.length; ++i) {
            invoiceObject.invoice_items.push({
                type: this.itemTypeFCSets[i].typeFC.value,
                description: this.itemTypeFCSets[i].descriptionFC.value,
                quantity: this.itemTypeFCSets[i].quantityFC.value,
                unit_price: this.itemTypeFCSets[i].unitPriceFC.value,
                taxable: this.itemTypeFCSets[i].taxableFC.value,
                order_detail_id: this.itemTypeFCSets[i].detailId,
            })
        }

        this.invoiceService.createInvoice(invoiceObject).subscribe((response) => {
            if (response.success) {
                var snack = "Invoice created."
                this._snackBar.open(snack, null, {
                    duration: 2000,
                });
                this.router.navigate(['invoice-view'], { queryParams: { invoiceId: response.invoice_uuid } })
            } else {
                console.log(response)
            }
        })
    }

    error
    update() {
        if (!this.dueDateFC.value) {
            this.error = "Please specify a due date."
            return;
        }
        this.error = null
        var invoiceDate = this.invoiceDateFC.value;
        var dueDate = this.dueDateFC.value;
        var taxRate = this.taxRateFC.value;
        if (taxRate) {
            taxRate /= 100;
        }
        var discount = this.discountFC.value;
        if (discount) {
            discount /= 100;
        }

        var invoiceObject = {
            id: this.invoiceId,
            order_uuid: this.editOrderId,
            po_number: this.poNumberFC.value,
            invoice_date: invoiceDate,
            payment_term_id: this.paymentTermFC.value,
            due_date: dueDate,
            bill_to_company_id: this.billToCompanyFC.value,
            tax_rate: taxRate,
            tax_location: this.taxLocationFC.value,
            tax_code: this.taxCodeFC.value,
            discount: discount,
            currency_id: this.currencyFC.value,
            subject: this.subjectFC.value,
            status: this.statusValue,
            notes: this.notesFC.value,
            invoice_items: [],
            removed_invoice_items: this.removedItems,
        }

        for (var i = 0; i < this.itemTypeFCSets.length; ++i) {
            invoiceObject.invoice_items.push({
                type: this.itemTypeFCSets[i].typeFC.value,
                description: this.itemTypeFCSets[i].descriptionFC.value,
                quantity: this.itemTypeFCSets[i].quantityFC.value,
                unit_price: this.itemTypeFCSets[i].unitPriceFC.value,
                taxable: this.itemTypeFCSets[i].taxableFC.value,
                order_detail_id: this.itemTypeFCSets[i].detailId,
            })
        }

        this.invoiceService.updateInvoice(invoiceObject).subscribe((response) => {
            if (response.success) {
                var snack = "Invoice updated."
                this._snackBar.open(snack, null, {
                    duration: 2000,
                });
                if (this.onSaveNavigate) {
                    this.onSaveNavigate()
                } else {
                    this.router.navigate(["/invoice-list"])
                }
            } else {
                console.log(response)
            }
        })
    }

    untaxable_subtotal
    taxable_subtotal
    discount_amount
    tax_amount
    amount_due

    updateInvoiceTotal() {
        this.untaxable_subtotal = 0;
        this.taxable_subtotal = 0;
        this.discount_amount = 0;
        this.tax_amount = 0;
        this.amount_due = 0;
        for (var i = 0; i < this.itemTypeFCSets.length; ++i) {
            if (this.itemTypeFCSets[i].taxableFC.value) {
                this.taxable_subtotal += this.itemTypeFCSets[i].amount
            } else {
                this.untaxable_subtotal += this.itemTypeFCSets[i].amount
            }
        }

        if (this.discountFC.value) {
            this.discount_amount = (this.taxable_subtotal + this.untaxable_subtotal) * this.discountFC.value / 100
        }
        if (this.taxRateFC.value) {
            var taxableDiscounted = this.taxable_subtotal;
            if (this.discountFC.value) {
                taxableDiscounted -= taxableDiscounted * this.discountFC.value / 100
            }
            this.tax_amount = taxableDiscounted * this.taxRateFC.value / 100
        }
        this.amount_due = this.taxable_subtotal + this.untaxable_subtotal - this.discount_amount + this.tax_amount
    }

    taxableClick(itemTypeFCSet, e) {
        itemTypeFCSet.taxableFC.setValue(e.target.checked ? 1 : 0)
        this.changeQuantityOrUnitPrice(itemTypeFCSet)
    }

    changeQuantityOrUnitPrice(itemTypeFCSet) {
        itemTypeFCSet.amount = itemTypeFCSet.quantityFC.value * itemTypeFCSet.unitPriceFC.value;
        if (!itemTypeFCSet.amount || Number.isNaN(itemTypeFCSet.amount)) {
            itemTypeFCSet.amount = 0;
        }
        this.updateInvoiceTotal()
    }

    cancel() {
        if (this.cancelFunction) {
            this.cancelFunction();
        } else {
            this.router.navigate(["invoice-list"])
        }
    }

    paymentTermChange() {
        this.setDueDate()
    }

    billToCompanyChange() {
        this.updateBillToCompanyPromise(this.billToCompanyFC.value)
    }

    updateBillToCompanyPromise(billToCompanyId) {
        return new Promise((resolve, reject) => {

            this.companyService.getCompanyById(billToCompanyId).subscribe((response) => {
                if (response.result && response.result.length) {
                    this.paymentTermFC.setValue(response.result[0].default_payment_term_id)
                    this.internalNotes = response.result[0].internal_billing_notes
                }
                if (response.result && response.result.length && response.result[0].default_sales_tax_id) {
                    this.salesTaxService.getSalesTaxById(response.result[0].default_sales_tax_id).subscribe((response) => {
                        if (response.result && response.result.length > 0) {
                            this.taxLocationFC.setValue(response.result[0].jurisdiction_name)
                            this.taxRateFC.setValue(response.result[0].rate)
                            this.taxCodeFC.setValue(response.result[0].code)
                        }
                        resolve(null)
                    })
                } else {
                    resolve(null)
                }
            })
        })

    }
}
