import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { faMinus, faPlus, faSearch, faBan, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { ButtonComponent } from '../button/button.component';
import { DocumentsService } from '../documents.service';
import { JurisdictionService } from '../jurisdiction.service';
import { OrderService } from '../order.service';
import { ThemeService } from '../theme.service';
import { UserService } from '../user.service';
import { filter, map, catchError, throwError } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-document-search',
    templateUrl: './document-search.component.html',
    styleUrls: ['./document-search.component.scss']
})
export class DocumentSearchComponent implements OnInit {
    @ViewChild(ButtonComponent) private searchButton: ButtonComponent;
    faMinus = faMinus;
    faPlus = faPlus;
    faSearch = faSearch;
    faBan = faBan;
    faCaretUp = faCaretUp
    faCaretDown = faCaretDown

    initialized = false;

    allAnyFormControl = new UntypedFormControl("all");

    currentSearchCriteria
    initialSearch = false

    saveSearchCriteria() {
        var savedCriteria = []
        for (var i = 0; i < this.currentSearchCriteria.length; ++i) {
            savedCriteria.push({
                searchFieldControlIndex: this.currentSearchCriteria[i].searchFieldControlIndex.value,
                searchTypeIndex: this.currentSearchCriteria[i].searchTypeIndex.value,
                matchValue: this.currentSearchCriteria[i].matchValue.value,
                matchValueIndex: this.currentSearchCriteria[i].matchValueIndex.value,
            })
        }
        //console.log("saving search criteria", savedCriteria)
        localStorage.setItem("apexiem_document_search_criteria", JSON.stringify(savedCriteria))
    }

    loadSearchCriteria() {
        this.currentSearchCriteria = []
        var savedCriteria
        try {
            savedCriteria = JSON.parse(localStorage.getItem("apexiem_document_search_criteria"))

            //console.log("saved criteria", savedCriteria)

            for (var i = 0; i < savedCriteria.length; ++i) {
                this.currentSearchCriteria.push({
                    searchFieldControlIndex: new UntypedFormControl(savedCriteria[i].searchFieldControlIndex),
                    searchTypeIndex: new UntypedFormControl(savedCriteria[i].searchTypeIndex),
                    matchValue: new UntypedFormControl(savedCriteria[i].matchValue),
                    matchValueIndex: new UntypedFormControl(savedCriteria[i].matchValueIndex),
                })
            }
        } catch (e) {
        }
        if (!this.currentSearchCriteria || !this.currentSearchCriteria.length) {
            //console.log("resetted")
            this.currentSearchCriteria = [
                {
                    searchFieldControlIndex: new UntypedFormControl(0),
                    searchTypeIndex: new UntypedFormControl(1),
                    matchValue: new UntypedFormControl(""),
                    matchValueIndex: new UntypedFormControl(0),
                }
            ];
        } else {
            this.initialSearch = true
        }
        //console.log(JSON.stringify(this.currentSearchCriteria))
    }

    savePageAndSort() {
        localStorage.setItem("apexiem_document_search_page_sort", JSON.stringify({
            page: this.currentPage,
            sort: this.sort,
            sortDirection: this.sortDirection
        }))
    }

    loadPageAndSort() {
        var pageAndSort
        try {
            pageAndSort = JSON.parse(localStorage.getItem("apexiem_document_search_page_sort"))
        } catch (e) {
        }
        if (pageAndSort) {
            this.currentPage = pageAndSort.page
            this.sort = pageAndSort.sort
            this.sortDirection = pageAndSort.sortDirection
        }
    }

    searchFieldControls = [
        {
            name: "SBL",
            field: "v.sbl",
            suppressIs: false,
        }, {
            name: "State",
            values: [{ name: "NY" }],
            field: "v.state"
        }, {
            name: "County",
            field: "v.county",
        }, {
            name: "Address",
            field: "v.address",
        }, {
            name: "Description",
            field: "",
        }, {
            name: "Type",
            field: "v.mime_type",
        }, {
            name: "Filename",
            field: "v.file_name",
        }, {
            name: "Order #",
            field: "v.order_id",
        }, {
            name: "File #",
            field: "v.file_number"
        }, {
            name: "Created",
            field: "",
        }, {
            name: "Creator",
            field: "v.creator_name",
        }
    ];

    columnHeaders = [
        {
            name: "SBL",
            sortField: "v.sbl",
        }, {
            name: "State",
            values: [{ name: "NY" }],
            sortField: "v.state",
        }, {
            name: "County",
            field: "v.name",
            sortField: "v.name",
        }, {
            name: "Address",
            field: "v.address",
            sortField: "v.address",
        }, {
            name: "Description",
            field: "",
            sortField: "v.description",
        }, {
            name: "Type",
            field: "",
            sortField: "v.mime_type",
        }, {
            name: "Filename",
            field: "",
            sortField: "v.file_name",
        }, {
            name: "Order #",
            field: "v.order_id",
            sortField: "v.order_id",
        }, {
            name: "File #",
            field: "v.file_number",
            sortField: "v.file_number",
        }, {
            name: "Created",
            field: "",
            sortField: "v.created",
        }, {
            name: "Creator",
            field: "v.creator_name",
            sortField: "v.creator_name",
        }
    ];

    searchResults;
    searchResultCount;
    searchResultLimit;
    currentPage = 1;
    sort = null
    sortDirection = 0
    lastPage;
    searchTypeData;
    orderStatusNameById = {};

    constructor(
        private _themeService: ThemeService,
        private _jurisdictionService: JurisdictionService,
        //private _orderService: OrderService,
        private router: Router,
        private userService: UserService,
        private documentService: DocumentsService,
    ) { }

    ngOnInit() {
        this.loadSearchCriteria()
        this.loadPageAndSort()
        var countyControlIndex
        var orderServicesControlIndex
        var orderStatusesControlIndex


        for (var i = 0; i < this.searchFieldControls.length; ++i) {
            switch (this.searchFieldControls[i].name) {
                case "County":
                    countyControlIndex = i
                    break
                case "Status":
                    orderStatusesControlIndex = i
                    break
                case "Order Type":
                    orderServicesControlIndex = i
                    break
            }
        }

        var initPromises = [];

        initPromises.push(new Promise((resolve, reject) => {
            this._jurisdictionService.getCountyData().subscribe((response) => {
                if (response.success) {
                    (<any>this.searchFieldControls[countyControlIndex]).values = response.result;
                } else {
                    //console.log("error while getting county names");
                }
                resolve(null);
            });
        }));

        Promise.all(initPromises).then(() => {

            if (this.initialSearch) {
                this.doSearch()
            }
            this.initialized = true;

            //console.log("debug", this.searchFieldControls);
        }).catch(function (err) {
            console.log("init error", err);
        })
    }

    get themeService() {
        return this._themeService;
    }

    hasValues(control) {
        return control.hasOwnProperty("values");
    }

    getValues(control) {
        return control.values;
    }

    addSearchCriteria(index) {
        this.currentSearchCriteria.splice(index + 1, 0, {
            searchFieldControlIndex: new UntypedFormControl(0),
            searchTypeIndex: new UntypedFormControl(1),
            matchValue: new UntypedFormControl(""),
            matchValueIndex: new UntypedFormControl(0),
        });
    }

    removeSearchCriteria(index) {
        this.currentSearchCriteria.splice(index, 1);
        if (this.currentSearchCriteria.length == 0) {
            this.doClear();
        }
    }

    searchButtonClick() {
        this.setPage(1)
    }

    doSearch() {
        this.savePageAndSort()
        this.saveSearchCriteria()

        var companyType
        companyType = "provider"
        // if (this.userService.isCurrentPortal('Provider Portal')) {
        //     companyType = "provider"
        // } else {
        //     companyType = "client"
        // }

        if (this.searchButton) {
            this.searchButton.processing = true;
        }
        //console.log("search fields", this.currentSearchCriteria);

        var sortObj = null
        if (this.sort) {
            sortObj = {
                field: this.sort,
                direction: this.sortDirection,
            }
        }

        var searchCriteria = {
            allAny: this.allAnyFormControl.value,
            page: this.currentPage,
            sort: sortObj,
            fields: [],
            companyType: companyType,
        };

        for (var i = 0; i < this.currentSearchCriteria.length; ++i) {
            var field = this.searchFieldControls[this.currentSearchCriteria[i].searchFieldControlIndex.value].field
            if (!field) {
                field = this.searchFieldControls[this.currentSearchCriteria[i].searchFieldControlIndex.value].name.replace(/\s/g, "_").toLowerCase();
            }

            var matchValue = (<any>this.searchFieldControls[this.currentSearchCriteria[i].searchFieldControlIndex.value]).values ?
                (<any>this.searchFieldControls[this.currentSearchCriteria[i].searchFieldControlIndex.value]).values[this.currentSearchCriteria[i].matchValueIndex.value]
                : this.currentSearchCriteria[i].matchValue.value;

            if ((<any>this.searchFieldControls[this.currentSearchCriteria[i].searchFieldControlIndex.value]).name == "County") {
                matchValue = (<any>this.searchFieldControls[this.currentSearchCriteria[i].searchFieldControlIndex.value]).values[this.currentSearchCriteria[i].matchValueIndex.value].name;
            }

            if ((<any>this.searchFieldControls[this.currentSearchCriteria[i].searchFieldControlIndex.value]).name == "Order Type") {
                matchValue = (<any>this.searchFieldControls[this.currentSearchCriteria[i].searchFieldControlIndex.value]).values[this.currentSearchCriteria[i].matchValueIndex.value].service_id;
            }

            if ((<any>this.searchFieldControls[this.currentSearchCriteria[i].searchFieldControlIndex.value]).name == "Status") {
                matchValue = (<any>this.searchFieldControls[this.currentSearchCriteria[i].searchFieldControlIndex.value]).values[this.currentSearchCriteria[i].matchValueIndex.value].id;
            }

            if ((<any>this.searchFieldControls[this.currentSearchCriteria[i].searchFieldControlIndex.value]).name == "State") {
                matchValue = (<any>this.searchFieldControls[this.currentSearchCriteria[i].searchFieldControlIndex.value]).values[this.currentSearchCriteria[i].matchValueIndex.value].name;
            }

            searchCriteria.fields.push({
                field: field,
                is: this.currentSearchCriteria[i].searchTypeIndex.value == 0 && !(<any>this.searchFieldControls[this.currentSearchCriteria[i].searchFieldControlIndex.value]).suppressIs,
                matchValue: matchValue,
                hasValues: !!(<any>this.searchFieldControls[this.currentSearchCriteria[i].searchFieldControlIndex.value]).values,
            });
        }
        //console.log("search criteria", searchCriteria);
        this.documentService.search(searchCriteria,).subscribe((response) => {
            console.log("search response", response);
            if (response.success) {
                this.searchResults = response.result;
                this.searchResultCount = response.count;
                this.searchResultLimit = response.limit;
                this.lastPage = Math.ceil(response.count / response.limit);
                if (this.searchButton) {
                    this.searchButton.processing = false;
                }
            } else {
                console.log(response);
            }
        });
    }

    doClear() {
        localStorage.removeItem("apexiem_document_search_criteria")
        localStorage.removeItem("apexiem_document_search_page_sort")
        this.currentSearchCriteria = [{
            searchFieldControlIndex: new UntypedFormControl(0),
            searchTypeIndex: new UntypedFormControl(1),
            matchValue: new UntypedFormControl(""),
            matchValueIndex: new UntypedFormControl(0),
        }];
        this.searchResults = null
    }

    setPage(page) {
        this.searchResults = null;
        this.currentPage = page;
        this.doSearch();
    }

    showingMessage() {
        var firstEntry = (((this.currentPage - 1) * this.searchResultLimit) + 1);
        if (this.searchResultCount == 0) {
            firstEntry = 0;
        }
        return "Showing " + firstEntry + " to " + (((this.currentPage - 1) * this.searchResultLimit) + this.searchResults.length)
            + " of " + this.searchResultCount;
    }

    doFirst() {
        this.setPage(1);
    }

    doPrevious() {
        this.setPage(this.currentPage - 1);
    }

    doNext() {
        this.setPage(this.currentPage + 1);
    }

    doLast() {
        this.setPage(this.lastPage);
    }

    getPageLinks() {
        var pageLinks = [];
        for (var i = this.currentPage - 5; i <= this.lastPage && pageLinks.length < 10; ++i) {
            if (i > 0) {
                pageLinks.push(i);
            }
        }
        for (var i = this.currentPage - 5; i > 0 && pageLinks.length < 10; --i) {
            pageLinks.unshift(i);
        }
        return pageLinks;
    }

    getSearchTypeDisplay(order) {
        var services = this.searchTypeData;
        for (var property in services) {
            if (services.hasOwnProperty(property)) {
                if (services[property].id == order.primary_service_id) {
                    return services[property].name;
                }
                if (services[property].services) {
                    for (var j = 0; j < services[property].services.length; ++j) {
                        if (services[property].services[j].id == order.primary_service_id) {
                            //return services[property].name + ": " + services[property].services[j].name;
                            return services[property].services[j].name;
                        }
                    }
                }
            }
        }
    }

    doSort(fieldName) {
        if (this.sort == fieldName) {
            this.sortDirection = 1 - this.sortDirection
        } else {
            this.sort = fieldName
            this.sortDirection = 1
        }
        this.doSearch()
    }

    download(doc)
    {
      const id = doc.guid;
      if (!id) 
      {
        console.error("Document ID is missing");
        return;
      }
      console.log(id)
      this.documentService.download(id).pipe(
          filter(event => event instanceof HttpResponse),
          map((event: HttpResponse<Blob>) => event.body as Blob)
      ).subscribe({
        next: (body: Blob) => {
            if (!body) {
              console.error("No response body found");
              return;
            }
            const url = window.URL.createObjectURL(body);
            const link = document.createElement('a');
  
            link.href = url;
            link.download = doc.file_name;
            link.click();
  
            window.URL.revokeObjectURL(url);
        },
        error: (error) => {
            console.error("Download failed", error);
        }
      });
    }

    fieldChange(index) {
        this.currentSearchCriteria[index].searchTypeIndex.setValue(0)
    }

}
