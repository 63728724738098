import { Component, OnInit } from '@angular/core';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { CrudService } from '../crud.service';
import { ProductService } from '../product.service';

@Component({
    selector: 'app-teams',
    templateUrl: './teams.component.html',
})
export class TeamsComponent implements OnInit {
    faCaretUp = faCaretUp
    faCaretDown = faCaretDown
    title = "Teams"
    currentPage = 1
    count = 0
    limit = 25
    sortColumn = "name"
    sortDirection = false

    doSearch = new BehaviorSubject(null)

    displayColumns = [
        {
            name: "Name",
            field: "name",
        }
    ]

    setPage(page) {
        this.currentPage = page
        this.doSearch.next(null)
    }

    doSort(column) {
        if (this.sortColumn == column) {
            this.sortDirection = !this.sortDirection
        } else {
            this.sortColumn = column
            this.sortDirection = true
        }
        this.doSearch.next(null)
    }

    teams$ = this.doSearch.pipe(
        switchMap(() => {
            return this.crudService.search({
                type: "data_team",
                where: "company_id = ?",
                whereArgs: [this.authService.companyId],
                limit: this.limit,
                offset: (this.currentPage - 1) * this.limit,
                sort: [
                    {
                        columnName: this.sortColumn,
                        isDescending: this.sortDirection
                    }
                ]
            })
        }),
        tap((response) => {
            //console.log("vendors response", response)
            this.count = response.count
        }),
        map((response) => {
            return response.result
        })
    )

    constructor(
        private crudService: CrudService,
        private authService: AuthService,
    ) { }

    ngOnInit() {
    }

}
