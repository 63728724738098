<div class="container-fluid mt-5">
    <div class="row">
        <div class="col" align="center">
            <img src="assets/search-it-logo-vertical.png" />
        </div>
    </div>
</div>

<div class="container-fluid mt-5 shadow" style="width: 400px; border-radius: .5em;"
    [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.secondaryColor">
    <form (submit)="loginUser($event)">
        <div class="row mt-2">
            <div class="col mt-3 bold" style="font-size: 1.2em;" align="center">
                Welcome back.
            </div>
        </div>
        <div class="row mt-2">
            <div class="col bold" style="font-size: 1em;" align="center">
                Please log in to your account.
            </div>
        </div>
        <div *ngIf="loginFailure" class="row mt-3">
            <div class="col validation-error-banner" align="center">
                Invalid login credentials!
            </div>
        </div>
        <div *ngIf="loginError" class="row mt-3">
            <div class="col" align="center">
                An error occurred while attempting to log in.
            </div>
        </div>
        <div *ngIf="loginMessage" class="row mt-3">
            <div class="col" align="center">
                {{loginMessage}}
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col-12 col-md-10 bold">
                Username
            </div>
            <div class="col-1 d-none d-md-block">
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col-12 col-md-10 bold">
                <div class="username-wrapper">
                    <input type="text" class="login-input-style" style="width: 100%;" placeholder="Enter username"
                        id="username" value="{{loginUsername}}" #username maxlength="255" />
                    <fa-icon [icon]="faEnvelope" class="errspan"></fa-icon>
                </div>
            </div>
            <div class="col-1 d-none d-md-block">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col-12 col-md-10 bold">
                Password
            </div>
            <div class="col-1 d-none d-md-block">
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col-12 col-md-10 bold">
                <input type="password" class="login-input-style" style="width: 100%;" placeholder="Enter password"
                    id="password" #password />
                <fa-icon [icon]="faLock" class="errspan"></fa-icon>
            </div>
            <div class="col-1 d-none d-md-block">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col-6 col-md-5">
                <input type="checkbox" class="checkbox-style" id="rememberme" [checked]="!!loginUsername" name="cb"
                    (change)="remembermeChanged($event)" /><label for="rememberme" style="font-size: .8em;">Remember
                    me</label>
            </div>
            <div class="col-6 col-md-5" align="right">
                <a class="mx-1 link-rebrand" routerLink="/forgot-password" style="font-size: .8em;">Forgot
                    Password?</a>
            </div>
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col" align="center">
            </div>
        </div>
        <div class="row">
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col-12 col-md-10 bold">
                <input class="action-button action-button_medium mt-4 mb-1" type="submit" value="LOG IN" #loginButton
                    style="width: 100%" />
            </div>
            <div class="col-1 d-none d-md-block">
            </div>
        </div>
    </form>
    <div class="row mt-4">
        <div class="col mb-4" align="center" style="font-size: .8em;">
            Don't have an account?
            <span class="mx-1 link-rebrand" (click)="signupClick()">Sign up
                now</span>
        </div>
    </div>
    <div class="row mt-2" *ngIf="destination">
        <div class="col mb-4" align="center">
            After logging in, you will be redirected to {{destination}}
        </div>
    </div>
</div>