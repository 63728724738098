<div *ngIf="companyValues$ | async as company" class="container-fluid mt-4 py-2 search-panel">
    <div class="row edit-height bottom-lightgrey-border">
        <div class="col-2">
        </div>
        <div class="col-8 pb-2 bold" style="font-size: 1.6em;">
            Customer Company Information
        </div>
    </div>

    <div class="row bottom-lightgrey-border pb-3">
        <div class="col-2">
        </div>
        <div class="col-8">
            <div class="row">
                <div class="col">
                    <app-input-text label="Client Name" [fc]="nameFC">
                    </app-input-text>

                    <app-input-text label="Address" [fc]="addressFC">
                    </app-input-text>

                    <app-input-text label="Phone" [fc]="phoneFC">
                    </app-input-text>

                    <app-input-text label="Fax" [fc]="faxFC">
                    </app-input-text>

                </div>
                <div class="col">
                    <app-input-text label="Website" [fc]="websiteFC">
                    </app-input-text>

                    <app-input-enum label="Payment Terms" [fc]="paymentTermsFC" [enumValuesNameAndId]="dueDates">
                    </app-input-enum>

                    <app-input-text label="Email for invoices" [fc]="invoiceEmailFC">
                    </app-input-text>

                    <app-input-search label="Sales Tax Jurisdiction" [fc]="taxJurisdictionNameFC"
                        [searchFn]="searchJurisdictions.bind(this)"
                        [getItemDisplayValue]="getItemJurisdictionName.bind(this)"
                        [itemSelect]="selectJurisdiction.bind(this)">
                    </app-input-search>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <app-input-text-area label="Client Notes (internal)" [fc]="internalNotesFC">
                    </app-input-text-area>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-2">
        </div>
        <div class="col-8">
            <div class="row pt-3 pb-2">
                <div class="col pb-2" align="center">
                    <app-button [buttonType]="ButtonType.cancel" [clickFunction]="back.bind(this)">
                    </app-button>
                    <app-button [buttonType]="ButtonType.save" [clickFunction]="save.bind(this)">
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</div>