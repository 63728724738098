import { Component, OnInit } from '@angular/core';
import { WorkflowService } from '../workflow.service';
import { UntypedFormControl } from '@angular/forms';
import {
    faPlus, faChevronLeft, faBan, faSave, faFilePdf, faFlagCheckered, faUnlock, faMinus, faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { ButtonType } from '../button/button.component';

@Component({
    selector: 'app-run-sheet-data-entry',
    templateUrl: './run-sheet-data-entry.component.html',
})
export class RunSheetDataEntryComponent implements OnInit {
    faExclamationCircle = faExclamationCircle
    ButtonType = ButtonType
    initialized = false
    faPlus = faPlus
    faMinus = faMinus
    faChevronLeft = faChevronLeft
    faBan = faBan
    faSave = faSave
    faFilePdf = faFilePdf
    faFlagCheckered = faFlagCheckered
    faUnlock = faUnlock

    addFC = new UntypedFormControl()
    locationTypes
    findingTypes
    findingsByType = {}

    orderNumberFC = new UntypedFormControl()
    orderTypeFC = new UntypedFormControl()
    sblFC = new UntypedFormControl()
    clientRefFC = new UntypedFormControl()
    searchedFromFC = new UntypedFormControl()
    effectiveDateFC = new UntypedFormControl()

    ownerNameFC = new UntypedFormControl()
    propertyFC = new UntypedFormControl()
    countyFC = new UntypedFormControl()

    locked
    error

    constructor(
        private workflowService: WorkflowService,
        private _snackBar: MatSnackBar,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) { }

    getFindingTypesPromise() {
        return new Promise((resolve, reject) => {
            this.workflowService.getFindingTypes().subscribe((response) => {
                if (response.success) {
                    this.findingTypes = response.result
                }
                resolve(null)
            })
        })
    }

    getLocationTypesPromise() {
        return new Promise((resolve, reject) => {
            this.workflowService.getLocationTypes().subscribe((response) => {
                if (response.success) {
                    this.locationTypes = response.result
                }
                resolve(null)
            })
        })
    }

    initFromOrderHeader() {
        this.orderNumberFC.setValue(this.orderHeader.order_id)
        this.orderTypeFC.setValue(this.orderHeader.order_type)
        this.sblFC.setValue(this.orderHeader.sbl)
        this.clientRefFC.setValue(this.orderHeader.reference_number)

        this.ownerNameFC.setValue(this.orderHeader.property_seller_parsed)
        this.propertyFC.setValue(this.orderHeader.address)
        this.countyFC.setValue(this.orderHeader.county)
    }

    orderHeader
    getHeaderFromOrderPromise() {
        return new Promise((resolve, reject) => {
            this.workflowService.getRunSheetHeaderFromOrder(this.orderWorkflowUuid).subscribe((response) => {
                if (response.success) {
                    this.orderHeader = response.result[0]
                    //console.log("order header", this.orderHeader)
                    this.initFromOrderHeader()
                } else {
                    console.log("error while fetching order header")
                    console.log(response.message)
                    console.log(response.error)
                }
                resolve(null)
            })
        })
    }

    getHeaderDataPromise() {
        return new Promise((resolve, reject) => {
            this.workflowService.getRunSheetHeaderOverride(this.orderWorkflowUuid).subscribe((response) => {
                //console.log("header", response)
                if (response.success) {
                    if (response.result.length == 0) {
                        return resolve(null)
                    }

                    if (response.result.length > 1) {
                        console.log(response)
                        throw new Error("more than one tax search header override result encountered")
                    }
                    this.ownerNameFC.setValue(response.result[0].owner_name)
                    this.propertyFC.setValue(response.result[0].property)
                    this.countyFC.setValue(response.result[0].county)
                    if (response.result[0].searched_from) {
                        this.searchedFromFC.setValue(formatDate(response.result[0].searched_from, 'yyyy-MM-dd', 'en'))
                    }
                    if (response.result[0].effective_date) {
                        this.effectiveDateFC.setValue(formatDate(response.result[0].effective_date, 'yyyy-MM-dd', 'en'))
                    }
                    if (response.result[0].locked) {
                        this.locked = true
                    }
                } else {
                    console.log(response)
                }
                resolve(null)
            })
        })
    }

    getFindingsPromise() {
        //workflowFindings
        return new Promise((resolve, reject) => {
            this.workflowService.getRunSheetFindings(this.orderWorkflowUuid).subscribe((response) => {
                if (response.success) {
                    //console.log("workflow id", this.orderWorkflowUuid)
                    //console.log("findings", response)
                    for (var i = 0; i < response.result.length; ++i) {
                        var finding = response.result[i]
                        if (!this.findingsByType[finding.finding_type_id]) {
                            this.findingsByType[finding.finding_type_id] = []
                        }

                        this.findingsByType[finding.finding_type_id].push({
                            name1FC: new UntypedFormControl(finding.name_1),
                            name2FC: new UntypedFormControl(finding.name_2),
                            date1FC: new UntypedFormControl(finding.date_1_timestamp ? finding.date_1_timestamp * 1000 : null),
                            date2FC: new UntypedFormControl(finding.date_2_timestamp ? finding.date_2_timestamp * 1000 : null),
                            deedTypeFC: new UntypedFormControl(finding.deed_type),
                            amountFC: new UntypedFormControl(finding.amount),
                            locationTypeFC: new UntypedFormControl(finding.location_type_label),
                            locationFC: new UntypedFormControl(finding.location),
                            lifeEstateFC: new UntypedFormControl(finding.life_estate),
                            assumedMortgageFC: new UntypedFormControl(finding.assumed_mortgage),
                            findingNotesFC: new UntypedFormControl(finding.finding_notes),
                            editable: false,
                        })
                    }

                } else {
                    console.log("error while fetching findings", response)
                }
                resolve(null)
            })
        })
    }

    orderUuid
    getOrderIdParamPromise() {
        return new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe(params => {
                this.orderUuid = params.orderUuid
                resolve(this.orderUuid)
            })
        })
    }

    orderWorkflowUuid
    getOrderWorkflowIdParamPromise() {
        return new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe(params => {
                this.orderWorkflowUuid = params.orderWorkflowUuid
                resolve(this.orderWorkflowUuid)
            })
        })
    }

    ngOnInit() {
        this.getOrderIdParamPromise().then(() => {
            return this.getOrderWorkflowIdParamPromise()
        }).then(() => {
            return this.getHeaderFromOrderPromise()
        }).then(() => {
            return Promise.all([
                this.getFindingTypesPromise(),
                this.getLocationTypesPromise(),
                this.getHeaderDataPromise(),
                this.getFindingsPromise(),
            ])
        }).then(() => {
            this.initialized = true
        })
    }

    addError
    add() {
        if (!this.addFC.value) {
            this.addError = "Please select a type"
            return
        }
        this.addError = null

        if (!this.findingsByType[this.addFC.value]) {
            this.findingsByType[this.addFC.value] = []
        }
        this.findingsByType[this.addFC.value].push({
            name1FC: new UntypedFormControl(),
            name2FC: new UntypedFormControl(),
            date1FC: new UntypedFormControl(),
            date2FC: new UntypedFormControl(),
            deedTypeFC: new UntypedFormControl(),
            amountFC: new UntypedFormControl(),
            locationTypeFC: new UntypedFormControl(),
            locationFC: new UntypedFormControl(),
            lifeEstateFC: new UntypedFormControl(),
            assumedMortgageFC: new UntypedFormControl(),
            findingNotesFC: new UntypedFormControl(),
            editable: true,
        })
        this.addFC.setValue(null)
    }

    addNotes(finding) {
        finding.findingNotesFC.setValue("")
    }

    back() {
        window.history.back();
    }

    cancel() {
        this._snackBar.open("Run Sheet Changes Cancelled", null, { duration: 2000, });
        window.history.back();
    }

    saveForLater() {
        this.clearError()

        if (!this.isValid()) {
            return
        }

        Promise.all([
            this.getSaveHeaderPromise(),
            this.getSaveFindingsPromise()
        ]).then(() => {
            this._snackBar.open("Run Sheet Saved For Later", null, { duration: 2000, });
            window.history.back();
        }).catch((err) => {
            this.setError("saving run sheet", err)
        })
    }

    previewPDF() {
        this.clearError()
        Promise.all([
            this.getSaveHeaderPromise(),
            this.getSaveFindingsPromise()
        ]).then(() => {
            this.workflowService.viewManualRunSheetPDF(this.orderWorkflowUuid)
        }).catch((err) => {
            this.setError("saving run sheet for pdf preview", err)
        })
    }

    setError(action, err) {
        this.error = "Error while " + action
        if (err.message) {
            this.error += ": " + err.message
        }
        console.log("error while " + action, err)
    }

    clearError() {
        this.error = null
    }
    anyError = false
    headerErrors = {}

    setFindingError(finding, field, errorText) {
        if (!finding.formErrors) {
            finding.formErrors = {}
        }
        finding.formErrors[field] = errorText
        this.anyError = true
    }

    clearFindingError(finding, field) {
        if (finding.formErrors) {
            delete finding.formErrors[field]
        }
    }

    checkFindingLength(finding, field, fieldLabel, length) {
        console.log("ibjibble", field, "dffdfd", finding[field + "FC"])
        if (finding[field + "FC"].value && finding[field + "FC"].value.length > length) {
            this.setFindingError(finding, field, fieldLabel + " must have " + length + " characters or fewer.")
        } else {
            this.clearFindingError(finding, field)
        }
    }

    isValid() {
        this.anyError = false

        if (this.ownerNameFC.value && this.ownerNameFC.value.length > 255) {
            this.headerErrors["ownerName"] = "Owner name must have 255 characters or fewer."
            this.anyError = true
        } else {
            delete this.headerErrors["ownerName"]
        }

        if (this.propertyFC.value && this.propertyFC.value.length > 255) {
            this.headerErrors["property"] = "Property must have 255 characters or fewer."
            this.anyError = true
        } else {
            delete this.headerErrors["property"]
        }

        if (this.countyFC.value && this.countyFC.value.length > 255) {
            this.headerErrors["county"] = "County must have 255 characters or fewer."
            this.anyError = true
        } else {
            delete this.headerErrors["county"]
        }

        for (var i = 0; i < this.findingTypes.length; ++i) {
            if (this.findingsByType[this.findingTypes[i].id]) {
                for (var j = 0; j < this.findingsByType[this.findingTypes[i].id].length; ++j) {
                    var finding = this.findingsByType[this.findingTypes[i].id][j]
                    this.checkFindingLength(finding, "name1", "Name", 255)
                    this.checkFindingLength(finding, "name2", "Name", 255)
                    this.checkFindingLength(finding, "deedType", "Deed Type", 255)
                    this.checkFindingLength(finding, "locationType", "Location Type", 255)
                    this.checkFindingLength(finding, "location", "Location", 255)
                    this.checkFindingLength(finding, "lifeEstate", "Life Estate", 255)
                    this.checkFindingLength(finding, "assumedMortgage", "Assumed Mortgage", 255)
                    this.checkFindingLength(finding, "findingNotes", "Notes", 2048)
                }
            }
        }
        return !this.anyError
    }

    finish() {
        this.clearError()

        if (!this.isValid()) {
            return
        }

        this.locked = true
        Promise.all([
            this.getSaveHeaderPromise(),
            this.getSaveFindingsPromise()
        ]).then(() => {
            this.workflowService.uploadManualRunSheetPDF(this.orderWorkflowUuid).subscribe((response) => {
                if (response.success) {
                    this._snackBar.open("Run Sheet Finished", null, { duration: 2000, });
                    this.router.navigate(["searcher-order-edit"], { queryParams: { orderUuid: this.orderUuid } })
                } else {
                    console.log("error while uploading run sheet pdf", response)
                }
            })
        }).catch((err) => {
            this.setError("finishing run sheet", err)
        })
    }

    unlock() {
        this.clearError()
        this.workflowService.manualRunSheetUnlock(this.orderWorkflowUuid).subscribe((response) => {
            if (response.success) {
                this._snackBar.open("Run Sheet Unlocked", null, { duration: 2000, });
                this.locked = false;
            } else {
                console.log(response)
                this.setError("unlocking run sheet", response.error)
            }
        })
    }

    getSaveHeaderPromise() {
        var headerRequestObject = {
            order_workflow_uuid: this.orderWorkflowUuid,
            owner_name: this.ownerNameFC.value,
            property: this.propertyFC.value,
            county: this.countyFC.value,
            searched_from: this.searchedFromFC.value,
            effective_date: this.effectiveDateFC.value,
            locked: this.locked,
        }
        //console.log("save header request", headerRequestObject)
        return new Promise((resolve, reject) => {
            this.workflowService.saveRunSheetHeader(headerRequestObject).subscribe((response) => {
                if (response.success) {
                    resolve(null)
                } else {
                    reject(response)
                }
            })
        })
    }

    getSaveFindingsPromise() {
        var requestObj = {
            order_workflow_uuid: this.orderWorkflowUuid,
            findings: []
        }
        for (var i = 0; i < this.findingTypes.length; ++i) {
            if (this.findingsByType[this.findingTypes[i].id]) {
                for (var j = 0; j < this.findingsByType[this.findingTypes[i].id].length; ++j) {
                    var finding = this.findingsByType[this.findingTypes[i].id][j]

                    var date1 = finding.date1FC.value;
                    if (date1) {
                        date1 /= 1000;
                    }
                    var date2 = finding.date2FC.value;
                    if (date2) {
                        date2 /= 1000;
                    }

                    requestObj.findings.push({
                        type_id: this.findingTypes[i].id,
                        name_1: finding.name1FC.value,
                        name_2: finding.name2FC.value,
                        date_1: date1,
                        date_2: date2,
                        deed_type: finding.deedTypeFC.value,
                        amount: finding.amountFC.value,
                        location_type_label: finding.locationTypeFC.value,
                        location: finding.locationFC.value,
                        life_estate: finding.lifeEstateFC.value,
                        assumed_mortgage: finding.assumedMortgageFC.value,
                        finding_notes: finding.findingNotesFC.value,
                    })
                }
            }
        }

        return new Promise((resolve, reject) => {
            //console.log("save findings request", requestObj)
            this.workflowService.saveRunSheetFindings(requestObj).subscribe((response) => {
                if (response.success) {
                    resolve(null)
                } else {
                    reject(response)
                }
            })
        })
    }

    edit(finding) {
        finding.editable = true
    }

    delete(typeId, i) {
        this.findingsByType[typeId].splice(i, 1)
        if (this.findingsByType[typeId].length == 0) {
            delete this.findingsByType[typeId]
        }
    }
}
