<div *ngIf="format == InputCurrencyFormat.verticalLabel" class="row mt-3">
    <div class="col bold">
        {{label}}
        <span *ngIf="tooltip" data-toggle="tooltip" data-placement="right" [title]="tooltip" class="saq-tooltip ml-1">
            <fa-icon [icon]="faInfoCircle">
            </fa-icon>
        </span>
    </div>
</div>
<div *ngIf="format == InputCurrencyFormat.verticalLabel" class="row mt-1">
    <div *ngIf="!disabled" class="col">
        <div class="input-group">
            <div class="input-group-addon">$</div>
            <input type="number" class="form-control" [formControl]="fc" (change)="doChange()" />
            <div class="input-group-addon">.00</div>
        </div>
    </div>
    <div *ngIf="disabled" class="col">
        {{fc.value}}
    </div>
</div>

<div *ngIf="format == InputCurrencyFormat.horizontalLabel" class="row">
    <div class="col-4 unedit-padding bold">
        {{label}}
    </div>
    <div class="col-8">
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">$</span>
            </div>
            <input type="number" class="form-control" [formControl]="fc" (change)="doChange()" />
        </div>
    </div>
</div>

<div *ngIf="format == InputCurrencyFormat.none" class="input-group">
    <div class="input-group-prepend">
        <span class="input-group-text">$</span>
    </div>
    <input type="number" class="form-control" [formControl]="fc" (change)="doChange()" />
</div>