import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { SysCounty } from "../model/sys-county.model";
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class CountyService
{

    constructor(private http: HttpClient) { 
        // Nothing to do
    }

    //update counties
    updateCounty(county: SysCounty): Observable<any> {
        console.log(county)
        return this.http.put<any>("api/updateCounty", {county: county})
    }


    //get county list
    getCounties(): Observable<any> {
        return this.http.get<any>("api/getCounties")
    }
}