import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';
import { OrderService } from '../order.service';
import { UntypedFormControl } from '@angular/forms';
import { JurisdictionService } from '../jurisdiction.service';
import { AddressService } from '../address.service';
import { TitleCasePipe } from '@angular/common';
import { faCheck, faSave, faBan } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackRouteService } from '../snack-route.service';
import { ButtonType } from '../button/button.component';

@Component({
    selector: 'app-searcher-order-edit-sbl',
    templateUrl: './searcher-order-edit-sbl.component.html',
})
export class SearcherOrderEditSblComponent implements OnInit {
    ButtonType = ButtonType
    cacheOrder
    initialized = false
    stateFC = new UntypedFormControl("NY");
    countyFC = new UntypedFormControl();
    addressFC = new UntypedFormControl();
    sblFC = new UntypedFormControl();

    yearBuiltFC = new UntypedFormControl();
    municipalityFC = new UntypedFormControl();
    swisCodeFC = new UntypedFormControl();
    schoolDistrictFC = new UntypedFormControl();
    schoolDistrictSwisFC = new UntypedFormControl();
    propertyTypeFC = new UntypedFormControl();
    landUseFC = new UntypedFormControl();
    deedBookFC = new UntypedFormControl()
    deedPageFC = new UntypedFormControl()

    faCheck = faCheck
    faSave = faSave
    faBan = faBan

    stateEnumValues = ["NY"]
    propertyTypeEnumValues = [
        "Residential",
        "Commercial",
    ];
    searchedItem;

    constructor(
        private _themeService: ThemeService,
        private _orderService: OrderService,
        private _jurisdictionService: JurisdictionService,
        private _addressService: AddressService,
        private titleCase: TitleCasePipe,
        private router: Router,
        private snackRouteService: SnackRouteService,
        private activatedRoute: ActivatedRoute,
    ) { }

    orderUuid
    getOrderIdParamPromise() {
        return new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe(params => {
                this.orderUuid = params.orderUuid
                resolve(this.orderUuid)
            })
        })
    }

    ngOnInit() {
        this.getOrderIdParamPromise().then(() => {

            this._jurisdictionService.getJurisdictionData().subscribe((response) => {
                this._jurisdictionService.setJurisdictionData(response);

                this._orderService.getOrder(this.orderUuid).subscribe((response) => {
                    this.cacheOrder = response.result
                    //console.log(this.cacheOrder)

                    this.countyFC.setValue(this.cacheOrder.name)
                    this.addressFC.setValue(this.cacheOrder.address)
                    this.sblFC.setValue(this.cacheOrder.sbl)

                    this.yearBuiltFC.setValue(this.cacheOrder.year_built)
                    this.municipalityFC.setValue(this.cacheOrder.municipality)
                    this.swisCodeFC.setValue(this.cacheOrder.real_property_code)
                    this.schoolDistrictSwisFC.setValue(this.cacheOrder.school_district_swis)
                    this.propertyTypeFC.setValue(this.cacheOrder.property_type)
                    this.landUseFC.setValue(this.cacheOrder.land_use)
                    this.deedBookFC.setValue(this.cacheOrder.deed_book)
                    this.deedPageFC.setValue(this.cacheOrder.deed_page)

                    this._jurisdictionService.getSchoolDistrictName(this.schoolDistrictSwisFC.value).subscribe((response) => {
                        if (response.success) {
                            this.schoolDistrictFC.setValue(response.result);
                        }
                        this.initialized = true
                    })
                })
            })
        })
    }

    get themeService() {
        return this._themeService;
    }

    get orderService() {
        return this._orderService;
    }

    get jurisdictionService() {
        return this._jurisdictionService;
    }

    get addressService() {
        return this._addressService;
    }

    getAddressItemDisplay(item) {
        if (item.unselectable) {
            return item.name;
        }
        return this.titleCase.transform(item.property_full_address + ", " + item.property_town_municipality);
    }

    sblItemSelect(item) {
        this.searchedItem = item;
    }

    propertyItemSelect(item) {
        this.addressFC.setValue(this.getAddressItemDisplay(item));
        this.yearBuiltFC.setValue(item.year_built);
        this.municipalityFC.setValue(this.titleCase.transform(item.property_town_municipality));
        this.swisCodeFC.setValue(item.county_number_code);
        this.schoolDistrictFC.setValue(item.school_district_long_description);
        this.schoolDistrictSwisFC.setValue(item.school_district_code);
        this._jurisdictionService.getSchoolDistrictName(this.schoolDistrictSwisFC.value).subscribe((response) => {
            if (response.success) {
                this.schoolDistrictFC.setValue(response.result);
            }
        })
        this.landUseFC.setValue(item.land_use_long_description)
        this.deedBookFC.setValue(item.deed_book)
        this.deedPageFC.setValue(item.deed_page)
    }

    searchSBL(term) {
        return this._addressService.searchSBL(this._jurisdictionService.getCountySwisByName(this.countyFC.value), term);
    }

    getSBLItemDisplay(item) {
        if (item.unselectable) {
            return item.name;
        }
        return item.print_key;
    }

    sblOk() {
        if (this.searchedItem) {
            this.propertyItemSelect(this.searchedItem)
        }
    }

    save() {
        var taxMapDataId;
        if (this.searchedItem) {
            taxMapDataId = this.searchedItem.id;
        } else {
            taxMapDataId = this.cacheOrder.tax_map_data_id;
        }

        this.orderService.updateOrderSBL(
            this.orderUuid,
            taxMapDataId,
            this.stateFC.value,
            this.countyFC.value,
            this.sblFC.value,
            this.addressFC.value,
            this.yearBuiltFC.value,
            this.swisCodeFC.value,
            this.municipalityFC.value,
            this.schoolDistrictSwisFC.value,
            this.schoolDistrictFC.value,
            this.propertyTypeFC.value,
            this.landUseFC.value,
            this.deedBookFC.value,
            this.deedPageFC.value
        ).subscribe((response) => {
            if (response.success) {
                this.snackRouteService.snack("Order SBL saved.")
                window.history.back();
            } else {
                console.log(response)
            }
        })
    }

    cancel() {
        this.snackRouteService.snack("Edit SBL cancelled.")
        window.history.back();
    }
}
