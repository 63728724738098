<div id="delete-confirm" class="modal">
    <div class="center p-3 confirm-box" align="center">
        <div class="mb-2">Are you sure you want to delete this draft order?</div>
        <app-button [buttonType]="ButtonType.delete" [clickFunction]="deleteConfirm.bind(this)">
        </app-button>
        <app-button [buttonType]="ButtonType.cancel" [clickFunction]="deleteCancel.bind(this)"></app-button>
    </div>
</div>
<div id="submission-error" class="modal">
    <div class="center p-3 confirm-box" align="center">
        <div class="mb-2">Error occurred: {{orderSubmissionError}}</div>
        <app-button label="Ok" [clickFunction]="orderSubmissionOk.bind(this)" [icon]="faCheck">
        </app-button>
    </div>
</div>

<div class="container-fluid bordered-container-below light-blue-bordered pt-4"
    [style.background-color]="themeService.tertiaryColor">
    <div *ngIf="error" class="row mb-2 validation-error-banner py-2">
        <div class="col bold" align="center">
            <fa-icon [icon]="faExclamationCircle"></fa-icon>
            {{error}}
        </div>
    </div>

    <app-button-group-wizard-first *ngIf="isFirstView()" [cancel]="cancel.bind(this)" [save]="saveForLater.bind(this)"
        [next]="advance.bind(this)" [delete]="showDeleteButton() ? delete.bind(this) : null" [isAgent]="is_agent"></app-button-group-wizard-first>

    <app-button-group-wizard-interim *ngIf="!isFirstView() && !isLastView()" [cancel]="cancel.bind(this)"
        [save]="saveForLater.bind(this)" [next]="advance.bind(this)" [previous]="back.bind(this)">
    </app-button-group-wizard-interim>

    <app-button-group-wizard-final *ngIf="isLastView()" [cancel]="cancel.bind(this)" [save]="submit.bind(this)"
        [previous]="back.bind(this)">
    </app-button-group-wizard-final>

    <div class="row pt-3"></div>

</div>