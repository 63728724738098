<span *ngIf="!initialized">
    <div class="container-fluid bordered-container-middle pt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.secondaryColor">
        Initializing...
    </div>
</span>

<span *ngIf="initialized && cacheOrder.order_type != 'Quick'">
    <app-order-create-step-circles [stepProvider]="orderService"></app-order-create-step-circles>
</span>

<div *ngIf="initialized" class="container-fluid bordered-container-middle light-blue-bordered"
    [style.color]="themeService.primaryColor" [style.background-color]="themeService.tertiaryColor">

    <div class="row pb-1">
        <div class="col pt-4 pb-3 attribute-h1 bold" align="center">
            Review
        </div>
    </div>
    <div class="row pb-1">
        <div class="col-2">
        </div>
        <div class="col-8">

            <span>
                <div class="row pb-1 mt-2">
                    <div class="col bold">
                        Client Information
                    </div>
                </div>
                <div class="row pb-1">
                    <div class="col-6">
                        Client Company
                    </div>
                    <div class="col-6">
                        {{cacheOrder.agent_company_name}}
                    </div>
                </div>
                <div class="row pb-1">
                    <div class="col-6">
                        Client Name
                    </div>
                    <div class="col-6">
                        {{cacheOrder.user_name}}
                    </div>
                </div>
                <div class="row pb-1">
                    <div class="col-6">
                        Client Email
                    </div>
                    <div class="col-6">
                        {{cacheOrder.user_email}}
                    </div>
                </div>

            </span>


            <div class="row pb-1 mt-2">
                <div class="col bold">
                    Property Information
                </div>
            </div>
            <div class="row pb-1">
                <div class="col-6">
                    Search Type
                </div>
                <div class="col-6">
                    {{getSearchTypeDisplay()}}
                </div>
            </div>
            <div *ngIf="cacheOrder.continuation_date" class="row pb-1">
                <div class="col-6">
                    Continuation Date
                </div>
                <div class="col-6">
                    {{cacheOrder.continuation_date | date: 'M/d/yy' }}
                </div>
            </div>
            <div class="row pb-1">
                <div class="col-6">
                    Reference Number
                </div>
                <div class="col-6">
                    {{cacheOrder.reference_number}}
                </div>
            </div>
            <div class="row pb-1">
                <div class="col-6">
                    State
                </div>
                <div class="col-6">
                    {{cacheOrder.state}}
                </div>
            </div>
            <div class="row pb-1">
                <div class="col-6">
                    County
                </div>
                <div class="col-6">
                    {{cacheOrder.name}}
                </div>
            </div>
            <div class="row pb-1">
                <div class="col-6">
                    Sbl
                </div>
                <div class="col-6">
                    {{cacheOrder.sbl}}
                </div>
            </div>
            <div class="row pb-1">
                <div class="col-6">
                    Address
                </div>
                <div class="col-6">
                    {{cacheOrder.address}}
                </div>
            </div>
            <div class="row pb-1">
                <div class="col-6">
                    Municipality
                </div>
                <div class="col-6">
                    {{cacheOrder.municipality}}
                </div>
            </div>
            <div class="row pb-1">
                <div class="col-6">
                    Swis Code
                </div>
                <div class="col-6">
                    {{cacheOrder.real_property_code}}
                </div>
            </div>
            <div class="row pb-1">
                <div class="col-6">
                    School District
                </div>
                <div class="col-6">
                    {{cacheOrder.school_district_name}}
                </div>
            </div>
            <span *ngFor="let seller of cacheOrder.property_seller; let isFirst = first">
                <div class="row pb-1">
                    <div class="col-6">
                        <span *ngIf="isFirst">
                            Property Seller
                        </span>
                    </div>
                    <div class="col-6">
                        {{seller}}
                    </div>
                </div>
            </span>
            <span *ngFor="let buyer of cacheOrder.property_buyer; let isFirst = first">
                <div class="row pb-1">
                    <div class="col-6">
                        <span *ngIf="isFirst">
                            Property Buyer
                        </span>
                    </div>
                    <div class="col-6">
                        {{buyer}}
                    </div>
                </div>
            </span>
            <span *ngFor="let assessedOwner of cacheOrder.assessed_owner; let isFirst = first">
                <div class="row pb-1">
                    <div class="col-6">
                        <span *ngIf="isFirst">
                            Assessed Owner
                        </span>
                    </div>
                    <div class="col-6">
                        {{assessedOwner}}
                    </div>
                </div>
            </span>

            <span *ngIf="hasPropertyServices()">
                <div class="row pb-1 mt-2">
                    <div class="col bold">
                        Property Services
                    </div>
                </div>
                <div class="row pb-1">
                    <div class="col">
                        <ul>
                            <li *ngFor="let service of objectKeys(cacheOrder.propertyServices); let isFirst = first">
                                {{serviceById[service]}}
                            </li>
                        </ul>
                    </div>
                </div>
            </span>

            <span *ngIf="hasEntityServices()">
                <div class="row pb-1 mt-2">
                    <div class="col bold">
                        Entity Services
                    </div>
                </div>
                <span *ngFor="let service of objectKeys(entityServices); let isFirst = first">
                    <div class="row pb-1 mt-2">
                        <div class="col">
                            {{serviceById[service]}}
                        </div>
                    </div>

                    <div class="row pb-1">
                        <div class="col-6">
                            <ul>
                                <li *ngFor="let item of entityServices[service]; let isFirst = first">
                                    {{item}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </span>
            </span>

            <span *ngIf="hasIndividualServices()">
                <div class="row pb-1 mt-2">
                    <div class="col bold">
                        Individual Services
                    </div>
                </div>
                <span *ngFor="let service of objectKeys(individualServices); let isFirst = first">
                    <div class="row pb-1 mt-2">
                        <div class="col">
                            {{serviceById[service]}}
                        </div>
                    </div>

                    <div class="row pb-1">
                        <div class="col-6">
                            <ul>
                                <li *ngFor="let item of individualServices[service]; let isFirst = first">
                                    {{item}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </span>
            </span>

            <div class="row pb-1 mt-2">
                <div class="col bold">
                    Notes
                </div>
            </div>
            <div class="row pb-1">
                <div class="col-6">
                    Searcher Property Notes
                </div>
                <div class="col-6">
                    {{cacheOrder.searcher_property_notes}}
                </div>
            </div>
            <div class="row pb-1">
                <div class="col-6">
                    Searcher Entity Notes
                </div>
                <div class="col-6">
                    {{cacheOrder.searcher_entity_notes}}
                </div>
            </div>
            <div class="row pb-1">
                <div class="col-6">
                    Searcher Individual Notes
                </div>
                <div class="col-6">
                    {{cacheOrder.searcher_individual_notes}}
                </div>
            </div>
            <div class="row pb-1">
                <div class="col-6">
                    Searcher Notes
                </div>
                <div class="col-6">
                    {{cacheOrder.searcher_notifications_notes}}
                </div>
            </div>
            <div class="row pb-1 mt-2"
                *ngIf="cacheOrder.attached_documents && cacheOrder.attached_documents.length > 0">
                <div class="col bold">
                    Attached Files
                </div>
            </div>
            <span *ngIf="cacheOrder.attached_documents && cacheOrder.attached_documents.length > 0">
                <div class="row">
                    <div class="col" [style.border-color]="themeService.backgroundColor"
                        style="border-width: 1px; border-style: solid;">
                        <div class="row" [style.color]="themeService.textOnBackgroundColor"
                            [style.background-color]="themeService.backgroundColor">
                            <div class="col-3">
                                Description
                            </div>
                            <div class="col-3">
                                Type
                            </div>
                            <div class="col-3">
                                Filename
                            </div>
                            <div class="col-3">
                                Created
                            </div>
                        </div>
                        <div *ngFor="let doc of cacheOrder.attached_documents" class="row">
                            <div class="col-3 link" (click)="download(doc)" [style.color]="themeService.linkColor">
                                {{doc.description}}
                            </div>
                            <div class="col-3">
                                {{documentTypeToDisplay[doc.document_type]}}
                            </div>
                            <div class="col-3">
                                {{doc.filename}}
                            </div>
                            <div class="col-3">
                                {{doc.created | date: 'M/dd/yyyy'}}
                            </div>
                        </div>
                    </div>
                </div>
            </span>
        </div>
    </div>
</div>
<app-order-create-buttons></app-order-create-buttons>