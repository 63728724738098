import { Component, OnInit } from '@angular/core';
import { faCaretDown, faCaretUp, faStar } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { ContactsService } from '../contacts.service';
import { CrudService } from '../crud.service';


@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
})
export class ContactsComponent implements OnInit {
    faCaretUp = faCaretUp
    faCaretDown = faCaretDown
    title = "Contacts"
    currentPage = 1
    count = 0
    limit = 25
    sortColumn = "full_name"
    sortDirection = false
    faStar = faStar

    doSearch = new BehaviorSubject(null)

    displayColumns = [
        {
            name: "Email",
            field: "email_address",
        }, {
            name: "Full Name",
            field: "full_name",
        }, {
            name: "Company Name",
            field: "company_name",
        }, {
            name: "Type",
            field: "parent_company_relation_type",
        }, {
            name: "Rating",
            field: "rating",
        }
    ]

    setPage(page) {
        this.currentPage = page
        this.doSearch.next(null)
    }

    doSort(column) {
        if (this.sortColumn == column) {
            this.sortDirection = !this.sortDirection
        } else {
            this.sortColumn = column
            this.sortDirection = false
        }
        this.doSearch.next(null)
    }

    contacts$ = this.doSearch.pipe(
        switchMap(() => {
            return this.contactsService.getContacts(this.sortColumn, this.sortDirection, this.limit, (this.currentPage - 1) * this.limit)
        }),
        tap((response) => {
            //console.log("contacts response", response)
            this.count = response.count
        }),
        map((response) => {
            return response.result
        })
    )

    constructor(
        private contactsService: ContactsService,
    ) { }

    ngOnInit() {
    }

}
