import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breakingNonSpaceMod'
})
export class BreakingNonSpaceModPipe implements PipeTransform {

    transform(value: any, modValue: number): any {
        if (!value) {
            return "";
        }
        var stringRep = value.toString();
        var newString = "";
        for (var i = 0; i < stringRep.length; ++i) {
            if (i % modValue == 0) {
                newString += "&#8203;";
            }
            newString += stringRep.charAt(i);
        }
        const tempElement = document.createElement("div")
        tempElement.innerHTML = newString;
        return tempElement.innerText
    }
}
