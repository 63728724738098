import { Component, OnInit } from '@angular/core';
import { faBan, faChevronLeft, faChevronRight, faPlus, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ButtonType } from '../button/button.component';

@Component({
    selector: 'app-button-demo',
    templateUrl: './button-demo.component.html',
})
export class ButtonDemoComponent implements OnInit {
    faTrashAlt = faTrashAlt
    faSave = faSave
    faBan = faBan
    faChevronRight = faChevronRight
    faChevronLeft = faChevronLeft
    faPlus = faPlus
    ButtonType = ButtonType

    constructor() { }

    ngOnInit() {
    }

    doButtonTest() {
        console.log("button clicked")
    }

}
