import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ThemeRebrandService } from '../theme-rebrand.service';
import {
    faChevronLeft, faShare
} from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { ButtonType } from '../button/button.component';

@Component({
    selector: 'app-user-feedback',
    templateUrl: './user-feedback.component.html',
})
export class UserFeedbackComponent implements OnInit {
    ButtonType = ButtonType
    faChevronLeft = faChevronLeft
    faShare = faShare
    initialized = false
    typeFC = new UntypedFormControl()
    feedbackFC = new UntypedFormControl()

    types = [
        "Question",
        "Feedback/Enhancement Request",
        "Issue"
    ]

    constructor(
        protected _themeService: ThemeRebrandService,
        private router: Router,
        private userService: UserService,
    ) { }

    get themeService() {
        return this._themeService;
    }

    ngOnInit() {
        this.initialized = true
    }

    send() {
        this.userService.sendUserFeedback(this.typeFC.value, this.feedbackFC.value).subscribe((response) => {
            if (response.success) {
                this.router.navigate(['/user-feedback-confirmation'])
            } else {
                console.log("error occurred while sending feedback", response)
            }
        })
    }

    cancel() {
        this.userService.navigateToInitialPage()
    }

}
