<div class="container-fluid pt-1">
    <app-dashboard-controls [hidePaging]="true" [title]="title" [pageNumber]="currentPage" [pageSize]="limit"
        [entryCount]="count" [setPage]="this.setPage.bind(this)">
    </app-dashboard-controls>
</div>
<div class="container-fluid pt-1 dashboard-header">
    <div class="row">
        <div class="col">
            <table class="table table-striped">
                <thead>
                    <tr class="table-header">
                        <td *ngFor="let display_column of displayColumns" (click)="doSort(display_column.field)"
                            class="py-2 pointer nowrap">{{display_column.name}}<span
                                *ngIf="sortColumn == display_column.field">
                                <span *ngIf="!sortDirection">
                                    <fa-icon [icon]="faCaretDown" size="1x" title="sorted ascending"></fa-icon>
                                </span>
                                <span *ngIf="sortDirection">
                                    <fa-icon [icon]="faCaretUp" size="1x" title="sorted descending"></fa-icon>
                                </span>
                            </span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" *ngFor="let product of products$ | async">
                        <td>
                            <a routerLink="/product" [queryParams]="{id: product.id}">
                                {{product.name}}
                            </a>
                        </td>
                        <td>
                            <div *ngFor="let group of product.groups">
                                {{group.name}}
                            </div>
                        </td>
                        <td>
                            {{product.effective_price}}
                        </td>
                        <td>
                            {{product.effective_active}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="container-fluid pt-2">
    <app-dashboard-controls [hidePaging]="true" [title]="title" [pageNumber]="currentPage" [pageSize]="limit"
        [entryCount]="count" [setPage]="this.setPage.bind(this)" [isFooter]="true">
    </app-dashboard-controls>
</div>