<mat-form-field>
    <ng-container *ngIf="isRange; else regularDatePicker">
      <input matInput [matDatepicker]="rangeStartPicker" placeholder="Select start date" [value]="selectedDateRange?.start">
      <input matInput [matDatepicker]="rangeEndPicker" placeholder="Select end date" [value]="selectedDateRange?.end">
      <mat-datepicker-toggle matSuffix [for]="rangeStartPicker"></mat-datepicker-toggle>
      <mat-datepicker #rangeStartPicker></mat-datepicker>
      <mat-datepicker-toggle matSuffix [for]="rangeEndPicker"></mat-datepicker-toggle>
      <mat-datepicker #rangeEndPicker></mat-datepicker>
    </ng-container>

    <ng-template #regularDatePicker>
      <input matInput [matDatepicker]="picker" placeholder="Select a date" [value]="selectedDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </ng-template>
  </mat-form-field>
  