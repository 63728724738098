import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';
import { UserService } from '../user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { faKey, faBan } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormControl } from '@angular/forms';
import { Md5 } from 'ts-md5';


@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email.component.html',
})
export class VerifyEmailComponent implements OnInit {
    faKey = faKey;
    faBan = faBan;
    initialized = false;
    validToken = false;
    error;
    passwordFC = new UntypedFormControl();
    password2FC = new UntypedFormControl();
    finished = false;

    constructor(
        private userService: UserService,
        private _themeService: ThemeService,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    userId;
    username;
    token;
    isPasswordSet;

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            //console.log("user", params.user);
            this.userService.getUserByName(params.user).subscribe(response => {
                if (response.success) {
                    var user = response.result[0];
                    if (user != null && user.email_verification_token == params.token) {
                        this.userId = user.id;
                        this.username = user.email_address;
                        this.token = params.token;
                        this.validToken = true;
                        this.isPasswordSet = user.is_password_set;
                        this.userService.emailVerified(params.user, params.token).subscribe((response)=>{
                            if (response.success) {
                            } else {
                                console.log("error while setting email verification status", response);
                            }
                        })
                    }
                }
                if (this.isPasswordSet) {
                    this.finished = true;
                } else {
                    localStorage.removeItem("authToken");
                    localStorage.setItem("loginUsernameStored", this.username);
                }
                this.initialized = true;
            });
        });
    }

    get themeService() {
        return this._themeService;
    }

    cancel() {
        this.router.navigate(["/"]);
    }

    setPassword() {
        var passwordCheck: any = this.userService.passwordCheckAndSum(this.passwordFC.value, this.password2FC.value, false);
        if (passwordCheck.error) {
            this.error = passwordCheck.error;
            return;
        }
        this.userService.setPassword(this.userId, passwordCheck.hash, this.token, "emailVerification").subscribe((response) => {
            //console.log(response);
            if (response.success) {
                this.finished = true;
            }
        });
    }
}
