import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'newlineBreak'
})
export class NewlineBreakPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        if (!value) {
            return value;
        }
        return value.replace(/\n/g, '<br />');
    }

}
