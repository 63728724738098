import { Component, Input, OnInit } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export enum InputCurrencyFormat {
    verticalLabel,
    horizontalLabel,
    none,
}

@Component({
    selector: 'app-input-currency',
    templateUrl: './input-currency.component.html',
})
export class InputCurrencyComponent implements OnInit {
    InputCurrencyFormat = InputCurrencyFormat
    @Input() label;
    @Input() fc;
    @Input() change;
    @Input() disabled;
    @Input() tooltip
    @Input() format: InputCurrencyFormat = InputCurrencyFormat.verticalLabel
    faInfoCircle = faInfoCircle

    constructor(
    ) {
    }

    doChange() {
        if (this.change) {
            this.change()
        }
    }

    ngOnInit() {
    }

}
