import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SnackRouteService {

    constructor(
        private _snackBar: MatSnackBar,
        private router: Router,
    ) { }

    snack(snack) {
        this._snackBar.open(snack, null, { duration: 2000, });
    }
    route(route) {
        this.router.navigate([route])
    }
    snackRoute(snack, route) {
        this.snack(snack)
        this.route(route)
    }
}
