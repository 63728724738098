<mat-paginator style="float: right;" [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons role="table"></mat-paginator>

<mat-form-field class="container" style="margin-top: 10px;">
    <mat-label>Filter</mat-label>
    <input matInput (input)="applyFilter($event.target.value)" placeholder="Ex. Albany" #input>
</mat-form-field>
  
  <table mat-table [dataSource]="schoolDataSource" class="mat-elevation-z8">
  
    <ng-container matColumnDef="schoolDistrictCode">
      <th mat-header-cell *matHeaderCellDef> School District Code </th>
      <td mat-cell *matCellDef="let school"> {{school.schoolDistrictCode}} </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="schoolDistrictName">
      <th mat-header-cell *matHeaderCellDef> School District Name </th>
      <td mat-cell *matCellDef="let school">
        <span>
          <a class="school-link" style="color: #337ab7;" 
          (click)="editSchool(school)">
             {{school.schoolDistrictName}} 
          </a>
        </span>
      </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef> Address </th>
      <td mat-cell *matCellDef="let school"> {{school.address}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="taxYear">
      <th mat-header-cell *matHeaderCellDef> Tax Year </th>
      <td mat-cell *matCellDef="let school"> {{school.taxYear}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="dueDate1">
        <th mat-header-cell *matHeaderCellDef> Due Date 1 </th>
        <td mat-cell *matCellDef="let school"> {{school.dueDate1 | date: 'M/d/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="dueDate2">
        <th mat-header-cell *matHeaderCellDef> Due Date 2 </th>
        <td mat-cell *matCellDef="let school"> {{school.dueDate2 | date: 'M/d/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="dueDate3">
        <th mat-header-cell *matHeaderCellDef> Due Date 3 </th>
        <td mat-cell *matCellDef="let school"> {{school.dueDate3 | date: 'M/d/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="dueDate4">
        <th mat-header-cell *matHeaderCellDef> Due Date 4 </th>
        <td mat-cell *matCellDef="let school"> {{school.dueDate4 | date: 'M/d/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="refreshDate">
      <th mat-header-cell *matHeaderCellDef> Run Date </th>
      <td mat-cell *matCellDef="let school"> {{school.refreshDate | date: 'M/d/yyyy'}} </td>
  </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
    <!-- Row shown when there is no matching school. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No school matching the filter "{{input.value}}"</td>
    </tr>
  </table>