import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
    initialized = false;

    constructor(
        private _themeService: ThemeService,
    ) { }

    ngOnInit() {
        this.initialized = true;
    }

    get themeService() {
        return this._themeService;
    }
}
