import { Component, Input, OnInit } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export enum InputNumberFormat {
    verticalLabel,
    horizontalLabel,
    none,
}

@Component({
    selector: 'app-input-number',
    templateUrl: './input-number.component.html',
})
export class InputNumberComponent implements OnInit {
    InputNumberFormat = InputNumberFormat
    @Input() label
    @Input() fc
    @Input() change
    @Input() disabled
    @Input() format: InputNumberFormat = InputNumberFormat.verticalLabel
    @Input() tooltip
    faInfoCircle = faInfoCircle

    constructor(
    ) {
    }

    doChange(event) {
        if (this.change) {
            this.change()
        }
    }

    doEnter() {

    }

    ngOnInit() {
    }

}
