import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ThemeService } from '../theme.service';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { faShareSquare, faBan } from '@fortawesome/free-solid-svg-icons';
import { ButtonType } from '../button/button.component';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
    ButtonType = ButtonType

    @ViewChild("cancelButton", { read: ElementRef }) cancelButton: ElementRef
    @ViewChild("sendButton", { read: ElementRef }) sendButton: ElementRef

    currentView = "showUsernameInput"
    username = new UntypedFormControl('', [Validators.required, Validators.email])
    isValidEmail = false
    faShareSquare = faShareSquare
    faBan = faBan

    constructor(
        private userService: UserService,
        private _themeService: ThemeService,
        private router: Router,
    ) { }

    ngOnInit() {
        if (localStorage.getItem("loginUsernameStored")) {
            this.username.setValue(localStorage.getItem("loginUsernameStored"));
        }
        if (this.validEmail()) {
            this.isValidEmail = true;
        }
    }

    get themeService() {
        return this._themeService;
    }

    validEmail() {
        return /\S+@\S+(\.\S+)?/.test(this.username.value)
    }

    sendMouseOutBGColor() {
        if (this.validEmail()) {
            return this._themeService.secondaryColor;
        }
        return "grey";
    }

    sendMouseOverBGColor() {
        if (this.validEmail()) {
            return this._themeService.backgroundColor;
        }
        return "grey";
    }

    sendTitleText() {
        if (this.validEmail()) {
            return "Send Password Reset Link";
        } else {
            return "Email address is not valid.";
        }
    }

    sendRecoveryEmail() {
        this.currentView = "showSending";
        this.userService.sendPasswordRecoveryEmail(this.username.value).subscribe((response) => {
            if (response.success) {
                this.currentView = "showSent";
            } else {
                console.log(response);
                this.currentView = "serviceError";
            }
        });
    }

    cancel() {
        this.router.navigate(["/"])
    }
}
