import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';
import { OrderService } from '../order.service';
import { DocumentsService } from '../documents.service';
import { UserService } from '../user.service';

@Component({
    selector: 'app-order-review',
    templateUrl: './order-review.component.html',
})
export class OrderReviewComponent implements OnInit {
    initialized = false;
    cacheOrder;
    searchTypeData;
    serviceById = {};
    entityServices = {};
    individualServices = {};
    documentTypeToDisplay = {};

    constructor(
        private _themeService: ThemeService,
        private _orderService: OrderService,
        private documentsService: DocumentsService,
        private _userService: UserService,
    ) { }

    get userService() {
        return this._userService
    }

    ngOnInit() {
        this.documentsService.getDocumentTypes().subscribe((response) => {
            //console.log("document types: ", response);
            for (var i = 0; i < response.result.length; ++i) {
                this.documentTypeToDisplay[response.result[i].id] = response.result[i].name;
            }
            this._orderService.getPrimarySearchTypeData((searchTypeData) => {

                this.searchTypeData = {};
                for (var i = 0; i < searchTypeData.length; ++i) {
                    this.searchTypeData[searchTypeData[i].id] = searchTypeData[i];
                    searchTypeData[i].servicesById = {};
                    for (var j = 0; j < searchTypeData[i].services.length; ++j) {
                        searchTypeData[i].servicesById[searchTypeData[i].services[j].id] = searchTypeData[i].services[j];
                    }
                }

                this._orderService.getAllServices().subscribe((response) => {
                    //console.log(response);
                    for (var i = 0; i < response.result.length; ++i) {
                        this.serviceById[response.result[i].id] = response.result[i].name;
                    }
                    //console.log(this.serviceById);

                    this.loadOrderCache();

                    if (this.cacheOrder.entityServices) {
                        for (var name in this.cacheOrder.entityServices) {
                            if (this.cacheOrder.entityServices.hasOwnProperty(name)) {
                                for (var serviceProp in this.cacheOrder.entityServices[name]) {
                                    if (!this.entityServices[serviceProp]) {
                                        this.entityServices[serviceProp] = [];
                                    }
                                    this.entityServices[serviceProp].push(name);
                                }
                            }
                        }
                    }

                    if (this.cacheOrder.individualServices) {
                        for (var name in this.cacheOrder.individualServices) {
                            if (this.cacheOrder.individualServices.hasOwnProperty(name)) {
                                for (var serviceProp in this.cacheOrder.individualServices[name]) {
                                    if (!this.individualServices[serviceProp]) {
                                        this.individualServices[serviceProp] = [];
                                    }
                                    this.individualServices[serviceProp].push(name);
                                }
                            }
                        }
                    }

                    this.initialized = true;
                })
            });
        });
    }

    getFirstObjProp(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return prop;
            }
        }
    }

    loadOrderCache() {
        var dataItem = localStorage.getItem("cached_order_draft");
        if (!dataItem) {
            this.cacheOrder = {};
        } else {
            this.cacheOrder = JSON.parse(dataItem);
        }
    }

    get themeService() {
        return this._themeService;
    }

    get orderService() {
        return this._orderService;
    }

    getOrderText() {
        return JSON.stringify(this.cacheOrder, null, 4);
    }

    getSearchTypeDisplay() {
        var display = "";
        if (this.cacheOrder.service_group_id) {
            display += this.searchTypeData[this.cacheOrder.service_group_id].name;
            if (this.cacheOrder.primary_service_id) {
                var primaryService = this.searchTypeData[this.cacheOrder.service_group_id].servicesById[this.cacheOrder.primary_service_id];
                if (primaryService) {
                    display += ": " + primaryService.name;
                }
            }
        }
        return display;
    }

    objectKeys(obj) {
        return Object.keys(obj);
    }

    hasPropertyServices() {
        return this.cacheOrder.propertyServices && this.getFirstObjProp(this.cacheOrder.propertyServices);
    }

    hasEntityServices() {
        return this.getFirstObjProp(this.entityServices);
    }

    hasIndividualServices() {
        return this.getFirstObjProp(this.individualServices);
    }

    download(doc) {
        this.documentsService.download(doc.uuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body]);
            const url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            link.download = doc.filename;
            link.click();
        });
    }
}