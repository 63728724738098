import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpRequest, HttpEventType, HttpResponse, HttpClient } from '@angular/common/http';
import { SystemService } from '../system.service';
import { faUpload, faBan, faImage } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-input-image',
    templateUrl: './input-image.component.html',
    styleUrls: ['./input-image.component.css'],
})
export class InputImageComponent implements OnInit {
    faUpload = faUpload;
    faBan = faBan;
    faImage = faImage

    @Input() label;
    @Input() fc;
    @Input() change;
    @Input() disabled;
    @Input() unsetIcon;
    @Input() supportedTypes;
    initialized = false;
    currentSingleUploadFile = null;
    fileFormControl = new UntypedFormControl();
    uploadClicked = false;
    percentDone = null;
    uploadStatus = null;
    error = null;
    none;

    @ViewChild("imageCol", { read: ElementRef }) imageCol: ElementRef;

    constructor(
        private http: HttpClient,
        private systemService: SystemService,
    ) { }

    ngOnInit() {
        this.loadImage();
        this.initialized = true;
    }

    getUnsetIcon() {
        if (this.unsetIcon) {
            return this.unsetIcon;
        }
        return this.faImage;
    }

    loadImage() {
        if (this.fc.value) {
            this.systemService.getProfileFile(this.fc.value).subscribe((response) => {
                var reader = new FileReader();
                reader.readAsDataURL(<any>response);
                reader.onloadend = () => {
                    var img = document.createElement('img');
                    (<any>img.src) = reader.result;
                    img.onload = function () {
                        var newWidth = 100;
                        var newHeight = this.getImageHeight(img.width, img.height, newWidth);
                        img.width = newWidth;
                        img.height = newHeight;
                        while (this.imageCol.nativeElement.firstChild) {
                            this.imageCol.nativeElement.removeChild(this.imageCol.nativeElement.firstChild);
                        }
                        this.imageCol.nativeElement.appendChild(img);
                        this.none = false;
                    }.bind(this);
                }
            });
        } else {
            this.none = true;
        }
    }

    getImageHeight(width, height, newWidth) {
        var ratio = width / height;
        return newWidth / ratio;
    }

    doChange() {
        if (this.change) {
            this.change();
        }
    }

    handleFileInput(files) {
        this.currentSingleUploadFile = files[0];

        if (this.supportedTypes) {
            //console.log("checking types")
            var supported = false
            for (var i = 0; i < this.supportedTypes.length; ++i) {
                if (this.supportedTypes[i] == this.currentSingleUploadFile.type) {
                    supported = true
                    break
                }
            }
            if (!supported) {
                this.error = "File type " + this.currentSingleUploadFile.type + " is not supported. Please select a file that is one of the following types: " + JSON.stringify(this.supportedTypes)
                console.log("error!" + this.error)
                return
            }
        }

        //console.log(this.currentSingleUploadFile)
        this.upload();
    }

    upload() {
        if (this.uploadClicked) {
            this.error = "Upload has already been clicked.";
            return;
        }

        if (this.currentSingleUploadFile == null) {
            this.error = "Please select a file to attach."
            return;
        }

        this.uploadStatus = "Uploading";

        this.uploadClicked = true;
        const status: { [key: string]: { progress: Observable<number> } } = {};

        // create a new multipart-form for every file
        const formData: FormData = new FormData();
        formData.append('fileKey', this.currentSingleUploadFile, this.currentSingleUploadFile.name);
        formData.append("file_name", this.currentSingleUploadFile.name);

        var url = "api/uploadProfileFile";
        const req = new HttpRequest('PUT', url, formData, {
            reportProgress: true
        });

        const progress = new BehaviorSubject<number>(0);
        this.percentDone = 0;
        this.http.request(req).subscribe(event => {
            //console.log("event", event);
            if (event.type === HttpEventType.UploadProgress) {

                this.percentDone = Math.round(100 * event.loaded / event.total);
                if (this.percentDone == 100) {
                    this.uploadStatus = "Processing";
                }
                //console.log("progress report", this.percentDone);
                progress.next(this.percentDone);
            } else if (event instanceof HttpResponse) {
                this.error = null;
                progress.complete();
                this.percentDone = null;
                //console.log(event);
                this.uploadClicked = false;
                if ((<any>event.body).success) {
                    //todo - call onchange?
                    this.fc.setValue((<any>event.body).uuid);
                    this.loadImage();
                    this.doChange();
                } else {
                    this.error = "File could not be uploaded: " + (<any>event.body).message;
                }
                this.clearForm();
            }
        });

        // Save every progress-observable in a map of all observables
        status[this.currentSingleUploadFile.name] = {
            progress: progress.asObservable()
        };

        // return the map of progress.observables
        progress.asObservable().subscribe((result) => {
            console.log("progress:", result);
        })
        return status;
    }

    clearForm() {
        this.fileFormControl.setValue(null);
        this.currentSingleUploadFile = null;
    }

    clearImage() {
        this.clearForm();
        this.fc.setValue(null);
        this.none = true;
        while (this.imageCol.nativeElement.firstChild) {
            this.imageCol.nativeElement.removeChild(this.imageCol.nativeElement.firstChild);
        }
    }
}
