import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';
import { Router } from '@angular/router';
import { JurisdictionService } from '../jurisdiction.service';
import { faCheck, faSearch, faLink } from '@fortawesome/free-solid-svg-icons';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { UserService } from '../user.service';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
    faCheck = faCheck;
    faSearch = faSearch;
    faLink = faLink;

    initialized = false;
    countyData;
    countyParcelAvailability;

    constructor(
        private _themeService: ThemeRebrandService,
        private _jurisdictionService: JurisdictionService,
        private router: Router,
        private userService: UserService,
    ) { }

    ngOnInit() {
        this._jurisdictionService.getCountyData().subscribe((response) => {
            if (response.success) {
                this.countyData = response.result;
            }
            this._jurisdictionService.getCountyParcelAvailability().subscribe((response) => {
                if (response.success) {
                    this.countyParcelAvailability = response.result;
                    for (var i = 0; i < this.countyData.length; ++i) {
                        var matched = false;
                        for (var j = 0; j < this.countyParcelAvailability.length; ++j) {
                            if (this.countyData[i].name == this.countyParcelAvailability[j].name) {
                                matched = true;
                                if (this.countyParcelAvailability[j].CountOfreal_property_code > 0) {
                                    this.countyData[i].parcelAvailability = true;
                                }
                                break;
                            }
                        }
                        if (!matched) {
                            //remove borough-county
                            this.countyData.splice(i, 1);
                            --i;
                        }
                    }
                }
                this.initialized = true;
            });
        });
    }

    get themeService() {
        return this._themeService;
    }

    get jurisdictionService() {
        return this._jurisdictionService;
    }

    doSearch() {
        this.router.navigate(['/researcher-tools']);
    }

}
