import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';
import { OrderService } from '../order.service';
import { faQuestionCircle, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-order-services-property',
    templateUrl: './order-services-property.component.html',
})
export class OrderServicesPropertyComponent implements OnInit {
    faQuestionCircle = faQuestionCircle;
    faWindowClose = faWindowClose;
    initialized = false;
    services;
    cacheOrder;
    searcherPropertyNotesFC;

    constructor(
        private _themeService: ThemeService,
        private _orderService: OrderService,
    ) { }

    ngOnInit() {
        this._orderService.getServices(2).subscribe((response) => {
            this.services = response.result;
            //console.log(this.services)
            this.loadOrderCache();
            if (this.cacheOrder.primary_service_id == "TAX") {
                for (var i = 0; i < this.services.length; ++i) {
                    if (this.services[i].service_id == "TAX") {
                        this.services[i].isDisabled = "disabled";
                    }
                }
            }
            /*
            if(this.cacheOrder.itf_tax_search == true) {
                for(let i = 0; i < this.services.length; ++i) {
                    if(this.services[i].service_id == "TAX") {
                        this.services[i].isDisabled = "disabled"
                    }
                }
            }
            */
            this.initialized = true;
        });
    }

    loadOrderCache() {
        var dataItem = localStorage.getItem("cached_order_draft");
        this.cacheOrder = JSON.parse(dataItem);
        if (!this.cacheOrder) {
            this.cacheOrder = {};
        }
        if (!this.cacheOrder.propertyServices) {
            this.cacheOrder.propertyServices = {};
        } 

        if (this.cacheOrder.searcher_property_notes) {
            this.searcherPropertyNotesFC = new UntypedFormControl(this.cacheOrder.searcher_property_notes);
        } else {
            this.searcherPropertyNotesFC = new UntypedFormControl();
        }
    }

    saveOrderCache() {
        this.cacheOrder.searcher_property_notes = this.searcherPropertyNotesFC.value;
        //console.log("saving cache order", this.cacheOrder);
        localStorage.setItem("cached_order_draft", JSON.stringify(this.cacheOrder));
    }

    get themeService() {
        return this._themeService;
    }

    get orderService() {
        return this._orderService;
    }

    serviceChange(service) {
        //console.log(service);
        var elem = document.getElementById(service.service_id);
        var serviceChecked = (<HTMLInputElement>elem).checked;
        this.cacheOrder.propertyServices[service.service_id] = serviceChecked;
        //console.log(this.cacheOrder.propertyServices)
        if (!serviceChecked) {
            delete this.cacheOrder.propertyServices[service.service_id];
        }

        this.saveOrderCache();
    }

    isChecked(service) {
        if (!this.cacheOrder.propertyServices) {
            return false;
        }
        if (service.service_id == "TAX" && this.cacheOrder.primary_service_id == "TAX") {
            return true
        }

        if(service.service_id == "TAX" && this.cacheOrder.itf_tax_search == true) {
            return true
        }

        return this.cacheOrder.propertyServices[service.service_id];
    }

    serviceClick(service) {
        var elem = document.getElementById(service.service_id);
        (<HTMLInputElement>elem).checked = !(<HTMLInputElement>elem).checked;
        this.serviceChange(service);
    }

    questionMouseEnter(refName) {
        var elem = document.getElementById(refName);
        elem.style.color = this.themeService.secondaryColor;
    }

    questionMouseLeave(refName) {
        var elem = document.getElementById(refName);
        elem.style.color = this.themeService.primaryColor;
    }

    questionCloseMouseEnter(refName) {
        var elem = document.getElementById(refName);
        elem.style.color = this.themeService.secondaryColor;
    }

    questionCloseMouseLeave(refName) {
        var elem = document.getElementById(refName);
        elem.style.color = this.themeService.primaryColor;
    }

    toggleDisplay(refName) {
        var elem = document.getElementById(refName);
        if (elem.style.display == "none") {
            elem.style.display = "inline-block";
        } else {
            elem.style.display = "none";
        }
    }
}
