import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faCheck, faExclamationTriangle, faFileAlt, faHourglass, faKeyboard, faPercent, faPlay, faPrint, faStop, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../auth.service';
import { OrderService } from '../order.service';
import { SearcherDashboardStateDialog } from '../searcher-dashboard/searcher-dashboard.component';
import { SnackRouteService } from '../snack-route.service';
import { WorkflowService } from '../workflow.service';

@Component({
    selector: 'app-workflow-action-dropdown',
    templateUrl: './workflow-action-dropdown.component.html',
})
export class WorkflowActionDropdownComponent implements OnInit {
    @Input() orderUuid
    @Input() orderId
    @Input() workflowUuid
    @Input() workflowName
    @Input() workflowStatusId
    @Input() orderAddress
    @Input() orderStatusId
    @Input() dataRefreshCallback
    @Input() order
    @Input() orderTeamId
    @Input() userTeamIds
    @Input() skipTeamCheck
    @Input() invoiceable
    @Output() workflowStateChange = new EventEmitter<string>();
    @Input() workflowStatuses

    faPlay = faPlay
    faStop = faStop
    faPercent = faPercent
    faPrint = faPrint
    faKeyboard = faKeyboard
    faFileAlt = faFileAlt
    faHourglass = faHourglass

    actionButtons

    constructor(
        private _orderService: OrderService,
        public dialog: MatDialog,
        private snackRouteService: SnackRouteService,
        private workflowService: WorkflowService,
        private router: Router,
        private authService: AuthService,
    ) { }

    get orderService() {
        return this._orderService;
    }

    showDropdown = false

    initShowDropdown() {
        //console.log("show dropdown check", this.authService.userrole, this.orderTeamId, this.userTeamIds)
        if (this.authService.userrole != "User" && this.authService.userrole != "user") {
            this.showDropdown = true
            return
        }
        if (this.skipTeamCheck) {
            this.showDropdown = true
            return
        }
        if (this.userTeamIds) {
            for (var i = 0; i < this.userTeamIds.length; ++i) {
                if (this.userTeamIds[i] == this.orderTeamId) {
                    this.showDropdown = true
                }
            }
        }
    }

    isOrderDetailsPage() {
        return this.router.url == '/searcher-order-edit' || this.router.url.startsWith('/searcher-order-edit?')
    }

    ngOnInit() {
        this.initShowDropdown()
        this.actionButtons = []

        if (!this.isOrderDetailsPage()) {
            this.actionButtons.push({
                label: "View Order",
                icon: this.faFileAlt,
                routerLink: this.orderStatusId == "R" ? "order-create" : "/searcher-order-edit",
                queryParams: { orderUuid: this.orderUuid },
            })
        } else {
            this.actionButtons.push({
                label: "Order Sheet",
                icon: this.faPrint,
                click: this.printOrderSheetPDF.bind(this),
            })
        }

        if (!this.invoiceable) {
            //race condition issue here: workflowStatuses might be null
            for (var i = 0; i < this.workflowStatuses.length; ++i) {
                if (this.workflowStatuses[i].id != "M") {
                    this.actionButtons.push({
                        label: this.workflowStatuses[i].name,
                        icon: this.faPlay,
                        click: this.openSetStatusDialog.bind(this, this.workflowStatuses[i].id, this.workflowStatuses[i].name),
                        badgeClass: "workflow-status-" + this.workflowStatuses[i].id,
                    })
                }
            }
        }
        if (!this.isOrderDetailsPage()) {
            this.actionButtons.push({
                label: "Order Sheet",
                icon: this.faPrint,
                click: this.printOrderSheetPDF.bind(this),
            })
        }

        this.actionButtons.push({
            label: "Run Sheet",
            icon: this.faPrint,
            click: this.printRunSheetPDF.bind(this),
        })

        this.actionButtons.push({
            label: "Run Sheet Entry",
            icon: this.faKeyboard,
            routerLink: "/run-sheet-data-entry",
            queryParams: { orderUuid: this.orderUuid, orderWorkflowUuid: this.workflowUuid },
        })

        this.actionButtons.push({
            label: "Tax Search",
            icon: this.faPercent,
            routerLink: "/tax-search",
            queryParams: { orderUuid: this.orderUuid, orderWorkflowUuid: this.workflowUuid },
            target: "_blank",
        })
    }

    setWorkflowStatus(statusId, statusName) {
        this.workflowService.setWorkflowStatus(this.workflowUuid, statusId).subscribe((response) => {
            if (response.success) {
                if (this.dataRefreshCallback) {
                    this.dataRefreshCallback()
                }
                this.workflowStateChange.next(null)
                var snack = 'Order ' + this.orderId + " workflow " + this.workflowName
                    + " status set to " + statusName
                this.snackRouteService.snack(snack)
            } else {
                console.log("set workflow response", response)
            }
        })
    }

    openSetStatusDialog(status, statusName): void {
        const dialogRef = this.dialog.open(SearcherDashboardStateDialog, {
            panelClass: 'custom-dialog-container-no-reason',
            data: {
                orderId: this.orderId,
                orderAddress: this.orderAddress,
                newStatus: statusName,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.setWorkflowStatus(status, statusName)
            } else {
                this.snackRouteService.snack("Action cancelled")
            }
        });
    }

    printOrderSheetPDF() {
        this.orderService.getOrderSheetPDF(this.orderUuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);

            var iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = 'none';
            iframe.onload = function () {
                setTimeout(function () {
                    iframe.focus();
                    iframe.contentWindow.print();
                    URL.revokeObjectURL(fileURL)
                }, 1);
            };
            iframe.src = fileURL;
        })
    }

    printRunSheetPDF() {
        this.orderService.getRunSheetPDF(this.workflowUuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp.body], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);

            var iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = 'none';
            iframe.onload = function () {
                setTimeout(function () {
                    iframe.focus();
                    iframe.contentWindow.print();
                    URL.revokeObjectURL(fileURL)
                }, 1);
            };
            iframe.src = fileURL;
        })
    }

}

@Component({
    selector: 'workflow-action-dropdown-state-dialog',
    templateUrl: './workflow-action-dropdown-state-dialog.html',
})
export class WorkflowActionDropdownStateDialog {
    faTimes = faTimes
    faExclamationTriangle = faExclamationTriangle
    faCheck = faCheck
    constructor(
        public dialogRef: MatDialogRef<SearcherDashboardStateDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) { }

    yes() {
        this.dialogRef.close({});
    }

    no() {
        this.dialogRef.close();
    }
}
