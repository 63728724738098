<div class="container-fluid pt-1">
    <div class="col-2 page-title" style="font-size: medium;">
        <mat-slide-toggle [formControl]="pageSelect" color="primary">New Page</mat-slide-toggle>
    </div>

    <div *ngIf="!pageSelect.value">
        <div class="container-fluid pt-1" *ngFor="let listView of listViewEntries; let listViewIndex = index">
            <span *ngIf="initialized">
                <div class="container-fluid mt-4 dashboard-header" style="font-size: 20px; font-weight: bold;">
                    <span *ngIf="order_status == 'A'">All Orders</span>
                    <span *ngIf="order_status == 'D'">Draft Orders</span>
                    <span *ngIf="order_status == 'IP'">In Progress</span>
                    <span *ngIf="order_status == 'RC'">Recently Completed</span>
                    <span *ngIf="order_status == undefined">All Orders</span>
                </div>
        
                <app-dashboard-controls [title]="listView.title" [pageNumber]="lists[listViewIndex].currentPage"
                    [pageSize]="lists[listViewIndex].limit" [entryCount]="lists[listViewIndex].count"
                    [setPage]="this.setPage.bind(this, listViewIndex)">
                </app-dashboard-controls>
        
                <div class="row">
                    <div class="col">
                        <table class="table table-striped">
                            <thead>
                                <tr class="table-header table-header-grey">
                                    <td *ngFor="let display_column of listView.display_column; let isFirst = first; let k = index"
                                        (click)="doSort(listView, display_column.name, listViewIndex)" class="py-2 pointer">
                                        {{display_column.title}}<span
                                            *ngIf="lists[listViewIndex].sort && lists[listViewIndex].sort.field == display_column.name">
                                            <span *ngIf="lists[listViewIndex].sort.direction == 1">
                                                <fa-icon [icon]="faCaretDown" size="1x" title="sorted ascending"></fa-icon>
                                            </span>
                                            <span *ngIf="lists[listViewIndex].sort.direction == 0">
                                                <fa-icon [icon]="faCaretUp" size="1x" title="sorted descending"></fa-icon>
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-row"
                                    *ngFor="let order of getListItems(listViewIndex); let j = index; let isLastOrder = last">
                                    <td>
                                        <a [routerLink]="this.listItems[listViewIndex][j].order_status == 'R' ? '/order-create' : '/order-download-mat'"
                                            [queryParams]="{orderUuid: this.listItems[listViewIndex][j].uuid}">{{order.order_id}}
                                            <span *ngIf="(order.attached_documents && order.attached_documents.length > 0)
                                                    || order.documents && order.documents.length > 0
                                                    || order.document_count > 0" class="paperclip-documents">
                                                <fa-icon class="ml-1" [icon]="faPaperclip" size="1x"
                                                    title="Documents are attached to order">
                                                </fa-icon>
                                            </span>
                                            <span *ngIf="order.sbl_document_count" class="paperclip-sbl">
                                                <fa-icon class="ml-1" [icon]="faHome" size="1x"
                                                    title="Documents are attached to sbl"></fa-icon>
                                            </span>
                                            <span *ngIf="order.final_document_count" class="paperclip-final">
                                                <fa-icon class="ml-1" [icon]="faUpload" size="1x"
                                                    title="Final Documents are attached to order"></fa-icon>
                                            </span>
                                            <span *ngIf="order.api_icon">
                                                <img src="assets/{{order.api_icon}}" />
                                            </span>
                                        </a>
                                    </td>
                                    <td>
                                        <span class="status-badge" [ngClass]="['order-status-' + order.order_status ]">
                                            {{orderStatusById[order.order_status].name | uppercase}}
                                        </span>
                                    </td>
                                    <td>
                                        {{order.reference_number}}
                                    </td>
                                    <td>
                                        {{order.service_name}}
                                    </td>
                                    <td>
                                        {{order.county_name}}
                                    </td>
                                    <td>
                                        {{order.address}}
                                    </td>
                                    <td>
                                        <a routerLink="/order-assessment-info" [queryParams]="{ oid: this.listItems[listViewIndex][j].uuid }">
                                            {{order.sbl}}
                                        </a>
                                    </td>
                                    <td>
                                        {{order.property_seller}}
                                    </td>
                                    <td>
                                        {{order.property_buyer}}
                                    </td>
                                    <td>
                                        {{order.submission_date | date : 'shortDate'}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
        
                <app-dashboard-controls [title]="listView.title" [pageNumber]="lists[listViewIndex].currentPage"
                    [pageSize]="lists[listViewIndex].limit" [entryCount]="lists[listViewIndex].count"
                    [setPage]="this.setPage.bind(this, listViewIndex)" [isFooter]="true">
                </app-dashboard-controls>
            </span>
        </div>
    </div>

    <div *ngIf="pageSelect.value">
        <app-agent-dashboard-mat [orderStatus]="order_status"></app-agent-dashboard-mat>
    </div>
</div>

