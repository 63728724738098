import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../theme.service';
import { UserService } from '../user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { faKey, faBan } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormControl } from '@angular/forms';
import { Md5 } from 'ts-md5';

@Component({
    selector: 'app-forgot-password-reset',
    templateUrl: './forgot-password-reset.component.html',
})
export class ForgotPasswordResetComponent implements OnInit {
    faKey = faKey;
    faBan = faBan;
    initialized = false;
    validToken = false;
    error;
    passwordFC = new UntypedFormControl();
    password2FC = new UntypedFormControl();
    finished = false;

    constructor(
        private userService: UserService,
        private _themeService: ThemeService,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    userId;
    username;
    token;

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            //console.log("user", params.user);
            this.userService.getUserByName(params.user).subscribe(response => {
                if (response.success) {
                    var user = response.result[0];

                    //if password reset time is more than 30 min ago, invalid token
                    if (new Date().getTime() - new Date(user.password_reset_time).getTime() < 30 * 60 * 1000) {
                        if (user != null && user.password_reset_token == params.token) {
                            this.userId = user.id;
                            this.username = user.email_address;
                            this.token = params.token;
                            this.validToken = true;
                        }
                    }
                }
                this.initialized = true;
            });
        });
    }

    get themeService() {
        return this._themeService;
    }

    cancel() {
        this.router.navigate(["/"]);
    }

    setPassword() {
        var passwordCheck: any = this.userService.passwordCheckAndSum(this.passwordFC.value, this.password2FC.value, false);
        if (passwordCheck.error) {
            this.error = passwordCheck.error;
            return;
        }
        this.userService.setPassword(this.userId, passwordCheck.hash, this.token, "forgotPassword").subscribe((response) => {
            //console.log(response);
            if (response.success) {
                this.finished = true;
            }
        });
    }
}
