<div *ngIf="!style" class="row edit-height">
    <div class="col-4 pb-2 unedit-padding bold">
        {{label}}
    </div>
    <div class="col-6 pb-2 pr-0">
        <input type="text" class="input-style" [formControl]="fc" (keyup)="doChange()" (keyup.enter)="doEnter()">
    </div>
    <div class="col-2 pb-2 pl-0">
        <select class="input-style" style="vertical-align: top;" [formControl]="enumFC" (change)="doChange()">
            <option *ngFor="let enumValue of enumValues" value={{enumValue}}>
                {{enumValue}}
            </option>
        </select>
    </div>
</div>

<div *ngIf="style == 'rebrand'" class="row edit-height">
    <div class="col">
        <div class="row">
            <div class="col pb-2 unedit-padding bold">
                {{label}}
            </div>
        </div>
        <div class="row">
            <div class="col-9 pb-2 pr-0">
                <input type="text" class="input-style" [formControl]="fc" (keyup)="doChange()"
                    (keyup.enter)="doEnter()">
            </div>
            <div class="col-3 pb-2 pl-0">
                <select class="input-style" style="vertical-align: top;" [formControl]="enumFC" (change)="doChange()">
                    <option *ngFor="let enumValue of enumValues" value={{enumValue}}>
                        {{enumValue}}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>