<div *ngIf="!initialized" class="container-fluid mt-4 rounded-ninety" [style.color]="themeService.textOnTertiaryColor"
    [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.secondaryColor">
    Initializing...
</div>
<div *ngIf="initialized" class="container-fluid mt-4 rounded-ninety" [style.color]="themeService.textOnTertiaryColor"
    [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.tertiaryColor"
    [style.border-color]="themeService.tertiaryColor">

    <div class="row mt-3">
        <div class="col">
            <div class="row mt-3">
                <div class="col-1 d-none d-md-block">
                </div>
                <div class="col-12 col-md-10 bold" style="font-size: 1.2em;">
                    Please answer the security questions.
                </div>
                <div class="col-1 d-none d-md-block">
                </div>
            </div>
        </div>
    </div>
    <span *ngFor="let question of questions">

        <div class="row mt-3">
            <div class="col">
                <div class="row mt-3">
                    <div class="col-1 d-none d-md-block">
                    </div>
                    <div class="col-12 col-md-10">
                        {{question.question}}
                    </div>
                    <div class="col-1 d-none d-md-block">
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <div class="row mt-1">
                    <div class="col-1 d-none d-md-block">
                    </div>
                    <div class="col-12 col-md-10">
                        <input type="text" class="input-style" [id]="question.id" />
                    </div>
                    <div class="col-1 d-none d-md-block">
                    </div>
                </div>
            </div>
        </div>
    </span>
    <div *ngIf="error" class="row mt-3">
        <div class="col bold" align="center" style="color: red;">
            {{error}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col" align="center">
            <app-button label="Submit" [clickFunction]="submit.bind(this)" [icon]="faChevronRight">
            </app-button>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col">
        </div>
    </div>
</div>