import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { UtilityReportService } from '../utility-report.service';

@Component({
    selector: 'app-utility-report',
    templateUrl: './utility-report.component.html',
})
export class UtilityReportComponent implements OnInit {
    footerTotal: number = 0;
    faWrench = faWrench
    reports$: Observable<any> = this.utilityReportService.getReports().pipe(
        map((reportsResponse) => {
            return reportsResponse.result
        })
    )
    localStorageReportName = "hsny_utility_report"
    localStorageSortFieldName = "hsny_utility_report_sort_field"
    localStorageSortIsDescending = "hsny_utility_report_sort_is_descending"
    reportFC = new UntypedFormControl(localStorage.getItem(this.localStorageReportName))

    calcFooterTotal() {
        this.entries$.subscribe(entries => 
            {
            if (this.reportFC.value === 'DAILY_ORDERS_TODAY' || this.reportFC.value === 'DAILY_ORDERS_YESTERDAY') 
            {
                console.log(entries.result)
                this.footerTotal = entries.result
                    .map(entry => entry.Total) 
                    .reduce((sum, value) => sum + value, 0);
            } 
            else 
            {
                this.footerTotal = 0; // Reset footerTotal if the condition doesn't match
            }
        });
        console.log(this.footerTotal)
    }

    entries$ = combineLatest(
        this.reportFC.valueChanges.pipe(
            startWith(localStorage.getItem(this.localStorageReportName)),
            tap((typeName) => {
                localStorage.setItem(this.localStorageReportName, typeName)
            })
        )
    ).pipe(
        switchMap(([value]) => {
            //console.log("value", value)
            return combineLatest(this.utilityReportService.getReport(value), this.utilityReportService.getReportSchema(value))
        })
    ).pipe(
        map(([reportResponse, reportSchemaResponse]) => {
            //console.log("report response", reportResponse)
            //console.log("report schema response", reportSchemaResponse)
            reportResponse.definition = reportSchemaResponse.result
            return reportResponse
        })
    )

    constructor(
        private utilityReportService: UtilityReportService,
    ) { }

    ngOnInit() {
        this.calcFooterTotal();
        this.reportFC.valueChanges.subscribe((value) => {
            this.calcFooterTotal();
        })

    }

    sortClick(oldSortField, newSortField, sortIsDescending) {

    }
}
