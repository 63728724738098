<span *ngIf="!initialized">
    <div class="container-fluid bordered-container mt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.tertiaryColor"
        [style.border-color]="themeService.tertiaryColor">
        Initializing
    </div>
</span>
<div *ngIf="initialized">
    <app-provider-dashboard *ngIf="isProvider"></app-provider-dashboard>
    <app-searcher-dashboard *ngIf="isSearcher"></app-searcher-dashboard>
</div>