<div class="row mt-3">
    <div class="col bold">
        {{label}}
    </div>
</div>
<div class="row">
    <div class="col ml-2 unedit-padding" align="center">
    </div>
</div>
<div class="row">
    <div class="col ml-2" #imageCol align="center">
    </div>
</div>
<div class="row">
    <div class="col ml-2" align="center">
        <label for="file" class="mb-0 pointer" style="width: 100%;">
            <div *ngIf="none" class="px-2 py-2 none-border" align="center">
                <div class="none-image" align="center">
                    <fa-icon [icon]="getUnsetIcon()" flip="horizontal"></fa-icon>
                </div>
            </div>
        </label>
    </div>
</div>
<div *ngIf="!disabled" class="row mt-1">
    <div class="col ml-2" style="color: #6c89a5">
        <input type="file" style="display: none;" [formControl]="fileFormControl" id="file"
            (change)="handleFileInput($event.target.files)">
        <label for="file" class="mb-0 bold pointer">
            <fa-icon [icon]="faUpload"></fa-icon> Upload photo
        </label>
        <span style="color: lightgrey;"> not more than 1mb</span>
        <span *ngIf="!none" style="color: #6c89a5; cursor: pointer;" (click)="clearImage()"> (clear)</span>
    </div>
</div>
<div *ngIf="error" class="row">
    <div class="col bold">
        {{error}}
    </div>
</div>