<span *ngIf="!initialized">
    <div class="container-fluid bordered-container mt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.tertiaryColor"
        [style.border-color]="themeService.tertiaryColor">
        Initializing
    </div>
</span>
<span *ngIf="initialized">
    <div class="container-fluid mt-4 py-2 rounded-ninety" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor">
        <div class="row pb-1">
            <div class="col-4 pb-3 attribute-h1 bold">
                Tax Search
            </div>
            <div class="col-2 pb-3">
                Order ID: {{orderHeader.order_id}}
            </div>
            <div class="col-6 pb-3">
                Client Reference: {{orderHeader.reference_number}}
            </div>
        </div>

        <div class="row" >
            <div class="col">
                <app-button *ngIf="taxInfoURL" [label]="taxInfoInfo.tax_year + ' Tax Information'" [clickFunction]="navTaxInfo.bind(this)"
                    [icon]="faInfoCircle" [small]="true">
                </app-button>
                <app-button *ngIf="!locked" [label]="'Edit Muni Data'" [clickFunction]="editMuniData.bind(this)"
                    [icon]="faEdit" [small]="true">
                </app-button>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <app-input-text label="Assessed To" [fc]="assessedToFC" [disabled]="locked">
                </app-input-text>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <app-input-text label="Premesis" [fc]="premesisFC" [disabled]="locked">
                </app-input-text>
                <app-input-text label="County" [fc]="countyFC" [disabled]="locked">
                </app-input-text>
                <app-input-text label="Town/City" [fc]="townFC" [disabled]="locked">
                </app-input-text>
                <app-input-text label="School District" [fc]="schoolDistrictFC" [disabled]="locked">
                </app-input-text>
                <app-input-text label="Assessed Land Value" [fc]="assessedLandValueTownFC" [disabled]="locked">
                </app-input-text>
                <app-input-text label="Total" [fc]="totalTownFC" [disabled]="locked">
                </app-input-text>
                <app-input-text-area label="Exemptions" [fc]="exemptionsFC" [disabled]="locked">
                </app-input-text-area>
            </div>
            <div class="col-6">
                <app-input-text label="Tax Class" [fc]="taxClassFC" [disabled]="locked">
                </app-input-text>
                <app-input-text label="SWIS" [fc]="swisFC" [disabled]="locked">
                </app-input-text>
                <div class="row">
                    <div class="col-4 pb-2 unedit-padding bold">
                        SBL
                    </div>
                    <div class="col-8 unedit-padding">
                        <span (click)="sblClick()" class="link-rebrand">{{sblFC.value}}</span>
                    </div>
                </div>
                <app-input-text label="Acreage" [fc]="acreageFC" [disabled]="locked">
                </app-input-text>
                <app-input-text label="Village" [fc]="villageFC" [disabled]="locked">
                </app-input-text>
                <app-input-text label="Assessed Land Value" [fc]="assessedLandValueVillageFC" [disabled]="locked">
                </app-input-text>
                <app-input-text label="Total" [fc]="totalVillageFC" [disabled]="locked">
                </app-input-text>
                <app-input-text label="Acct #" [fc]="accountNumberFC" [disabled]="locked">
                </app-input-text>
            </div>
        </div>

        <hr />
        <span *ngFor="let fcSet of detailPaymentFCSet; let i = index">
            <div *ngIf="i > 0" class="row">
                <div class="col">
                    <hr />
                </div>
            </div>
            <div class="row">
                <div class="col" align="right">
                    <app-button *ngIf="!locked && i == 0" label="Add" class="ml-2"
                        [clickFunction]="addDetail.bind(this)" [icon]="faPlus" [small]="true">
                    </app-button>
                    <app-button *ngIf="!locked && detailPaymentFCSet.length > 1" label="Remove" class="ml-2"
                        [clickFunction]="removeDetail.bind(this, i)" [icon]="faMinus" [small]="true">
                    </app-button>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <app-input-text label="Muni Type" [fc]="fcSet.muniTypeFC" [disabled]="locked">
                    </app-input-text>
                    <app-input-text-area label="Address" [fc]="fcSet.addressFC" [disabled]="locked">
                    </app-input-text-area>
                    <!-- <app-input-text-area label="Notes" [fc]="fcSet.notesFC" [disabled]="locked" mlength="2048">
                    </app-input-text-area> -->
                    <label for="notes" style="font-weight: bold;">Notes</label>
                    <textarea id="notes" maxlength="2048" [rows]="4" class="ml-2" [formControl]="fcSet.notesFC"
                    style="vertical-align: top; width: 100%; resize: both; border-radius: .3em;">
                    </textarea>
                </div>
                <div class="col-6">
                    <app-input-text label="Tax Year" [fc]="fcSet.taxYearFC" [disabled]="locked">
                    </app-input-text>
                    <app-input-text label="Tax Lien" [fc]="fcSet.taxLienFC" [disabled]="locked">
                    </app-input-text>
                    <app-input-text label="Tax Levy" [fc]="fcSet.taxLevyFC" [disabled]="locked">
                    </app-input-text>
                    <app-input-text label="Billed Amount" [fc]="fcSet.billedAmtFC" [disabled]="locked">
                    </app-input-text>
                    <app-input-text label="W/O Exmp" [fc]="fcSet.woExemptionFC" [disabled]="locked">
                    </app-input-text>
                </div>
            </div>
            <div class="row bold">
                <div class="col-1">Inst</div>
                <div class="col-3">Taxes Due Amount</div>
                <div class="col-2">Status</div>
                <div class="col-3">Due Date</div>
                <div class="col-3" align="center">
                    <app-button *ngIf="!locked" label="Add" class="ml-2" [clickFunction]="addPayment.bind(this, i)"
                        [icon]="faPlus" [small]="true">
                    </app-button>
                </div>
            </div>
            <div *ngIf="fcSet.payments.length == 0" class="row">
                <div class="col" align="center">No payments exist.<span *ngIf="!locked"> Click "Add" to add
                        payments.</span></div>
            </div>
            <div *ngFor="let payment of fcSet.payments; let j = index" class="row">
                <div class="col-1">{{j + 1}}</div>
                <div class="col-3">
                    <app-input-text label="Taxes Due" [fc]="payment.taxesDue" [style]="'edit-sbl'" [disabled]="locked">
                    </app-input-text>
                </div>
                <div class="col-2">
                    <app-input-enum label="Status" [fc]="payment.status" [style]="'edit-sbl'"
                        [enumValuesNameAndId]="taxSearchStatuses" [disabled]="locked">
                    </app-input-enum>
                </div>
                <div class="col-3">
                    <app-input-date label="Due Date" [fc]="payment.dueDates" [style]="'edit-sbl'" [disabled]="locked">
                    </app-input-date>
                </div>
                <div class="col-3" align="center">
                    <app-button *ngIf="!locked" label="Remove" class="ml-2"
                        [clickFunction]="removePayment.bind(this, i, j)" [icon]="faMinus" [small]="true">
                    </app-button>
                </div>
            </div>
        </span>
        <div class="row"> 
            <div class="col-6"> 
                <app-input-text-area label="County Notes" [fc]="countyNotes" [disabled]="locked" mlength="1000"></app-input-text-area> 
            </div>
        </div>

        <div *ngIf="saveError" class="row mt-3">
            <div class="col" align="center">
                {{saveError}}
            </div>
        </div>
        <div class="row mt-3">
            <div class="col" align="center">
                <app-button *ngIf="locked" class="mx-1" label="Back" [clickFunction]="back.bind(this)"
                    [icon]="faChevronLeft">
                </app-button>
                <app-button *ngIf="!locked" class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)"
                    [icon]="faBan">
                </app-button>

                <app-button *ngIf="!locked" class="mx-1" label="View Order" [clickFunction]="viewOrder.bind(this)"
                    [icon]="faEdit">
                </app-button>

                <app-button *ngIf="!locked" class="mx-1" label="Save For Later"
                    [clickFunction]="saveForLater.bind(this)" [icon]="faSave">
                </app-button>

                <app-button class="mx-1" label="Preview PDF" [clickFunction]="previewPDF.bind(this)"
                    [icon]="faFilePdf">
                </app-button>

                <app-button *ngIf="!locked" class="mx-1" label="Finish" [clickFunction]="finish.bind(this)"
                    [icon]="faFlagCheckered" [buttonType]="ButtonType.save">
                </app-button>
                <app-button *ngIf="locked" class="mx-1" label="Unlock" [clickFunction]="unlock.bind(this)"
                    [icon]="faUnlock">
                </app-button>
            </div>
        </div>
    </div>
</span>