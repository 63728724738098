import { Component, OnInit, Input } from '@angular/core';
import { faMagic, faFileDownload, faUpload, faTrashAlt, faEdit, faCheck, faBan, faHourglass, faEnvelope, faShare } from '@fortawesome/free-solid-svg-icons';
import { ThemeService } from '../theme.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DocumentsService } from '../documents.service';
import { UntypedFormControl } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpRequest, HttpEventType, HttpResponse, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AwsSesService } from '../aws-ses.service';

@Component({
    selector: 'app-searcher-order-documents',
    templateUrl: './searcher-order-documents.component.html',
})
export class SearcherOrderDocumentsComponent implements OnInit {
    faMagic = faMagic;
    faFileDownload = faFileDownload;
    faUpload = faUpload;
    faTrashAlt = faTrashAlt;
    faEdit = faEdit;
    faCheck = faCheck;
    faBan = faBan;
    faHourglass = faHourglass;
    faEnvelope = faEnvelope;
    faShare = faShare;

    descriptionEdits = {};
    tempDescriptionEdits = {};

    // paramOID;
    // paramCounty;
    // paramSBL;
    // paramSync;
    // paramAction;
    // paramEnvironemnt;
    // paramKey;

    // paramTo;
    // paramBcc;

    // paramSubject;

    // paramInitComplete = false;
    @Input() paramOID;
    @Input() paramCounty;
    @Input() paramSBL;
    @Input() paramOrderUUID;

    orderDocuments;
    sblDocuments;
    tempDocuments;

    fileFormControl = new UntypedFormControl();
    currentSingleUploadFile = null;

    //note: deprecated document types are removed from the documentTypes array
    documentTypes = [];
    allDocumentTypes = [];
    uploadClicked = false;
    percentDone = null;
    uploadStatus = "Uploading";

    uploadError;

    constructor(
        private _themeService: ThemeService,
        private _router: Router,
        private activatedRoute: ActivatedRoute,
        private documentsService: DocumentsService,
        private http: HttpClient,
        private awsSes: AwsSesService,
    ) { }

    getOrderDocuments() {
        this.documentsService.getDocumentsForOrder(this.paramOrderUUID).subscribe((response) => {
            //console.log("order documents fetch result", response);
            this.orderDocuments = response.result;
        });
    }

    getSBLDocuments() {
        this.documentsService.getDocumentsForSBL(this.paramSBL, this.paramOrderUUID, this.paramCounty).subscribe((response) => {
            //console.log("sbl documents fetch result", response);
            this.sblDocuments = response.result;
        });
    }

    getTempDocuments() {
        this.documentsService.getTemporaryDocumentsForOrder(this.paramOrderUUID).subscribe((response) => {
            //console.log("temp order documents fetch result", response);
            this.tempDocuments = response.result;
        });
    }

    ensureSpacesAfterCommas(emailAddresses) {
        var _return = "";
        var lastComma = false;
        for (var i = 0; i < emailAddresses.length; ++i) {
            if (lastComma && emailAddresses.charAt(i) != ' ') {
                _return += " ";
            }
            lastComma = false;
            if (emailAddresses.charAt(i) == ",") {
                lastComma = true;
            }
            _return += emailAddresses.charAt(i);
        }
        return _return;
    }

    ngOnInit() {
        // this.activatedRoute.queryParams.subscribe(params => {
        //     this.paramOID = params.oid;
        //     this.paramCounty = params.c;
        //     this.paramSBL = params.sbl;
        //     this.paramSync = params.sync;
        //     this.paramAction = params.action;
        //     this.paramEnvironemnt = params.e;
        //     this.paramKey = params.key;
        //     this.paramSubject = params.s;

        //     if (params.to) {
        //         this.paramTo = this.ensureSpacesAfterCommas(params.to);
        //     }
        //     if (params.bcc) {
        //         this.paramBcc = this.ensureSpacesAfterCommas(params.bcc);
        //     }

        //     this.paramInitComplete = true;

        //     this.getOrderDocuments();
        //     this.getSBLDocuments();
        //     this.getTempDocuments();
        // });
        this.getOrderDocuments();
        this.getSBLDocuments();
        this.getTempDocuments();

        this.documentsService.getKCSDocumentTypes().subscribe((response) => {
            //console.log("document types: ", response);
            for (var i = 0; i < response.result.length; ++i) {
                this.allDocumentTypes.push(response.result[i]);
                if (!response.result[i].deprecated) {
                    this.documentTypes.push(response.result[i]);
                }
            }
        });
    }

    getDocumentTypeDisplay(documentType) {
        for (var i = 0; i < this.allDocumentTypes.length; ++i) {
            if (this.allDocumentTypes[i].id == documentType) {
                return this.allDocumentTypes[i].name;
            }
        }
    }

    isAuthorized() {
        // if (this.paramKey == "y2zEa2byDmyjlpXa8fgh") {
        //     return true;
        // }
        // return false;
        return true;
    }

    get themeService() {
        return this._themeService;
    }

    get router() {
        return this._router;
    }


    handleFileInput(files) {
        this.currentSingleUploadFile = files[0];
        if (!(<HTMLInputElement>document.getElementById("newfile_description")).value) {
            (<HTMLInputElement>document.getElementById("newfile_description")).value = this.currentSingleUploadFile.name;
        }
    }

    upload() {
        if (this.uploadClicked) {
            console.log("upload already clicked");
            return;
        }

        if ((<HTMLInputElement>document.getElementById("newfile_type")).value == "-1") {
            alert("No attachment type selected");
            return;
        }

        if (this.currentSingleUploadFile == null) {
            alert("No file selected");
            return;
        }

        this.uploadStatus = "Uploading";

        this.uploadClicked = true;
        const status: { [key: string]: { progress: Observable<number> } } = {};

        // create a new multipart-form for every file
        const formData: FormData = new FormData();
        formData.append('fileKey', this.currentSingleUploadFile, this.currentSingleUploadFile.name);
        formData.append("document_type", (<HTMLInputElement>document.getElementById("newfile_type")).value);
        formData.append("state", "NY");
        formData.append("county", this.paramCounty);
        formData.append("sbl", this.paramSBL);
        formData.append("address", null);
        formData.append("order_uuid", this.paramOrderUUID);
        formData.append("description", (<HTMLInputElement>document.getElementById("newfile_description")).value);
        formData.append("status", "A");
        formData.append("scope", "private");
        formData.append("file_name", this.currentSingleUploadFile.name);

        formData.append("insert_without_order_uuid", "true");

        var url = "api/document";
        const req = new HttpRequest('PUT', url, formData, {
            reportProgress: true
        });

        const progress = new BehaviorSubject<number>(0);
        this.percentDone = 0;
        this.http.request(req).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {

                this.percentDone = Math.round(100 * event.loaded / event.total);
                if (this.percentDone == 100) {
                    this.uploadStatus = "Processing";
                }
                //console.log("progress report", this.percentDone);
                progress.next(this.percentDone);
            } else if (event instanceof HttpErrorResponse) {
                this.uploadError = (<any>event.message);
                this.uploadClicked = false;
            } else if (event instanceof HttpResponse) {

                progress.complete();
                this.percentDone = null;
                //console.log("request complete");
                console.log(event);
                if ((<any>event.body).success) {
                    this.getTempDocuments();
                    this.getOrderDocuments();
                    this.clearForm();
                } else {
                    this.uploadError = (<any>event.body).message;
                    this.uploadClicked = false;
                }
            }
        });

        // Save every progress-observable in a map of all observables
        status[this.currentSingleUploadFile.name] = {
            progress: progress.asObservable()
        };

        // return the map of progress.observables
        progress.asObservable().subscribe((result) => {
            console.log("progress:", result);
        })
        return status;
    }

    finishUpload() {
        this.documentsService.finishUpload(this.paramOID).subscribe(response => {
            if (response.success) {
                this.getTempDocuments();
                this.getOrderDocuments();
                window.scrollTo(0, 0);
                this.showAddContainer = false;
            }
        });
    }

    download(doc) {
        console.log("download doc", doc);
        var filename = null;

        this.documentsService.filename(doc.guid).subscribe(response => {
            filename = response.filename;

            this.documentsService.download(doc.guid).subscribe((response) => {
                console.log(response);
                var tmp: any = response;
                var blob = new Blob([tmp.body]);
                const url = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                link.href = url;
                link.download = filename;
                link.click();
            });
        });
    }

    downloadAll() {
        for (var i = 0; i < this.orderDocuments.length; ++i) {
            this.download(this.orderDocuments[i]);
        }
    }

    showAddContainer = false;
    scrollToAdd() {
        this.showAddContainer = !this.showAddContainer;
        if (this.showAddContainer) {
            setTimeout(function () {
                document.getElementById("addTitle").scrollIntoView(true);
            }, 0);
        }
    }

    clearForm() {
        (<HTMLInputElement>(document.getElementById("newfile_type"))).value = "-1";
        (<HTMLInputElement>(document.getElementById("newfile_description"))).value = "";
        this.fileFormControl.setValue(null);
        this.currentSingleUploadFile = null;
        this.uploadClicked = false;
    }

    deleteTempFile(doc) {
        this.documentsService.deleteTempFile(doc.guid).subscribe((response) => {
            this.getTempDocuments();
        });
    }

    deleteOrderFile(doc) {
        if (confirm('Are you sure you want to delete document ' + doc.description + "?")) {
            this.documentsService.deleteOrderFile(doc.guid, this.paramOrderUUID).subscribe((response) => {
                this.getOrderDocuments();
            });
        }
    }

    saveDescription(doc, i) {
        this.documentsService.updateDescription(
            doc.guid,
            (<HTMLInputElement>document.getElementById("description_edit_" + i)).value).subscribe((response) => {
                this.getOrderDocuments();
            });
    }

    saveTempDescription(doc, i) {
        this.documentsService.updateDescription(
            doc.guid,
            (<HTMLInputElement>document.getElementById("temp_description_edit_" + i)).value).subscribe((response) => {
                this.getTempDocuments();
            });
    }

    emailDocs = {};
    email(doc) {
        console.log(doc);
        this.emailDocs[doc.guid] = true;

    }

    cancelEmail(doc) {
        delete this.emailDocs[doc.guid];
    }

    sendingDocs = {};

    // sendEmail(doc) {
    //     if (this.sendingDocs[doc.guid]) {
    //         console.log("document already sending");
    //         return;
    //     }
    //     this.sendingDocs[doc.guid] = true;
    //     this.awsSes.sendSubmissionEmail(
    //         this.paramTo,
    //         (<HTMLInputElement>document.getElementById("cc_" + doc.guid)).value,
    //         this.paramBcc,
    //         "no-reply",
    //         this.getSubject(doc),
    //         (<HTMLInputElement>document.getElementById("body_" + doc.guid)).value.replace(/\n/g, "<br />"),
    //         this.paramKey,
    //         doc.file_name,
    //         doc.guid,
    //     ).subscribe((response) => {
    //         if (response.success) {
    //             console.log("email sent");
    //             delete this.emailDocs[doc.guid];
    //             delete this.sendingDocs[doc.guid];
    //         } else {
    //             console.log("email send error:", response.error);
    //         }
    //     });
    //     //delete this.emailDocs[doc.guid];
    // }

//     defaultEmailBody = `Please find the search you ordered from KCS Land Research Corporation attached to this email.  Feel free to contact us with any questions or concerns.

// Order ID:  <<oid>>  
    
// Thank you for your continued business.
    
// The KCS Team!!!

// Please do not reply to this email. If you have any questions please contact us at orders@kcslandresearch.com or call (518) 842-9101`;

//     getEmailBody(doc) {
//         return this.defaultEmailBody.replace("<<oid>>", doc.kcs_order_id);
//     }

//     defaultSubject = `Search documents from KCS Land Research Corporation - Order ID: <<oid>>`;
//     getSubject(doc) {
//         if (this.paramSubject) {
//             return this.paramSubject.replace("<<oid>>", doc.kcs_order_id);
//         }
//         return this.defaultSubject.replace("<<oid>>", doc.kcs_order_id);
//     }

    getDocumentTypeIsFinal(documentType) {
        for (var i = 0; i < this.documentTypes.length; ++i) {
            if (this.documentTypes[i].id == documentType) {
                return this.documentTypes[i].final_flag == "Y";
            }
        }
    }

    isFinal(doc) {
        return this.getDocumentTypeIsFinal(doc.document_type);
    }

    // tooLargeToEmail(sizeInBytes) {
    //     if (sizeInBytes > (10485760 / 2)) {
    //         return true;
    //     }
    //     return false;
    // }
}
