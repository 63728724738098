<style>
    .role-selected {
        color: #3bbfb0;
    }
</style>
<span *ngFor="let enumValue of enumValues">
    <div *ngIf="!disabled || fc.value==enumValue.id" class="row">
        <div class="col-1 px-0" align="right">
            <input *ngIf="!disabled" class="ml-2" type="radio" [id]="attributeIdentifier + '_' + enumValue.id"
                [name]="attributeIdentifier" [name]="attributeIdentifier" [value]="enumValue.id" [formControl]="fc"
                (change)="doChange($event)" />
        </div>
        <div class="col-5">
            <label class="mb-0 bold" [for]="attributeIdentifier + '_' + enumValue.id"
                [ngClass]="{'role-selected': fc.value==enumValue.id && !disabled}">{{enumValue.name}}</label>
        </div>
        <div *ngIf="fc.value==enumValue.id && !disabled" class="col-6">
            <input type="text" class="input-style" [formControl]="textFC">
        </div>
        <div *ngIf="fc.value==enumValue.id && disabled" class="col-6">
            {{textFC.value}}
        </div>
    </div>
    <div class="row">
        <div class="col-1">
        </div>
        <div class="col-11" style="font-size: .8em;">
            {{enumValue.comment}}
        </div>
    </div>
</span>