import { Component, OnInit, Renderer2 } from '@angular/core';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { NewlineBreakPipe } from '../newline-break.pipe';
import { AccountService } from '../account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { faChevronRight, faChevronLeft, faBan, faLongArrowAltLeft, faLongArrowAltRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../user.service';
// import { ChargebeeService } from '../chargebee.service';
// declare const Chargebee: any;

@Component({
    selector: 'app-account-create',
    templateUrl: './account-create.component.html',
    styleUrls: ['./account-create.component.css'],
})
export class AccountCreateComponent implements OnInit {
    faChevronLeft = faChevronLeft;
    faChevronRight = faChevronRight;
    faBan = faBan;
    faLongArrowAltLeft = faLongArrowAltLeft;
    faLongArrowAltRight = faLongArrowAltRight;
    faCheck = faCheck

    initialized = false;
    planId;
    error;
    email;
    password;

    emailFC = new UntypedFormControl('');
    passwordFC = new UntypedFormControl();
    password2FC = new UntypedFormControl();


    constructor(
        private _themeService: ThemeRebrandService,
        private _accountService: AccountService,
        private router: Router,
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private renderer: Renderer2,
        // private chargebeeService: ChargebeeService,
    ) { }

    get themeService() {
        return this._themeService;
    }

    get accountService() {
        return this._accountService;
    }

    companyTypesById;
    promotion;
    plan;
    plans;
    addons;
    planDetails;
    chargebeeInstance;

    ngOnInit() {
        this.emailFC = new UntypedFormControl(this.accountService.accountCreateEmail);
        // this.passwordFC = new FormControl(this.accountService.accountCreatePassword);
        // this.password2FC = new FormControl(this.accountService.accountCreatePassword2);
        this.activatedRoute.queryParams.subscribe(params => {
            if (params.promotion) {
                this.promotion = params.promotion;
                if (this.promotion == "professional-trial") {
                    this.accountService.setPromotionSteps()

                    if (!this.accountService.accountCreateCompanyType) {
                        this.accountService.accountCreateCompanyType = "real-estate-professional";
                    }
                    if (!this.accountService.accountCreatePlanId) {
                        this._accountService.accountCreatePlanId = "NY_RP_TOOL_01";
                    }
                }
                this.accountService.getCompanyTypes().subscribe((response) => {
                    //console.log(response);
                    //this.companyTypes = response.result;
                    this.companyTypesById = {};
                    for (var i = 0; i < response.result.length; ++i) {
                        this.companyTypesById[response.result[i].id] = response.result[i];
                    }





                    this.accountService.getAllPlanDetails().subscribe((response) => {
                        if (response.result) {

                            this.planDetails = {};
                            for (var i = 0; i < response.result.length; ++i) {
                                this.planDetails[response.result[i].plan_id] = response.result[i];
                            }
                        }

                        // removed all calls to chargebee, only using data stored in db until we rebuild chargebee
                        
                        //console.log(response);
                        // this.chargebeeService.getChargebeeParams().subscribe((response) => {
                        //     this.chargebeeInstance = Chargebee.init({
                        //         site: response.site,
                        //         publishableKey: response.key
                        //     });

                        //     // this.activatedRoute.queryParams.subscribe(params => {
                        //     //     if (params.companyType) {
                        //     //         this.accountService.accountCreateCompanyType = params.companyType;
                        //     //     }
                        //     this.chargebeeService.listPlans().subscribe((response) => {
                        //         if (response.success) {
                        //             this.plans = response.result.list;
                        //             for (var i = 0; i < this.plans.length; ++i) {
                        //                 this.plans[i].plan.totalPrice = this.plans[i].plan.price;
                        //             }
                        //         }
                        //         if (this.accountService.accountCreateCompanyType) {
                        //             this.accountService.getPlansByCompanyType(this.accountService.accountCreateCompanyType).subscribe((response) => {
                        //                 if (response.success) {
                        //                     for (var i = 0; i < this.plans.length; ++i) {
                        //                         var matchFound = false;
                        //                         for (var j = 0; j < response.result.length; ++j) {
                        //                             if (this.plans[i].plan.id == response.result[j].plan_id) {
                        //                                 matchFound = true;
                        //                                 break;
                        //                             }
                        //                         }
                        //                         if (!matchFound) {
                        //                             this.plans.splice(i, 1);
                        //                             --i;
                        //                         }
                        //                     }
                        //                 }
                        //                 this.getAddons();
                        //             })
                        //         } else {
                        //             this.getAddons();
                        //         }
                        //     });
                        //     // });
                        // });
                    });



                })

            } else {
                this.initialized = true;
            }
        });
    }

    getAddons() {
        for (var i = 0; i < this.plans.length; ++i) {
            if (this.plans[i].plan.id == this._accountService.accountCreatePlanId) {
                this.plan = this.plans[i];
                break;
            }
        }
        // this.chargebeeService.listAddons().subscribe((response) => {
        //     this.addons = response.result.list;
        //     this.initialized = true;
        // })
    }

    getUserCount(plan) {
        var count = this.planDetails[plan.plan.id].max_users;
        if (!count) {
            return "";
        }
        if (count == 1) {
            return "1 User";
        }
        return "Up to " + count + " users";
    }

    // back() {
    //     this.accountService.accountCreateEmail = this.emailFC.value;
    //     this.accountService.accountCreatePassword = this.passwordFC.value;
    //     this.accountService.accountCreatePassword2 = this.password2FC.value;
    //     this.router.navigate(['account-choose-plan']);
    // }

    cancel() {
        this.router.navigate(['/login']);
    }

    next() {
        //var emailAddress = (<HTMLInputElement>document.getElementById("em")).value;
        var emailAddress = this.emailFC.value;
        if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/.test(emailAddress)) {
            this.error = "invalid email address";
            return;
        }

        this.accountService.doesEmailExist(emailAddress).subscribe((response) => {
            //console.log("email exist response", response);
            if (response.success) {
                if (response.count > 0) {
                    this.error = "This email is already in use. If you forgot your password, please click the 'Forgot Password?' link on the login page."
                    return;
                }
            } else {
                this.error = response.message;
                if (response.error) {
                    console.log("response error", response.error);
                }
                return;
            }

            var passwordCheck: any = this.userService.passwordCheckAndSum(this.passwordFC.value, this.password2FC.value, false);
            if (passwordCheck.error) {
                this.error = passwordCheck.error;
                return;
            }

            // if (!this.accountService.accountCreatePlanId) {
            //     this.error = "A plan was not chosen in step 1.";
            //     return;
            // }

            this.accountService.accountCreateEmail = emailAddress;
            this.accountService.accountCreatePassword = passwordCheck.hash;
            // this.accountService.accountCreatePassword = this.passwordFC.value;
            // this.accountService.accountCreatePassword2 = this.password2FC.value;
            this.accountService.navNextStep();
        });
    }

    back() {
        this.accountService.navPreviousStep();
    }

    getImageForCompanyType(type) {
        switch (type) {
            case "title-agent":
                return "assets/choose_company_title_agent.png"
            case "searcher":
                return "assets/choose_company_searcher.png";
            case "search-company":
                return "assets/choose_company_search_abstract_company.png";
            case "real-estate-professional":
                return "assets/choose_company_real_estate_professional.png";
        }
    }

    getMouseoverImageForCompanyType(type) {
        var imgPath = this.getImageForCompanyType(type);
        return imgPath.substring(0, imgPath.length - 4) + "_mouseover.png";
    }

    tileMouseover(type) {
        var elem = document.getElementById(type + "_img");
        (<HTMLImageElement>elem).src = this.getMouseoverImageForCompanyType(type);
        elem = document.getElementById(type + "_check");
        if (elem) {
            this.renderer.setStyle(elem, "color", "white");
        }
    }

    tileMouseout(type) {
        var elem = document.getElementById(type + "_img");
        (<HTMLImageElement>elem).src = this.getImageForCompanyType(type);
        elem = document.getElementById(type + "_check");
        if (elem) {
            this.renderer.setStyle(elem, "color", "#003263");
        }
    }

    tileMouseoverPlan(plan) {
        var tileElem = document.getElementById(plan.id + "_tile");
        tileElem.classList.add('shadow');

        var titleElem = document.getElementById(plan.id + "_title");
        titleElem.classList.add('choose-plan-title');
    }
    tileMouseoutPlan(plan) {
        var tileElem = document.getElementById(plan.id + "_tile");
        tileElem.classList.remove('shadow');

        var titleElem = document.getElementById(plan.id + "_title");
        titleElem.classList.remove('choose-plan-title');
    }

}
