import { Component, Input, OnInit } from '@angular/core';
import { ButtonType } from 'src/app/button/button.component';

@Component({
    selector: 'app-button-group-save',
    templateUrl: './button-group-save.component.html',
})
export class ButtonGroupSaveComponent implements OnInit {
    ButtonType = ButtonType
    @Input() cancel
    @Input() save
    @Input() delete

    constructor() { }

    ngOnInit() {
    }

}
