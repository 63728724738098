<div class="container-fluid pt-1">
    <app-dashboard-controls [title]="title" [pageNumber]="currentPage" [pageSize]="limit" [entryCount]="count"
        newRoute="/user" [hidePaging]="true">
    </app-dashboard-controls>
</div>
<div *ngIf="initialized" class="container-fluid pt-1 dashboard-header">
    <div class="row">
        <div class="col">
            <table class="table table-striped">
                <thead>
                    <tr class="table-header">
                        <td *ngFor="let display_column of displayColumns" (click)="doSort(display_column.field)"
                            class="py-2 pointer nowrap">{{display_column.name}}<span
                                *ngIf="sortColumn == display_column.field">
                                <span *ngIf="!sortDirection">
                                    <fa-icon [icon]="faCaretDown" size="1x" title="sorted ascending"></fa-icon>
                                </span>
                                <span *ngIf="sortDirection">
                                    <fa-icon [icon]="faCaretUp" size="1x" title="sorted descending"></fa-icon>
                                </span>
                            </span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" *ngFor="let user of data; let j = index">
                        <td>
                            <a routerLink="/user" [queryParams]="{id: user.id}">
                                {{user.email_address}}
                            </a>
                        </td>
                        <td>
                            {{user.full_name}}
                        </td>
                        <td>
                            {{user.role_name}}
                        </td>
                        <td>
                            {{user.last_login | date: 'M/d/yyyy'}}
                        </td>
                        <td align="center">
                            <app-button *ngIf="canDelete(user)" [buttonType]="ButtonType.delete"
                                [clickFunction]="delete.bind(this, j)" [small]="true">
                            </app-button>
                            <span *ngIf="canDelete(user) && canSetOwner(user)"> | </span>
                            <span *ngIf="canSetOwner(user)" class="link" (click)="setOwner(j)">Set
                                Owner</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>