import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-dashboard-search-my',
    templateUrl: './dashboard-search-my.component.html',
})
export class DashboardSearchMyComponent implements OnInit {
    filter = {
        gate: "all",
        comparisons: [
            {
                field: "searcher_id",
                operator: "is",
                matchValue: this.authService.userId
            },
            {
                field: "workflow_status",
                operator: "is not",
                matchValue: "D"
            },
        ],
    }

    constructor(
        private userService: UserService,
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
    }

}
