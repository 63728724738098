<div *ngIf="data$ | async as data" class="container-fluid pt-2">
    <div class="row">
        <div class="col-2 d-none d-md-block">
        </div>
        <div class="col-12 col-md-8">

            <div class="row">
                <div class="col bold" style="font-size: 1.6em; color: #117ac9">
                    Tax Profile Edit
                </div>
            </div>

            <span *ngFor="let profile of data.result">
                <div class="row">
                    <div class="col mt-4 attribute-h1 bold">
                        {{profile.tax_type_name | dbtitlecase | titlecase}}
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-input-text-area label="Address" [fc]="profile.address_fc">
                        </app-input-text-area>
                        <span *ngIf="formErrors[profile.profile_type]['address']"
                            class="validation-error mt-1">{{formErrors[profile.profile_type]['address']}}</span>

                        <app-input-text label="Tax Year" [fc]="profile.tax_year_fc">
                        </app-input-text>
                        <span *ngIf="formErrors[profile.profile_type]['tax_year']"
                            class="validation-error mt-1">{{formErrors[profile.profile_type]['tax_year']}}</span>

                        <app-input-text label="Tax Period" [fc]="profile.tax_period_fc">
                        </app-input-text>
                        <span *ngIf="formErrors[profile.profile_type]['tax_period']"
                            class="validation-error mt-1">{{formErrors[profile.profile_type]['tax_year']}}</span>

                        <app-input-text label="Lien Date" [fc]="profile.lien_date_fc">
                        </app-input-text>
                        <span *ngIf="formErrors[profile.profile_type]['lien_date']"
                            class="validation-error mt-1">{{formErrors[profile.profile_type]['tax_year']}}</span>

                        <app-input-date label="Fiscal Start Date" [fc]="profile.fiscal_start_date_fc">
                        </app-input-date>
                        <app-input-date label="Fiscal End Date" [fc]="profile.fiscal_end_date_fc">
                        </app-input-date>
                        <app-input-date label="Due Date 1" [fc]="profile.due_date_1_fc">
                        </app-input-date>
                        <app-input-date label="Due Date 2" [fc]="profile.due_date_2_fc">
                        </app-input-date>
                        <app-input-date label="Due Date 3" [fc]="profile.due_date_3_fc">
                        </app-input-date>
                        <app-input-date label="Due Date 4" [fc]="profile.due_date_4_fc">
                        </app-input-date>
                        <app-input-date label="Refresh Date" [fc]="profile.refresh_date_fc">
                        </app-input-date>
                    </div>
                </div>
            </span>

            <div *ngIf="anyError" class="row mt-3 validation-error-banner py-2">
                <div class="col-12" align="center">
                    <fa-icon [icon]="faExclamationCircle"></fa-icon>
                    Please address errors above and re-submit.
                </div>
            </div>
            <div *ngIf="saveError" class="row mt-3 validation-error-banner py-2">
                <div class="col-12" align="center">
                    <fa-icon [icon]="faExclamationCircle"></fa-icon>
                    {{saveError}}
                </div>
            </div>

            <div class="row pt-3">
                <div class="col" align="center">
                    <app-button [buttonType]="ButtonType.cancel" [clickFunction]="cancel.bind(this)">
                    </app-button>
                    <app-button label="Save and Return to Tax Search" [buttonType]="ButtonType.save"
                        [clickFunction]="save.bind(this, data)">
                    </app-button>
                </div>
            </div>
        </div>
    </div>