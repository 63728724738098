import { Component, OnInit } from '@angular/core';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { v4 as uuid } from 'uuid';
import { InvoiceService } from '../invoice.service';
import { UntypedFormControl } from '@angular/forms';
import { CompanyService } from '../company.service';
import { Router } from '@angular/router';
import { faBan, faFileInvoice, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { DatePipe, CurrencyPipe } from '@angular/common';

enum ReportType {
    invoice,
    payments,
}

@Component({
    selector: 'app-invoice-report',
    templateUrl: './invoice-report.component.html',
    providers: [DatePipe, CurrencyPipe]
})
export class InvoiceReportComponent implements OnInit {
    initialized = false
    faBan = faBan
    faFileInvoice = faFileInvoice
    faFileExport = faFileExport
    ReportType = ReportType
    invoiceReportId = uuid()
    paymentsReceivedReportId = uuid()
    timeframeEnumValues = [
        "All Time",
        "This Month",
        "Last Month",
        "This Quarter",
        "Last Quarter",
        "This Year",
        "Last Year",
        "Custom",
    ]
    customStartDateFC = new UntypedFormControl()
    customEndDateFC = new UntypedFormControl()
    invoiceStatuses
    invoiceStatusesNameById = {}
    reportTypeFC = new UntypedFormControl(ReportType.invoice)
    timeframeFC = new UntypedFormControl(this.timeframeEnumValues[0])
    clientFC = new UntypedFormControl(0)
    clientCompanies
    clientCompaniesNameById = {}

    displayedReportType: ReportType = null
    displayedReport

    searchCriteria

    constructor(
        private _themeService: ThemeRebrandService,
        private invoiceService: InvoiceService,
        private companyService: CompanyService,
        private router: Router,
        private datePipe: DatePipe,
        private currencyPipe: CurrencyPipe,
    ) { }

    ngOnInit() {
        var promises = []

        promises.push(new Promise((resolve, reject) => {
            this.invoiceService.getInvoiceStatuses().subscribe((response) => {
                if (response.success) {
                    this.invoiceStatuses = response.result;
                    for (var i = 0; i < this.invoiceStatuses.length; ++i) {
                        this.invoiceStatuses[i].generated_id = uuid()
                        this.invoiceStatuses[i].fc = new UntypedFormControl(true)
                        this.invoiceStatusesNameById[this.invoiceStatuses[i].id] = this.invoiceStatuses[i].name
                    }
                } else {
                    console.log(response)
                }
                resolve(null)
            })
        }))

        promises.push(new Promise((resolve, reject) => {
            this.companyService.getClientCompanies().subscribe((response) => {
                if (response.success) {
                    this.clientCompanies = response.result;
                    this.clientCompanies.unshift({ id: 0, name: "All Clients" })
                    for (var i = 0; i < this.clientCompanies.length; ++i) {
                        this.clientCompaniesNameById[this.clientCompanies[i].id] = this.clientCompanies[i].name
                    }
                }
                resolve(null)
            })
        }))

        Promise.all(promises).then(() => {
            this.initialized = true
            //console.log(this.clientCompanies)
        })
    }

    get themeService() {
        return this._themeService;
    }

    get searchCriteriaString() {
        var str = "";

        if (this.searchCriteria.client) {
            str += " client " + this.clientCompaniesNameById[this.searchCriteria.client] + ", and "
        }
        if (this.searchCriteria.states) {
            str += "status "
            for (var i = 0; i < this.searchCriteria.states.length; ++i) {
                if (i != 0 && i != this.searchCriteria.states.length - 1) {
                    str += ", "
                }
                if (i == this.searchCriteria.states.length - 1) {
                    str += " or "
                }
                str += this.invoiceStatusesNameById[this.searchCriteria.states[i]]
            }
        }
        return str
    }

    generateReport() {
        this.searchCriteria = {
            client: null,
            startDate: null,
            endDate: null,
            states: [],
        }

        switch (this.timeframeFC.value) {
            case "This Month":
                var start = new Date()
                start.setDate(1)
                start.setHours(0, 0, 0, 0)
                var end = new Date(start.getTime())
                end.setMonth(end.getMonth() + 1)

                this.searchCriteria.startDate = start.getTime() / 1000
                this.searchCriteria.endDate = end.getTime() / 1000
                break;
            case "Last Month":
                var start = new Date()
                start.setDate(1)
                start.setHours(0, 0, 0, 0)
                start.setMonth(start.getMonth() - 1)
                var end = new Date(start.getTime())
                end.setMonth(end.getMonth() + 1)

                this.searchCriteria.startDate = start.getTime() / 1000
                this.searchCriteria.endDate = end.getTime() / 1000
                break;
            case "This Quarter":
                var start = new Date()
                start.setMonth(Math.trunc(start.getMonth() / 4) * 4)
                start.setDate(1)
                start.setHours(0, 0, 0, 0)
                var end = new Date(start.getTime())
                end.setMonth(end.getMonth() + 3)

                this.searchCriteria.startDate = start.getTime() / 1000
                this.searchCriteria.endDate = end.getTime() / 1000
                break;
            case "Last Quarter":
                var start = new Date()
                start.setMonth((Math.trunc(start.getMonth() / 4) - 1) * 4)
                start.setDate(1)
                start.setHours(0, 0, 0, 0)
                var end = new Date(start.getTime())
                end.setMonth(end.getMonth() + 3)

                this.searchCriteria.startDate = start.getTime() / 1000
                this.searchCriteria.endDate = end.getTime() / 1000
                break;
            case "This Year":
                var start = new Date()
                start.setMonth(0)
                start.setDate(1)
                start.setHours(0, 0, 0, 0)
                var end = new Date(start.getTime())
                end.setFullYear(end.getFullYear() + 1)

                this.searchCriteria.startDate = start.getTime() / 1000
                this.searchCriteria.endDate = end.getTime() / 1000
                break;
            case "Last Year":
                var start = new Date()
                start.setFullYear(start.getFullYear() - 1)
                start.setMonth(0)
                start.setDate(1)
                start.setHours(0, 0, 0, 0)
                var end = new Date(start.getTime())
                end.setFullYear(end.getFullYear() + 1)

                this.searchCriteria.startDate = start.getTime() / 1000
                this.searchCriteria.endDate = end.getTime() / 1000
                break;
            case "Custom":
                this.searchCriteria.startDate = this.customStartDateFC.value / 1000
                this.searchCriteria.endDate = this.customEndDateFC.value / 1000
                break;
        }

        if (this.clientFC.value != 0) {
            this.searchCriteria.client = this.clientFC.value;
        }

        for (var i = 0; i < this.invoiceStatuses.length; ++i) {
            if (this.invoiceStatuses[i].fc.value) {
                this.searchCriteria.states.push(this.invoiceStatuses[i].id)
            }
        }

        //console.log(searchCriteria)
        if (this.reportTypeFC.value == this.ReportType.invoice) {
            this.invoiceService.searchInvoices(this.searchCriteria).subscribe((response) => {
                if (response.success) {
                    this.displayedReportType = ReportType.invoice
                    this.displayedReport = response.result
                } else {
                    console.log(response)
                }
            })
        } else {
            this.invoiceService.searchInvoicePayments(this.searchCriteria).subscribe((response) => {
                if (response.success) {
                    this.displayedReportType = ReportType.payments
                    this.displayedReport = response.result
                } else {
                    console.log(response)
                }
            })
        }
    }

    cancel() {
        this.router.navigate(["invoice-list"])
    }

    export() {
        var fileContents = "";
        fileContents += "Status,"
        fileContents += "Invoice Date,"
        fileContents += "Paid In,"
        fileContents += "ID,"
        fileContents += "Client,"
        fileContents += "Invoice,"
        fileContents += "Paid,"
        fileContents += "Balance,"
        fileContents += "\n"

        for (var i = 0; i < this.displayedReport.length; ++i) {
            fileContents += "\"" + this.displayedReport[i].status_name + "\","
            fileContents += "\"" + this.datePipe.transform(this.displayedReport[i].invoice_date * 1000, 'M/d/yy') + "\","
            fileContents += "\"" + this.currencyPipe.transform(this.displayedReport[i].paid, 'USD') + "\","
            fileContents += "\"" + this.displayedReport[i].invoice_id + "\","
            fileContents += "\"" + this.displayedReport[i].for_company_name + "\","
            fileContents += "\"" + this.currencyPipe.transform(this.displayedReport[i].amount_due, 'USD') + "\","
            fileContents += "\"" + this.currencyPipe.transform(this.displayedReport[i].paid, 'USD') + "\","
            fileContents += "\"" + this.currencyPipe.transform(this.displayedReport[i].balance, 'USD') + "\","
            fileContents += "\n"
        }
        var blob = new Blob([fileContents], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;

        var fileName = ""
        if (this.displayedReportType == ReportType.invoice) {
            fileName += "invoice"
        }
        if (this.displayedReportType == ReportType.payments) {
            fileName += "payments"
        }
        fileName += "-report_" + new Date().toLocaleDateString("en-US").replace(/\//g, "-") + ".csv";

        link.download = fileName;
        link.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 0)
    }
}
