<div class="container-fluid pt-2">
    <div class="row edit-height">
        <div class="col-2">
        </div>
        <div class="col-8 pb-2 bold" style="font-size: 1.6em; color: #117ac9">
            Reset Password
        </div>
    </div>
    <div class="row pb-3">
        <div class="col-2">
        </div>
        <div class="col-8">
            <app-input-password label="Change Password" [fc]="changePasswordFC" [style]="'rebrand2'">
            </app-input-password>
            <app-input-password label="Confirm New Password" [fc]="confirmPasswordFC" [style]="'rebrand2'"
                [hideRules]="true">
            </app-input-password>
            <div *ngIf="passwordError" class="row">
                <div class="col my-2" style="color: red" align="center">
                    {{passwordError}}
                </div>
            </div>
            <div class="row">
                <div class="col pb-2 pt-3" align="center">
                    <app-button label="Cancel" [clickFunction]="changePasswordCancel.bind(this)" [icon]="faBan">
                    </app-button>
                    <app-button label="Change Password" [clickFunction]="setPassword.bind(this)" [icon]="faKey"
                        [buttonType]="ButtonType.save"></app-button>
                </div>
            </div>
        </div>
    </div>
</div>