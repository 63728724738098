<div style="white-space: nowrap;">
    <div style="display: inline-block; position: relative;">
        <a *ngIf="firstButton.routerLink" [routerLink]="firstButton.routerLink" [queryParams]="firstButton.queryParams"
            [target]="firstButton.target" class="action-dropdown-first-item link">
            {{firstButton.label}}
        </a>
        <span *ngIf="!firstButton.routerLink" class="action-dropdown-first-item link" (click)="firstButton.click()">
            {{firstButton.label}}
        </span>
        <div class="action-dropdown-menu" id="actions_dropdown_menu" #actions_dropdown_menu style="display: none;">
            <span *ngFor="let button of buttons; let last = last">
                <a *ngIf="button.routerLink" [routerLink]="button.routerLink" [queryParams]="button.queryParams"
                    [target]="button.target">
                    <div class="action-dropdown-item action-dropdown-border-top py-1"
                        [ngClass]="{'action-dropdown-border-bottom': last}">
                        <fa-icon [icon]="button.icon" style="color: black;"></fa-icon>
                        <span class="ml-2 link">{{button.label}}</span>
                    </div>
                </a>
                <div *ngIf="!button.routerLink" class="action-dropdown-item action-dropdown-border-top py-1"
                    [ngClass]="{'action-dropdown-border-bottom': last}" (click)="button.click()">
                    <span *ngIf="button.badgeClass">
                        <span class="px-2 ml-4 status-badge" [ngClass]="[button.badgeClass]">
                            {{button.label | uppercase}}
                        </span>
                    </span>
                    <span *ngIf="!button.badgeClass">
                        <fa-icon [icon]="button.icon" style="color: black;"></fa-icon>
                        <span class="ml-2 link">{{button.label}}</span>
                    </span>
                </div>
            </span>
        </div>
    </div>
    <span class="action-dropdown-control" (click)="clickActionsMenu()">
        <fa-icon [icon]="isCaretUp ? faCaretUp : faCaretDown" title="actions">
        </fa-icon>
    </span>
</div>