<div class="content">
    <app-banner-message *ngIf="hasAuthenticationToken()"></app-banner-message>
    <app-main-menu *ngIf="hasAuthenticationToken()"></app-main-menu>
    <app-banner-notification *ngIf="hasAuthenticationToken()"></app-banner-notification>
    <router-outlet></router-outlet>
    <br />
</div>
<div class="container-fluid mb-0 py-2">
    <div class="row">
        <div class="col" align="center">
            &copy; 2022 <a class="footer-link" href="https://www.search-itsolutions.com" target="_blank">Search-it
                Solutions</a>. All rights reserved.
            <a class="mx-1 footer-link" routerLink="/terms-of-use" target="_blank">Terms of Use</a>
            |
            <a class="mx-1 footer-link" routerLink="/privacy-policy" target="_blank">Privacy Policy</a>
        </div>
    </div>
</div>