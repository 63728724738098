import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { faPlus, faSearch, faFolderOpen, faFolder, faChevronLeft, faChevronRight, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { of } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { InvoiceService } from '../invoice.service';
import { CompanyService } from '../company.service';

@Component({
    selector: 'app-invoice-list',
    templateUrl: './invoice-list.component.html',
    styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
    initialized = false
    faPlus = faPlus
    faSearch = faSearch
    faFolderOpen = faFolderOpen
    faFolder = faFolder
    faChevronLeft = faChevronLeft
    faChevronRight = faChevronRight
    faCaretDown = faCaretDown
    faCaretUp = faCaretUp
    searchInputFC = new UntypedFormControl()
    openInvoices
    allInvoices
    clientCompanies
    clientCompaniesNameById = {}
    invoiceStatusesById = {}
    totalOpen
    totalPaid
    allSortField = "invoice_date"
    allSortDirection = "desc"
    openSortField = "invoice_date"
    openSortDirection = "desc"
    graphOffset = 0
    graphInvoices
    graphIntervalLabel = "This Year"
    graphHeaderLabel = "Invoices issued in " + new Date().getFullYear()
    currentPageAll = 1
    currentPageOpen = 1
    allListLimit
    openListLimit
    allListCount
    openListCount
    columnTitles = [
        {
            display: "ID",
            fieldName: "invoice_id"
        },
        {
            display: "Invoice Date",
            fieldName: "invoice_date"
        },
        {
            display: "Client",
            fieldName: "for_company_name"
        },
        {
            display: "Status",
            fieldName: "status_name"
        },
        {
            display: "Balance",
            fieldName: "balance",
            align: "right"
        }
    ]
    invoiceConfiguration

    allSortClick(columnTitle) {
        if (this.allSortField == columnTitle.fieldName) {
            if (this.allSortDirection == "desc") {
                this.allSortDirection = "asc"
            } else {
                this.allSortDirection = "desc"
            }
        } else {
            this.allSortField = columnTitle.fieldName
            this.allSortDirection = "desc"
        }
        this.getAllInvoicesPromise()
    }

    openSortClick(columnTitle) {
        if (this.openSortField == columnTitle.fieldName) {
            if (this.openSortDirection == "desc") {
                this.openSortDirection = "asc"
            } else {
                this.openSortDirection = "desc"
            }
        } else {
            this.openSortField = columnTitle.fieldName
            this.openSortDirection = "desc"
        }
        this.getOpenInvoicesPromise()
    }

    constructor(
        private _themeService: ThemeRebrandService,
        private userService: UserService,
        private router: Router,
        private invoiceService: InvoiceService,
        private companyService: CompanyService,
    ) { }

    getGraphDataPromise() {
        return new Promise((resolve, reject) => {
            this.invoiceService.getInvoiceConfiguration().subscribe((response) => {
                this.invoiceConfiguration = response.result[0]
                this.invoiceService.getInvoiceGraphData(this.graphOffset).subscribe((response) => {
                    if (response.success) {
                        this.graphInvoices = response.result
                        //console.log("graph data", response)
                        
                    } else {
                        console.log(response)
                    }
                    resolve(null)
                })
            })
        })
    }

    changeGraphOffset(delta) {
        this.graphOffset += delta
        var offsetDate = new Date()
        offsetDate.setFullYear(offsetDate.getFullYear() + this.graphOffset)
        this.graphHeaderLabel = "Invoices issued in " + offsetDate.getFullYear().toString()

        if (this.graphOffset == 0) {
            this.graphIntervalLabel = "This Year"
        } else if (this.graphOffset == 1) {
            this.graphIntervalLabel = "Next Year"
        } else if (this.graphOffset == -1) {
            this.graphIntervalLabel = "Last Year"
        } else if (this.graphOffset < 0) {
            this.graphIntervalLabel = Math.abs(this.graphOffset) + " Years Ago"
        } else {
            this.graphIntervalLabel = Math.abs(this.graphOffset) + " Years in the Future"
        }
        this.getGraphDataPromise()
    }

    getAllInvoicesPromise() {
        return new Promise((resolve, reject) => {
            this.invoiceService.getAllInvoices(this.allSortField, this.allSortDirection, this.currentPageAll).subscribe((response) => {
                //console.log("all response", response)
                if (response.success) {
                    this.allInvoices = response.result
                    this.allListLimit = response.limit
                    this.allListCount = response.count
                } else {
                    console.log(response)
                }
                //console.log("all", this.allInvoices)
                resolve(null)
            })
        })
    }

    getOpenInvoicesPromise() {
        new Promise((resolve, reject) => {
            this.invoiceService.getOpenInvoices(this.openSortField, this.openSortDirection, this.currentPageOpen).subscribe((response) => {
                //console.log("open response", response)
                if (response.success) {
                    this.openInvoices = response.result
                    this.openListLimit = response.limit
                    this.openListCount = response.count
                } else {
                    console.log(response)
                }
                //console.log("open", this.openInvoices)
                resolve(null)
            })
        })
    }

    ngOnInit() {
        var promises = [];
        promises.push(this.getGraphDataPromise())
        promises.push(this.getAllInvoicesPromise())
        promises.push(this.getOpenInvoicesPromise())
        promises.push(new Promise((resolve, reject) => {
            this.companyService.getClientCompanies().subscribe((response) => {
                if (response.success) {
                    this.clientCompanies = response.result;
                    for (var i = 0; i < this.clientCompanies.length; ++i) {
                        this.clientCompaniesNameById[this.clientCompanies[i].id] = this.clientCompanies[i].name;
                    }
                    //console.log(this.clientCompaniesNameById)
                } else {
                    console.log(response)
                }
                resolve(null)
            })
        }))
        promises.push(new Promise((resolve, reject) => {
            this.invoiceService.getInvoiceStatuses().subscribe((response) => {
                if (response.success) {
                    for (var i = 0; i < response.result.length; ++i) {
                        this.invoiceStatusesById[response.result[i].id] = response.result[i]
                    }
                } else {
                    console.log(response);
                }
                //console.log("statuses", this.invoiceStatusesById)
                resolve(null)
            })
        }))
        Promise.all(promises).then(() => {
            this.initialized = true
        })
    }

    get themeService() {
        return this._themeService;
    }

    newInvoice() {
        this.router.navigate(["/invoice-edit"]);
    }

    searchInvoicesById(value) {
        if (value.length == 0) {
            return of([])
        }
        return this.invoiceService.searchInvoiceByID(value);
    }

    selectInvoice(value) {
        this.router.navigate(['invoice-view'], { queryParams: { invoiceId: value.id } })
    }

    curPage(page) {
        return this.router.url == '/' + page;
    }

    listDisplay = "open";

    openClick() {
        this.listDisplay = "open"
    }

    allClick() {
        this.listDisplay = "all"
    }

    
    searchItemDisplay(item) {
        if (item.name) {
            return item.name;
        }
        return item.invoice_id
    }

    setPageAll(page) {
        this.currentPageAll = page
        this.getAllInvoicesPromise()
    }

    setPageOpen(page) {
        this.currentPageOpen = page
        this.getOpenInvoicesPromise()
    }
}
