import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard-order-to-be-invoiced',
    templateUrl: './dashboard-order-to-be-invoiced.component.html',
})
export class DashboardOrderToBeInvoicedComponent implements OnInit {
    //data_order.status= C and data_order_detail.bill_status_id = U where data_order.uuid = data_order_detail.order_uuid
    filter = {
        gate: "all",
        comparisons: [
            {
                field: "order_status",
                operator: "is",
                matchValue: "C"
            },
            {
                field: "data_order_detail.bill_status_id",
                operator: "bill-status-subquery",
                matchValue: "1"
            },
            {
                field: "allow_invoice",
                operator: "is",
                matchValue: "1"
            },
        ],
    }

    constructor() { }

    ngOnInit() {
    }

}
