<div class="container-fluid mt-5">
    <div class="row">
        <div class="col" align="center">
            <img src="assets/logo.png" />
        </div>
    </div>
</div>
<!-- <span *ngIf="!initialized">
    <div class="container-fluid bordered-container mt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.tertiaryColor"
        [style.border-color]="themeService.tertiaryColor">
        Initializing...
    </div>
</span> -->

<div *ngIf="initialized" class="container-fluid mt-5 shadow" style="width: 800px; border-radius: .5em;"
    [style.color]="themeService.secondaryColor" [style.background-color]="themeService.tertiaryColor"
    [style.border-color]="themeService.secondaryColor">
    <app-account-step-circles [stepProvider]="accountService" disableStepLinks="true">
    </app-account-step-circles>
    <div class="row mt-2">
        <div class="col mt-3 bold" style="font-size: 1.2em;" align="center">
            Please select your company type:
        </div>
    </div>
    <div class="row mt-2 px-4">
        <div *ngFor="let type of companyTypes" class="col mt-3 bold" align="center">
            <div class="choose-company-tile" (click)="selectType(type)" (mouseenter)="tileMouseover(type)"
                (mouseleave)="tileMouseout(type)">
                <div *ngIf="this.accountService.accountCreateCompanyType == type.id" align="right" style="position: absolute; right: 0;">
                    <fa-icon [icon]="faCheck" class="mr-2" style="color: white;" id="{{type.id}}_check"></fa-icon>
                </div>
                <img [src]="getImageForCompanyType(type)" class="vcentered" id="{{type.id}}_img" />
            </div>
            I am a {{type.name}}
        </div>
    </div>
    <div *ngIf="error" class="row">
        <div class="col" style="color: red;">
            {{error}}
        </div>
    </div>
    <div class="row mt-4 pb-5">
        <div class="col mt-3 bold" align="center">
            <div class="action-button action-button_secondary" (click)="back()">
                <fa-icon [icon]="faLongArrowAltLeft" class="mr-2"></fa-icon>BACK
            </div>
        </div>
        <div class="col mt-3 bold" align="center">
            <div class="action-button action-button_primary " (click)="next()">NEXT<fa-icon [icon]="faLongArrowAltRight"
                                                                                            class="ml-2">
                </fa-icon>
            </div>
        </div>
    </div>
</div>
