import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { iWorkflowStatus } from "../model/workflow-status.model";
import { iOrderWorkflow } from "../model/workflow.model";
import { iAttachmentType } from "../model/attachment-type.model"
import { ApiResponse } from "../model/api-responce.model";

@Injectable({
    providedIn: 'root'
})

export class WorkflowService
{
    constructor(private http: HttpClient) 
    { 
        // Nothing to do
    }

    getWorkflowOptions(): Observable<iWorkflowStatus[]>
    {
        return this.http.get<iWorkflowStatus[]>('api/orderWorkflows')
    }

    saveWorkflow(workflow: iOrderWorkflow, field: string): Observable<any>
    {
        return this.http.post<any>('api/saveWorkflow', { ...workflow, field });
    }

    getOrderWorkflowCountByOrderUuid(orderUuid: string): Observable<{ order_uuid: string; workflow_count: number }[]>
    {
        return this.http
            .get<ApiResponse<{ order_uuid: string; workflow_count: number }[]>>(`/api/orderWorkflowCount/${orderUuid}`)
            .pipe(map(ApiResponse.unwrapApiResponse));
    }

    getAttachmentTypes(): Observable<iAttachmentType[]>
    {
        return this.http
            .get<ApiResponse<iAttachmentType[]>>(`/api/attachmentTypes`)
            .pipe(map(ApiResponse.unwrapApiResponse));
    }

    updateWorkflowAssignment(workflowUuid: string): Observable<any>
    {
        return this.http
            .post<ApiResponse<any>>(`/api/workflowAssignment`, { workflowUuid })
            .pipe(map(ApiResponse.unwrapApiResponse));
    }
    
}