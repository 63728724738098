import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class AddressService {
    private searchAddressURL = 'api/searchAddress';
    private searchSBLURL = 'api/searchSBL';
    private advancedSearchUrl = 'api/advancedAddressSearch';
    private getAddressByIdUrl = 'api/getAddressById';

    selectedAddress;
    taxInformation;
    parcelInformation;

    _googleMapsAddress;
    _taxMapSrc;

    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer,
    ) { }

    setSelectedAddress(item) {
        this.selectedAddress = item;
        var addressValue = this.selectedAddress.property_full_address
        if (addressValue) {
            addressValue = addressValue.trim()
        }

        var muniValue = this.selectedAddress.property_town_municipality
        if (muniValue) {
            muniValue = muniValue.trim()
        }

        this._googleMapsAddress = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.google.com/maps/embed/v1/place?key=AIzaSyAdm-BRTehaXh8DDDGAN0q21hmMa7jgEOQ&maptype=satellite&q="
            + addressValue + ","
            + muniValue + ",NY&zoom=19");
        this._taxMapSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
            "api/landMap/"
            + this.selectedAddress.county_code + "/"
            + this.selectedAddress.town_code + "/"
            + this.selectedAddress.tax_id);
    }

    get taxMapSrc() {
        return this._taxMapSrc;
    }

    get mapsAddress() {
        return this._googleMapsAddress;
    }

    searchAddress(countySwis, term): Observable<any> {
        return this.http.post<any>(this.searchAddressURL, { county: countySwis, address: term }, httpOptions);
    }

    searchSBL(countySwis, term): Observable<any> {
        return this.http.post<any>(this.searchSBLURL, { county: countySwis, sbl: term }, httpOptions);
    }

    advancedSearch(advancedSearchRequest) {
        return this.http.post<any>(this.advancedSearchUrl, advancedSearchRequest, httpOptions);
    }

    getAddressById(tax_map_data_id) {
        return this.http.post<any>(this.getAddressByIdUrl, { tax_map_data_id: tax_map_data_id }, httpOptions);
    }

    orderCounty;
    orderSBL;
}
