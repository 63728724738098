import { Component, OnInit, Input } from '@angular/core';
import { TitleCasePipe, CurrencyPipe } from '@angular/common';
import { NumberFormatPipe } from '../number-format.pipe';
import { AddressService } from '../address.service';
import { ThemeService } from '../theme.service';
import { ThemeRebrandService } from '../theme-rebrand.service';
import { faBan, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { JurisdictionService } from '../jurisdiction.service';

@Component({
    selector: 'app-researcher-tools-results',
    templateUrl: './researcher-tools-results.component.html',
    styleUrls: ['./researcher-tools-results.component.css'],
    providers: [CurrencyPipe]
})
export class ResearcherToolsResultsComponent implements OnInit {
    @Input() clearFunction;
    faBan = faBan;
    faExpandArrowsAlt = faExpandArrowsAlt

    constructor(
        private _themeService: ThemeRebrandService,
        private titlecase: TitleCasePipe,
        private _numberFormat: NumberFormatPipe,
        private _addressService: AddressService,
        private currencyPipe: CurrencyPipe,
        private _jurisdictionService: JurisdictionService,
    ) { }

    propInfo;
    schoolTimestamp;

    ngOnInit() {
    }

    doGetSchoolDistrictName(schoolDistrictSWIS) {
        this.schoolTimestamp = Date.now();
        this._jurisdictionService.getSchoolDistrictName(schoolDistrictSWIS).subscribe(function (schoolTimestamp, response) {
            if (response.success && this.schoolTimestamp == schoolTimestamp) {
                for (var i = 0; i < this.propInfo.length; ++i) {
                    if (this.propInfo[i].title == "General Property Description") {
                        for (var j = 0; j < this.propInfo[i].props.length; ++j) {
                            if (this.propInfo[i].props[j].label == "School District") {
                                this.propInfo[i].props[j].value = response.result;
                                return;
                            }
                        }
                    }
                }
            }
        }.bind(this, this.schoolTimestamp))
    }

    setPropInfo() {
        this.propInfo = [
            {
                title: "General Property Description",
                props: [
                    {
                        label: "County",
                        value: this.titlecase.transform(this.addressService.selectedAddress.county_name),
                    },
                    {
                        label: "Section Block Lot",
                        value: this.titlecase.transform(this.addressService.selectedAddress.print_key),
                    },
                    {
                        label: "Owner",
                        value: this.titlecase.transform(this.getOwnerString(this.addressService.selectedAddress)),
                    },
                    {
                        label: "Property Address",
                        value: this.titlecase.transform(this.addressService.selectedAddress.property_full_address),
                    },
                    {
                        label: "Town SWIS Code",
                        value: this.titlecase.transform(this.addressService.selectedAddress.county_number_code),
                    },
                    {
                        label: "Municipality",
                        value: this.titlecase.transform(this.addressService.selectedAddress.town_long_description),
                    },
                    {
                        label: "School District Code",
                        value: this.titlecase.transform(this.addressService.selectedAddress.school_district_code),
                    },
                    {
                        label: "School District",
                        value: "",
                    },
                    {
                        label: "Acreage",
                        value: this.addressService.selectedAddress.lot_size_numeric,
                    },
                    {
                        label: "Lot Size",
                        value: this.addressService.selectedAddress.lot_size,
                    },
                    {
                        label: "Account #",
                        value: this.addressService.selectedAddress.account_number,
                    },
                    {
                        label: "Zoned/Use Code",
                        value: this.addressService.selectedAddress.use_code,
                    },
                    {
                        label: "Class Name",
                        value: this.titlecase.transform(this.addressService.selectedAddress.land_use_long_description),
                    },
                ],
            },
            {
                title: "Tax/Assessment Data",
                props: [
                    {
                        label: "School Tax",
                        value: this.currencyPipe.transform(this.addressService.selectedAddress.school_tax_estimate),
                        //align: "right",
                    },
                    {
                        label: "County Tax",
                        value: this.currencyPipe.transform(this.addressService.selectedAddress.county_tax_estimate),
                        //align: "right",
                    },
                    {
                        label: "Land",
                        value: this.currencyPipe.transform(this.addressService.selectedAddress.land_assed_value),
                        //align: "right",
                    },
                    {
                        label: "Total Assessment",
                        value: this.currencyPipe.transform(this.addressService.selectedAddress.assessed_value),
                        //align: "right",
                    },

                ],
            },
            {
                title: "Property Details",
                props: [
                    {
                        label: "Year Built",
                        value: this.addressService.selectedAddress.year_built,
                    },
                    {
                        label: "# of Buildings",
                        value: this.addressService.selectedAddress.misc_field_nb,
                    },
                    {
                        label: "Stories",
                        value: this.addressService.selectedAddress.number_of_stores,
                    },
                    {
                        label: "Square Feet",
                        value: this.numberFormat.transform(this.addressService.selectedAddress.square_footage, ".", ",", true),
                    },
                    {
                        label: "House Type",
                        value: this.titlecase.transform(this.addressService.selectedAddress.house_type_long_desc),
                    },
                    {
                        label: "Exterior",
                        value: this.titlecase.transform(this.addressService.selectedAddress.exterior_long_description),
                    },
                    {
                        label: "Residential Units",
                        value: this.addressService.selectedAddress.res_units,
                    },
                    {
                        label: "Garage",
                        value: this.addressService.selectedAddress.number_of_garages,
                    },
                    {
                        label: "Number of Rooms",
                        value: this.addressService.selectedAddress.number_of_rooms,
                    },
                    {
                        label: "Bedrooms",
                        value: this.addressService.selectedAddress.number_of_bedrooms,
                    },
                    {
                        label: "Bathrooms Full",
                        value: this.addressService.selectedAddress.number_of_bathrooms,
                    },
                    {
                        label: "Bathrooms Half",
                        value: this.addressService.selectedAddress.number_of_half_bathrooms,
                    },
                    {
                        label: "Heat Type",
                        value: this.titlecase.transform(this.addressService.selectedAddress.heat_long_description),
                    },
                    {
                        label: "Fuel",
                        value: this.titlecase.transform(this.addressService.selectedAddress.fuel_long_description),
                    },
                    {
                        label: "Fireplaces",
                        value: this.addressService.selectedAddress.number_of_fireplaces,
                    },
                    {
                        label: "Water",
                        value: this.titlecase.transform(this.addressService.selectedAddress.water_long_description),
                    },
                    {
                        label: "Sewer",
                        value: this.titlecase.transform(this.addressService.selectedAddress.sewer_type_long_description),
                    },
                    {
                        label: "Basement",
                        value: this.getBasementDescription(this.addressService.selectedAddress.basement_type),
                    },
                    {
                        label: "Utilities",
                        value: this.titlecase.transform(this.addressService.selectedAddress.utilities_long_description),
                    },
                    {
                        label: "Central Air",
                        value: this.getCentralAirDescription(this.addressService.selectedAddress.central_air),
                    },
                    {
                        label: "Deed Book",
                        value: this.titlecase.transform(this.addressService.selectedAddress.deed_book),
                    },
                    {
                        label: "Deed Page",
                        value: this.titlecase.transform(this.addressService.selectedAddress.deed_page),
                    },
                ]
            }
        ];
        this.doGetSchoolDistrictName(this.addressService.selectedAddress.school_district_code);
    }

    get themeService() {
        return this._themeService;
    }

    get addressService() {
        return this._addressService;
    }

    get taxInformation() {
        return null;
    }

    get parcelInformation() {
        return null;
    }

    get numberFormat() {
        return this._numberFormat;
    }

    getTaxLink(taxInfoRow) {
        return taxInfoRow.url.replace("$sbl.getSbl()", this._addressService.selectedAddress.print_key)
            .replace("$tempTaxSearch.getTaxYer()", taxInfoRow.tax_year)
            .replace("$tempTaxSearch.getTaxYear()", taxInfoRow.tax_year);
    }

    getTaxMapLink(address) {
        return "http://www.landmaxmaps.com/map.php/properties/property_map/" + address.county_code + "/" + address.town_code + "/" + address.tax_id;
    }

    getParcelLink(parcelInfoRow) {
        return parcelInfoRow.url.replace("$getSwis()", this._addressService.selectedAddress.county_number_code)
            .replace("$getLongSbl()", this._addressService.selectedAddress.tax_id)
            .replace("$getLongSblMinus4()", this._addressService.selectedAddress.tax_id.substring(0, this._addressService.selectedAddress.tax_id.length - 4));
    }

    getOwnerString(address) {
        var _return = address.owner_name_1;
        if (address.owner_name_2 && address.owner_name_2 != "") {
            _return += " " + address.owner_name_2;
        }
        return _return;
    }

    getBasementDescription(basementCode) {
        switch (Number(basementCode)) {
            case 1:
                return "Pier/Slab";
            case 2:
                return "Crawl space";
            case 3:
                return "Partial";
            case 4:
                return "Full";
            default:
                return "";
        }
    }

    getCentralAirDescription(centralAirCode) {
        switch (centralAirCode) {
            case 0:
                return "No";
            case 1:
                return "Yes";
            default:
                return "";
        }
    }

    getAddressPropertyImprovements(address) {
        return [
            {
                year: address.home_improvement_year_built_1,
                improvement: this.titlecase.transform(address.home_improvement_1_description),
            }, {
                year: address.home_improvement_year_built_2,
                improvement: this.titlecase.transform(address.home_improvement_2_description),
            }, {
                year: address.home_improvement_year_built_3,
                improvement: this.titlecase.transform(address.home_improvement_3_description),
            }, {
                year: address.home_improvement_year_built_4,
                improvement: this.titlecase.transform(address.home_improvement_1_description_4),
            }
        ];
    }

    clear() {
        if (this.clearFunction) {
            this.clearFunction();
        }
    }

    viewLargerMap() {
        window.open(this.getTaxMapLink(this.addressService.selectedAddress))
    }
}
