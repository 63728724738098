import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    private getPlanDetailsURL = 'api/planDetails';
    private getAllPlanDetailsURL = 'api/allPlanDetails';
    private emailExistURL = 'api/does_email_exist';
    private canUserBeCreatedURL = 'api/can_user_be_created';
    private newAccountURL = 'api/create_sierra_account';
    private getPortalUrlURL = "api/chargebee_portal";
    private getCompanyTypesURL = "api/company_types";
    private getPlansByCompanyTypeUrl = "api/plans_by_company_type";
    private originalSteps = [
        { label: "Company Type", route: "/account-choose-company-type" },
        { label: "Choose a plan", route: "/account-choose-plan" },
        { label: "Create Account", route: "/account-create" },
        { label: "Review & Payment", route: "/account-review" },
    ];
    private professionalTrialPromotionSteps = [
        this.originalSteps[2],
        this.originalSteps[3],
    ]
    private steps = this.originalSteps

    private loginRoute = "/login";
    accountCreateCompanyType;
    accountCreatePlanId;
    accountCreateEmail;
    accountCreatePassword;
    accountCreatePassword2;
    accountCreateAddons;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private router: Router,
    ) {
    }

    private _profileImageId = new BehaviorSubject(null);

    get profileImageId() {
        return this._profileImageId;
    }

    setPromotionSteps() {
        this.steps = this.professionalTrialPromotionSteps
    }

    setOriginalSteps() {
        this.steps = this.originalSteps
    }

    getSteps() {
        return this.steps;
    }

    getFirstStepRoute() {
        return this.steps[0].route;
    }

    getNextStepRoute() {
        var stepMatched = false;
        for (var i = 0; i < this.steps.length; ++i) {
            if (stepMatched) {
                return this.steps[i].route;
            }
            if (this.router.url.startsWith(this.steps[i].route)) {
                stepMatched = true;
            }
        }
        return this.loginRoute;
    }

    getPreviousStepRoute() {
        var stepMatched = false;
        for (var i = this.steps.length - 1; i >= 0; --i) {
            if (stepMatched) {
                return this.steps[i].route;
            }
            if (this.router.url.startsWith(this.steps[i].route)) {
                stepMatched = true;
            }
        }
        return this.loginRoute;
    }

    navNextStep() {
        this.router.navigate([this.getNextStepRoute()]);
    }

    navPreviousStep() {
        this.router.navigate([this.getPreviousStepRoute()]);
    }

    navCancelStep() {
        this.router.navigate([this.loginRoute]);
    }

    doesEmailExist(emailAddress) {
        return this.http.post<any>(this.emailExistURL,
            { emailAddress: emailAddress }, httpOptions);
    }

    canUserBeCreated(emailAddress, companyId) {
        return this.http.post<any>(this.canUserBeCreatedURL,
            { emailAddress: emailAddress, companyId: companyId }, httpOptions);
    }

    createAccount(planId, email, addons) {
        return this.http.post<any>("api/create_chargebee_account",
            { plan: planId, email: email, addons: addons }, httpOptions);
    }

    newAccount(page, password) {
        return this.http.post<any>(this.newAccountURL,
            { page: page, password: password }, httpOptions);
    }

    getPortalUrl(subscriptionId, customerId) {
        return this.http.post<any>(this.getPortalUrlURL,
            { subscription_id: subscriptionId, customer_id: customerId }, httpOptions);
    }

    getPlanDetails(planId) {
        return this.http.post<any>(this.getPlanDetailsURL,
            { plan_id: planId }, httpOptions);
    }

    getAllPlanDetails() {
        return this.http.get<any>(this.getAllPlanDetailsURL, httpOptions);
    }

    getCompanyTypes() {
        return this.http.get<any>(this.getCompanyTypesURL);
    }

    getPlansByCompanyType(companyType) {
        return this.http.get<any>(this.getPlansByCompanyTypeUrl + "/" + companyType);
    }

    createAPosterori(email) {
        return this.http.put<any>("api/create_company_and_user_by_subscription_email", { email }, httpOptions);
    }

    getCanCreateClientUser(email, companyUuid) {
        return this.http.get<any>("api/canCreateClientUser/" + email + "/" + companyUuid);
    }
}
