<div class="container-fluid mt-5">
    <div class="row">
        <div class="col" align="center">
            <img src="assets/logo.png" />
        </div>
    </div>
</div>
<div *ngIf="initialized" class="container-fluid mt-5 shadow" style="width: 800px; border-radius: .5em;"
    [style.background-color]="themeService.tertiaryColor"
    [style.border-color]="themeService.secondaryColor">
    <app-account-step-circles [stepProvider]="accountService" disableStepLinks="true">
    </app-account-step-circles>
    <div class="row pb-1 pt-3">
        <div class="col-2">
        </div>
        <div class="col-8">
            <div *ngIf="error" class="row">
                <div class="col">
                    {{error}}
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4 pb-5">
        <div class="col mt-3 bold" align="center">
            <div class="action-button action-button_secondary" (click)="cancel()">
                <fa-icon [icon]="faBan" class="mr-2"></fa-icon>CANCEL
            </div>
        </div>
    </div>
</div>
