<div class="row mt-3">
    <div class="col bold">
        {{label}}<span *ngIf="requiredMarker" style="position: relative;"
            (mouseenter)="getElementById(attributeIdentifier + '_required_mouseover').style.display='block';"
            (mouseleave)="getElementById(attributeIdentifier + '_required_mouseover').style.display='none';">*<span
                style="position: absolute; display: none; top: 0px; left: 1.0em;"
                id="{{attributeIdentifier}}_required_mouseover">(required)</span></span>
    </div>
</div>
<span *ngFor="let enumValue of enumValues">
    <div class="row">
        <div class="col-1 px-0" align="right">
            <input class="ml-2" type="radio" [id]="enumValue.id" [name]="attributeIdentifier" [value]="enumValue.id"
                [formControl]="fc" />
        </div>
        <div class="col-11">
            <label class="mb-0 bold" [for]="enumValue.id"
                [ngClass]="{'role-selected': fc.value==enumValue.id}">{{enumValue.name}}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-1">
        </div>
        <div class="col-11" style="font-size: .8em;">
            {{enumValue.comment}}
        </div>
    </div>
</span>