<!-- Check if documents data source has values -->
<ng-container *ngIf="documents && documents.data.length > 0; else noData">
  <div class="header-container">
    <div class="header">
      <h2>Documents Attached to Order {{ orderName }}</h2>
    </div>
  
    <div class="download-all">
      <button mat-raised-button class="download-all-button" (click)="downloadAll()">Download All</button>
    </div>
  </div>

  <table mat-table [dataSource]="documents" matSort class="mat-elevation-z8">
    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let document" class="clickable-data" (click)="downloadFile(document)">{{ document.description }}</td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="documentType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let document">{{ document.documentType }}</td>
    </ng-container>

    <!-- Filename Column -->
    <ng-container matColumnDef="fileName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Filename</th>
      <td mat-cell *matCellDef="let document">{{ document.fileName }}</td>
    </ng-container>

    <!-- Created Column -->
    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
      <td mat-cell *matCellDef="let document">{{ document.created | date:'MM/dd/yyyy' }}</td>
    </ng-container>

    <!-- Table rows -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-container>

<!-- If documents data source is empty -->
<ng-template #noData>
  <h2>No downloads available for this order.</h2>
  <a routerLink="/agent-dashboard">Back to Dashboard</a>
</ng-template>
