import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faBan, faSave } from '@fortawesome/free-solid-svg-icons';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ButtonType } from '../button/button.component';
import { CompanyService } from '../company.service';
import { InvoiceService } from '../invoice.service';
import { SalesTaxService } from '../sales-tax.service';
import { SnackRouteService } from '../snack-route.service';

@Component({
    selector: 'app-customer-company',
    templateUrl: './customer-company.component.html',
})
export class CustomerCompanyComponent implements OnInit {
    ButtonType = ButtonType
    faBan = faBan
    faSave = faSave

    constructor(
        private activatedRoute: ActivatedRoute,
        private companyService: CompanyService,
        private snackRouteService: SnackRouteService,
        private invoiceService: InvoiceService,
        private salesTaxService: SalesTaxService,
    ) { }

    uuid
    nameFC = new UntypedFormControl()
    addressFC = new UntypedFormControl()
    phoneFC = new UntypedFormControl()
    faxFC = new UntypedFormControl()
    websiteFC = new UntypedFormControl()
    paymentTermsFC = new UntypedFormControl()
    invoiceEmailFC = new UntypedFormControl()
    taxJurisdictionFC = new UntypedFormControl()
    internalNotesFC = new UntypedFormControl()
    dueDates

    taxJurisdictionNameFC = new UntypedFormControl()

    companyValues$ = this.activatedRoute.queryParams.pipe(
        switchMap((params) => {
            this.uuid = params.uuid
            return combineLatest(
                this.salesTaxService.getSalesTaxes(),
                this.invoiceService.getDueDates(),
                this.companyService.getCompanyById(params.uuid)
            )
        }),
        map(([salesTaxResponse, invoiceDueDatesResponse, companyResponse]) => {
            this.salesTaxJurisdictions = salesTaxResponse.result
            this.dueDates = invoiceDueDatesResponse.result;
            //console.log("response", companyResponse)
            this.nameFC.setValue(companyResponse.result[0].name)
            this.addressFC.setValue(companyResponse.result[0].address)
            this.phoneFC.setValue(companyResponse.result[0].phone)
            this.faxFC.setValue(companyResponse.result[0].fax)
            this.websiteFC.setValue(companyResponse.result[0].web_address)
            this.paymentTermsFC.setValue(companyResponse.result[0].default_payment_term_id)
            this.invoiceEmailFC.setValue(companyResponse.result[0].invoice_email_address)
            this.taxJurisdictionFC.setValue(companyResponse.result[0].default_sales_tax_id)
            this.taxJurisdictionNameFC.setValue(companyResponse.result[0].tax_jurisdiction_name)
            this.internalNotesFC.setValue(companyResponse.result[0].internal_billing_notes)
            return of({})
        }))

    ngOnInit() {
    }

    back() {
        window.history.back()
    }

    save() {
        this.companyService.updateClientCompany({
            uuid: this.uuid,
            name: this.nameFC.value,
            address: this.addressFC.value,
            web_address: this.websiteFC.value,
            phone: this.phoneFC.value,
            fax: this.faxFC.value,
            invoice_email_address: this.invoiceEmailFC.value,
            default_payment_term_id: this.paymentTermsFC.value,
            default_sales_tax_id: this.taxJurisdictionFC.value,
            internal_billing_notes: this.internalNotesFC.value,
        }).subscribe((response) => {
            if (response.success) {
                this.snackRouteService.snack("Customer Company Saved")
                this.back()
            } else {
                console.log(response)
                this.snackRouteService.snack("Error occurred while saving company " + response.message)
            }
        })
    }

    salesTaxJurisdictions
    searchJurisdictions(term) {
        var results = []
        term = term.toLowerCase()
        for (var i = 0; i < this.salesTaxJurisdictions.length; ++i) {
            if (this.salesTaxJurisdictions[i].jurisdiction_name.toLowerCase().startsWith(term)) {
                results.push(this.salesTaxJurisdictions[i])
            }
        }
        return of(results)
    }

    getItemJurisdictionName(item) {
        return item.jurisdiction_name
    }

    selectJurisdiction(item) {
        //console.log("selected", item)
        // this.taxJurisdictionNameFC.setValue(item.jurisdiction_name)
        this.taxJurisdictionFC.setValue(item.id)
        //this.taxRateFC.setValue(item.rate)


        // this.taxRateFC.setValue(response.result[0].rate)
        // this.taxLocationFC.setValue(response.result[0].jurisdiction_name)
        // this.taxCodeFC.setValue(response.result[0].code)

    }

}
