import { Component, OnInit } from '@angular/core';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { CrudService } from '../crud.service';
import { ProductService } from '../product.service';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
})
export class ProductsComponent implements OnInit {
    faCaretUp = faCaretUp
    faCaretDown = faCaretDown
    title = "Products"
    currentPage = 1
    count = 0
    limit = 25
    sortColumn = "name"
    sortDirection = false

    doSearch = new BehaviorSubject(null)

    displayColumns = [
        {
            name: "Name",
            field: "name",
        }, {
            name: "Group",
            field: "group_name",
        }, {
            name: "Price",
            field: "price_1",
        }, {
            name: "Active",
            field: "active",
        }
    ]

    setPage(page) {
        this.currentPage = page
        this.doSearch.next(null)
    }

    doSort(column) {
        if (this.sortColumn == column) {
            this.sortDirection = !this.sortDirection
        } else {
            this.sortColumn = column
            this.sortDirection = true
        }
        this.doSearch.next(null)
    }

    products$ = this.doSearch.pipe(
        switchMap(() => {
            return this.productService.getProducts()
            // return this.crudService.search({
            //     type: "data_company",
            //     where: "parent_company_uuid = ? and parent_company_relation_type = ?",
            //     whereArgs: [this.authService.companyId, "vendor"],
            //     limit: this.limit,
            //     offset: (this.currentPage - 1) * this.limit,
            //     sort: [
            //         {
            //             columnName: this.sortColumn,
            //             isDescending: this.sortDirection
            //         }
            //     ]
            // })
        }),
        tap((response) => {
            //console.log("vendors response", response)
            this.count = response.count
        }),
        map((response) => {

            for (var i = 0; i < response.result.length; ++i) {
                if (response.result[i].price_id) {
                    response.result[i].effective_price = response.result[i].price_1
                    response.result[i].effective_active = response.result[i].active ? "Active" : "Inactive"
                } else {
                    response.result[i].effective_price = response.result[i].default_price_1
                    response.result[i].effective_active = "Active"
                }

            }

            return response.result
        })
    )

    constructor(
        private crudService: CrudService,
        private authService: AuthService,
        private productService: ProductService,
    ) { }

    ngOnInit() {
    }

}
