<span *ngIf="!initialized">
    <div class="container-fluid bordered-container mt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.tertiaryColor"
        [style.border-color]="themeService.tertiaryColor">
        Initializing
    </div>
</span>
<span *ngIf="initialized && !validToken">
    <div class="container-fluid bordered-container mt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.tertiaryColor"
        [style.border-color]="themeService.tertiaryColor">
        <div class="row mt-2">
            <div class="col" style="font-size: 1.6em;">
                Reset Your Password
            </div>
        </div>
        <div class="row mt-1">
            <div class="col">
                Invalid Token
            </div>
        </div>
        <div class="row mt-3 mb-2">
            <div class="col" align="center">
                <app-button label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan"></app-button>
            </div>
        </div>
    </div>
</span>

<span *ngIf="initialized && validToken && !finished">
    <div class="container-fluid bordered-container mt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.tertiaryColor"
        [style.border-color]="themeService.tertiaryColor">
        <div class="row mt-2">
            <div class="col" style="font-size: 1.6em;">
                Reset Your Password
            </div>
        </div>
        <div class="row mt-1">
            <div class="col">
                Set a new password to sign in with email address {{username}}.
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-2">
            </div>
            <div class="col-7">
                <app-input-password label="New Password" [fc]="passwordFC" [keyupEnter]="setPassword.bind(this)">
                </app-input-password>
                <app-input-password label="Confirm Password" [fc]="password2FC" [keyupEnter]="setPassword.bind(this)"
                    [hideRules]="true">
                </app-input-password>
            </div>
        </div>
        <div class="row mt-2" *ngIf="error">
            <div class="col" align="center">
                {{error}}
            </div>
        </div>
        <div class="row mt-3 mb-2">
            <div class="col" align="center">
                <app-button label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan"></app-button>
                <app-button class="ml-2" label="Reset Password" [clickFunction]="setPassword.bind(this)" [icon]="faKey">
                </app-button>
            </div>
        </div>
    </div>
</span>

<span *ngIf="initialized && validToken && finished">
    <div class="container-fluid bordered-container mt-4" [style.color]="themeService.textOnTertiaryColor"
        [style.background-color]="themeService.tertiaryColor" [style.border-color]="themeService.tertiaryColor"
        [style.border-color]="themeService.tertiaryColor">
        <div class="row mt-2">
            <div class="col" style="font-size: 1.6em;">
                Reset Your Password
            </div>
        </div>
        <div class="row mt-4 mb-5">
            <div class="col" align="center">
                Your password has been reset. Please <a href="/">log in</a>.
            </div>
        </div>
    </div>
</span>