import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatTableDataSource} from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { SYSCOUNTY } from '../mock/sys-county-mock';
import { CountyService } from '../services/county.service';
import { CountyServiceMock } from '../services/county.service.mock';
import { SysCounty } from '../model/sys-county.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-sys-county',
  templateUrl: './sys-county.component.html',
  styleUrls: ['./sys-county.component.css']
})
export class SysCountyComponent {

  //data used to populate the table
  ELEMENT_DATA = SYSCOUNTY

  countyDataSource: MatTableDataSource<SysCounty>;
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  
  displayedColumns: string[] = 
  [
    'state',
    'countyName',
    'address',
    'tax_year',
    'tax_period',
    'due_date_1',
    'due_date_2',
    'due_date_3',
    'due_date_4',
    'refresh_date'
  ]

  constructor(
    private countyServiceMock: CountyServiceMock, 
    private dialog: MatDialog,
    private countyService: CountyService
  ) 
  {
    this.countyDataSource = new MatTableDataSource([{}]);
    this.loadTableData();
  }

  private loadTableData(): void 
  {
    this.countyService.getCounties().subscribe( response => {
      this.countyDataSource.data = response.results
    });
  }

  ngAfterViewInit()
  {
    this.countyDataSource.paginator = this.paginator;
  }


  //open county edit dialog
  editCounty(county: SysCounty): void
  {
      const dialogRef = this.dialog.open(EditCountyDialog, {
      minWidth: '480px',
      data: county
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed, having data: ' + JSON.stringify(result));
      this.loadTableData();
    });
  }

    //filter functionality for search bar
    applyFilter(filterValue: string) {
      filterValue = filterValue.trim().toLowerCase();
      this.countyDataSource.filterPredicate = (data: any, filter: string) => {
      const columnValue = data.countyName.toLowerCase();
      return columnValue.includes(filter);
      };
      this.countyDataSource.filter = filterValue;
    }
}

@Component({
  selector: 'sys-county-edit',
  templateUrl: './edit-county-dialog.html',
  styleUrls: ['./sys-county.component.css']
})
export class EditCountyDialog 
{
  form = new UntypedFormGroup({
    //filling form with data from result of getCountyById function
    state: new UntypedFormControl({value: this.data.state, disabled: true}),
    countyName: new UntypedFormControl({value: this.data.countyName, disabled: true}),
    address: new UntypedFormControl(this.data.address, [Validators.required]),
    taxYear: new UntypedFormControl(this.data.taxYear, [Validators.required]),
    taxPeriod: new UntypedFormControl(this.data.taxPeriod, [Validators.required]),
    dueDate1: new UntypedFormControl(this.data.dueDate1, [Validators.required]),
    dueDate2: new UntypedFormControl(this.data.dueDate2),
    dueDate3: new UntypedFormControl(this.data.dueDate3),
    dueDate4: new UntypedFormControl(this.data.dueDate4),
    refreshDate: new UntypedFormControl(this.data.refreshDate)
  })
  
  constructor(
      public DialogRef: MatDialogRef<EditCountyDialog>,
      private countyServiceMock: CountyServiceMock,
      private countyService: CountyService,
      @Inject(MAT_DIALOG_DATA) public data: SysCounty)
   {}


   save() {
    //logic to update county
      if (this.form.valid) 
      {
        this.data.address = this.form.get("address").value;
        this.data.taxYear = this.form.get("taxYear").value;
        this.data.taxPeriod = this.form.get("taxPeriod").value;
        this.data.dueDate1 = this.form.get("dueDate1").value;
        this.data.dueDate2 = this.form.get("dueDate2").value;
        this.data.dueDate3 = this.form.get("dueDate3").value;
        this.data.dueDate4 = this.form.get("dueDate4").value;
        this.data.refreshDate = this.form.get("refreshDate").value;

        this.countyService.updateCounty(this.data).subscribe(response => {
          if(response.success === true) {
            console.log(`County updated: ${this.data}`);
          } else {
            console.log(`Failed to update county: ${this.data}`);
          }
          // next: (response) => {
          //   console.log(`County updated: ${this.data}`);
          // },
          // error: (err) => {
          //   console.log(err);
          // }
        });
        this.DialogRef.close(this.data);
      }
      else
      {
        this.form.markAllAsTouched();
        return;
      }
   }
}

